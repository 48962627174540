import { ApplicationConstant, NPITypes } from "../../../../constants/application.constant";
import { getFormattedDate, getDateIgnoreTimezone, isObjsEqual } from "../../../../utils/helper";

export const initialState = (value, options) =>  {
    return {
      first_name: {value: value?.user?.first_name || '',},
      last_name: {value: value?.user?.last_name || '',},
      preferred_name: {value: value?.user?.preferred_name || ''},
      date_of_birth: {value: (value?.user?.date_of_birth ? getDateIgnoreTimezone(value.user.date_of_birth) : ''), valid: true},
      phone: {value: value?.user?.phone || ''},
      profile_image: {value: value?.user?.profile_image || ''},
      is_image_deleted: ApplicationConstant.ZERO,
      npi_number:{value: value?.npi_number || '', valid: true},
      npi_type:getNpiSelectedOption(value?.npi_type),
      license_number:{value: value?.license_number || '', valid: true},
      expiration_date:{value: (value?.expiration_date ? getDateIgnoreTimezone(value.expiration_date) : null), valid: true},
      location: {
        address1: {value: value?.location?.address1 || ''},
        address2: {value: value?.location?.address2 || ''},
        state: { id: value?.location?.state_id, label: value?.location?.state_name,},
        city: { id: value?.location?.city_id, label: value?.location?.city_name},
        zip_code: {value: value?.location?.zip_code || '', valid: true},
      },
      document: {value: value?.document || '', valid: true},
      prescription_availability: value?.prescription_availability || '',
      see_patients: value?.see_patients || '',
      own_insurance: value?.own_insurances || '',
      accept_insurance: value?.accept_insurance || '',
      electronic_medical_record: value?.electronic_medical_record || '',
      medical_school: getSelectedOptions(value?.medical_school),
      other_medical_school: value?.other_medical_school || '',
      doctor_certifications: getSelectedOptions(value?.doctor_certifications, 'doctor_certifications'),
      other_certification: getOtherFieldValue(value?.doctor_certifications, 'other_certification'),
      doctor_specializations: getSelectedOptions(value?.doctor_specializations, 'doctor_specializations'),
      user_facilities: getSelectedOptions(value?.user_facilities, 'user_facilities'),
      state: getSelectedOptions(value?.state_licenses, 'state_licenses'),
      insurance_provider:getSelectedOptions(value?.doctor_insurance_providers, 'doctor_insurance_providers'),
      other_insurance_provider: getOtherFieldValue(value?.doctor_insurance_providers, 'other_insurance_provider'),
      year_of_experience:{value: value?.year_of_exp|| ''},
      practice:getSelectedOptions(value?.doctor_practices, 'doctor_practices'),
      procedure:getSelectedOptions(value?.doctor_procedures, 'doctor_procedures'),
      condition_treated:getSelectedOptions(value?.doctor_condition_treateds, 'doctor_condition_treateds'),

    }
};

function getSelectedOptions(val, fieldName){

  if (!Array.isArray(val)) {
    if(typeof val === 'object' && !!val){
      return {...val, value: val?.name.toLowerCase(), label: val?.name};
    } else {
      return [];
    }
  } else {
    switch (fieldName) {
      case 'doctor_certifications':
        return val.map(item=>({id:item.certification_id, name:item.name, value:item.name.toLowerCase(), label:item.name}));
      case 'doctor_specializations':
        return val.map(item=>({id:item.specialization_id, name:item.name, value:item.name.toLowerCase(), label:item.name}));
      case 'user_facilities':
        return val.map(item=>({id:item.facility_id, name:item.name, value:item.name.toLowerCase(), label:item.name}));
      case 'doctor_practices':
        return val.map(item=>({id:item.practice_id, name:item.name, value:item.name.toLowerCase(), label:item.name}));
      case 'doctor_procedures':
        return val.map(item=>({id:item.procedure_id, name:item.name, value:item.name.toLowerCase(), label:item.name}));
      case 'doctor_condition_treateds':
        return val.map(item=>({id:item.condition_treated_id, name:item.name, value:item.name.toLowerCase(), label:item.name}));
      case 'doctor_insurance_providers':
        return val.map(item=>({id:item.insurance_provider_id, name:item.name, value:item.name.toLowerCase(), label:item.name}));
      case 'state_licenses':
        return val.map(item=>({id:item.state_id, name:item.name, value:item.name.toLowerCase(), label:item.name}));
      default:
        return val.map(item=>({id:item.id, name:item.name, value:item.name.toLowerCase(), label:item.name}));
    }
  }
}

function getOtherFieldValue(val, fieldName){
  let otherValue = "";
  if(Array.isArray(val)){
    otherValue = val.find((item) =>item?.name?.toLowerCase() === ApplicationConstant.OTHER_LOWER_CASE);
    if(otherValue){
      return otherValue[`${fieldName}`];
    }
  }
  return '';
}

function getNpiSelectedOption(id){
 return NPITypes.find((item)=> parseInt(id)===item.id);
}

export const checkValidation = (data, initialData) => {
  const isValidFirstName = data?.first_name?.value;
  const isValidLastName = data?.last_name?.value;
  const isValidDateOfBirth = data?.date_of_birth?.valid;
  const isValidPhone = isValidPhoneNumber(data);
  const isAddressValid = data?.location?.address1.value;
  const isStateValid = data?.location?.state?.id;
  const isCityValid = data?.location?.city?.id;
  const isZipValid = data?.location?.zip_code.valid;
  const isNpiNumberValid = data?.npi_number?.valid;
  const isDocumentValid = true; //!!data?.document?.value; // removed document for now
  const isLicenseNumberValid = data?.license_number?.valid;
  const isExpirationdateValid = data?.expiration_date?.valid;
  const isPescriptionAvailabilityValid = data?.prescription_availability;
  const isOwnInsurancesValid = !!data?.own_insurance;
  const isAcceptInsuranceValid = !!data?.accept_insurance;
  const isOtherCertificationFieldValid = data?.doctor_certifications && isOtherFieldValid(data?.doctor_certifications, data?.other_certification);
  const isOtherMedicalSchoolFieldValid = ((data?.medical_school?.name === ApplicationConstant.OTHER &&
    data?.other_medical_school) || data?.medical_school?.name !== ApplicationConstant.OTHER);
  const isInsuranceProviderRequired = ((data?.accept_insurance === 'Y' && (data?.insurance_provider.length > 0)) || data?.accept_insurance === 'N');
  const isOtherInsuranceProviderFieldValid = data?.accept_insurance === 'Y' ? isOtherFieldValid(data?.insurance_provider, data?.other_insurance_provider) : true;
  return (isValidFirstName && isValidLastName && isValidDateOfBirth &&
      isValidPhone && isAddressValid && isStateValid && isCityValid && isDocumentValid && isInsuranceProviderRequired &&
      isZipValid  && isNpiNumberValid && isLicenseNumberValid && isOtherCertificationFieldValid && isOtherMedicalSchoolFieldValid &&
      isOtherInsuranceProviderFieldValid && isPescriptionAvailabilityValid && isOwnInsurancesValid && isAcceptInsuranceValid &&
      isExpirationdateValid);
};

function isOtherFieldValid(data, otherData){
  return (data.length > 0
    ? (data?.findIndex(
        (item) => item?.name === ApplicationConstant.OTHER
      ) > -1 &&
        otherData) ||
      data?.findIndex(
        (item) => item?.name === ApplicationConstant.OTHER
      ) === -1
    : ApplicationConstant.TRUE);
}

export const getParsedData = (data, filename) => {
  let dob = getFormattedDate(data?.date_of_birth.value);
  let expDate = data?.expiration_date.value ? getFormattedDate(data?.expiration_date.value) : null;
  const parsedData = {
    first_name: data?.first_name.value,
    last_name: data?.last_name.value,
    date_of_birth: dob,
    preferred_name: data?.preferred_name.value,
    phone: data?.phone.value,
    state_licenses: Array.isArray(data?.state) ? data?.state?.map((o) => o.id) : '',
    npi_number: data?.npi_number.value,
    npi_type: data?.npi_type.id,
    license_number: data?.license_number.value,
    expiration_date: expDate,
    prescription_availability: data?.prescription_availability,
    see_patients: data?.see_patients,
    own_insurances: data?.own_insurance,
    accept_insurance: data?.accept_insurance,
    electronic_medical_record: data?.electronic_medical_record,
    location: {
      state_id: data?.location.state.id,
      city_id: data?.location.city.id,
      address1: data?.location.address1.value,
      address2: data?.location.address2.value,
      zip_code: data?.location.zip_code.value,
    },
  };
  if (!!data?.document) {
    parsedData.document = data?.document?.value;
    parsedData.document_name = filename; 
  }
  if(!!data?.profile_image){
    parsedData.profile_image = data?.profile_image?.value;
  }

  if(data?.medical_school?.id){
    parsedData.medical_school_id = data?.medical_school.id;
  }

  if(data?.other_medical_school){
    parsedData.other_medical_school= data?.other_medical_school;
  }

  if(data?.year_of_experience?.value){
    parsedData.year_of_exp = data?.year_of_experience.value;
  } else if(parsedData.year_of_exp === ApplicationConstant.NO_VALUE_STRING){
    parsedData.year_of_exp = ApplicationConstant.NO_VALUE_STRING;
  }

  if(data?.procedure?.length > ApplicationConstant.ZERO){
    parsedData.doctor_procedure = data?.procedure.map((o)=> o.id);
  } else if(data?.procedure === ApplicationConstant.NO_VALUE_STRING){
    parsedData.doctor_procedure = [];
  }

  if(data?.condition_treated?.length > ApplicationConstant.ZERO){
    parsedData.doctor_condition_treated = data?.condition_treated.map((o)=> o.id);
  } else if(data?.condition_treated === ApplicationConstant.NO_VALUE_STRING){
    parsedData.doctor_condition_treated = [];
  }

  if(data?.doctor_specializations?.length > ApplicationConstant.ZERO){
    parsedData.doctor_specializations = data?.doctor_specializations.map((o) => o.id);
  } else if(data?.doctor_specializations === ApplicationConstant.NO_VALUE_STRING){
    parsedData.doctor_specializations = [];
  }

  if(data?.user_facilities?.length > ApplicationConstant.ZERO){
    parsedData.user_facilities = data?.user_facilities.map((o) => o.id);
  } else if(data?.user_facilities === ApplicationConstant.NO_VALUE_STRING){
    parsedData.user_facilities = [];
  }

  if(data?.practice?.length > ApplicationConstant.ZERO){
    parsedData.doctor_pratice = data?.practice.map((o) => o.id);
  } else if(data?.practice === ApplicationConstant.NO_VALUE_STRING){
    parsedData.doctor_pratice = [];
  }
  if (
    data?.doctor_certifications?.length >
    ApplicationConstant.ZERO
  ) {
    parsedData.doctor_certifications =
    data.doctor_certifications.map((o) => {
        if (o.name.toLowerCase() === ApplicationConstant.OTHER_LOWER_CASE) {
          return {
            id: o.id,
            other_certification: data.other_certification,
          };
        } else {
          return { id: o.id, other_certification: "" };
        }
      });
  }

  if (
    data?.insurance_provider?.length > ApplicationConstant.ZERO
  ) {
    parsedData.doctor_insurance_provider =
      data.insurance_provider.map((o) => {
        if (o.name.toLowerCase() === ApplicationConstant.OTHER_LOWER_CASE) {
          return {
            id: o.id,
            name: o.name,
            other_insurance_provider:
              data.other_insurance_provider,
          };
        } else {
          return { id: o.id, name: o.name, other_insurance_provider: "" };
        }
      });
  }

  return parsedData;
}

export const isValidPhoneNumber = (data) => {
  const phoneValue = data?.phone?.value || '';
  if (phoneValue && phoneValue.length === ApplicationConstant.TEN) {
    return true;
  }
  return false;
};

export const isValidNpiNumber = (data) => {
  const npiNumber = data?.npi_number?.value || '';
  if (npiNumber && npiNumber.length === ApplicationConstant.TEN) {
    return true;
  }
  return false;
};
