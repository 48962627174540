import React, { useState, useEffect } from 'react';
import useDocumentTitle from '../../../../utils/DocumentTitle';
import SpinnerComponent from '../../../common/spinner/SpinnerComponent';
import { ToastContainer, toast, Slide } from 'react-toastify';
import HospitalProfile from './HospitalProfile';
import { PageTitles } from '../../../../constants/application.constant';
import ApiConstant from '../../../../constants/ApiConstant';
import axiosInstance from '../../../../axios/axiosInstance';

function HospitalProfileView() {
  useDocumentTitle(PageTitles.HOSPITAL_PROFILE);
  const [oProfile, setProfile] = useState(null);
  const [isLoading, setLoader] = useState(false);

  const fetchHospitalProfile = async () => {
    setLoader(true);
    await axiosInstance
      .get(`${ApiConstant.HOSPITAL_DASHBOARD}`)
      .then((response) => {
        if (response && response.data) {
          const hospitalProfileData = response.data.data || {};
          setProfile(hospitalProfileData);
        }
      })
      .catch((error) => {
        toast.error(error || '');
      });
    setLoader(false);
  };

  useEffect(() => {
    fetchHospitalProfile();
  }, []);

  return (
    <>
      {isLoading && <SpinnerComponent />}
      <div className="middle-content view-profile">
        <div className="container">
          <HospitalProfile oHospitalProfile={oProfile} />
        </div>
      </div>
      <ToastContainer autoClose={4000} hideProgressBar transition={Slide} />
    </>
  );
}

export default HospitalProfileView;
