import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import axiosInstance from '../../../axios/axiosInstance';
import ApiConstant from '../../../constants/ApiConstant';
import { toast } from 'react-toastify';
import { CustomDateCell } from '../../common/calendarElements/CalendarElements';
import 'react-big-calendar/lib/sass/styles.scss';

const localizer = momentLocalizer(moment);
const PatientCalendar = () => {
  const [currentDate, setCurrentDate] = useState(moment(new Date()).format('YYYY-MM'));
  const [calanderData, setCalanderData] = useState([]);

  useEffect(() => {
    axiosInstance.get(`${ApiConstant.PATIENT_APPOINTMENTS}?date=${currentDate}`).then(
      (res) => {
        setCalanderData(res.data.data);
      },
      (error) => {
        toast.error(error || '');
      }
    );
  }, ['', currentDate]);

  const getTitle = (appointment_status_id) => {
    switch (appointment_status_id) {
      case 1:
        return 'Pending';
      case 2:
        return 'Approved';
      case 3:
        return 'Completed';
      case 4:
        return 'Time Changed';
      case 5:
        return 'Cancelled By Doctor';
      case 6:
        return 'Cancelled By user';
      default:
        return 'failed';
    }
  };

  const getEvents = () => {
    if (calanderData && calanderData.length) {
      return calanderData.map((event) => {
        return {
          title: getTitle(event.appointment_status_id),
          start: new Date(moment(event.start_date_time).format('YYYY,M,DD')),
          end: new Date(moment(event.end_date_time).format('YYYY,M,DD')),
        };
      });
    } else return [];
  };

  return (
    <>
      <div className='heading-link'>
        <h5>My Calendar</h5>
      </div>
      <Calendar
        selectable
        localizer={localizer}
        events={getEvents()}
        views={['month', 'week']}
        defaultDate={moment().toDate()}
        timeslots={4}
        longPressThreshold={10}
        components={{
          dateCellWrapper: CustomDateCell,
        }}
        onNavigate={(date) => {
          setCurrentDate(moment(date).format('YYYY-MM'));
        }}
      />
    </>
  );
};

export default PatientCalendar;
