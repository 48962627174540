import { RegExConstant } from '../../../constants/application.constant';

export const validatePassword = (oPasswordState) => {
  const password = oPasswordState.password || '';
  const confirmPassword = oPasswordState.password_confirmation || '';
  if (password && confirmPassword && RegExConstant.PASSWORD_REGEX.test(password) && password === confirmPassword) {
    return true;
  }
  return false;
};

export const isValidConfirmPassword = (oPasswordState) => {
  const password = oPasswordState.password || '';
  const confirmPassword = oPasswordState.password_confirmation || '';
  if (confirmPassword && password !== confirmPassword) {
    return false;
  }
  return true;
};

export const isValidEmail = (email) => {
  if (email && !RegExConstant.EMAIL_REG.test(email)) {
    return false;
  }
  return true;
};
