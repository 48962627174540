import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PaymentHistoryDetailList from './PaymentHistoryDetailList/PaymentHistoryDetailList';
import { ApplicationConstant } from './../../../../../constants/application.constant';

const PaymentHistoryListItem = (props) => {
  const { item, openListDetailTab, setOpenListDetailTab, selectListItems } = props;
  const [ monthDetailListView, setMonthDetailListView] = useState(false);

  const toggleMonthDetailListView = () => {
    setMonthDetailListView(!monthDetailListView);
    setOpenListDetailTab(true)
  }

  const onSelectListRow = (monthYear) => {
    selectListItems(monthYear);
  }

  useEffect(()=>{
    !openListDetailTab && setMonthDetailListView(false);
  },[openListDetailTab])

  return (
    <div className="list">
      <div className="month-row">
        <div className="month">
          <div className="check-btn m-0 mt--15">
            <label className="label--checkbox flat d-flex">
              <input
                type="checkbox"
                className="checkbox"
                id={`monthView-${item?.month_year}`}
                onChange={()=>{onSelectListRow(item?.month_year)}}
                checked={item?.checked}
              />
              <label className="font-16 font-500" htmlFor={`monthView-${item?.month_year}`}></label>
            </label>
          </div>
        <span className="ml-10">{moment(`${item?.year}-${item?.month}-01`).format('MMMM YYYY')}</span>
        </div>
        <div className="visits">
          <span className="visit-count">{item?.total_visit || 0} total {item?.total_visit > ApplicationConstant.ONE ? 'visits' : 'visit'}</span>
          <div className="action-status d-flex row-action-status">
            <a className="btn-white-outline ml-4" onClick={toggleMonthDetailListView}>
              <span className="material-icons md-dark md-18">{openListDetailTab && monthDetailListView ? 'expand_less' : 'expand_more'}</span>
            </a>
          </div>
        </div>
      </div>
      {openListDetailTab && monthDetailListView &&
        <PaymentHistoryDetailList
          listDate={`${item?.year}-${item?.month}`}
          totalEarnings={item?.total_earnings}
          totalRefund={item?.total_refund_amount}
          isOpenMonthDetailListView={monthDetailListView}
        />
      }
    </div>
  )
}

export default PaymentHistoryListItem;
