import React from 'react';
import { toast } from 'react-toastify';
import { Image } from './../../../common/image/Image';
import axiosInstance from '../../../../axios/axiosInstance';
import ApiConstant from '../../../../constants/ApiConstant';
import { ApplicationConstant } from '../../../../constants/application.constant';
import { downloadFileFromBlob } from '../../../../utils/helper';
import { convertUtcToLocalTime } from '../../../../utils/TimezoneUtils';

function PaymentHistoryItem(props) {
  const { item } = props

  const handlePdf = () => {
    fetchReceipt(item.id, ApplicationConstant.PDF_FILES);
  }

  const fetchReceipt = async (id,fileType) => {
    await axiosInstance
      .get(`${ApiConstant.PATIENT_PAYMENT_HISTORY_EXPORT}`, {responseType : 'blob',
        headers: {'Content-Type': 'application/pdf'},
        params: { payment_id: item.id, type: fileType },
      })
      .then((response) => response.data)
      .then((blob)=>{
        const fileName = `PaymentHistory ${item.payment_date}`;
        downloadFileFromBlob(blob, fileName);
      })
      .catch((error) => {
        toast.error(error || '');
      });
  };
    return (
      <>
        <div className="divRow">
          <div className="divCell" data-label="Name">
          <div className="img-name">
            <div className="profile-img">
              <Image src={item?.profile_image || ''} alt='Profile Image' />
            </div>
            <span>{item?.first_name}</span>
          </div>

          </div>
          <div className="divCell" data-label="Source">
            { item?.payment_type }
          </div>
          <div className="divCell" data-label="Date">
          {convertUtcToLocalTime(item?.payment_date, 'ddd MMM D, YYYY')}
          </div>
          <div className="divCell" data-label="Consultation Fee">
            { `${"$"}${item?.amount} `}
          </div>
          <div className="divCell" data-label="Refund Amount">
            { `${item?.refund_amount === null ? `${"$"}${ApplicationConstant.ZERO}`: `${"-"}${" "}${"$"}${item?.refund_amount}` } `}
          </div>
          <div className="divCell" data-label="Action">
            <div className="action-status d-flex row-action-status">
              <a className="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8">
                <span className="material-icons md-dark md-18" onClick={handlePdf}>
                  picture_as_pdf
                </span>
              </a>
            </div>
          </div>
        </div>
      </>
    );
}

export default PaymentHistoryItem;



