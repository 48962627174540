import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import GoBack from '../../../../common/back/GoBack';
import { ApplicationConstant } from '../../../../../constants/application.constant';
import { PatientAppointmentSidebarMenuItems } from './PatientAppointmentSidebarItems';

function PatientAppointmentSidebar() {
  const { pathname } = useLocation();
  const [navOpen, setNavOpen] = useState(ApplicationConstant.FALSE);

  return (
    <div>
      <div className="profile-sidebar">
        <GoBack heading={'Appointments'} />
        <div className={navOpen ? 'edit-nav open' : 'edit-nav'}>
          <ul>
            {PatientAppointmentSidebarMenuItems.map((item, index) => {
              return (
                <li
                  onClick={() => {
                    setNavOpen(!navOpen);
                  }}
                  key={index}
                  className={pathname === item.to ? 'active' : ''}
                >
                  <Link to={item.to} replace>
                    <span className={item.iconClass}>{item.icon}</span>
                    <span className="name">{item.name}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
          <a
            className="nav-toggal"
            onClick={() => {
              setNavOpen(!navOpen);
            }}
          >
            <span className="material-icons">{navOpen ? 'chevron_left' : 'chevron_right'}</span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default PatientAppointmentSidebar;
