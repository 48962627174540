import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { CardStyle, CardLoader } from './PaymentFormUtils';
import { toast } from 'react-toastify';
import axiosInstance from '../../axios/axiosInstance';
import ApiConstant from '../../constants/ApiConstant';

const PaymentForm = (props) => {
  const { bookAppointemnt, userDetail, paymentPayload } = props;
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [loader, setLoader] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const stripe = useStripe();
  const elements = useElements();


  const fetchPaymentIntent = async () => {
    setLoader(true);
    await axiosInstance.post(ApiConstant.STRIPE_PAYMENT_INTENT, paymentPayload).then(
    (response) => {
      if(response && response.data) {
        const clientSecretKey= response.data?.data?.clientSecret;
        setClientSecret(clientSecretKey);
        setLoader(false);
      }
    }).catch((error) => {toast.error(error || '')});
  }

  const handleChange = async (event) => {
    setDisabled(!event.complete);
    setError(event.error ? event.error.message : '');
  };

  const handlePay = async (e) => {
    e.preventDefault();
    setProcessing(true);
    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: `${userDetail?.user?.first_name}${' '}${userDetail?.user?.last_name}`,
          email: userDetail?.user?.email,
        }
      }
    });
    const paymentDetail = {
      charge_id: payload?.paymentIntent?.id || '',
      client_secret: payload?.paymentIntent?.client_secret || '',
      amount:payload?.paymentIntent?.amount || ''
    }
    if (payload.error) {
      toast.error(`Payment failed: ${payload.error.message}`);
      setProcessing(false);
    } else {
      if(payload?.paymentIntent?.status === 'succeeded') {
        bookAppointemnt(paymentDetail);
      }
      setError(null);
      setProcessing(false);
      setSucceeded(true);
    }
  };

  useEffect(() => {
    fetchPaymentIntent();
  }, []);

  return (
    <>
    {loader ? <CardLoader /> :
      <form className="stripeForm" id="payment-form" onSubmit={handlePay}>
        <CardElement id="card-element" options={CardStyle} onChange={handleChange} />
        <button disabled={processing || disabled || succeeded} id="submit">
          <span id="button-text">
            {processing ? <div className="spinner" id="spinner"></div> : "Pay Now" }
          </span>
        </button>
        {error && <div className="card-error" role="alert">{error}</div>}
      </form>
      }
    </>
  );
}

export default PaymentForm;
