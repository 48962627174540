import React from 'react';
import PageLogo from '../../common/logo/PageLogo';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';

function TokenExpired() {
  const history = useHistory();
  const handleClick = (e) => {
    e.preventDefault();
    history.push('/');
  };

  return (
    <div className="full-bg-color">
      <div className="container">
        <div className="landing-ui">
          <div className="forgot-password">
            <form className="w-100">
              <div className="w-350">
                <div className="login-title">
                  <PageLogo />
                  <h1>Reset Password</h1>
                  <h2>Token has been expired.</h2>
                </div>
                <div className="col-sm-12">
                  <Button className="w-100 mb-20" color="primary" onClick={handleClick}>
                    Back To Login
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TokenExpired;
