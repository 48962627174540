import React, {useEffect, useState} from 'react';
import axiosInstance from '../../../../../../axios/axiosInstance';
import ApiConstant from '../../../../../../constants/ApiConstant';
import InputElement from '../../../../../common/formElements/InputElement';
import { toast } from 'react-toastify';
import update from "immutability-helper";
import FormButton from "../../../../../common/buttons/FormButton";
import { getUserInfo } from "../../../../../../utils/helper";
import SignatureCanvas from "react-signature-canvas";
import SpinnerComponent from "../../../../../common/spinner/SpinnerComponent";

function DoctorSuperbill(props) {
  const [isLoading, setLoading] = useState(false);

  const CodeState = { code: '', description: '' };
  const [icdCode, setIcdCode] = useState(CodeState);
  const [cptCode, setCptCode] = useState(CodeState);

  const [timer, setTimer] = useState(null)

  useEffect(() => {
    fetchAppointmentSuperbill();
  }, []);

  // find codes
  const findIcdCode = async (code) => {
    setLoading(true);
    await axiosInstance.get(`${ApiConstant.APPOINTMENT_SUPERBILL_CODES_SEARCH}`, {params: {code: code, type: 'icd' }})
        .then((response) => {
          if (response && response.data && response.data.data && response.data.data.code) {
            setIcdCode(update(icdCode, {
              code: { $set: response.data.data.code },
              description: { $set: response.data.data.description }
            }));
          }
          setLoading(false);
        })
        .catch((error) => {
          toast.error(error || '')
          setLoading(false);
        });
  };

  const onIcdCodeChange = (e) => {
    setIcdCode(update(icdCode, { code: { $set: e.target.value } }));

    clearTimeout(timer)
    const newTimer = setTimeout(() => {
      findIcdCode(e.target.value)
    }, 750)
    setTimer(newTimer)
  };

  const onIcdDescriptionChange = (e) => {
    setIcdCode(update(icdCode, { description: { $set: e.target.value } }));
  }

  const findCptCode = async (code) => {
    setLoading(true);
    await axiosInstance.get(`${ApiConstant.APPOINTMENT_SUPERBILL_CODES_SEARCH}`, {params: {code: code, type: 'cpt' }})
        .then((response) => {
          if (response && response.data && response.data.data && response.data.data.code) {
            setCptCode(update(cptCode, {
              code: { $set: response.data.data.code },
              description: { $set: response.data.data.description }
            }));
          }
          setLoading(false);
        })
        .catch((error) => {
          toast.error(error || '')
          setLoading(false);
        });
  };

  const onCptCodeChange = (e) => {
    setCptCode(update(cptCode, { code: { $set: e.target.value } }));

    clearTimeout(timer)
    const newTimer = setTimeout(() => {
      findCptCode(e.target.value)
    }, 750)
    setTimer(newTimer)
  };

  const onCptDescriptionChange = (e) => {
    setCptCode(update(cptCode, { description: { $set: e.target.value } }));
  }

  // signature
  let signatureCanvas = null;

  function signSignature(e) {
    e.preventDefault();

    clearSignature(e);

    let user = getUserInfo();
    let name = user.first_name + ' ' + user.last_name;

    let ctx = document.querySelector('canvas').getContext('2d');
    ctx.font      = "italic 65px Freestyle Script";
    ctx.fillStyle = "#000000";
    ctx.fillText(name, 50, 100);
  }

  function clearSignature(e) {
    e.preventDefault();
    signatureCanvas.clear();
  }

  // submit
  const handleSubmit = () => {
    setLoading(true);

    const data = {
      icdCode : icdCode,
      cptCode : cptCode,
      signature : signatureCanvas.toDataURL(),
      appointment_id: props.appointmentId,
    };

    axiosInstance.post(ApiConstant.APPOINTMENT_SUPERBILL_SAVE, data).then(
        (response) => {
          toast.success(response?.data?.message);
          setLoading(false);
          fetchAppointmentSuperbill();
        },
        (_error) => {
          toast.error(_error);
          setLoading(false);
        }
    );
  };

  const fetchAppointmentSuperbill = async () => {
    setLoading(true);
    await axiosInstance
        .get(`${ApiConstant.APPOINTMENT_SUPERBILL}`, { params: { appointment_id: props.appointmentId } })
        .then((res) => {
            setIcdCode(update(icdCode, {
              code: { $set: res.data.data.icdCode.code},
              description: { $set: res.data.data.icdCode.description }
            }));

            setCptCode(update(cptCode, {
              code: { $set: res.data.data.cptCode.code},
              description: { $set: res.data.data.cptCode.description }
            }));

            let ctx = document.querySelector('canvas').getContext('2d');
            var image = new Image();
            image.onload = function() {
                ctx.drawImage(image, 0, 0);
            };
            image.src = res.data.data.signature;

            setLoading(false);
        })
        .catch((error) => {
          toast.error(error || '');
          setLoading(false);
        });
  };

  return (
    <>
      {isLoading && <SpinnerComponent />}
      {
        <div id={'superbillDiv'} className="body document-tab-body">
            <InputElement
              className="col-sm-5 pl-0 pr-0"
              type="text"
              name="icd_code"
              placeholder={'Please enter diagnosis code'}
              label={'ICD Code*'}
              onChange={onIcdCodeChange}
              value={icdCode.code || ''}
            />
            <InputElement
                className="col-sm-7 pl-0 pr-0"
                type="text"
                name="icd_description"
                placeholder={''}
                label={'ICD Description'}
                onChange={onIcdDescriptionChange}
                value={icdCode.description || ''}
            />
            <InputElement
                className="col-sm-5 pl-0 pr-0"
                type="text"
                name="diagnosis2"
                placeholder={'Please enter CPT code'}
                label={'CPT Code*'}
                onChange={onCptCodeChange}
                value={cptCode.code || ''}
            />
            <InputElement
                className="col-sm-7 pl-0 pr-0"
                type="text"
                name="diagnosis_description"
                placeholder={''}
                label={'CPT Description'}
                onChange={onCptDescriptionChange}
                value={cptCode.description || ''}
            />
            <h4>Signature</h4>
            <div className="col-sm-12 pl-0 pr-0">
                <a href='#' onClick={clearSignature} className="m-0">Clear</a>
                <a href='#' onClick={signSignature} className="ml-12">Auto sign</a>
            </div>
            <div className="col-sm-12 pl-0 pr-0">
                <SignatureCanvas
                    ref={SignatureCanvas => (signatureCanvas = SignatureCanvas)}
                    penColor='black'
                    canvasProps={{width: 400, height: 200, className: 'sigCanvas'}}
                />
            </div>
            <div className="col-sm-12 p-0 action-button justify-content-end ">
              <div className="btn-sec ">
                <FormButton
                    buttonLabel="SUBMIT"
                    buttonClassName="btn btn-primary"
                    handleClick={handleSubmit}
                />
              </div>
            </div>
        </div>
      }
    </>
  );
}

export default DoctorSuperbill;
