import React from 'react';
import { Button } from 'reactstrap';

const PatientSignUpFooter = ({ maxStep, handleSave, getStepTwoValidity, handleNext, val, getValidity, isPrev, handlePrev}) => {
  return (
    <footer>
      <div className="container">
        <div className="footer-btn">
          <div className="prev">
            {isPrev &&
                <Button color="secondary" onClick={handlePrev}>
                  Previous{' '}
                </Button>
              }
          </div>
          <div className="next">
            {maxStep === val ? (
              <Button color="primary" disabled={!getStepTwoValidity().isValid} onClick={handleSave}>
                Save{' '}
              </Button>
            ) : (
              <Button disabled={!getValidity().isValid} color="primary" onClick={handleNext}>
                Next
              </Button>
            )}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default PatientSignUpFooter;
