import React from 'react';
import { useHistory } from 'react-router';

function GoBack(props) {
  const { targetPath, heading } = props;
  const history = useHistory();

  const handleClick = () => {
    if (targetPath) {
      history.replace(targetPath);
    } else {
      history.goBack();
    }
  };

  return (
    <div className="back-link">
      <a className="btn-white-outline pr-7 pl-7 d-flex align-items-center mr-15" onClick={handleClick}>
        <span className="material-icons md-dark md-24">arrow_back</span>
      </a>
      <h1>{heading}</h1>
    </div>
  );
}

export default GoBack;
