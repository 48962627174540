import React from 'react';
import DoctorSignUpPage from '../components/user-signup/doctor/DoctorSignUpPage';
import DoctorSignUpProvider from '../contexts/doctor-signup/DoctorSignUpProvider';
import useDocumentTitle from '../utils/DocumentTitle';
import { PageTitles } from '../constants/application.constant';

function DoctorSignupPage() {
  useDocumentTitle(PageTitles.DOCTOR_SIGN_UP);

  return (
    <div className="step-page">
      <DoctorSignUpProvider>
        <DoctorSignUpPage />
      </DoctorSignUpProvider>
    </div>
  );
}

export default DoctorSignupPage;
