import React from 'react';
import { ApplicationConstant } from '../../../constants/application.constant';

function HospitalProviderItem(props) {
  const { oProvider } = props;

  function getStatus(statusId) {
    if (statusId && statusId === ApplicationConstant.ONE) {
      return ApplicationConstant.ACTIVE;
    }
    return ApplicationConstant.INACTIVE;
  }
  return (
  <div class="divRow">
    <div class="divCell d-none">
      <div className="check-btn m-0 ">
        <label className="label--checkbox flat d-flex">
          <input type="checkbox" className="checkbox" id="1" />
          <label className="font-16 font-500" htmlFor="1"></label>
        </label>
      </div>
    </div>
    <div class="divCell" data-label="First Name">{oProvider?.user?.first_name || '-'}</div>
    <div class="divCell" data-label="Last Name">{oProvider?.user?.last_name || '-'}</div>
    <div class="divCell" data-label="License Number">{oProvider?.license_number || '-'}</div>
    <div class="divCell" data-label="NPI Number">{oProvider?.npi_number || '-'}</div>
    <div class="divCell" data-label="State Name">{oProvider?.location?.state_name || '-'}</div>
    <div class="divCell" data-label="Email Address">{oProvider?.user?.email || '-'}</div>
    <div class="divCell" data-label="Phone Number">{oProvider?.user?.phone || '-'}</div>
    <div class="divCell" data-label="School">
      {(oProvider?.medical_school?.name.toLowerCase() === ApplicationConstant.OTHER_LOWER_CASE ? 
      oProvider?.other_medical_school : oProvider?.medical_school?.name || '-' ) 
      
      }
      </div>
    <div class="divCell" data-label="Status">{getStatus(oProvider?.user?.status_id)}</div>
  </div>
  );
}

export default HospitalProviderItem;
