import React from 'react'
import { Link } from "react-router-dom";

function PrivacyPolicy() {
  return (
    <div className="middle-content terms-policy">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="back-link">
                <h1>equalityMD Privacy Policy <span>Effective date of terms of service: Nov 4, 2019</span></h1>
              </div>
              <p>
                Morbi ligula ipsum, dictum at molestie quis, varius a ex. Donec interdum porta turpis, ut pharetra erat aliquam sagittis. Nam sapien lorem, porttitor id nisl ac, gravida semper ex. Duis at tempus sapien. Duis volutpat neque nisi, id rhoncus tortor cursus ut. Suspendisse potenti. Nullam sit amet turpis eget leo fermentum auctor. Cras molestie quam quam. Morbi ligula ipsum, dictum at molestie quis, varius a ex. Donec interdum porta turpis, ut pharetra erat aliquam sagittis. Nam sapien lorem, porttitor id nisl ac, gravida semper ex. Duis at tempus sapien. Duis volutpat neque nisi, id rhoncus tortor cursus ut. Suspendisse potenti. Nullam sit amet turpis eget leo fermentum auctor. Cras molestie quam quam.
              </p>
              <p>
                <strong>Ut maximus massa eget metus lacinia commodo. Duis nec neque consectetur, sollicitudin metus non, aliquam lacus. Donec venenatis nunc in porta pulvinar. Donec eu nibh vitae lectus luctus consectetur. Praesent tincidunt volutpat mi, quis mattis nulla condimentum </strong>
                id. Pellentesque quis sapien in leo aliquam rhoncus. Aenean efficitur ipsum velit, quis volutpat dui pulvinar a. Phasellus tortor augue, consectetur at rutrum et, pretium a urna. Phasellus consequat laoreet mauris, dignissim posuere dolor dictum vel.
              </p>
              <p>Click on any of the links below to go straight to one of the following sections.</p>
              <ul className="link-list">
                <li>1.<Link to=""> Our Services</Link></li>
                <li>2.<Link to=""> When does this Privacy Policy apply?</Link></li>
                <li>3.<Link to=""> What information do we collect and receive?</Link></li>
                <li>4.<Link to=""> How do we use your information?</Link></li>
                <li>5.<Link to=""> What are your rights regarding your information?</Link></li>
              </ul>
              <p><strong>1. Our Services</strong></p>
              <p>
              Etiam vestibulum vulputate auctor. Aenean sit amet turpis placerat, condimentum lorem malesuada, consequat felis. Cras fermentum nisi purus, id vulputate arcu sodales sit amet. Sed tristique lorem nec efficitur bibendum. In quis vulputate risus. Vestibulum nibh justo, sodales id orci quis, placerat porttitor dolor. Nunc scelerisque tincidunt nibh sed tempor. Aenean et magna velit. Curabitur ac felis ac odio pretium gravida sed vel elit. Vivamus ac justo accumsan magna pulvinar fermentum eu non mi.
              </p>

              <p><strong>2. When does this Privacy Policy apply?</strong></p>
              <p>Etiam vestibulum vulputate auctor. Aenean sit amet turpis placerat, condimentum lorem malesuada, consequat felis. Cras fermentum nisi purus, id vulputate arcu sodales sit amet. Sed tristique lorem nec efficitur bibendum. In quis vulputate risus. Vestibulum nibh justo, sodales id orci quis, placerat porttitor dolor. Nunc scelerisque tincidunt nibh sed tempor. Aenean et magna velit. Curabitur ac felis ac odio pretium gravida sed vel elit. Vivamus ac justo accumsan magna pulvinar fermentum eu non mi.</p>
              <p><strong>3. What information do we collect and receive?</strong></p>
              <p>Etiam vestibulum vulputate auctor. Aenean sit amet turpis placerat, condimentum lorem malesuada, consequat felis. Cras fermentum nisi purus, id vulputate arcu sodales sit amet. Sed tristique lorem nec efficitur bibendum. In quis vulputate risus. Vestibulum nibh justo, sodales id orci quis, placerat porttitor dolor. Nunc scelerisque tincidunt nibh sed tempor. Aenean et magna velit. Curabitur ac felis ac odio pretium gravida sed vel elit. Vivamus ac justo accumsan magna pulvinar fermentum eu non mi.</p>
              <p><strong>4. How do we use your information?</strong></p>
              <p>Etiam vestibulum vulputate auctor. Aenean sit amet turpis placerat, condimentum lorem malesuada, consequat felis. Cras fermentum nisi purus, id vulputate arcu sodales sit amet. Sed tristique lorem nec efficitur bibendum. In quis vulputate risus. Vestibulum nibh justo, sodales id orci quis, placerat porttitor dolor. Nunc scelerisque tincidunt nibh sed tempor. Aenean et magna velit. Curabitur ac felis ac odio pretium gravida sed vel elit. Vivamus ac justo accumsan magna pulvinar fermentum eu non mi.</p>
              <p><strong>5. What are your rights regarding your information?</strong></p>
              <p>Etiam vestibulum vulputate auctor. Aenean sit amet turpis placerat, condimentum lorem malesuada, consequat felis. Cras fermentum nisi purus, id vulputate arcu sodales sit amet. Sed tristique lorem nec efficitur bibendum. In quis vulputate risus. Vestibulum nibh justo, sodales id orci quis, placerat porttitor dolor. Nunc scelerisque tincidunt nibh sed tempor. Aenean et magna velit.</p>
              <p><strong></strong></p>
              <p></p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy
