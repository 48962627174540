import React from 'react';
import PatientRegistrationPage from '../components/user-signup/patient/PatientRegistrationPage';
import PatientSignUpProvider from '../contexts/patient-signup/PatientSignUpProvider';
import useDocumentTitle from '../utils/DocumentTitle';
import { PageTitles } from '../constants/application.constant';

function PatientSignupPage() {
  useDocumentTitle(PageTitles.PATIENT_SIGN_UP);

  return (
    <div className="step-page">
      <PatientSignUpProvider>
        <PatientRegistrationPage />
      </PatientSignUpProvider>
    </div>
  );
}

export default PatientSignupPage;
