import React from 'react';
import { Link } from 'react-router-dom';
import { Image } from './../../common/image/Image';
import DoctorRecentActivity from './DoctorRecentActivity';
import { targetedId, profileKey, ApplicationConstant } from '../../../constants/application.constant';
import ProfileTooltip from '../../common/profileElements/ProfileTooltip';
import {  RouteConstants } from '../../../constants/application.constant';

function DoctorProfile(props) {
  const { oDoctorProfile } = props;

  return (
    <div>
      <div className="profile-info">
        <div className="row">
          <div className="col-sm-12 col-lg-8">
            <div className="box">
              <div className="user-info">
                <div className="profile-img">
                  <Image
                    src={oDoctorProfile?.doctor_profile?.profile_image || ""}
                    alt="Profile image"
                  />
                </div>
                <div className="head">
                  <div className="title-text mt-5">
                    <h1>
                      {oDoctorProfile?.doctor_profile?.first_name || "-"}
                      &nbsp;{oDoctorProfile?.doctor_profile?.last_name || ""}
                    </h1>
                    <h2>
                      {oDoctorProfile?.doctor_profile?.doctor_specializations
                        ?.length
                        ? oDoctorProfile?.doctor_profile
                            ?.doctor_specializations[ApplicationConstant.ZERO]
                            .name
                        : "-"}
                    </h2>
                  </div>
                  <div className="action-status">
                    <Link
                      to={RouteConstants.DOCTOR_ACCOUNT_INFORMATION}
                      className="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8">
                      <span className="material-icons md-dark md-18">
                        mode_edit
                      </span>
                    </Link>
                    <Link
                      to={RouteConstants.DOCTOR_PROFILE}
                      class="btn-white-outline text-uppercase">
                      View Profile
                    </Link>
                  </div>
                </div>
              </div>
              <div className="profile-info">
                <div className="row">
                  <div className="col-md-4 col-sm-6">
                    <label>Pronouns</label>
                    <div className="value more-items">
                      {oDoctorProfile?.doctor_profile?.user_pronouns?.length
                        ? ProfileTooltip(
                            oDoctorProfile.doctor_profile?.user_pronouns,
                            targetedId.DOCTOR,
                            profileKey.TITLE,
                            profileKey.OTHER_PRONOUN
                          )
                        : "-"}
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <label>City</label>
                    <div className="value">
                      {oDoctorProfile?.doctor_profile?.city || "-"}
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <label>State</label>
                    <div className="value">
                      {oDoctorProfile?.doctor_profile?.state || "-"}
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <label>Age</label>
                    {oDoctorProfile?.doctor_profile?.age ? (
                      <div className="value">
                        {oDoctorProfile?.doctor_profile?.age} years
                      </div>
                    ) : (
                      <div>-</div>
                    )}
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <label>Race</label>
                    <div className="value more-items">
                      {oDoctorProfile?.doctor_profile?.user_races?.length
                        ? ProfileTooltip(
                            oDoctorProfile.doctor_profile?.user_races,
                            targetedId.DOCTOR,
                            profileKey.NAME,
                            profileKey.OTHER_RACE
                          )
                        : "-"}
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <label>Ethnicity</label>
                    <div className="value">
                      {oDoctorProfile?.doctor_profile?.ethnicity || "-"}
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <label>Language Spoken</label>
                    <div className="value more-items">
                      {oDoctorProfile?.doctor_profile?.user_languages?.length
                        ? ProfileTooltip(
                            oDoctorProfile.doctor_profile?.user_languages,
                            targetedId.DOCTOR,
                            profileKey.NAME,
                            profileKey.OTHER_LANGUAGE
                          )
                        : "-"}
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <label>Gender Identity</label>
                    <div className="value more-items">
                      {oDoctorProfile?.doctor_profile?.user_identifications
                        ?.length
                        ? ProfileTooltip(
                            oDoctorProfile.doctor_profile?.user_identifications,
                            targetedId.DOCTOR,
                            profileKey.TITLE,
                            profileKey.OTHER_IDENTIFICATION
                          )
                        : "-"}
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6">
                    <label>Sexual Orientation</label>
                    <div className="value more-items">
                      {oDoctorProfile?.doctor_profile?.user_sexual_orientations
                        ?.length
                        ? ProfileTooltip(
                            oDoctorProfile.doctor_profile
                              ?.user_sexual_orientations,
                            targetedId.DOCTOR,
                            profileKey.NAME,
                            profileKey.OTHER_SEXUAL_ORIENTATION
                          )
                        : "-"}
                    </div>
                  </div>
                </div>
                <h4>
                  Member Since:{" "}
                  <span>
                    {oDoctorProfile?.doctor_profile?.member_since || "-"}
                  </span>
                </h4>
              </div>
            </div>
          </div>
          <DoctorRecentActivity />
        </div>
      </div>
    </div>
  );
}

export default DoctorProfile;
