import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { RouteConstants } from '../../../constants/application.constant';

export const CommonFooter = () => {
  const { pathname } = useLocation();

  return (
    <div className="login-footer">
      <div className="container d-flex justify-content-between">
        <p>Copyright © 2021 equalityMD. All right reserved.</p>
        <ul>
          <li className={pathname === RouteConstants.FAQ ? 'active' : ''}>
            <Link to={RouteConstants.FAQ}>FAQs</Link>
          </li>
          <li className={pathname === RouteConstants.CONTACT_US ? 'active' : ''}>
            <Link to={RouteConstants.CONTACT_US}>Contact Us</Link>
          </li>
          <li className={pathname === RouteConstants.PRIVACY_POLICY ? 'active' : ''}>
            <Link to={RouteConstants.PRIVACY_POLICY}>Privacy Policy</Link>
          </li>
          <li className={pathname === RouteConstants.TERMS_SERVICE ? 'active' : ''}>
            <Link to={RouteConstants.TERMS_SERVICE}>Terms of Service</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
