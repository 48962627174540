import React, { useContext } from 'react';
import { ApplicationConstant } from '../../../constants/application.constant';
import UserRegistrationContext from '../../../contexts/user-registration/UserRegistrationContext';
import { getActiveStateAndValidity } from '../../../utils/PatientProfileUtil';

const StepButton = (stepNum, key, oUserRegistration, handleStepClick) => {
    return <div key={key} className={`box ${(getActiveStateAndValidity(oUserRegistration, stepNum).isActive ? 'active' : '') ||
            (getActiveStateAndValidity(oUserRegistration, stepNum).isValid ? 'check' : '')}`}>
            <div className="step-content">
                <span className="material-icons" data-step={stepNum} onClick={handleStepClick}>check</span>
                <span className="number" data-step={stepNum} onClick={handleStepClick}>{stepNum}</span>
            </div>
        </div>
}

function PatientProfileStepper({ handleStepClick }) {
    const [oUserRegistration] = useContext(UserRegistrationContext);
    return (
        <div className="stepper" style={{ cursor: 'pointer' }}>
            <div className="content">
                {Array.from({length: ApplicationConstant.NINE}, (_, i) => i + 1).map((item, index)=>StepButton(item, index,oUserRegistration, handleStepClick))}
            </div>
        </div>
    );
}

export default PatientProfileStepper;
