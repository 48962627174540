import React, { useContext, useState } from 'react';
import FormCheckBox from '../../common/formElements/FormCheckBox';
import { FormGroup, Input, InputGroup } from 'reactstrap';
import UserRegistrationContext from '../../../contexts/user-registration/UserRegistrationContext';
import update from 'immutability-helper';
import { ApplicationConstant } from '../../../constants/application.constant';
import SkipToNextProfile from './SkipToNextProfile';
import { EMPTY_SEARCH } from './../../../constants/image';
import EmptyStateComponent from './../../common/emptyStateComponent/EmptyStateComponent';

function SelectLanguage({ aLanguageOptions }) {
    const [oUserRegistration, setUserRegistration] = useContext(UserRegistrationContext);
    const [languageFilter, setLanguageFilter] = useState(ApplicationConstant.NO_VALUE_STRING);

    const handleSelect = (id) => {
        const i = aLanguageOptions.findIndex(o => o.id === id);
        if (i > -ApplicationConstant.ONE) {
            aLanguageOptions[i].isChecked = !aLanguageOptions[i].isChecked;
            const selectedLanguages = aLanguageOptions.filter(o => o.isChecked);
            setUserRegistration(update(oUserRegistration, { patient_profile: { user_languages: { $set: selectedLanguages } } }));
        }
    }

    const onOtherInput = (e) => {
        e.preventDefault();
        const value = e.target.value.trim();
        setUserRegistration(update(oUserRegistration, { patient_profile: { other_language: { $set: value } } }));
    }

    const filterLanguage = (e) => {
        setLanguageFilter(e.target.value);
    }

    const filterLanguageList = () => {
        return aLanguageOptions.filter(l => l.name.toLowerCase().includes(languageFilter.toLowerCase()));
    }

    return (
        <div className="form-step">
            <h3>{"Languages Spoken (Select all that apply)"}</h3>
            <form className="w-100">
                <div className="search-form">
                    <input value={languageFilter} onChange={filterLanguage} placeholder="Search language..." className="search-checkbox" type="text" />
                    <span className="material-icons">search</span>
                </div>
                <FormGroup tag="fieldset">
                    <div className="row">
                        {filterLanguageList().length ? (
                            filterLanguageList().map((item) => {
                                if ((oUserRegistration.patient_profile.user_languages.find(o => o.name.toLowerCase() === ApplicationConstant.OTHER_LOWER_CASE)) &&
                                    (item.name.toLowerCase() === ApplicationConstant.OTHER_LOWER_CASE)) {
                                    return (
                                        <div key={item.id} className="col-sm-6">
                                            <InputGroup>
                                                <div className="label-float">
                                                    <Input value={oUserRegistration.patient_profile.other_language}
                                                        onChange={onOtherInput} maxLength="100" type="text" placeholder="Please enter other"
                                                        autoFocus
                                                        />
                                                    <label>{`${item.name}`}</label>
                                                </div>
                                            </InputGroup>
                                        </div>
                                    );
                                } else {
                                    return (
                                        <FormCheckBox
                                            key={item.id}
                                            keyId={item.id}
                                            isChecked={item.isChecked}
                                            item={item}
                                            label={item.name}
                                            handleSelect={handleSelect}
                                            default_key={1}
                                            aLength={oUserRegistration.patient_profile.user_languages.length}
                                            labelDescription={item.id === 1 ? "Default" : ''}
                                        />
                                    );
                                }
                            })):
                            (<EmptyStateComponent
                                imageSrc={EMPTY_SEARCH}
                                title="No results found"
                                subtitle="Try adjusting your search to find what you're looking for."
                            />)
                        }
                    </div>
                </FormGroup>
            </form>
            <SkipToNextProfile />
        </div>
    )
}
export default SelectLanguage;
