import moment from 'moment-timezone';
import { ApplicationConstant } from '../../../../constants/application.constant';


const setWorkingDays = (value) => {
  if(value.repeat_day === ApplicationConstant.ONE) {
    return setArrayRange(ApplicationConstant.ONE,ApplicationConstant.SEVEN);
  } else if(value.repeat_day === ApplicationConstant.TWO){
    return setArrayRange(ApplicationConstant.ONE,ApplicationConstant.FIVE);
  } else {
    return value.working_days;
  }
}

export const scheduleAvailabilityPayload = (value) => {
  const workingDays = setWorkingDays(value);
  return {
      time_zone_id: value.time_zone_id || '',
      duration: value.duration || '',
      schedule_type: value.schedule_type || '',
      repeat_day: value.repeat_day || '',
      recurring_type: value.recurring_type || '',
      emd_notification: value.emd_notification || '',
      email_notification: value.email_notification || '',
      sms_notification: value.sms_notification || '',
      working_days: workingDays || '',
      slots: value.slots || '',
      calendar_date: value.calendar_date || '',
      add_slots: value.add_slots || '',
      remove_slots: value.remove_slots || '',
    }
}

function setArrayRange(start, end) {
  return Array(end - start + ApplicationConstant.ONE).fill().map((_, idx) => start + idx)
}

export const FilterTypes = {
  WORKING_DAYS: 'working_days',
  REPEAT_DAY: 'repeat_day',
  RECURRING_TYPE: 'recurring_type',
  TIME_ZONE_ID: 'time_zone_id',
}

export const getCurrentTimeZone = () => {
  const defaultTimeZone = moment.tz.guess();
  const timeZoneOffset = new Date().getTimezoneOffset();
  return moment.tz.zone(defaultTimeZone).abbr(timeZoneOffset);
}

export const returnFilteredArray = (arr1, arr2) => {
  const concatArrays = [...arr1, ...arr2]
  const filteredArray = concatArrays.filter((ele2,index,ele1)=>ele1.findIndex(e=>(e.id === ele2.id))===index);
  return filteredArray.sort((arg1, arg2) => arg1.id - arg2.id);
}

export const returnFilteredSlots = (arr1, arr2) => {
  if(arr1.length === 0) {
      return arr2
  } else {
      arr2.map(function(item, index) { // all available slots
          arr1.map(function(item2, index2) { // booked slots
              if (item.slot_start_time === item2.slot_start_time && parseInt(item.day_of_week_id) === parseInt(item2.day_of_week_id)) { // replace available slots with booked slot
                  arr2[index] = item2;
              }
          })
      });

      return arr2.sort((arg1, arg2) => {
          if(arg1[KeyProperty.SLOT_START_DATE] < arg2[KeyProperty.SLOT_START_DATE]) {
              return -1
          } else if (arg1[KeyProperty.SLOT_START_DATE] > arg2[KeyProperty.SLOT_START_DATE]) {
              return 1
          } else {
              return 0
          }
      });
  }
}

export const setActiveDays = (repeatType) => {
  if(repeatType === ApplicationConstant.ONE) {
      return 'every-day'
  } else if (repeatType === ApplicationConstant.TWO) {
      return 'week-day'
  } else {
      return 'custom'
  }
}

export const setActiveSlot = (appointment_status) => {
  switch (appointment_status) {
    case 1:
      return 'pending';
    case 2:
      return 'booked';
    case 3:
      return 'completed';
    default:
      return 'open';
  }
}

export const KeyProperty = {
  BOOKED_APPOINTMENT: 'is_booked_appointment',
  SLOT_START_DATE: 'slot_start_time',
  DAY_OF_WEEK_ID: 'day_of_week_id',
}

export const scheduledTypeTime = {
  STANDARD: {start: '09:00', end: '18:00', day_of_week_id: [1,2,3,4,5]},
  DEFAULT: {start: '00:00', end: '00:00', day_of_week_id: [1,2,3,4,5,6,7]}
}

