import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import DoctorRegistration from '../../../components/user-registration/doctor/DoctorRegistration';

const DoctorEditProfile = (props) => {
  const {
    isOpen,
    modalOpen,
    className,
    modalClassName,
    fetchProfileData,
  } = props;

  return (
    <div>
      <Modal isOpen={isOpen} toggle={modalOpen} className={className} modalClassName={modalClassName}>
        <ModalHeader>
          <div>Edit Profile</div>
          <a onClick={modalOpen} class="btn-white-outline modal-close-btn d-flex align-items-center ">
            <span class="material-icons md-dark md-18">close</span>
          </a>
        </ModalHeader>
        <ModalBody>
          <div className="form-step">
            <div className="w-100">
              <DoctorRegistration editMode={true} closeModal={modalOpen} fetchProfile={fetchProfileData} />
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default DoctorEditProfile;

