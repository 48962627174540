import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import moment from 'moment';
import { toast } from 'react-toastify';
import axiosInstance from '../../../../axios/axiosInstance';
import ApiConstant from '../../../../constants/ApiConstant';
import SpinnerComponent from '../../../common/spinner/SpinnerComponent';
import PatientProfile from './profile/PatientProfile';
import ZoomMeeting from '../../../meeting/ZoomMeeting';
import { showHideZoomWindow, getUserInfo } from '../../../../utils/helper';
import { RouteConstants, PageTitles } from '../../../../constants/application.constant';
import DocumentContainer from './document/DocumentContainer';
import useDocumentTitle from '../../../../utils/DocumentTitle';
import { convertUtcToLocalTime, timeZoneFormats } from '../../../../utils/TimezoneUtils';


function DoctorSession() {
    useDocumentTitle(PageTitles.TELEHEALTH_VIDEO);
    const location = useLocation();
    const [loader, setLoader] = useState(true);
    const [oPatientProfile, setPatientProfile] = useState(null);
    const [isSessionExpire, setSessionExpire] = useState(false);
    const {oMeeting} = location.state;
    const {user:oPatient, zoom_meeting_id, zoom_start_url, appointment_id, user_id, end_date_time} = oMeeting;

    const fetchPatientProfile = async() => {
      await axiosInstance.get(`${ApiConstant.DOCTOR_VIEW_PATIENT_PROFILE}/${oPatient.id}`)
      .then((res) => {
        if (res && res.data) {
          const PatientProfileData = res.data.data || [];
          setPatientProfile(PatientProfileData);
        }
      })
      .catch((error) => {
        toast.error(error || '')
      });
      setLoader(false);
    }

    const completeSession = () => {
      const appointmentId = {appointment_id: appointment_id}
      return axiosInstance.post(ApiConstant.DOCTOR_COMPLETE_SESSION, appointmentId);
    }

    const fetchSessionDuration = (durationTime) => {
      const payload = {appointment_id: appointment_id, duration: durationTime}
      return axiosInstance.post(ApiConstant.UPDATE_ZOOM_SESSION_DURATION, payload);
    }

    const appointmentActionButtonStatus = () => {
      if (convertUtcToLocalTime(end_date_time, timeZoneFormats.FULL_TIME) < moment().format(timeZoneFormats.FULL_TIME)) {
        setSessionExpire(true);
      } else {
        setSessionExpire(false);
      }
    };

    useEffect(() => {
      fetchPatientProfile();
      appointmentActionButtonStatus();
      showHideZoomWindow('zmmtg-root', 'block');
      }, []);

    return (
        <>
          <div className="middle-content view-profile zoom-session-page">
            <div className="container">
              <div className="row">
              {loader ? <SpinnerComponent /> : (
                <div className="col-sm-12">
                  <PatientProfile
                    patientProfile={oPatientProfile}
                    id={oPatient.id}
                    oMeeting={oMeeting}
                  />
                  <div className="zoom-container">
                    <div className="zoom-ui">
                      <ZoomMeeting
                        meetingId={zoom_meeting_id}
                        role={1}
                        leaveUrl={RouteConstants.DOCTOR_UPCOMING_APPOINTMENT}
                        userName={getUserInfo().first_name}
                        email={getUserInfo().email}
                        completeSession={completeSession}
                        fetchSessionDuration={fetchSessionDuration}
                        isSessionExpire={isSessionExpire}
                      />
                    </div>
                    <div className="content-section">
                      <DocumentContainer
                        oMeeting={oMeeting}
                        appointmentId={appointment_id}
                        userId={user_id}
                      />
                    </div>
                    <div className="session-footer">
                      <a href={zoom_start_url} target='_blank' className="zoom-link-desktop">Open zoom in new browser window</a>
                      <a href={zoom_start_url} target='_blank' className="zoom-link-mobile">Join now</a>
                    </div>
                  </div>
                </div>
                )}
              </div>
            </div>
          </div>
      </>
    )
}

export default DoctorSession;
