import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast, Slide } from 'react-toastify';
import SpinnerComponent from '../common/spinner/SpinnerComponent';
import axiosInstance from './../../axios/axiosInstance';
import ApiConstant from '../../constants/ApiConstant';
import useDocumentTitle from '../../utils/DocumentTitle';
import { EMPTY_ICON } from './../../constants/image';
import EmptyStateComponent from './../common/emptyStateComponent/EmptyStateComponent';
import { PageTitles, ApplicationConstant, InitialPageLimit } from '../../constants/application.constant';
import DoctorSearch from './DoctorSearch';
import DoctorList from './DoctorList';
import { getSearchParama, getPageParams } from './../../utils/helper';

const FindDoctor = () => {
  useDocumentTitle(PageTitles.FIND_DOCTOR);
  const history = useHistory();
  const [doctorList, setDoctorList] = useState({});
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(getPageParams(history, 'page'));
  const [loader, setLoader] = useState(false);
  const [sortObj, setSortObj] = useState({ sortBy: '', order: '' });
  const params = new URLSearchParams();
  const [searchValue, setSearchvalue] = useState(getSearchParama(history, 'search'));
  const [listInitialPage, setListInitialPage] = useState(getPageParams(history, 'page') - 1);

  const fetchDoctorList = async (page, search, sortKey) => {
    setLoader(true);
    await axiosInstance
      .post(
        `${ApiConstant.FIND_DOCTOR}?page=${page}&limit=${InitialPageLimit.FIND_DOCTOR}${
          search && `&keyword=${search}`
        }${sortKey.sortBy && `&sortBy=${sortKey.sortBy}&order=${sortKey.order}`}`
      )
      .then((response) => {
        if (response && response.data) {
          const doctorListData = response.data.data.data || {};
          const listPageCount = response.data.data.last_page || '';
          setDoctorList(doctorListData);
          setPageCount(listPageCount);
        }
      })
      .catch((error) => {
        toast.error(error || '');
      });
    setLoader(false);
  };
  const sortTableData = (sortBy, order) => {
    setSortObj({ sortBy, order });
  };

  const changeSearch = (searchKey) => {
    setSearchvalue(searchKey);
    setListInitialPage(ApplicationConstant.ZERO);
    setCurrentPage(ApplicationConstant.ONE);
  };

  const setPageParams = (pageRange) => {
    if (pageRange) {
      getSearchParama(history, 'search') && params.set('search', getSearchParama(history, 'search'))
      params.set('page', pageRange)
    }
    history.replace({search: params.toString()})
  }

  const changePagination = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage + ApplicationConstant.ONE);
    setListInitialPage(selectedPage);
    setPageParams(selectedPage + ApplicationConstant.ONE);
  };

  useEffect(() => {
    fetchDoctorList(currentPage, searchValue, sortObj);
  }, [sortObj, currentPage, searchValue]);

  return (
    <>
      {loader && <SpinnerComponent />}
      <div className="find-doctor-wrapper">
        <div className="container">
          <DoctorSearch changeSearch={changeSearch} />
          {doctorList.length ? (
            <DoctorList
              sortTableData={sortTableData}
              changePagination={changePagination}
              pageCount={pageCount}
              sortObj={sortObj}
              doctorList={doctorList}
              initialPage={listInitialPage}
            />
          ) : (
            <>{!loader && <EmptyStateComponent imageSrc={EMPTY_ICON} title="No providers found." />}</>
          )}
          <ToastContainer autoClose={4000} hideProgressBar transition={Slide} />
        </div>
      </div>
    </>
  );
};

export default FindDoctor;
