import React from 'react'
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from "classnames";
import { SessionDocumentTabTitle } from '../../../../../../constants/application.constant';

function TabHead(props) {
    const {activeTab, toggle} = props
    return (
        <Nav tabs>
      <NavItem>
        <NavLink
          className={classnames({ active: activeTab === SessionDocumentTabTitle.DOCUMENTS })}
          onClick={() => {toggle(SessionDocumentTabTitle.DOCUMENTS);}}>
          {SessionDocumentTabTitle.DOCUMENTS}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className={classnames({ active: activeTab === SessionDocumentTabTitle.HISTORY })}
          onClick={() => {toggle(SessionDocumentTabTitle.HISTORY);}}>
          {SessionDocumentTabTitle.HISTORY}
        </NavLink>
      </NavItem>
    </Nav>
    )
}

export default TabHead
