import React, { useContext } from 'react';
import UserRegistrationContext from '../../../contexts/user-registration/UserRegistrationContext';
import update from 'immutability-helper';
import { FormGroup, Input, InputGroup } from 'reactstrap';
import FormRadioButton from '../../common/formElements/FormRadioButton';
import SkipToNextProfile from './SkipToNextProfile';
import { ApplicationConstant } from '../../../constants/application.constant';

function SelectEthnicity({aEthnicityOption}) {
    const [oUserRegistration, setUserRegistration] = useContext(UserRegistrationContext);

    const handleSelect = (value) => {
        const oEthnicity = aEthnicityOption.find(o => o.id === value);
        setUserRegistration(update(oUserRegistration, {patient_profile: {ethnicity_id : {$set: oEthnicity.id}, ethnicity_name: {$set: oEthnicity.name }}}));
    }

    const onOtherInput = (e) => {
        e.preventDefault();
        setUserRegistration(update(oUserRegistration, {patient_profile: {other_ethnicity : {$set: e.target.value}}}));
    }

    return (
        <div className="form-step">
            <h3>{"Ethnicity"}</h3>
            <form className="w-100">
                <FormGroup tag="fieldset">
                    <div className="row">
                        {
                            aEthnicityOption.map((item)=> {
                                if ((oUserRegistration.patient_profile.ethnicity_name.toLowerCase() === ApplicationConstant.OTHER_LOWER_CASE) &&
                                (item.name.toLowerCase() === ApplicationConstant.OTHER_LOWER_CASE)) {
                                    return (
                                        <div key={item.id} className="col-sm-6">
                                            <InputGroup>
                                                <div className="label-float">
                                                    <Input value={oUserRegistration.patient_profile.other_ethnicity}
                                                    onChange={onOtherInput} maxLength="100" type="text" placeholder="Please enter other"
                                                    autoFocus />
                                                    <label>{`${item.name}`}</label>
                                                </div>
                                            </InputGroup>
                                        </div>
                                    );
                                } else {
                                    return (
                                        <FormRadioButton
                                            key={item.id}
                                            keyId={item.id}
                                            label={item.name}
                                            currentItem={oUserRegistration.patient_profile.ethnicity_id}
                                            item={item.id}
                                            handleSelect={handleSelect}
                                        />
                                    );
                                }
                            })
                        }
                    </div>
                </FormGroup>
            </form>
            <SkipToNextProfile />
        </div>
    )
}

export default SelectEthnicity;
