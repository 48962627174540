import { Spinner } from 'reactstrap';

export const CardStyle = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "15px",
      "::placeholder": {
        color: "#a3a3a6"
      }
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a"
    }
  }
};

export const CardLoader = () => {
  return (
    <div className="spinner-component full-page-spinner position-absolute">
      <Spinner />
    </div>
  )
}
