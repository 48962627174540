import moment from 'moment';
import React from 'react';
import { convertUtcToLocalTime } from '../../../../../utils/TimezoneUtils';
import { checkSlotvalidation } from '../../../../../utils/helper';

function TimeSlot(props) {
  const { onSlotSelect, slot, slots = [], isToday=false, selectedDate } = props;

  const handleSlotClick = (item) => {
    if(!item?.is_booked_appointment && !checkSlotvalidation(item?.utc_slot_start_time, selectedDate)){
      onSlotSelect(item);
    }
  };

  const getSlotTime = (slotTime) => {
    const slotTimes = moment(slotTime).format('YYYY-MM-DD HH:mm:ss');
    const convertedTimeSlot = convertUtcToLocalTime(slotTimes, 'HH:mm A');
    return moment(convertedTimeSlot, ["H:m"]).format("hh:mm A");
  }

  return (
    <ul>
      {slots.map((item, index) => (
        <li className={`${item.id === slot?.id? 'active' : ''} ${item?.is_booked_appointment || (checkSlotvalidation(item?.utc_slot_start_time, selectedDate)) ? 'disabled' : ''}`}>
          <div
            className='time-slot'
            onClick={() => {handleSlotClick(item)}}>
            {getSlotTime(item?.utc_slot_start_time)}
          </div>
        </li>
      ))}
    </ul>
  );
}

export default TimeSlot;
