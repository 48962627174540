import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { ApplicationConstant, profileKey, targetedId, ModalConstants, RouteConstants } from '../../../../constants/application.constant';
import { Image } from '../../../common/image/Image';
import axiosInstance from '../../../../axios/axiosInstance';
import ApiConstant from '../../../../constants/ApiConstant';
import ProfileTooltip from '../../../common/profileElements/ProfileTooltip';
import AppointmentModal from '../../../common/modal/AppointmentModal';
import IconTooltip from '../../../common/tooltip/IconTooltip';

function PatientViewDoctorProfileBasic(props) {
  const history = useHistory();
  const { doctorData } = props;
  const [isOpen, setModalOpen] = useState(false);
  const [isActive, setIsActive] = useState(doctorData?.my_favorite);

  const schedularModal = () => {
    doctorData?.available_for_booking && setModalOpen(!isOpen);
  }

  const changeFavorite = async () => {
    setIsActive(!isActive);
    const payload = { doctor_id: doctorData?.user_id };
    await axiosInstance
      .post(`${isActive ? ApiConstant.DELETE_FAV_DOCTOR : ApiConstant.ADD_FAV_DOCTOR}`, payload)
      .then((response) => {
        if (response && response.data) {
          toast.success(response?.data?.message);
        }
      })
      .catch((error) => {
        toast.error(error || '');
      });
  };

  const sendEmail = () => {
    if(doctorData.email){
      window.location.assign(`mailto:${doctorData?.email}`);
    }
  };

  const onScheduleAppointment = (input) => {
    history.push({
      pathname: RouteConstants.SUBSCRIPTION_PLAN,
      state: { msg: input, patientProfile: {}, doctorProfile: doctorData },
    });
  };

  const saveScheduleAppointemntModal = (bool, input) => {
    if(bool){
      onScheduleAppointment(input);
    }
    setModalOpen(!isOpen);
  }

  return (
    <>
      <div className="row doctor-profile-view-wrapper" hidden={!doctorData?.hospital}>
        <div className="user-info" style={{ marginLeft: 'auto'}}>
          <div className="head">
            <div className="title-text mt-10">
              <h1>{doctorData?.hospital ? doctorData?.hospital.preferred_name : "-" }</h1>
            </div>
          </div>
          <div className="profile-img" style={{width:'40px', height:'40px'}}>
            <Image src={doctorData?.hospital?.profile_image || ''} alt="Profile image" style={{width:'40px', height:'40px'}} />
          </div>
        </div>
      </div>
      <div className="row doctor-profile-view-wrapper">
        <div className="user-info">
          <div className="profile-img">
            <Image src={doctorData?.profile_image || ''} alt="Profile image" />
          </div>
          <div className="head">
            <div className="title-text mt-5">
              <h1>
                {doctorData?.first_name || '-'}
                &nbsp;{doctorData?.last_name || ''}
              </h1>
              <h2>
                {doctorData?.doctor_specializations?.length
                  ? doctorData?.doctor_specializations[ApplicationConstant.ZERO].name
                  : '-'}
              </h2>
              <h3 className="matching-percentage">Consultation fees <span>({doctorData?.consultation_fee && doctorData?.consultation_fee?.includes("###") ? '' : '$'}{doctorData?.consultation_fee})</span></h3>
            </div>
          </div>
        </div>
        <div className="action-status">
          <h4 className="ml-md-auto">
            {' '}
            Member Since: <span>{doctorData?.member_since || '-'}</span>
          </h4>
          <a onClick={changeFavorite} class={`btn-white-outline mr-8 ${isActive ? 'active' : ''}`} id={`favorite${doctorData?.user_id}`}>
            <span class="material-icons md-dark md-18">favorite</span>
            {IconTooltip('bottom', 'favorite', `${isActive ? 'Unsave' : 'Save'}`, doctorData?.user_id)}
          </a>
          {doctorData?.can_schedule ?
            <>
              <a onClick={sendEmail} class="btn-white-outline mr-8" id={`email${doctorData?.user_id}`}>
                <span class="material-icons md-dark md-18">email</span>
                {IconTooltip('bottom', 'email', 'Mail', doctorData?.user_id)}
              </a>
              <a onClick={schedularModal} className={`btn-white-outline mr-8 ${doctorData?.available_for_booking ? 'enabled-icon' : 'disabled-icon'}`} id={`appointment${doctorData?.user_id}`}>
                  <span class="material-icons md-dark md-18">today</span>
                  {IconTooltip('bottom', 'appointment', 'Schedule Appointment', doctorData?.user_id)}
              </a>
            </>
          :''}
        </div>
      </div>
      <div className="profile-info">
        <div className="row">
          <div className="col-md-4 col-sm-6">
            <label>Pronouns</label>
            <div className="value more-items">
              {doctorData?.user_pronouns?.length
                ? ProfileTooltip(
                    doctorData.user_pronouns,
                    targetedId.DOCTOR,
                    profileKey.TITLE,
                    profileKey.OTHER_PRONOUN
                  )
                : '-'}
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <label>Age</label>
            {doctorData?.age ? <div className="value">{doctorData?.age} years</div> : <div>-</div>}
          </div>
          <div className="col-md-4 col-sm-6">
            <label>Race</label>
            <div className="value more-items">
              {doctorData?.user_races?.length
                ? ProfileTooltip(
                    doctorData.user_races,
                    targetedId.DOCTOR,
                    profileKey.NAME,
                    profileKey.OTHER_RACE
                  )
                : '-'}
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <label>Ethnicity</label>
            <div className="value">{doctorData?.ethnicity || '-'}</div>
          </div>
          <div className="col-md-4 col-sm-6">
            <label>Language Spoken</label>
            <div className="value more-items">
              {doctorData?.user_languages?.length
                ? ProfileTooltip(
                    doctorData.user_languages,
                    targetedId.DOCTOR,
                    profileKey.NAME,
                    profileKey.OTHER_LANGUAGE
                  )
                : '-'}
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <label>Gender Identity</label>
            <div className="value more-items">
              {doctorData?.user_identifications?.length
                ? ProfileTooltip(
                    doctorData.user_identifications,
                    targetedId.DOCTOR,
                    profileKey.TITLE,
                    profileKey.OTHER_IDENTIFICATION
                  )
                : '-'}
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <label>Sexual Orientation</label>
            <div className="value more-items">
              {doctorData?.user_sexual_orientations?.length
                ? ProfileTooltip(
                    doctorData.user_sexual_orientations,
                    targetedId.DOCTOR,
                    profileKey.NAME,
                    profileKey.OTHER_SEXUAL_ORIENTATION
                  )
                : '-'}
            </div>
          </div>
        </div>
      </div>
      <AppointmentModal
        modalTitle={ModalConstants.SCHEDULER_TITLE}
        modalMessage={ModalConstants.VISIT_REASON}
        secondaryButonText={ModalConstants.CANCEL}
        primaryButtonText={ModalConstants.SAVE}
        setModelOpen={schedularModal}
        isOpen={isOpen}
        onButtonClicked={saveScheduleAppointemntModal}
        modalClassName="modal-logout"
      />
    </>
  );
}

export default PatientViewDoctorProfileBasic;
