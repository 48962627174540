import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import { Calendar, momentLocalizer} from 'react-big-calendar'
import moment from 'moment';
import axiosInstance from '../../../../axios/axiosInstance';
import ApiConstant from '../../../../constants/ApiConstant';
import { CustomDateCell} from '../../../common/calendarElements/CalendarElements';
import 'react-big-calendar/lib/sass/styles.scss';
import { RouteConstants } from '../../../../constants/application.constant';

const localizer = momentLocalizer(moment);


const SuggestAvailability = (props) => {
    const { modalClose, apointmentId, fetchUpdatedList } = props;
    const currentDate = moment(new Date()).format("YYYY-MM-DD").toString();
    const [selectedTime, setSelectedTime] = useState({start_date: currentDate, end_date: currentDate});
    const [slot, setSlot] = useState(null);
    const [availableSlots, setAvailableSlots] = useState([]);
    const [loader, setLoader] = useState(false);
    const [events, setEvents] = useState([]);

    const fetchDoctorAvailabilitySlots = async() => {
        setLoader(true);
        await axiosInstance.post(ApiConstant.DOCTOR_AVAILABILITY_SLOTS, selectedTime).then(
          (response) => {
            if(response && response.data) {
                const doctorAvailabilitySlots = response.data.data?.slots || [];
                const filteredAvailableSlots = doctorAvailabilitySlots.filter(e => !e.is_booked_appointment)
                setAvailableSlots(filteredAvailableSlots);
            }
          })
          .catch((error) => {
            toast.error(error || '');
          });
        setLoader(false);
    }

    const setHoursFormat = (key) => {
        return moment(key, ["H:mm"]).format("hh:mm a");;
    }

    const setAvailableSlot =  async () => {
        const slotPayload = {
            appointment_id:apointmentId,
            start_date_time:`${selectedTime?.start_date}${' '}${moment(slot?.start_date_time, 'HH:mm:ss').format('HH:mm:ss')}`,
            end_date_time:`${selectedTime?.start_date}${' '}${moment(slot?.end_date_time, 'HH:mm:ss').format('HH:mm:ss')}`
        }
        await axiosInstance.post(ApiConstant.DOCTOR_SUGGESTED_AVAILABILITY, slotPayload).then(
            (response) => {
                fetchUpdatedList();
                toast.success(response.data?.message || '');
                modalClose();
        })
        .catch((error) => {
            toast.error(error || '');
        });
    }

    const onSlotChange = (item) => {
        setSlot({
            start_date_time: item?.slot_start_time,
            end_date_time: item?.slot_end_time
        });
    }

    const handleDateSelect = ({start, end}) => {
        const presentDate = new Date();
        presentDate.setHours(0, 0, 0, 0);
        setSlot(null);
        if (presentDate <= start) {
            setEvents([{start: start, end: end}])
            const selectedStartDate = moment(start).format("YYYY-MM-DD").toString();
            setSelectedTime({start_date: selectedStartDate, end_date: selectedStartDate});
        }
    }

    useEffect(()=>{
        fetchDoctorAvailabilitySlots();
    },[selectedTime])

    return (
        <div className="suggest-different-time-wrapper">
            <h3>Suggest a Different Time</h3>
            <div className="calendar-wrapper">
                <Calendar
                    selectable
                    localizer={localizer}
                    events={events}
                    views={['month']}
                    defaultDate={moment().toDate()}
                    onSelectSlot={handleDateSelect}
                    components={{
                        dateCellWrapper: CustomDateCell,
                    }}
                />
            </div>
            <div className="available-slots">
                <h3>Available Slot</h3>
                <p>Appointment: {moment(selectedTime.start_date).format('ll')}</p>
                <div className="suggested-time-wrapper">
                    {loader ? (
                        <div className="spinner-component full-page-spinner position-absolute">
                            <Spinner/>
                        </div>
                    ):(
                    <>
                        {availableSlots.length ? (
                            <>
                            {availableSlots.map((item, i) => {
                                return (
                                    <div className="time-slot" key={`slot-${i}`}>
                                        <input
                                            type="radio"
                                            name="available_slots"
                                            id={`slot-${item.id}`}
                                            value={item.time}
                                            onChange={() => onSlotChange(item)}
                                            disabled={item.is_booked_appointment}
                                        />
                                        <label htmlFor={`slot-${item.id}`}><p>{setHoursFormat(item.slot_start_time)}</p></label>
                                    </div>
                                )
                            })}
                            </>
                        ):(
                            !loader && <div className="no-available-slots"><p>No Available slots</p></div>
                        )}
                    </>
                    )}
                </div>
                <div className="calendar-setting">
                    <span>Set to Eastern Standard Time</span>
                    <Link to={RouteConstants.DOCTOR_SCHEDULE_AVAILABILITY}>Go to Calendar Settings</Link>
                </div>
            </div>
            <div className="suggest-different-time-btn-wrapper">
                <Button color="primary" disabled={!slot} onClick={setAvailableSlot}>Save</Button>
            </div>
        </div>
    )
}

export default SuggestAvailability;
