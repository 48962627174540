import { iconType, RouteConstants } from '../../../../constants/application.constant';

export const HospitalSideMenuItems = [
  {
    name: 'Account Information',
    icon: 'place',
    iconClass: iconType.FILLED,
    to: RouteConstants.HOSPITAL_ACCOUNT_INFORMATION,
  },
  {
    name: 'Change Password',
    icon: 'https',
    iconClass: iconType.SHARP,
    to: RouteConstants.CHANGE_PASSWORD,
  },
  {
    name: 'Logout',
    icon: 'logout',
    iconClass: iconType.OUTLINED,
  },
];
