import React from 'react';
import { LOGO } from '../../../constants/image';

function PageLogo() {
    return (
        <div className="step-logo text-center" >
            <img src={LOGO} alt="img" />
        </div>
    )
}

export default PageLogo;
