import React, { useContext } from 'react';
import UserRegistrationContext from '../../../contexts/user-registration/UserRegistrationContext';
import update from 'immutability-helper';
import { FormGroup, Input, InputGroup } from 'reactstrap';
import SkipToNextProfile from './SkipToNextProfile';
import { ApplicationConstant } from '../../../constants/application.constant';
import FormCheckBox from '../../common/formElements/FormCheckBox';

function SelectRace({ aRaceOption }) {
  const [oUserRegistration, setUserRegistration] = useContext(
    UserRegistrationContext
  );

  const handleSelect = (id) => {
    const i = aRaceOption.findIndex((o) => o.id === id);
    if (i > -ApplicationConstant.ONE) {
      aRaceOption[i].isChecked = !aRaceOption[i].isChecked;
      const selectedRace = aRaceOption.filter((o) => o.isChecked);
      setUserRegistration(
        update(oUserRegistration, {
          patient_profile: { user_races: { $set: selectedRace } },
        })
      );
    }
  };

  const onOtherInput = (e) => {
    e.preventDefault();
    setUserRegistration(update(oUserRegistration, { patient_profile: { other_race: { $set: e.target.value } } }));
  };
  return (
    <div className="form-step">
      <h3>{'Race (Select all that apply)'}</h3>
      <form className="w-100">
        <FormGroup tag="fieldset">
          <div className="row">
            {aRaceOption.map((item) => {
                if (
                  oUserRegistration.patient_profile.user_races.find(
                    (o) => o.name.toLowerCase() === ApplicationConstant.OTHER_LOWER_CASE
                  ) &&
                  item.name.toLowerCase() === ApplicationConstant.OTHER_LOWER_CASE
                ) {
                return (
                  <div key={item.id} className="col-sm-6">
                    <InputGroup>
                      <div className="label-float">
                        <Input
                          value={oUserRegistration.patient_profile.other_race}
                          onChange={onOtherInput}
                          maxLength="100"
                          type="text"
                          placeholder="Please enter other"
                          autoFocus
                        />
                        <label>{`${item.name}`}</label>
                      </div>
                    </InputGroup>
                  </div>
                );
              } else {
                return (
                  <FormCheckBox
                  key={item.id}
                  keyId={item.id}
                  isChecked={item.isChecked}
                  item={item}
                  labelDescription={item.description}
                  label={`${item.name}`}
                  handleSelect={handleSelect}
                   />
                );
              }
            })}
          </div>
        </FormGroup>
      </form>
      <SkipToNextProfile />
    </div>
  );
}

export default SelectRace;
