import ApiConstant from '../constants/ApiConstant';
import axiosInstance from '../axios/axiosInstance';
import StorageConstant from '../constants/StorageConstant';
import { RouteConstants } from '../constants/application.constant';

export const getCitiesById = async (id) => {
  let data = [];
  try {
    if (id) {
      const oResponse = await axiosInstance.get(`${ApiConstant.CITY}/${id}`);
      data = oResponse && oResponse.data && oResponse.data.data ? oResponse.data.data : [];
    }
  } catch (e) {
    data = [];
  }
  return data;
};

export const getStates = async () => {
  let data = [];
  try {
    const oResponse = await axiosInstance.get(ApiConstant.STATE);
    data = oResponse && oResponse.data && oResponse.data.data ? oResponse.data.data : [];
  } catch (e) {
    data = [];
  }
  return data;
};

export const checkEmailExist = (email) => {
  return axiosInstance.post(ApiConstant.CHECK_EMAIL, { email: email }).then(
    (res) => {
      return res;
    },
    (_error) => {
      return { msg: _error };
    }
  );
};

export const checkPhoneExist = (phone) => {
  return axiosInstance.post(ApiConstant.CHECK_PHONE, { phone: phone }).then(
    (res) => {
      return res;
    },
    (_error) => {
      return { msg: _error };
    }
  );
};

export const logout = () => {
  const token = localStorage.getItem(StorageConstant.TOKEN);
  const keysToRemove = [StorageConstant.TOKEN, StorageConstant.ROLE_ID, StorageConstant.USER, StorageConstant.PROFILE_IMAGE];
  if (token) {
    axiosInstance.get(ApiConstant.LOGOUT).then(
      (_res) => {
        goToLogin(keysToRemove);
      },
      (_err) => {
        goToLogin(keysToRemove);
      }
    );
  }
};

function goToLogin(keysToRemove) {
  keysToRemove.forEach((key) => localStorage.removeItem(key));
  window.location.href = RouteConstants.LOGIN;
}
