import React from 'react';

function PatientViewDoctorProfileContact(props) {
  const { doctorData } = props;
  return (
    <>
      <div className="profile-info">
        <h3 className="mt-10 mb-18">Contact Information</h3>
        <div className="row">
          <div className="col-md-4 col-sm-6">
            <label>Birthday</label>
            <div className="value">{doctorData?.date_of_birth || '-'}</div>
          </div>
          <div className="col-md-4 col-sm-6">
            <label>Email Address</label>
            <div className="value">{doctorData?.email || '-'}</div>
          </div>
          <div className="col-md-4 col-sm-6">
            <label>Phone Number</label>
            <div className="value">{'**********'}</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PatientViewDoctorProfileContact;
