import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import axiosInstance from '../../../../axios/axiosInstance';
import ApiConstant from '../../../../constants/ApiConstant';
import SpinnerComponent from '../../../common/spinner/SpinnerComponent';
import DoctorProfile from './profile/DoctorProfile';
import ZoomMeeting from '../../../meeting/ZoomMeeting';
import { showHideZoomWindow, getUserInfo } from '../../../../utils/helper';
import useDocumentTitle from '../../../../utils/DocumentTitle';
import { RouteConstants, PageTitles } from '../../../../constants/application.constant';
import DocumentContainer from './document/DocumentContainer';

function PatientSession() {
    const [loader, setLoader] = useState(true);
    const [oDoctorProfile, setDoctorProfile] = useState(null);
    const [specializations, setSpecializations] = useState(null);
    useDocumentTitle(PageTitles.TELEHEALTH_VIDEO);
    const location = useLocation();
    const history = useHistory();
    const {oMeeting} = location.state;
    const {user:oDoctor, zoom_meeting_id, zoom_join_url, appointment_id, user_id} = oMeeting;

    const fetchDoctorProfile = async() => {
      await axiosInstance.get(`${ApiConstant.PATIENT_VIEW_DOCTOR_PROFILE}/${oDoctor.id}`)
      .then((res) => {
        if (res && res.data) {
          const oDoctorProfileData = res.data?.data || {};
          setDoctorProfile(oDoctorProfileData);
          setLoader(false);
        }
      })
      .catch((error) => {
        toast.error(error || '')
      });
    }

    const fetchDoctorSpecilazation = async() =>{
      await axiosInstance.get(`${ApiConstant.VIEW_APPOINTMENT}/${appointment_id}`)
      .then((res)=>{
        if(res && res.data){
          const specilazationName = res.data?.data || {};
          setSpecializations(specilazationName)

        }
      })
    }

    const closeSession = () => {
      history.push({
        pathname: `${RouteConstants.PATIENT_UPCOMING_APPOINTMENT}`,
      });
    }

    useEffect(() => {
      fetchDoctorProfile();
      fetchDoctorSpecilazation();
      showHideZoomWindow('zmmtg-root', 'block');
    }, []);

    return (
        <>
          <div className="middle-content view-profile zoom-session-page">
            <div className="container">
              <div className="row">
              {loader ? <SpinnerComponent/> :(
                <div className="col-sm-12">
                  <DoctorProfile
                    doctorProfile={oDoctorProfile}
                    id={oDoctor.id}
                    oMeeting={oMeeting}
                    specializations={specializations}
                  />
                  <div className="zoom-container">
                    <div className="zoom-ui">
                      <ZoomMeeting
                        meetingId={zoom_meeting_id}
                        role={0}
                        leaveUrl={RouteConstants.PATIENT_UPCOMING_APPOINTMENT}
                        userName={getUserInfo().first_name}
                        email={getUserInfo().email}
                        completeSession={closeSession}
                        fetchSessionDuration={closeSession}
                        isSessionExpire={false}
                      />
                    </div>
                    <div className="content-section">
                      <DocumentContainer appointmentId={appointment_id} userId={user_id}/>
                    </div>
                    <div className="session-footer">
                      <a href={zoom_join_url} target='_blank' className="zoom-link-desktop">Open zoom in new browser window</a>
                      <a href={zoom_join_url} target='_blank' className="zoom-link-mobile">Join Now</a>
                    </div>
                  </div>
                </div>
                )}
              </div>
            </div>
          </div>
        </>
    )
}

export default PatientSession;
