
import {  FormGroup, Label, Progress } from 'reactstrap';

const ReactImageUploader = (props) => {
    const {progressPercent, progressBar, value, responseUrl, loader, removeFile} = props;
    return (
    <FormGroup className="m-0">
        <div className="custom-file-link hidden-label">
          <Label>
            {!!value || responseUrl &&
                <span className="material-icons md-18 red-text-color2 ml-8"  onClick={removeFile}>close</span>
            }
            {progressBar && loader && (
              <div className="progressBarWrapper">
                <Progress color="warning" value={progressPercent}>{`${progressPercent}%`}</Progress>
              </div>
            )}
          </Label>
        </div>
      </FormGroup>
    );
};

export default ReactImageUploader;
