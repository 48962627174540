import { useEffect, useState } from 'react';
import moment from 'moment';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { Image } from '../../../../common/image/Image';
import axiosInstance from '../../../../../axios/axiosInstance';
import ApiConstant from '../../../../../constants/ApiConstant';
import CancelScheduleAppointment from '../../../../common/modal/CancelScheduleAppointment';
import { patientPreferredName, getAppointmentTypeName } from '../../../../../utils/helper';
import { convertUtcToLocalTime, timeZoneFormats } from '../../../../../utils/TimezoneUtils';
import { ApplicationConstant, RouteConstants } from '../../../../../constants/application.constant';
import SpinnerComponent from '../../../../common/spinner/SpinnerComponent';
import { useHistory } from 'react-router';

const UpcomingAppointmentItem = (props) => {
  const { item, fetchUpdatedList, isDashboard } = props;
  const currentTime = moment().format('YYYY-MM-DD HH:mm:ss');
  const startSessionPreparatoryTime = moment().add(ApplicationConstant.TEN, 'minutes').format(timeZoneFormats.FULL_TIME);
  const [appointmentButton, setAppointmentButton] = useState(false);
  const [isAppointmentExpire, setAppointmentExpire] = useState(false);
  const [canceledAppointmentModal, setCanceledAppointmentModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const targetPath = isDashboard ? RouteConstants.DASHBOARD_DOCTOR : RouteConstants.DOCTOR_UPCOMING_APPOINTMENT;

  const toggleCancelAppointmentModal = () => {
    setCanceledAppointmentModal(!canceledAppointmentModal);
  };

  const sendEmail = (emailId) => {
    if (emailId) {
      window.location.assign(`mailto:${emailId}`);
    }
  };

  const appointmentActionButtonStatus = () => {
    if (convertUtcToLocalTime(item?.start_date_time, timeZoneFormats.FULL_TIME) > startSessionPreparatoryTime) {
      setAppointmentButton(true);
    } else {
      setAppointmentButton(false);
      convertUtcToLocalTime(item?.end_date_time, timeZoneFormats.FULL_TIME) < currentTime &&
        setAppointmentExpire(true);
    }
  };


  useEffect(() => {
    appointmentActionButtonStatus();
  }, []);

  const startTelehealthSession = async () => {
    setLoader(true);
    const appointmentId = {appointment_id: item?.appointment_id}
    await axiosInstance.post(ApiConstant.DOCTOR_TELEHEALTH_SESSION_STARTED, appointmentId).then(
    (_response) => {
      openSessionPage();
    })
    .catch((error) => {
      toast.error(error || '');
    })
    setLoader(false);
  }

  const openSessionPage = () => {
    history.push({
      pathname: `${RouteConstants.DOCTOR_SESSION_CONTAINER}`,
      state: { oMeeting: item },
    });
  };

  return (
    <>
      {loader && <SpinnerComponent />}
      <div className='user-info'>
        <div className='profile-img'>
          <Image src={item?.user?.profile_image || ''} alt='Profile image' />
        </div>
        <div className='head'>
          <div className='title-text'>
            <h1 className='font-500 font-16'>
              {patientPreferredName(
                item?.user?.first_name,
                item?.user?.last_name,
                item?.is_disply_preferred_name,
                item?.user?.preferred_name
              )}{' '}
            </h1>
            <h3>
              Appointment:{' '}
              <span className='ml-3'> {ApplicationConstant.TELEHEALTH}</span>
            </h3>
            <div className='datetime'>
              <span className='material-icons'>event</span>
              <p>
                {convertUtcToLocalTime(item?.start_date_time, 'll')}
                {' | '}
                {convertUtcToLocalTime(item?.start_date_time, 'hh:mm A')}
                {' - '}
                {convertUtcToLocalTime(item?.end_date_time, 'hh:mm A')}
              </p>
            </div>
          </div>
          <div className='action-status d-flex row-action-status upcoming-appointment-action-status'>
            <a
              className='btn-white-outline mr-8'
              onClick={() => {
                sendEmail(item?.user?.email);
              }}
            >
              <span className='material-icons md-dark md-18'>email</span>
            </a>
            <button
              className='btn btn-primary small'
              onClick={startTelehealthSession}
              disabled={appointmentButton}
            >
              {isAppointmentExpire ? 'Mark as Completed' : 'Start Session'}
            </button>
            {!item?.session_start_time && (
              <Button color='secondary' className='outline' onClick={toggleCancelAppointmentModal}>
                Cancel
              </Button>
            )}
          </div>
        </div>
      </div>

      {canceledAppointmentModal && (
        <CancelScheduleAppointment
          setModelOpen={toggleCancelAppointmentModal}
          isOpen={canceledAppointmentModal}
          modalClassName='pending-appointment'
          userData={item}
          fetchUpdatedList={fetchUpdatedList}
          targetPath={targetPath}
        />
      )}
    </>
  );
};

export default UpcomingAppointmentItem;
