import React, { useState } from 'react';
import DoctorSchedulerInitialState from './DoctorSchedulerInitialState';
import DoctorSchedulerContext from './DoctorSchedulerContext';

const DoctorSchedulerProvider = (props) => {
  const [scheduleAvailability, setScheduleAvailability] = useState(DoctorSchedulerInitialState);

  return (
    <DoctorSchedulerContext.Provider value={[scheduleAvailability, setScheduleAvailability]}>
      {props.children}
    </DoctorSchedulerContext.Provider>
  );
};

export default DoctorSchedulerProvider;
