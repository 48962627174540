export const AllAppointmentsTypes = [
  {
    id: 1,
    label: "All Appointments",
    value: "All Appointments",
  },
  {
    id: 2,
    label: "Pending Appointments",
    value: "Pending Appointments",
  },
  {
    id: 3,
    label: "Upcoming Appointments",
    value: "Upcoming Appointments",
  },
  {
    id: 4,
    label: "Canceled Appointments",
    value: "Canceled Appointments",
  },
  {
    id: 5,
    label: "Completed Appointments",
    value: "Completed Appointments",
  },
];
