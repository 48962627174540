import { useState, useEffect } from 'react';
import { ApplicationConstant } from '../../../constants/application.constant';

const DoctorProfileFieldsHook = (callback) => {
  const [oData, setData] = useState(null);
  const oFilteredData = {};

  useEffect(() => {
    if (oData) {
      oFilteredData.pronouns = createData('pronouns', 'title');
      oFilteredData.races = createData('races', 'name');
      oFilteredData.ethnicities = createData('ethnicities', 'name');
      oFilteredData.identifications = createData('identifications', 'title');
      oFilteredData.languages = createData('languages', 'name');
      oFilteredData.sexual_orientations = createData('sexual_orientations', 'name');
      callback(oFilteredData);
    }
  }, [oData]);

  function updateData(oData) {
    setData(oData);
  }

  function createData(arrayKey, objectKey) {
    const aFinalData = [];
    const aData = oData[arrayKey] || [];
    if (aData.length > ApplicationConstant.ZERO) {
      aData.map(function (item) {
        if (item[objectKey] === ApplicationConstant.OTHER) {
          aFinalData.push({ ...item, label: item[objectKey], value: item[objectKey].toLowerCase(), isOther: true });
        } else {
          aFinalData.push({ ...item, label: item[objectKey], value: item[objectKey].toLowerCase(), isOther: false });
        }
        return { ...item, label: item[objectKey], value: item[objectKey].toLowerCase() };
      });
    }
    return aFinalData;
  }

  return {
    updateData,
  };
};

export default DoctorProfileFieldsHook;
