import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { ToastContainer, toast, Slide } from 'react-toastify';
import { PageTitles, ApplicationConstant } from '../../../../../constants/application.constant';
import axiosInstance from '../../../../../axios/axiosInstance';
import ApiConstant from '../../../../../constants/ApiConstant';
import SpinnerComponent from '../../../../common/spinner/SpinnerComponent';
import PatientCanceledAppointmentItem from './PatientCanceledAppointmentItem';
import EmptyStateComponent from '../../../../common/emptyStateComponent/EmptyStateComponent';
import { EMPTY_ICON } from '../../../../../constants/image';
import useDocumentTitle from '../../../../../utils/DocumentTitle';
import { getPageParams } from '../../../../../utils/helper';

function PatientCanceledAppointments() {
  useDocumentTitle(PageTitles.CANCELED_APPOINTMENT);
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [canceledAppointments, setCanceledAppointments] = useState(null);
  const [pageCount, setPageCount] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [listInitialPage, setListInitialPage] = useState(0);

  useEffect(() => {
    fetchCanceledAppointments();
  }, [currentPage]);

  const fetchCanceledAppointments = async () => {
    setLoading(true);
    await axiosInstance
      .get(
        `${ApiConstant.PATIENT_CANCELED_APPOINTMENT}?page=${currentPage}&limit=${ApplicationConstant.TEN}`
      )
      .then((res) => {
        if (res && res.data) {
          const canceledAppointmentList = res.data?.data?.data || [];
          const totalPageCount = res.data?.data?.last_page || '';
          setCanceledAppointments(canceledAppointmentList);
          setPageCount(totalPageCount);
        }
      })
      .catch((error) => {
        toast.error(error || '');
      });
    setLoading(false);
  };

  const changePagination = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage + ApplicationConstant.ONE);
    setListInitialPage(selectedPage);
  };

  return (
    <>
      <div className='middle-content edit-profile user-right-container'>
        <div className='pages'>
          <div className='profile-information'>
            <div className='form-step max-w-100'>
              <div className='appointments doctor-appointment-list'>
                <div className='profile-info'>
                  <div className='box'>
                    {isLoading ? (
                      <SpinnerComponent />
                    ) : (
                      <>
                        {canceledAppointments?.length ? (
                          canceledAppointments.map((item, i) => {
                            return <PatientCanceledAppointmentItem item={item} key={i} />;
                          })
                        ) : (
                          <>
                            {!isLoading && (
                              <EmptyStateComponent
                                imageSrc={EMPTY_ICON}
                                title='No Canceled Appointments.'
                              />
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <div
                    className='pagination-wrapper'
                    hidden={pageCount === ApplicationConstant.ONE}
                  >
                    <ReactPaginate
                      previousLabel={'previous'}
                      nextLabel={'next'}
                      breakLabel={'...'}
                      breakClassName={'break-me'}
                      pageCount={pageCount}
                      marginPagesDisplayed={4}
                      onPageChange={changePagination}
                      pageRangeDisplayed={1}
                      forcePage={listInitialPage}
                      containerClassName={'pagination'}
                      activeClassName={'active'}
                      nextLinkClassName={'next-btn'}
                      previousLinkClassName={'prev-btn'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={4000} hideProgressBar transition={Slide} />
    </>
  );
}

export default PatientCanceledAppointments;
