import { iconType, RouteConstants } from '../../../../../constants/application.constant';

export const DoctorAppointmentSidebarItems = [
  {
    name: 'Pending Appointments',
    icon: 'pending',
    iconClass: iconType.SHARP,
    to: RouteConstants.DOCTOR_PENDING_APPOINTMENT,
  },
  {
    name: 'Upcoming Appointments',
    icon: 'insert_invitation',
    iconClass: iconType.FILLED,
    to: RouteConstants.DOCTOR_UPCOMING_APPOINTMENT,
  },
  {
    name: 'Canceled Appointments',
    icon: 'event_busy',
    iconClass: iconType.FILLED,
    to: RouteConstants.DOCTOR_CANCELED_APPOINTMENT,
  },
  {
    name: 'Completed Appointments',
    icon: 'event_available',
    iconClass: iconType.FILLED,
    to: RouteConstants.DOCTOR_COMPLETED_APPOINTMENT,
  }
];
