import { RouteConstants } from './../constants/application.constant';
import HtmlDesign from './../components/ui-designs/HtmlDesign.js';
import SignIn from './../components/auth/login/SignIn';
import ForgotPassword from './../components/auth/forgot-password/ForgotPassword';
import ResetPassword from './../components/auth/forgot-password/ResetPassword';
import PatientSummary from './../components/summary/patient/PatientSummary';
import UserSignUpPage from './../pages/UserSignUpPage';
import PatientSignupPage from './../pages/PatientSignupPage';
import DoctorSignupPage from './../pages/DoctorSignupPage';
import HospitalSignupPage from './../pages/HospitalSignupPage';
import PatientRegistrationPage from './../pages/PatientRegistrationPage';
import DoctorRegistration from './../components/user-registration/doctor/DoctorRegistration';
import DoctorSummary from './../components/summary/doctor/DoctorSummary';
import DoctorDashboard from './../components/dashboard/doctor/DoctorDashboard';
import HospitalDashboard from './../components/dashboard/hospital/HospitalDashboard';
import PatientDashboard from './../components/dashboard/patient/PatientDashboard';
import PatientProfileView from './../components/profile/patient/profileView/PatientProfileView';
import DoctorProfileView from './../components/profile/doctor/DoctorProfileView';
import ContactUs from './../components/contactus/ContactUs';
import TermsOfService from './../components/staticPages/TermsOfService';
import PrivacyPolicy from './../components/staticPages/PrivacyPolicy';
import Faq from './../components/faq/Faq';
import HospitalProfileView from './../components/profile/hospital/profileView/HospitalProfileView';
import AccountInformation from './../components/profile/patient/accountInformation/AccountInformation';
import UserPreferences from './../components/profile/patient/userPreferences/UserPreferences';
import PatientUserProfile from './../components/profile/patient/userProfile/PatientUserProfile';
import VerifyEmail from './../components/auth/verify-email/VerifyEmail';
import DoctorAccountInformation from './../components/profile/doctor/accountInformation/DoctorAccountInformation';
import ChangePassword from './../components/auth/change-password/ChangePassword';
import HospitalAccountInformation from './../components/profile/hospital/accountInformation/AccountInformation';
import DoctorUserProfile from './../components/profile/doctor/userProfile/DoctorUserProfile';
import CsvUpload from './../components/dashboard/hospital/CsvUpload';
import AddDoctor from '../components/dashboard/hospital/RegisterDoctorByHospital/AddDoctor';
import FindDoctor from './../components/findDoctor/FindDoctor';
import DoctorBackgroundInfo from '../components/profile/doctor/backgroundInformation/DoctorBackgroundInfo';
import PatientViewDoctorContainer from '../components/dashboard/patient/patientViewDoctorProfile/PatientViewDoctorContainer';
import PatientViewAllDoctor from '../components/dashboard/patient/PatientViewAllDoctor';
import PatientFavouriteDoctorList from '../components/profile/patient/userFavouriteDooctor/PatientFavouriteDoctorList';
import PendingAppointments from '../components/appointment/doctor/history/pending/PendingAppointments';
import UpcomingAppointments from '../components/appointment/doctor/history/upcoming/UpcomingAppointments';
import CanceledAppointments from '../components/appointment/doctor/history/canceled/CanceledAppointments';
import CompletedAppointments from '../components/appointment/doctor/history/completed/CompletedAppointments';
import SubscriptionPlan from '../components/appointment/patient/subscriptionPlan/SubscriptionPlan';
import AvailableDoctor from '../components/appointment/patient/subscriptionPlan/AvailableDoctor';
import BookAppointment from '../components/appointment/patient/subscriptionPlan/BookAppointment';
import PatientCancelAppointments from '../components/appointment/patient/history/canceled/PatientCanceledAppointments';
import PatientUpcomingAppointments from '../components/appointment/patient/history/upcoming/PatientUpcomingAppointments';
import PatientCompletedAppointments from '../components/appointment/patient/history/completed/PatientCompletedAppointments';
import CancelConfirmation from '../components/appointment/patient/history/upcoming/CancelConfirmation';
import DoctorScheduler from './../pages/DoctorScheduler';
import CancelAppointmentConfirmation from '../components/appointment/doctor/history/upcoming/CancelAppointmentConfirmation';
import DoctorSession from '../components/appointment/doctor/session/DoctorSession';
import PatientSession from '../components/appointment/patient/session/PatientSession';
import DoctorMembership from '../components/profile/doctor/membership/DoctorMembership';
import PatientMembership from '../components/profile/patient/membership/PatientMembership';
import DoctorPaymentHistory from '../components/profile/doctor/paymentHistory/DoctorPaymentHistory';
import PatientPaymentHistory from '../components/profile/patient/paymentHistory/PatientPaymentHistory';
import PaymentErrorPage from '../components/common/error/PaymentErrorPage';
import HospitalProviderList from '../components/dashboard/hospital/HospitalProviderList';

export const Routes = [
  { isProtected: false, restricted: false, component: HtmlDesign, path: RouteConstants.HTML },
  { isProtected: false, restricted: true, component: SignIn, path: RouteConstants.LOGIN },
  { isProtected: false, restricted: true, component: ForgotPassword, path: RouteConstants.FORGET_PASSWORD },
  { isProtected: false, restricted: true, component: ResetPassword, path: RouteConstants.RESET_PASSWORD },
  { isProtected: false, restricted: true, component: UserSignUpPage, path: RouteConstants.SIGNUP },
  { isProtected: false, restricted: true, component: PatientSignupPage, path: RouteConstants.SIGNUP_PATIENT },
  { isProtected: false, restricted: true, component: DoctorSignupPage, path: RouteConstants.SIGNUP_DOCTOR },
  { isProtected: false, restricted: true, component: HospitalSignupPage, path: RouteConstants.SIGNUP_HOSPITAL },
  { isProtected: false, restricted: false, component: ContactUs, path: RouteConstants.CONTACT_US },
  { isProtected: false, restricted: false, component: TermsOfService, path: RouteConstants.TERMS_SERVICE },
  { isProtected: false, restricted: false, component: PrivacyPolicy, path: RouteConstants.PRIVACY_POLICY },
  { isProtected: false, restricted: false, component: VerifyEmail, path: RouteConstants.VERIFY_EMAIL },
  { isProtected: false, restricted: false, component: Faq, path: RouteConstants.FAQ },
  { isProtected: true, component: DoctorDashboard, path: RouteConstants.DASHBOARD_DOCTOR },
  { isProtected: true, component: HospitalDashboard, path: RouteConstants.DASHBOARD_HOSPITAL },
  { isProtected: true, component: PatientDashboard, path: RouteConstants.DASHBOARD_PATIENT },
  { isProtected: true, component: PatientRegistrationPage, path: RouteConstants.REGISTRATION_PATIENT },
  { isProtected: true, component: DoctorRegistration, path: RouteConstants.REGISTRATION_DOCTOR },
  { isProtected: true, component: PatientSummary, path: RouteConstants.SUMMARY_PATIENT },
  { isProtected: true, component: DoctorSummary, path: RouteConstants.SUMMARY_DOCTOR },
  { isProtected: true, component: PatientProfileView, path: RouteConstants.PATIENT_PROFILE },
  { isProtected: true, component: HospitalProfileView, path: RouteConstants.HOSPITAL_PROFILE },
  { isProtected: true, component: DoctorProfileView, path: RouteConstants.DOCTOR_PROFILE },
  { isProtected: true, component: AccountInformation, path: RouteConstants.PATIENT_ACCOUNT_INFORMATION },
  { isProtected: true, component: UserPreferences, path: RouteConstants.PATIENT_USER_PREFERENCES },
  { isProtected: true, component: PatientFavouriteDoctorList, path: RouteConstants.FAVOURITE_DOCTOR_LIST },
  { isProtected: true, component: PatientUserProfile, path: RouteConstants.PATIENT_USER_PROFILE },
  { isProtected: true, component: DoctorAccountInformation, path: RouteConstants.DOCTOR_ACCOUNT_INFORMATION },
  { isProtected: true, component: ChangePassword, path: RouteConstants.CHANGE_PASSWORD },
  { isProtected: true, component: HospitalAccountInformation, path: RouteConstants.HOSPITAL_ACCOUNT_INFORMATION },
  { isProtected: true, component: DoctorUserProfile, path: RouteConstants.DOCTOR_USER_PROFILE },
  { isProtected: true, component: CsvUpload, path: RouteConstants.CSV_UPLOAD },
  { isProtected: true, component: AddDoctor, path: RouteConstants.ADD_DOCTOR },
  { isProtected: true, component: FindDoctor, path: RouteConstants.FIND_DOCTOR },
  { isProtected: true, component: PatientViewAllDoctor, path: RouteConstants.VIEW_ALL_DOCTOR },
  { isProtected: true, component: PatientViewDoctorContainer, path: RouteConstants.PATIENT_VIEW_DOCTOR_PROFILE },
  { isProtected: true, component: DoctorBackgroundInfo, path: RouteConstants.DOCTOR_BACKGROUND_INFO },
  { isProtected: true, component: PendingAppointments, path: RouteConstants.DOCTOR_PENDING_APPOINTMENT },
  { isProtected: true, component: UpcomingAppointments, path: RouteConstants.DOCTOR_UPCOMING_APPOINTMENT },
  { isProtected: true, component: CanceledAppointments, path: RouteConstants.DOCTOR_CANCELED_APPOINTMENT },
  { isProtected: true, component: CompletedAppointments, path: RouteConstants.DOCTOR_COMPLETED_APPOINTMENT },
  { isProtected: true, component: SubscriptionPlan, path: RouteConstants.SUBSCRIPTION_PLAN},
  { isProtected: true, component: AvailableDoctor, path: RouteConstants.AVAILABLE_DOCTOR},
  { isProtected: true, component: BookAppointment, path: RouteConstants.BOOK_APPOINTMENT},
  { isProtected: true, component: PatientCancelAppointments , path: RouteConstants.PATIENT_CANCELED_APPOINTMENT},
  { isProtected: true, component: PatientCompletedAppointments, path: RouteConstants.PATIENT_COMPLETED_APPOINTMENT},
  { isProtected: true, component: PatientUpcomingAppointments, path: RouteConstants.PATIENT_UPCOMING_APPOINTMENT},
  { isProtected: true, component: DoctorScheduler, path: RouteConstants.DOCTOR_SCHEDULE_AVAILABILITY},
  { isProtected: true, component: CancelConfirmation, path: RouteConstants.PATIENT_CANCEL_CONFIRMATION},
  { isProtected: true, component: CancelAppointmentConfirmation, path: RouteConstants.DOCTOR_CANCEL_CONFIRMATION},
  { isProtected: true, component: DoctorSession, path: RouteConstants.DOCTOR_SESSION_CONTAINER},
  { isProtected: true, component: PatientSession, path: RouteConstants.PATIENT_SESSION_CONTAINER},
  { isProtected: true, component: DoctorMembership, path: RouteConstants.DOCTOR_PAYMENT},
  { isProtected: true, component: PatientMembership, path: RouteConstants.PATIENT_MEMBERSHIP},
  { isProtected: true, component: DoctorPaymentHistory, path: RouteConstants.DOCTOR_PAYMENT_HISTORY},
  { isProtected: true, component: PatientPaymentHistory, path: RouteConstants.PATIENT_PAYMENT_HISTORY},
  { isProtected: true, component: PaymentErrorPage, path: RouteConstants.PAYMENT_ERROR_PAGE},
  { isProtected: true, component: HospitalProviderList, path: RouteConstants.VIEW_ALL_OUR_DOCTOR}
];
