import React from 'react';

function HospitalThanksPage() {

  return (
    <div>
      <div className="emal-sent thanks mt-50">
        <form className="w-100">
          <div className="w-350" style={{ maxWidth: '365px' }}>
            <div className="check-box mb-50">
              <span class="material-icons">done</span>
            </div>
            <h1>Thank you for registering with equalityMD.</h1>
            <h2>
              We can't wait to have you join our team. Once your account information has been approved, you will receive
              an email with onboarding next steps.
            </h2>
            <div className="sub-heading theme-color">Thank you!</div>
            <div className="sub-heading font-600 theme-color mt-5">equalityMD</div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default HospitalThanksPage;
