import React, { useState } from 'react';
import TabHead from './tabs/TabHead';
import TabBody from './tabs/TabBody';
import { SessionDocumentTabTitle } from '../../../../../constants/application.constant';

function DocumentContainer(props) {
  const { oMeeting, appointmentId, userId } = props;
  const [activeTab, setActiveTab] = useState(SessionDocumentTabTitle.NOTES);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div className="tab-section">
      <TabHead activeTab={activeTab} toggle={toggle} />
      <TabBody
        activeTab={activeTab}
        oMeeting={oMeeting}
        appointmentId={appointmentId}
        userId={userId}
      />
    </div>
  );
}

export default DocumentContainer;
