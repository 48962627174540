import React, { useContext, useEffect, useState } from 'react';
import { ApplicationConstant, RouteConstants } from '../../../constants/application.constant';
import PageLogo from '../../common/logo/PageLogo';
import update from 'immutability-helper';
import SpinnerComponent from '../../common/spinner/SpinnerComponent';
import DoctorSignUpContext from '../../../contexts/doctor-signup/DoctorSignUpContext';
import { getAPIData, saveDoctorAccountCreationData } from '../../../utils/doctorDetailsApi';
import DoctorAccountCreation from './DoctorAccountCreation';
import ApiConstant from '../../../constants/ApiConstant';
import DoctorThanksPage from './DoctorThanksPage';
import DoctorLocationPage from './DoctorLocationPage';
import DoctorHistoryPage from './DoctorHistoryPage';
import DoctorFooter from './DoctorFooter';
import LoginFooter from '../../common/footer/LoginFooter';
import GoBack from '../../common/back/GoBack';
import { ToastContainer, toast, Slide } from 'react-toastify';
import { useHistory } from 'react-router-dom';


const LABEL_INFO = {
  npi_type: [
    { id: 1, name: 'Individual', label: 'Individual', value: 'Individual' },
    {
      id: 2,
      name: 'Organization(Clinics,Hospitals)',
      label: 'Organization(Clinics,Hospitals)',
      value: 'organization(Clinics,Hospitals)',
    },
  ],
};

const FIRST_PAGE = ApplicationConstant.ONE;

function DoctorSignUpPage(props) {
  const {
    isHospitalFlow = ApplicationConstant.FALSE,
    userId= null
  } = props;
  const [doctorSignUp, setDoctorSignUp] = useContext(DoctorSignUpContext);
  const [isLoading, setLoader] = useState(ApplicationConstant.FALSE);
  const [labelInfo, setLabelInfo] = useState(LABEL_INFO);
  const [filename, setFilename] = useState('');
  const LAST_PAGE = isHospitalFlow ? ApplicationConstant.TWO : ApplicationConstant.THREE;
  const THANK_PAGE = LAST_PAGE + ApplicationConstant.ONE;
  const history = useHistory();

  const getNext = (step) => {
    return step + ApplicationConstant.ONE;
  };
  const getPrev = (step) => {
    return step - ApplicationConstant.ONE;
  };

  const handleNext = () => {
    setDoctorSignUp(update(doctorSignUp, { doctor_profile: { current_step: { $apply: getNext } } }));
  };

  const handlePrev = () => {
    setDoctorSignUp(update(doctorSignUp, { doctor_profile: { current_step: { $apply: getPrev } } }));
  };

  const handleSave = () => {
    setLoader(true);
    saveDoctorAccountCreationData( doctorSignUp.doctor_profile, LABEL_INFO, isHospitalFlow, filename )
      .then((res) => {
        setLoader(false);
          if(isHospitalFlow){
            history.push({pathname:RouteConstants.DASHBOARD_HOSPITAL, state:{msg:res?.data?.message || ApplicationConstant.NO_VALUE_STRING}});
          }else{
            setDoctorSignUp(update(doctorSignUp, { doctor_profile: { current_step: { $set: THANK_PAGE } } }));
          }
      })
      .catch((err) => {
        setLoader(err);
        setTimeout(() => {
          setDoctorSignUp(
            update(doctorSignUp, { doctor_profile: { current_step: { $set: ApplicationConstant.ONE } } })
          );
          setLoader(false);
          toast.error(err || '');
        }, 500);
      });
  };

  const pageThreeValidity = (qObj, ques = []) => {
    let bool = true;
    if (!qObj || ques.length <= 0) {
      return false;
    }
    ques.forEach((i) => {
      let opt = qObj[i.id] === 'N' || (qObj[i.id] === 'Y' && !!qObj[`comment_${i.id}`]) ? true : false;
      bool = bool && opt;
    });
    return bool;
  };

  const getValidity = (step) => {
    const oStateValidity = { isValid: ApplicationConstant.FALSE };
    const oUser = doctorSignUp.doctor_profile;

    if (oUser) {
      switch (step) {
        case ApplicationConstant.ONE:
          oStateValidity.isValid = !!(
            oUser.first_name &&
            (isHospitalFlow ||
              (oUser.password &&
                oUser.password.valid &&
                oUser.confirm_password &&
                oUser.last_name)) &&
            oUser.first_name.value &&
            oUser.first_name.valid &&
            oUser.last_name.value &&
            oUser.last_name.valid &&
            oUser.date_of_birth.value &&
            oUser.date_of_birth.valid &&
            oUser.phone.value &&
            oUser.phone.valid &&
            oUser.email.value &&
            oUser.email.valid &&
            oUser.password.value === oUser.confirm_password.value &&
            (oUser.npi_number.value ? oUser.npi_number.valid : true) &&
            (oUser.license_number.value ? oUser.license_number.valid : true) &&
            //oUser.expiration_date &&
            oUser.prescription_availability &&
            oUser.own_insurance &&
            oUser.accept_insurance &&
            oUser.electronic_medical_record &&
            // oUser.document &&
            oUser.state &&
            ((oUser.medical_school?.name === ApplicationConstant.OTHER &&
              oUser.other_medical_school) ||
              oUser.medical_school?.name !== ApplicationConstant.OTHER) &&
            ( oUser.accept_insurance==='Y' && oUser.insurance_provider.length > 0
              ? (oUser.insurance_provider?.findIndex(
                  (item) => item?.name === ApplicationConstant.OTHER
                ) > -1 &&
                  oUser.other_insurance_provider) ||
                oUser.insurance_provider?.findIndex(
                  (item) => item?.name === ApplicationConstant.OTHER
                ) === -1
              : ApplicationConstant.TRUE) &&
            Array.isArray(oUser.doctor_certifications) &&
            ((oUser.doctor_certifications?.findIndex(
              (item) => item?.name === ApplicationConstant.OTHER
            ) > -1 &&
              oUser.other_certification) ||
              oUser.doctor_certifications?.findIndex(
                (item) => item?.name === ApplicationConstant.OTHER
              ) === -1) &&
            ((oUser.accept_insurance === "Y" && (oUser.insurance_provider.length > 0)) || oUser.accept_insurance === "N") &&
            oUser.terms_and_conditions == ApplicationConstant.ONE
          );
          break;

        case ApplicationConstant.TWO:
          oStateValidity.isValid = !!(oUser.location.address1 && oUser.location.city && oUser.location.state &&
            oUser.location.city.id && oUser.location.state.id &&
            oUser.location.zip_code && oUser.location.zip_code.valid
          );
          break;
        case ApplicationConstant.THREE:
          oStateValidity.isValid = pageThreeValidity(oUser.doctor_background_questions, LABEL_INFO.questions);
          break;
        default:
          oStateValidity.isValid = false;
          break;
      }
    }
    return oStateValidity;
  };

  const getComponent = (step) => {
    switch (step) {
      case 1:
        return <DoctorAccountCreation 
                oLabelInfo={LABEL_INFO} 
                isHospitalFlow={isHospitalFlow} 
                filename={filename}
                setFilename={setFilename}/>;

      case 2:
        return (
          <DoctorLocationPage
            oLabelInfo={LABEL_INFO}
            handleSave={handleSave}
            handleNext={handleNext}
            isNext={doctorSignUp.doctor_profile.current_step !== LAST_PAGE}
            showSkip={!getValidity(ApplicationConstant.TWO).isFilled}
            isHospitalFlow={isHospitalFlow}
            userId={userId}
          />
        );

      case 3:
        return <DoctorHistoryPage oLabelInfo={LABEL_INFO} />;

      case 4:
        return <DoctorThanksPage />;

      default:
        return <div>{'There was a problem !!'}</div>;
    }
  };

  const setBackgroundLabel = () => {
    getAPIData(ApiConstant.DOCTOR_BACKGROUND_INFO)
      .then((res) => {
        if (Object.entries(res).length > 0) {
          Object.entries(res).forEach(([key, value]) => {
            LABEL_INFO[key] = value.map((item) => ({ ...item, label: item.name, value: item.name.toLowerCase() }));
          });
          setLabelInfo({ ...labelInfo, ...LABEL_INFO });
        }
      })
      .catch((err) => {
        LABEL_INFO = {};
      });
  };

  const setFacilities = async () => {
     await getAPIData(ApiConstant.DOCTOR_FACILITY)
      .then((res) => {
        let obj = res.map((item) => ({ ...item, label: item.name, value: item.name.toLowerCase() }));
        LABEL_INFO.facility = obj;
        setLabelInfo({ ...labelInfo, facility: obj });
      })
      .catch((err) => {
        LABEL_INFO = {};
      });

      if(userId && isHospitalFlow){
        const hospital = LABEL_INFO.facility.filter((item)=> item.user_id === parseInt(userId));
        setDoctorSignUp(update(doctorSignUp, { doctor_profile: { 'user_facilities': { $set: hospital || ApplicationConstant.NO_VALUE_STRING } } }));
      }
  }

  useEffect(() => {
    setBackgroundLabel();
    //get all states
    getAPIData(ApiConstant.STATE)
      .then((res) => {
        let obj = res.map((item) => ({ ...item, label: item.name, value: item.name.toLowerCase() }));
        LABEL_INFO.states = obj;
        setLabelInfo({ ...labelInfo, states: obj });
      })
      .catch((err) => {
        LABEL_INFO = {};
      });

      //get all facilities
      setFacilities();

       //get all insurance provider
    getAPIData(ApiConstant.DOCTOR_INSURANCE_PROVIDER)
    .then((res) => {
      let obj = res.map((item) => ({ ...item, label: item.name, value: item.name.toLowerCase() }));
      LABEL_INFO.insurance_provider = obj;
      setLabelInfo({ ...labelInfo, insurance_provider: obj });
    })
    .catch((err) => {
      LABEL_INFO = {};
    });

     //get all doctor practice
       getAPIData(ApiConstant.DOCTOR_PRACTICE)
       .then((res) => {
         let obj = res.map((item) => ({ ...item, label: item.name, value: item.name.toLowerCase() }));
         LABEL_INFO.practice = obj;
         setLabelInfo({ ...labelInfo, practice: obj });
       })
       .catch((err) => {
         LABEL_INFO = {};
       });

       //get all procedure
       getAPIData(ApiConstant.PROCEDURE)
       .then((res) => {
         let obj = res.map((item) => ({ ...item, label: item.name, value: item.name.toLowerCase() }));
         LABEL_INFO.procedure = obj;
         setLabelInfo({ ...labelInfo, procedure: obj });
       })
       .catch((err) => {
         LABEL_INFO = {};
       });

       //get all condition Treated
       getAPIData(ApiConstant.CONDITION_TREATED)
       .then((res) => {
         let obj = res.map((item) => ({ ...item, label: item.name, value: item.name.toLowerCase() }));
         LABEL_INFO.condition_treated = obj;
         setLabelInfo({ ...labelInfo, condition_treated : obj });
       })
       .catch((err) => {
         LABEL_INFO = {};
       });

      //get all background-question
    (!isHospitalFlow && getAPIData(ApiConstant.DOCTOR_BACKGROUND_QUESTIONS)
      .then((res) => {
        LABEL_INFO.questions = res;
        setLabelInfo({ ...labelInfo, questions: res });
      })
      .catch((err) => {
        LABEL_INFO = {};
      }))
  }, []);

  return (
    <div className="step-page">
      <div className="middle">
        <div className="container">
          <div className="back-login">
            {(doctorSignUp.doctor_profile.current_step === FIRST_PAGE )&&<GoBack/>}
          </div>
          <PageLogo />
          {isLoading && (
            <div className="head-title mb-50">
              <div className="heading">
                <SpinnerComponent />
              </div>
            </div>
          )}
          {!isLoading && getComponent(doctorSignUp.doctor_profile.current_step)}
        </div>
      </div>
      {(doctorSignUp.doctor_profile.current_step === THANK_PAGE || isLoading) && <LoginFooter />}
      {!(doctorSignUp.doctor_profile.current_step === THANK_PAGE || isLoading) && (
        <DoctorFooter
          saveBtnVisible={doctorSignUp.doctor_profile.current_step === LAST_PAGE && !isLoading}
          handleSave={handleSave}
          handleNext={handleNext}
          handlePrev={handlePrev}
          enableNext={getValidity(doctorSignUp.doctor_profile.current_step).isValid}
          enableSave={getValidity(doctorSignUp.doctor_profile.current_step).isValid}
          prevBtnVisible={doctorSignUp.doctor_profile.current_step !== ApplicationConstant.ONE}
        />
      )}
      <ToastContainer autoClose={8000} hideProgressBar transition={Slide} />

    </div>
  );
}

export default DoctorSignUpPage;
