import React, { useState, useEffect } from 'react';
import { ApplicationConstant, PageTitles, ToastMessage } from '../../../constants/application.constant';
import PageLogo from '../../common/logo/PageLogo';
import ApiConstant from '../../../constants/ApiConstant';
import FileUploadButton from '../../common/fileUpload/FileUploadButton';
import axiosInstance from '../../../axios/axiosInstance';
import { Slide, toast, ToastContainer } from 'react-toastify';
import GoBack from '../../common/back/GoBack';
import useDocumentTitle from '../../../utils/DocumentTitle';

function CsvUpload() {
  const [fileUploaded, setFileUploaded] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [urlLink, setUrlLink] = useState('');
  const [uploadMsg, setUploadMsg] = useState([]);
  useDocumentTitle(PageTitles.CSV_UPLOAD);

  const onFileUpload = ({ file, res }) => {
    setUploadMsg(res?.data?.validationError || []);
    setFileUploaded(true);
    setFileName(file?.name);
    toast.success(ToastMessage.FILE_UPLOADED);
  };

  const onFileRemove = () => {
    setFileUploaded(false);
    setFileName(ApplicationConstant.NO_VALUE_STRING);
    setUploadMsg([]);
  };

  const onUploadFailure = (error) => {
    toast.error(error || '');
  };

  useEffect(() => {
    axiosInstance.get(`${ApiConstant.CSV_SAMPLE_URL}`).then((res) => {
      if (res && res.data) {
        const getUrl = res?.data?.data?.url;
        setUrlLink(getUrl);
      }
    });
  }, []);

  return (
    <div className="step-form csv-form">
      <div className="container">
        <div className="back-link">
          <GoBack />
          <div className="text-center add-provider-logo">
            <PageLogo />
          </div>
        </div>
        <div className="text-center font-16 mt-20 mb-48">Add Provider</div>
        <div className="csv-uploader text-center">
          <div className="mb-24">
            {!fileUploaded && <span class="material-icons-sharp theme-color"> upload</span>}
            {fileUploaded && (
              <div className="d-flex align-items-start">
                <span class="material-icons-sharp mr-10">description</span>
                <div class="font-14" style={{ color: '#717171' }}>{fileName}</div>
                <span class="material-icons-sharp ml-auto " style={{ cursor:'pointer'}} onClick={onFileRemove}>
                  close
                </span>
              </div>
            )}
          </div>
          <div className="upload-csv-wrraper">
            <FileUploadButton
              onSuccess={onFileUpload}
              onRemove={onFileRemove}
              allowedExtensions={['csv']}
              allowedSize={1000000}
              allowedTypeMsg={'.csv are allowed'}
              dataLabel={'Upload'}
              formFieldName={'csv_file'}
              url={ApiConstant.UPLOAD_PROVIDERS}
              onFailure={onUploadFailure}
            />
          </div>

          <div class="font-14" style={{ color: '#717171' }}>
            Supported file: .csv
          </div>
        </div>
        <div className="text-center mt-20">
          <a className="font-14 theme-color text-decoration-underline" href={urlLink}>
            Download Sample File
          </a>
        </div>
        {uploadMsg.length > ApplicationConstant.ZERO && (
          <div className="csv-uploader-err mt-24">
            <ul>
              {uploadMsg.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <ToastContainer autoClose={4000} hideProgressBar transition={Slide} />
    </div>
  );
}

export default CsvUpload;
