import axiosInstance from '../../../../../../axios/axiosInstance';
import ApiConstant from '../../../../../../constants/ApiConstant';
import { InitialPageLimit, ApplicationConstant } from '../../../../../../constants/application.constant';


export const getDoctorDocumentList = (userId, page, sortKey) =>{
    return (
        axiosInstance.get(`${ApiConstant.DOCTOR_APPOINTMENT_DOCUMENTS}?patient_id=${
        userId}${sortKey?.sortBy && `&sortBy=${sortKey?.sortBy}&order=${
        sortKey?.order}`}&page=${page}&limit=${InitialPageLimit.DOCTOR_DOCUMENT}`)
    )
}

export const downloadDoctorMultipleDocuments = (payload) => {
    return axiosInstance.post(`${ApiConstant.DOWNLOAD_APPOINTMENT_DOCUMENT}`, payload, {responseType : 'blob'})
}

export const downloadDoctorDocument = (documentId) => {
    return axiosInstance.get(`${ApiConstant.DOWNLOAD_DOCUMENT}`, {
    params: { document_id: documentId },
    responseType : 'blob'
    })
}

export const deleteDoctorDocumentList = (payload) => {
    return axiosInstance.post(ApiConstant.DELETE_APPOINTMENT_DOCUMENT, payload)
}

export const setDocumentVisibility = (payload) => {
  return axiosInstance.post(ApiConstant.SET_DOCUMENT_VISIBILITY, payload)
}

export const resetScrollPosition = (scrollRef) => {
    return scrollRef.current.scrollTop = ApplicationConstant.ZERO;
}


export const getDoctorSessionDocumentList = (appointmentId, page, sortKey) =>{
    return (
        axiosInstance.get(`${ApiConstant.DOCTOR_APPOINTMENT_DOCUMENTS}?appointment_id=${
            appointmentId}${sortKey?.sortBy && `&sortBy=${sortKey?.sortBy}&order=${
        sortKey?.order}`}&page=${page}&limit=${InitialPageLimit.DOCTOR_DOCUMENT}`)
    )
}