import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ApplicationConstant, PageTitles, RouteConstants } from '../../../constants/application.constant';
import useDocumentTitle from '../../../utils/DocumentTitle';
import InputElement from '../../common/formElements/InputElement';
import SpinnerComponent from '../../common/spinner/SpinnerComponent';
import ApiConstant from '../../../constants/ApiConstant';
import StorageConstant from '../../../constants/StorageConstant';
import { ToastContainer, toast, Slide } from 'react-toastify';
import { Button } from 'reactstrap';
import update from 'immutability-helper';
import axiosInstance from '../../../axios/axiosInstance';
import { validatePassword, isValidConfirmPassword } from './ChangePasswordUtils';
import 'react-toastify/dist/ReactToastify.css';

const ChangePassword = () => {
  useDocumentTitle(PageTitles.CHANGE_PASSWORD);
  const initialState = { old_password: '', new_password: '', confirm_password: '' };
  const [changePasswordState, setChangePasswordState] = useState(initialState);
  const [passwordShown, setPasswordShown] = useState(ApplicationConstant.TRUE);
  const [confirmPasswordShown, setconfirmPasswordShown] = useState(ApplicationConstant.TRUE);
  const [confirmPasswordShown2, setconfirmPasswordShown2] = useState(ApplicationConstant.TRUE);
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();

  const changePassword = (e) => {
    e.preventDefault();
    setLoading(true);
    axiosInstance.post(ApiConstant.CHANGE_PASSWORD, changePasswordState).then(
      (res) => {
        setLoading(false);
        resetState();
        toast.success(res?.data?.message);
      },
      (error) => {
        setLoading(false);
        toast.error(error || '');
      }
    );
  };

  const resetState = () => {
    setChangePasswordState(update(changePasswordState, { $set: initialState }));
  };

  const handleCancel = (e) => {
    e.preventDefault();
    const roleId = parseInt(localStorage.getItem(StorageConstant.ROLE_ID));
    switch (roleId) {
      case ApplicationConstant.ONE:
        history.replace(RouteConstants.DASHBOARD_PATIENT);
        break;
      case ApplicationConstant.TWO:
        history.replace(RouteConstants.DASHBOARD_DOCTOR);
        break;
      case ApplicationConstant.THREE:
        history.replace(RouteConstants.DASHBOARD_HOSPITAL);
        break;
    }
  };

  const handleChange = (e) => {
    setChangePasswordState(update(changePasswordState, { [e.target.name]: { $set: e.target.value } }));
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const toggleConfirmPasswordVisiblity = () => {
    setconfirmPasswordShown(confirmPasswordShown ? false : true);
  };
  const toggleConfirmPasswordVisiblity2 = () => {
    setconfirmPasswordShown2(confirmPasswordShown2 ? false : true);
  };

  return (
    <>
      {isLoading && <SpinnerComponent />}
      <div className="middle-content edit-profile user-right-container">
        <div className="pages">
          <div className="profile-information">
            <div className="form-step max-w-100">
              <form className="change-password-form">
                <div className="row">
                  <div className="passwordshowhide ">
                    <InputElement
                      className="col-sm-12"
                      type={!passwordShown ? "text" : "password"}
                      name="old_password"
                      placeholder="Please enter old password"
                      value={changePasswordState.old_password || ""}
                      onChange={handleChange}
                      label={"Current Password*"}
                    />

                    <span
                      className="material-icons md-20 md-dark "
                      onClick={togglePasswordVisiblity}>
                      {passwordShown ? "visibility_off" : "visibility"}
                    </span>{" "}
                  </div>
                  <div className="passwordshowhide ">
                    <InputElement
                      className="col-sm-12"
                      type={!confirmPasswordShown ? "text" : "password"}
                      name="new_password"
                      placeholder="Please enter new password"
                      value={changePasswordState.new_password || ""}
                      onChange={handleChange}
                      label={"New Password*"}
                    />
                    
                    <span
                      className="material-icons md-20 md-dark "
                      onClick={toggleConfirmPasswordVisiblity}>
                      {confirmPasswordShown ? "visibility_off" : "visibility"}
                    </span>{" "}
                  </div>
                  <div className="passwordshowhide ">
                    <InputElement
                      className="col-sm-12"
                      type={!confirmPasswordShown2 ? "text" : "password"}
                      name="confirm_password"
                      placeholder="Please enter confirm password"
                      msg={`New and confirm password doesn't match`}
                      isValid={isValidConfirmPassword(changePasswordState)}
                      value={changePasswordState.confirm_password || ""}
                      onChange={handleChange}
                      label={"Confirm Password*"}
                    />

                      <span
                        className="material-icons md-20 md-dark "
                        onClick={toggleConfirmPasswordVisiblity2}>
                        {confirmPasswordShown2 ? "visibility_off" : "visibility"}
                      </span>{" "}
                  </div>
                </div>
                <div className="mt-10">
                  <p className="font-12 gray666">
                    8 Characters Minimum, 1 Upper Case Letter, 1 Number, 1
                    Special Character
                  </p>
                </div>
                <div className="btn-group justify-content-end w-100 pt-10 form-btn-group">
                  <Button
                    className="btn mr-15 cancel-btn btn-secondary"
                    onClick={handleCancel}>
                    CANCEL
                  </Button>
                  <Button
                    disabled={!validatePassword(changePasswordState)}
                    type="submit"
                    color="primary"
                    className="btn btn-primary"
                    onClick={changePassword}>
                    SAVE
                  </Button>
                </div>
                <ToastContainer
                  autoClose={4000}
                  hideProgressBar
                  transition={Slide}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ChangePassword;
