import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Image } from './../image/Image';
import { patientPreferredName } from '../../../utils/helper';
import { convertUtcToLocalTime } from '../../../utils/TimezoneUtils';
import { ApplicationConstant } from '../../../constants/application.constant';

const AcceptAppointment = (props) => {
  const { modalClassName, isOpen, setModelOpen, userData} = props;
  const [emdcalendarNotification, setEmdCalendarNotification] = useState(false);

  return (
    <div>
      <Modal isOpen={isOpen} modalClassName={modalClassName}>
        <ModalBody className="emal-sent">
            <div className="check-box">
                <span className="material-icons">done</span>
            </div>
            <h1>Appointment was successfully confirmed! </h1>
            <div className="profile-info">
                  <div className="box">
                    <div className="user-info">
                      <div className="profile-img">
                      <Image src={userData?.user?.profile_image || ''} alt='Profile image' />
                      </div>
                      <div className="head">
                        <div className="title-text">
                          <h2>With: <span>
                            {patientPreferredName(
                              userData?.user?.first_name,
                              userData?.user?.last_name,
                              userData?.is_disply_preferred_name,
                              userData?.user?.preferred_name
                            )}{' '}
                          </span></h2>
                          <h2>Date: <span>{convertUtcToLocalTime(userData?.start_date_time, 'll')}</span></h2>
                          <h2>Time: <span>{convertUtcToLocalTime(userData?.start_date_time, 'LT')}</span></h2>
                          <h2>Appointment: <span className="ml-3">{' '}{ApplicationConstant.TELEHEALTH}</span></h2>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div className="action-checkbox">
              <label className="label--checkbox flat">
                  <input
                      type="checkbox"
                      className="checkbox"
                      id="emdCalendarNotification"
                      name="emd_calendarNotification"
                      onChange={()=>{setEmdCalendarNotification(!emdcalendarNotification)}}
                      checked
                      readOnly
                  />
                  <label htmlFor="emdCalendarNotification">Add to your eMD Calendar</label>
              </label>
            </div>
        </ModalBody>
        <ModalFooter>
            <Button color="secondary" onClick={()=>{setModelOpen()}}>Cancel</Button>
            <Button color="primary" onClick={()=>{setModelOpen()}}>Save</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AcceptAppointment;
