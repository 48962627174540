import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { useHistory, useLocation, Link } from 'react-router-dom';
import axiosInstance from '../../../../../axios/axiosInstance';
import ApiConstant from '../../../../../constants/ApiConstant';
import { PageTitles, RouteConstants } from '../../../../../constants/application.constant';
import useDocumentTitle from '../../../../../utils/DocumentTitle';
import SpinnerComponent from '../../../../common/spinner/SpinnerComponent';

function CancelAppointmentConfirmation() {
  useDocumentTitle(PageTitles.CANCEL_CONFIRMATION);
  const history = useHistory();
  const location = useLocation();
  const [isLoading, setLoading] = useState(false);
  const [isAccepted, setAccepted] = useState(false);

  const cancelAppointment = () => {
    setLoading(true);
    const appointmentId = location.state?.appointmentId || '';
    const targetPath = location.state?.targetPath || '';
    const oPayload = { appointment_id: appointmentId };
    axiosInstance.post(ApiConstant.DOCTOE_CANCELED_PATIENT_APPOINTMENT, oPayload).then(
      (_res) => {
        setLoading(false);
        history.replace(targetPath);
      },
      (error) => {
        setLoading(false);
        toast.error(error || '');
      }
    );
  };

  const handleChange = () => {
    setAccepted(!isAccepted);
  };

  return (
    <>
      {isLoading ? <SpinnerComponent /> : ''}
      <div className='full-bg-color'>
        <div className='container'>
          <div className='landing-ui'>
            <div className='emal-sent'>
              <form className='w-100'>
                <div className='doctor-appointment-cancel-confirmation'>
                  <div className='check-box'>
                    <span className='material-icons'>done</span>
                  </div>
                  <h1>Sorry to see you had to cancel.</h1>
                  <h2 className='mt-15'>
                    <span>Cancelation need to be made in 24 hours.</span>
                  </h2>
                  <h2>
                      <span>
                        Please see{' '}
                        <Link to={RouteConstants.PRIVACY_POLICY} className='theme-color cursor-pointer text-decoration'>Terms of service</Link>{' '}
                        details.
                      </span>
                  </h2>
                  <div className='plane-checkbox'>
                    <div className='label--checkbox flat' onClick={handleChange}>
                      <input readOnly type='checkbox' className='checkbox' checked={isAccepted}  />
                      <label>Yes, I have read and agree equalityMD cancelation policy</label>
                    </div>
                  </div>
                  <div className='sub-heading theme-color mt-16'>Thank you!</div>
                  <div className='sub-heading font-600 theme-color mt-5'>equalityMD</div>
                  <div className='cancel-appointemnt-btn-wrapper'>
                    <Button className='w-100 mb-20' disabled={!isAccepted} color='primary' onClick={cancelAppointment}>
                      Yes, Cancel
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CancelAppointmentConfirmation;
