import React from 'react';
import { TabContent, TabPane } from 'reactstrap';
import PatientDocument from '../documents/PatientDocument';
import PatientHistory from '../history/PatientHistory';
import { SessionDocumentTabTitle } from '../../../../../../constants/application.constant';

function TabBody(props) {
    const { activeTab, appointmentId, userId } = props
    return (
        <TabContent activeTab={activeTab}>
        <TabPane tabId={SessionDocumentTabTitle.DOCUMENTS}>
            <PatientDocument
                appointmentId={appointmentId}
                userId={userId}
            />
        </TabPane>
        <TabPane tabId={SessionDocumentTabTitle.HISTORY}>
            <PatientHistory userId={userId}/>
        </TabPane>
      </TabContent>
    )
}

export default TabBody;



