import React from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { SessionDocumentTabTitle } from '../../../../../../constants/application.constant';
import CompletedAppointmentDocument from './CompletedAppointmentDocument';
import CompletedAppointmentNotes from './CompletedAppointmentNotes';
import CompletedAppointmentSuperbill from "./CompletedAppointmentSuperbill";

function TabBody(props) {
    const { activeTab, oMeeting:item ,appointmentId, userId, viewHistoryOpen } = props;
    return (
      <TabContent activeTab={activeTab}>
        <TabPane tabId={SessionDocumentTabTitle.NOTES}>
          <CompletedAppointmentNotes
            item={item}
            viewHistoryOpen={viewHistoryOpen}
          />
        </TabPane>
        <TabPane tabId={SessionDocumentTabTitle.DOCUMENTS}>
          <CompletedAppointmentDocument
            appointmentId={appointmentId}
            userId={userId}
          />
        </TabPane>
        <TabPane tabId={SessionDocumentTabTitle.SUPERBILL}>
          <CompletedAppointmentSuperbill
              appointmentId={appointmentId}
              userId={userId}
          />
        </TabPane>
        <TabPane tabId={SessionDocumentTabTitle.HISTORY}></TabPane>
      </TabContent>
    );
}

export default TabBody;
