import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ApplicationConstant, NPITypes, RouteConstants } from '../../../constants/application.constant';
import { Image } from './../../common/image/Image';
import { getNPIType } from '../../../utils/helper';

function HospitalProfile(props) {
  const [oSpecialization, setHospitalSpecialization] = useState(null);
  const { oHospitalProfile } = props;

  useEffect(() => {
    if (oHospitalProfile) {
      const specializations = oHospitalProfile?.doctor_specializations || [];
      if (specializations.length > ApplicationConstant.ZERO) {
        let aSpecializations = [];
        for (const oSpecialization of specializations) {
          aSpecializations.push(oSpecialization?.name || '');
        }
        aSpecializations = aSpecializations.join(', ');
        setHospitalSpecialization(aSpecializations);
      }
    }
  }, [oHospitalProfile]);

  return (
    <div className="col-sm-12 col-lg-8">
      <div className="box">
        <div className="user-info">
          <div className="profile-img">
            <Image src={oHospitalProfile?.profile_image || ''} alt="Profile image" />
          </div>
          <div className="head">
            <div className="title-text">
              <h1 className="mt-20">{oHospitalProfile?.first_name || ''}</h1>
              <h2>
                <span className="ml-0">Available</span>
              </h2>
            </div>
            <div className="action-status">
              <a className="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8">
                <span className="material-icons md-dark md-18">mail</span>
              </a>
              <Link to={RouteConstants.HOSPITAL_ACCOUNT_INFORMATION} className="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8">
                <span className="material-icons md-dark md-18">mode_edit</span>
              </Link>
              <Link to={RouteConstants.HOSPITAL_PROFILE} class="btn-white-outline text-uppercase">View Profile</Link>
            </div>
          </div>
        </div>
        <div className="profile-info">
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <label>Tax ID</label>
              <div className="value">{oHospitalProfile?.tax_id || ''}</div>
            </div>
            <div className="col-md-4 col-sm-6">
              <label>Date of Business Creation</label>
              <div className="value">{oHospitalProfile?.date_of_business_creation || ''}</div>
            </div>
            <div className="col-md-4 col-sm-6">
              <label>City</label>
              <div className="value">{oHospitalProfile?.city || ''}</div>
            </div>
            <div className="col-md-4 col-sm-6">
              <label>State</label>
              <div className="value">{oHospitalProfile?.state || ''}</div>
            </div>
            <div className="col-md-4 col-sm-6">
              <label>NPI Number</label>
              <div className="value">{oHospitalProfile?.npi_number || ''}</div>
            </div>
            <div className="col-md-4 col-sm-6">
              <label>NPI Type</label>
              <div className="value">{getNPIType(oHospitalProfile?.npi_type, NPITypes)}</div>
            </div>
            <div className="col-sm-12">
              <label>Specialty</label>
              <div className="value">{oSpecialization || '-'}</div>
            </div>
          </div>
          <h4>
            Joined Since: <span>{oHospitalProfile?.joined_since || '-'}</span>
          </h4>
        </div>
      </div>
    </div>
  );
}
export default HospitalProfile;
