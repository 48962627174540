import { Modal, ModalBody, ModalHeader } from 'reactstrap';

const DocumentView = (props) => {
  const { className, modalClassName, isOpen, setModelOpen, mediaPath } = props;

  const checkFormat = (path) => {
    return path.split('.').pop();
  }

  const uploadedDocument = (filePath) => {
    if (checkFormat(filePath) === 'pdf') {
        return <iframe src={filePath} />
    } else {
        return <img src={filePath} alt="document" />
      }
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          setModelOpen();
        }}
        className={className}
        modalClassName={modalClassName}
      >
        <ModalHeader
          toggle={() => {
            setModelOpen();
          }}
        />
        <ModalBody>{mediaPath ? uploadedDocument(mediaPath) : <p className="text-center">No document</p>}</ModalBody>
      </Modal>
    </div>
  );
};

export default DocumentView;
