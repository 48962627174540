import { useState, useEffect } from 'react';
import { ApplicationConstant } from '../../../constants/application.constant';

const PatientSummaryHook = (callback) => {

    const [oProfile, setProfile] = useState({});

    useEffect(() => {
        if (oProfile) {
            checkSingleFields();
            checkMultiSelectionFields();
            callback(oProfile, isProfileCompleted());
        }
    }, [oProfile]);

    function checkSingleFields() {
        const otherPronounValue = oProfile.other_pronoun || '';
        const otherRaceValue = oProfile.other_race || '';
        const otherEthnicityValue = oProfile.other_ethnicity || '';
        if (otherPronounValue) {
            oProfile.pronoun.title = otherPronounValue;
        }
        if (otherRaceValue) {
            oProfile.race.name = otherRaceValue;
        }
        if (otherEthnicityValue) {
            oProfile.ethnicity.name = otherEthnicityValue;
        }
    }

    function checkMultiSelectionFields() {
        const selectedLanguages = oProfile.user_languages || [];
        const userIdentifications = oProfile.user_identifications || [];
        const sexualOrientations = oProfile.user_sexual_orientations || [];
        const selectedPronoun = oProfile.user_pronouns || [];
        const selectedRace = oProfile.user_races || [];
        if (selectedLanguages && selectedLanguages.length > ApplicationConstant.ZERO) {
            const other = selectedLanguages.find(oLanguage => oLanguage.name === ApplicationConstant.OTHER);
            if (other) {
                other.name = other.other_language || '';
            }
        }
        if (userIdentifications && userIdentifications.length > ApplicationConstant.ZERO) {
            const other = userIdentifications.find(oIdentification => oIdentification.title === ApplicationConstant.OTHER);
            if (other) {
                other.title = other.other_identification || '';
            }
        }
        if (sexualOrientations && sexualOrientations.length > ApplicationConstant.ZERO) {
            const other = sexualOrientations.find(oSexOrientation => oSexOrientation.name === ApplicationConstant.OTHER);
            if (other) {
                other.name = other.other_sexual_orientation || '';
            }
        }
        if (selectedPronoun && selectedPronoun.length > ApplicationConstant.ZERO) {
            const other = selectedPronoun.find(oPronoun => oPronoun.title === ApplicationConstant.OTHER);
            if (other) {
                other.title = other.other_pronoun || '';
            }
        }
        if (selectedRace && selectedRace.length > ApplicationConstant.ZERO) {
            const other = selectedRace.find(oRace => oRace.name === ApplicationConstant.OTHER);
            if (other) {
                other.name = other.other_race || '';
            }
        }
    }

    function isProfileCompleted() {
        const pronoun = oProfile?.pronoun?.title || '';
        const race = oProfile?.race?.name || '';
        const ethnicity = oProfile?.ethnicity?.name || '';
        if (!pronoun || !race || !ethnicity) {
            return false;
        }
        const selectedLanguages = oProfile.user_languages || [];
        const selectedOrientation = oProfile.user_sexual_orientations || [];
        const selectedIdentifications = oProfile.user_identifications || [];
        if (selectedLanguages.length === ApplicationConstant.ZERO ||
            selectedOrientation.length === ApplicationConstant.ZERO ||
            selectedIdentifications.length === ApplicationConstant.ZERO) {
            return false;
        }
        const age = oProfile.age || '';
        const weight = oProfile.weight || '';
        const height = oProfile.height_feet || '';
        if (!age || !weight || !height) {
            return false;
        }
        return true;
    }

    function updateProfile(oProfile) {
        setProfile(oProfile);
    }

    return {
        updateProfile
    }
}

export default PatientSummaryHook;
