import React from "react";
import { FormGroup, Input } from 'reactstrap';
import { LOGO } from '../../../constants/image';
import { HEADERLOGO } from '../../../constants/image';
import ProfileDropdown from './ProfileDropdown';
import { NavLink } from 'react-router-dom'
const Mainheader = () => {
const [open, setNavOpen] = React.useState(false);
return (
  <header>
    <div className="container">
      <div className="headerSection">
        <div className="header-logo">
            <span
              class="material-icons"
              onClick={() => {
                setNavOpen(!open);
              }}>
              {open ? "close" : "menu"}
            </span>
            <a>
              <img src={HEADERLOGO} all="" />
            </a>
          </div>
          <nav className={open ? "nav open" : "nav none"}>
            <ul>
              <li>
                <NavLink className="active" to="/">
                  {' '}
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink to="/">Find a Doctor</NavLink>
              </li>
            </ul>
          </nav>
        <div className="small-right-nav">
          <ul>
            <li>
              <div className="header-search d-none">
                <FormGroup>
                  <Input type="text" name="search" id="mainSearch" placeholder="Search" />
                </FormGroup>
              </div>
              <a><span class="material-icons">search</span></a>
            </li>
            <li>
              <a><span class="material-icons icon-w">notifications</span></a>
            </li>
            <li>
              <a><span class="material-icons">shopping_cart</span></a>
            </li>
            <li>
              <a>
                <ProfileDropdown />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
  )
}

export default Mainheader; 