import React from "react";
import '~/../../src/assets/scss/_dashboard.scss';
import { PRODUCTIMG, PROFILEIMG } from '../../../constants/image';
const DoctorDashboard = () => {
  return (
    <div className="dashboard-page">
      <div className="container">        
        <section className="detail-info">
          <div className="welcome">
            <h1>Welcome Rodney! <span> Psychologist</span></h1>
          </div>
          <div className="profile-links">
            <ul>
              <li>
                <a className="active">
                  <div className="profile-status">
                    <span class="material-icons">done</span>
                  </div>
                  <span>Professional Profile</span>
                </a>
              </li>
              <li>
                <a className="active">
                  <div className="profile-status">
                    <span class="material-icons">done</span>
                  </div>
                  <span>Add an Image</span>
                </a>
              </li>
              <li>
                <a>
                  <div className="profile-status">
                  <span class="material-icons">done</span>   
                  </div>
                  <span>Add a Profile (Intro/ Bio)</span>
                </a>
              </li>
              <li>
                <a>
                  <div className="profile-status">
                    <span class="material-icons">done</span>
                  </div>
                  <span>Have your first patient session</span>
                </a>
              </li>
              <li>
                <a>
                  <div className="profile-status">
                    <span class="material-icons">done</span>                    
                  </div>
                  <span>Review your first patient session</span>
                </a>
              </li>
              
            </ul>
          </div>
          <div className="profile-info">
            <div className="row">
              <div className="col-sm-12 col-md-8">
                <div className="box">
                  
                  <div className="user-info">
                    <div className="profile-img active">
                      <img src={PROFILEIMG}></img>
                    </div>
                    <div className="head">
                      <div className="title-text">
                        <h1>Rodney Fuller</h1>
                        <h2>Psychologist <span>Available</span></h2>
                        <h3>Patient Rating <span className="star-rating"> (star rating here!!!)</span></h3>
                      </div>
                      <div className="action-status">
                        <a class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8"><span class="material-icons md-dark md-18">mode_edit</span></a>
                        <a class="btn-white-outline">View Profile</a>
                      </div>
                    </div>
                  </div>
                  <div className="profile-info">
                    <div className="row">
                      <div className="col-md-4 col-sm-6">
                        <label>Pronoun</label>
                        <div className="value">He/Him/His</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>City</label>
                        <div className="value">San Diego</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>State</label>
                        <div className="value">California</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Age</label>
                        <div className="value">44 years</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Race</label>
                        <div className="value">Alaska Native</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Ethnicity</label>
                        <div className="value">Non-Hispanic or Latino</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Language Spoken</label>
                        <div className="value">American English, British English <span className="chip">2 More</span></div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Gender Identity</label>
                        <div className="value">Cigender man</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Sexual Orientation</label>
                        <div className="value">Gay, Queer</div>
                      </div>
                    </div>
                    <h4>Member Since: <span>Jan 21, 2020</span></h4>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-4">
                <div className="box">
                  <div className="head">
                      <div className="title"> 
                        Recent Activity
                      </div>
                  </div>
                  <div className="activity">
                    <ul>
                      <li><span class="material-icons">today</span><span className="name">Upcoming Appointment </span></li>
                      <li><span class="material-icons">account_circle </span><span className="name">New Match </span></li>
                      <li><span class="material-icons">today</span><span className="name">Upcoming Appointment  </span></li>
                      <li><span class="material-icons-outlined">inventory_2</span><span className="name">Purchased Product </span></li>
                    </ul>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          
        </section>
        <section className="my-matched-section">
          <div className="heading-link">
            <h5>My Scheduled Patients</h5>
            <a href="">View All</a>
          </div>
          <div className="card-box">
            <div className="card-row">
              <div className="row">
                <div className="col-lg-4 col-md-12 profile-info">
                  <div className="user-info">
                    <div className="profile-img">
                      <img src={PROFILEIMG}></img>
                    </div>
                    <div className="head">
                      <div className="title-text">
                        <h1>Sam Fowler</h1>
                        <h2>San Diego, California</h2>
                        <div className="value"><span className="chip font-12">Appointment: Friday, June 17, 2021 </span></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                <div className="profile-info">
                    <div className="row">
                      <div className="col-md-4 col-sm-6">
                        <label>Pronoun</label>
                        <div className="value">He/Him/His</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Ethnicity</label>
                        <div className="value">Non-Hispanic or Latino</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Sexual Orientation</label>
                        <div className="value">Gay, Queer”</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Race</label>
                        <div className="value">Alaska Native</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Gender Identity</label>
                        <div className="value">Cigender man</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Age</label>
                        <div className="value">48 years</div>
                      </div>
                     
                    </div>
                  </div>
                </div>
                <div className="col-sm-2 d-flex align-items-center justify-content-end">
                  <div className="action-status d-flex">
                    <a class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8"><span class="material-icons md-dark md-18">favorite</span></a>
                    <a class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8"><span class="material-icons md-dark md-18">email</span></a>
                    <a class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8"><span class="material-icons md-dark md-18">today</span></a>
                    <a class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8"><span class="material-icons md-dark md-18">description</span></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-row">
              <div className="row">
                <div className="col-lg-4 col-md-12 profile-info">
                  <div className="user-info">
                    <div className="profile-img">
                      <img src={PROFILEIMG}></img>
                    </div>
                    <div className="head">
                      <div className="title-text">
                        <h1>Sam Fowler</h1>
                        <h2>San Diego, California</h2>
                        <div className="value"><span className="chip font-12">Appointment: Friday, June 17, 2021 </span></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                <div className="profile-info">
                    <div className="row">
                      <div className="col-md-4 col-sm-6">
                        <label>Pronoun</label>
                        <div className="value">He/Him/His</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Ethnicity</label>
                        <div className="value">Non-Hispanic or Latino</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Sexual Orientation</label>
                        <div className="value">Gay, Queer”</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Race</label>
                        <div className="value">Alaska Native</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Gender Identity</label>
                        <div className="value">Cigender man</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Age</label>
                        <div className="value">48 years</div>
                      </div>
                     
                    </div>
                  </div>
                </div>
                <div className="col-sm-2 d-flex align-items-center justify-content-end">
                  <div className="action-status d-flex">
                    <a class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8"><span class="material-icons md-dark md-18">favorite</span></a>
                    <a class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8"><span class="material-icons md-dark md-18">email</span></a>
                    <a class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8"><span class="material-icons md-dark md-18">today</span></a>
                    <a class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8"><span class="material-icons md-dark md-18">description</span></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-row">
              <div className="row">
                <div className="col-lg-4 col-md-12 profile-info">
                  <div className="user-info">
                    <div className="profile-img">
                      <img src={PROFILEIMG}></img>
                    </div>
                    <div className="head">
                      <div className="title-text">
                        <h1>Sam Fowler</h1>
                        <h2>San Diego, California</h2>
                        <div className="value"><span className="chip font-12">Appointment: Friday, June 17, 2021 </span></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                <div className="profile-info">
                    <div className="row">
                      <div className="col-md-4 col-sm-6">
                        <label>Pronoun</label>
                        <div className="value">He/Him/His</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Ethnicity</label>
                        <div className="value">Non-Hispanic or Latino</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Sexual Orientation</label>
                        <div className="value">Gay, Queer”</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Race</label>
                        <div className="value">Alaska Native</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Gender Identity</label>
                        <div className="value">Cigender man</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Age</label>
                        <div className="value">48 years</div>
                      </div>
                     
                    </div>
                  </div>
                </div>
                <div className="col-sm-2 d-flex align-items-center justify-content-end">
                  <div className="action-status d-flex">
                    <a class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8"><span class="material-icons md-dark md-18">favorite</span></a>
                    <a class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8"><span class="material-icons md-dark md-18">email</span></a>
                    <a class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8"><span class="material-icons md-dark md-18">today</span></a>
                    <a class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8"><span class="material-icons md-dark md-18">description</span></a>
                  </div>
                </div>
              </div>
            </div>
            
            
          </div>
        </section>

        <div className="row">
          <div className=" col-md-6">
            <section className="my-matched-section">
              <div className="heading-link">
                <h5>Reviews</h5>
              </div>
              <div className="card-box rating-section">
                <div className="card-row">
                  <div className="row">
                    <div className="col-sm-12 profile-info">
                      <div className="user-info">
                        <div className="profile-img">
                          <img src={PROFILEIMG}></img>
                        </div>
                        <div className="head">
                          <div className="title-text">
                            <div className="d-flex justify-content-between align-items-start"><h1>Sam Fowler</h1><h2>14 hours ago</h2></div>
                            <h3 className="mt-0">Rating <span className="star-rating"> (star rating here!!!)</span></h3>
                            <p className="font-14 font-400 lineheight-24 mt-8">Etiam non ligula lectus. Morbi finibus elit id elementum posuere. </p>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-row">
                  <div className="row">
                    <div className="col-sm-12 profile-info">
                      <div className="user-info">
                        <div className="profile-img">
                          <img src={PROFILEIMG}></img>
                        </div>
                        <div className="head">
                          <div className="title-text">
                            <div className="d-flex justify-content-between align-items-start"><h1>Rosie Douglas</h1><h2>14 hours ago</h2></div>
                            <h3 className="mt-0">Rating <span className="star-rating"> (star rating here!!!)</span></h3>
                            <p className="font-14 font-400 lineheight-24 mt-8">Mauris finibus tellus non orci sagittis, maximus pharetra nibh aliquet. Cras eget suscipit magna. Duis magna justo. </p>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-row">
                  <div className="row">
                    <div className="col-sm-12 profile-info">
                      <div className="user-info">
                        <div className="profile-img">
                          <img src={PROFILEIMG}></img>
                        </div>
                        <div className="head">
                          <div className="title-text">
                            <div className="d-flex justify-content-between align-items-start"><h1>Kenneth Cross</h1><h2>14 hours ago</h2></div>
                            <h3 className="mt-0">Rating <span className="star-rating"> (star rating here!!!)</span></h3>
                            <p className="font-14 font-400 lineheight-24 mt-8">Cras vestibulum felis et sagittis dictum. Nam finibus, justo a euismod fringilla, vitae eleifend purus arcu ac mauris. </p>
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="d-none col-md-6">
            <section className="my-matched-section">
              <div className="heading-link">
                <h5>My Calendar</h5>
              </div>
              <div className="card-box">
                <div className="calender-box">
                Calendar here!!!!
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>

    )
}

export default DoctorDashboard; 