import { iconType, RouteConstants } from "../../../../../constants/application.constant";

export const PatientAppointmentSidebarMenuItems = [
  {
    name: 'Upcoming Appointments',
    icon: 'event',
    iconClass: iconType.FILLED,
    to: RouteConstants.PATIENT_UPCOMING_APPOINTMENT,
  },
  {
    name: 'Canceled Appointments',
    icon: 'event_busy',
    iconClass: iconType.FILLED,
    to: RouteConstants.PATIENT_CANCELED_APPOINTMENT,
  },
  {
    name: 'Completed Appointments',
    icon: 'event_available',
    iconClass: iconType.SHARP,
    to: RouteConstants.PATIENT_COMPLETED_APPOINTMENT,
  },
];
