import { NPITypes, ApplicationConstant } from "../../../../constants/application.constant";
import {getDateIgnoreTimezone, getFormattedDate, isObjsEqual} from './../../../../utils/helper';

export const intialState = (value) =>  {
    return {
        first_name: {value: value?.user?.first_name || '',},
        user_name: {value: value?.user?.user_name || '',},
        tax_id: {value: value?.tax_id || '', valid: true},
        date_of_birth: {value: (value?.user?.date_of_birth ? getDateIgnoreTimezone(value.user.date_of_birth) : ''), valid: true},
        phone: {value: value?.user?.phone || ''},
        email: {value: value?.user?.email || ''},
        profile_image: {value: value?.user?.profile_image || ''},
        npi_number: {value: value?.npi_number || '', valid: true},
        npi_type: getNpiSelectedOption(value?.npi_type) || '',
        specializations: getSpecializationSelectedOption(value?.doctor_specializations) || '',
        address1: {value: value?.location?.address1 || ''},
        address2: {value: value?.location?.address2 || ''},
        state: { id: value?.location?.state_id, label: value?.location?.state_name,},
        city: { id: value?.location?.city_id, label: value?.location?.city_name},
        zip_code: {value: value?.location?.zip_code || '', valid: true},
    }
};

const getNpiSelectedOption = (id) => {
    return NPITypes.find((item)=> parseInt(id)===item.id);
}

const getSpecializationSelectedOption = (option) => {
    if(option.length) {
        return option.map(item => ({
            ...item,
            id:item.specialization_id,
            label: item.name,
            value:item.name.toLowerCase(),
        }))
    }
}

export const FieldKey = {
    PROFILE_IMAGE: 'profile_image',
    DATE_OF_BUSINESS: 'date_of_business',
    STATE: 'state',
    CITY: 'city',
    NPI_TYPE: 'npi_type',
    SPECIALIZATIONS:'specializations',
}

export const ValidationText = {
    TAX_ID: 'Tax Id must be of 10 digits number.',
}

export const checkValidation = (data, initialData) => {
    const isValidFirstName = data?.first_name?.value;
    const isValidUserName = data?.user_name?.value;
    const isValidDateOfBirth = data?.date_of_business?.valid;
    const isValidTaxId = data?.tax_id?.valid;
    const isValidPhone = isValidPhoneNumber(data);
    const isAddressValid = data?.address1.value;
    const isStateValid = data?.state?.id;
    const isCityValid = data?.city?.id;
    const isZipValid = data?.zip_code?.valid;
    const isNpiNumberValid = data?.npi_number?.valid;
    const isNpiTypeValid = data?.npi_type?.id;
    if (isValidFirstName && isValidUserName && isValidDateOfBirth &&
        isValidTaxId && isValidPhone && isAddressValid && isStateValid && isCityValid &&
        isZipValid && isNpiNumberValid && isNpiTypeValid) {
      return true;
    }
    return false;
};

export const isValidPhoneNumber = (data) => {
    const phoneValue = data?.phone?.value || '';
    if (phoneValue && phoneValue.length === ApplicationConstant.TEN) {
      return true;
    }
    return false;
};

export const updateAccountInfoPayload = (payload) =>  {
    const dateOfBirthFormat = getFormattedDate(payload?.date_of_business?.value);
    const specializations = payload?.specializations.length ? payload?.specializations.map(e => e.id) : [];
    return {
        first_name: payload?.first_name?.value,
        user_name: payload?.user_name?.value,
        tax_id: payload?.tax_id?.value,
        date_of_birth: dateOfBirthFormat,
        phone: payload?.phone?.value,
        profile_image: payload?.profile_image?.value,
        address1: payload?.address1?.value,
        address2: payload?.address2?.value,
        state_id: payload?.state.id.toString(),
        city_id: payload?.city.id.toString(),
        zip_code: payload?.zip_code.value,
        npi_number: payload?.npi_number?.value,
        npi_type: payload?.npi_type?.id,
        doctor_specializations: specializations,
    }
};
