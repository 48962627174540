import React from 'react'
import InputElement from '../../../common/formElements/InputElement'
import DateElement from '../../../common/formElements/DateElement'
import { dateofBirthValidation, nameValidation, phoneValidation, phoneValidationApi, preferredNameValidation } from '../../../../validators/CommonValidation';
import { ApplicationConstant, DateConstant } from '../../../../constants/application.constant';
import { subYears } from 'date-fns';

function DoctorPersonalInformation({profileData, setValueByFieldName, email}) {
  const handleNameChange = (e) => {
    const obj = nameValidation(e.target.value, profileData[e.target.name]);
    setValueByFieldName(e.target.name, obj);
  };

  const onDobInput = (date) => {
    const obj = dateofBirthValidation(date, ApplicationConstant.EIGHTEEN);
    setValueByFieldName("date_of_birth", obj);
  };

  const onPhoneInput = (e) => {
    e.preventDefault();
    if (e.target.value.length <= ApplicationConstant.TEN) {
      phoneValidation("phone", e.target.value, setValueByFieldName);
    }
  };

  const onPhoneInputApi = (e) => {
    e.preventDefault();
    if (
      e.target.value.length <= ApplicationConstant.TEN &&
      e.target.value !== profileData?.phone?.value
    ) {
      phoneValidationApi(
        "phone",
        e.target.value,
        setValueByFieldName,
        profileData.phone.value
      );
    }
  };

  return (
    <>
      <h3>Personal Information</h3>
      <div className="row">
        <InputElement
          className="col-sm-6"
          type="text"
          name="first_name"
          placeholder={"First Name"}
          label={"First Name*"}
          onChange={handleNameChange}
          isValid={profileData?.first_name?.valid}
          value={profileData?.first_name?.value}
          msg={profileData?.first_name?.msg}
        />
        <InputElement
          className="col-sm-6"
          type="text"
          name="last_name"
          placeholder={"Last Name"}
          label={"Last Name*"}
          onChange={handleNameChange}
          isValid={profileData?.last_name.valid}
          value={profileData?.last_name?.value}
          msg={profileData?.last_name?.msg}
        />
        <DateElement
          className="col-sm-12"
          type="date"
          name="date_of_birth"
          onChange={onDobInput}
          dateFormat="MMM d, yyyy"
          maxDate={subYears(new Date(), ApplicationConstant.EIGHTEEN)}
          minDate={new Date(DateConstant.START_DATE)}
          onKeyPress={(e) => {
            e.preventDefault();
          }}
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          onKeyUp={(e) => {
            e.preventDefault();
          }}
          isValid={profileData?.date_of_birth?.valid}
          msg={profileData?.date_of_birth?.msg}
          value={profileData?.date_of_birth?.value}
          label={"Birthdate*"}
        />
      </div>
      <h3 className="mt-24">Contact Information</h3>
      <div className="row">
        <InputElement
          className="col-sm-6 read-only-field"
          type="text"
          name="email"
          placeholder={"Please enter email"}
          label={"Email Address*"}
          value={email}
          readOnly
        />
        <InputElement
          className="col-sm-6"
          type="number"
          name="phone"
          placeholder={"Please enter phone number"}
          label={"Phone Number*"}
          onChange={onPhoneInput}
          onBlur={onPhoneInputApi}
          isValid={profileData?.phone?.valid}
          value={profileData?.phone?.value}
          msg={profileData?.phone?.msg}
        />
      </div>
    </>
  );
}

export default DoctorPersonalInformation
