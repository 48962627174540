import { useState, useEffect } from 'react';
import { ApplicationConstant } from '../../../constants/application.constant';

const DoctorSummaryHook = (callback) => {
  const [oProfile, setProfile] = useState({});

  useEffect(() => {
    if (oProfile) {
      checkSingleFields();
      checkMultiSelectionFields();
      callback(oProfile, isProfileCompleted());
    }
  }, [oProfile]);

  function checkSingleFields() {
    const otherPronounValue = oProfile.other_pronoun || '';
    const otherRaceValue = oProfile.other_race || '';
    const otherEthnicityValue = oProfile.other_ethnicity || '';
    if (otherPronounValue) {
      oProfile.pronoun.title = otherPronounValue;
    }
    if (otherRaceValue) {
      oProfile.race.name = otherRaceValue;
    }
    if (otherEthnicityValue) {
      oProfile.ethnicity.name = otherEthnicityValue;
    }
  }

  function checkMultiSelectionFields() {
    const selectedLanguages = oProfile.user_languages || [];
    const userIdentifications = oProfile.user_identifications || [];
    const sexualOrientations = oProfile.user_sexual_orientations || [];
    const selectedPronous = oProfile.user_pronouns || [];
    const selectedRaces = oProfile.user_races || [];
    if (selectedPronous && selectedPronous.length > ApplicationConstant.ZERO) {
      const other = selectedPronous.find((oPronoun) => oPronoun.title === ApplicationConstant.OTHER);
      if (other) {
        other.title = other.other_pronoun || '';
      }
    }
    if (selectedRaces && selectedRaces.length > ApplicationConstant.ZERO) {
      const other = selectedRaces.find((oRace) => oRace.name === ApplicationConstant.OTHER);
      if (other) {
        other.name = other.other_race || '';
      }
    }
    if (selectedLanguages && selectedLanguages.length > ApplicationConstant.ZERO) {
      const other = selectedLanguages.find((oLanguage) => oLanguage.name === ApplicationConstant.OTHER);
      if (other) {
        other.name = other.other_language || '';
      }
    }
    if (userIdentifications && userIdentifications.length > ApplicationConstant.ZERO) {
      const other = userIdentifications.find((oIdentification) => oIdentification.title === ApplicationConstant.OTHER);
      if (other) {
        other.title = other.other_identification || '';
      }
    }
    if (sexualOrientations && sexualOrientations.length > ApplicationConstant.ZERO) {
      const other = sexualOrientations.find((oSexOrientation) => oSexOrientation.name === ApplicationConstant.OTHER);
      if (other) {
        other.name = other.other_sexual_orientation || '';
      }
    }
  }

  function isProfileCompleted() {
    const pronoun = oProfile?.pronoun?.title || '';
    const race = oProfile?.race?.name || '';
    const ethnicity = oProfile?.ethnicity?.name || '';
    if (!pronoun || !race || !ethnicity) {
      return false;
    }
    const selectedLanguages = oProfile.user_languages || [];
    const selectedOrientation = oProfile.user_sexual_orientations || [];
    const selectedIdentifications = oProfile.user_identifications || [];
    if (
      selectedLanguages.length === ApplicationConstant.ZERO ||
      selectedOrientation.length === ApplicationConstant.ZERO ||
      selectedIdentifications.length === ApplicationConstant.ZERO
    ) {
      return false;
    }
    return true;
  }

  function updateProfile(oProfile) {
    setProfile(oProfile);
  }

  return {
    updateProfile,
  };
};

export default DoctorSummaryHook;
