import React, { useState, useEffect, useContext } from 'react';
import { ApplicationConstant, NPITypes, PageTitles, RouteConstants } from '../../../../constants/application.constant';
import useDocumentTitle from '../../../../utils/DocumentTitle';
import SpinnerComponent from '../../../common/spinner/SpinnerComponent';
import update from 'immutability-helper';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import DoctorPersonalInformation from './DoctorPersonalInformation';
import DoctorProfessionalInformation from './DoctorProfessionalInformation';
import DoctorBackgroundInformation from './DoctorBackgroundInformation';
import DoctorLocationInformation from './DoctorLocationInformation';
import axiosInstance from '../../../../axios/axiosInstance';
import ApiConstant from '../../../../constants/ApiConstant';
import { checkValidation, getParsedData, initialState } from './DoctorAccountInformationUtils';
import { ToastContainer, toast, Slide } from 'react-toastify';
import { getAPIData } from '../../../../utils/doctorDetailsApi';
import DoctorProfileImage from './DoctorProfileImage';
import UserProfileContext from '../../../../contexts/user-profile/UserProfileContext';
import { setProfileImage } from '../../../../utils/helper';

const SELECT_OPTIONS = {
  npi_type: NPITypes,
};

function DoctorAccountInformation() {
  useDocumentTitle(PageTitles.ACCOUNT_INFORMATION);
  const [loader, setLoader] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const [email, setEmail] = useState(null);
  const [selectOptions, setSelectOptions] = useState(SELECT_OPTIONS);
  const [profileInitialData, setProfileInitialData] = useState(null);
  const [fileName, setFilename] = useState(ApplicationConstant.NO_VALUE_STRING);
  const [oUserGenericData, setUserGenericData] = useContext(UserProfileContext);

  const fetchDoctorProfile = async () => {
    setLoader(true);
    await axiosInstance.get(`${ApiConstant.DOCTOR_PROFILE}`)
      .then((response) => {
        if (response && response.data) {
          const doctorProfileData = response.data.data || {};
          setEmail(doctorProfileData?.user?.email || '');
          setProfileData(initialState(doctorProfileData, selectOptions));
          setProfileInitialData(initialState(doctorProfileData));
          setFilename(doctorProfileData?.document_name);
          setUserGenericData(
            update(oUserGenericData, {
              profile_image: { $set: doctorProfileData?.user?.profile_image || ApplicationConstant.NO_VALUE_STRING },
            })
          );
          setProfileImage(doctorProfileData?.user?.profile_image);
        }
      })
      .catch((error) => {
        toast.error(error || '');
      });
    setLoader(false);
  };

  const setSelectOptionsValue = async () => {
    getAPIData(ApiConstant.DOCTOR_BACKGROUND_INFO)
      .then((res) => {
        if (Object.entries(res).length > ApplicationConstant.ZERO) {
          Object.entries(res).forEach(([key, value]) => {
            SELECT_OPTIONS[key] = value.map((item) => ({ ...item, label: item.name, value: item.name.toLowerCase() }));
          });
          setSelectOptions({ ...selectOptions, ...SELECT_OPTIONS });
        }
      })
      .catch((err) => {
        SELECT_OPTIONS.err = {};
      });

    //get all states
    getAPIData(ApiConstant.STATE)
      .then((res) => {
        let obj = res.map((item) => ({ ...item, label: item.name, value: item.name.toLowerCase() }));
        SELECT_OPTIONS.states = obj;
        setSelectOptions({ ...selectOptions, states: obj });
      })
      .catch((err) => {
        SELECT_OPTIONS.states = {};
      });
    //get all facilities
    getAPIData(ApiConstant.DOCTOR_FACILITY)
      .then((res) => {
        let obj = res.map((item) => ({ ...item, label: item.name, value: item.name.toLowerCase() }));
        SELECT_OPTIONS.facility = obj;
        setSelectOptions({ ...selectOptions, facility: obj });
      })
      .catch((err) => {
        SELECT_OPTIONS.facility = {};
      });

    //get all insurance provider
    getAPIData(ApiConstant.DOCTOR_INSURANCE_PROVIDER)
      .then((res) => {
        let obj = res.map((item) => ({ ...item, label: item.name, value: item.name.toLowerCase() }));
        SELECT_OPTIONS.insurance_provider = obj;
        setSelectOptions({ ...selectOptions, insurance_provider: obj });
      })
      .catch((err) => {
        SELECT_OPTIONS.insurance_provider = {};
      });

    //get all doctor practice
    getAPIData(ApiConstant.DOCTOR_PRACTICE)
      .then((res) => {
        let obj = res.map((item) => ({ ...item, label: item.name, value: item.name.toLowerCase() }));
        SELECT_OPTIONS.practice = obj;
        setSelectOptions({ ...selectOptions, practice: obj });
      })
      .catch((err) => {
        SELECT_OPTIONS.practice = {};
      });

    //get all procedure
    getAPIData(ApiConstant.PROCEDURE)
      .then((res) => {
        let obj = res.map((item) => ({ ...item, label: item.name, value: item.name.toLowerCase() }));
        SELECT_OPTIONS.procedure = obj;
        setSelectOptions({ ...selectOptions, procedure: obj });
      })
      .catch((err) => {
        SELECT_OPTIONS.procedure = {};
      });

    //get all condition Treated
    getAPIData(ApiConstant.CONDITION_TREATED)
      .then((res) => {
        let obj = res.map((item) => ({ ...item, label: item.name, value: item.name.toLowerCase() }));
        SELECT_OPTIONS.condition_treated = obj;
        setSelectOptions({ ...selectOptions, condition_treated: obj });
      })
      .catch((err) => {
        SELECT_OPTIONS.condition_treated = {};
      });
  };
  async function setData() {
    await setSelectOptionsValue();
    fetchDoctorProfile();
  }
  useEffect(() => {
    setData();
  }, []);

  const setValueByFieldName = (fieldname, value) => {
    setProfileData(update(profileData, { [fieldname]: { $set: value } }));
  };

  const setLocationValues = (prop, val) => {
    setProfileData(update(profileData, { location: { [prop]: { $set: val } } }));
  };

  const handleSubmit = (payload) => {
    setLoader(true);
    axiosInstance.post(ApiConstant.UPDATE_DOCTOR_PROFILE, getParsedData(payload, fileName)).then(
      (response) => {
        setLoader(false);
        if (response && response.data) {
          const doctorProfileData = response.data.data || {};
          setEmail(doctorProfileData?.user?.email || '');
          setProfileData(initialState(doctorProfileData, selectOptions));
          setProfileInitialData(initialState(doctorProfileData));
        }
        toast.success(response?.data?.message);
      },
      (_error) => {
        setLoader(false);
        toast.error(_error);
      }
    );
  };

  return (
    <>
      {loader && <SpinnerComponent />}
      <div className="middle-content edit-profile user-right-container">
        <div className="pages">
          <div className="profile-information">
            <div className="form-step max-w-100">
              <form>
                <DoctorProfileImage
                  profileData={profileData}
                  intialProfileData={profileInitialData}
                  setValueByFieldName={setValueByFieldName}
                  updateProfileImage={handleSubmit}
                />
                <DoctorPersonalInformation
                  profileData={profileData}
                  setValueByFieldName={setValueByFieldName}
                  email={email}
                />
                <DoctorProfessionalInformation
                  profileData={profileData}
                  setValueByFieldName={setValueByFieldName}
                  options={SELECT_OPTIONS}
                  fileName={fileName}
                  setFilename={setFilename}
                />
                <DoctorBackgroundInformation
                  profileData={profileData}
                  setValueByFieldName={setValueByFieldName}
                  options={SELECT_OPTIONS}
                />
                <DoctorLocationInformation
                  profileData={profileData}
                  setValueByFieldName={setValueByFieldName}
                  options={SELECT_OPTIONS}
                  setLocationValues={setLocationValues}
                />
                <div className="btn-group justify-content-end w-100 pt-10 form-btn-group">
                  <Link to={RouteConstants.DASHBOARD_DOCTOR} className="btn mr-15 cancel-btn btn-secondary">
                    CANCEL
                  </Link>
                  <Button
                    disabled={!checkValidation(profileData, profileInitialData)}
                    type="button"
                    color="primary"
                    className="btn btn-primary"
                    onClick={()=>{handleSubmit(profileData)}}
                  >
                    SAVE
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={4000} hideProgressBar transition={Slide} />
    </>
  );
}

export default DoctorAccountInformation;
