import React from "react";
import moment from "moment";
import IconTooltip from "../../../../../../common/tooltip/IconTooltip";

const DocumentListItem = (props) => {
  const { item, onSelectListRow, onDownloadDocumentByItem } = props;

  return (
    <>
      <div className="divRow">
        <div className="table-col table-column-9" data-label="Select All">
          <label className="label--checkbox flat table-checkbox-position">
            <input
              type="checkbox"
              className="checkbox"
              id={`selectList${item?.id}`}
              onChange={() => {
                onSelectListRow(item?.id);
              }}
              checked={item?.checked}
            />
            <label
              className="font-16 font-500"
              htmlFor={`selectList${item?.id}`}></label>
          </label>
        </div>
        <div
          className="table-col table-column-18"
          title={item?.first_name}
          data-label="By">
          <span>{item?.first_name}</span>
        </div>
        <div
          className="table-col table-column-27"
          title={item?.document_name}
          data-label="File Name">
          <span>
            <a className="a-link theme-color" href={item?.document_url} target="_blank">
              {item?.document_name}
            </a>
          </span>
        </div>
        <div
          className="table-col table-column-22"
          title={moment(item?.created_at).format("ll")}
          data-label="Date">
          <span>{moment(item?.created_at).format("ll")}</span>
        </div>
        <div className="table-col table-column-24 d-flex" data-label="Actions">    
                <span
                  className="material-icons-sharp md-dark md-18"
                  onClick={()=>{onDownloadDocumentByItem(item?.id)}}
                  id="downloadAll">
                  file_download
                </span>
              {IconTooltip("bottom", "download", "Download", "All")}
        </div>
      </div>
    </>
  );
};

export default DocumentListItem;

