import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import { Button, Spinner } from 'reactstrap';
import axiosInstance from '../../../../../../axios/axiosInstance';
import ApiConstant from '../../../../../../constants/ApiConstant';
import { downloadFileFromBlob } from '../../../../../../utils/helper';

function CompletedAppointmentNotes(props) {
  const {
    item: doctor_data,
    index: key,
    viewHistoryOpen
  } = props;
  const [noteLoader, setNoteLoader] = useState(false);
  const [patientNotes, setPatientNotes] = useState('');

  const fetchPatientNotes = async() => {
    setNoteLoader(true);
    await axiosInstance.get(`${ApiConstant.APPOINTMENT_NOTES}`, {params: { appointment_id: doctor_data.appointment_id }})
    .then((response) => {
      if (response && response.data && response.data.data) {
        const updatedPatientNotes = response.data.data?.notes || '';
        setPatientNotes(updatedPatientNotes);
      }
    })
    .catch((error) => {toast.error(error || '')});
    setNoteLoader(false);
  }

  const downloadNotes = async() => {
    await axiosInstance.get(`${ApiConstant.PATIENT_DOWNLOAD_NOTES}`, {
      responseType : 'blob',
      params: { appointment_id: doctor_data?.appointment_id
    }})
    .then((response) => response.data).then((blob)=>{
      downloadFileFromBlob(blob, 'Notes.pdf');
    })
    .catch((error) => {toast.error(error || '')});
  };

  useEffect(() => {
    viewHistoryOpen && fetchPatientNotes();
  }, [viewHistoryOpen]);

  return (
    
         <div className={`body profile-info position-relative d-block`}>
           {noteLoader &&
            <div className="spinner-component full-page-spinner position-absolute">
              <Spinner/>
             </div>
           }
          <div className="row patient-notes-row">
             <h2 className="view-history-heading">Notes
             <Button
                 type="button"
                 onClick={downloadNotes}
                 hidden={!patientNotes}
               >
                 <span class="material-icons-sharp">download</span>
               </Button>
             </h2>
             {patientNotes ?
                 <div className="col-12">
                   <p className='font-14 font-400 gray666 mb-0'>
                     {patientNotes || '-'}
                   </p>
                 </div>
               : <p className="no-notes-text">No notes avaliable</p>}
           </div>
         </div>
  );
}

export default CompletedAppointmentNotes;
