import { useState } from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Image } from '../../../../common/image/Image';
import { ApplicationConstant, RouteConstants } from '../../../../../constants/application.constant';
import CancelAppointmentModal from '../../../../common/modal/CancelAppointmentModal';
import { convertUtcToLocalTime, timeZoneFormats } from '../../../../../utils/TimezoneUtils';
import { getSpecialzationName } from '../../../../../utils/helper';

function PatientUpcomingAppointmentItem(props) {
  const { item, cancelBooking } = props;
  const history = useHistory();
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const currentTime = moment().format('YYYY-MM-DD HH:mm:ss');
  const startSessionPreparatoryTime = moment().add(ApplicationConstant.TEN, 'minutes').format(timeZoneFormats.FULL_TIME);
  const [appointmentButton, setAppointmentButton] = useState(false);
  const [isAppointmentExpire, setAppointmentExpire] = useState(false)
  const toggle = () => { setCancelModalOpen(!cancelModalOpen) }

  const cancelPatientAppointment = () => {
    cancelBooking(item.appointment_id);
    setCancelModalOpen(false)
  };

  const appointmentActionButtonStatus = () => {
    if(convertUtcToLocalTime(item?.start_date_time, timeZoneFormats.FULL_TIME) > startSessionPreparatoryTime) {
      setAppointmentButton(true);
    } else {
      setAppointmentButton(false);
      convertUtcToLocalTime(item?.end_date_time, timeZoneFormats.FULL_TIME) < currentTime && setAppointmentExpire(true)
    }
  }

  const openSessionPage = (e) =>{
    e.preventDefault();
    history.push({
      pathname: `${RouteConstants.PATIENT_SESSION_CONTAINER}`,
      state: { oMeeting: item },
    });

  }

  const buttonStatus = () => {
    switch (item?.appointment_status_id) {

      case 1:
        return <Button className="btn-pending" color="primary" disabled>Request Pending</Button>
      case 2:
      case 4:
        return <Button
                className="btn mb-15 btn-primary small"
                color="primary"
                disabled={appointmentButton}
                onClick={openSessionPage}
              >
                Start Session
              </Button>;
      case 3:
        return <Button className="btn mb-15 btn-primary small" color="primary" disabled>Completed</Button>;
      default:
        return null;
    }
  }

  useState(()=>{
    appointmentActionButtonStatus();
  },[])
  return (
    <>
      <div className='user-info'>
        <div className='profile-img'>
          <Image src={item?.user?.profile_image || ''} alt='Profile image' />
        </div>
        <div className='head'>
          <div className='title-text'>
            <h1 className='font-500 font-16'>
              {item?.user?.first_name}&nbsp;{item?.user?.last_name}
            </h1>
            <h2>
              {getSpecialzationName(item?.doctor_specializations,item?.specialization_id)}
            </h2>
            <div className='datetime'>
              <span className='material-icons'>event</span>
              <p>
                {convertUtcToLocalTime(item?.start_date_time, 'll')}{' | '}
                {convertUtcToLocalTime(item?.start_date_time, 'hh:mm A')}{' - '}
                {convertUtcToLocalTime(item?.end_date_time, 'hh:mm A')}
              </p>
            </div>
          </div>
          <div className="action-status patient-action-status">
            {!isAppointmentExpire ? (
              <>
              {buttonStatus()}
              <Button color="primary" className="btn-outline" color="primary" onClick={toggle}>Cancel</Button>
              </>
            ):''}
          </div>
        </div>
      </div>
      <CancelAppointmentModal
        isOpen={cancelModalOpen}
        setModelOpen={toggle}
        cancelPatientAppointment={cancelPatientAppointment}
        wrapClassName="modal-top"
      />
    </>
  );
}

export default PatientUpcomingAppointmentItem;
