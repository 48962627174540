import React from 'react';
import { useHistory } from 'react-router';
import { UncontrolledTooltip } from 'reactstrap';
import {
  ApplicationConstant,
  profileKey,
  RouteConstants,
  targetedId,
} from '../../../../../constants/application.constant';
import { getSpecialzationName } from '../../../../../utils/helper';
import { convertUtcToLocalTime } from '../../../../../utils/TimezoneUtils';
import GoBack from '../../../../common/back/GoBack';
import { Image } from '../../../../common/image/Image';
import ProfileTooltip from '../../../../common/profileElements/ProfileTooltip';

function DoctorProfile(props) {
  const { doctorProfile, id: key, oMeeting , specializations } = props;
  const history = useHistory();
  const sendEmail = (emailId) => {
    if (emailId) {
      window.location.assign(`mailto:${emailId}`);
    }
  };

  return (
    <>
      <div className="back-link">
        <GoBack heading={"Telehealth video"}/>
      </div>
      <section className="detail-info zoom pb-25">
        <div className="profile-info">
          <div className="row">
            <div className="col-sm-12 col-md-12">
              <div className="box">
                <div className="card-row">
                  <div className="row">
                    <div className="col-lg-4 col-md-12 profile-info">
                      <div className="user-info">
                        <div className="profile-img" style={{ cursor: 'pointer' }}>
                          <Image src={doctorProfile?.profile_image || '-'} alt="Profile image" />
                        </div>
                        <div className="head">
                          <div className="title-text">
                            <h1>
                              {doctorProfile?.first_name} {doctorProfile?.last_name}
                            </h1>
                            <h2>{getSpecialzationName(doctorProfile?.doctor_specializations, specializations?.specialization_id)}</h2>
                            <div class="datetime">
                              <span class="material-icons">event</span>
                              <p>
                                {convertUtcToLocalTime(
                                  oMeeting?.start_date_time,
                                  'dddd MMM, DD yyyy'
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 d-flex align-items-center">
                      <div className="profile-info w-100">
                        <div className="row">
                          <div className="col-md-6 col-sm-6">
                            <label>Pronouns</label>
                            <div className="value more-items">
                              {doctorProfile?.user_pronouns?.length
                                ? ProfileTooltip(
                                    doctorProfile?.user_pronouns,
                                    targetedId.PATIENT,
                                    profileKey.TITLE,
                                    profileKey.OTHER_PRONOUN
                                  )
                                : '-'}
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-6">
                            <label>Age</label>
                            <div className="value">
                              {doctorProfile?.age ? `${doctorProfile?.age} Years` : '-'}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-2 d-flex align-items-center justify-content-end mb-20">
                      <div className="action-status d-flex">
                        <a
                          href
                          class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8"
                          id={`email-btn-${key}`}
                          onClick={() => {
                            sendEmail(doctorProfile?.email);
                          }}
                        >
                          <span class="material-icons md-dark md-18">email</span>
                        </a>
                        <UncontrolledTooltip
                          className="moreItemTooltiip"
                          placement="bottom"
                          target={`email-btn-${key}`}
                        >
                          <span>Mail</span>
                        </UncontrolledTooltip>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="profile-info user-info-detail">
                  <div className="row">
                    <div className="col-md-3 col-sm-6">
                      <label>Sexual Orientation</label>

                      <div className="value more-items">
                        {doctorProfile?.user_sexual_orientations?.length
                          ? ProfileTooltip(
                              doctorProfile?.user_sexual_orientations,
                              targetedId.PATIENT,
                              profileKey.NAME,
                              profileKey.OTHER_SEXUAL_ORIENTATION
                            )
                          : '-'}
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                      <label>Gender Identity</label>
                      <div className="value more-items">
                        {doctorProfile?.user_identifications?.length
                          ? ProfileTooltip(
                              doctorProfile?.user_identifications,
                              targetedId.PATIENT,
                              profileKey.TITLE,
                              profileKey.OTHER_IDENTIFICATION
                            )
                          : '-'}
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                      <label>Race</label>
                      <div className="value more-items">
                        {doctorProfile?.user_races?.length
                          ? ProfileTooltip(
                              doctorProfile?.user_races,
                              targetedId.PATIENT,
                              profileKey.NAME,
                              profileKey.OTHER_RACE
                            )
                          : '-'}
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                      <label>Ethnicity</label>
                      <div className="value">{doctorProfile?.ethnicity || '-'}</div>
                    </div>
                    <div className="col-md-12 col-sm-12">
                      <label>Language Spoken</label>

                      <div className="value more-items">
                        {doctorProfile?.user_languages?.length
                          ? ProfileTooltip(
                              doctorProfile?.user_languages,
                              targetedId.PATIENT,
                              profileKey.NAME,
                              profileKey.OTHER_LANGUAGE
                            )
                          : '-'}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DoctorProfile;

