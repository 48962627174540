import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { logout } from '../../../utils/CommonRequests';
import CustomModal from '../../common/modal/CustomModal';
import { Image } from './../../common/image/Image';
import { ModalConstants, RouteConstants } from '../../../constants/application.constant';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, FormGroup, Input } from 'reactstrap';
import { HEADERLOGO } from '../../../constants/image';
import { getProfileImage } from '../../../utils/helper';

export const HospitalHeader = (props) => {
  const [isOpen, setModalOpen] = useState(false);
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const toggleLogOutModal = () => setModalOpen(!isOpen);

  const onSignOut = (isSignOut) => {
    toggleLogOutModal();
    if (isSignOut) {
      logout();
    }
  };

  return (
    <header>
      <div className="container">
        <div className="headerSection">
          <Link className="header-logo" to={RouteConstants.DASHBOARD_HOSPITAL}>
            <img src={HEADERLOGO} all="logo" />
          </Link>
          <nav className="nav d-none">
            <ul>
              <li>
                <NavLink className="active" to="/">
                  {' '}
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink to="/">Find a Doctor</NavLink>
              </li>
            </ul>
          </nav>
          <div className="small-right-nav">
            <ul>
              <li>
                <div className="header-search d-none">
                  <FormGroup>
                    <Input type="text" name="search" id="mainSearch" placeholder="Search" />
                  </FormGroup>
                </div>
                <a>
                  <span className="material-icons">search</span>
                </a>
              </li>
              <li>
                <a>
                  <ButtonDropdown className="profile-dropdown" direction="left" isOpen={dropdownOpen} toggle={toggle}>
                    <DropdownToggle>
                      <div className="profile-img">
                        <Image src={getProfileImage()} all="Profile Image" />
                        <span className="material-icons md-36 d-none">account_circle</span>
                      </div>
                      <span className="material-icons md-18">expand_more</span>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem header>Account Details</DropdownItem>
                      <DropdownItem tag={Link} to={RouteConstants.HOSPITAL_ACCOUNT_INFORMATION}>
                        <span className="material-icons">place</span> Account Information
                      </DropdownItem>
                      <DropdownItem tag={Link} to={RouteConstants.CHANGE_PASSWORD}>
                        <span className="material-icons">https</span> Change Password
                      </DropdownItem>
                      <DropdownItem onClick={toggleLogOutModal}>
                        <span className="hospital-logout-btn d-flex">
                          <span className="material-icons-outlined">logout</span>Logout
                        </span>
                      </DropdownItem>
                    </DropdownMenu>
                    <CustomModal
                      modalTitle={ModalConstants.SIGN_OUT_TITLE}
                      modalMessage={ModalConstants.SIGN_OUT_MESSAGE}
                      secondaryButonText={ModalConstants.CANCEL}
                      primaryButtonText={ModalConstants.SIGN_OUT}
                      setModelOpen={toggleLogOutModal}
                      isOpen={isOpen}
                      onButtonClicked={onSignOut}
                      modalClassName="modal-logout"
                    />
                  </ButtonDropdown>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};
