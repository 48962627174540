import { ApplicationConstant, RegExConstant } from '../../constants/application.constant';

export const checkValidation = (oContact) => {
  const isValidRole = oContact?.role?.id || '';
  const isValidFirstName = oContact?.first_name?.value;
  const isValidLastName = oContact?.last_name?.value;
  const isValidEmail = isValidEmailAddress(oContact);
  const isValidPhone = isValidPhoneNumber(oContact);
  const isValidQuestion = isValidQuestionType(oContact);
  if (isValidRole && isValidQuestion && isValidFirstName && isValidLastName && isValidEmail && isValidPhone) {
    return true;
  }
  return false;
};

export const isValidEmailAddress = (oContact) => {
  const emailValue = oContact?.email?.value || '';
  if (emailValue && RegExConstant.EMAIL_REG.test(emailValue)) {
    return true;
  }
  return false;
};

export const isValidPhoneNumber = (oContact) => {
  const phoneValue = oContact?.phone?.value || '';
  if (phoneValue && phoneValue.length === ApplicationConstant.TEN) {
    return true;
  }
  return false;
};

export const isValidQuestionType = (oContact) => {
  const oQuestion = oContact?.questionType?.value || null;
  const query = oContact?.query || '';
  if (query && oQuestion) {
    return true;
  }
  return false;
};

export const createContactPayload = (oContact) => {
  return {
    role_id: oContact?.role?.id,
    first_name: oContact?.first_name?.value,
    last_name: oContact?.last_name?.value,
    email: oContact?.email?.value,
    phone: oContact?.phone?.value,
    contact_question_type_id: oContact?.questionType?.id,
    query: oContact?.query,
  };
};
