import React, { useState } from 'react';
import UserRegistrationInitialState from './UserRegistrationInitialState';
import UserRegistrationContext from './UserRegistrationContext';

const UserRegistrationProvider = (props) => {
  const [oUserRegistration, setUserRegistration] = useState(UserRegistrationInitialState);
  return (
    <UserRegistrationContext.Provider value={[oUserRegistration, setUserRegistration]}>
      {props.children}
    </UserRegistrationContext.Provider>
  );
};

export default UserRegistrationProvider;
