import React from 'react';
import ReactPaginate from 'react-paginate';
import { ApplicationConstant, TableClassNameObj } from '../../../../constants/application.constant';
import PaymentHistoryHeader from './PaymentHistoryHeader';
import PaymentHistoryItem from './PaymentHistoryItem';

function PaymentHistoryList(props) {
  const { paymentHistoryData, pageCount, changePagination, listInitialPage, sortTableData, sortObj} = props;
  const tableContent = [
    { title: 'Name', sortKey: 'name', class: `${TableClassNameObj.col14}`, sort: true },
    { title: 'Source', sortKey: 'payment_type', class: `${TableClassNameObj.col12}`, sort: true },
    { title: 'Date', sortKey: 'payment_date', class: `${TableClassNameObj.col14}`, sort: true },
    { title: 'Consultation Fee', sortKey: 'amount', class: `${TableClassNameObj.col16}`, sort: true },
    { title: 'Refund Amount', sortKey: 'refund_amount', class: `${TableClassNameObj.col16}`, sort: true },
    { title: 'Action', class: `${TableClassNameObj.col20}`, sort: false },
  ];
    return (
      <>
        <div className="table-list">
          <div className="divTable patient-payment-table">
            <div className="headRow">
              <PaymentHistoryHeader tableHeadContent={tableContent} sortData={sortTableData} filter={sortObj}/>
            </div>
            { paymentHistoryData.map((item , i) => {
              return <PaymentHistoryItem key={item.id} item={item}/>
            })  }
          </div>
          <div className="pagination-wrapper" hidden={pageCount === ApplicationConstant.ONE}>
          <ReactPaginate
            previousLabel={'previous'}
            nextLabel={'next'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={4}
            onPageChange={changePagination}
            pageRangeDisplayed={1}
            forcePage={listInitialPage}
            containerClassName={'pagination'}
            activeClassName={'active'}
            nextLinkClassName={'next-btn'}
            previousLinkClassName={'prev-btn'}
          />
        </div>
        </div>
      </>
    );
}

export default PaymentHistoryList;




