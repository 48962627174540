import axios from 'axios';
import StorageConstant from '../constants/StorageConstant';
import { HttpStatusCode } from '../constants/application.constant';
import { logout } from '../utils/CommonRequests';
import { isAuthorized } from '../utils/helper';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_URL,
});

axiosInstance.interceptors.request.use(
  (request) => {
    const token = localStorage.getItem(StorageConstant.TOKEN);
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error && error.response) {
      if (error.response.status === HttpStatusCode.UNAUTHORISED && isAuthorized()) {
        logout();
      } else {
        const data = error?.response?.data || null;
        if (data && data.message) {
          return Promise.reject(data.message);
        } else {
          return Promise.reject(error);
        }
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
