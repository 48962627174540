import React, { useState, useEffect } from 'react';
import { Collapse, CardBody, Card, CardHeader } from 'reactstrap';
import { ApplicationConstant } from '../../constants/application.constant';
import RadioToggleElement from '../common/formElements/RadioToggleElement';
import InputElement from '../common/formElements/InputElement';
import { Button } from 'reactstrap';
import axiosInstance from '../../axios/axiosInstance';
import ApiConstant from '../../constants/ApiConstant';
import SpinnerComponent from '../common/spinner/SpinnerComponent';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function FaqQuestionItem(props) {
  const { aQuestions } = props;
  const [isLoading, setLoading] = useState(false);
  const [aFaqQuestions, setFaqQuestions] = useState([]);

  useEffect(() => {
    const aData = aQuestions.map((item) => ({
      ...item,
      isInputOpen: false,
      query: '',
      selectedValue: '',
    }));
    setFaqQuestions(aData);
  }, [aQuestions]);

  const handleClick = (index) => {
    const aData = JSON.parse(JSON.stringify(aFaqQuestions));
    aData[index].isOpen = !aData[index].isOpen;
    setFaqQuestions(aData);
  };

  const handleOnChange = (e, index) => {
    const aData = JSON.parse(JSON.stringify(aFaqQuestions));
    aData[index].query = e.target.value;
    setFaqQuestions(aData);
  };

  const onSelectionChange = (e, index) => {
    const aData = JSON.parse(JSON.stringify(aFaqQuestions));
    const value = e.target.value;
    if (value === ApplicationConstant.YES_SHORT) {
      aData[index].isInputOpen = false;
    } else {
      aData[index].isInputOpen = true;
    }
    aData[index].selectedValue = value;
    setFaqQuestions(aData);
  };

  const onSubmitQuery = (e, index) => {
    e.preventDefault();
    setLoading(true);
    axiosInstance.post(ApiConstant.SUBMIT_FAQ_FEEDBACK, createPaylod(index)).then(
      (res) => {
        setLoading(false);
        const aData = JSON.parse(JSON.stringify(aFaqQuestions));
        aData[index].query = '';
        aData[index].isInputOpen = false;
        aData[index].isOpen = false;
        aData[index].selectedValue = '';
        setFaqQuestions(aData);
        toast.success(res?.data?.message || '');
      },
      (error) => {
        setLoading(false);
        toast.error(error || '');
      }
    );
  };

  function createPaylod(index) {
    const aData = JSON.parse(JSON.stringify(aFaqQuestions));
    const oQuestion = aData[index];
    return {
      faq_id: oQuestion?.id,
      description: oQuestion?.query?.trim(),
      is_helpful: ApplicationConstant.NO_SHORT,
    };
  }

  function createMarkup(data) {
    return { __html: data };
  }


  return (
    <>
      {isLoading && <SpinnerComponent />}
      {aFaqQuestions?.length > ApplicationConstant.ZERO
        ? aFaqQuestions.map((item, index) => {
            return (
              <Card key={index}>
                <CardHeader onClick={() => handleClick(index)}>
                  <strong>{item?.question}</strong>
                  <span className="material-icons md-20">{item.isOpen ? 'expand_less' : 'expand_more'}</span>
                </CardHeader>
                <Collapse isOpen={item.isOpen ? true : false}>
                  <CardBody>
                    <div dangerouslySetInnerHTML={createMarkup(item?.answer)}></div>
                    <RadioToggleElement
                      classNm={'plane-radio'}
                      label={'Was this question helpful?'}
                      name={`faq${item.id}`}
                      selectedValue={item?.selectedValue}
                      onChange={(e) => onSelectionChange(e, index)}
                    />
                    {item?.isInputOpen ? (
                      <div className="query-box">
                        <InputElement
                          className="col-sm-12"
                          type="textarea"
                          name={`query${item.id}`}
                          placeholder={'Please enter your query'}
                          label={'Your reason*'}
                          onChange={(e) => handleOnChange(e, index)}
                          value={item.query || ''}
                        />
                        <div className="text-right">
                          <Button
                            disabled={!item?.query?.trim()}
                            className="btn btn-primary w-120"
                            color="primary"
                            onClick={(e) => onSubmitQuery(e, index)}
                          >
                            SUBMIT
                          </Button>
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </CardBody>
                </Collapse>
                <ToastContainer autoClose={4000} hideProgressBar transition={Slide} />
              </Card>
            );
          })
        : ''}
    </>
  );
}

export default FaqQuestionItem;
