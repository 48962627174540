import React, { useState } from 'react';
import { Progress } from 'reactstrap';
import axiosInstance from '../../../axios/axiosInstance';
import ApiConstant from '../../../constants/ApiConstant';
import { ApplicationConstant } from '../../../constants/application.constant';


function FileUploadButton(props) {
  const {
    onSuccess,
    onFailure,
    url=ApiConstant.UPLOAD_DOCUMENT,
    formFieldName = 'document',
    uploadText = 'Upload File',
  } = props;
  const [modal, setModal] = useState(false);
  const [progressPercent, setProgressPercent] = useState(0);
  const [loader, setLoader] = useState(false);
  const [, setValidation] = useState({ value: [], valid: true });

  const onUploadProgress = (event) => {
    const percent = Math.round((event.loaded * 100) / event.total);
    setProgressPercent(percent);
  };

  const toggle = () => {
    setModal(!modal)
  };

  let fileInput = null;

  const removeFile = (e) => {
    e?.preventDefault();
    if (!!fileInput) {
      fileInput.value = '';
    }
    props.onRemove && props.onRemove();
  };

  const onUploadFile = (fileToUpload) => {
    if (fileToUpload) {
      setLoader(true);
      const formData = new FormData();
      const config = { headers: { 'Content-Type': 'multipart/form-data' },  onUploadProgress: onUploadProgress };
      formData.append(formFieldName, fileToUpload, fileToUpload.name);
      axiosInstance
        .post(`${url}`, formData, config)
        .then(function (response) {
          onSuccess && onSuccess({file:fileToUpload, res:response.data});
          toggle();
        })
        .catch(function (err) {
          setValidation({ valid: false, value: [err] });
          onFailure && onFailure(err);
          removeFile();
        })
        .finally(() => {
          setLoader(false);
          setProgressPercent(ApplicationConstant.ZERO);
        });
    }
  };

  const selectFile = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    e.target.value = null;
    if (!!file) {
      onUploadFile(file);
    }

  };

  return (
    <div class="upload-btn-wrapper" style={{ cursor: 'pointer' }}>
      {loader &&
        <div className={"mb-12"}>
          <Progress color="warning" value={progressPercent}>{`${progressPercent}%`}</Progress>
        </div>
      }
      <label className="btn btn-secondary">
      <input
        ref={(input) => {
          fileInput = input;
        }}
        type="file"
        name="myfile"
        onChange={selectFile}
      />{uploadText}</label>

    </div>
  );
}

export default FileUploadButton;
