import React, { useContext, useEffect, useState } from 'react';
import { ApplicationConstant, PageTitles } from '../../../constants/application.constant';
import useDocumentTitle from '../../../utils/DocumentTitle';
import HospitalRecentActivity from './HospitalRecentActivity';
import HospitalProfile from './HospitalProfile';
import axiosInstance from '../../../axios/axiosInstance';
import ApiConstant from '../../../constants/ApiConstant';
import HospitalProviders from './HospitalProviders';
import update from 'immutability-helper';
import UserProfileContext from '../../../contexts/user-profile/UserProfileContext';
import { useHistory, useLocation } from 'react-router-dom';
import { ToastContainer, toast, Slide } from 'react-toastify';

const HospitalDashboard = () => {
  useDocumentTitle(PageTitles.HOSPITAL_DASHBOARD);
  const [oUserGenericData, setUserGenericData]= useContext(UserProfileContext)
  const [oProfile, setProfile] = useState(null);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    axiosInstance.get(`${ApiConstant.HOSPITAL_DASHBOARD}`).then((res) => {
      if (res && res.data) {
        const oProfile = res.data.data || {};
        setProfile(oProfile);
        setUserGenericData(update(oUserGenericData, {'profile_image': { $set:oProfile?.profile_image }}));
      }
    });
    if(!!location?.state?.msg){
      toast.success(location.state?.msg);
      history.replace({});
    }
  }, []);
  return (
    <>
      <div className="dashboard-page">
        <div className="container">
          <section className="detail-info">
            <div className="welcome">
              <h1>Welcome to the dashboard!</h1>
            </div>
            <div className="profile-info">
              <div className="row">
                <HospitalProfile oHospitalProfile={oProfile} />
                <HospitalRecentActivity />
              </div>
            </div>
          </section>
          <HospitalProviders oHospitalProfile={oProfile} limit={ApplicationConstant.THREE}/>
        </div>
      </div>
      <ToastContainer autoClose={4000} hideProgressBar transition={Slide} />
    </>
  );
};

export default HospitalDashboard;
