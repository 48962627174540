import React from 'react';
import { profileKey, targetedId } from '../../../constants/application.constant';
import ProfileTooltip from '../../common/profileElements/ProfileTooltip';

function DoctorProfileBasicInfo(props) {
  const { profileInfo } = props;
  return (
    <>
      <div className="col-md-4 col-sm-6">
        <label>Pronouns</label>
        <div className="value more-items">
          {profileInfo?.doctor_profile?.user_pronouns?.length
            ? ProfileTooltip(
                profileInfo.doctor_profile?.user_pronouns,
                targetedId.DOCTOR,
                profileKey.TITLE,
                profileKey.OTHER_PRONOUN
              )
            : "-"}
        </div>
      </div>
      <div className="col-md-4 col-sm-6">
        <label>Age</label>
        {profileInfo?.doctor_profile?.age ? (
          <div className="value">{profileInfo?.doctor_profile?.age} Years</div>
        ) : (
          <>-</>
        )}
      </div>
      <div className="col-md-4 col-sm-6">
        <label>Race</label>
        <div className="value more-items">
          {profileInfo?.doctor_profile?.user_races?.length
            ? ProfileTooltip(
                profileInfo.doctor_profile?.user_races,
                targetedId.DOCTOR,
                profileKey.NAME,
                profileKey.OTHER_RACE
              )
            : "-"}
        </div>
      </div>
      <div className="col-md-4 col-sm-6">
        <label>Ethnicity</label>
        <div className="value">{profileInfo?.doctor_profile?.ethnicity}</div>
      </div>
      <div className="col-md-4 col-sm-6">
        <label>Language Spoke</label>
        <div className="value more-items">
          {profileInfo?.doctor_profile?.user_languages?.length
            ? ProfileTooltip(
                profileInfo?.doctor_profile?.user_languages,
                targetedId.DOCTOR,
                profileKey.NAME,
                profileKey.OTHER_LANGUAGE
              )
            : "-"}
        </div>
      </div>
      <div className="col-md-4 col-sm-6">
        <label>Gender Identity</label>
        <div className="value more-items">
          {profileInfo?.doctor_profile?.user_identifications?.length
            ? ProfileTooltip(
                profileInfo?.doctor_profile?.user_identifications,
                targetedId.DOCTOR,
                profileKey.TITLE,
                profileKey.OTHER_IDENTIFICATION
              )
            : "-"}
        </div>
      </div>
      <div className="col-md-4 col-sm-6">
        <label>Sexual Orientation</label>
        <div className="value more-items">
          {profileInfo?.doctor_profile?.user_sexual_orientations?.length
            ? ProfileTooltip(
                profileInfo?.doctor_profile?.user_sexual_orientations,
                targetedId.DOCTOR,
                profileKey.NAME,
                profileKey.OTHER_SEXUAL_ORIENTATION
              )
            : "-"}
        </div>
      </div>
      <h2 className="profile-section-head">Contact Information</h2>
      <div className="col-md-4 col-sm-6">
        <label>Birthday</label>
        <div className="value">
          {profileInfo?.doctor_profile?.date_of_birth}
        </div>
      </div>
      <div className="col-md-4 col-sm-6">
        <label>Email Address</label>
        <div className="value">{profileInfo?.doctor_profile?.email}</div>
      </div>
      <div className="col-md-4 col-sm-6">
        <label>Phone Number</label>
        <div className="value">{profileInfo?.doctor_profile?.phone}</div>
      </div>
    </>
  );
}

export default DoctorProfileBasicInfo;

