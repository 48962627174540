import React, { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';
import DropdownComponent from '../../../../../common/dropdown/DropdownComponent';
import { AllAppointmentsTypes, getDoctorHistoryList, resetDoctorHistoryScrollPosition } from './DoctorHistoryUtils';
import { downloadDoctorDocument } from '../documents/DoctorDocumentUtils';
import { ApplicationConstant } from '../../../../../../constants/application.constant';
import HistoryList from './historyList/HistoryList';
import { EMPTY_ICON } from '../../../../../../constants/image';
import EmptyStateComponent from '../../../../../common/emptyStateComponent/EmptyStateComponent';
import { downloadFileFromBlob } from '../../../../../../utils/helper';

function DoctorHistory(props) {
    const { userId } = props;
    const tableScrollRef = useRef(null);
    const [appointmentType, setAppointmentType] = useState(ApplicationConstant.ONE);
    const [currentPage, setCurrentPage] = useState(ApplicationConstant.ONE);
    const [sortObj, setSortObj] = useState({ sortBy: '', order: '' });
    const [listLoader, setListLoader] = useState(false);
    const [totalPageLimit, setTotalPageLimit] = useState(ApplicationConstant.ZERO);
    const [historyList, setHistoryList] = useState([]);
    const [scrollLoader, setScrollLoader] = useState(false);

    const fetchDoctorHistory = async(id, statusId, page, sortKey) => {
      setListLoader(true);
      await getDoctorHistoryList(id, statusId, page, sortKey).then((response) => {
        if(response && response.data) {
          const doctorHistoryList = response.data?.data?.data || [];
          const doctorTotalListCount = response.data?.data?.last_page || ApplicationConstant.ZERO;
          setHistoryList(doctorHistoryList);
          setTotalPageLimit(doctorTotalListCount);
          resetDoctorHistoryScrollPosition(tableScrollRef);
        }
      })
      .catch(error => toast.error(error || ''));
      setListLoader(false);
    }

    const fetchMoreHistoryList = async () => {
      if(currentPage < totalPageLimit) {
        setScrollLoader(true);
        await getDoctorHistoryList(userId, appointmentType, currentPage + ApplicationConstant.ONE, sortObj)
        .then((response) => {
          if(response && response.data) {
            const updatedList = response.data?.data?.data || [];
            const historyUpdatedList = [...historyList, ...updatedList];
            setHistoryList(historyUpdatedList);
            setCurrentPage(currentPage + ApplicationConstant.ONE);
          }
        }).catch(error => {toast.error(error || '');});
        setScrollLoader(false);
      }
    }

    const onChangeAppointmentType = (value) => {
      setCurrentPage(ApplicationConstant.ONE);
      setAppointmentType(value);
    };

    const sortHistoryList = (sortBy, order) => {
      setCurrentPage(ApplicationConstant.ONE);
      setSortObj({ sortBy, order });
    };

    const collapseListDetail = (itemId) => {
      let checkedList = historyList;
      const targetIdIndex = checkedList.findIndex(e => e.id === itemId);
      if(targetIdIndex > -1) {
        checkedList[targetIdIndex].checked = !checkedList[targetIdIndex].checked;
      }
      setHistoryList([...checkedList])
    }

    const onDownloadDocument = async(documentId, documentName) => {
      await downloadDoctorDocument(documentId).then((response)=> response.data)
      .then((blob)=>{downloadFileFromBlob(blob, documentName);
      }).catch((error) => toast.error(error || ''));
    }

    useEffect(() => {
      fetchDoctorHistory(userId, appointmentType, currentPage, sortObj);
    }, [appointmentType, sortObj]);

    return (
        <>
      <div className="body document-tab-body">
        {listLoader &&
          <div className="spinner-component full-page-spinner position-absolute">
            <Spinner/>
          </div>
        }
        <div className="dropdown-btn">
          <DropdownComponent
            placeholder={"All Appointment"}
            showFirstItem={false}
            items={AllAppointmentsTypes}
            onValueSelected={onChangeAppointmentType}
          />
        </div>
        <div class="divTable d-flex-table history-table">
          {historyList && historyList?.length ?
            <HistoryList
              historyList={historyList}
              sortFilter={sortObj}
              sortList={sortHistoryList}
              fetchMoreData={fetchMoreHistoryList}
              scrollLoader={scrollLoader}
              tableScrollRef={tableScrollRef}
              onSelectListRow={collapseListDetail}
              totalPageLimit={totalPageLimit}
              onDownloadDocument={onDownloadDocument}
            />
          : <EmptyStateComponent imageSrc={EMPTY_ICON} title='No Result Found.'/>}
        </div>
      </div>
    </>
    )
}

export default DoctorHistory;


