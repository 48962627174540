import React, { useState } from 'react';
import Select, { components} from 'react-select';
import makeAnimated from 'react-select/animated'
import { FormGroup } from 'reactstrap';

const Option = props => {
  return (
    <div>
      <components.Option {...props}>
      <input style={{float:'right'}}
          type="checkbox"
          className="checkboxselect"
          checked={props.isSelected}
          onChange={() => null}
        />
        <label style={{marginLeft:'0', width:'100%'}} >{props.label}</label>{" "}
      </components.Option>
    </div>
  );
};

const MultiValue = props => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);



const animatedComponents = makeAnimated();

function SelectElement({ classNm, name, isMulti=false, onChange, options, value, isSearchable, placeholder, label, ...props}) {
    const [focus, setFocus] = useState(false);
    return (
        <div className={classNm}>
            <FormGroup>
              <div className="label-float">
                <Select type="select"
                  name={name}
                  id={name}
                  isMulti={isMulti}
                  data-name={name}
                  onChange={onChange}
                  onFocus={(e)=>{setFocus(true)}}
                  onBlur={(e)=>{if(!e.value){setFocus(false)}}}
                  className="reactSelect"
                  classNamePrefix="react-select"
                  options={options}
                  value={value}
                  isSearchable={isSearchable}
                  placeholder={placeholder}
                  closeMenuOnSelect={!props.stayMenuOnSelect}
                  hideSelectedOptions={false}
                  components={isMulti &&{ Option, MultiValue, animatedComponents}}
                  {...props}
                />
                <label className={`selectLabel ${value || focus ? 'selected' : ''}`}>{label}</label>
              </div>
            </FormGroup>
        </div>
    )
}

export default SelectElement;
