import React, { useState } from 'react'
import Select from 'react-select';

function FormSelect() {
  const[state, setState]= useState(null)
  const options = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
  ];
  const handleChange = selectedOption => {
    setState({ selectedOption });
    console.log(`Option selected:`, selectedOption);
  };
  
  return (
    <div className="label-float">
        <Select
          value={state}
          onChange={handleChange}
          options={options}
          className="reactSelect"
          classNamePrefix="react-select"
        />
      <label className={`selectLabel ${state ? 'selected' : ''}`}>NPT Type*</label>
    </div>
  )
}

export default FormSelect