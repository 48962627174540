import React,{useEffect, useState} from 'react';
import { useHistory } from 'react-router';
import { Slide, ToastContainer } from 'react-toastify';
import axiosInstance from '../../../../axios/axiosInstance';
import ApiConstant from '../../../../constants/ApiConstant';
import { ApplicationConstant, InitialPageLimit, PageTitles } from '../../../../constants/application.constant';
import useDocumentTitle from '../../../../utils/DocumentTitle';
import { getPageParams } from '../../../../utils/helper';
import SpinnerComponent from '../../../common/spinner/SpinnerComponent';
import PaymentHistoryList from './PaymentHistoryList';


function PatientPaymentHistory() {
  useDocumentTitle(PageTitles.PATIENT_PAYMENT_HISTORY);
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [paymentHistoryData, setPaymentHistoryData] = useState({});
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(getPageParams(history, 'page'));
  const [listInitialPage, setListInitialPage] = useState(getPageParams(history, 'page') - 1);
  const [sortObj, setSortObj] = useState({ sortBy: '', order: '' });



  const fetchPaymentHistory = async(page, sortKey) => {
    setLoader(true);
    await axiosInstance
      .get(
        `${ApiConstant.PATIENT_PAYMENT_HISTORY}?page=${page}&limit=${
          InitialPageLimit.PATIENT_PAYMENT_HISTORY
        }${
          sortKey.sortBy && `&sortBy=${sortKey.sortBy}&order=${sortKey.order}`
        }`
      )
      .then((response) => {
        if (response && response.data) {
          const paymentListData = response.data?.data?.data || {};
          const listPageCount = response.data?.data?.last_page || "";
          setPaymentHistoryData(paymentListData);
          setPageCount(listPageCount);
        }
      })
      .catch();
    setLoader(false);
  }

  const changePagination = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage + ApplicationConstant.ONE);
    setListInitialPage(selectedPage);
  };

  const sortTableData = (sortBy, order) => {
    setSortObj({ sortBy, order });
  };

  useEffect(() => {
    fetchPaymentHistory(currentPage, sortObj)
  },[currentPage, sortObj])
    return (
      <>
        {loader && <SpinnerComponent />}
        <div className="middle-content edit-profile user-right-container">
          <div className="pages">
            <div className="profile-information">
              <div className="form-step max-w-100">
                <div className="patient-payment-history">
                  {paymentHistoryData.length ? (
                    <PaymentHistoryList
                      sortTableData={sortTableData}
                      paymentHistoryData={paymentHistoryData}
                      pageCount={pageCount}
                      sortObj={sortObj}
                      changePagination={changePagination}
                      listInitialPage={listInitialPage}
                    />
                  ) : (
                    <>
                      <div className="data-not-found">
                        <div className="content">
                          <div className="icon-bg">
                            <span className="material-icons">credit_card</span>
                          </div>
                          <h2>You have not made any payment yet</h2>
                        </div>
                      </div>
                    </>
                  )}
                  <ToastContainer
                    autoClose={4000}
                    hideProgressBar
                    transition={Slide}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}

export default PatientPaymentHistory;


