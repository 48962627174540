import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import axiosInstance from '../../../../../axios/axiosInstance';
import ApiConstant from '../../../../../constants/ApiConstant';
import { ApplicationConstant} from '../../../../../constants/application.constant';

const SessionFee = () => {
  const [sessionFee, setSessionFee] = useState(null);

  const fetchSessionFee = async () => {
    await axiosInstance.get(ApiConstant.DOCTOR_SESSION_FEE).then((response) => {
      if(response && response.data) {
        const doctorSessionFee = response.data?.data?.consultation_fee || '';
        setSessionFee(doctorSessionFee)
      }
    })
    .catch((error) => {toast.error(error || '');});
  };

  const updateSessionFee = async (payload) => {
    await axiosInstance.post(ApiConstant.SAVE_DOCTOR_SESSION_FEE, payload).then((response) => {
      if(response && response.data) {
        toast.success(response.data?.message || '');
      }
    })
    .catch((error) => {toast.error(error || '');});
  };

  const onSubmitSessionFee = () => {
    const payload = {consultation_fee:sessionFee}
    updateSessionFee(payload);
  }
  const onChnageSessionFee = (e) => {
    const targetValue = e.target?.value;
    if (!targetValue || (/^[0-9][0-9]*[.]?[0-9]{0,2}$/.test(targetValue))) {
      setSessionFee(targetValue);
    }
  }

  useEffect(()=>{
    fetchSessionFee();
  },[])

  return (
    <div className="set-session-fee-row">
      <p>Set Session Fee: </p>
      <div className="session-fee-input">
        <span className="currency-sign">$</span>
        <input
          type="text"
          name="currency"
          value={sessionFee}
          onChange={onChnageSessionFee}
          maxLength={ApplicationConstant.NINE}
        />
        <span className="currency-name">USD</span>
        {sessionFee && sessionFee <= ApplicationConstant.ZERO ? <span className="error">Amount should not be 0</span> : ''}
      </div>
      <Button
        color="primary"
        onClick={onSubmitSessionFee}
        disabled={!sessionFee || sessionFee <= ApplicationConstant.ZERO}
      >
        Save
      </Button>
    </div>
  )
}

export default SessionFee;
