import { Button } from 'reactstrap';
import { Image } from './../../common/image/Image';

const EmptyStateComponent = (props) => {
  return (
    <div className="no-data-section">
      <Image src={props.imageSrc} alt="No data" />
      <h2>{props.title}</h2>
      {props.subtitle ? <p>{props.subtitle}</p> : ''}
      {props.buttonText ? (
        <Button type="button" color="primary" onClick={props.handleClick}>
          {props.buttonText}
        </Button>
      ) : (
        ''
      )}
    </div>
  );
};

export default EmptyStateComponent;
