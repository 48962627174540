import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RouteConstants, ModalConstants } from './../../constants/application.constant';
import AppointmentModal from '../common/modal/AppointmentModal';
import IconTooltip from '../common/tooltip/IconTooltip';

const DoctorListItem = (props) => {
  const history = useHistory();
  const { item, changeFavorite } = props;
  const [isOpen, setModalOpen] = useState(false);
  const [isActive, setIsActive] = useState(item?.is_favorite);

  const schedularModal = (e) => {
    e.stopPropagation();
    item?.available_for_booking && setModalOpen(!isOpen);
  }

  const sendEmail=(e)=>{
    e.stopPropagation();
    if(item.email){
      window.location.assign(`mailto:${item?.email}`);
    }
  }

  const setFavorite = (e) => {
    e.stopPropagation();
    const payload = { doctor_id: item?.user_id };
    setIsActive(!isActive);
    changeFavorite(isActive, payload);
  };

  const openDoctorProfile = () => {
    history.push({
      pathname:`${RouteConstants.PATIENT_VIEW_DOCTOR_PROFILE}`,
      state:{doctor_id: item.user_id}
    })
  }

  const onScheduleAppointment = (input) => {
    history.push({
      pathname: RouteConstants.SUBSCRIPTION_PLAN,
      state: { msg: input, patientProfile: {}, doctorProfile: item },
    });
  };

  const saveScheduleAppointemntModal = (bool, input) => {
    if(bool){
      onScheduleAppointment(input);
    }
    setModalOpen(!isOpen);
  }

  return (
    <>
      <div className="divRow" onClick={openDoctorProfile}>
        <div className="table-col table-column-15"  title={item?.first_name} data-label="First Name">
          <span>{item?.first_name}</span>
        </div>
        <div className="table-col table-column-15" title={item?.last_name} data-label="Last Name">
          <span>{item?.last_name}</span>
        </div>
        <div className="table-col table-column-15" title={item?.pronoun} data-label="Pronouns">
          <span>{item?.pronoun}</span>
        </div>
        <div className="table-col table-column-15" title={item?.match_value} data-label="Matching Score">
          <span>{item?.match_value}{"%"}</span>
        </div>
        <div className="table-col table-column-15" title={item?.consultation_fee} data-label="Consultation fees">
          <span>{item?.consultation_fee && item?.consultation_fee?.includes("###") ? '' : '$'}{item?.consultation_fee}</span>
        </div>
        <div className="table-col table-column-10" title={item?.state} data-label="State">
          <span>{item?.state}</span>
        </div>
        <div className="table-col table-column-18" data-label="Action">
          <div className="action-status d-flex justify-content-start">
            <a onClick={setFavorite} class={`btn-white-outline mr-8 ${isActive ? 'active' : ''}`} id={`favorite${item.user_id}`}>
              <span class="material-icons md-dark md-18">favorite</span>
              {IconTooltip('bottom', 'favorite', `${isActive ? 'Unsave' : 'Save'}`, item.user_id)}
            </a>
            {item.can_schedule ?
            <>
              <a onClick={sendEmail} class="btn-white-outline mr-8" id={`email${item.user_id}`}>
                <span class="material-icons md-dark md-18">email</span>
                {IconTooltip('bottom', 'email', 'Mail', item.user_id)}
              </a>
              <a onClick={schedularModal} className={`btn-white-outline mr-8 ${item?.available_for_booking ? 'enabled-icon' : 'disabled-icon'}`} id={`appointment${item.user_id}`}>
                  <span class="material-icons md-dark md-18">today</span>
                  {IconTooltip('bottom', 'appointment', 'Schedule Appointment', item.user_id)}
              </a>
            </>
            :''}
            <a class="btn-white-outline" id={`description${item.user_id}`}>
              <span class="material-icons md-dark md-18">description</span>
              {IconTooltip('bottom', 'description', 'View Profile', item.user_id)}
            </a>
          </div>
        </div>
      </div>
      <AppointmentModal
        modalTitle={ModalConstants.SCHEDULER_TITLE}
        modalMessage={ModalConstants.VISIT_REASON}
        secondaryButonText={ModalConstants.CANCEL}
        primaryButtonText={ModalConstants.SAVE}
        setModelOpen={schedularModal}
        isOpen={isOpen}
        onButtonClicked={saveScheduleAppointemntModal}
        modalClassName="modal-logout"
      />
    </>
  );
};

export default DoctorListItem;
