import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText, Row, Col, CustomInput, Table, CardHeader, CardBody, Collapse } from 'reactstrap';
import classnames from 'classnames';
import InputElement from '../../common/formElements/InputElement';
import FormButton from './FormButton';
import CommonDropdown from './CommonDropdown';

const ZoomTab = (props) => {
  const [activeTab, setActiveTab] = useState('1');
  const [toggleQuestion, setToggequestion] = useState(1);//1 is the default id to be opened by default
  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }

  return (
    <div className="tab-section">
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '1' })}
            onClick={() => { toggle('1'); }}
          >
            Take Notes
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '2' })}
            onClick={() => { toggle('2'); }}
          >
            Documents
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === '3' })}
            onClick={() => { toggle('3'); }}
          >
            History
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <div className="body">
            <InputElement
                className="col-sm-12 p-0"
                type="textarea"
                height="250"
                placeholder={'Please enter'}
                label={'Please add your question here'}
              /> 
              <span className="last-update">Last updated: 09/18/2021 |  12:12PM EST</span> 
              <div className="action-button">
                  <div className="switch-text">
                    <span>Make it public:</span>
                    <label className="switch">
                        <input type="checkbox" />
                        <span className="slider round"></span>
                    </label>
                  </div>
                  <div className="btn-sec ">
                  <a class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8"><span class="material-icons md-dark md-18">description</span></a>
                  <FormButton buttonLabel = "SUBMIT" buttonClassName="btn btn-primary" />
                  </div>
              </div>
          </div>
        </TabPane>
        <TabPane tabId="2">
            <div className="body">
                <div className="upload-doc">
                    <div className="inner">
                        <strong>No media files uploaded yet…</strong>
                        <FormButton buttonLabel = "Upload File" buttonClassName="btn btn-secondary" />
                        <span>Only .doc, .docx, pdf., .exl, .png, and .jpg files with max size of 2mb.</span>
                    </div>
                </div>

            <div className="table-section">
                <div className="mobile-checkbox">
                    <div className="check-btn d-flex m-0">
                        
                        <label className="label--checkbox flat">
                        <input type="checkbox" className="checkbox" id="1" />
                        <label className="font-16 font-500" htmlFor="1"></label>
                        </label>
                        <span className="mt-5">Select All</span>
                    </div>
                </div>
                <div class="divTable doc">
                    <div class="headRow">
                        <div class="divCell">
                        <div className="check-btn m-0">
                            <label className="label--checkbox flat">
                            <input type="checkbox" className="checkbox" id="1" />
                            <label className="font-16 font-500" htmlFor="1"></label>
                            </label>
                        </div>
                        </div>
                        <div  class="divCell"> 
                        <strong>By </strong>
                        <span className="material-icons">unfold_more</span>
                        </div>
                        <div  class="divCell">
                        <strong>File Name </strong>
                        <span className="material-icons">unfold_more</span>
                        </div>
                        <div  class="divCell">
                        <strong>Date </strong>
                        <span className="material-icons">unfold_more</span>
                        </div>
                        <div  class="divCell">
                        <strong>Actions </strong>
                        </div>
                    </div>
                
                    <div class="divRow">
                        <div class="divCell">
                        <div className="check-btn m-0">
                            <label className="label--checkbox flat">
                            <input type="checkbox" className="checkbox" id="1" />
                            <label className="font-16 font-500" htmlFor="1"></label>
                            </label>
                        </div>
                        </div>
                        <div class="divCell" data-label="First Name">Patrick</div>
                        <div class="divCell" data-label="Last Name">photo id.jpg</div>
                        <div class="divCell" data-label="License Number">Sep 15, 2021</div>
                        <div class="divCell" data-label="NPI Number">
                            <div className="action-status d-flex">
                                <a className="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8"><span className="material-icons md-dark md-18">description</span></a>
                                <a className="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8"><span className="material-icons md-dark md-18">visibility</span></a>
                            </div>
                        </div>
                    </div>  
                    <div class="divRow">
                        <div class="divCell">
                        <div className="check-btn m-0">
                            <label className="label--checkbox flat">
                            <input type="checkbox" className="checkbox" id="1" />
                            <label className="font-16 font-500" htmlFor="1"></label>
                            </label>
                        </div>
                        </div>
                        <div class="divCell" data-label="First Name">Patrick</div>
                        <div class="divCell" data-label="Last Name">photo id.jpg</div>
                        <div class="divCell" data-label="License Number">Sep 15, 2021</div>
                        <div class="divCell" data-label="NPI Number">
                            <div className="action-status d-flex">
                                <a className="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8"><span className="material-icons md-dark md-18">description</span></a>
                                <a className="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8"><span className="material-icons md-dark md-18">visibility</span></a>
                            </div>
                        </div>
                    </div> 
                </div>   
            </div>  
        </div>  
        </TabPane>
        <TabPane tabId="3">
          <div className="body">
          <div className="dropdown-btn">
            <CommonDropdown />
          </div>
          <div className="table-section">
                <div className="mobile-checkbox">
                    <div className="check-btn d-flex m-0">
                        
                        <label className="label--checkbox flat">
                        <input type="checkbox" className="checkbox" id="1" />
                        <label className="font-16 font-500" htmlFor="1"></label>
                        </label>
                        <span className="mt-5">Select All</span>
                    </div>
                </div>
                <div class="divTable history">
                    <div class="headRow">
                        <div  class="divCell"></div>
                        <div  class="divCell"> 
                        <strong>Provider </strong>
                        <span className="material-icons">unfold_more</span>
                        </div>
                        <div  class="divCell">
                        <strong>Appointment Date </strong>
                        <span className="material-icons">unfold_more</span>
                        </div>
                        <div  class="divCell">
                        <strong>Status </strong>
                        </div>
                    </div>
                    <Card>
                    <CardHeader onClick={() => setToggequestion(1)}>
                      <div class="divRow">
                        <div  class="divCell"><span className="material-icons">{toggleQuestion === 1 ? "expand_less" : "expand_more"}</span></div>
                        <div class="divCell" data-label="Provider">Dr. Patel</div>
                        <div class="divCell" data-label="Appointment Date">Sep 15, 2021</div>
                        <div class="divCell" data-label="Status">Pending</div>
                    </div> 
                    </CardHeader>
                    <Collapse  isOpen={toggleQuestion === 1 ? true : false}>
                      <CardBody>
                      <div className="profile-info">
                        <div className="row">
                            <div className="col-md-4 col-sm-6">
                                <label>TeleHealth</label>
                                <div className="value">Grief Therapy</div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <label>Duration</label>
                                <div className="value">26:00 mins</div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <label>Diagnosis</label>
                                <div className="value">Prozac</div>
                            </div>
                            <div className="col-md-12 col-sm-12">
                                <label>Documents</label>
                                <div className="value d-flex align-items-center"><span className="material-icons mr-10">insert_drive_file</span>james_patrick_intakeform.pdf</div>
                            </div>
                            <div className="col-md-12 col-sm-12">
                                <label>Notes</label>
                                <div className="value">Nunc molestie erat at euismod mollis. Integer luctus purus a nulla laoreet elementum.</div>
                            </div>
                        </div>
                    </div>
                        
                      </CardBody>
                  </Collapse>
                  </Card>
                  <Card>
                    <CardHeader onClick={() => setToggequestion(2)}>
                      <div class="divRow">
                        <div  class="divCell"><span className="material-icons">{toggleQuestion === 1 ? "expand_less" : "expand_more"}</span></div>
                        <div class="divCell" data-label="Provider">Dr. Patel</div>
                        <div class="divCell" data-label="Appointment Date">Sep 15, 2021</div>
                        <div class="divCell" data-label="Status">Pending</div>
                    </div> 
                    </CardHeader>
                    <Collapse  isOpen={toggleQuestion === 2 ? true : false}>
                      <CardBody>
                      <div className="profile-info">
                        <div className="row">
                            <div className="col-md-4 col-sm-6">
                                <label>TeleHealth</label>
                                <div className="value">Grief Therapy</div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <label>Duration</label>
                                <div className="value">26:00 mins</div>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <label>Diagnosis</label>
                                <div className="value">Prozac</div>
                            </div>
                            <div className="col-md-12 col-sm-12">
                                <label>Documents</label>
                                <div className="value d-flex align-items-center"><span className="material-icons mr-10">insert_drive_file</span>james_patrick_intakeform.pdf</div>
                            </div>
                            <div className="col-md-12 col-sm-12">
                                <label>Notes</label>
                                <div className="value">Nunc molestie erat at euismod mollis. Integer luctus purus a nulla laoreet elementum.</div>
                            </div>
                        </div>
                    </div>
                      </CardBody>
                  </Collapse>
                  </Card>
                </div>   
            </div>  
          </div>
        </TabPane>
      </TabContent>
    </div>
  );
}

export default ZoomTab;