import React, { useContext } from 'react';
import UserRegistrationContext from '../../../contexts/user-registration/UserRegistrationContext';
import update from 'immutability-helper';
import { FormGroup, Input, InputGroup } from 'reactstrap';
import SkipToNextProfile from './SkipToNextProfile';
import { ApplicationConstant } from '../../../constants/application.constant';
import FormCheckBox from '../../common/formElements/FormCheckBox';

function SelectIdentification({ aIdentificationOption }) {
  const [oUserRegistration, setUserRegistration] = useContext(UserRegistrationContext);
  const handleSelect = (id) => {
    const i = aIdentificationOption.findIndex((o) => o.id === id);
    if (i > -ApplicationConstant.ONE) {
      aIdentificationOption[i].isChecked = !aIdentificationOption[i].isChecked;
      const selectedIdentification = aIdentificationOption.filter((o) => o.isChecked);
      setUserRegistration(
        update(oUserRegistration, {
          patient_profile: { user_identifications: { $set: selectedIdentification } },
        })
      );
    }
  };

  const onOtherInput = (e) => {
    e.preventDefault();
    setUserRegistration(
      update(oUserRegistration, {
        patient_profile: { other_identification: { $set: e.target.value } },
      })
    );
  };

  return (
    <div className='form-step'>
      <h3>{'Gender Identity (select all that apply)'}</h3>
      <form className='w-100'>
        <FormGroup tag='fieldset'>
          <div className='row'>
            {aIdentificationOption.map((item) => {
              if (
                oUserRegistration.patient_profile.user_identifications.find(
                  (o) => o.title.toLowerCase() === ApplicationConstant.OTHER_LOWER_CASE
                ) &&
                item.title.toLowerCase() === ApplicationConstant.OTHER_LOWER_CASE
              ) {
                return (
                  <div key={item.id} className='col-sm-6'>
                    <InputGroup>
                      <div className='label-float'>
                        <Input
                          value={oUserRegistration.patient_profile.other_identification}
                          onChange={onOtherInput}
                          type='text'
                          maxLength='100'
                          placeholder='Please enter other'
                          autoFocus
                        />
                        <label>{`${item.title}`}</label>
                      </div>
                    </InputGroup>
                  </div>
                );
              } else {
                return (
                  <FormCheckBox
                    key={item.id}
                    keyId={item.id}
                    isChecked={item.isChecked}
                    item={item}
                    labelDescription={item.description}
                    label={`${item.title}`}
                    handleSelect={handleSelect}
                  />
                );
              }
            })}
          </div>
        </FormGroup>
      </form>
      <SkipToNextProfile />
    </div>
  );
}

export default SelectIdentification;
