import React, { useContext, useState } from 'react';
import HospitalSignUpContext from '../../../contexts/hospital-signup/HospitalSignUpContext';
import InputElement from '../../common/formElements/InputElement';
import update from 'immutability-helper';
import { ApplicationConstant, DateConstant } from '../../../constants/application.constant';
import {
  phoneValidationApi,phoneValidation,emailValidationApi,emailValidation,
  passwordValidation,confirmPasswordValidation,
  pastDateValidation,preferredNameValidation, idNumberValidation,
} from '../../../validators/CommonValidation.js';
import DateElement from '../../common/formElements/DateElement';

function HospitalBasicInformation() {
  const [hospitalSignUp, setHospitalSignUp] = useContext(HospitalSignUpContext);
  const [passwordShown, setPasswordShown] = useState(ApplicationConstant.TRUE);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(ApplicationConstant.TRUE);

  const setValueByFieldName = (fieldname, value) => {
    setHospitalSignUp(
      update(hospitalSignUp, {
        hospital_details: { [fieldname]: { $set: value } },
      })
    );
  };

  const onPhoneInputApi = (e) => {
    e.preventDefault();
    if (e.target.value.length <= ApplicationConstant.TEN) {
      phoneValidationApi('phone', e.target.value, setValueByFieldName, hospitalSignUp.hospital_details.phone);
    }
  };

  const onPhoneInput = (e) => {
    e.preventDefault();
    if (e.target.value.length <= ApplicationConstant.TEN) {
      phoneValidation('phone', e.target.value, setValueByFieldName);
    }
  };

  const onEmailInputApi = (e) => {
    e.preventDefault();
    emailValidationApi('email', e.target.value, setValueByFieldName, hospitalSignUp.hospital_details.email);
  };
  const onExpirationDateInput = (date) => {
    const obj = pastDateValidation(date);
    setValueByFieldName('date_of_business', obj);
  };

  const onFirstNameInput = (e) => {
    e.preventDefault();
    const obj = preferredNameValidation(e.target.value, hospitalSignUp.hospital_details.first_name);
    setValueByFieldName('first_name', obj);
  };

  const onTaxInput = (e) => {
    e.preventDefault();
    if (e.target.value.length <= ApplicationConstant.TWELVE) {
      idNumberValidation('tax_id', e.target.value, setValueByFieldName, 'Tax Id must be of 10 digits number.');
    }
  };

  const onUserNameInput = (e) => {
    e.preventDefault();
    const obj = preferredNameValidation(e.target.value, hospitalSignUp.hospital_details.user_name);
    setValueByFieldName('user_name', obj);
  };

  const onEmailInput = (e) => {
    e.preventDefault();
    emailValidation('email', e.target.value, setValueByFieldName);
  };
  const onPasswordInput = (e) => {
    e.preventDefault();
    const obj = passwordValidation(e.target.value, hospitalSignUp.hospital_details.password);
    setValueByFieldName('password', obj);
  };

  const onConfirmPasswordInput = (e) => {
    e.preventDefault();
    if (hospitalSignUp.hospital_details.password.valid) {
      const obj = confirmPasswordValidation(e.target.value, hospitalSignUp.hospital_details.password.value);
      setValueByFieldName('confirm_password', obj);
    }
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(confirmPasswordShown ? false : true);
  };

  return (
    <div>
      <h3>Basic Information</h3>
      <div className="row">
        <InputElement
          className="col-sm-6"
          type="text"
          name="first_name"
          value={hospitalSignUp.hospital_details.first_name.value}
          onChange={onFirstNameInput}
          onBlur={onFirstNameInput}
          placeholder="Please enter entity name"
          label={"Healthcare Entity Name*"}
          isValid={hospitalSignUp.hospital_details.first_name.valid}
          msg={hospitalSignUp.hospital_details.first_name}
        />
        <InputElement
          className="col-sm-6"
          type="text"
          name="user_name"
          value={hospitalSignUp.hospital_details.user_name.value}
          onChange={onUserNameInput}
          onBlur={onUserNameInput}
          placeholder="Please enter name"
          label={"User Name*"}
        />
        <InputElement
          className="col-sm-6"
          type="text"
          name="tax_id"
          value={hospitalSignUp.hospital_details.tax_id.value}
          label={"Tax ID*"}
          onChange={onTaxInput}
          placeholder={"Please Enter Tax ID"}
          isValid={hospitalSignUp.hospital_details.tax_id.valid}
          msg={hospitalSignUp.hospital_details.tax_id.msg}
        />
        <DateElement
          className="col-sm-6"
          type="date"
          name="date_of_birth"
          value={hospitalSignUp.hospital_details.date_of_business.value}
          onChange={onExpirationDateInput}
          dateFormat="MMM d, yyyy"
          maxDate={new Date()}
          minDate={new Date(DateConstant.START_DATE)}
          onKeyPress={(e) => {
            e.preventDefault();
          }}
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          onKeyUp={(e) => {
            e.preventDefault();
          }}
          isValid={hospitalSignUp.hospital_details.date_of_business.valid}
          msg={hospitalSignUp.hospital_details.date_of_business.msg}
          label={"Date of Business Creation*"}
        />

        <InputElement
          className="col-sm-6"
          type="email"
          name="email"
          value={hospitalSignUp.hospital_details.email.value}
          onChange={onEmailInput}
          onBlur={onEmailInputApi}
          label={"Point of Contact - Email Address*"}
          placeholder={"Please enter email"}
          isValid={hospitalSignUp.hospital_details.email.valid}
          msg={hospitalSignUp.hospital_details.email.msg}
        />
        <InputElement
          className="col-sm-6"
          type="number"
          name="phone"
          value={hospitalSignUp.hospital_details.phone.value}
          onChange={onPhoneInput}
          onBlur={onPhoneInputApi}
          onKeyDown={(evt) =>
            ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
          }
          label={"Point of Contact - Phone Number*"}
          placeholder={"Please Enter 10 digit number"}
          isValid={hospitalSignUp.hospital_details.phone.valid}
          msg={hospitalSignUp.hospital_details.phone.msg}
        />
        <div className="col-sm-6 p-0">
          <div className="passwordshowhide ">
            <InputElement
              className="col-sm-12"
              type={!passwordShown ? "text" : "password"}
              name="password"
              autocomplete="new-password"
              value={hospitalSignUp.hospital_details.password.value}
              onChange={onPasswordInput}
              onBlur={onPasswordInput}
              label={"Password*"}
              placeholder={"Please enter password"}
              isValid={hospitalSignUp.hospital_details.password.valid}
              msg={hospitalSignUp.hospital_details.password.msg}
            />
            <span
              className="material-icons md-20 md-dark "
              onClick={togglePasswordVisiblity}>
              {passwordShown ? "visibility_off" : "visibility"}
            </span>{" "}
          </div>
        </div>
        <div className="col-sm-6 p-0">
          <div className="passwordshowhide ">
            <InputElement
              className="col-sm-12"
              type={!confirmPasswordShown ? "text" : "password"}
              name="confirm_password"
              value={hospitalSignUp.hospital_details.confirm_password.value}
              onChange={onConfirmPasswordInput}
              onBlur={onConfirmPasswordInput}
              label={"Confirm Password*"}
              placeholder={"Please confirm password"}
              isDisabled={!hospitalSignUp.hospital_details.password.valid}
              isValid={hospitalSignUp.hospital_details.confirm_password.valid}
              msg={hospitalSignUp.hospital_details.confirm_password.msg}
            />
            <span
              className="material-icons md-20 md-dark "
              onClick={toggleConfirmPasswordVisiblity}>
              {confirmPasswordShown ? "visibility_off" : "visibility"}
            </span>{" "}
          </div>
        </div>
        <div className="col-sm-12 pb-20">
          <p className="font-12 gray666">
            8 Characters Minimum, 1 Upper Case Letter, 1 Number, 1 Special
            Character
          </p>
        </div>
      </div>
    </div>
  );
}

export default HospitalBasicInformation;
