import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import GoBack from '../../../../common/back/GoBack';
import { DoctorAppointmentSidebarItems } from './DoctorAppointmentSidebarItems';

function DoctorAppointmentSidebar() {
  const { pathname } = useLocation();
  const [navOpen, setNavOpen] = useState(false);

  return (
    <div className="profile-sidebar">
      <GoBack heading={'Appointments'} />
      <div className={navOpen ? 'edit-nav open' : 'edit-nav'}>
        <ul>
          {DoctorAppointmentSidebarItems.map((item, index) => {
            return (
              <li
                onClick={() => {
                  setNavOpen(!navOpen);
                }}
                key={index}
                className={pathname === item.to ? 'active' : ''}
              >
                <Link to={item.to} replace>
                  <span className={item.iconClass}>{item.icon}</span>
                  <span className="name">{item.name}</span>
                </Link>
              </li>
            );
          })}
        </ul>
        <a
          className="nav-toggal"
          onClick={() => {
            setNavOpen(!navOpen);
          }}
        >
          <span className="material-icons">{navOpen ? 'chevron_left' : 'chevron_right'}</span>
        </a>
      </div>
    </div>
  );
}

export default DoctorAppointmentSidebar;
