import axiosInstance from '../axios/axiosInstance';
import ApiConstant from '../constants/ApiConstant';
import { ApplicationConstant, RegExConstant } from '../constants/application.constant';

const setObj = (val, valid = false, msg = '') => {
  return {
    value : val,
    valid : valid,
    msg : msg
  }
};


export const nameValidation = (val) => {
  return {
    value : val,
    valid:ApplicationConstant.TRUE,
    msg:''
  };
};

export const preferredNameValidation = (val, o) => {
  if ((val.charAt(ApplicationConstant.ZERO) !== ' ')) {
    o = setObj(val, ApplicationConstant.TRUE, ApplicationConstant.SUCCESS_MSG);
  }
  return o;
};

export const emailValidation = (fieldName, val, setValueByFieldName) => {
  if (RegExConstant.EMAIL_REG.test(val)) {
    setValueByFieldName(fieldName, {
      value: val,
      msg: '',
      valid: ApplicationConstant.FALSE,
      touched: ApplicationConstant.TRUE,
    });
  } else if (!val) {
    setValueByFieldName(fieldName, {
      value: val,
      msg: ApplicationConstant.NO_VALUE_STRING,
      valid: ApplicationConstant.FALSE,
      touched: ApplicationConstant.TRUE,
    });
  } else {
    setValueByFieldName(fieldName, {
      value: val,
      msg: 'Email is invalid',
      valid: false,
      touched: ApplicationConstant.TRUE,
    });
  }
};

export const emailValidationApi = (fieldName, val, setValueByFieldName) => {
  val = val.toLowerCase();
  if (RegExConstant.EMAIL_REG.test(val)) {
    axiosInstance
      .post(ApiConstant.CHECK_EMAIL, {
        email: val,
      })
      .then(
        (res) => {
          let output = null;
          if (res && res.data && res.data.data && res.data.data.email) {
            output = { value: res.data.data.email, msg: 'success', valid: true, touched: ApplicationConstant.TRUE };
          } else if (res && res.msg) {
            output = { value: val, msg: res.msg, valid: false, touched: ApplicationConstant.TRUE };
          }
          setValueByFieldName(fieldName, output);
        },
        (error) => {
          setValueByFieldName(fieldName, { value: val, msg: error, valid: false, touched: ApplicationConstant.TRUE });
        }
      );
  } else if (!val) {
    setValueByFieldName(fieldName, {
      value: val,
      msg: '',
      valid: ApplicationConstant.FALSE,
      touched: ApplicationConstant.TRUE,
    });
  } else {
    setValueByFieldName(fieldName, {
      value: val,
      msg: 'Not a valid email',
      valid: false,
      touched: ApplicationConstant.TRUE,
    });
  }
};

export const phoneValidation = (fieldName, val, setValueByFieldName) => {
  if (!val || val.length === ApplicationConstant.TEN ) {
    setValueByFieldName(fieldName, {
      value: val,
      msg: '',
      valid: ApplicationConstant.FALSE,
      touched: ApplicationConstant.TRUE,
    });
  } else {
    setValueByFieldName(fieldName, {
      value: val,
      msg: 'Phone Number must be 10 digits.',
      valid: ApplicationConstant.FALSE,
      touched: ApplicationConstant.TRUE,
    });
  }
};

export const taxIdFormatter = (val = '') => {
  val = val.toString().replace(/-/g, '');
  if (val.length > 6) {
    val = val.substring(0, 3) + '-' + val.substring(3, 6) + '-' + val.substring(6);
  } else if (val.length > 3) {
    val = val.substring(0, 3) + '-' + val.substring(3);
  }
  return val;
};

const checkTaxId = (val) => {
  return (
    (val.length === ApplicationConstant.TWELVE && (val.match(/-/g) || []).length === ApplicationConstant.TWO) ||
    (val.length === ApplicationConstant.ELEVEN && (val.match(/-/g) || []).length === ApplicationConstant.ONE) ||
    (val.length === ApplicationConstant.TEN && (val.match(/-/g) || []).length === ApplicationConstant.ZERO)
  );
};

export const idNumberValidation = (fieldName, val, setValueByFieldName, err_msg) => {
  val = val.toString();
  if (
    val.toString().replace(/-/g, '').length <= ApplicationConstant.TEN &&
    (val.match(/--/g) || []).length <= ApplicationConstant.ZERO
  ) {
    if (RegExConstant.TAX_ID.test(val) && (val.match(/-/g) || []).length <= ApplicationConstant.TWO) {
      if (checkTaxId(val)) {
        setValueByFieldName(fieldName, {
          value: val,
          msg: '',
          valid: ApplicationConstant.TRUE,
          touched: ApplicationConstant.TRUE,
        });
      } else if (!val) {
        setValueByFieldName(fieldName, {
          value: val,
          msg: '',
          valid: ApplicationConstant.FALSE,
          touched: ApplicationConstant.TRUE,
        });
      } else {
        setValueByFieldName(fieldName, {
          value: val,
          msg: err_msg,
          valid: ApplicationConstant.FALSE,
          touched: ApplicationConstant.TRUE,
        });
      }
    } else if (val === '') {
      setValueByFieldName(fieldName, {
        value: val,
        msg: '',
        valid: ApplicationConstant.FALSE,
        touched: ApplicationConstant.TRUE,
      });
    }
  }
};

export const zipCodeValidation = (fieldName, val, setValueByFieldName) => {
  if (val.length <= ApplicationConstant.SIX) {
    if (val.length === ApplicationConstant.FIVE || val.length === ApplicationConstant.SIX) {
      setValueByFieldName(fieldName, { value: val, msg: '', valid: ApplicationConstant.TRUE });
    } else if (val === '') {
      setValueByFieldName(fieldName, { value: val, msg: '', valid: ApplicationConstant.FALSE });
    } else {
      setValueByFieldName(fieldName, {
        value: val,
        msg: 'Minimum 5 digits allowed and Maximum 6 digits allowed.',
        valid: ApplicationConstant.FALSE,
        touched: ApplicationConstant.TRUE,
      });
    }
  }
};

export const phoneValidationApi = (fieldName, val, setValueByFieldName) => {

  if (val.length === ApplicationConstant.TEN) {
    axiosInstance
      .post(ApiConstant.CHECK_PHONE, {
        phone: val,
      })
      .then((res) => {
        let output = null;
        if (res && res.data && res.data.data && res.data.data.phone) {
          output = {
            value: res.data.data.phone,
            msg: 'success',
            valid: ApplicationConstant.TRUE,
            touched: ApplicationConstant.TRUE,
          };
        } else if (res && res.msg) {
          output = { value: val, msg: res.msg, valid: ApplicationConstant.FALSE, touched: ApplicationConstant.TRUE };
        }
        setValueByFieldName(fieldName, output);
      })
      .catch((error) => {
        setValueByFieldName(fieldName, {
          value: val,
          msg: error,
          valid: ApplicationConstant.FALSE,
          touched: ApplicationConstant.TRUE,
        });
      });
  } else if (!val) {
    setValueByFieldName(fieldName, {
      value: val,
      msg: '',
      valid: ApplicationConstant.FALSE,
      touched: ApplicationConstant.TRUE,
    });
  } else {
    setValueByFieldName(fieldName, {
      value: val,
      msg: 'phone number must be 10 digit',
      valid: ApplicationConstant.FALSE,
      touched: ApplicationConstant.TRUE,
    });
  }
};

export const numberValidation = (val, o) => {
  if (val.length === ApplicationConstant.TEN) {
    o = { value: val, valid: true, msg: '' };
  } else {
    o = { value: val, valid: false, msg: '' };
  }

  return o;
};

function _getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export const dateofBirthValidation = (val, minAge, o) => {
  if (_getAge(val) >= minAge) {
    o = setObj(val, true, '');
  } else {
    o = setObj(val, false, 'Age should be minimum 18 years');
  }
  return o;
};

export const futureDateValidation = (val) => {
  let o = {};
  if (new Date().toDateString() === new Date(val).toDateString()) {
    o = setObj(val, true, '');
  } else if (new Date() <= new Date(val)) {
    o = setObj(val, true, '');
  } else {
    o = setObj(val, false, 'Selected date must be a future date');
  }
  return o;
};

export const pastDateValidation = (val) => {
  let o = {};
  if (new Date().toDateString() === new Date(val).toDateString()) {
    o = setObj(val, true, '');
  } else if (new Date() >= new Date(val)) {
    o = setObj(val, true, '');
  } else {
    o = setObj(val, false, 'Selected date must be a past date');
  }
  return o;
};

export const passwordValidation = (val, o) => {
  if (RegExConstant.PASSWORD_REGEX.test(val, o)) {
    o = setObj(val, ApplicationConstant.TRUE, ApplicationConstant.SUCCESS_MSG);
  } else if (!val) {
    o = { value: val, msg: '', valid: ApplicationConstant.FALSE, touched: ApplicationConstant.TRUE };
  } else {
    o = setObj(val, ApplicationConstant.FALSE, 'Password must follow the rule.');
  }
  return o;
};

export const confirmPasswordValidation = (val, password, o) => {
  if (val === password) {
    o = setObj(val, ApplicationConstant.TRUE, ApplicationConstant.SUCCESS_MSG);
  } else if (password.includes(val)) {
    o = setObj(val, ApplicationConstant.FALSE, '');
  } else {
    o = setObj(val, ApplicationConstant.FALSE, 'Password and confirm password are not same');
  }
  return o;
};

export const npiNumberValidation = (val) => {
  let o = {};
  if(/^\d+$/.test(val) || val === '') {
    if ((val && val.length === ApplicationConstant.TEN) || val === '') {
      o = setObj(val, ApplicationConstant.TRUE, 'success');
    } else {
      o = setObj(val, ApplicationConstant.FALSE, 'Please enter 10 digits NPI number.');
    }
  } else {
    o = setObj(false, ApplicationConstant.FALSE, '');
  }
  return o;
};

export const onAuthorizationValidation = (val, o) => {
  if (RegExConstant.WORDS_REGEX.test(val)) {
    o = setObj(val, ApplicationConstant.TRUE, ApplicationConstant.SUCCESS_MSG);
  }
  return o;
};

export const experienceValidation = (val, o) => {
  if (RegExConstant.TWO_DIGIT_FLOAT.test(val) && !RegExConstant.REMOVE_SPECIAL_CHAR.test(val)) {
    if (val === '00') {
      val = '0';
    }
    if (parseFloat(val) > ApplicationConstant.ZERO) {
      o = setObj(val, ApplicationConstant.TRUE, 'success');
    } else {
      o = setObj(val, ApplicationConstant.FALSE, 'Experience should be greater than 0 year');
    }
  } else if (RegExConstant.DECIMAL_AT_END.test(val)) {
    o = setObj(val, ApplicationConstant.FALSE, '');
  } else if (val === '') {
    o = setObj(val, ApplicationConstant.FALSE, '');
  }
  return o;
};
