import React, { useContext } from 'react';
import update from 'immutability-helper';
import DoctorSignUpContext from '../../../contexts/doctor-signup/DoctorSignUpContext';
import RadioToggleElement from '../../common/formElements/RadioToggleElement';
import InputElement from '../../common/formElements/InputElement';
import { ApplicationConstant } from '../../../constants/application.constant';

function DoctorHistoryPage({ oLabelInfo }) {
  const [doctorSignUp, setDoctorSignUp] = useContext(DoctorSignUpContext);

  const setValueByFieldName = (fieldname, value) => {
    setDoctorSignUp(
      update(doctorSignUp, { doctor_profile: { doctor_background_questions: { [fieldname]: { $set: value } } } })
    );
  };
  const handleToggle = (e) => {
    setValueByFieldName(e.target.name, e.target.value);
  };

  const handleInput = (e) => {
    setValueByFieldName(`comment_${e.target.name}`, e.target.value);
  };

  return (
    <div className="form-step">
      <form className="w-100">
        <div className="head-title mb-50">
          <div className="heading">Provider Registration</div>
          <div className="sub-heading">Background Information</div>
        </div>
        <div className="row">
          {oLabelInfo.questions &&
            oLabelInfo.questions.map((item, index) => (
              <RadioToggleElement
                key={index}
                classNm={'col-sm-12'}
                label={`${item.question}*`}
                name={item.id}
                selectedValue={doctorSignUp.doctor_profile.doctor_background_questions[item.id]}
                onChange={handleToggle}
              >
                <InputElement
                  className="col-sm-12"
                  type="textarea"
                  height="100"
                  autoComplete={ApplicationConstant.OFF}
                  placeholder={'Please enter'}
                  label={'Description*'}
                  name={item.id}
                  value={doctorSignUp.doctor_profile.doctor_background_questions[`comment_${item.id}`]}
                  onChange={handleInput}
                />
              </RadioToggleElement>
            ))}
        </div>
      </form>
    </div>
  );
}

export default DoctorHistoryPage;
