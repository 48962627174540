import React from 'react';
import { Button } from 'reactstrap';
import PageLogo from '../common/logo/PageLogo';

function ContactUsThanks({ handleClick }) {
  return (
    <div className="full-bg-color">
      <div className="container">
        <div className="landing-ui">
          <div className="emal-sent">
            <form className="w-100">
              <div className="w-350">
                <div className="login-title">
                  <PageLogo />
                </div>
                <div className="check-box">
                  <span class="material-icons">done</span>
                </div>
                <h1>Thank you for contacting us!</h1>
                <h2 className="mt-15">
                  <span>
                    We have received your message.<br></br> We'll reach out immediately!
                  </span>
                </h2>
                <div className="sub-heading theme-color mt-20">Thank you!</div>
                <div className="sub-heading font-600 theme-color mt-5">equalityMD</div>
                <div className="row mt-30">
                  <div className="col-sm-12">
                    <Button className="w-100 mb-20" color="primary" onClick={handleClick}>
                      Go Back
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUsThanks;
