import React from 'react';

function PaymentHistoryHeader(props) {
  const{ tableHeadContent, sortData, filter } = props;

  const isActiveSort = (sortKey, sortValue) => {
    const { sortBy, order } = filter;
    if (sortKey === sortBy && sortValue === order) {
      return 'active';
    } else {
      return '';
    }
  };
    return (
      <>
        {tableHeadContent.map((item, index) => {
          return (
            <div key={index} className={`divCell ${item.class}`}>
              <strong>{item.title}</strong>
              {item.sort && (
                <div className="sorting-icon">
                  <span
                    className={`material-icons-outlined ${isActiveSort(item.sortKey, 'asc')}`}
                    onClick={() => {
                      sortData(item.sortKey, 'asc');
                    }}
                  >
                    expand_less
                  </span>
                  <span
                    className={`material-icons-outlined ${isActiveSort(item.sortKey, 'desc')}`}
                    onClick={() => {
                      sortData(item.sortKey, 'desc');
                    }}
                  >
                    expand_more
                  </span>
                </div>
              )}
            </div>
          );
        })}
      </>
    );
}

export default PaymentHistoryHeader;

