import React, { useState } from 'react';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import CustomModal from '../../common/modal/CustomModal';
import { PROFILEIMG } from '../../../constants/image';
// reactstrap components


function Example(props) {
  const [dropdownOpen, setOpen] = useState(false);
  const [isOpen, modalOpen] = useState(false);

  const toggle = () => setOpen(!dropdownOpen);
  const toggleLogOutModal = () => modalOpen(!isOpen);
  return (
    <>
      <ButtonDropdown className="profile-dropdown" direction="left" isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle>
          <div className="profile-img">
            <img src={PROFILEIMG}></img>
            <span class="material-icons md-36 d-none">account_circle</span>
          </div>

          <span class="material-icons md-18">expand_more</span>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem header>Account Details</DropdownItem>
          <DropdownItem><span class="material-icons">mic</span> Accessibility Settings</DropdownItem>
          <DropdownItem><span class="material-icons">place</span> Account Information</DropdownItem>
          <DropdownItem><span class="material-icons">account_circle</span>User Profile</DropdownItem>
          <DropdownItem><span class="material-icons">description</span>Prescription</DropdownItem>
          <DropdownItem><span class="material-icons">shopping_cart</span> Purchase Details</DropdownItem>
          <DropdownItem onClick={toggleLogOutModal}><span class="material-icons-outlined">logout </span>Sign Out</DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
      <CustomModal isOpen={isOpen} modalOpen={modalOpen} modalClassName="modal-logout" />
    </>
  );
}

export default Example;
