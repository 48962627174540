import React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import FormCheckBox from '../../common/formElements/FormCheckBox';

const StartAppointmentModal = (props) => {
  const {
    className,
    modalClassName,
    isOpen,
    setModelOpen,
    onButtonClicked,
    primaryButtonText
  } = props;

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          setModelOpen();
        }}
        className={className}
        modalClassName={modalClassName}
      >
        <ModalBody>
          <h1>Start Appointment</h1>
          <p>You will now be leaving the eMD platform and joining zoom.</p>
          <FormCheckBox
                  key={' '}
                  currentItem={' '}
                  item={' '}
                  label={'Open Telehealth Video in new browser window'}
                  handleSelect={' '}/>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              onButtonClicked(true);
            }}
          >
            {primaryButtonText}
          </Button>{' '}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default StartAppointmentModal;
