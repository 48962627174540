import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import SpinnerComponent from '../../common/spinner/SpinnerComponent';
import axiosInstance from '../../../axios/axiosInstance';
import ApiConstant from '../../../constants/ApiConstant';
import UpcomingAppointmentItem from './../../appointment/doctor/history/upcoming/UpcomingAppointmentItem';
import PendingAppointmentItem from './../../appointment/doctor/history/pending/PendingAppointmentItem';
import { EMPTY_ICON } from './../../../constants/image';
import EmptyStateComponent from '../../common/emptyStateComponent/EmptyStateComponent';
import { ApplicationConstant, RouteConstants } from '../../../constants/application.constant';
import DropdownComponent from '../../common/dropdown/DropdownComponent';
import { AllAppointmentsTypes } from './AllAppointmentsTypes';
import CanceledAppointmentItem from '../../appointment/doctor/history/canceled/CanceledAppointmentItem';
import CompletedAppointmentItem from '../../appointment/doctor/history/completed/CompletedAppointmentItem';

const DoctorAppointments = () => {
  const [pendingAppointments, setPendingAppointments] = useState({});
  const [upcomingAppointments, setUpcomingAppointments] = useState({});
  const [canceledAppointments, setCanceledAppointments] = useState(null);
  const [completedAppointments, setCompletedAppointments] = useState(null);
  const [loader, setLoader] = useState(false);
  const [allAppointments, setAllAppointments] = useState(1);

  const fetchPendingAppointmentList = async () => {
    await axiosInstance
      .get(`${ApiConstant.DOCTOR_PENDING_APPOINTMENT}?page=1&limit=${ApplicationConstant.THREE}`)
      .then((res) => {
        if (res && res.data) {
          const pendingAppointmentList = res.data?.data?.data || [];
          setPendingAppointments(pendingAppointmentList);
        }
      })
      .catch((error) => {
        toast.error(error || '');
      });
  };

  const fetchUpcomingAppointmentList = async () => {
    await axiosInstance
      .get(`${ApiConstant.DOCTOR_UPCOMING_APPOINTMENT}?page=1&limit=${ApplicationConstant.THREE}`)
      .then((res) => {
        if (res && res.data) {
          const upcomingAppointmentList = res.data?.data?.data || [];
          setUpcomingAppointments(upcomingAppointmentList);
        }
      })
      .catch((error) => {
        toast.error(error || '');
      });
  };

  const fetchCanceledAppointmentList = async () => {
    await axiosInstance
      .get(`${ApiConstant.DOCTOR_CANCELED_APPOINTMENT}?page=1&limit=${ApplicationConstant.THREE}`)
      .then((res) => {
        if (res && res.data) {
          const canceledAppointmentList = res.data?.data?.data || [];
          setCanceledAppointments(canceledAppointmentList);
        }
      })
      .catch((error) => {
        toast.error(error || '');
      });
  };

  const fetchCompletedAppointmentList = async () => {
    await axiosInstance
      .get(`${ApiConstant.DOCTOR_COMPLETED_APPOINTMENT}?page=1&limit=${ApplicationConstant.THREE}`)
      .then((res) => {
        if (res && res.data) {
          const completedAppointmentList = res.data?.data?.data || [];
          setCompletedAppointments(completedAppointmentList);
        }
      })
      .catch((error) => {
        toast.error(error || '');
      });
  };

  const completeSession = async (Id) => {
    const appointmentId = { appointment_id: Id };
    setLoader(true);
    await axiosInstance
      .post(ApiConstant.DOCTOR_COMPLETE_SESSION, appointmentId)
      .then((response) => {
        toast.success(response?.data?.message || '');
        fetchUpcomingAppointmentList();
        fetchCompletedAppointmentList();
      })
      .catch((error) => {
        toast.error(error || '');
      });
      setLoader(false);
  };

  const fetchAllAppointments = async () => {
    setLoader(true);
    await fetchPendingAppointmentList();
    await fetchUpcomingAppointmentList();
    await fetchCanceledAppointmentList();
    await fetchCompletedAppointmentList();
    setLoader(false);
  };

  useEffect(() => {
    fetchAllAppointments();
  }, []);

  const onAllAppointmentTypes = (value) => {
    setAllAppointments(value);
  };

  const handleViewAll = (appointmentNo) => {
    const viewAllObj = {
      shouldShow: false,
      route: RouteConstants.DOCTOR_PENDING_APPOINTMENT,
    };

    switch (appointmentNo) {
      case ApplicationConstant.ONE:
        viewAllObj.shouldShow =
          pendingAppointments?.length ||
          upcomingAppointments?.length ||
          canceledAppointments?.length ||
          completedAppointments?.length;
        viewAllObj.route = RouteConstants.DOCTOR_PENDING_APPOINTMENT;
        break;
      case ApplicationConstant.TWO:
        viewAllObj.shouldShow = pendingAppointments?.length > ApplicationConstant.ZERO;
        viewAllObj.route = RouteConstants.DOCTOR_PENDING_APPOINTMENT;
        break;
      case ApplicationConstant.THREE:
        viewAllObj.shouldShow = upcomingAppointments?.length > ApplicationConstant.ZERO;
        viewAllObj.route = RouteConstants.DOCTOR_UPCOMING_APPOINTMENT;
        break;
      case ApplicationConstant.FOUR:
        viewAllObj.shouldShow = canceledAppointments?.length > ApplicationConstant.ZERO;
        viewAllObj.route = RouteConstants.DOCTOR_CANCELED_APPOINTMENT;
        break;
      case ApplicationConstant.FIVE:
        viewAllObj.shouldShow = completedAppointments?.length > ApplicationConstant.ZERO;
        viewAllObj.route = RouteConstants.DOCTOR_COMPLETED_APPOINTMENT;
        break;

      default:
        break;
    }
    return viewAllObj.shouldShow ? <Link to={viewAllObj.route}>VIEW ALL</Link> : '';

  };

  return (
    <section className="my-matched-section">
      <div className="heading-link appointment_heading-link d-flex">
        <h5>
          {
            AllAppointmentsTypes.find((item) => item.id === allAppointments)
              .value
          }
        </h5>
        <div className="dropdown-btn mt--10 d-flex ">
          <DropdownComponent
            placeholder={"All Appointment"}
            showFirstItem={false}
            items={AllAppointmentsTypes}
            onValueSelected={onAllAppointmentTypes}
          />
        </div>
      </div>
      <div className="card-box dashbaord-all-apointment-section pr-0">
        {loader ? (
          <SpinnerComponent />
        ) : (
          <>
            <div className="appointments doctor-appointment-list">
              <div className="profile-info">
                {(allAppointments === 1 && pendingAppointments?.length) ||
                allAppointments === 2 ? (
                  pendingAppointments?.length ? (
                    <div className="box">
                      <h4>Pending Appointments</h4>
                      {pendingAppointments?.map((item, i) => {
                        return (
                          <PendingAppointmentItem
                            item={item}
                            key={i}
                            fetchUpdatedList={fetchAllAppointments}
                          />
                        );
                      })}
                    </div>
                  ) : (
                    <EmptyStateComponent
                      imageSrc={EMPTY_ICON}
                      title="No Pending Appointments"
                    />
                  )
                ) : (
                  ""
                )}
                {(allAppointments === 1 && upcomingAppointments?.length) ||
                allAppointments === 3 ? (
                  upcomingAppointments?.length ? (
                    <div className="box">
                      <h4>Upcoming Appointments</h4>
                      {upcomingAppointments?.map((item, i) => {
                        return (
                          <UpcomingAppointmentItem
                            item={item}
                            key={i}
                            completeSession={completeSession}
                            isDashboard={true}
                          />
                        );
                      })}
                    </div>
                  ) : (
                    <EmptyStateComponent
                      imageSrc={EMPTY_ICON}
                      title="No Upcoming Appointments"
                    />
                  )
                ) : (
                  ""
                )}
                {(allAppointments === 1 && canceledAppointments?.length) ||
                allAppointments === 4 ? (
                  canceledAppointments?.length ? (
                    <div className="box">
                      <h4>Canceled Appointments</h4>
                      {canceledAppointments?.map((item, i) => {
                        return (
                          <CanceledAppointmentItem
                            item={item}
                            key={i}
                            completeSession={completeSession}
                            isDashboard={true}
                          />
                        );
                      })}
                    </div>
                  ) : (
                    <EmptyStateComponent
                      imageSrc={EMPTY_ICON}
                      title="No Canceled Appointments"
                    />
                  )
                ) : (
                  ""
                )}
                {(allAppointments === 1 && completedAppointments?.length) ||
                allAppointments === 5 ? (
                  completedAppointments?.length ? (
                    <div className="box">
                      <h4>Completed Appointments</h4>
                      {completedAppointments?.map((item, i) => {
                        return (
                          <CompletedAppointmentItem
                            item={item}
                            key={i}
                            completeSession={completeSession}
                            isDashboard={true}
                            showAll={false}
                          />
                        );
                      })}
                    </div>
                  ) : (
                    <EmptyStateComponent
                      imageSrc={EMPTY_ICON}
                      title="No Completed Appointments"
                    />
                  )
                ) : (
                  ""
                )}
              </div>
              {allAppointments === 1 &&
                pendingAppointments?.length === ApplicationConstant.ZERO &&
                canceledAppointments?.length === ApplicationConstant.ZERO &&
                completedAppointments?.length === ApplicationConstant.ZERO &&
                upcomingAppointments?.length === ApplicationConstant.ZERO && (
                  <EmptyStateComponent
                    imageSrc={EMPTY_ICON}
                    title="No Appointments"
                  />
                )}
            </div>
            <div className="view-all-section">
              {handleViewAll(allAppointments)}
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default DoctorAppointments;
