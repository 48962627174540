import { Link } from 'react-router-dom';
import { Image } from './../../common/image/Image';
import { profileKey, ApplicationConstant, targetedId, RouteConstants } from '../../../constants/application.constant';
import ProfileTooltip from '../../common/profileElements/ProfileTooltip';

const PatientProfile = (props) => {
  const { profileData } = props;

  return (
    <div className="col-sm-12 col-lg-8">
      <div className="box">
        <div className="user-info">
          <div className="profile-img">
            <Image src={profileData?.profile_image || ""} alt="Profile image" />
          </div>
          <div className="head">
            <div className="title-text mt-5">
              <h1>
                <span>{profileData?.first_name} </span>
                <span>{profileData?.last_name}</span>
              </h1>
            </div>
            <div className="action-status">
              <Link
                to={RouteConstants.PATIENT_ACCOUNT_INFORMATION}
                className="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8">
                <span className="material-icons md-dark md-18">mode_edit</span>
              </Link>
              <Link
                to={RouteConstants.PATIENT_PROFILE}
                className="btn-white-outline text-uppercase">
                View Profile
              </Link>
            </div>
          </div>
        </div>
        <div className="profile-info">
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <label>Pronouns</label>
              <div className="value more-items">
                {profileData?.user_pronouns?.length
                  ? ProfileTooltip(
                      profileData.user_pronouns,
                      targetedId.PATIENT,
                      profileKey.TITLE,
                      profileKey.OTHER_PRONOUN
                    )
                  : "-"}
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <label>Weight (Pounds)</label>
              <div className="value">{profileData?.weight || "-"}</div>
            </div>
            <div className="col-md-4 col-sm-6">
              <label>Height (Feet & Inches)</label>
              <div className="value">
                {profileData?.height_feet}
                {profileData?.height_inches
                  ? `'${profileData?.height_inches}"`
                  : ""}
                {profileData?.height_feet === "" &&
                profileData?.height_inches === ""
                  ? "-"
                  : ""}
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <label>Age</label>
              <div className="value">
                {profileData?.age ? `${profileData?.age} Years` : "-"}
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <label>Race</label>
              <div className="value more-items">
                {profileData?.user_races?.length
                  ? ProfileTooltip(
                      profileData.user_races,
                      targetedId.PATIENT,
                      profileKey.NAME,
                      profileKey.OTHER_RACE
                    )
                  : "-"}
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <label>Ethnicity</label>
              <div className="value">{profileData?.ethnicity || "-"}</div>
            </div>
            <div className="col-md-4 col-sm-6">
              <label>Language Spoke</label>
              <div className="value more-items">
                {profileData?.user_languages?.length
                  ? ProfileTooltip(
                      profileData.user_languages,
                      targetedId.PATIENT,
                      profileKey.NAME,
                      profileKey.OTHER_LANGUAGE
                    )
                  : "-"}
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <label>Gender Identity</label>
              <div className="value more-items">
                {profileData?.user_identifications?.length
                  ? ProfileTooltip(
                      profileData.user_identifications,
                      targetedId.PATIENT,
                      profileKey.TITLE,
                      profileKey.OTHER_IDENTIFICATION
                    )
                  : "-"}
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <label>Sexual Orientation</label>
              <div className="value more-items">
                {profileData?.user_sexual_orientations?.length
                  ? ProfileTooltip(
                      profileData.user_sexual_orientations,
                      targetedId.PATIENT,
                      profileKey.NAME,
                      profileKey.OTHER_SEXUAL_ORIENTATION
                    )
                  : "-"}
              </div>
            </div>
          </div>
          <h4>
            Member Since: <span>{profileData?.member_since || "-"}</span>
          </h4>
        </div>
      </div>
    </div>
  );
};
export default PatientProfile;
