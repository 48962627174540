import { UncontrolledTooltip } from 'reactstrap';
import { ApplicationConstant } from '../../../constants/application.constant';

const ProfileTooltip = (items, targetID, itemKey, itemOtherKey) => {
    const showingItems = items.slice(ApplicationConstant.ZERO, ApplicationConstant.TWO);
    const moreItems = items.slice(ApplicationConstant.TWO);

    return (
        <>
            {showingItems.map((item, index) => {
                return (
                    <span key={index}>{item[itemOtherKey] ? `${item[itemOtherKey]}` : `${item[itemKey]}`}<em /></span>
                )
            })}
            {moreItems.length ?
                <>
                    <span href="#" id={`${targetID}_${itemOtherKey}`} className="chip">{moreItems.length} More</span>
                    <UncontrolledTooltip className="moreItemTooltiip" placement="top" target={`${targetID}_${itemOtherKey}`}>
                        {moreItems.map((item, index) =>
                            <span key={index}>{item[itemOtherKey] ? `${item[itemOtherKey]}` : `${item[itemKey]}`}<em /></span>
                        )}
                    </UncontrolledTooltip>
                </>
                : ''}
        </>
    )
}

export default ProfileTooltip;
