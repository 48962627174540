import React from 'react';
import { Button } from 'reactstrap';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { ApplicationConstant, RouteConstants } from '../../../../constants/application.constant';
import { convertUtcToLocalTime } from '../../../../utils/TimezoneUtils';
import { Image } from '../../../common/image/Image';

function BookAppointment() {
  const history = useHistory();
  const location = useLocation();
  const {
    user: doctorProfile = {},
    start_date_time,
    sub_specialization,
    doctor_specializations,
  } = location.state;

  const handleClick = (e) => {
    e.preventDefault();
    history.push(RouteConstants.DASHBOARD_PATIENT);
  };
  return (
    <>
      <div className='middle-content edit-profile'>
        <div className='container'>
          <div className='row '>
            <div className='emal-sent thanks mt-50 w-100'>
              <form className='w-100'>
                <div className='thankyou-scheduling'>
                  <div className='check-box mb-50'>
                    <span className='material-icons'>done</span>
                  </div>
                  <h1>Thank you for scheduling an appointment with an equalityMD Provider.</h1>
                  <div className='purchase-card'>
                    <div className=' profile-info '>
                      <div className='box'>
                        <div className='user-info mb-0 pb-0 border-0'>
                          <div className='profile-img'>
                            <Image src={doctorProfile?.profile_image || ''} alt='Profile image' />
                          </div>
                          <div className='head'>
                            <div className='title-text text-left'>
                              <h1 className='font-500 font-16'>
                                {doctorProfile?.first_name} {doctorProfile?.last_name}
                                {doctorProfile?.first_name === '' && doctorProfile?.last_name === ''
                                  ? '-'
                                  : ''}
                              </h1>
                              <h2 className='mt-0'>
                                {sub_specialization ||
                                  (doctor_specializations?.length
                                    ? doctor_specializations[ApplicationConstant.ZERO].name
                                    : "")}
                              </h2>
                            </div>
                            <div className='text-right'>
                              <p>{convertUtcToLocalTime(start_date_time, 'LLLL')}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h2 className='mb-0'>
                    We can't wait to have you connect with our personally vetted and inclusive
                    providers.{' '}
                  </h2>
                  <h2 className='mb-0'>
                    We strive to provide you the best experience we can as a member of community.{' '}
                  </h2>
                  <h2>
                    Cancellations should be submitted 24 hours before your session{' '}
                    <span>
                      <Link className='text-decoration-underline' to={RouteConstants.TERMS_SERVICE}>Terms of service.</Link>
                    </span>{' '}
                  </h2>
                  <div className='sub-heading theme-color font-14 mt-20'>Thank you!</div>
                  <div className='sub-heading font-600 theme-color font-14 mt-5'>equalityMD</div>
                </div>
              </form>
              <div className='col-sm-12 text-center'>
                <Button className=' mt-20' color='primary' onClick={handleClick}>
                  Go to Dashboard
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BookAppointment;
