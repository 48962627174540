import React from 'react';
import ScheduleAvailability from '../components/appointment/doctor/scheduleAvailability/ScheduleAvailability';
import DoctorSchedulerProvider from '../contexts/doctor-scheduler/DoctorSchedulerProvider';
import useDocumentTitle from '../utils/DocumentTitle';
import { PageTitles } from '../constants/application.constant';

function DoctorScheduler() {
  useDocumentTitle(PageTitles.SCHEDULE_AVAILABILITY);

  return (
    <div className="step-page">
      <DoctorSchedulerProvider>
        <ScheduleAvailability />
      </DoctorSchedulerProvider >
    </div>
  );
}

export default DoctorScheduler;
