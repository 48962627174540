import { iconType, RouteConstants } from '../../../../constants/application.constant';

export const PatientSideMenuItems = [
  {
    name: 'Account Information',
    icon: 'place',
    iconClass: iconType.FILLED,
    to: RouteConstants.PATIENT_ACCOUNT_INFORMATION,
  },
  {
    name: 'User Preferences',
    icon: 'manage_accounts',
    iconClass: iconType.SHARP,
    to: RouteConstants.PATIENT_USER_PREFERENCES,
  },
  {
    name: 'User Profile',
    icon: 'account_circle',
    iconClass: iconType.FILLED,
    to: RouteConstants.PATIENT_USER_PROFILE,
  },
  {
    name: 'Favorite Providers',
    icon: 'favorite',
    iconClass: iconType.FILLED,
    to: RouteConstants.FAVOURITE_DOCTOR_LIST,
  },
  {
    name: 'Membership Information',
    icon: 'monetization_on',
    iconClass: iconType.FILLED,
    to: RouteConstants.PATIENT_MEMBERSHIP,
  },
  {
    name: 'Payment History',
    icon: 'request_quote',
    iconClass: iconType.SHARP,
    to: RouteConstants.PATIENT_PAYMENT_HISTORY,
  },
  {
    name: 'Change Password',
    icon: 'https',
    iconClass: iconType.SHARP,
    to: RouteConstants.CHANGE_PASSWORD,
  },
  {
    name: 'Logout',
    icon: 'logout',
    iconClass: iconType.OUTLINED,
  },
];
