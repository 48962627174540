import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { ApplicationConstant, PageTitles, UserRole, RouteConstants } from '../../../constants/application.constant';
import useDocumentTitle from '../../../utils/DocumentTitle';
import DoctorProfileStepper from './DoctorProfileStepper';
import DoctorProfile from './DoctorProfile';
import DoctorAppointments from './DoctorAppointments';
import axiosInstance from '../../../axios/axiosInstance';
import ApiConstant from '../../../constants/ApiConstant';
import update from 'immutability-helper';
import DoctorCalendar from './DoctorCalendar';
import UserProfileContext from '../../../contexts/user-profile/UserProfileContext';
import PurchaseSubscriptionConfirmation from '../../subscription/SubscriptionConfirmation';
import Subscription from '../../subscription/Subscription';
import { updateSubscriptionStatus } from '../../subscription/SubscriptionPaymentUtils';
import { getSearchParama } from '../../../utils/helper';

const DoctorDashboard = () => {
  useDocumentTitle(PageTitles.DOCTOR_DASHBOARD);
  const [oProfile, setProfile] = useState(null);
  const [oUserGenericData, setUserGenericData]= useContext(UserProfileContext);
  const [subscriptionModal, setSubscriptionModal] = useState(false);
  const [subscriptionConfirmationModal, setSubscriptionConfirmationModal] = useState(false);
  const history = useHistory();

  useEffect(() => {
    axiosInstance.get(`${ApiConstant.DOCTOR_DASHBOARD}`).then((res) => {
      if (res && res.data) {
        const oProfile = res.data.data || {};
        oProfile.doctorSpecialization = calculateDoctorSpecialization(oProfile);
        setProfile(oProfile);
        setUserGenericData(update(oUserGenericData, {'profile_image': { $set: oProfile?.profile_image }}));
      }
    });
  }, []);

  function calculateDoctorSpecialization(oProfile) {
    const aSpecializations = oProfile?.doctor_specializations || [];
    if (aSpecializations.length > ApplicationConstant.ZERO) {
      return aSpecializations[ApplicationConstant.ZERO]?.name || '';
    }
    return '';
  }

  const toggleSubscriptionModal = () => {
    setSubscriptionModal(!subscriptionModal);
  }

  const toggleSubscriptionConfirmationModal = () => {
    setSubscriptionConfirmationModal(!subscriptionConfirmationModal);
  }

  const updateDoctorSubscriptionStatus = async () => {
    const subscriptionSessionId = getSearchParama(history, 'session_id');
    if(subscriptionSessionId) {
      await updateSubscriptionStatus(subscriptionSessionId)
      .then((_res) => {
        history.replace({pathname: RouteConstants.DASHBOARD_DOCTOR, search: ''})
        toggleSubscriptionConfirmationModal();
      }).catch((error) => {
        toast.error(error || '')
      });
    }
  }

  useEffect(()=>{
    updateDoctorSubscriptionStatus();
  },[])

  return (
    <>
      <div className="dashboard-page">
        <div className="container">
          <section className="detail-info">
            <DoctorProfileStepper
              oDoctorProfile={oProfile}
              toggleSubscriptionModal={toggleSubscriptionModal}
            />
            <DoctorProfile oDoctorProfile={oProfile} />
          </section>
          <div className="row">
            <div className="col-sm-12 col-lg-6">
              <DoctorAppointments />
            </div>
            <div className="col-sm-12 col-lg-6 dashboard-calendar-card">
              <section className="my-matched-section">
              <div className="heading-link">
                <h5>My Calendar</h5>
              </div>
              <DoctorCalendar />
            </section>
            </div>
          </div>
        </div>
      </div>
      {subscriptionModal &&
        <Subscription
          isOpen={subscriptionModal}
          setModelOpen={toggleSubscriptionModal}
          modalClassName="subscription-plan"
          discountAmount="50"
          title="Memberships!"
          subTitle="Become an equalityMD member! It is required that all  providers become members before accepting any appointments."
          subscriptionPlanValidity="Monthly"
          primaryButtonText="Sign up!"
          secondaryButtonText="Not Today"
          isAccessRestrictions={false}
          subscriptionRole={UserRole.DOCTOR}
        />
      }
      {subscriptionConfirmationModal &&
        <PurchaseSubscriptionConfirmation
          isOpen={subscriptionConfirmationModal}
          roleType={UserRole.DOCTOR}
          setModelOpen={toggleSubscriptionConfirmationModal}
          modalClassName="subscription-confirmation"
        />
      }
    </>
  );
};

export default DoctorDashboard;
