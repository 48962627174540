import React, { useState } from 'react';
import { RouteConstants } from '../../../../constants/application.constant';
import GoBack from '../../../common/back/GoBack';
import { HospitalSideMenuItems } from './HospitalSideMenuItems';
import CommonSidebar from '../../CommonSidebar';

const HospitalProfileSidebar = () => {
  const [pageHeading, setPageHeading] = useState('');
  const setHospitalProfileTitle = (title) => {
    setPageHeading(title);
  };

  return (
    <div className="profile-sidebar">
      <div className="ml-0">
        <GoBack heading={pageHeading} targetPath={RouteConstants.DASHBOARD_HOSPITAL} />
      </div>
      <CommonSidebar sideMenuItems={HospitalSideMenuItems} setPageTitle={setHospitalProfileTitle} />
    </div>
  );
};

export default HospitalProfileSidebar;
