const DoctorListHeader = (props) => {
  const { tableHeadContent, sortData, filter } = props;

  const isSortActive = (sortKey, sortValue) => {
    const { sortBy, order } = filter;
    if (sortKey === sortBy && sortValue === order) {
      return 'active';
    } else {
      return '';
    }
  };

  return (
    <div className="table-head">
      <div className="headRow">
        {tableHeadContent.map((item, index) => {
          return (
            <div key={index} className={`table-col ${item.class}`}>
              <strong>{item.title}</strong>
              {item.sort && (
                <div className="sorting-icon">
                  <span
                    className={`material-icons-outlined ${isSortActive(item.sortKey, 'asc')}`}
                    onClick={() => {
                      sortData(item.sortKey, 'asc');
                    }}
                  >
                    expand_less
                  </span>
                  <span
                    className={`material-icons-outlined ${isSortActive(item.sortKey, 'desc')}`}
                    onClick={() => {
                      sortData(item.sortKey, 'desc');
                    }}
                  >
                    expand_more
                  </span>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DoctorListHeader;
