import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { logout } from '../../../utils/CommonRequests';
import { Image } from './../../common/image/Image';
import CustomModal from '../../common/modal/CustomModal';
import {
  ModalConstants,
  RouteConstants,
  AppointmentSideBarRoutes,
} from '../../../constants/application.constant';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { HEADERLOGO } from '../../../constants/image';
import { getMatchedPath, getProfileImage, showHideZoomWindow } from '../../../utils/helper';

export const DoctorHeader = (props) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const [isOpen, setModalOpen] = useState(false);
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const toggleLogOutModal = () => setModalOpen(!isOpen);
  const [open, setNavOpen] = React.useState(false);

  const onSignOut = (isSignOut) => {
    toggleLogOutModal();
    if (isSignOut) {
      logout();
    }
  };

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname === RouteConstants.DOCTOR_SESSION_CONTAINER) {
        showHideZoomWindow('zmmtg-root', 'block');
      }
      else {
        showHideZoomWindow('zmmtg-root', 'none');
      }
    })
 },[history])

  return (
    <header>
      <div className='container'>
        <div className='headerSection'>
          <div className='header-logo'>
            <span
              className='material-icons'
              onClick={() => {
                setNavOpen(!open);
              }}
            >
              {open ? 'close' : 'menu'}
            </span>
            <Link to={RouteConstants.DASHBOARD_DOCTOR}>
              <img src={HEADERLOGO} all='logo' />
            </Link>
          </div>
          <nav className={open ? 'nav open' : 'nav none'}>
            <ul>
              <li
                onClick={() => {setNavOpen(!open);}}
                className={pathname === RouteConstants.DASHBOARD_DOCTOR ? 'active' : ''}>
                <Link to={RouteConstants.DASHBOARD_DOCTOR}>Home</Link>
              </li>
              <li
                onClick={() => {setNavOpen(!open);}}
                className={getMatchedPath(AppointmentSideBarRoutes, pathname) ? 'active' : ''}>
                <Link to={RouteConstants.DOCTOR_PENDING_APPOINTMENT}>Appointments</Link>
              </li>
              <li
                onClick={() => {setNavOpen(!open);}}
                className={pathname === RouteConstants.DOCTOR_SCHEDULE_AVAILABILITY ? 'active' : ''}>
                <Link to={RouteConstants.DOCTOR_SCHEDULE_AVAILABILITY}>My Availability</Link>
              </li>
            </ul>
          </nav>
          <div className='small-right-nav'>
            <div className='small-right-nav-wrapper'>
              <ul>
                <li>
                  <a>
                    <ButtonDropdown
                      className='profile-dropdown doctor-profile-dropdown'
                      direction='left'
                      isOpen={dropdownOpen}
                      toggle={toggle}
                    >
                      <DropdownToggle>
                        <div className="d-flex align-items-center">
                          <div className='profile-img'>
                            <Image src={getProfileImage()} alt='Profile Image' />
                            <span className='material-icons md-36 d-none'>account_circle</span>
                          </div>
                          <span className='material-icons md-18'>expand_more</span>
                        </div>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem header>Account Details</DropdownItem>
                        <DropdownItem tag={Link} to={RouteConstants.DOCTOR_ACCOUNT_INFORMATION}>
                          <span className='material-icons'>place</span> Account Information
                        </DropdownItem>
                        <DropdownItem tag={Link} to={RouteConstants.DOCTOR_USER_PROFILE}>
                          <span className='material-icons'>account_circle</span>User Profile
                        </DropdownItem>
                        <DropdownItem tag={Link} to={RouteConstants.DOCTOR_BACKGROUND_INFO}>
                          <span className='material-icons'>info</span>Background Information
                        </DropdownItem>
                        <DropdownItem tag={Link} to={RouteConstants.DOCTOR_PAYMENT}>
                          <span className='material-icons'>monetization_on</span>Membership Information
                        </DropdownItem>
                        <DropdownItem tag={Link} to={RouteConstants.DOCTOR_PAYMENT_HISTORY}>
                        <span class="material-icons-sharp">request_quote</span>Payment History
                        </DropdownItem>
                        <DropdownItem className="doctor-change-password-btn" tag={Link} to={RouteConstants.CHANGE_PASSWORD}>
                          <span className='material-icons'>https</span> Change Password
                        </DropdownItem>
                        <DropdownItem onClick={toggleLogOutModal}>
                          <span className='material-icons-outlined'>logout</span>Logout
                        </DropdownItem>
                      </DropdownMenu>
                      <CustomModal
                        modalTitle={ModalConstants.SIGN_OUT_TITLE}
                        modalMessage={ModalConstants.SIGN_OUT_MESSAGE}
                        secondaryButonText={ModalConstants.CANCEL}
                        primaryButtonText={ModalConstants.SIGN_OUT}
                        setModelOpen={toggleLogOutModal}
                        isOpen={isOpen}
                        onButtonClicked={onSignOut}
                        modalClassName='modal-logout'
                      />
                    </ButtonDropdown>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
