import React, { useContext, useEffect, useState } from 'react';
import { getCitiesById } from '../../../utils/CommonRequests';
import InputElement from '../../common/formElements/InputElement';
import update from 'immutability-helper';
import { ApplicationConstant } from '../../../constants/application.constant';
import SelectElement from '../../common/formElements/SelectElement';
import { zipCodeValidation } from '../../../validators/CommonValidation';
import DoctorSignUpContext from '../../../contexts/doctor-signup/DoctorSignUpContext';

function DoctorLocationPage(props) {
  const { oLabelInfo } = props;
  const [doctorSignUp, setDoctorSignUp] = useContext(DoctorSignUpContext);
  const [stateId, setStateId] = useState(null);
  const [cities, setCities] = useState([]);

  const setLocationValues = (prop, val) => {
    setDoctorSignUp(update(doctorSignUp, { doctor_profile: { location: { [prop]: { $set: val } } } }));
  };

  const setAddressValue = (e) => {
    e.preventDefault();
    setLocationValues(e.target.name, e.target.value);
  };

  const onCityInput = (e) => {
    setLocationValues('city_id', e.id);
    setLocationValues('city', e);
  };

  const onStateInput = (e) => {
    setStateId(e.id);
    setLocationValues('state_id', e.id);
    setLocationValues('state', e);
  };

  const setZipCode = (e) => {
    zipCodeValidation('zip_code', e.target.value, setLocationValues);
  };

  useEffect(() => {
    getCitiesById(stateId).then((res) => {
      if (res.length > ApplicationConstant.ZERO) {
        setCities(res.map((item) => ({ ...item, label: item.name, value: item.name.toLowerCase() })));
      }
    });
    if (!!stateId) {
      setLocationValues('city', '');
    }
  }, [stateId]);

  return (
    <div className="form-step">
      <form className="w-100">
        <div className="head-title mb-50">
          <div className="heading">Provider Registration</div>
          <div className="sub-heading">Location</div>
        </div>
        <h3>Add Primary Address</h3>
        <div className="row">
          <InputElement
            className="col-sm-6"
            type="text"
            name="address1"
            value={doctorSignUp.doctor_profile.location.address1}
            onChange={setAddressValue}
            autoComplete={ApplicationConstant.OFF}
            placeholder={'Please enter street address*'}
            label={'Street Address*'}
          />
          <InputElement
            className="col-sm-6"
            type="text"
            name="address2"
            value={doctorSignUp.doctor_profile.location.address2}
            onChange={setAddressValue}
            autoComplete={ApplicationConstant.OFF}
            placeholder={'Please enter appartment'}
            label={'Apartment'}
          />

          <SelectElement
            classNm={'col-sm-6'}
            name={'state'}
            onChange={onStateInput}
            className="reactSelect"
            classNamePrefix="react-select"
            options={oLabelInfo.states}
            value={doctorSignUp.doctor_profile.location.state}
            isSearchable={ApplicationConstant.TRUE}
            placeholder={''}
            label={'State*'}
          />
          <SelectElement
            classNm={'col-sm-6'}
            name={'city'}
            onChange={onCityInput}
            className="reactSelect"
            classNamePrefix="react-select"
            options={cities}
            value={doctorSignUp.doctor_profile.location.city}
            isSearchable={ApplicationConstant.TRUE}
            placeholder={''}
            label={'City*'}
          />

          <InputElement
            className="col-sm-12"
            type="number"
            name="zip_code"
            value={doctorSignUp.doctor_profile.location.zip_code.value}
            onChange={setZipCode}
            autoComplete={ApplicationConstant.OFF}
            placeholder={'Please enter zipcode'}
            label={'Zip Code*'}
            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
            isValid={doctorSignUp.doctor_profile.location.zip_code.valid}
            msg={doctorSignUp.doctor_profile.location.zip_code.msg}
          />
        </div>
      </form>
    </div>
  );
}

export default DoctorLocationPage;
