import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'reactstrap';
import PageLogo from '../../common/logo/PageLogo';

function EmailSent({ emailValue, handleClick }) {
  return (
    <div className="full-bg-color">
      <div className="container">
        <div className="landing-ui">
          <div className="emal-sent">
            <form className="w-100">
              <div className="w-350">
                <div className="login-title">
                  <PageLogo />
                </div>
                <div className="check-box">
                  <span className="material-icons">done</span>
                </div>
                <h1>Email sent successfully</h1>
                <h2>
                  We have sent a reset password email to <span className="font-600">"{emailValue}"</span>
                </h2>
                <h2 className="mt-15">
                  <span>Please click the reset password link to set your password.</span>
                </h2>
                <div className="row mt-30">
                  <div className="col-sm-12">
                    <Button className="w-100 mb-20" color="primary" onClick={handleClick}>
                      Resend Link
                    </Button>
                  </div>
                  <div className="col-sm-12">
                    <div className="signup-text">
                      <NavLink to="/">Back to Login</NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailSent;
