import React from 'react';
import { Route, Switch, Link, BrowserRouter as Router } from 'react-router-dom';
import Dashboard from '../ui-designs/pages/dashboard';
import DoctorDashboard from '../ui-designs/pages/DoctorDashboard';
import HospitalDashboard from '../ui-designs/pages/HospitalDashboard';
import PatientEditProfile from '../ui-designs/pages/PatientEditProfile';
import ViewProfile from '../ui-designs/pages/ViewProfile';
import Mainheader from '../ui-designs/pages/mainheader';
import Mainfooter from '../ui-designs/pages/mainfooter';
import Landing from '../ui-designs/pages/landing';
import Middle from '../ui-designs/step-ui/middle';
import DoctorRegForm from '../ui-designs/pages/DoctorRegForm';
import ContactUs from'../ui-designs/pages/ContactUs';
import SubscriptionPlan from './pages/SubscriptionPlan';
import Faq from './pages/Faq';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import DoctorZoom from './pages/DoctorZoom';
const HtmlDesign = () => {
  return (
    <Router>
      <Mainheader />
      <Route path="/contact"><ContactUs/></Route>
      <Route path="/subscription-plan"><SubscriptionPlan /></Route>
      <Route path="/faqs"><Faq /></Route>
      <Route path="/privacy-policy"><PrivacyPolicy /></Route>
      <Route path="/terms-of-service"><TermsOfService /></Route>
      
      <Route path="/doctor"><DoctorDashboard /></Route>
      <Route path="/hospital"><HospitalDashboard /></Route>
      <Route exact path="/html-design"><Dashboard /> </Route>
      <Route path="/patient/profile-edit"><PatientEditProfile /></Route>
      <Route path="/patient/profile-view"><ViewProfile /></Route>
      <Route path="/html-design/doctor-zoom"><DoctorZoom /></Route>
      
      {/* <Mainfooter /> */}
      {/* <Landing /> */}
      {/* <div className="step-page">
      <Middle/>
      </div> */}
      {/* <DoctorRegForm/> */}
      <div className="login-footer d-block" style={{zIndex: "2"}}>
          <div className="container d-flex justify-content-between w-100 mt-10">
            <p>Copyright © 2021 equalityMD. All right reserved.</p>
            <ul>
              <li><Link to="/faqs">FAQs</Link></li>
              <li><Link to="contact">Contact Us</Link></li>
              <li><Link to="/privacy-policy">Privacy Policy</Link></li>
              <li><Link to="/terms-of-service">Terms of Service</Link></li>
              <li><Link to="/html-design">patient</Link> <Link to="/doctor">doctor</Link> <Link to="/hospital">hospital</Link></li>
            </ul>
          </div>
          <div className="container w-100">
            <ul className="justify-content-end">
              <li><Link to="/html-design/doctor-zoom">Doctor zoom</Link></li>
            </ul>
          </div>
        </div>
    </Router>
  )
}

export default HtmlDesign;