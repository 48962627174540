import React, { useContext } from 'react';
import { ApplicationConstant } from '../../../constants/application.constant';
import UserRegistrationContext from '../../../contexts/user-registration/UserRegistrationContext';
import { getActiveStateAndValidity, goToNextStep } from '../../../utils/PatientProfileUtil';

const MAX_STEP = ApplicationConstant.NINE;
function SkipToNextProfile({handleSave}) {
    const [ oUserRegistration, setUserRegistration ] = useContext(UserRegistrationContext);

    const nextStep = (e) => {
        e.preventDefault();
        if (oUserRegistration.patient_profile.current_step === MAX_STEP) {
            handleSave();
        } else {
            goToNextStep(oUserRegistration, setUserRegistration);
        }
    }

    if (getActiveStateAndValidity(oUserRegistration, oUserRegistration.patient_profile.current_step).isValid) {
        return <></>
    } else {
        return (
            <div className="form-skip text-right">
                <span onClick={nextStep}>skip</span>
            </div>
        );
    }
}

export default SkipToNextProfile;
