import { ApplicationConstant } from '../../../../constants/application.constant';

export const validateQuestions = (aQuestions) => {
  if (aQuestions.length > ApplicationConstant.ZERO) {
    for (const oQuestion of aQuestions) {
      const selectionOption = oQuestion.option;
      const description = oQuestion.description;
      if (!selectionOption || (selectionOption === 'Y' && !description)) {
        return false;
      }
    }
  }
  return true;
};
