import React, { Fragment, useEffect, useState } from 'react';
import PageLogo from '../common/logo/PageLogo';
import useDocumentTitle from '../../utils/DocumentTitle';
import { ApplicationConstant, PageTitles } from '../../constants/application.constant';
import ApiConstant from '../../constants/ApiConstant';
import axiosInstance from '../../axios/axiosInstance';
import FaqQuestionItem from './FaqQuestionItem';
import SpinnerComponent from '../common/spinner/SpinnerComponent';
import GoBack from '../common/back/GoBack';

function Faq() {
  const [selectedQuestionTypeId, setQuestionTypeId] = useState(null);
  const [aQuestionTypes, setQuestionTypes] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [navigationOpen, setNavigationOpen] = React.useState(false);
  useDocumentTitle(PageTitles.FAQ);

  useEffect(() => {
    setLoading(true);
    axiosInstance.get(ApiConstant.FAQS).then(
      (res) => {
        setLoading(false);
        const aQuestions = res?.data?.data || [];
        setQuestions(aQuestions);
      },
      (_error) => {
        setLoading(false);
      }
    );
  }, []);

  function setQuestions(aQuestions) {
    if (aQuestions.length > ApplicationConstant.ZERO) {
      const aData = aQuestions.map((item) => ({
        ...item,
        isSelected: false,
      }));
      setQuestionTypes(aData);
      setQuestionTypeId(aData[ApplicationConstant.ZERO].id);
    }
  }

  const handleClick = (index) => {
    const oQuestionType = aQuestionTypes[index];
    setQuestionTypeId(oQuestionType?.id);
    setNavigationOpen(false);
  };

  function getQuestions() {
    if (selectedQuestionTypeId) {
      const oFaqType = aQuestionTypes.find((oQuestion) => oQuestion.id === selectedQuestionTypeId);
      return oFaqType?.description || [];
    }
    return [];
  }

  return (
    <Fragment>
      {isLoading && <SpinnerComponent />}
      {!isLoading && (
        <div className="middle-content mb-0 mt-0 pb-100 h-100vh faqs">
          <div className="container">
            <div className="back-login">
              <GoBack />
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="login-title">
                  <PageLogo />
                  <h1 className="mt-30">FAQs</h1>
                </div>
              </div>
              <div className="col-md-4 faq-nav">
                <div className={navigationOpen ? 'edit-nav open' : 'edit-nav'}>
                  {aQuestionTypes.length > ApplicationConstant.ZERO ? (
                    <ul>
                      {aQuestionTypes.map((item, index) => {
                        return (
                          <li
                            key={index}
                            onClick={() => handleClick(index)}
                            className={`${item.id === selectedQuestionTypeId ? 'active' : ''}`}
                          >
                            <span className="name">{item?.title}</span>
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    ''
                  )}
                  <a
                    class="nav-toggal"
                    onClick={() => {
                      setNavigationOpen(!navigationOpen);
                    }}
                  >
                    <span class="material-icons">{navigationOpen ? 'chevron_left' : 'chevron_right'}</span>
                  </a>
                </div>
              </div>
              <div className="col-md-8">
                <div className="pages">
                  <div className="accordion faq-accordion">
                    <FaqQuestionItem aQuestions={getQuestions()} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default Faq;
