const UserRegistrationInitialState = {
  email: 'sk2@yopmail.com',
  user_role: '',
  role_id: 0,
  patient_profile: {
    current_step: 1,
    pronoun_name: '',
    pronoun_id: '',
    other_pronoun: '',
    weight: '',
    height_feet: '',
    height_inches: '',
    age: '',
    date_of_birth: '',
    race_name: '',
    race_id: '',
    other_race: '',
    ethnicity_name: '',
    ethnicity_id: '',
    other_ethnicity: '',
    identification_name: '',
    identification_id: '',
    user_identifications: [],
    other_identification: '',
    sexual_orientation_name: '',
    sexual_orientation_id: '',
    user_sexual_orientations: [],
    other_sexual_orientation: '',
    notes: '',
    user_languages: [],
    user_pronouns: [],
    user_races:[],
    other_language: '',
    gender_prefrence_first:'',
    gender_prefrence_second:'',
    age_prefrence_first:'',
    age_prefrence_second:'',
    race_prefrence_first:'',
    race_prefrence_second:'',
    orentation_prefrence_first:'',
    orentation_prefrence_second:'',
    location_prefrence_first:'',
    location_prefrence_second:'',
    ethnicity_prefrence_first:'',
    ethnicity_prefrence_second:'',
    language_prefrence_first:'',
    language_prefrence_second:''
  },
  patient_details: {
    current_step: 1,
    role_id: '',
    user_Name: '',
    password: '',
    confirm_password: '',
    first_name: '',
    last_name: '',
    preferred_name: '',
    date_of_birth: '',
    email: '',
    phone: '',
    location: {
      address1: '',
      address2: '',
      state_id: '',
      city_id: '',
      zip_code: '',
    },
  },
};

export default UserRegistrationInitialState;
