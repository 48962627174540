import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import {
  PageTitles,
  ApplicationConstant,
  RouteConstants,
} from '../../../../../constants/application.constant';
import axiosInstance from '../../../../../axios/axiosInstance';
import ApiConstant from '../../../../../constants/ApiConstant';
import EmptyStateComponent from '../../../../common/emptyStateComponent/EmptyStateComponent';
import { EMPTY_ICON } from '../../../../../constants/image';
import SpinnerComponent from '../../../../common/spinner/SpinnerComponent';
import PatientUpcomingAppointmentItem from './PatientUpcomingAppointmentItem';
import useDocumentTitle from '../../../../../utils/DocumentTitle';

function PatientUpcomingAppointments(props) {
  useDocumentTitle(PageTitles.UPCOMING_APPOINTMENT);
  const { isDashboard } = props;
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [upcomingAppointments, setUpcomingAppointments] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [listInitialPage, setListInitialPage] = useState(0);

  useEffect(() => {
    fetchUpcomingAppointments();
  }, [currentPage]);

  const fetchUpcomingAppointments = async () => {
    setLoading(true);
    const pageSize = isDashboard ? ApplicationConstant.THREE : ApplicationConstant.TEN;
    await axiosInstance
      .get(`${ApiConstant.PATIENT_UPCOMING_APPOINTMENT}?page=${currentPage}&limit=${pageSize}`)
      .then((res) => {
        if (res && res.data) {
          const upcomingAppointmentList = res.data?.data?.data || [];
          const totalPageCount = res.data?.data?.last_page || '';
          setUpcomingAppointments(upcomingAppointmentList);
          setPageCount(totalPageCount);
        }
      })
      .catch((error) => {
        toast.error(error || '');
      });
    setLoading(false);
  };

  const cancelAppointment = (appointmentId) => {
    const targetPath = isDashboard
      ? RouteConstants.DASHBOARD_PATIENT
      : RouteConstants.PATIENT_UPCOMING_APPOINTMENT;
    history.push({
      pathname: RouteConstants.PATIENT_CANCEL_CONFIRMATION,
      state: {
        appointmentId: appointmentId,
        targetPath: targetPath,
      },
    });
  };

  const handleViewAll = () => {
    history.push(RouteConstants.PATIENT_UPCOMING_APPOINTMENT);
  };

  const changePagination = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage + ApplicationConstant.ONE);
    setListInitialPage(selectedPage);
  };

  return (
    <>
      <div className='middle-content edit-profile user-right-container'>
        <div className='pages'>
          <div className='profile-information'>
            <div className='form-step max-w-100'>
              <div className='appointments doctor-appointment-list'>
                <div className='profile-info'>
                  <div className='heading-link' hidden={!isDashboard}>
                    <h5>Upcoming Appointments</h5>
                    <a onClick={handleViewAll} hidden={pageCount === ApplicationConstant.ONE}>
                      <span>VIEW ALL</span>
                    </a>
                  </div>
                  <div className='box'>
                    <div className='scroller'>
                      {isLoading ? (
                        <SpinnerComponent />
                      ) : (
                        <>
                          {upcomingAppointments?.length ? (
                            upcomingAppointments.map((item, i) => {
                              return (
                                <PatientUpcomingAppointmentItem
                                  item={item}
                                  key={i}
                                  cancelBooking={cancelAppointment}
                                />
                              );
                            })
                          ) : (
                            <>
                              {!isLoading && (
                                <EmptyStateComponent
                                  imageSrc={EMPTY_ICON}
                                  title='No Upcoming Appointments.'
                                />
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div
                    className='pagination-wrapper'
                    hidden={pageCount === ApplicationConstant.ONE || isDashboard}
                  >
                    <ReactPaginate
                      previousLabel={'previous'}
                      nextLabel={'next'}
                      breakLabel={'...'}
                      breakClassName={'break-me'}
                      pageCount={pageCount}
                      marginPagesDisplayed={4}
                      onPageChange={changePagination}
                      pageRangeDisplayed={1}
                      forcePage={listInitialPage}
                      containerClassName={'pagination'}
                      activeClassName={'active'}
                      nextLinkClassName={'next-btn'}
                      previousLinkClassName={'prev-btn'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PatientUpcomingAppointments;
