import React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import FormCheckBox from '../../common/formElements/FormCheckBox';
import { PROFILEIMG } from '../../../constants/image';
import InputElement from '../../common/formElements/InputElement';

const PendingAppointmentModal = (props) => {
  const {
    className,
    modalClassName,
    isOpen,
    setModelOpen,
    onButtonClicked,
    primaryButtonText
  } = props;

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          setModelOpen();
        }}
        className={className}
        modalClassName={modalClassName}
      >
        <ModalBody className="emal-sent">
            <h1>Pending Appointment </h1>
            <div className="profile-info">
                  <div className="box">
                    <div className="user-info">
                      <div className="profile-img active">
                        <img src={PROFILEIMG}></img>
                      </div>
                      <div className="head">
                        <div className="title-text">
                          <h2>With: <span>Patrick James</span></h2>
                          <h2>Date: <span>June 17, 2021</span></h2>
                          <h2>Time: <span>12:00 PM</span></h2>
                          <h2>Appointment: <span>In Person</span></h2>
                        </div>
                        <div className="button-group">
                        <Button
                                color="secondary"
                                onClick={() => {
                                onButtonClicked(false);
                                }}
                            >
                            {'Reject'}
                        </Button>
                        <Button
                                color="primary"
                                onClick={() => {
                                onButtonClicked(false);
                                }}
                            >
                            {'Accept'}
                        </Button>
                        <Button
                                color="secondary"
                                onClick={() => {
                                onButtonClicked(false);
                                }}
                            >
                            {'Suggest a Different Time'}
                        </Button>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <InputElement
                    className="w-100"
                    type="textarea"
                    height="100"
                    // autoComplete={ApplicationConstant.OFF}
                    placeholder={'Please enter value'}
                    label={'Description*'}
                    name={''}
                    value={''}
                    onChange={''}
                  />

        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              onButtonClicked(true);
            }}
          >
            {primaryButtonText}
          </Button>{' '}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default PendingAppointmentModal;
