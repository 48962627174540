import { useEffect, useState } from 'react';
import { ZoomMtg } from '@zoomus/websdk';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast, Slide } from 'react-toastify';
import axiosInstance from '../../axios/axiosInstance';
import { RouteConstants } from '../../constants/application.constant';
import ApiConstant from '../../constants/ApiConstant';
import '../../assets/scss/_zoom.scss';

const ZoomMeeting = (props) => {
  const history = useHistory();
  const { meetingId, role, leaveUrl, userName, email, completeSession, fetchSessionDuration, isSessionExpire } = props;
  const [zoomMeetingStatus, setZoomMeetingStatus] = useState(0);
  const [meetingStartTime, setMeetingStartTime] = useState(0);

  const meetingConfig = {
    apiKey: process.env.REACT_APP_ZOOM_API_KEY,
    meetingNumber: meetingId,
    leaveUrl: leaveUrl,
    userName: userName,
    userEmail: email,
    passWord: '',
    role: role
  };

  const joinMeeting = (meetingSignature) => {
    /*ZoomMtg.prepareWebSDK(['WebCodecs', 'WebAssembly SIMD']);
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareJssdk();*/
    ZoomMtg.init({
      leaveUrl: meetingConfig.leaveUrl,
      //disableCORP: !window.crossOriginIsolated,
      isSupportAV: true,
      success: (success) => {
        ZoomMtg.join({
          signature: meetingSignature,
          apiKey: meetingConfig.apiKey,
          meetingNumber: meetingConfig.meetingNumber,
          userName: meetingConfig.userName,
          userEmail: meetingConfig.userEmail,
          success: (successMsg) => {
            toast.success(successMsg || '');
            setMeetingStartTime(moment())
          },
          error: (error) => {
            toast.error(error || '');
          }
        })
        ZoomMtg.inMeetingServiceListener('onMeetingStatus', function (data) {
          setZoomMeetingStatus(data?.meetingStatus);
        });
      },
      error: (error) => {
        toast.error(error || '');
      }
    })
  }

  const getSignature = async () => {
    await axiosInstance.post(ApiConstant.ZOOM_SIGNATURE, meetingConfig).then(
    (response) => {
      if(response && response.data && response.data.data) {
        const meetingSignature = response.data.data?.signature || '';
        meetingSignature && joinMeeting(meetingSignature);
      }
    })
    .catch((error) => {
      toast.error(error || '');
    });
  }

  const getMeetingDuration = async(sessionCompletedTime) => {
    await fetchSessionDuration(sessionCompletedTime).then((response) => {
      if(response) {
        ZoomMtg.endMeeting(
          history.push({
            pathname: `${RouteConstants.DOCTOR_UPCOMING_APPOINTMENT}`,
          }),
          {success: (res) => {
            toast.success(res || '')
          },
          error: (err) =>{toast.error(err || '')}
        });
      }
    }).catch((error) => {toast.error(error || '')})
  }

  const endZoomMeeting = async() => {
    const meetingDuration = moment.duration(moment().diff(meetingStartTime))
    const sessionCompletedTime = Math.round(meetingDuration.asMinutes());
    await completeSession().then((response) => {
      if(response) {
        getMeetingDuration(isSessionExpire ? 60 : sessionCompletedTime);
      }
    })
    .catch((error) => {toast.error(error || '')})
  };

  const leaveMeeting = () => {
    ZoomMtg.leaveMeeting({
      success: function(res) {
        toast.success(res || '');
      },
      error: function (err) {
        toast.error(err || '');
      }
    });
  }

  const initializeMeeting = () => {
    ZoomMtg.setZoomJSLib('https://source.zoom.us/1.9.8/lib', '/av');
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareJssdk();
    getSignature();
  }

  useEffect(() => {
    initializeMeeting();
  }, []);

  return (
    <>
      <ToastContainer autoClose={4000} hideProgressBar transition={Slide} />
      {role === 1 ?
        <button
          type="button"
          hidden={zoomMeetingStatus !== 2 && !isSessionExpire}
          className="btn btn-primary end-meeting-btn"
          onClick={endZoomMeeting}>{isSessionExpire ? 'End Session' : 'End Meeting'}
        </button>
      : <button
          type="button"
          hidden={zoomMeetingStatus !== 2}
          className="btn btn-primary end-meeting-btn"
          onClick={leaveMeeting}>leave Meeting
        </button>
      }
    </>
  )
}

export default ZoomMeeting;
