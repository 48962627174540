import { TableClassNameObj } from './../../../../../../constants/application.constant';

const PaymentHistoryDetailListHeader = (props) => {
  const { sortTableData, sortFilterObj } = props;

  const sortActiveClass = (sortKey, sortValue) => {
    const { sortBy, order } = sortFilterObj;
    if (sortKey === sortBy && sortValue === order) {
      return 'active';
    } else {
      return '';
    }
  };

  const tableHeadContent = [
    { title: 'Patient', sortKey: 'name', class: `${TableClassNameObj.col12}`, sort: true },
    { title: 'Session fee', sortKey: 'amount', class: `${TableClassNameObj.col18}`, sort: true },
    { title: 'Platform fee', sortKey: 'application_fee', class: `${TableClassNameObj.col18}`, sort: true },
    { title: 'Net to provider', sortKey: 'net_to_provider', class: `${TableClassNameObj.col18}`, sort: true },
    { title: 'date', sortKey: 'payment_date', class: `${TableClassNameObj.col14}`, sort: true },
    { title: 'Action', class: `${TableClassNameObj.col20}`, sort: false },
  ];


  return (
    <div className="headRow">
      {tableHeadContent.map((item, index) => {
        return (
          <div key={index} className={`table-col ${item.class}`}>
            <strong>{item.title}</strong>
            {item.sort && (
              <div className="sorting-icon">
                <span
                  className={`material-icons-outlined ${sortActiveClass(item.sortKey, 'asc')}`}
                  onClick={() => {
                    sortTableData(item.sortKey, 'asc');
                  }}
                >
                  expand_less
                </span>
                <span
                  className={`material-icons-outlined ${sortActiveClass(item.sortKey, 'desc')}`}
                  onClick={() => {
                    sortTableData(item.sortKey, 'desc');
                  }}
                >
                  expand_more
                </span>
              </div>
            )}
          </div>
        );
      })}
    </div>
  )
}

export default PaymentHistoryDetailListHeader;
