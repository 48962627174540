import axiosInstance from '../axios/axiosInstance';
import ApiConstant from '../constants/ApiConstant';
import { getFormattedDate } from './helper';

export const savePatientAccountCreationData = async (oAccountDetailsData) => {
  let response = null;
  const dob = getFormattedDate(oAccountDetailsData.date_of_birth.value)
  try {
    const location = {
      address1: oAccountDetailsData.location.address1,
      address2: oAccountDetailsData.location.address2,
      state_id: oAccountDetailsData.location.state.id,
      city_id: oAccountDetailsData.location.city.id,
      zip_code: oAccountDetailsData.location.zip_code.value,
    };
    const oPayload = {
      first_name: oAccountDetailsData.first_name.value,
      last_name: oAccountDetailsData.last_name.value,
      date_of_birth: dob,
      email: oAccountDetailsData.email.value,
      phone: oAccountDetailsData.phone.value,
      password: oAccountDetailsData.password.value,
      preferred_name:oAccountDetailsData.preferred_name.value,
      confirm_password: oAccountDetailsData.confirm_password.value,
      location: location,
      is_disply_preferred_name: oAccountDetailsData.is_disply_preferred_name
    };

    const ret = await axiosInstance.post(ApiConstant.PATIENT_SIGNUP, oPayload);
    ret.then((_res) => (response = _res)).catch((err) => (response = err));
  } catch (e) {
    console.log(e);
  }
  return response;
};
