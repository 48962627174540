import React, { Fragment, useEffect, useState } from 'react';
import SpinnerComponent from '../../common/spinner/SpinnerComponent';
import axiosInstance from '../../../axios/axiosInstance';
import useDocumentTitle from '../../../utils/DocumentTitle';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import PageLogo from '../../common/logo/PageLogo';
import { PageTitles } from '../../../constants/application.constant';
import ApiConstant from '../../../constants/ApiConstant';
import { ToastContainer, toast, Slide } from 'react-toastify';
import TokenExpired from './TokenExpired';
import { useLocation } from 'react-router';
import update from 'immutability-helper';
import InputElement from '../../common/formElements/InputElement';
import { validatePassword, isValidConfirmPassword } from './ResetPasswordHelper';

function ResetPassword() {
  const initialState = { token: '', password: '', password_confirmation: '' };
  const [resetPasswordState, setResetPasswordState] = useState(initialState);
  const [isTokenExpired, setTokenExpired] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();
  useDocumentTitle(PageTitles.RESET_PASSWORD);

  useEffect(() => {
    setLoading(true);
    const token = query.get('token') || '';
    axiosInstance.get(`${ApiConstant.VALIDATE_TOKEN}/${token}`).then(
      (_res) => {
        setLoading(false);
        setResetPasswordState(update(resetPasswordState, { token: { $set: token } }));
      },
      (_error) => {
        setLoading(false);
        setTokenExpired(true);
      }
    );
  }, []);

  const handleChange = (e) => {
    setResetPasswordState(update(resetPasswordState, { [e.target.name]: { $set: e.target.value } }));
  };

  const handleClick = (e) => {
    e.preventDefault();
    setLoading(true);
    axiosInstance.post(ApiConstant.RESET_PASSWORD, resetPasswordState).then(
      (_res) => {
        setLoading(false);
        history.replace('/');
        toast.success('Password has been reset successfully.');
      },
      (error) => {
        setLoading(false);
        toast.error(error || '');
      }
    );
  };

  return (
    <Fragment>
      {isTokenExpired && <TokenExpired />}
      {isLoading && <SpinnerComponent />}
      {!isTokenExpired && !isLoading && (
        <div className="full-bg-color">
          <div className="container">
            <div className="landing-ui">
              <div className="forgot-password">
                <form className="w-100">
                  <div className="w-350">
                    <div className="login-title">
                      <PageLogo />
                      <h1>Reset Password</h1>
                    </div>
                    <div className="row">
                      <InputElement
                        className="col-sm-12"
                        type="password"
                        name="password"
                        value={resetPasswordState.password || ''}
                        onChange={handleChange}
                        placeholder="Please enter password"
                        label={'Password*'}
                      />
                      <InputElement
                        className="col-sm-12"
                        type="password"
                        name="password_confirmation"
                        value={resetPasswordState.password_confirmation || ''}
                        onChange={handleChange}
                        msg={'Password and Confirm Password not matched'}
                        isValid={isValidConfirmPassword(resetPasswordState)}
                        placeholder="Please enter confirm password"
                        label={'Confirm Password*'}
                      />
                      <div className="col-sm-12">
                        <p className="font-12 gray666">
                          8 Characters Minimum, 1 Upper Case Letter, 1 Number, 1 Special Character
                        </p>
                      </div>
                      <div className="col-sm-12 mt-20">
                        <Button
                          disabled={!validatePassword(resetPasswordState)}
                          className="w-100 mb-20"
                          color="primary"
                          onClick={handleClick}
                        >
                          Reset Password
                        </Button>
                      </div>
                      <ToastContainer autoClose={4000} hideProgressBar transition={Slide} />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default ResetPassword;
