import moment from 'moment';
import { ApplicationConstant, RouteConstants } from '../constants/application.constant';
import StorageConstant from '../constants/StorageConstant';
import { convertUtcToLocalTime } from './TimezoneUtils';

export const getDatebyYear = (year) => {
  const date = new Date();
  let month = date.getMonth() + ApplicationConstant.ONE;
  let day = date.getDate();
  if (month < ApplicationConstant.TEN) {
    month = `0${month}`;
  }
  if (day < ApplicationConstant.TEN) {
    day = `0${day}`;
  }
  return `${date.getFullYear() - year}-${month}-${day}`;
};

export const isAuthorized = () => {
  const token = localStorage.getItem(StorageConstant.TOKEN);
  if (token) {
    return true;
  }
  return false;
};

export const dashboardRedirection = () => {
  const roleId = parseInt(localStorage.getItem(StorageConstant.ROLE_ID));
  switch (roleId) {
    case ApplicationConstant.ONE:
      return RouteConstants.DASHBOARD_PATIENT;

    case ApplicationConstant.TWO:
      return RouteConstants.DASHBOARD_DOCTOR;

    case ApplicationConstant.THREE:
      return RouteConstants.DASHBOARD_HOSPITAL;
    default:
      return RouteConstants.LOGIN;
  }
};

export const getDateFormat = (date) => {
  const dateString = new Date(date);
  return dateString.toDateString();
};

export const boolenDataFilter = (data) => {
  if (data === 'Y') {
    return 'Yes';
  }
  return 'No';
};

export const getFormattedDate = (d) => {
  const date = new Date(d);
  let month = date.getMonth() + ApplicationConstant.ONE;
  let day = date.getDate();
  if (month < ApplicationConstant.TEN) {
    month = `0${month}`;
  }
  if (day < ApplicationConstant.TEN) {
    day = `0${day}`;
  }
  return `${date.getFullYear()}-${month}-${day}`;
};

export const getDateIgnoreTimezone = (d) => {
  if (typeof d === "undefined") {
    return;
  }

  let date = new Date(d);
  let userTimezoneOffset = date.getTimezoneOffset() * 60000;

  return new Date(date.getTime() + userTimezoneOffset)
}

export const getMatchedPath = (routeKey, pathName) => {
  return routeKey.some(function (key) {
    if (key === pathName) {
      return true;
    }
    return false;
  });
};

export const getRange = (start, end, step = 1) => {
  const arr = [];
  if (start < end) {
    while (start <= end) {
      arr.push(start);
      start = start + step;
    }
  }
  return arr;
};

export const getNPIType = (typeId, NPITypes) => {
  if (typeId) {
    const npiTypes = JSON.parse(JSON.stringify(NPITypes));
    const oType = npiTypes.find((oNpiType) => oNpiType.id === Number(typeId));
    if (oType) {
      return oType.name || '';
    } else {
      return '-';
    }
  }
  return '-';
};

export const getFilenameFromUrl = (url) => {
  if(url && url.length>1){
    if(!(url.includes('https:') || url.includes('http:'))){
      return url;
    } else {
      let fileName = url.toString().match(/.*\/(.+?)\./);
      if (fileName && fileName.length > ApplicationConstant.ONE) {
        return fileName[ApplicationConstant.ONE];
      }
    }
  }
  return '';
};

export const isObjsEqual = (a, b) => {
  return JSON.stringify(a) === JSON.stringify(b);
};

export function generatePassword(){
  const timeStamp = Date.now();
  return `${ApplicationConstant.DEFAULT_PASSWORD}${timeStamp}`;
}

export const getSearchParama = (history, searchKey) => {
  const searchParams = new URLSearchParams(history?.location?.search).get(searchKey);
  return searchParams ? searchParams : '';
}

export const getPageParams = (history, searchKey) => {
  const searchParams = new URLSearchParams(history?.location?.search).get(searchKey);
  return searchParams ? parseInt(searchParams) : 1;
}

export const patientPreferredName = (firstName, lastName, isPreferred, preferredName) => {
  if (isPreferred) {
    return `${preferredName}`;
  } else {
    return `${firstName} ${' '} ${lastName}`
  }
}

export const getFormattedSlot = (startTime, endTime, fromFormat='HH:mm:ss', toFormat='hh:mm A') => {
  return `${moment(startTime, fromFormat).format(toFormat)}`;
}

export const setArrayRange = (start, end) =>{
  return Array(end - start + ApplicationConstant.ONE).fill().map((_, idx) => start + idx)
}

export const getAppointmentTypeName = (appointmentType) => {
  switch (appointmentType) {
    case ApplicationConstant.ONE:
      return 'Telehealth';
    case ApplicationConstant.TWO:
      return 'In Person';
    default:
      return '-';
  }
}

export const checkSlotvalidation = (slotTime, selectedDate) => {
  const dateFormat = 'YYYY-MM-DD HH:mm:ss';
  const selectedDateTime = moment(selectedDate).format(dateFormat);
  const slotTimes = moment(slotTime).format(dateFormat);
  const utcTimeSlot = convertUtcToLocalTime(slotTimes, dateFormat);
  return utcTimeSlot <= selectedDateTime ? true : false;
}

export const showHideZoomWindow = (id, display) => {
  return document.getElementById(id).style.display = display;
}

export const getUserInfo = () => {
  const userInfo = localStorage.getItem(StorageConstant.USER);
  return JSON.parse(userInfo);
};

export const setProfileImage = (imageURL) => {
  localStorage.setItem(StorageConstant.PROFILE_IMAGE, imageURL || '');
}

export const getProfileImage = () => {
  const profileImage = localStorage.getItem(StorageConstant.PROFILE_IMAGE);
  return profileImage ? profileImage : '';
}

export const downloadFileFromBlob = (blob,fileName) => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
}

export const getSpecialzationName = (specializations, id) =>{
  const value =  specializations.find(obj =>
     obj.specialization_id === id)
     if(value){
       return value.name
     }
     return specializations[ApplicationConstant.ZERO]?.name || ''

 }

export const linkActiveClass = (path, pagePath) => {
  return path === pagePath ? 'active' : ''
}
