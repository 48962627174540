import React from 'react';

const FormButton = (props) => {
  const { buttonLabel, buttonClassName, handleClick, disabled } = props;
  return (
    <div>
      <button className={buttonClassName} onClick={handleClick} disabled={disabled}>
        {buttonLabel}
      </button>
    </div>
  );
};

export default FormButton;
