import React from 'react';
import {
    preferredNameValidation,
    idNumberValidation,
    pastDateValidation,
    phoneValidation,
    phoneValidationApi} from '../../../../validators/CommonValidation';
import { ApplicationConstant, DateConstant } from '../../../../constants/application.constant';
import InputElement from '../../../common/formElements/InputElement';
import DateElement from '../../../common/formElements/DateElement';
import { FieldKey, ValidationText } from './AccountInformationUtils';

const BasicInformation = (props) => {
    const {profileData, setValueByFieldName, profileInitialData} = props;

    const handleNameChange = (e) => {
        e.preventDefault();
        const nameObj = preferredNameValidation(e.target.value, profileData[e.target.name]);
        setValueByFieldName(e.target.name, nameObj);
    }

    const handleUserNameChange = (e) => {
        e.preventDefault();
        const userNameObj = preferredNameValidation(e.target.value, profileData[e.target.name]);
        setValueByFieldName(e.target.name, userNameObj);
    };

    const handleTaxIdChange = (e) => {
        e.preventDefault();
        if (e.target.value.length <= ApplicationConstant.TWELVE) {
            idNumberValidation(e.target.name, e.target.value, setValueByFieldName, ValidationText.TAX_ID);
        }
    };

    const handleDateOfBusinessChange = (e) => {
        const dateObj = pastDateValidation(e);
        setValueByFieldName(FieldKey.DATE_OF_BUSINESS, dateObj);
    };

    const handlePhoneChange = (e) => {
        e.preventDefault();
        if (e.target.value.length <= ApplicationConstant.TEN) {
          phoneValidation(e.target.name, e.target.value, setValueByFieldName);
        }
    };

    const phoneNumberVerification = (e) => {
        e.preventDefault();
        if (e.target.value.length <= ApplicationConstant.TEN && e.target.value !== profileInitialData?.phone?.value) {
          phoneValidationApi(e.target.name, e.target.value, setValueByFieldName, profileData.phone.value);
        }
    };

    return (
        <div className="row">
            <InputElement
            className="col-sm-6"
            type="text"
            name="first_name"
            placeholder="Please Enter"
            label={'Healthcare Entity Name*'}
            onChange={handleNameChange}
            isValid={profileData?.first_name?.valid}
            value={profileData?.first_name?.value}
            msg={profileData?.first_name?.msg}
            />
            <InputElement
            className="col-sm-6"
            type="text"
            name="user_name"
            placeholder="Please Enter"
            label={'User Name*'}
            onChange={handleUserNameChange}
            isValid={profileData?.user_name?.valid}
            value={profileData?.user_name?.value}
            msg={profileData?.user_name?.msg}
            />
            <InputElement
            className="col-sm-6"
            type="text"
            name="tax_id"
            label={'Tax ID*'}
            placeholder={'Please Enter Tax ID'}
            onChange={handleTaxIdChange}
            isValid={profileData?.tax_id?.valid}
            value={profileData?.tax_id?.value}
            msg={profileData?.tax_id?.msg}
            />
            <DateElement
            className="col-sm-6"
            type="date"
            name="date_of_business"
            dateFormat="MMM d, yyyy"
            label={'Date of Business Creation*'}
            maxDate={new Date()}
            minDate={new Date(DateConstant.START_DATE)}
            onKeyPress = {(e)=>{e.preventDefault()}}
            onKeyDown = {(e)=>{e.preventDefault()}}
            onKeyUp = {(e)=>{e.preventDefault()}}
            onChange={handleDateOfBusinessChange}
            isValid={profileData?.date_of_business?.valid}
            value={profileData?.date_of_business?.value}
            msg={profileData?.date_of_business?.msg}
            />
            <InputElement
            className="col-sm-6 read-only-field"
            type="email"
            name="email"
            label={'Point of Contact - Email Address*'}
            placeholder={'Please Enter'}
            value={profileData?.email?.value}
            readOnly
            />
            <InputElement
            className="col-sm-6"
            type="number"
            name="phone"
            label={'Point of Contact - Phone Number*'}
            placeholder={'Please Enter 10 digit number'}
            onChange={handlePhoneChange}
            onBlur={phoneNumberVerification}
            isValid={profileData?.phone?.valid}
            value={profileData?.phone?.value}
            msg={profileData?.phone?.msg}
            />
        </div>
    )
}
export default BasicInformation;
