const PatientSignUpInitialState = {
  patient_details: {
    current_step: 1,
    role_id: '',
    user_Name: '',
    password: '',
    confirm_password: '',
    first_name: '',
    last_name: '',
    preferred_name: '',
    date_of_birth: '',
    email: '',
    phone: '',
    is_disply_preferred_name: 0,
    location: {
      address1: '',
      address2: '',
      state: '',
      city: '',
      zip_code: '',
    },
  },
};

export default PatientSignUpInitialState;
