import { useState } from 'react';
import { Modal, ModalBody, Button, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { HttpStatusCode, RouteConstants } from '../../constants/application.constant';
import { cancelSubscriptionPlan } from './SubscriptionPaymentUtils';

const SubscriptionCancellation = (props) => {
    const { isOpen,
        setModelOpen,
        modalClassName,
        title,
        subTitle,
        subscriptionId,
        primaryButtonText,
        secondaryButtonText,
        updateMembersip,
        linkText
    } = props;
    const [modalLoader, setModalLoader] = useState(false);

    const cancelSubscription = async () => {
        setModalLoader(true);
        await cancelSubscriptionPlan(subscriptionId).then((response) => {
            if(response){
                response.status === HttpStatusCode.SUCCESS_REQUEST && updateMembersip();
                setModelOpen();
                toast.success(response?.data?.message || '');
            }
        }).catch((error) => {
            toast.error(error || '');
        });
        setModalLoader(false);
    }

  return (
      <Modal isOpen={isOpen} modalClassName={modalClassName}>
        <ModalBody>
            <h1>{title}</h1>
            <p>{subTitle}</p>
            <div className="subscription-actions cancellation-subscription-modal-footer">
                <Button
                    color="secondary"
                    disabled={modalLoader}
                    onClick={cancelSubscription}>{modalLoader ? <Spinner /> : primaryButtonText}
                </Button>
                <Button
                    color="primary"
                    onClick={setModelOpen}>{secondaryButtonText}
                </Button>
                <span className="link-wrapper">
                    <Link to={RouteConstants.PRIVACY_POLICY}
                        className="w-100 text-decoration theme-color"
                        color="secondary">{linkText}
                    </Link>
                </span>
            </div>
        </ModalBody>
    </Modal>
  );
};

export default SubscriptionCancellation;
