import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { ToastContainer, toast, Slide } from 'react-toastify';
import { PageTitles, ApplicationConstant } from '../../../../../constants/application.constant';
import useDocumentTitle from '../../../../../utils/DocumentTitle';
import CompletedAppointmentItem from './CompletedAppointmentItem';
import ApiConstant from '../../../../../constants/ApiConstant';
import axiosInstance from '../../../../../axios/axiosInstance';
import { getPageParams } from '../../../../../utils/helper';
import SpinnerComponent from '../../../../common/spinner/SpinnerComponent';
import { EMPTY_ICON } from '../../../../../constants/image';
import EmptyStateComponent from '../../../../common/emptyStateComponent/EmptyStateComponent';

const CompletedAppointments = () => {
  useDocumentTitle(PageTitles.COMPLETED_APPOINTMENT);
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(getPageParams(history, 'page'));
  const [completedAppointments, setCompletedAppointments] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [listInitialPage, setListInitialPage] = useState(getPageParams(history, 'page') - 1);

  const fetchCompletedAppointmentList = async () => {
    setLoading(true);
    await axiosInstance
      .get(
        `${ApiConstant.DOCTOR_COMPLETED_APPOINTMENT}?page=${currentPage}&limit=${ApplicationConstant.TEN}`
      )
      .then((res) => {
        if (res && res.data) {
          const completedAppointmentList = res.data?.data?.data || [];
          const totalPageCount = res.data?.data?.last_page || '';
          setCompletedAppointments(completedAppointmentList);
          setPageCount(totalPageCount);
        }
      })
      .catch((error) => {
        toast.error(error || '');
      });
    setLoading(false);
  };

  const changePagination = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage + ApplicationConstant.ONE);
    setListInitialPage(selectedPage);
  };

  useEffect(() => {
    fetchCompletedAppointmentList();
  }, [currentPage]);

  return (
    <div className='middle-content edit-profile user-right-container'>
      <div className='pages'>
        <div className='profile-information'>
          <div className='form-step max-w-100'>
            <div className='history-and-records doctor-appointment-list'>
              <div className='profile-info'>
                <div className='box'>
                  {isLoading ? (
                    <SpinnerComponent />
                  ) : (
                    <>
                      {completedAppointments?.length ? (
                        completedAppointments.map((item, i) => {
                          return <CompletedAppointmentItem item={item} key={i} />;
                        })
                      ) : (
                        <>
                          {!isLoading && (
                            <EmptyStateComponent
                              imageSrc={EMPTY_ICON}
                              title='No Completed Appointments.'
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className='pagination-wrapper' hidden={pageCount === ApplicationConstant.ONE}>
                  <ReactPaginate
                    previousLabel={'previous'}
                    nextLabel={'next'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pageCount}
                    marginPagesDisplayed={4}
                    onPageChange={changePagination}
                    pageRangeDisplayed={1}
                    forcePage={listInitialPage}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                    nextLinkClassName={'next-btn'}
                    previousLinkClassName={'prev-btn'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={4000} hideProgressBar transition={Slide} />
    </div>
  );
};

export default CompletedAppointments;
