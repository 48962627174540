import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Button } from 'reactstrap';
import axiosInstance from '../../../axios/axiosInstance';
import PageLogo from '../../common/logo/PageLogo';
import SpinnerComponent from '../../common/spinner/SpinnerComponent';
import { PageTitles, RouteConstants } from '../../../constants/application.constant';
import useDocumentTitle from '../../../utils/DocumentTitle';
import SummaryHook from './PatientSummaryHook';
import ApiConstant from '../../../constants/ApiConstant';
import SummaryMultiValues from '../SummaryMultiValues';
import SummarySingleValue from '../SummarySingleValue';
import ProfileCompleteMessage from '../ProfileCompleteMessage';
import SummaryBioInfo from '../SummaryBioInfo';
import SummaryPreference from './SummaryPreference';

function PatientSummary(props) {
  const { isUserProfile, handleEditSummary, savedProfileData, isProfileDateSaved } = props;
  const history = useHistory();
  const { updateProfile } = SummaryHook(onCallback);
  const [isLoading, setLoading] = useState(true);
  const [oUserProfile, setUserProfile] = useState(null);
  const [oPreference, setPreference] = useState(null);
  const [isProfileCompleted, setProfileCompleted] = useState(false);
  useDocumentTitle(PageTitles.PATIENT_SUMMARY);

  const handleClick = (e) => {
    e.preventDefault();
    history.replace(RouteConstants.DASHBOARD_PATIENT);
  };

  useEffect(() => {
    axiosInstance.get(`${ApiConstant.PATIENT_PROFILE}`).then(
      (res) => {
        if (res && res.data) {
          const oProfile = res.data.data || {};
          updateProfile(oProfile);
          setLoading(false);
        }
      },
      (_error) => {
        setLoading(false);
      }
    );
  }, []);

  useEffect(() => {
    isProfileDateSaved && updateProfile(savedProfileData);
  }, [isProfileDateSaved]);

  function onCallback(oProfile, isProfileCompleted) {
    if (oProfile) {
      setUserProfile(oProfile);
      setProfileCompleted(isProfileCompleted);
      setPreference(oProfile?.patient_preferences);
    }
  }

  const handleEditClick = (e) => {
    e.preventDefault();
    const selecteStep = e.target.dataset.step;
    if (isUserProfile) {
      handleEditSummary(selecteStep);
    } else {
      history.push({
        pathname: RouteConstants.REGISTRATION_PATIENT,
        state: { step: selecteStep },
      });
    }
  };

  return (
    <>
      {isLoading ? (
        <SpinnerComponent />
      ) : (
        <div className="step-page">
          <div className="middle">
            <div className="container">
              {!isUserProfile ? <PageLogo /> : ""}
              <div className="form-step summary">
                <form className="w-100">
                  {!isUserProfile ? (
                    <div className="emal-sent mt-50">
                      <div className="check-box mb-30">
                        <span className="material-icons mt-5">done</span>
                      </div>
                      <div className="mb-30">
                        <h1>Thank you, your profile has been created. </h1>
                        <ProfileCompleteMessage
                          isCompleted={isProfileCompleted}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="detail">
                    <div className="number-heading">1. Pronouns</div>
                    <div className="content">
                      <SummaryMultiValues
                        aData={oUserProfile.user_pronouns}
                        keyValue={"title"}
                      />
                      <a className="btn-white-outline pr-7 pl-7 d-flex align-items-center ml-8">
                        <span
                          className="material-icons md-dark md-18 mt-0"
                          data-step="1"
                          onClick={handleEditClick}>
                          edit
                        </span>
                      </a>
                    </div>
                  </div>
                  <div className="detail">
                    <div className="number-heading">
                      2. Biometric Information
                    </div>
                    <div className="content">
                      <SummaryBioInfo oProfile={oUserProfile} />
                      <a className="btn-white-outline pr-7 pl-7 d-flex align-items-center ml-8">
                        <span
                          className="material-icons md-dark md-18 mt-0"
                          data-step="2"
                          onClick={handleEditClick}>
                          edit
                        </span>
                      </a>
                    </div>
                  </div>
                  <div className="detail">
                    <div className="number-heading">3. Race</div>
                    <div className="content">
                      <SummaryMultiValues
                        aData={oUserProfile.user_races}
                        keyValue={"name"}
                      />
                      <a className="btn-white-outline pr-7 pl-7 d-flex align-items-center ml-8">
                        <span
                          className="material-icons md-dark md-18 mt-0"
                          data-step="3"
                          onClick={handleEditClick}>
                          edit
                        </span>
                      </a>
                    </div>
                  </div>
                  <div className="detail">
                    <div className="number-heading">4. Ethnicity</div>
                    <div className="content">
                      <SummarySingleValue
                        oData={oUserProfile?.ethnicity}
                        keyValue={"name"}
                      />
                      <a className="btn-white-outline pr-7 pl-7 d-flex align-items-center ml-8">
                        <span
                          className="material-icons md-dark md-18 mt-0"
                          data-step="4"
                          onClick={handleEditClick}>
                          edit
                        </span>
                      </a>
                    </div>
                  </div>
                  <div className="detail">
                    <div className="number-heading">5. Languages Spoken</div>
                    <div className="content">
                      <SummaryMultiValues
                        aData={oUserProfile.user_languages}
                        keyValue={"name"}
                      />
                      <a className="btn-white-outline pr-7 pl-7 d-flex align-items-center ml-8">
                        <span
                          className="material-icons md-dark md-18 mt-0"
                          data-step="5"
                          onClick={handleEditClick}>
                          edit
                        </span>
                      </a>
                    </div>
                  </div>
                  <div className="detail">
                    <div className="number-heading">
                      6. Gender Identity
                    </div>
                    <div className="content">
                      <SummaryMultiValues
                        aData={oUserProfile.user_identifications}
                        keyValue={"title"}
                      />
                      <a className="btn-white-outline pr-7 pl-7 d-flex align-items-center ml-8">
                        <span
                          className="material-icons md-dark md-18 mt-0"
                          data-step="6"
                          onClick={handleEditClick}>
                          edit
                        </span>
                      </a>
                    </div>
                  </div>
                  <div className="detail">
                    <div className="number-heading">7. Sexual Orientation</div>
                    <div className="content">
                      <SummaryMultiValues
                        aData={oUserProfile.user_sexual_orientations}
                        keyValue={"name"}
                      />
                      <a className="btn-white-outline pr-7 pl-7 d-flex align-items-center ml-8">
                        <span
                          className="material-icons md-dark md-18 mt-0"
                          data-step="7"
                          onClick={handleEditClick}>
                          edit
                        </span>
                      </a>
                    </div>
                  </div>
                  <div className="detail">
                    <div className="number-heading">
                      8. How can equalityMD support you living your healthiest
                      and most authentic life?
                    </div>
                    <div className="content">
                      {oUserProfile.notes ? <p>{oUserProfile.notes}</p> : "-"}
                      <a className="btn-white-outline pr-7 pl-7 d-flex align-items-center ml-8">
                        <span
                          className="material-icons md-dark md-18 mt-0"
                          data-step="8"
                          onClick={handleEditClick}>
                          edit
                        </span>
                      </a>
                    </div>
                  </div>
                  <div className="detail border-0" hidden={isUserProfile}>
                    <div className="content">
                      <div className="number-heading d-flex justify-content-between">
                        9. User Preferences
                      </div>
                      <a className="btn-white-outline pr-7 pl-7 d-flex align-items-center ml-8 mt-0">
                        <span
                          className="material-icons md-dark md-18 mt-0"
                          data-step="9"
                          onClick={handleEditClick}>
                          edit
                        </span>
                      </a>
                    </div>
                    <div className="content p-0">
                      <SummaryPreference oPatientPreference={oPreference} />
                    </div>
                  </div>
                  {!isUserProfile ? (
                    <div className="text-center btn-group mt-30">
                      <Button
                        className="w-100 mb-20"
                        color="primary"
                        onClick={handleClick}>
                        Go to Dashboard
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PatientSummary;
