import React from 'react';
import { Link } from 'react-router-dom';
import { RouteConstants } from '../../../constants/application.constant';

function LoginFooter() {
    return (
        <div className="login-footer">
          <div className="container d-flex justify-content-between">
            <p>Copyright © 2021 equalityMD. All right reserved.</p>
            <ul>
              <li>
              <Link to={RouteConstants.PRIVACY_POLICY}>Privacy Policy</Link>
              </li>
              <li>
                <Link to={RouteConstants.TERMS_SERVICE}>Terms of Service</Link>
              </li>
            </ul>
          </div>
        </div>
    )
}

export default LoginFooter;
