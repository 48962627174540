import React from 'react';
import { TabContent, TabPane } from 'reactstrap';
import { SessionDocumentTabTitle } from '../../../../../../constants/application.constant';
import CompletedAppointmentDocument from './CompletedAppointmentDocument';
import CompletedAppointmentNotes from './CompletedAppointmentNotes';

function TabBody(props) {
    const { activeTab, activeCollapse, downloadNotes, patientNotes, noteLoader, item, viewHistoryOpen } = props;

    return (
    <TabContent activeTab={activeTab}>
      <TabPane tabId={SessionDocumentTabTitle.NOTES}>
        <CompletedAppointmentNotes
        item={item}
        noteLoader={noteLoader}
        patientNotes={patientNotes}
        downloadNotes={downloadNotes}
        activeCollapse={activeCollapse}
        viewHistoryOpen={viewHistoryOpen}
        />
      </TabPane>
      <TabPane tabId={SessionDocumentTabTitle.DOCUMENTS}>
          <CompletedAppointmentDocument
            appointmentId={item?.appointment_id}
            userId={item?.user_id}
          />
      </TabPane>
    </TabContent>
    )
}

export default TabBody;

