import React from 'react';
import UserRegistrationProvider from '../contexts/user-registration/UserRegistrationProvider';
import PatientHealthProfileContainerComponent from '../components/user-registration/patient/PatientProfileContainer';

export default function PatientRegistrationPage() {
  return (
    <UserRegistrationProvider>
      <PatientHealthProfileContainerComponent/>
    </UserRegistrationProvider>
  );
}
