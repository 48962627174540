
import React, { useEffect, useContext, useState } from 'react';
import { ToastContainer, toast, Slide } from 'react-toastify';
import DoctorInfo from './doctorInfo/DoctorInfo';
import axiosInstance from '../../../../axios/axiosInstance';
import ApiConstant from '../../../../constants/ApiConstant';
import SpinnerComponent from '../../../common/spinner/SpinnerComponent';
import Filter from './filter/Filter';
import TimeSlot from './timeSlot/TimeSlot';
import DoctorSchedulerContext from '../../../../contexts/doctor-scheduler/DoctorSchedulerContext';
import { scheduleAvailabilityPayload } from './ScheduleAvailabilityUtils';
import { UserRole } from '../../../../constants/application.constant';
import Subscription from '../../../subscription/Subscription';

const ScheduleAvailability = () => {
  const [workingdDaysList, setWorkingdDaysList] = useState([]);
  const [availabilityData] = useContext(DoctorSchedulerContext);
  const [doctorProfile, setDoctorProfile] = useState(null);
  const [filterSettings, setFilterSettings] = useState({});
  const [loader, setLoader] = useState(true);
  const [scheduleType, setScheduleType] = useState(null)
  const [subscriptionModal, setSubscriptionModal] = useState(false);

  const toggleSubscriptionModal = () =>{
    setSubscriptionModal(!subscriptionModal);
  }

  const getDisplayWorkingdDays = (days) =>{
    setWorkingdDaysList(days);
  }

  const fetchDoctorScheduleAvailability = async () => {
      await axiosInstance.get(`${ApiConstant.DOCTOR_SCHEDULE_AVAILABILITY}`).then((response) => {
          if (response && response.data && response.data.data) {
              const doctorScheduleAvailability = response.data.data.provider || {};
              const scheduleFilters = response.data.data.settings || {};
              const isMembershipActivated = response.data.data?.is_member;
              setFilterSettings(scheduleFilters);
              setDoctorProfile(doctorScheduleAvailability);
              !isMembershipActivated && toggleSubscriptionModal();
          }
      })
      .catch((error) => {
          toast.error(error || '');
      });
      setLoader(false);
  };

  const setDoctorAvailability = async (fetchUpdateSlots) => {
    await axiosInstance.post(ApiConstant.CREATE_SCHEDULE_AVAILABILITY, scheduleAvailabilityPayload(availabilityData)).then(
      (response) => {
        toast.success(response?.data?.message || '');
        fetchUpdateSlots();
      })
      .catch((error) => {
        toast.error(error || '');
      });
  }

  useEffect(()=> {
      fetchDoctorScheduleAvailability();
  },[])

  return (
      <>
      {loader ? <SpinnerComponent /> : (
        <div className="middle-content view-profile">
            <div className="container">
                <div className="row">
                  <div className="col-sm-12">
                    <DoctorInfo
                      userProfile={doctorProfile}
                      filterSettings={filterSettings}
                      />
                    <form className="select-schedule-slot">
                      <Filter
                        displayWorkingDays={getDisplayWorkingdDays}
                        filterSettings={filterSettings}
                        changeScheduleType={setScheduleType}
                      />
                      <TimeSlot
                        setSlots={setDoctorAvailability}
                        workingdDaysList={workingdDaysList}
                        scheduleType={scheduleType}
                        filterSettings={filterSettings}
                      />
                    </form>
                  </div>
                </div>
            </div>
        </div>
        )}
        <ToastContainer autoClose={4000} hideProgressBar transition={Slide} />
        {subscriptionModal &&
          <Subscription
            isOpen={subscriptionModal}
            setModelOpen={toggleSubscriptionModal}
            modalClassName="subscription-plan"
            discountAmount="50"
            title="Memberships!"
            subTitle="Become an equalityMD member! It is required that all  providers become members before scheduling availability"
            subscriptionPlanValidity="Monthly"
            primaryButtonText="Sign up!"
            secondaryButtonText="Not Today"
            isAccessRestrictions={true}
            subscriptionRole={UserRole.DOCTOR}
          />
        }
      </>
  );
};

export default ScheduleAvailability;
