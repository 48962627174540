import React, { useState } from 'react';
import { convertUtcToLocalTime } from '../../../../../../../utils/TimezoneUtils';

const HistoryListItem = (props) => {
  const { item, onSelectListRow, onDownloadDocument } = props;
  const [activeCollapse, setActiveCollapse] = useState(false);

  const toggleListDetail = () => {
    if(item?.status === 'Completed'){
      setActiveCollapse(!activeCollapse);
      onSelectListRow(item?.id)
    }
  }
  return (
      <>
      <div className={`divRow ${item?.status === 'Completed' ? 'completed' : ''}`} onClick={toggleListDetail}>
        <div className="table-toggle">
          <span className='material-icons-outlined'>
            {activeCollapse ? "expand_less" : "expand_more"}
          </span>
        </div>
        <div
          className="table-col table-column-40 provider-col"
          title={`${item?.provider?.first_name || ''} ${''} ${item?.provider?.last_name || ''}`}
          data-label="Provider Name"
        >
          <span>{item?.provider?.first_name || ''} {''} {item?.provider?.last_name || ''}</span>
        </div>
        <div
          className="table-col table-column-40"
          title={convertUtcToLocalTime(item?.start_date_time, "lll HH:mm")}
          data-label="Appointment Data"
        >
          <span>{convertUtcToLocalTime(item?.start_date_time, "lll")}</span>
        </div>
        <div
          className="table-col table-column-20 pr-0"
          title={item?.status}
          data-label="Status"
        >
          <span>{item?.status}</span>
        </div>
    </div>
    {activeCollapse &&
      <div className="profile-info">
        <div className="profile-info-col">
          <label>Duration</label>
          <div className="value">{item?.duration || '-'}</div>
        </div>
        <div className="profile-info-col document">
          <label>Documents</label>
          <div className="value d-flex align-items-center">
            {item?.documents?.length ? (
              item?.documents.map((ele, index) => {
                return (
                  <div key={index} className="download-doc-row">
                    <span className="doc-link" onClick={()=>{onDownloadDocument(ele?.id, ele?.document_name)}}>
                      <span className="material-icons-sharp mr-10">download</span>
                      {ele.document_name || '-'}
                    </span>
                  </div>
                )
              })
            ): '-'}
          </div>
        </div>
        <div className="profile-info-col notes">
          <label>Notes</label>
          <div className="value mb-0">{item?.notes || '-'}</div>
        </div>
      </div>
      }
    </>
  )
}

export default HistoryListItem;
