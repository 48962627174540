import React, { useEffect, useState } from 'react';
import { Button } from "reactstrap";
import axiosInstance from '../../../axios/axiosInstance';
import ApiConstant from '../../../constants/ApiConstant';
import HospitalProviderItem from './HospitalProviderItem';
import { EMPTY_ICON } from './../../../constants/image';
import EmptyStateComponent from '../../common/emptyStateComponent/EmptyStateComponent';
import { Link, useHistory } from 'react-router-dom';
import { ApplicationConstant, RouteConstants } from '../../../constants/application.constant';
import { getPageParams } from '../../../utils/helper';
import ReactPaginate from 'react-paginate';
import SpinnerComponent from '../../common/spinner/SpinnerComponent';

function HospitalProviders(props) {
  const { oHospitalProfile, limit = ApplicationConstant.TEN, isViewAllClicked = ApplicationConstant.FALSE} = props;
  const history = useHistory();
  const [aProviders, setProviders] = useState([]);
  const [itemCount, setItemCount] = useState(ApplicationConstant.ZERO);
  const [loader, setLoader] = useState(false);
  const params = new URLSearchParams();
  const [listInitialPage, setListInitialPage] = useState(getPageParams(history, 'page') - 1);
  const [pageNum, setPageNum] = useState(listInitialPage+1);
  const [lastPage, setLastPage] = useState(ApplicationConstant.ZERO);

  const fetchHospitalDoctorList = async (pageNum) => {
    setLoader(ApplicationConstant.TRUE);
    await axiosInstance
      .get(`${ApiConstant.HOSPITAL_PROVIDERS}?page=${pageNum}&limit=${limit}`)
      .then((response) => {
        if (response && response?.data && response?.data?.data) {
          const hospitalDoctorList = response?.data?.data?.data || {};
          const totalitems = response?.data?.data?.total;
          setProviders(hospitalDoctorList);
          setItemCount(totalitems);
          setLastPage(Math.ceil(totalitems / limit));
        }
      });
    setLoader(ApplicationConstant.FALSE);
  };

  useEffect(() => {
    fetchHospitalDoctorList(pageNum);
  }, [pageNum]);


  const routeChange = (e) =>{ 
    e.preventDefault()  
    history.push({pathname: RouteConstants.CSV_UPLOAD,});
  }

  const registerDoctor = (e)=>{
    e.preventDefault()
    history.push({pathname:RouteConstants.ADD_DOCTOR, state: { user_id: oHospitalProfile?.user_id}});
  }

  const setPageParams = (pageRange) => {
    if (pageRange) {
      params.set('page', pageRange);
    }
    history.replace({search: params.toString()});
  }

  const changePagination = (data) => {
    const selectedPage = data.selected;
    setPageNum(selectedPage + ApplicationConstant.ONE);
    setListInitialPage(selectedPage);
    setPageParams(selectedPage + ApplicationConstant.ONE);
  };

  return (
    <>
    {loader ? <SpinnerComponent /> : (
    <section className="providers-section">
      <div className="heading-link">
        <div className = "d-flex align-items-center">
        {!aProviders?.length && <h5 >{'Add Providers'}</h5>}
        {aProviders?.length>0 && !isViewAllClicked && <h5 className="mb-0 mr-16">{'Our Providers'}</h5>}
        </div> 
        <div>
          <Button className="medium mr-15 small" color="secondary" onClick={routeChange}>
            <div className="d-flex align-items-center justify-content-center">
            <strong class="material-icons-sharp mr-15">
              upload
            </strong>
              UPLOAD FILE
            </div>
            </Button>
            <Button className="medium mr-15 small" color="primary" onClick={registerDoctor}>
              <div className="d-flex align-items-center justify-content-center ">
                <strong class="material-icons-outlined mr-15">
                  person_add
                </strong>
                  Add INDIVIDUAL
              </div>
            </Button>
        </div>
      </div>
      {aProviders?.length ? (
        <div className="table-section">
          <div className="box">
          <div className="mobile-checkbox d-none">
              <div className="check-btn d-flex m-0 ">       
                <label className="label--checkbox flat d-flex">
                  <input type="checkbox" className="checkbox" id="1" />
                  <label className="font-16 font-500" htmlFor="1"></label>
                </label>
                <span className="mt-15">Select All</span>
              </div>
            </div>
          <div class="divTable">
            <div class="headRow">
                <div class="divCell d-none">
                  <div className="check-btn m-0 ">
                    <label className="label--checkbox flat d-flex">
                      <input type="checkbox" className="checkbox" id="1" />
                      <label className="font-16 font-500" htmlFor="1"></label>
                    </label>
                  </div>
                </div>
                <div  class="divCell"> 
                  <strong>First Name </strong>
                  <span className="material-icons">unfold_more</span>
                </div>
                <div  class="divCell">
                  <strong>Last Name </strong>
                  <span className="material-icons">unfold_more</span>
                </div>
                <div  class="divCell">
                  <strong>License </strong>
                  <span className="material-icons">unfold_more</span>
                </div>
                <div  class="divCell">
                  <strong>NPI Number </strong>
                  <span className="material-icons">unfold_more</span>
                </div>
                <div  class="divCell">
                  <strong>State </strong>
                  <span className="material-icons">unfold_more</span>
                </div>
                <div  class="divCell">
                  <strong>Email Address </strong>
                  <span className="material-icons">unfold_more</span>
                </div>
                <div  class="divCell">
                  <strong>Phone Number </strong>
                  <span className="material-icons">unfold_more</span>
                </div>
                <div  class="divCell">
                  <strong>School </strong>
                  <span className="material-icons">unfold_more</span>
                </div>
                <div  class="divCell">
                  <strong>Status </strong>
                  <span className="material-icons">unfold_more</span>
                </div>
            </div>           
              {aProviders.map((item, index) => {
                return <HospitalProviderItem key={index} oProvider={item} />;
              })}
          </div>
          </div>
        </div>
      ) : (
        <EmptyStateComponent
          imageSrc={EMPTY_ICON}
          title="No provider found."
          subtitle="Click on the button below to add a provider."
          buttonText="add a provider"
          handleClick={registerDoctor}
        />
      )}
       {isViewAllClicked && (aProviders?.length > ApplicationConstant.ZERO) && (
        <div className='pagination-wrapper' hidden={lastPage === ApplicationConstant.ONE}>
          <ReactPaginate
            previousLabel={'previous'}
            nextLabel={'next'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={lastPage}
            marginPagesDisplayed={ApplicationConstant.FOUR}
            onPageChange={changePagination}
            pageRangeDisplayed={1}
            forcePage={pageNum - ApplicationConstant.ONE}
            containerClassName={'pagination'}
            activeClassName={'active'}
            nextLinkClassName={'next-btn'}
            previousLinkClassName={'prev-btn'}
          />
        </div>
      )}
      {!isViewAllClicked && (aProviders?.length > ApplicationConstant.ZERO) && itemCount > ApplicationConstant.THREE && (
        <div className='pagination-wrapper'>
          <Link to={RouteConstants.VIEW_ALL_OUR_DOCTOR}>
            <span className={'theme-color hover-none'}>VIEW ALL</span>
          </Link>
        </div>
        )
      }
    </section>
    )}
    </>
  );
}

export default HospitalProviders;


