import React from 'react';
import { ApplicationConstant } from '../../../constants/application.constant';

function SummaryPreference(props) {
  const { oPatientPreference } = props;
  return (
    <div className="w-100">
      <div className="detail">
        <div className="number-heading">What gender do you prefer your provider to be?</div>
        <div className="content">
          <ul className="value-list">
            <li>
              <span>
                {ApplicationConstant.FIRST_PREFERENCE} {oPatientPreference?.first_gender_name || '-'}
              </span>
            </li>
            <li>
              <span>
                {ApplicationConstant.SECOND_PREFERENCE} {oPatientPreference?.second_gender_name || '-'}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="detail">
        <div className="number-heading">What age do you prefer your provider to be?</div>
        <div className="content">
          <ul className="value-list">
            <li>
              <span>
                {ApplicationConstant.FIRST_PREFERENCE} {oPatientPreference?.first_age_range_value || '-'}
              </span>
            </li>
            <li>
              <span>
                {ApplicationConstant.SECOND_PREFERENCE} {oPatientPreference?.second_age_range_value || '-'}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="detail">
        <div className="number-heading">What race do you prefer your provider to be?</div>
        <div className="content">
          <ul className="value-list">
            <li>
              <span>
                {ApplicationConstant.FIRST_PREFERENCE} {oPatientPreference?.first_race_name || '-'}
              </span>
            </li>
            <li>
              <span>
                {ApplicationConstant.SECOND_PREFERENCE} {oPatientPreference?.second_race_name || '-'}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="detail">
        <div className="number-heading">What ethnicity do you prefer your provider to be?</div>
        <div className="content">
          <ul className="value-list">
            <li>
              <span>
                {ApplicationConstant.FIRST_PREFERENCE} {oPatientPreference?.first_ethnicity_name || '-'}
              </span>
            </li>
            <li>
              <span>
                {ApplicationConstant.SECOND_PREFERENCE} {oPatientPreference?.second_ethnicity_name || '-'}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="detail">
        <div className="number-heading">What language do you prefer your provider to speak?</div>
        <div className="content">
          <ul className="value-list">
            <li>
              <span>
                {ApplicationConstant.FIRST_PREFERENCE} {oPatientPreference?.first_language_name || '-'}
              </span>
            </li>
            <li>
              <span>
                {ApplicationConstant.SECOND_PREFERENCE} {oPatientPreference?.second_language_name || '-'}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="detail">
        <div className="number-heading">What sexual orientation do you prefer your provider to be?</div>
        <div className="content">
          <ul className="value-list">
            <li>
              <span>
                {ApplicationConstant.FIRST_PREFERENCE} {oPatientPreference?.first_sexual_orientation_name || '-'}
              </span>
            </li>
            <li>
              <span>
                {ApplicationConstant.SECOND_PREFERENCE} {oPatientPreference?.second_sexual_orientation_name || '-'}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SummaryPreference;
