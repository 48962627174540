import React from 'react';
import { PROFILEIMG } from '../../../constants/image';
import { CommonFooter } from './../../common/footer/CommonFooter';
import { useHistory } from 'react-router';
const ViewProfile = () => {
    const history = useHistory();
    return (
        <>
        <div className="middle-content view-profile">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="back-link">
                  <a onClick={history.goBack} class="btn-white-outline pr-7 pl-7 d-flex align-items-center mr-15"><span class="material-icons md-dark md-24">arrow_back</span></a>
                  <h1>Health Profile</h1>
                </div>
                    <section className="detail-info">
                        <div className="profile-info">
                            <div className="row">
                                <div className="col-sm-12 col-md-12">
                                    <div className="box">

                                        <div className="user-info">
                                            <div className="profile-img active">
                                                <img src={PROFILEIMG}></img>
                                            </div>
                                            <div className="head">
                                                <div className="title-text mt-5">
                                                    <h1>Rodney Fuller</h1>
                                                    <h2>Pro Member <span>Available</span></h2>
                                                    <h3 >Engagement Score <span className="star-rating"> (star rating here!!!)</span></h3>
                                                </div>
                                                <div className="action-status">
                                                    <a class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8"><span class="material-icons md-dark md-18">mode_edit</span></a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="profile-info">
                                            <div className="row">
                                                <div className="col-md-4 col-sm-6">
                                                    <label>Pronoun</label>
                                                    <div className="value">He/Him/His</div>
                                                </div>
                                                <div className="col-md-4 col-sm-6">
                                                    <label>Weight* (lbs)</label>
                                                    <div className="value">176lb</div>
                                                </div>
                                                <div className="col-md-4 col-sm-6">
                                                    <label>Height (Feet & Inch)</label>
                                                    <div className="value">6’11”</div>
                                                </div>
                                                <div className="col-md-4 col-sm-6">
                                                    <label>Age</label>
                                                    <div className="value">48 years</div>
                                                </div>
                                                <div className="col-md-4 col-sm-6">
                                                    <label>Race</label>
                                                    <div className="value">Alaska Native</div>
                                                </div>
                                                <div className="col-md-4 col-sm-6">
                                                    <label>Ethnicity</label>
                                                    <div className="value">Non-Hispanic or Latino</div>
                                                </div>
                                                <div className="col-md-4 col-sm-6">
                                                    <label>Language Spoken</label>
                                                    <div className="value">American English, British English <span className="chip">2 More</span></div>
                                                </div>
                                                <div className="col-md-4 col-sm-6">
                                                    <label>Gender Identity</label>
                                                    <div className="value">Cigender man</div>
                                                </div>
                                                <div className="col-md-4 col-sm-6">
                                                    <label>Sexual Orientation</label>
                                                    <div className="value">Gay, Queer</div>
                                                </div>
                                            </div>
                                            <h4>Member Since: <span>Jan 21, 2020</span></h4>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </section>
                    <div className="row">
                      <div className="col-sm-12">
                        <section className="my-matched-section">
                            <div className="heading-link">
                                <h5>Reviews</h5>
                            </div>
                            <div className="card-box rating-section">
                                <div className="card-row">
                                    <div className="row">
                                        <div className="col-sm-12 profile-info">
                                            <div className="user-info">
                                                <div className="profile-img">
                                                    <img src={PROFILEIMG}></img>
                                                </div>
                                                <div className="head">
                                                    <div className="title-text mt-5">
                                                        <div className="d-flex justify-content-between align-items-start"><h1>Sam Fowler</h1><h2>14 hours ago</h2></div>
                                                        <h3 className="mt-0">Rating <span className="star-rating"> (star rating here!!!)</span></h3>
                                                        <p className="font-14 font-400 lineheight-24 mt-8">Etiam non ligula lectus. Morbi finibus elit id elementum posuere. </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-row">
                                    <div className="row">
                                        <div className="col-sm-12 profile-info">
                                            <div className="user-info">
                                                <div className="profile-img">
                                                    <img src={PROFILEIMG}></img>
                                                </div>
                                                <div className="head">
                                                    <div className="title-text mt-5">
                                                        <div className="d-flex justify-content-between align-items-start"><h1>Rosie Douglas</h1><h2>14 hours ago</h2></div>
                                                        <h3 className="mt-0">Rating <span className="star-rating"> (star rating here!!!)</span></h3>
                                                        <p className="font-14 font-400 lineheight-24 mt-8">Mauris finibus tellus non orci sagittis, maximus pharetra nibh aliquet. Cras eget suscipit magna. Duis magna justo. </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-row">
                                    <div className="row">
                                        <div className="col-sm-12 profile-info">
                                            <div className="user-info">
                                                <div className="profile-img">
                                                    <img src={PROFILEIMG}></img>
                                                </div>
                                                <div className="head">
                                                    <div className="title-text mt-5">
                                                        <div className="d-flex justify-content-between align-items-start"><h1>Kenneth Cross</h1><h2>14 hours ago</h2></div>
                                                        <h3 className="mt-0">Rating <span className="star-rating"> (star rating here!!!)</span></h3>
                                                        <p className="font-14 font-400 lineheight-24 mt-8">Cras vestibulum felis et sagittis dictum. Nam finibus, justo a euismod fringilla, vitae eleifend purus arcu ac mauris. </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>

            <CommonFooter />
        </>
    )
}

export default ViewProfile;
