import React, { useState, useContext } from 'react';
import update from 'immutability-helper';
import { Image } from '../../../common/image/Image';
import ReactFileUploader from '../../../common/fileUpload/ReactFileUploader';
import { BasePathConstant, ApplicationConstant } from '../../../../constants/application.constant';
import UserProfileContext from '../../../../contexts/user-profile/UserProfileContext';
import { FieldKey } from './AccountInformationUtils';

const ProfileImage = (props) => {
    const {profileData, setValueByFieldName, intialProfileData, updateProfileImage} = props;
    const [fileUploaded, isFileUploaded] = useState(false);
    const [userGenericData, setUserGenericData] = useContext(UserProfileContext);

    const onFileUploadSucess = (url) => {
        const updatedImagePayload = {...intialProfileData, profile_image: {value: url}};
        setValueByFieldName(FieldKey.PROFILE_IMAGE, {value: url});
        isFileUploaded(true);
        setUserGenericData(update(userGenericData, { [FieldKey.PROFILE_IMAGE] : { $set: url } }));
        updateProfileImage(updatedImagePayload);
      };

    const onFileRemove = () => {
        const updatedImagePayload = {...intialProfileData, profile_image: {value: ''}};
        setValueByFieldName(FieldKey.PROFILE_IMAGE, {value: ''});
        isFileUploaded(false);
        setUserGenericData(update(userGenericData, { [FieldKey.PROFILE_IMAGE] : { $set: ApplicationConstant.NO_VALUE_STRING } }));
        updateProfileImage(updatedImagePayload);
    };

    return (
        <div className="upload-profile-image w-100">
            <div className="profile-image">
                <Image src={profileData?.profile_image?.value || ''} alt="Profile image" />
            </div>
            {profileData?.profile_image.value  && !fileUploaded ? <span className="material-icons btn-close" onClick={onFileRemove}>close</span> : ''}
            <div className="upload-action">
                <h3 className="mb-10">Your Profile Picture</h3>
                <ReactFileUploader
                onSuccess={onFileUploadSucess}
                onRemove={onFileRemove}
                allowedExtensions={['jpg', 'jpeg', 'png']}
                allowedSize={10000000}
                allowedTypeMsg={'.jpg, .jpeg, and .png are allowed.'}
                basePath={BasePathConstant.HOSPITAL_PROFILE_IMAGE}
                dataLabel={profileData?.profile_image.value ? 'update' : 'upload'}
                profileMode={true}
                />
            </div>
        </div>
    )
}
export default ProfileImage;
