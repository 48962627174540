import { Button } from 'reactstrap';
import React from 'react';

const SignUpFooter = ({ handleSave, isEnabled }) => {
  return (
    <footer>
      <div className='container'>
        <div className='footer-btn'>
          <div className='prev'></div>
          <div className='next'>
            <Button
              color='primary'
              disabled={!!isEnabled ? false : true}
              onClick={() => {
                if (isEnabled) {
                  handleSave();
                }
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default SignUpFooter;
