import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm from './PaymentForm';



const Payment = (props) => {
  const { bookAppointemnt, userDetail, paymentPayload, stripePromise} = props;
  return (
    <Elements stripe={stripePromise}>
      <PaymentForm
        bookAppointemnt={bookAppointemnt}
        userDetail={userDetail}
        paymentPayload={paymentPayload}
      />
    </Elements>
  );
}

export default Payment;
