import React, { useState } from 'react'
import { SessionDocumentTabTitle } from '../../../../../../constants/application.constant';
import TabBody from './TabBody';
import PatientTabHead from './PatientTabHead';

function PatientAppointmentTabContainer(props) {
    const {activeCollapse,downloadNotes,patientNotes,noteLoader,item, viewHistoryOpen} = props
    const [activeTab, setActiveTab] = useState(SessionDocumentTabTitle.NOTES);
    const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
    return (
        <div className="tab-section">
        <PatientTabHead activeTab={activeTab} toggle={toggle} />
        <TabBody
          activeTab={activeTab}
          activeCollapse={activeCollapse}
          downloadNotes={downloadNotes}
          patientNotes={patientNotes}
          noteLoader={noteLoader}
          item={item}
          viewHistoryOpen={viewHistoryOpen}
        />
      </div>
    )
}

export default PatientAppointmentTabContainer;

