const DoctorSignUpInitialState = {
  doctor_profile: {
    current_step: 1,
    role_id: '',
    user_Name: '',
    password: '',
    confirm_password: '',
    first_name: '',
    last_name: '',
    preferred_name: '',
    date_of_birth: '',
    email: '',
    phone: '',
    npi_number: '',
    npi_type: '',
    license_number: '',
    expiration_date: '',
    document: '',
    prescription_availability: '',
    see_patients: '',
    own_insurance: '',
    accept_insurance: '',
    other: '',
    electronic_medical_record: '',
    medical_school: '',
    doctor_certifications: '',
    doctor_specializations: '',
    user_facilities: '',
    location: { zip_code: ''},
    state: '',
    insurance_provider:'',
    year_of_experience:'',
    practice:'',
    procedure:'',
    condition_treated:'',
    other_insurance_provider:'',
    other_certification:'',
    other_medical_school:'',
    doctor_background_questions: {},
  },
};

export default DoctorSignUpInitialState;
