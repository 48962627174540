import logo from '../assets/images/logo-lg.png';
import headerlogo from '../assets/images/headerlogo.svg';
import infoIconGrey from '../assets/images/info-icon-grey.svg';
import productimg from '../assets/images/product-img.png';
import profileimg from '../assets/images/profile-img.png';
import doctoricon from '../assets/images/doctor-icon.svg';
import patienticon from '../assets/images/patient-icon-orange.svg';
import hospitalicon from '../assets/images/hospital-icon.svg';
import avatar from '../assets/images/avatar.png';
import doctorEmptyIcon  from '../assets/images/doctor-empty-icon.svg';
import calendar  from '../assets/images/calendar.png';
import emptySearch from '../assets/images/empty-search-icon.svg';
import activityEmptyIcon from '../assets/images/empty-recent-activity.svg';
import dollarIcon from '../assets/images/dollar-icon.svg';

export const LOGO = logo;
export const HEADERLOGO = headerlogo;
export const INFOICONGREY = infoIconGrey;
export const PRODUCTIMG = productimg;
export const PROFILEIMG = profileimg;
export const DOCICON = doctoricon;
export const patientIcon = patienticon;
export const hospitalIcon = hospitalicon;
export const AVATAR_PLACEHOLDER = avatar;
export const EMPTY_ICON = doctorEmptyIcon;
export const Calendar = calendar;
export const EMPTY_SEARCH = emptySearch;
export const EMPTY_RECENT_ACTIVITY = activityEmptyIcon;
export const DOLLAR_ICON = dollarIcon;
