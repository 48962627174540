import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { ToastContainer, toast, Slide } from 'react-toastify';
import { PageTitles, ApplicationConstant } from '../../../../../constants/application.constant';
import axiosInstance from '../../../../../axios/axiosInstance';
import SpinnerComponent from '../../../../common/spinner/SpinnerComponent';
import ApiConstant from '../../../../../constants/ApiConstant';
import EmptyStateComponent from '../../../../common/emptyStateComponent/EmptyStateComponent';
import { EMPTY_ICON } from '../../../../../constants/image';
import useDocumentTitle from '../../../../../utils/DocumentTitle';
import PendingAppointmentItem from './PendingAppointmentItem';
import { getPageParams } from '../../../../../utils/helper';

const PendingAppointments = () => {
  useDocumentTitle(PageTitles.PENDING_APPOINTMENT);
  const history = useHistory();
  const [pendingAppointments, setPendingAppointments] = useState(null);
  const [loader, setLoader] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const params = new URLSearchParams();
  const [currentPage, setCurrentPage] = useState(getPageParams(history, 'page'));
  const [listInitialPage, setListInitialPage] = useState(getPageParams(history, 'page') - 1);

  const fetchPendingAppointmentList = async () => {
    setLoader(true);
    await axiosInstance
      .get(`${ApiConstant.DOCTOR_PENDING_APPOINTMENT}?page=${currentPage}&limit=${ApplicationConstant.TEN}`)
      .then((res) => {
        if (res && res.data) {
          const pendingAppointmentList = res.data?.data?.data || [];
          const totalPageCount = res.data?.data?.last_page || '';
          setPendingAppointments(pendingAppointmentList);
          setPageCount(totalPageCount);
        }
      })
      .catch((error) => {
        toast.error(error || '');
      });
    setLoader(false);
  };

  const setPageParams = (pageRange) => {
    if (pageRange) {params.set('page', pageRange)}
    history.replace({search: params.toString()})
  }

  const changePagination = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage + ApplicationConstant.ONE);
    setListInitialPage(selectedPage);
    setPageParams(selectedPage + ApplicationConstant.ONE)
  };

  useEffect(() => {
    fetchPendingAppointmentList();
  }, [currentPage]);

  return (
    <>
      <div className='middle-content edit-profile user-right-container'>
        <div className='pages'>
          <div className='profile-information'>
            <div className='form-step max-w-100'>
              <div className='appointments doctor-appointment-list'>
                <div className='profile-info'>
                  <div className='box'>
                    {loader ? (
                      <SpinnerComponent />
                    ) : (
                      <>
                        {pendingAppointments?.length ? (
                          pendingAppointments.map((item, i) => {
                            return <PendingAppointmentItem item={item} key={i} fetchUpdatedList={fetchPendingAppointmentList} />;
                          })
                        ) : (
                          <>
                            {!loader && (
                              <EmptyStateComponent
                                imageSrc={EMPTY_ICON}
                                title='No Pending Appointments.'
                              />
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <div className='pagination-wrapper' hidden={pageCount === ApplicationConstant.ONE}>
                    <ReactPaginate
                      previousLabel={'previous'}
                      nextLabel={'next'}
                      breakLabel={'...'}
                      breakClassName={'break-me'}
                      pageCount={pageCount}
                      marginPagesDisplayed={4}
                      onPageChange={changePagination}
                      pageRangeDisplayed={1}
                      forcePage={listInitialPage}
                      containerClassName={'pagination'}
                      activeClassName={'active'}
                      nextLinkClassName={'next-btn'}
                      previousLinkClassName={'prev-btn'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={4000} hideProgressBar transition={Slide} />
    </>
  );
};

export default PendingAppointments;
