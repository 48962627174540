import React from 'react';

function SummarySingleValue({ oData, keyValue }) {
  return (
    <ul className={`${oData?.[keyValue] ? 'chip-value-list' : 'list-unstyled'}`}>
      <li>{oData?.[keyValue] || '-'}</li>
    </ul>
  );
}

export default SummarySingleValue;
