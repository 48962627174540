import { ApplicationConstant } from '../constants/application.constant';
import update from 'immutability-helper';
const MAX_STEP = ApplicationConstant.NINE;

export const getActiveStateAndValidity = (oUser, forStep) => {
  if (oUser) {
    const oStateAndValidity = { isActive: oUser.patient_profile.current_step === forStep };
    switch (forStep) {
      case ApplicationConstant.ONE:
        if (
          oUser.patient_profile.user_pronouns.find(
            (o) => o.title.toLowerCase() === ApplicationConstant.OTHER_LOWER_CASE
          )
        ) {
          const otherValue = oUser.patient_profile.other_pronoun || '';
          const filteredList = oUser.patient_profile.user_pronouns.filter(
            (o) => o.title !== ApplicationConstant.OTHER
          );
          if (otherValue) {
            oStateAndValidity.isValid = ApplicationConstant.TRUE;
            oStateAndValidity.isOther = ApplicationConstant.TRUE;
          } else {
            if (filteredList && filteredList.length > ApplicationConstant.ZERO) {
              oStateAndValidity.isValid = ApplicationConstant.TRUE;
              oStateAndValidity.isOther = ApplicationConstant.FALSE;
            } else {
              oStateAndValidity.isValid = ApplicationConstant.FALSE;
              oStateAndValidity.isOther = ApplicationConstant.FALSE;
            }
          }
        } else {
          oStateAndValidity.isValid = oUser.patient_profile.user_pronouns.length > ApplicationConstant.ZERO;
          oStateAndValidity.isOther = ApplicationConstant.FALSE;
        }
        break;

      case ApplicationConstant.TWO:
        oStateAndValidity.isValid = !!(
          oUser.patient_profile.weight &&
          oUser.patient_profile.height_feet &&
          oUser.patient_profile.age
        );
        break;

      case ApplicationConstant.THREE:

        if (
          oUser.patient_profile.user_races.find(
            (o) => o.name.toLowerCase() === ApplicationConstant.OTHER_LOWER_CASE
          )
        ) {
          const otherValue = oUser.patient_profile.other_race || '';
          const filteredList = oUser.patient_profile.user_races.filter(
            (o) => o.name !== ApplicationConstant.OTHER
          );
          if (otherValue) {
            oStateAndValidity.isValid = ApplicationConstant.TRUE;
            oStateAndValidity.isOther = ApplicationConstant.TRUE;
          } else {
            if (filteredList && filteredList.length > ApplicationConstant.ZERO) {
              oStateAndValidity.isValid = ApplicationConstant.TRUE;
              oStateAndValidity.isOther = ApplicationConstant.FALSE;
            } else {
              oStateAndValidity.isValid = ApplicationConstant.FALSE;
              oStateAndValidity.isOther = ApplicationConstant.FALSE;
            }
          }
        } else {
          oStateAndValidity.isValid = oUser.patient_profile.user_races.length > ApplicationConstant.ZERO;
          oStateAndValidity.isOther = ApplicationConstant.FALSE;
        }
        break;

      case ApplicationConstant.FOUR:
        if (oUser.patient_profile.ethnicity_name.toLowerCase() === ApplicationConstant.OTHER_LOWER_CASE) {
          oStateAndValidity.isValid = !!oUser.patient_profile.other_ethnicity;
          oStateAndValidity.isOther = ApplicationConstant.TRUE;
        } else {
          oStateAndValidity.isValid = Number.isSafeInteger(oUser.patient_profile.ethnicity_id);
          oStateAndValidity.isOther = ApplicationConstant.FALSE;
        }
        break;

      case ApplicationConstant.FIVE:
        if (
          oUser.patient_profile.user_languages.find(
            (o) => o.name.toLowerCase() === ApplicationConstant.OTHER_LOWER_CASE
          )
        ) {
          const otherValue = oUser.patient_profile.other_language || '';
          const filteredList = oUser.patient_profile.user_languages.filter((o) => o.name !== ApplicationConstant.OTHER);
          if (otherValue) {
            oStateAndValidity.isValid = ApplicationConstant.TRUE;
            oStateAndValidity.isOther = ApplicationConstant.TRUE;
          } else {
            if (filteredList && filteredList.length > ApplicationConstant.ZERO) {
              oStateAndValidity.isValid = ApplicationConstant.TRUE;
              oStateAndValidity.isOther = ApplicationConstant.FALSE;
            } else {
              oStateAndValidity.isValid = ApplicationConstant.FALSE;
              oStateAndValidity.isOther = ApplicationConstant.FALSE;
            }
          }
        } else {
          oStateAndValidity.isValid = oUser.patient_profile.user_languages.length > ApplicationConstant.ZERO;
          oStateAndValidity.isOther = ApplicationConstant.FALSE;
        }
        break;

      case ApplicationConstant.SIX:
        if (
          oUser.patient_profile.user_identifications.find(
            (o) => o.title.toLowerCase() === ApplicationConstant.OTHER_LOWER_CASE
          )
        ) {
          const otherValue = oUser.patient_profile.other_identification || '';
          const filteredList = oUser.patient_profile.user_identifications.filter(
            (o) => o.title !== ApplicationConstant.OTHER
          );
          if (otherValue) {
            oStateAndValidity.isValid = ApplicationConstant.TRUE;
            oStateAndValidity.isOther = ApplicationConstant.TRUE;
          } else {
            if (filteredList && filteredList.length > ApplicationConstant.ZERO) {
              oStateAndValidity.isValid = ApplicationConstant.TRUE;
              oStateAndValidity.isOther = ApplicationConstant.FALSE;
            } else {
              oStateAndValidity.isValid = ApplicationConstant.FALSE;
              oStateAndValidity.isOther = ApplicationConstant.FALSE;
            }
          }
        } else {
          oStateAndValidity.isValid = oUser.patient_profile.user_identifications.length > ApplicationConstant.ZERO;
          oStateAndValidity.isOther = ApplicationConstant.FALSE;
        }
        break;

      case ApplicationConstant.SEVEN:
        if (
          oUser.patient_profile.user_sexual_orientations.find(
            (o) => o.name.toLowerCase() === ApplicationConstant.OTHER_LOWER_CASE
          )
        ) {
          const otherValue = oUser.patient_profile.other_sexual_orientation || '';
          const filteredList = oUser.patient_profile.user_sexual_orientations.filter(
            (o) => o.name !== ApplicationConstant.OTHER
          );
          if (otherValue) {
            oStateAndValidity.isValid = ApplicationConstant.TRUE;
            oStateAndValidity.isOther = ApplicationConstant.TRUE;
          } else {
            if (filteredList && filteredList.length > ApplicationConstant.ZERO) {
              oStateAndValidity.isValid = ApplicationConstant.TRUE;
              oStateAndValidity.isOther = ApplicationConstant.FALSE;
            } else {
              oStateAndValidity.isValid = ApplicationConstant.FALSE;
              oStateAndValidity.isOther = ApplicationConstant.FALSE;
            }
          }
        } else {
          oStateAndValidity.isValid = oUser.patient_profile.user_sexual_orientations.length > ApplicationConstant.ZERO;
          oStateAndValidity.isOther = ApplicationConstant.FALSE;
        }
        break;

      case ApplicationConstant.EIGHT:
        oStateAndValidity.isValid = !!oUser.patient_profile.notes;
        break;

      case ApplicationConstant.NINE:
        oStateAndValidity.isValid = !!(
          oUser.patient_profile.gender_prefrence_first &&
          oUser.patient_profile.gender_prefrence_second &&
          oUser.patient_profile.age_prefrence_first &&
          oUser.patient_profile.age_prefrence_second &&
          oUser.patient_profile.race_prefrence_first &&
          oUser.patient_profile.race_prefrence_second &&
          oUser.patient_profile.orentation_prefrence_first &&
          oUser.patient_profile.orentation_prefrence_second &&
          oUser.patient_profile.ethnicity_prefrence_first &&
          oUser.patient_profile.ethnicity_prefrence_second &&
          oUser.patient_profile.language_prefrence_first &&
          oUser.patient_profile.language_prefrence_second
        )
        break;

      default:
        break;
    }
    return oStateAndValidity;
  }
};

export const goToNextStep = (oUserRegistration, setUserRegistration) => {
  setUserRegistration(update(oUserRegistration, { patient_profile: { current_step: { $apply: next } } }));
};

export const goToPreviousStep = (oUserRegistration, setUserRegistration) => {
  setUserRegistration(update(oUserRegistration, { patient_profile: { current_step: { $apply: prev } } }));
};

export const goToStepNumber = (val, oUserRegistration, setUserRegistration) => {
  setUserRegistration(update(oUserRegistration, { patient_profile: { current_step: { $set: val } } }));
};

function next(step) {
  if (step < MAX_STEP) {
    return step + ApplicationConstant.ONE;
  }
  return step;
}

function prev(step) {
  if (step > ApplicationConstant.ONE) {
    return step - ApplicationConstant.ONE;
  }
  return step;
}
