import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';
import { ApplicationConstant } from '../../../../../../constants/application.constant';
import { downloadFileFromBlob } from '../../../../../../utils/helper';
import { downloadPatientDocument, downloadPatientMultipleDocuments, getPatientDocumentList, getPatientSessionDocumentList, resetScrollPosition } from '../../../session/document/documents/PatientDocumentUtils';
import DocumentList from './documentList/DocumentList';

function CompletedAppointmentDocument(props) {

  const { appointmentId, userId } = props;
  const [listLoader, setListLoader] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [scrollLoader, setScrollLoader] = useState(false);
  const [sortObj, setSortObj] = useState({ sortBy: '', order: '' });
  const [currentPage, setCurrentPage] = useState(ApplicationConstant.ONE);
  const [totalPageLimit, setTotalPageLimit] = useState(ApplicationConstant.ZERO);
  const [selectAllChecked, isSelectAllChecked] = useState(false);
  const [documentList, setDocumentList] = useState([]);
  const tableScrollRef = useRef(null);

  const fetchPatientDocuments = async(appointmentId, page, sortKey) => {
    setListLoader(true);
    await getPatientSessionDocumentList(appointmentId, page, sortKey).then((response) => {
      if(response && response.data) {
        const patientDocumentList = response.data?.data?.data || [];
        const filterdDocumentList = patientDocumentList?.map((item) => ({...item, checked: false}));
        const patientTotalListCount = response.data?.data?.last_page || 0;
        setDocumentList(filterdDocumentList);
        setTotalPageLimit(patientTotalListCount);
        resetScrollPosition(tableScrollRef);
        isSelectAllChecked(false);
      }
    })
    .catch((error) => {
      toast.error(error || '');
    });
    setListLoader(false);
  }

  const fetchMoreDocumentList = async () => {
    if(currentPage < totalPageLimit) {
      setScrollLoader(true);
      await getPatientSessionDocumentList(appointmentId, currentPage + ApplicationConstant.ONE, sortObj)
      .then((response) => {
        if(response && response.data) {
          const updatedList = response.data?.data?.data || [];
          const filterdDocumentList = updatedList?.map((item) => ({...item, checked: false}));
          const documentUpdatedList = [...documentList, ...filterdDocumentList];
          setDocumentList(documentUpdatedList);
          setCurrentPage(currentPage + ApplicationConstant.ONE);
        }
      }).catch(error => {toast.error(error || '');});
      setScrollLoader(false);
    }
  }

  const sortDocumentList = (sortBy, order) => {
    setCurrentPage(ApplicationConstant.ONE);
    setSortObj({ sortBy, order });
  };

  const onSelectListRow = (itemId) => {
    let checkedList = documentList;
    const targetIdIndex = checkedList.findIndex(e => e.id === itemId);
    if(targetIdIndex > -1) {
      checkedList[targetIdIndex].checked = !documentList[targetIdIndex].checked;
    }
    setDocumentList([...checkedList])
  }

  const onDownloadDocument = async() => {
    const checkedItems = documentList.filter(e => e.checked);
    if(checkedItems?.length) {
      const checkedIds = checkedItems.map(e=>e.id);
      setDownloadLoader(true);
      if(checkedItems?.length > ApplicationConstant.ONE) {
        const dowloadDocumentPayload = {appointment_id:appointmentId,document_ids:checkedIds}
        await downloadPatientMultipleDocuments(dowloadDocumentPayload).then((resposnse) => resposnse.data)
        .then((blob)=>{downloadFileFromBlob(blob, 'Documents.zip');
        }).catch((error) => toast.error(error || ''));
      } else {
        await downloadPatientDocument(checkedIds).then((response)=> response.data)
        .then((blob)=>{downloadFileFromBlob(blob, checkedItems[ApplicationConstant.ZERO]?.document_name);
        }).catch((error) => toast.error(error || ''));
      }
      setDownloadLoader(false);
    }
  }

  const onDownloadDocumentByItem = async(id) => {
    const target = documentList.find(e => e.id === id);
    await downloadPatientDocument(id).then((response)=> response.data)
        .then((blob)=>{downloadFileFromBlob(blob, target?.document_name);
        }).catch((error) => toast.error(error || ''));
  }

  const onSelectAll = (e) => {
    const isChecked = e.target?.checked;
    isSelectAllChecked(isChecked);
    const selectedIds = documentList.map((item) => ({...item, checked: isChecked}));
    setDocumentList(selectedIds);
  }

  useEffect(()=>{
    fetchPatientDocuments(appointmentId, currentPage, sortObj);
  },[sortObj])

  return (
    <div className={"divTable d-flex-table scroll-padding"}>
        {listLoader &&
          <div className="spinner-component full-page-spinner position-absolute">
            <Spinner/>
          </div>
        }
        {documentList && documentList?.length ?
          <DocumentList
            documentList={documentList}
            fetchMoreData={fetchMoreDocumentList}
            scrollLoader={scrollLoader}
            sortFilter={sortObj}
            sortData={sortDocumentList}
            onSelectListRow={onSelectListRow}
            downloadDocument={onDownloadDocument}
            selectAll={onSelectAll}
            isSelectAllChecked={selectAllChecked}
            tableScrollRef={tableScrollRef}
            totalPageLimit={totalPageLimit}
            downloadLoader={downloadLoader}
            onDownloadDocumentByItem={onDownloadDocumentByItem}
          />
        :<p className="no-notes-text pt-50 pb-50 border-bottom">No document avaliable</p>}
      </div>
    )
}

export default CompletedAppointmentDocument;
