import React, { useState, useEffect } from 'react';
import { ToastContainer, toast, Slide } from 'react-toastify';
import axiosInstance from '../../../axios/axiosInstance';
import ApiConstant from '../../../constants/ApiConstant';
import useDocumentTitle from '../../../utils/DocumentTitle';
import { PageTitles, ApplicationConstant } from '../../../constants/application.constant';
import DoctorProfile from './DoctorProfile';
import SpinnerComponent from '../../common/spinner/SpinnerComponent';

const DoctorProfileView = () => {
  useDocumentTitle(PageTitles.DOCTOR_PROFILE);

  const [profileData, setProfileData] = useState({});
  const [loader, setLoader] = useState(false);

  const fetchPatientProfile = async () => {
    setLoader(true);
    await axiosInstance
      .get(`${ApiConstant.DOCTOR_DASHBOARD}`)
      .then((response) => {
        if (response && response.data) {
          const doctorProfileData = response.data.data || {};
          doctorProfileData.doctorSpecialization = calculateDoctorSpecialization(doctorProfileData);
          setProfileData(doctorProfileData);
        }
      })
      .catch((error) => {
        toast.error(error || '');
      });
    setLoader(false);
  };

  const calculateDoctorSpecialization = (docProfile) => {
    const doctorSpecializations = docProfile?.doctor_specializations || [];
    if (doctorSpecializations.length > ApplicationConstant.ZERO) {
      return doctorSpecializations[ApplicationConstant.ZERO]?.name || '';
    }
    return '';
  }

  useEffect(() => {
    fetchPatientProfile();
  }, []);

  return (
    <>
    {loader && <SpinnerComponent />}
      <div className="middle-content view-profile">
        <div className="container">
          <DoctorProfile profileInfo={profileData} />
        </div>
      </div>
      <ToastContainer autoClose={4000} hideProgressBar transition={Slide} />
    </>
  );
};
export default DoctorProfileView;
