import React from 'react';
import { ApplicationConstant } from '../../constants/application.constant';

function SummaryMultiValues({ aData, keyValue }) {
  return (
    <ul className={`${aData?.length > ApplicationConstant.ZERO ? 'chip-value-list' : 'list-unstyled'}`}>
      {aData?.length > ApplicationConstant.ZERO
        ? aData.map(function (oData, index) {
            if (oData[keyValue]) {
              return <li key={index}>{oData[keyValue]}</li>;
            }
            return <></>;
          })
        : '-'}
    </ul>
  );
}

export default SummaryMultiValues;
