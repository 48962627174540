import React from 'react';
import { ApplicationConstant, profileKey, targetedId } from '../../../../constants/application.constant';
import ProfileTooltip from '../../../common/profileElements/ProfileTooltip';

function PatientViewDoctorProfileEducation(props) {
  const { doctorData } = props;
  return (
    <>
      <div className="profile-info">
        <h3 className="mt-10 mb-18">Education Information</h3>
        <div className="row">
          <div className="col-md-4 col-sm-6">
            <label>School</label>
            <div className="value">
              {doctorData?.medical_school?.toLowerCase() ===
              ApplicationConstant.OTHER_LOWER_CASE
                ? doctorData?.other_medical_school
                : "-"}
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <label>Degrees/Credentials</label>
            <div className="value more-items">
              {doctorData?.doctor_certifications?.length
                ? ProfileTooltip(
                    doctorData.doctor_certifications,
                    targetedId.DOCTOR,
                    profileKey.NAME,
                    profileKey.OTHER_CERTIFICATION
                  )
                : "-"}
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <label>Specialty</label>
            <div className="value more-items">
              {doctorData?.doctor_specializations?.length
                ? ProfileTooltip(
                    doctorData.doctor_specializations,
                    targetedId.DOCTOR,
                    profileKey.NAME,
                    profileKey.OTHER_SPECIALIZATIONS
                  )
                : "-"}
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <label>Years of Experience</label>
            {doctorData?.year_of_exp ? (
              <div className="value">{doctorData?.year_of_exp} years</div>
            ) : (
              <div>-</div>
            )}
          </div>
          <div className="col-md-4 col-sm-6">
            <label>Practice</label>
            <div className="value more-items">
              {doctorData?.doctor_practices?.length
                ? ProfileTooltip(
                    doctorData.doctor_practices,
                    targetedId.DOCTOR,
                    profileKey.NAME,
                    profileKey.OTHER_PRACTICES
                  )
                : "-"}
            </div>
          </div>
          <div className="col-md-4 col-sm-6 d-none">
            <label>Procedure(s) Performed</label>
            <div className="value more-items">
              {doctorData?.doctor_procedures?.length
                ? ProfileTooltip(
                    doctorData.doctor_procedures,
                    targetedId.DOCTOR,
                    profileKey.NAME,
                    profileKey.OTHER_PROCEDURES
                  )
                : "-"}
            </div>
          </div>
          <div className="col-md-4 col-sm-6 d-none">
            <label>Condition(s) Treated</label>
            <div className="value more-items">
              {doctorData?.doctor_condition_treateds?.length
                ? ProfileTooltip(
                    doctorData.doctor_condition_treateds,
                    targetedId.DOCTOR,
                    profileKey.NAME,
                    profileKey.OTHER_CONDITION_TREATEDS
                  )
                : "-"}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PatientViewDoctorProfileEducation;
