import React from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router';
import ApiConstant from '../../../constants/ApiConstant';
import axiosInstance from '../../../axios/axiosInstance';
import { checkValidation } from './DoctorRegistrationUtils';
import { RouteConstants, StatusType } from '../../../constants/application.constant';

const DoctorRegistrationFooter = ({ setLoadingState, oDoctorRequest, oDoctorFormState, profileMode, handleCancel, fetchProfile }) => {
  const history = useHistory();

  const handleClick = (e) => {
    e.preventDefault();
    setLoadingState(true);
    const oPayload = createPayload(oDoctorRequest.doctor);
    axiosInstance.post(ApiConstant.CREATE_DOCTOR_PROFILE, oPayload).then(
      (_res) => {
        setLoadingState(false);
        history.replace(RouteConstants.SUMMARY_DOCTOR);
      },
      (_error) => {
        setLoadingState(false);
      }
    );
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    const oPayload = createPayload(oDoctorRequest.doctor);
    axiosInstance.post(ApiConstant.UPDATE_DOCTOR_USER_PROFILE, oPayload).then(
      (response) => {
        fetchProfile(StatusType.SUCCESS, response)
      },
      (error) => {
        fetchProfile(StatusType.ERROR, error)
      }
    );
  };

  function createPayload(oData) {
    return {
      ethnicity_id: oData.ethnicity_id || null,
      other_ethnicity: oData.other_ethnicity || null,
      user_pronouns:  prepareRequestArray(
        oData.user_pronouns, 'other_pronoun',
        oData.other_pronoun || ''),
      user_races: prepareRequestArray(
        oData.user_races, 'other_race',
        oData.other_race || ''),
      user_languages: prepareRequestArray(
        oData.user_languages, 'other_language',
        oData.other_language || ''),
      user_identifications: prepareRequestArray(
        oData.user_identifications, 'other_identification',
        oData.other_identification || ''),
      user_sexual_orientations: prepareRequestArray(
        oData.user_sexual_orientations, 'other_sexual_orientation',
        oData.other_sexual_orientation || '')
    };
  }

  function prepareRequestArray(aData, key, value) {
    const items = [];
    for (const oData of aData) {
      if (oData.isOther) {
        items.push({ id: oData.id, [key]: value });
      } else {
        items.push({ id: oData.id, [key]: '' });
      }
    }
    return items;
  }

  return (
    <>
      <footer hidden={profileMode}>
        <div className="container">
          <div className="footer-btn">
            <div className="prev"></div>
            <div className="next">
              <Button disabled={ checkValidation(oDoctorRequest.doctor, oDoctorFormState)}
              color="primary" onClick={handleClick}>
                Save
              </Button>
            </div>
          </div>
        </div>
      </footer>
      <footer className="static-footer" hidden={!profileMode}>
        <div className="container p-0">
            <div className="footer-btn">
                <div className="next">
                    <Button
                      color="secondary"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      onClick={handleUpdate}
                      disabled={checkValidation(oDoctorRequest.doctor, oDoctorFormState)}
                    >
                      Save
                    </Button>
                </div>
            </div>
        </div>
      </footer>
    </>
  );
};

export default DoctorRegistrationFooter;
