import React, { useContext, useState } from 'react';
import update from 'immutability-helper';
import DoctorSignUpContext from '../../../contexts/doctor-signup/DoctorSignUpContext';
import SelectElement from '../../common/formElements/SelectElement';
import InputElement from '../../common/formElements/InputElement';
import { ApplicationConstant, BasePathConstant, DateConstant } from '../../../constants/application.constant';
import RadioToggleElement from '../../common/formElements/RadioToggleElement';
import { futureDateValidation } from './DoctorValidation';
import { idNumberValidation, npiNumberValidation } from '../../../validators/CommonValidation';
import ReactFileUploader from '../../common/fileUpload/ReactFileUploader';
import DateElement from '../../common/formElements/DateElement';

function DoctorProfessionalInformation(props) {
  const { oLabelInfo, filename, setFilename } = props;
  const [doctorSignUp, setDoctorSignUp] = useContext(DoctorSignUpContext);

  const setValueByFieldName = (fieldname, value) => {
    setDoctorSignUp(update(doctorSignUp, { doctor_profile: { [fieldname]: { $set: value } } }));
  };

  const onNpiNumberInput = (e) => {
    e.preventDefault();
    if (e.target.value.length <= ApplicationConstant.TEN) {
      const obj = npiNumberValidation(e.target.value);
      if (obj.value !== false) {
        setValueByFieldName('npi_number', obj);
      }
    }
  };

  const onLisenceNumberInput = (e) => {
    e.preventDefault();

    setValueByFieldName('license_number', {
      value: e.target.value,
      msg: '',
      valid: ApplicationConstant.TRUE,
      touched: ApplicationConstant.TRUE,
    });
  };

  const onExpirationDateInput = (date) => {
    const obj = futureDateValidation(date);
    setValueByFieldName('expiration_date', obj);
  };

  const handleToggle = (e) => {
    setValueByFieldName(e.target.name, e.target.value);
  };

  const onFileUploadSucess = (url, name) => {
    setFilename(name);
    setValueByFieldName('document', url);
  };

  const onFileRemove = () => {
    setFilename('');
    setValueByFieldName('document', '');
  };

  const onMultiSelectInput = (name, e) => {   
    if (e.length >= ApplicationConstant.ONE) {
      setValueByFieldName(name, e);
    } else {
      setValueByFieldName(name, '');
    }
  };

  const otherTextInput = (e) => {
    e.preventDefault();
    setValueByFieldName(e.target.name, e.target.value);
  };

  return (
    <div>
      <h3 className="mt-24">Professional Information</h3>
      <div className="row">
        <InputElement
          className="col-sm-6"
          type="text"
          name="npi_number"
          value={doctorSignUp.doctor_profile.npi_number.value}
          onChange={onNpiNumberInput}
          onBlur={onNpiNumberInput}
          onKeyDown={(evt) =>
            ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
          }
          label={"NPI Number"}
          placeholder={"Please enter npi number"}
          isValid={doctorSignUp.doctor_profile.npi_number.valid}
          msg={doctorSignUp.doctor_profile.npi_number.msg}
          required={ApplicationConstant.FALSE}
        />
        <InputElement
          className="col-sm-6"
          type="text"
          name="license_number"
          onChange={onLisenceNumberInput}
          onBlur={onLisenceNumberInput}
          onKeyDown={(evt) =>
              ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
          }
          value={doctorSignUp.doctor_profile.license_number.value}
          label={"License Number"}
          placeholder={"Please Enter Your License Number"}
          required={ApplicationConstant.FALSE}
        />
        <DateElement
          className="col-sm-12"
          type="date"
          name="expiration_date"
          value={doctorSignUp.doctor_profile.expiration_date.value}
          onChange={onExpirationDateInput}
          dateFormat="MMM d, yyyy"
          minDate={new Date()}
          maxDate={new Date(DateConstant.END_DATE)}
          onKeyPress={(e) => {
            e.preventDefault();
          }}
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          onKeyUp={(e) => {
            e.preventDefault();
          }}
          isValid={doctorSignUp.doctor_profile.expiration_date.valid}
          msg={doctorSignUp.doctor_profile.expiration_date.msg}
          label={"License Expiration Date"}
          required={ApplicationConstant.FALSE}
        />
        {/*<ReactFileUploader
          onSuccess={onFileUploadSucess}
          onRemove={onFileRemove}
          allowedExtensions={["jpg", "jpeg", "png", "pdf", "doc", "docx"]}
          allowedSize={10000000}
          basePath={BasePathConstant.DOCTOR_LICENSE}
          allowedTypeMsg={".jpg, .png, pdf, jpeg and doc/docx allowed."}
          value={
            filename !==
            ApplicationConstant.NO_VALUE_STRING
              ? filename
              : null
          }
        />
        <div class="col-sm-12 pb-20">
          <p class="font-12 gray666">Maximum file size: 10 MB </p>
          <p class="font-12 gray666">
            Accepted files types: png, jpg, pdf, jpeg, doc/docx
          </p>
        </div>*/}
        <SelectElement
          classNm={"col-sm-12"}
          name={"state"}
          isMulti={ApplicationConstant.TRUE}
          onChange={(e) => {
            onMultiSelectInput("state", e);
          }}
          className="reactSelect"
          classNamePrefix="react-select"
          options={oLabelInfo.states}
          value={doctorSignUp.doctor_profile.state}
          isSearchable={ApplicationConstant.TRUE}
          stayMenuOnSelect={ApplicationConstant.TRUE}
          placeholder={""}
          label={"State(s) of license*"}
        />
        <RadioToggleElement
          classNm={"col-sm-12"}
          label={"Are you a prescriber?*"}
          name={"prescription_availability"}
          onChange={handleToggle}
          selectedValue={doctorSignUp.doctor_profile.prescription_availability}
        />
        <RadioToggleElement
          classNm={"col-sm-12 d-none"}
          label={"Do you currently see patients in private practice?*"}
          name={"see_patients"}
          selectedValue={doctorSignUp.doctor_profile.see_patients}
          onChange={handleToggle}
        />
        <RadioToggleElement
          classNm={"col-sm-12"}
          label={
            "Do you currently have your own independent malpractice insurance?*"
          }
          name={"own_insurance"}
          selectedValue={doctorSignUp.doctor_profile.own_insurance}
          onChange={handleToggle}
        />
        <RadioToggleElement
          classNm={"col-sm-12"}
          label={"Do you currently accept insurance?*"}
          name={"accept_insurance"}
          selectedValue={doctorSignUp.doctor_profile.accept_insurance}
          onChange={handleToggle}
        />
        {doctorSignUp.doctor_profile.accept_insurance === "Y" && (
          <SelectElement
            classNm={"col-sm-12"}
            name={"insurance_provider"}
            isMulti={ApplicationConstant.TRUE}
            onChange={(e) => {
              onMultiSelectInput("insurance_provider", e);
            }}
            className="reactSelect"
            classNamePrefix="react-select"
            options={oLabelInfo.insurance_provider}
            value={doctorSignUp.doctor_profile.insurance_provider}
            isSearchable={ApplicationConstant.TRUE}
            stayMenuOnSelect={ApplicationConstant.TRUE}
            placeholder={""}
            label={"Insurance Provider*"}
          />
        )}
        {doctorSignUp.doctor_profile?.accept_insurance === "Y" &&
          Array.isArray(doctorSignUp.doctor_profile.insurance_provider) &&
          doctorSignUp.doctor_profile.insurance_provider?.findIndex(
            (item) =>
              item?.name?.toLowerCase() === ApplicationConstant.OTHER_LOWER_CASE
          ) > -1 && (
            <InputElement
              className="col-sm-12"
              type="text"
              name="other_insurance_provider"
              value={doctorSignUp.doctor_profile.other_insurance_provider}
              onChange={otherTextInput}
              label={"Other Insurance Provider*"}
              placeholder={"Please enter other detail"}
              required={ApplicationConstant.FALSE}
              autoFocus
            />
          )}
        <RadioToggleElement
          classNm={"col-sm-12"}
          label={
            "Do you currently use your own independent electronic medical record?*"
          }
          name={"electronic_medical_record"}
          selectedValue={doctorSignUp.doctor_profile.electronic_medical_record}
          onChange={handleToggle}
        />
      </div>
    </div>
  );
}

export default DoctorProfessionalInformation;
