import React, { useState } from 'react';
import DoctorSignUpContext from './DoctorSignUpContext';
import DoctorSignUpInitialState from './DoctorSignUpInitialState';

const DoctorSignUpProvider = (props) => {
  const [doctorSignUp, setDoctorSignUp] = useState(DoctorSignUpInitialState);
  return (
    <DoctorSignUpContext.Provider value={[doctorSignUp, setDoctorSignUp]}>
      {props.children}
    </DoctorSignUpContext.Provider>
  );
};

export default DoctorSignUpProvider;
