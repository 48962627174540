import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import update from 'immutability-helper';
import { ToastContainer, toast, Slide } from 'react-toastify';
import useDocumentTitle from '../../../../utils/DocumentTitle';
import { getAPIData } from '../../../../utils/doctorDetailsApi';
import { getCitiesById, getStates } from '../../../../utils/CommonRequests';
import axiosInstance from '../../../../axios/axiosInstance';
import ApiConstant from '../../../../constants/ApiConstant';
import { PageTitles, RouteConstants, NPITypes, ApplicationConstant } from '../../../../constants/application.constant';
import { intialState, updateAccountInfoPayload, checkValidation } from './AccountInformationUtils';
import SpinnerComponent from '../../../common/spinner/SpinnerComponent';
import BackgroundInformation from './BackgroundInformation';
import BasicInformation from './BasicInformation';
import LocationInformation from './LocationInformation';
import ProfileImage from './ProfileImage';
import UserProfileContext from '../../../../contexts/user-profile/UserProfileContext';

function AccountInformation() {
  useDocumentTitle(PageTitles.ACCOUNT_INFORMATION);
  const [loader, setLoader] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const [profileInitialData, setProfileInitialData] = useState(null);
  const [stateId, setStateId] = useState(null);
  const [npiOptions, setNpiOptions] = useState([]);
  const [specializationOptions, setSpecializationOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [oUserGenericData, setUserGenericData]= useContext(UserProfileContext);

  const fetchPatientProfile = async () => {
    setLoader(true);
    await axiosInstance
      .get(`${ApiConstant.HOSPITAL_PROFILE}`)
      .then((response) => {
        if (response && response.data) {
          const hospitalProfileData = response.data.data || {};
          const hospitalStateId = hospitalProfileData?.location?.state_id || '';
          setProfileData(intialState(hospitalProfileData));
          setProfileInitialData(intialState(hospitalProfileData));
          setStateId(hospitalStateId);
          setUserGenericData(update(oUserGenericData, {'profile_image': { $set: hospitalProfileData?.user?.profile_image || ApplicationConstant.NO_VALUE_STRING}}));
        }
      })
      .catch((error) => {
        toast.error(error || '');
      });
    setLoader(false);
  };

  const fetchNPIData = () => {
    setNpiOptions(NPITypes);
  }

  const fetchSpecializationData = () => {
    getAPIData(ApiConstant.SPECIALIZATIONS)
    .then((res) => {
      if(Object.entries(res).length > 0) {
        let options = res.map(item => ({...item, label: item.name, value: item.name.toLowerCase()}));
        setSpecializationOptions(options);
      }
    })
    .catch((error) => {
      toast.error(error || '');
      setSpecializationOptions({});
    });
  };


  const fetchStateData = () => {
    getStates().then((res) => {
      if (res.length > 0) {
        setStateOptions(res.map((item) => ({ ...item, label: item.name, value: item.name.toLowerCase() })));
      }
    });
  }

  const setValueByFieldName = (fieldname, value) => {
    setProfileData(update(profileData, { [fieldname]: { $set: value } }));
  };

  const handleSubmit = (payload) => {
    setLoader(true);
    axiosInstance.post(ApiConstant.UPDATE_HOSPITAL_PROFILE, updateAccountInfoPayload(payload)).then(
      (response) => {
        setLoader(false);
        if (response && response.data) {
          const hospitalProfileData = response.data.data || {};
          const hospitalStateId = hospitalProfileData?.location?.state_id || '';
          setProfileData(intialState(hospitalProfileData));
          setProfileInitialData(intialState(hospitalProfileData));
          setStateId(hospitalStateId);
        }
        toast.success(response?.data?.message);
      })
      .catch((error) => {
        setLoader(false);
        toast.error(error || '');
      });
  }

  useEffect(()=>{
    fetchPatientProfile();
    fetchStateData();
    fetchNPIData();
    fetchSpecializationData();
  },[])

  return (
    <>
    {loader && <SpinnerComponent />}
    <div className="middle-content edit-profile user-right-container">
      <div className="pages">
        <div className="profile-information">
          <div className="form-step max-w-100">
            <form className='w-100'>
              <ProfileImage
                profileData={profileData}
                intialProfileData={profileInitialData}
                setValueByFieldName={setValueByFieldName}
                updateProfileImage={handleSubmit}
              />
              <BasicInformation
                profileData={profileData}
                setValueByFieldName={setValueByFieldName}
                profileInitialData={profileInitialData}
              />
              <LocationInformation
                profileData={profileData}
                setValueByFieldName={setValueByFieldName}
                stateId={stateId}
                setStateId={setStateId}
                stateOptions={stateOptions}
              />
              <BackgroundInformation
                profileData={profileData}
                setValueByFieldName={setValueByFieldName}
                npiOption={npiOptions}
                specializationOptions={specializationOptions}
              />
              <div className="btn-group justify-content-end w-100 pt-10 form-btn-group">
                <Link to={RouteConstants.DASHBOARD_HOSPITAL} className="btn mr-15 cancel-btn btn-secondary">CANCEL</Link>
                <Button
                  type="button"
                  color="primary"
                  className="btn btn-primary"
                  disabled={!checkValidation(profileData, profileInitialData)}
                  onClick={()=>{handleSubmit(profileData)}}
                >
                  save
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <ToastContainer autoClose={4000} hideProgressBar transition={Slide} />
    </>
  );
}

export default AccountInformation;
