const HospitalSignUpInitialState = {
  hospital_details: {
    current_step: 1,
    role_id: '',
    first_name: '',
    user_name: '',
    tax_id: '',
    date_of_business: '',
    email: '',
    phone: '',
    password: '',
    confirm_password: '',
    npi_number: '',
    npi_type: '',
    malpractice_insurance: '',
    hipaa_compliance: '',
    expiration_date: '',
    authorized_official_name: '',
    doctor_specializations: '',
    address1: '',
    address2: '',
    state: '',
    city: '',
    zip_code: '',
  },
};

export default HospitalSignUpInitialState;
