import React from 'react';

function DoctorProfileLocationInfo(props) {
  const { profileInfo } = props;
  return (
    <>
      <h2 className="profile-section-head">Location</h2>

      <div className="col-md-4 col-sm-6">
        <label>Street Address</label>
        <div className="value">
          {profileInfo?.doctor_profile?.address1 || "-"}
        </div>
      </div>
      <div className="col-md-4 col-sm-6">
        <label>Apartmant</label>
        <div className="value">
          {profileInfo?.doctor_profile?.address2 || "-"}
        </div>
      </div>
      <div className="col-md-4 col-sm-6">
        <label>City</label>
        <div className="value">{profileInfo?.doctor_profile?.city || "-"}</div>
      </div>
      <div className="col-md-4 col-sm-6">
        <label>State</label>
        <div className="value">{profileInfo?.doctor_profile?.state || "-"}</div>
      </div>
      <div className="col-md-4 col-sm-6">
        <label>Zip Code</label>
        <div className="value">
          {profileInfo?.doctor_profile?.zip_code || "-"}
        </div>
      </div>
    </>
  );
}

export default DoctorProfileLocationInfo;

