import React, { useState } from 'react';
import PatientSignUpContext from './PatientSignUpContext';
import PatientSignUpInitialState from './PatientSignUpInitialState';

const PatientSignUpProvider = (props) => {
  const [patientSignUp, setPatientSignUp] = useState(PatientSignUpInitialState);
  return (
    <PatientSignUpContext.Provider value={[patientSignUp, setPatientSignUp]}>
      {props.children}
    </PatientSignUpContext.Provider>
  );
};

export default PatientSignUpProvider;
