import React, { useEffect, useState } from 'react';
import InputElement from '../common/formElements/InputElement';
import SelectElement from '../common/formElements/SelectElement';
import { Button } from 'reactstrap';
import useDocumentTitle from '../../utils/DocumentTitle';
import axiosInstance from '../../axios/axiosInstance';
import { PageTitles } from '../../constants/application.constant';
import ApiConstant from '../../constants/ApiConstant';
import ContactUsState from './ContactUsState';
import SpinnerComponent from '../common/spinner/SpinnerComponent';
import { useHistory } from 'react-router';
import update from 'immutability-helper';
import { checkValidation, createContactPayload } from './ContactUsUtils';
import { Roles, ApplicationConstant } from '../../constants/application.constant';
import {
  nameValidation,
  emailValidation,
  phoneValidation,
  preferredNameValidation,
} from '../../validators/CommonValidation';
import PageLogo from '../common/logo/PageLogo';
import ContactUsThanks from './ContactUsThanks';

function ContactUs() {
  const history = useHistory();
  const [isQuerySent, setQuerySent] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [aQuestionTypes, setQuestionTypes] = useState([]);
  const [oContact, setContact] = useState(ContactUsState);
  useDocumentTitle(PageTitles.CONTACT_US);

  useEffect(() => {
    setLoading(true);
    axiosInstance.get(ApiConstant.CONTACT_US_QUESTIONS_TYPE).then(
      (res) => {
        setLoading(false);
        const aQuestions = res?.data?.data || [];
        if (aQuestions.length > ApplicationConstant.ZERO) {
          const aData = aQuestions.map((item) => ({
            ...item,
            label: item.question,
            value: item.question.toLowerCase(),
          }));
          setQuestionTypes(aData);
        }
      },
      (_error) => {
        setLoading(false);
      }
    );
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axiosInstance.post(ApiConstant.SUBMIT_QUESTION, createContactPayload(oContact)).then(
      (_res) => {
        setLoading(false);
        setQuerySent(true);
      },
      (_error) => {
        setLoading(false);
      }
    );
  };

  const goBack = (e) => {
    e.preventDefault();
    history.goBack();
  };

  const onChangeRole = (role) => {
    setContact(update(oContact, { role: { $set: role } }));
  };

  const onChangeQuestionType = (oType) => {
    setContact(update(oContact, { questionType: { $set: oType } }));
  };

  const handleOnChange = (e, keyValue) => {
    const obj = nameValidation(e.target.value, oContact[keyValue]);
    setValueByFieldName(keyValue, obj);
  };

  const onInputQuery = (e, keyValue) => {
    setValueByFieldName(keyValue, e.target.value);
  };

  const onInputPreferredName = (e, keyValue) => {
    const obj = preferredNameValidation(e.target.value, oContact[keyValue]);
    setValueByFieldName(keyValue, obj);
  };

  const onEmailInput = (e) => {
    e.preventDefault();
    emailValidation('email', e.target.value, setValueByFieldName);
  };

  const onPhoneInput = (e) => {
    e.preventDefault();
    if (e.target.value.length <= ApplicationConstant.TEN) {
      phoneValidation('phone', e.target.value, setValueByFieldName);
    }
  };

  const setValueByFieldName = (fieldname, value) => {
    setContact(update(oContact, { [fieldname]: { $set: value } }));
  };

  return (
    <>
      {isLoading && <SpinnerComponent />}
      {isQuerySent && <ContactUsThanks handleClick={goBack} />}
      {!isQuerySent && (
        <div className="middle-content contactUs mb-0 mt-0 pb-100 h-100vh">
          <div className="login-title">
            <PageLogo />
            <h1>Contact Us</h1>
          </div>
          <div className="container">
            <div className="form-step">
              <form className="w-100">
                <div className="row">
                  <SelectElement
                    classNm={'col-sm-6'}
                    name={'medical_school_id'}
                    className="reactSelect"
                    classNamePrefix="react-select"
                    value={oContact?.role}
                    options={Roles || []}
                    isSearchable={false}
                    onChange={(e) => onChangeRole(e)}
                    placeholder={''}
                    label={'What is your role?*'}
                  />
                  <InputElement
                    className="col-sm-6"
                    type="text"
                    name="address1"
                    placeholder={'Please enter preferred name'}
                    label={'Preferred Name'}
                    onChange={(e) => onInputPreferredName(e, 'preferred_name')}
                    value={oContact.preferred_name?.value || ''}
                  />
                  <InputElement
                    className="col-sm-6"
                    type="text"
                    name="address1"
                    placeholder={'Please enter first name'}
                    label={'First Name*'}
                    onChange={(e) => handleOnChange(e, 'first_name')}
                    isValid={oContact?.first_name?.valid}
                    value={oContact?.first_name?.value || ''}
                  />
                  <InputElement
                    className="col-sm-6"
                    type="text"
                    name="address1"
                    placeholder={'Please enter last name'}
                    label={'Last Name*'}
                    onChange={(e) => handleOnChange(e, 'last_name')}
                    isValid={oContact?.last_name?.valid}
                    value={oContact?.last_name?.value || ''}
                  />
                  <InputElement
                    className="col-sm-6"
                    type="text"
                    name="email"
                    placeholder={'Please enter email'}
                    label={'Email Address*'}
                    onChange={onEmailInput}
                    isValid={oContact?.email?.valid}
                    value={oContact?.email?.value || ''}
                    msg={oContact?.email?.msg}
                  />
                  <InputElement
                    className="col-sm-6"
                    type="number"
                    name="address1"
                    placeholder={'Please enter phone number'}
                    label={'Phone Number*'}
                    onChange={onPhoneInput}
                    isValid={oContact?.phone?.valid}
                    value={oContact?.phone?.value || ''}
                    msg={oContact?.phone?.msg}
                  />
                  <SelectElement
                    classNm={'col-sm-12'}
                    name={'question_type'}
                    className="reactSelect"
                    classNamePrefix="react-select"
                    options={aQuestionTypes || []}
                    value={oContact?.questionType}
                    isSearchable={false}
                    onChange={(e) => onChangeQuestionType(e)}
                    placeholder={''}
                    label={'Please select the type of question you have to help us direct it to the right place.*'}
                  />
                  <InputElement
                    className="col-sm-12 pt-10"
                    type="textarea"
                    height="100"
                    placeholder={'Please enter question here*'}
                    label={'Please add your question here*'}
                    onChange={(e) => onInputQuery(e, 'query')}
                    value={oContact?.query || ''}
                  />
                  <div className="col-sm-12">
                    <div className="justify-content-end d-flex w-100 pt-10">
                      <div className="btn-group">
                        <Button className="btn mr-15 btn-secondary w-120" color="secondary" onClick={goBack}>
                          CANCEL
                        </Button>
                        <Button
                          disabled={!checkValidation(oContact)}
                          className="btn btn-primary w-120"
                          color="primary"
                          onClick={onSubmit}
                        >
                          SUBMIT
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ContactUs;
