import React from 'react';
import { PROFILEIMG } from '../../../constants/image';
import { CommonFooter } from './../../common/footer/CommonFooter';
import { useHistory } from 'react-router';
import ZoomTab from './ZoomTab';
const DoctorZoom = () => {
    const history = useHistory();
    return (
        <>
        <div className="middle-content view-profile">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="back-link">
                  <a onClick={history.goBack} class="btn-white-outline pr-7 pl-7 d-flex align-items-center mr-15"><span class="material-icons md-dark md-24">arrow_back</span></a>
                  <h1>Telehealth Video</h1>
                </div>
                    <section className="detail-info zoom">
                        <div className="profile-info">
                            <div className="row">
                                <div className="col-sm-12 col-md-12">
                                    <div className="box">
                                        <div className="card-row">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-12 profile-info">
                                            <div className="user-info">
                                                <div className="profile-img">
                                                <img src={PROFILEIMG}></img>
                                                </div>
                                                <div className="head">
                                                <div className="title-text">
                                                    <h1>Rodney Fuller</h1>
                                                    <div class="datetime"><span class="material-icons">event</span><p>Thursday June, 17 2021</p></div>
                                                </div>
                                                </div>
                                            </div>
                                            </div>
                                            <div className="col-lg-6 col-md-12 d-flex align-items-center">
                                            <div className="profile-info w-100">
                                                <div className="row">
                                                <div className="col-md-6 col-sm-6">
                                                    <label>Pronoun</label>
                                                    <div className="value">He/Him/His</div>
                                                </div>
                                                <div className="col-md-6 col-sm-6">
                                                    <label>TeleHealth</label>
                                                    <div className="value">Specialization</div>
                                                </div>
                                                
                                                </div>
                                            </div>
                                            </div>
                                            <div className="col-sm-2 d-flex align-items-center mb-20">
                                            <div className="action-status d-flex">
                                                <a class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8"><span class="material-icons md-dark md-18">email</span></a>
                                                <a class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8"><span class="material-icons md-dark md-18">event</span></a>
                                                <a class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8"><span class="material-icons md-dark md-18">description</span></a>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                        <div className="profile-info">
                                            <div className="row">
                                                <div className="col-md-3 col-sm-6">
                                                    <label>Age</label>
                                                    <div className="value">48 years</div>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <label>Sexual Orientation</label>
                                                    <div className="value">Gay, Queer</div>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <label>Height (Feet & Inch)</label>
                                                    <div className="value">6’1”</div>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <label>Weight* (lbs)</label>
                                                    <div className="value">176 lb</div>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <label>Gender Identity</label>
                                                    <div className="value">Cisgender man</div>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <label>Race</label>
                                                    <div className="value">Other</div>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <label>Ethnicity</label>
                                                    <div className="value">Non-Hispanic or Latino</div>
                                                </div>
                                                <div className="col-md-3 col-sm-6">
                                                    <label>Language Spoken</label>
                                                    <div className="value">American English, British English <span className="chip">2 More</span></div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </section>
                    <div className="zoom-container">
                        <div className="zoom-ui">

                        </div>
                        <div className="content-section">
                            <ZoomTab />
                        </div>
                    </div>
              </div>
            </div>
          </div>
        </div>

            <CommonFooter />
        </>
    )
}

export default DoctorZoom;
