import React, { useContext } from 'react';
import { ApplicationConstant } from '../../../constants/application.constant';
import UserRegistrationContext from '../../../contexts/user-registration/UserRegistrationContext';
import SelectElement from '../../common/formElements/SelectElement';
import update from 'immutability-helper';

function PatientPrefrence({ aPreferences }) {
  const [oUserRegistration, setUserRegistration] = useContext(UserRegistrationContext);

  const setValueByFieldName = (fieldname, value) => {
    setUserRegistration(update(oUserRegistration, { patient_profile: { [fieldname]: { $set: value } } }));
  };

  const onSelectInput = (name, e, secondKey) => {
    if(e.label.toLowerCase()===ApplicationConstant.PREFER_NOT_TO_DISCLOSE && secondKey){
      oUserRegistration.patient_profile[secondKey] = e;
    }
    setValueByFieldName(name, e); 
  };

  return (
    <div className="form-step">
      <div className="row">
        <h3 className="col-sm-12">{'What gender do you prefer your provider to be?*'}</h3>
        <SelectElement
          classNm={'col-sm-6'}
          name={'first_prefrence'}
          onChange={(e) => {
            onSelectInput('gender_prefrence_first', e, 'gender_prefrence_second');
          }}
          className="reactSelect"
          classNamePrefix="react-select"
          options={aPreferences?.gender.filter(
            (val) =>
              val.title?.toLowerCase() === ApplicationConstant.PREFER_NOT_TO_DISCLOSE?.toLowerCase() ||
              val.id !== oUserRegistration.patient_profile?.gender_prefrence_second?.id 
              
          )}
          value={oUserRegistration.patient_profile.gender_prefrence_first}
          isSearchable={ApplicationConstant.FALSE}
          placeholder={''}
          label={ApplicationConstant.FIRST_PREF}
        />
        <SelectElement
          classNm={'col-sm-6'}
          name={'second_prefrence'}
          onChange={(e) => {
            onSelectInput('gender_prefrence_second', e);
          }}
          className="reactSelect"
          classNamePrefix="react-select"
          options={aPreferences?.gender.filter(
            (val) => val.title?.toLowerCase() === ApplicationConstant.PREFER_NOT_TO_DISCLOSE?.toLowerCase() ||
            val.id !== oUserRegistration.patient_profile?.gender_prefrence_first?.id
          )}
          value={oUserRegistration.patient_profile.gender_prefrence_second}
          isSearchable={ApplicationConstant.FALSE}
          isDisabled={oUserRegistration.patient_profile?.gender_prefrence_first?.label?.toLowerCase() === ApplicationConstant.PREFER_NOT_TO_DISCLOSE}
          placeholder={''}
          label={ApplicationConstant.SECOND_PREF}
        />
        <h3 className="col-sm-12 mt-24">{'What age do you prefer your provider to be?*'}</h3>
        <SelectElement
          classNm={'col-sm-6'}
          name={'age_prefrence'}
          onChange={(e) => {
            onSelectInput('age_prefrence_first', e, 'age_prefrence_second');
          }}
          className="reactSelect"
          classNamePrefix="react-select"
          options={aPreferences?.age_range.filter(
            (val) => val.label?.toLowerCase() === ApplicationConstant.PREFER_NOT_TO_DISCLOSE?.toLowerCase() ||
            val.value !== oUserRegistration.patient_profile?.age_prefrence_second?.value
          )}
          value={oUserRegistration.patient_profile.age_prefrence_first}
          isSearchable={ApplicationConstant.FALSE}
          placeholder={''}
          label={ApplicationConstant.FIRST_PREF}
        />
        <SelectElement
          classNm={'col-sm-6'}
          name={'second_prefrence'}
          onChange={(e) => {
            onSelectInput('age_prefrence_second', e);
          }}
          className="reactSelect"
          classNamePrefix="react-select"
          options={aPreferences?.age_range.filter(
            (val) => val.label?.toLowerCase() === ApplicationConstant.PREFER_NOT_TO_DISCLOSE?.toLowerCase() ||
            val.value !== oUserRegistration.patient_profile?.age_prefrence_first?.value
          )}
          value={oUserRegistration.patient_profile.age_prefrence_second}
          isDisabled={oUserRegistration.patient_profile?.age_prefrence_first?.label?.toLowerCase() === ApplicationConstant.PREFER_NOT_TO_DISCLOSE}
          isSearchable={ApplicationConstant.FALSE}
          placeholder={''}
          label={ApplicationConstant.SECOND_PREF}
        />
        <h3 className="col-sm-12 mt-24">{'What race do you prefer your provider to be?*'}</h3>
        <SelectElement
          classNm={'col-sm-6'}
          name={'race_prefrence'}
          onChange={(e) => {
            onSelectInput('race_prefrence_first', e, 'race_prefrence_second');
          }}
          className="reactSelect"
          classNamePrefix="react-select"
          options={aPreferences?.races.filter(
            (val) =>
              val.name?.toLowerCase() === ApplicationConstant.PREFER_NOT_TO_DISCLOSE?.toLowerCase() ||
              val.id !== oUserRegistration.patient_profile?.race_prefrence_second?.id
          )}
          value={oUserRegistration.patient_profile.race_prefrence_first}
          isSearchable={ApplicationConstant.FALSE}
          placeholder={''}
          label={ApplicationConstant.FIRST_PREF}
        />
        <SelectElement
          classNm={'col-sm-6'}
          name={'second_prefrence'}
          onChange={(e) => {
            onSelectInput('race_prefrence_second', e);
          }}
          className="reactSelect"
          classNamePrefix="react-select"
          options={aPreferences?.races.filter(
            (val) => val.name?.toLowerCase() === ApplicationConstant.PREFER_NOT_TO_DISCLOSE?.toLowerCase() ||
            val.id !== oUserRegistration.patient_profile.race_prefrence_first.id
          )}
          value={oUserRegistration.patient_profile.race_prefrence_second}
          isDisabled={oUserRegistration.patient_profile?.race_prefrence_first?.label?.toLowerCase() === ApplicationConstant.PREFER_NOT_TO_DISCLOSE}
          isSearchable={ApplicationConstant.FALSE}
          placeholder={''}
          label={ApplicationConstant.SECOND_PREF}
        />
         <h3 className="col-sm-12 mt-24">{"What ethnicity do you prefer your provider to be?"}</h3>
        <SelectElement
          classNm={'col-sm-6'}
          name={'ethnicity_prefrence'}
          onChange={(e) => {
            onSelectInput('ethnicity_prefrence_first', e, 'ethnicity_prefrence_second');
          }}
          className="reactSelect"
          classNamePrefix="react-select"
          options={aPreferences?.ethnicity.filter(
            (val) => (val.label?.toLowerCase() === ApplicationConstant.PREFER_NOT_TO_DISCLOSE?.toLowerCase() ||
            val.id !== oUserRegistration.patient_profile?.ethnicity_prefrence_second?.id) &&
            val.label?.toLowerCase() !== "Not Hispanic and/or Latino/a/x".toLowerCase()
          )}
          value={oUserRegistration.patient_profile.ethnicity_prefrence_first}
          isSearchable={ApplicationConstant.FALSE}
          placeholder={''}
          label={ApplicationConstant.FIRST_PREF}
        />
        <SelectElement
          classNm={'col-sm-6'}
          name={'second_prefrence'}
          onChange={(e) => {
            onSelectInput('ethnicity_prefrence_second', e);
          }}
          className="reactSelect"
          classNamePrefix="react-select"
          options={aPreferences?.ethnicity.filter(
            (val) => (val.label?.toLowerCase() === ApplicationConstant.PREFER_NOT_TO_DISCLOSE?.toLowerCase() ||
            val.id !== oUserRegistration.patient_profile?.ethnicity_prefrence_first?.id) &&
            val.label?.toLowerCase() !== "Not Hispanic and/or Latino/a/x".toLowerCase()
          )}
          value={oUserRegistration.patient_profile.ethnicity_prefrence_second}
          isDisabled={oUserRegistration.patient_profile?.ethnicity_prefrence_first?.label?.toLowerCase() === ApplicationConstant.PREFER_NOT_TO_DISCLOSE}
          isSearchable={ApplicationConstant.FALSE}
          placeholder={''}
          label={ApplicationConstant.SECOND_PREF}
        />
        <h3 className="col-sm-12 mt-24">{"What language do you prefer your provider to speak?"}</h3>
        <SelectElement
          classNm={'col-sm-6'}
          name={'language_prefrence'}
          onChange={(e) => {
            onSelectInput('language_prefrence_first', e, 'language_prefrence_second');
          }}
          className="reactSelect"
          classNamePrefix="react-select"
          options={aPreferences?.language.filter(
            (val) => val.label?.toLowerCase() === ApplicationConstant.PREFER_NOT_TO_DISCLOSE?.toLowerCase() ||
            val.id !== oUserRegistration.patient_profile?.language_prefrence_second?.id
          )}
          value={oUserRegistration.patient_profile.language_prefrence_first}
          isSearchable={ApplicationConstant.FALSE}
          placeholder={''}
          label={ApplicationConstant.FIRST_PREF}
        />
        <SelectElement
          classNm={'col-sm-6'}
          name={'second_prefrence'}
          onChange={(e) => {
            onSelectInput('language_prefrence_second', e);
          }}
          className="reactSelect"
          classNamePrefix="react-select"
          options={aPreferences?.language.filter(
            (val) => val.label?.toLowerCase() === ApplicationConstant.PREFER_NOT_TO_DISCLOSE?.toLowerCase() ||
            val.id !== oUserRegistration.patient_profile?.language_prefrence_first?.id
          )}
          value={oUserRegistration.patient_profile.language_prefrence_second}
          isDisabled={oUserRegistration.patient_profile?.language_prefrence_first?.label?.toLowerCase() === ApplicationConstant.PREFER_NOT_TO_DISCLOSE}
          isSearchable={ApplicationConstant.FALSE}
          placeholder={''}
          label={ApplicationConstant.SECOND_PREF}
        />
        <h3 className="col-sm-12 mt-24">{'What sexual orientation do you prefer your provider to be?*'}</h3>
        <SelectElement
          classNm={'col-sm-6'}
          name={'orentation_prefrence'}
          onChange={(e) => {
            onSelectInput('orentation_prefrence_first', e, 'orentation_prefrence_second');
          }}
          className="reactSelect"
          classNamePrefix="react-select"
          options={aPreferences?.sexual_orientations.filter(
            (val) => val.name?.toLowerCase() === ApplicationConstant.PREFER_NOT_TO_DISCLOSE?.toLowerCase() ||
              val.id !== oUserRegistration.patient_profile?.orentation_prefrence_second?.id
          )}
          value={oUserRegistration.patient_profile.orentation_prefrence_first}
          isSearchable={ApplicationConstant.FALSE}
          placeholder={''}
          label={ApplicationConstant.FIRST_PREF}
          maxMenuHeight={100}
        />
        <SelectElement
          classNm={'col-sm-6'}
          name={'second_prefrence'}
          onChange={(e) => {
            onSelectInput('orentation_prefrence_second', e);
          }}
          className="reactSelect"
          classNamePrefix="react-select"
          options={aPreferences?.sexual_orientations.filter(
            (val) => val.name?.toLowerCase() === ApplicationConstant.PREFER_NOT_TO_DISCLOSE?.toLowerCase() ||
            val.id !== oUserRegistration.patient_profile?.orentation_prefrence_first?.id
          )}
          value={oUserRegistration.patient_profile.orentation_prefrence_second}
          isDisabled={oUserRegistration.patient_profile?.orentation_prefrence_first?.label?.toLowerCase() === ApplicationConstant.PREFER_NOT_TO_DISCLOSE}
          isSearchable={ApplicationConstant.FALSE}
          placeholder={''}
          label={ApplicationConstant.SECOND_PREF}
          maxMenuHeight={100}
        />
      </div>
    </div>
  );
}

export default PatientPrefrence;
