import React from 'react';
import { AVATAR_PLACEHOLDER } from '../../../constants/image';

export const Image = (props) => {
  return (
    <>
      <img {...props} onError={(e) => (e.target.src = AVATAR_PLACEHOLDER)} alt='img'/>
    </>
  );
};
