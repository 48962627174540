import React, {useState} from 'react'
import { Button, FormGroup, Label, Input, InputGroup, Tooltip, Table } from 'reactstrap';
import FormSelect from './FormSelect';
import { PROFILEIMG, Calendar, DOLLAR_ICON } from '../../../constants/image';
import FormButton from './FormButton';
import ProfileEditModal from './ProfileEditModal';
import { useHistory } from 'react-router';
import StartAppointmentModal from './StartAppointmentModal';
import AppointmentConfirmedModal from './AppointmentConfirmedModal';
import PendingAppointmentModal from './PendingAppointmentModal';
import CommonDropdown from './CommonDropdown';

function PatientEditProfile() {
  const history = useHistory();
  const [navOpen, setNavOpen] = React.useState(false)
  const [toggleQuestion, setToggequestion] = useState(1);//1 is the default id to be opened by default
  console.log("navOpen", navOpen)

  const [isOpen, setModalOpen] = useState(false);
  const startAppointmentModal = () => setModalOpen(!isOpen);
  const appointmentConfirmedModal = () => setModalOpen(!isOpen);
  const pendingAppointmentModal = () => setModalOpen(!isOpen);
  
  return (
    <div className="middle-content edit-profile">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="back-link">
              <a onClick={history.goBack} class="btn-white-outline pr-7 pl-7 d-flex align-items-center mr-15"><span class="material-icons md-dark md-24">arrow_back</span></a>
              <h1>Account Information</h1>
              
            </div>
          </div>
          <div className="col-md-4">
            <div className={navOpen ? 'edit-nav open' : 'edit-nav'}>
              <ul>
                <li><span class="material-icons">mic</span> <span className="name">Accessibility Settings</span></li>
                <li className="active"><span class="material-icons">place</span> <span className="name">Account Information</span></li>
                <li><span class="material-icons">account_circle</span> <span className="name">User Profile</span></li>
                <li><span class="material-icons">description</span> <span className="name">Prescription</span></li>
                <li><span class="material-icons">shopping_cart</span> <span className="name">Purchase Details</span></li>
                <li><span class="material-icons-outlined">logout</span> <span className="name">Sign Out</span></li>
              </ul>
              <a class="nav-toggal" onClick={() => { setNavOpen(!navOpen) }}>
                <span class="material-icons">{navOpen ? 'chevron_left' : 'chevron_right'}</span>
              </a>
            </div>
          </div>
          <div className="col-md-8">
            <div className="pages">
              <div className="profile-information">
                <div className="form-step max-w-100">

                  <form>
                      <h3>Personal Information</h3>
                      <div className="row">
                        <div className="col-sm-6">
                          <InputGroup>
                            <div className="label-float">
                              <Input type="text" placeholder="Please Ender" />
                              <label>First Name*</label>
                            </div>
                          </InputGroup>
                          </div>
                          <div className="col-sm-6">
                            <InputGroup>
                              <div className="label-float">
                                <Input type="text" placeholder="Please Ender" />
                                <label>Last Name*</label>
                              </div>
                          </InputGroup>
                          </div>
                          <div className="col-sm-6">
                            <InputGroup>
                              <div className="label-float">
                                <Input type="text" placeholder="Please Ender" />
                                <label>Preferred Name</label>
                              </div>
                            </InputGroup>
                          </div>
                          <div className="col-sm-6">
                            <InputGroup>
                              <div className="label-float">
                                <Input type="text" placeholder="Please Ender" />
                                <label>Date of Birth*</label>
                              </div>
                            </InputGroup>
                          </div>
                        </div>
                        <h3 className="mt-24">Contact Information</h3>
                        <div className="row">
                          <div className="col-sm-6">
                            <InputGroup>
                              <div className="label-float">
                                <Input type="text" placeholder="Please Ender" />
                                <label>Email Address*</label>
                              </div>
                            </InputGroup>
                          </div>
                          <div className="col-sm-6">
                            <InputGroup>
                              <div className="label-float">
                                <Input type="text" placeholder="Please Ender" />
                                <label>Phone Number*</label>
                              </div>
                            </InputGroup>
                        </div>
                      </div>
                      <h3 className="mt-24">Location</h3>
                      <div className="row">
                        <div className="col-sm-6">
                          <InputGroup>
                            <div className="label-float">
                              <Input type="text" placeholder="Please Ender" />
                              <label>Street Address</label>
                            </div>
                          </InputGroup>
                        </div>
                        <div className="col-sm-6">
                          <InputGroup>
                            <div className="label-float">
                              <Input type="text" placeholder="Please Ender" />
                              <label>Apartment</label>
                            </div>
                          </InputGroup>
                        </div>
                        <div className="col-sm-6">
                          <InputGroup>
                            <div className="label-float">
                              <Input type="text" placeholder="Please Ender" />
                              <label>City*</label>
                            </div>
                          </InputGroup>
                        </div>
                        <div className="col-sm-6">
                          <InputGroup>
                            <div className="label-float">
                              <Input type="text" placeholder="Please Ender" />
                              <label>State*</label>
                            </div>
                          </InputGroup>
                        </div>
                        <div className="col-sm-12">
                          <InputGroup>
                            <div className="label-float">
                              <Input type="text" placeholder="Please Ender" />
                              <label>Zip Code*</label>
                            </div>
                          </InputGroup>
                        </div>
                    </div>
                    <div className="btn-group justify-content-end w-100 pt-10">
                      <FormButton buttonLabel = "CANCEL" buttonClassName="btn mr-15 btn-secondary" />
                      <FormButton buttonLabel = "SAVE" buttonClassName="btn btn-primary" />
                    </div>
                    
                  </form>
                </div>
              </div>
              <div className="profile-summary">
                <div className="form-step max-w-100 summary">
                  <form className="w-100">
                    <div className="detail">
                      <div className="number-heading">1. Pronoun</div>
                      <div className="content">
                        <ul className="chip-value-list">
                          <li>Other</li>
                        </ul>
                        {/* <span class="material-icons md-20">edit</span> */}
                        <ProfileEditModal buttonLabel={'edit'} modalClassName={'right'} />
                      </div>
                    </div>
                    <div className="detail">
                      <div className="number-heading">2. Biometric Information</div>
                      <div className="content">
                        <ul className="value-list">
                          <li>Weight (Lbs) : 185.188</li>
                          <li>Height (Feet & Inch) : 5’8”</li>
                          <li>Age : 25</li>
                        </ul>
                        {/* <span class="material-icons md-20">edit</span> */}
                        <ProfileEditModal buttonLabel={'edit'} modalClassName={'right'} />
                      </div>
                    </div>
                    <div className="detail">
                      <div className="number-heading">3. Race</div>
                      <div className="content">
                        <span>-</span>
                        {/* <span class="material-icons md-20">edit</span> */}
                        <ProfileEditModal buttonLabel={'edit'} modalClassName={'right'} />
                      </div>
                    </div>
                    <div className="detail">
                      <div className="number-heading">4. Ethnicity</div>
                      <div className="content">
                        <ul className="chip-value-list">
                          <li>Not Hispanic or Latino</li>
                        </ul>
                        {/* <span class="material-icons md-20">edit</span> */}
                        <ProfileEditModal buttonLabel={'edit'} modalClassName={'right'} />
                      </div>
                    </div>
                    <div className="detail">
                      <div className="number-heading">5. Languages Spoken</div>
                      <div className="content">
                        <ul className="chip-value-list">
                          <li>English (British)</li>
                          <li>American Sign Language</li>
                          <li>Bantu</li>
                          <li>Bengali</li>
                        </ul>
                        <span class="material-icons md-20">edit</span>
                      </div>
                    </div>
                    <div className="detail">
                      <div className="number-heading">6. How would you identify yourself?</div>
                      <div className="content">
                        <span>-</span>
                        <span class="material-icons md-20">edit</span>
                      </div>
                    </div>
                    <div className="detail">
                      <div className="number-heading">7. Sexual Orientation</div>
                      <div className="content">
                        <ul className="chip-value-list">
                          <li>Lesbian</li>
                        </ul>
                        <span class="material-icons md-20">edit</span>
                      </div>
                    </div>
                    <div className="detail">
                      <div className="number-heading">8. How Can eMD support you living your a healthiest and most authentic life?</div>
                      <div className="content">
                        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.</p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              
              <div className="appointments">
                <div className="profile-info">
                  <div className="box">
                    <div className="user-info">
                      <div className="profile-img active">
                        <img src={PROFILEIMG}></img>
                      </div>
                      <div className="head">
                        <div className="title-text">
                          <h1 className="font-500 font-16">Rodney Fuller</h1>
                          <h2>Psychologist <span>Available</span></h2>
                          <h3>Patient Rating <span className="star-rating"> (star rating here!!!)</span></h3>
                          <h3>Matching <span className="matching font-600 font-14 theme-color"> 90%</span></h3>
                          <div className="datetime">
                            <span className="material-icons">event</span><p>Thursday June, 17 2021 | 08:00 AM- 09:00 AM</p>
                          </div>
                        </div>
                        <div className="action-status">
                          <FormButton buttonLabel = "Start Session" buttonClassName="btn mb-15 btn-primary small" />
                          <FormButton buttonLabel = "Cancel" buttonClassName="btn-link small" />
                        </div>
                      </div>
                    </div>
                    <div className="user-info">
                      <div className="profile-img active">
                        <img src={PROFILEIMG}></img>
                      </div>
                      <div className="head">
                        <div className="title-text">
                          <h1 className="font-500 font-16">Rodney Fuller</h1>
                          <h2>Psychologist <span>Available</span></h2>
                          <h3>Patient Rating <span className="star-rating"> (star rating here!!!)</span></h3>
                          <h3>Matching <span className="matching font-600 font-14 theme-color"> 90%</span></h3>
                          <div className="datetime">
                            <span className="material-icons">event</span><p>Thursday June, 17 2021 | 08:00 AM- 09:00 AM</p>
                          </div>
                        </div>
                        <div className="action-status">
                          <FormButton buttonLabel = "Start Session" buttonClassName="btn mb-15 btn-primary small" />
                          <FormButton buttonLabel = "Cancel" buttonClassName="btn-link small" />
                        </div>
                      </div>
                    </div>
                    <div className="user-info">
                      <div className="profile-img active">
                        <img src={PROFILEIMG}></img>
                      </div>
                      <div className="head">
                        <div className="title-text">
                          <h1 className="font-500 font-16">Rodney Fuller</h1>
                          <h2>Psychologist <span style={{color:"#cf342d"}}>Unavailable</span></h2>
                          <h3>Patient Rating <span className="star-rating"> (star rating here!!!)</span></h3>
                          <h3>Matching <span className="matching font-600 font-14 theme-color"> 90%</span></h3>
                          <div className="datetime">
                            <span className="material-icons">event</span><p>Thursday June, 17 2021 | 08:00 AM- 09:00 AM</p>
                          </div>
                        </div>
                        <div className="action-status">
                          <div className="panding-req mb-15">Request Panding </div>
                          <FormButton buttonLabel = "Cancel" buttonClassName="btn-link small" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="purchase-card">
                <div className=" profile-info">
                  <div className="box">
                    <div className="user-info">
                      <div className="profile-img active">
                        <img src={PROFILEIMG}></img>
                      </div>
                      <div className="head">
                        <div className="title-text">
                          <h1 className="font-500 font-16">Rodney Fuller</h1>
                          <h2>Psychologist <span>Available</span></h2>
                          <h3>Patient Rating <span className="star-rating"> (star rating here!!!)</span></h3>
                          <h3>Matching <span className="matching font-600 font-14 theme-color"> 90%</span></h3>
                        </div>
                       <div className="price-sec">
                         <div>
                           <div>
                            <label>TeleHealth</label>
                            <div className="value">Specialization</div>
                           </div>
                           <div>
                            <label>Accept Insurance</label>
                            <span class="material-icons theme-color">
                            check_circle
                            </span>
                           </div>
                         </div>
                         <div className="price">
                           <h5><span>$</span>355</h5>
                           <p>Hourly Rate</p>
                         </div>
                       </div>
                      </div>
                    </div>
                    <div className="action-status">
                          <FormButton buttonLabel = "Purchase" buttonClassName="btn mb-15 btn-primary small" />
                        </div>
                  </div>
                  <div className="box">
                    <div className="user-info">
                      <div className="profile-img active">
                        <img src={PROFILEIMG}></img>
                      </div>
                      <div className="head">
                        <div className="title-text">
                          <h1 className="font-500 font-16">Rodney Fuller</h1>
                          <h2>Psychologist <span>Available</span></h2>
                          <h3>Patient Rating <span className="star-rating"> (star rating here!!!)</span></h3>
                          <h3>Matching <span className="matching font-600 font-14 theme-color"> 90%</span></h3>
                        </div>
                       <div className="price-sec">
                         <div>
                           <div>
                            <label>TeleHealth</label>
                            <div className="value">Specialization</div>
                           </div>
                           <div>
                            <label>Accept Insurance</label>
                            <span class="material-icons theme-color">
                            check_circle
                            </span>
                           </div>
                         </div>
                         <div className="price">
                           <h5>5/5</h5>
                           <p>Telehealth Session Used</p>
                         </div>
                       </div>
                      </div>
                    </div>
                    <div className="action-status">
                          <FormButton buttonLabel = "View Membership" buttonClassName="btn mb-15 btn-primary small" />
                        </div>
                  </div>
                  <div className="box">
                    <div className="user-info">
                      <div className="profile-img active">
                        <img src={PROFILEIMG}></img>
                      </div>
                      <div className="head align-items-start">
                        <div className="title-text">
                          <h1 className="font-500 font-16">Rodney Fuller</h1>
                          <h2>Psychologist <span>Available</span></h2>
                          <h3>Patient Rating <span className="star-rating"> (star rating here!!!)</span></h3>
                          <h3>Matching <span className="matching font-600 font-14 theme-color"> 90%</span></h3>
                        </div>
                       <div className="price-sec">
                         <div className="d-flex flex-1 justify-content-between">
                           <div>
                            <label>TeleHealth</label>
                            <div className="value">Specialization</div>
                           </div>
                           <div>
                            <label>Accept Insurance</label>
                            <span class="material-icons theme-color">
                            check_circle
                            </span>
                           </div>
                         </div>
                       </div>
                      </div>
                    </div>
                    <div className="action-status d-flex">
                          <FormButton buttonLabel = "See Doctor Now" buttonClassName="btn mr-15 btn-secondary small" />
                          <FormButton buttonLabel = "Schedule Appointment" buttonClassName="btn mb-15 btn-primary small" />
                        </div>
                  </div>
                </div>
              </div>

              <div className="history-and-records">
                <div className=" profile-info">
                  <div className="box">
                    <div className="user-info">
                      <div className="profile-img active">
                        <img src={PROFILEIMG}></img>
                      </div>
                      <div className="head">
                        <div className="title-text">
                          <h1 className="font-500 font-16">Rodney Fuller</h1>
                          <h2>Psychologist <span>Available</span></h2>
                          <h3>Patient Rating <span className="star-rating"> (star rating here!!!)</span></h3>
                          <h3>Matching <span className="matching font-600 font-14 theme-color"> 90%</span></h3>
                        </div>
                       <div className="price-sec">
                         <div>
                           <div>
                            <label>TeleHealth</label>
                            <div className="value">Specialization</div>
                           </div>
                           <div>
                            <label>Accept Insurance</label>
                            <span class="material-icons theme-color">
                            check_circle
                            </span>
                           </div>
                         </div>
                         <div className="price">
                          <div class="panding-req">Thursday June, 17 2021 </div>
                         </div>
                       </div>
                      </div>
                    </div>
                    <div class="button-row">
                      <span onClick={() => setToggequestion(1)} class="view-history">View History <span class="material-icons-outlined">{toggleQuestion === 1 ? "expand_less" : "expand_more"}</span></span>
                      <div class="action-status d-flex">
                        <a class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8">
                          <span class="material-icons md-dark md-18">favorite</span>
                        </a>
                        <a class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8">
                          <span class="material-icons md-dark md-18">email</span>
                        </a>
                        <a class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8">
                          <span class="material-icons md-dark md-18">today</span>
                        </a>
                        <a class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8">
                          <span class="material-icons md-dark md-18">description</span>
                        </a>
                        <a class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8">
                          <span class="material-icons md-dark md-18">visibility_off</span>
                        </a>
                        <a class="btn-white-outline pr-10 pl-10 d-flex align-items-center">
                          <span class="material-icons md-dark md-18">share</span>
                        </a>
                      </div>
                    </div>
                    <div className="body profile-info" isOpen={toggleQuestion === 1 ? true : false}>
                      <div className="row">
                          <div className="col-lg-3 col-md-6 col-sm-12">
                            <label>Prescriptions</label>
                            <div className="value">Psychologist</div>
                            <div className="value">Rating</div>
                            <div className="value">Matching</div>
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-12">
                            <label>Wellness</label>
                            <div className="value">Psychologist</div>
                            <div className="value">Rating</div>
                            <div className="value">Matching</div>
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-12">
                            <label>Resources</label>
                            <div className="value">Psychologist</div>
                            <div className="value">Rating</div>
                            <div className="value">Matching</div>
                          </div>
                          <div className="col-lg-3 col-md-6 col-sm-12">
                            <label>Referrals</label>
                            <div className="value">Psychologist</div>
                            <div className="value">Rating</div>
                            <div className="value">Matching</div>
                          </div>
                        </div>
                      
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="font-500 font-16 mb-10">Notes</div>
                          <p className="font-14 font-400 gray666 mb-0">Nunc molestie erat at euismod mollis. Integer luctus purus a nulla laoreet elementum. Mauris id sollicitudin libero. Morbi sed augue eu diam posuere pulvinar vitae sit amet purus. Vivamus libero ipsum, vehicula vitae quam vehicula, malesuada lacinia orci. Phasellus justo quam, ultricies eget viverra id, malesuada eget nulla. Interdum et malesuada fames ac ante ipsum primis in faucibus. Phasellus id dolor sed ante placerat suscipit. In quis convallis elit.</p>
                        </div>
                      </div>
                      <div className="row">
                      <div className="col-sm-12">
                        <div className="payment-button">
                          <div>
                            <div className="font-500 font-16 mb-10">Payments</div>
                            <div className="font-14 font-400 gray666">Membership / Silver</div>
                            <div className="font-14 font-400 gray666">Covered by Insurance</div>
                            <div className="font-14 font-400 gray666">Paid for out of Pocket</div>
                          </div>
                          <div className="action-status">
                            <FormButton buttonLabel = "View Super Bill" buttonClassName="btn btn-secondary" />
                          </div>
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* payment-section */}
              <div className="payment-section">
                <div className="card-sec">
                  <h3>Personal Information</h3>
                  <div className="go-to-stripe">
                    <p>Please set up a Stripe account in order to receive payment</p>
                    <button class="btn btn-primary">Go to stripe</button>
                  </div>
                </div>
                <div className="card-sec">
                  <h3>Membership Information</h3>
                  <div class="box">
                    <div className="not-a-member">
                      <img src={DOLLAR_ICON} alt="" />
                      <div className="info">
                        <h4>You are currently not a member</h4>
                        <p>The first month is <strong>50% off</strong>, Full Price after the first 90 calendar days.</p>
                        <span>The credit card used will be billed full price every month from time of purchase.</span>
                      </div>
                    </div>     
                    <div className="monthly-price">
                      <div className="price">
                        <strong>$</strong>325<span>Monthly</span>
                      </div>
                      <ul>
                        <li>
                          <span className="material-icons">event</span>
                          <p>1 Included telehealth consultation</p>
                        </li>
                        <li>
                          <span className="material-icons">account_circle</span>
                          <p>Selectable Profile </p>
                        </li>
                        <li>
                          <span className="material-icons">local_shipping</span>
                          <p>Free Shipping</p>
                        </li>
                      </ul>
                      <div className="info">
                        <p>The first month is <strong>50% off</strong>, Full Price after the first 90 calendar days.</p>
                        <span>The credit card used will be billed full price every month from time of purchase.</span>
                      </div>
                    </div>   
                    <div className="d-flex align-items-center justify-content-between btn-sec">
                      <a href="">See Terms of Agreement</a>
                      <button class="btn btn-primary">Sign up!</button>
                    </div>          
                    
                  </div>
                </div>
              </div>

              {/* payment-transaction */}
              <div className="payment-transaction">
                <div className="card-sec">
                  <div className="box">
                    <div className="icon-bg">
                      <span className="material-icons">monetization_on</span>
                    </div>
                    <div className="info">
                      <h4>$550</h4>
                      <strong>Earnings</strong>
                      <p>Year to date</p>
                    </div>
                  </div>
                  <div className="box">
                    <div className="icon-bg">
                      <span className="material-icons">monetization_on</span>
                    </div>
                    <div className="info">
                      <h4>105</h4>
                      <strong>Appointments Taken</strong>
                      <p>Year to date</p>
                    </div>
                  </div>
                  <div className="box">
                    <div className="icon-bg">
                      <span className="material-icons">monetization_on</span>
                    </div>
                    <div className="info">
                      <h4>35</h4>
                      <strong>Patients Served</strong>
                      <p>Year to date</p>
                    </div>
                  </div>
                </div>
                <div className="data-not-found">
                  <div className="content">
                  <div className="icon-bg">
                    <span className="material-icons">credit_card</span>
                  </div>
                  <h2>You have not made any payment yet</h2>
                  </div>
                </div>
                <section className="payment-list-section">
                  <div className="filter-row">
                      <div className="month">
                        <div className="check-btn m-0 mt--15">
                          <label className="label--checkbox flat d-flex">
                            <input type="checkbox" className="checkbox" id="1" />
                            <label className="font-16 font-500" htmlFor="1"></label>
                          </label>
                        </div>
                        <span className="ml-10">Month</span>
                      </div>
                      <div className="action-status d-flex row-action-status">
                          <a className="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-15">
                            <span className="material-icons md-dark md-18">share</span>
                          </a>
                          <button class="btn btn-secondary small min-w-auto">Filter</button>
                        </div>
                  </div>
                {/* repeat list */}
                  <div className="list">
                    <div className="month-row">
                      <div className="month">
                        <div className="check-btn m-0 mt--15">
                          <label className="label--checkbox flat d-flex">
                            <input type="checkbox" className="checkbox" id="1" />
                            <label className="font-16 font-500" htmlFor="1"></label>
                          </label>
                        </div>
                      <span className="ml-10">September 2021</span>
                      </div>
                      <div className="visits">
                        <span className="mr-15">15 total visits</span>
                        <div className="action-status d-flex row-action-status">
                          <a className="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-0">
                            <span className="material-icons md-dark md-18">expand_more</span>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="table-section">
                    <div className="total-refunds">
                      <ul>
                        <li>Total Monthly Refunds <strong>$1780</strong></li>
                        <li>Total Monthly Payments <strong>$1780</strong></li>
                      </ul>
                    </div>
                    <div className="box">
                      <div className="mobile-checkbox">
                          <div className="check-btn d-flex m-0">
                            
                            <label className="label--checkbox flat d-flex">
                              <input type="checkbox" className="checkbox" id="1" />
                              <label className="font-16 font-500" htmlFor="1"></label>
                            </label>
                            <span className="mt-15">Select All</span>
                          </div>
                        </div>
                      <div class="divTable payment-table">
                        <div class="headRow">
                            <div class="divCell">
                              <div className="check-btn m-0">
                                <label className="label--checkbox flat d-flex">
                                  <input type="checkbox" className="checkbox" id="1" />
                                  <label className="font-16 font-500" htmlFor="1"></label>
                                </label>
                              </div>
                            </div>
                            <div  class="divCell"> 
                              <strong>Patient </strong>
                              <span className="material-icons">unfold_more</span>
                            </div>
                            <div  class="divCell">
                              <strong>Session Fee </strong>
                              <span className="material-icons">unfold_more</span>
                            </div>
                            <div  class="divCell">
                              <strong>Platform Fee </strong>
                              <span className="material-icons">unfold_more</span>
                            </div>
                            <div  class="divCell">
                              <strong>Net to provider </strong>
                              <span className="material-icons">unfold_more</span>
                            </div>
                            <div  class="divCell">
                              <strong>Date </strong>
                              <span className="material-icons">unfold_more</span>
                            </div>
                            <div  class="divCell">
                              <strong>Action </strong>
                              <span className="material-icons">unfold_more</span>
                            </div>
                            
                        </div>
                        {/* repeat sub-accoridon */}
                        <div className="sub-accoridon">
                          <div class="divRow">
                            <div class="divCell">
                              <div className="check-btn m-0">
                                <label className="label--checkbox flat d-flex">
                                  <input type="checkbox" className="checkbox" id="1" />
                                  <label className="font-16 font-500" htmlFor="1"></label>
                                </label>
                              </div>
                            </div>
                            <div class="divCell" data-label="Patient">Patrick</div>
                            <div class="divCell" data-label="Session Fee">$95.00</div>
                            <div class="divCell" data-label="Platform Fee">$25.00</div>
                            <div class="divCell" data-label="Net to provider">- $70.00</div>
                            <div class="divCell" data-label="Date">Sept. 04, 2021</div>
                            <div class="divCell" data-label="Action">
                              <div className="action-status d-flex row-action-status">
                                  <a className="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8">
                                    <span className="material-icons md-dark md-18">picture_as_pdf</span>
                                  </a>
                                  <a className="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-0">
                                    <span className="material-icons md-dark md-18">expand_more</span>
                                  </a>
                                </div>
                            </div>                          
                          </div>
                          <div className="profile-info ml-40">
                            <div className="row">
                              <div className="col-md-3 col-sm-6">
                                <label>Transaction ID</label>
                                <div className="value">ID15325</div>
                              </div>
                              <div className="col-md-3 col-sm-6">
                                <label>Canceled appointment</label>
                                <div className="value">- $70.00</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="sub-accoridon">
                          <div class="divRow">
                            <div class="divCell">
                              <div className="check-btn m-0">
                                <label className="label--checkbox flat d-flex">
                                  <input type="checkbox" className="checkbox" id="1" />
                                  <label className="font-16 font-500" htmlFor="1"></label>
                                </label>
                              </div>
                            </div>
                            <div class="divCell" data-label="Patient">Patrick</div>
                            <div class="divCell" data-label="Session Fee">$95.00</div>
                            <div class="divCell" data-label="Platform Fee">$25.00</div>
                            <div class="divCell" data-label="Net to provider">- $70.00</div>
                            <div class="divCell" data-label="Date">Sept. 04, 2021</div>
                            <div class="divCell" data-label="Action">
                              <div className="action-status d-flex row-action-status">
                                  <a className="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8">
                                    <span className="material-icons md-dark md-18">picture_as_pdf</span>
                                  </a>
                                  <a className="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-0">
                                    <span className="material-icons md-dark md-18">expand_more</span>
                                  </a>
                                </div>
                            </div>                          
                          </div>
                          <div className="profile-info ml-40">
                            <div className="row">
                              <div className="col-md-3 col-sm-6">
                                <label>Transaction ID</label>
                                <div className="value">ID15325</div>
                              </div>
                              <div className="col-md-3 col-sm-6">
                                <label>Canceled appointment</label>
                                <div className="value">- $70.00</div>
                              </div>
                            </div>
                          </div>
                        </div>
                       
                      </div>
                    </div>
                  </div>
                  </div>
                  
                 </section>
              </div>
              
              {/* Patient Payment History */}
              <div className="patient-payment-history">
                <div className="data-not-found">
                  <div className="content">
                    <div className="icon-bg">
                      <span className="material-icons">credit_card</span>
                    </div>
                    <h2>You have not made any payment yet</h2>
                  </div>
                </div>
                {/* list */}
                <div className="table-list">
                  <div class="divTable patient-payment-table">
                        <div class="headRow">
                            <div  class="divCell"> 
                              <strong>Name </strong>
                              <span className="material-icons">unfold_more</span>
                            </div>
                            <div  class="divCell">
                              <strong>Source </strong>
                              <span className="material-icons">unfold_more</span>
                            </div>
                            <div  class="divCell">
                              <strong>Date </strong>
                              <span className="material-icons">unfold_more</span>
                            </div>
                            <div  class="divCell">
                              <strong>Consultation Fee </strong>
                              <span className="material-icons">unfold_more</span>
                            </div>
                            <div  class="divCell">
                              <strong>Action </strong>
                              <span className="material-icons">unfold_more</span>
                            </div>
                            
                        </div>
                        {/* repeat sub-accoridon */}
                          <div class="divRow">
                            <div class="divCell" data-label="Name">Dr. Patel</div>
                            <div class="divCell" data-label="Source">Video Appointment</div>
                            <div class="divCell" data-label="Date">Sept. 04, 2021</div>
                            <div class="divCell" data-label="Consultation Fee">- $70.00</div>
                            <div class="divCell" data-label="Action">
                              <div className="action-status d-flex row-action-status">
                                  <a className="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8">
                                    <span className="material-icons md-dark md-18">picture_as_pdf</span>
                                  </a>
                                </div>
                            </div>                          
                          </div>                    
                          <div class="divRow">
                            <div class="divCell" data-label="Name">Dr. Patel</div>
                            <div class="divCell" data-label="Source">Video Appointment</div>
                            <div class="divCell" data-label="Date">Sept. 04, 2021</div>
                            <div class="divCell" data-label="Consultation Fee">- $70.00</div>
                            <div class="divCell" data-label="Action">
                              <div className="action-status d-flex row-action-status">
                                  <a className="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8">
                                    <span className="material-icons md-dark md-18">picture_as_pdf</span>
                                  </a>
                                </div>
                            </div>                          
                          </div>     
                      </div>
                    </div>
              </div>
              {/* Patient Payment History */}
                <div onClick={startAppointmentModal}>
                  <span>Start Appointment Modal </span>
                  <StartAppointmentModal
                    primaryButtonText={"Okay"}
                    setModelOpen={startAppointmentModal}
                    isOpen={isOpen}
                    modalClassName="start-appointment"
                  />
                </div>
                <div onClick={appointmentConfirmedModal}>
                  <span>Appointment Confirmed Modal </span>
                  <AppointmentConfirmedModal
                    secondaryButonText={"Cancel"}
                    primaryButtonText={"Save"}
                    setModelOpen={appointmentConfirmedModal}
                    isOpen={isOpen}
                    modalClassName="appointment-confirmed"
                  />
                </div>
                <div onClick={pendingAppointmentModal}>
                  <span>Pending Appointment Modal </span>
                  <PendingAppointmentModal
                    primaryButtonText={"Save"}
                    setModelOpen={pendingAppointmentModal}
                    isOpen={isOpen}
                    modalClassName="pending-appointment"
                  />
                </div>
            </div>
          </div>
        </div>
        
        
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <div className="container">
        <div className="row">
        <div className="col-md-6">
          <section className="my-matched-section">
            <div className="heading-link align-items-center">
              <h5>All Appointments</h5>
              <div className="dropdown-btn">
                <CommonDropdown />
              </div>
            </div>
            <div className="all-appointments">
                <div className="profile-info">
                  <div className="box">
                  <h1 className="font-500 font-16 mb-30">Pending Appointments</h1>
                    <div className="user-info">
                      <div className="profile-img active">
                        <img src={PROFILEIMG}></img>
                      </div>
                      <div className="head">
                        <div className="title-text">
                          <h1 className="font-500 font-16">Rodney Fuller</h1>
                          <h3>Rating <span className="star-rating"> (star rating here!!!)</span></h3>
                          <h3>Appointment:  In Person</h3>
                          <div className="datetime">
                            <span className="material-icons">event</span><p>June, 17 2021 in 1 hr</p>
                          </div>
                        </div>
                        <div className="action-status justify-content-end align-items-end">
                          <div class="d-flex mb-15 ">
                            <a class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8">
                              <span class="material-icons md-dark md-18">favorite</span>
                            </a>
                            <a class="btn-white-outline pr-10 pl-10 d-flex align-items-center">
                              <span class="material-icons md-dark md-18">email</span>
                            </a>
                          </div>
                          <FormButton buttonLabel = "Go to Appointment" buttonClassName="btn mb-15 btn-primary small" />
                        </div>
                      </div>
                    </div>
                    <h1 className="font-500 font-16 mb-30">Upcoming Appoinments </h1>
                    <div className="user-info">
                      <div className="profile-img active">
                        <img src={PROFILEIMG}></img>
                      </div>
                      <div className="head">
                        <div className="title-text">
                          <h1 className="font-500 font-16">Rodney Fuller</h1>
                          <h3>Rating <span className="star-rating"> (star rating here!!!)</span></h3>
                          <h3>Appointment:  In Person</h3>
                          <div className="datetime">
                            <span className="material-icons">event</span><p>June, 17 2021 in 1 hr</p>
                          </div>
                        </div>
                        <div className="action-status justify-content-end align-items-end">
                          <div class="d-flex mb-15 ">
                            <a class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8">
                              <span class="material-icons md-dark md-18">favorite</span>
                            </a>
                            <a class="btn-white-outline pr-10 pl-10 d-flex align-items-center">
                              <span class="material-icons md-dark md-18">email</span>
                            </a>
                          </div>
                          <FormButton buttonLabel = "Pending" buttonClassName="btn mb-15 btn-primary green-btn small" />
                          
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
          </section>
        </div>
        <div className="col-md-6">
          <section className="my-matched-section">
            <div className="heading-link">
              <h5>My Calendar</h5>
            </div>
            <div className="card-box">
              <div className="calender-box">
                <img className="w-100" src={Calendar} alt="" />
              </div>
            </div>
          </section>
        </div>
      </div>
      </div>
    </div>
  )
}

export default PatientEditProfile
