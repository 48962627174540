import React from 'react'

function TermsOfService() {
  return (
    <div className="middle-content terms-policy">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="back-link">
                <h1>equalityMD Terms of Service <span>Effective date of terms of service: Nov 4, 2019</span></h1>
              </div>
              <p>
              Morbi ligula ipsum, dictum at molestie quis, varius a ex. Donec interdum porta turpis, ut pharetra erat aliquam sagittis. Nam sapien lorem, porttitor id nisl ac, gravida semper ex. Duis at tempus sapien. Duis volutpat neque nisi, id rhoncus tortor cursus ut. Suspendisse potenti. Nullam sit amet turpis eget leo fermentum auctor. Cras molestie quam quam. Morbi ligula ipsum, dictum at molestie quis, varius a ex. Donec interdum porta turpis, ut pharetra erat aliquam sagittis. Nam sapien lorem, porttitor id nisl ac, gravida semper ex. Duis at tempus sapien. Duis volutpat neque nisi, id rhoncus tortor cursus ut. Suspendisse potenti. Nullam sit amet turpis eget leo fermentum auctor. Cras molestie quam quam.
              </p>
              <p>
                <strong>Ut maximus massa eget metus lacinia commodo. Duis nec neque consectetur, sollicitudin metus non, aliquam lacus. Donec venenatis nunc in porta pulvinar. Donec eu nibh vitae lectus luctus consectetur. Praesent tincidunt volutpat mi, quis mattis nulla condimentum </strong>
                id. Pellentesque quis sapien in leo aliquam rhoncus. Aenean efficitur ipsum velit, quis volutpat dui pulvinar a. Phasellus tortor augue, consectetur at rutrum et, pretium a urna. Phasellus consequat laoreet mauris, dignissim posuere dolor dictum vel.
            </p>
            <p>Fusce ullamcorper consectetur vehicula. Ut at accumsan enim. Praesent aliquet congue quam, eget sagittis turpis rutrum quis. Vivamus cursus, neque eget volutpat pulvinar, libero odio ullamcorper est, nec sollicitudin libero ante in massa. Duis eu tristique quam. In dapibus euismod erat at tempus. Nam nec ipsum pulvinar, efficitur nisi ut, aliquam quam. Maecenas accumsan tortor mattis enim porttitor, at pulvinar purus convallis. Vivamus vel augue ante. Nulla lorem quam, commodo nec cursus non, condimentum nec leo. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nullam in vehicula ipsum. Etiam quis purus molestie, laoreet diam at, lobortis nunc. Vestibulum sed egestas neque. Aliquam porttitor quis nunc eget pulvinar.</p>
            <p><strong className="text-uppercase font-500">Duis eu tristique quam. In dapibus euismod erat at tempus. Nam nec ipsum pulvinar, efficitur nisi ut, aliquam quam. Maecenas accumsan tortor mattis enim porttitor, at pulvinar purus convallis.</strong></p>
              
              <ul className="nest-list">
              <li>1.<strong> Nulla feugiat turpis eu odio sollicitudin, in euismod massa malesuada. </strong>
                <ul>
                  <li>Quisque rutrum elit nisi, vel scelerisque magna maximus eu. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Integer eget porttitor 		eros, nec vulputate neque. Pellentesque eget lacus mauris. Praesent nisi lacus, consequat et sapien sed, scelerisque eleifend sapien.</li>
                  <li>Quisque rutrum elit nisi, vel scelerisque magna maximus eu. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Integer eget porttitor 		eros, nec vulputate neque. Pellentesque eget lacus mauris. Praesent nisi lacus, consequat et sapien sed, scelerisque eleifend sapien.</li>
                  <li>Quisque rutrum elit nisi, vel scelerisque magna maximus eu. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Integer eget porttitor 		eros, nec vulputate neque. Pellentesque eget lacus mauris. Praesent nisi lacus, consequat et sapien sed, scelerisque eleifend sapien.</li>
                
                </ul>
              </li>
              <li>2.<strong> Phasellus vel turpis non enim tristique dignissim id et risus.</strong>
                <ul>
                <li>Quisque rutrum elit nisi, vel scelerisque magna maximus eu. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Integer eget porttitor 		eros, nec vulputate neque. Pellentesque eget lacus mauris. Praesent nisi lacus, consequat et sapien sed, scelerisque eleifend sapien.</li>
                  <li>Quisque rutrum elit nisi, vel scelerisque magna maximus eu. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Integer eget porttitor 		eros, nec vulputate neque. Pellentesque eget lacus mauris. Praesent nisi lacus, consequat et sapien sed, scelerisque eleifend sapien.</li>
                  <li>Quisque rutrum elit nisi, vel scelerisque magna maximus eu. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Integer eget porttitor 		eros, nec vulputate neque. Pellentesque eget lacus mauris. Praesent nisi lacus, consequat et sapien sed, scelerisque eleifend sapien.</li>
                </ul>
              </li>
              </ul>
              
              <p>Etiam vestibulum vulputate auctor. Aenean sit amet turpis placerat, condimentum lorem malesuada, consequat felis. Cras fermentum nisi purus, id vulputate arcu sodales sit amet. Sed tristique lorem nec efficitur bibendum. In quis vulputate risus. Vestibulum nibh justo, sodales id orci quis, placerat porttitor dolor. Nunc scelerisque tincidunt nibh sed tempor. Aenean et magna velit. Curabitur ac felis ac odio pretium gravida sed vel elit. Vivamus ac justo accumsan magna pulvinar fermentum eu non mi.</p>
              
              <p>Etiam vestibulum vulputate auctor. Aenean sit amet turpis placerat, condimentum lorem malesuada, consequat felis. Cras fermentum nisi purus, id vulputate arcu sodales sit amet. Sed tristique lorem nec efficitur bibendum. In quis vulputate risus. Vestibulum nibh justo, sodales id orci quis, placerat porttitor dolor. Nunc scelerisque tincidunt nibh sed tempor. Aenean et magna velit.</p>
              <p><strong></strong></p>
              <p></p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TermsOfService

