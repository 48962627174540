import React, { useState } from 'react';
import { ApplicationConstant, PageTitles } from '../../../constants/application.constant';
import PatientMatchedDoctors from './PatientMatchedDoctors';
import GoBack from '../../common/back/GoBack';
import SpinnerComponent from '../../common/spinner/SpinnerComponent';
import { Slide, ToastContainer } from 'react-toastify';
import useDocumentTitle from '../../../utils/DocumentTitle';

function PatientViewAllDoctor() {
  useDocumentTitle(PageTitles.MY_MATCHED_DOCTORS);
  const [isLoading, setLoading] = useState(false);

  return (
    <>
      {isLoading && <SpinnerComponent />}
      <div className="dashboard-page">
        <div className="container">
          <GoBack heading={PageTitles.MY_MATCHED_DOCTORS}/>
          <PatientMatchedDoctors
            isViewAllClicked={ApplicationConstant.TRUE}
            page={ApplicationConstant.ONE}
            limit={ApplicationConstant.TEN}
            setSpinnerLoader={setLoading}
          />
        </div>
      </div>
      <ToastContainer autoClose={4000} hideProgressBar transition={Slide} />
    </>
  );
}

export default PatientViewAllDoctor;
