import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { logout } from '../../../utils/CommonRequests';
import { Image } from './../../common/image/Image';
import CustomModal from '../../common/modal/CustomModal';
import { ModalConstants, RouteConstants } from '../../../constants/application.constant';
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { HEADERLOGO } from '../../../constants/image';
import { getProfileImage, showHideZoomWindow } from '../../../utils/helper';

export const PatientHeader = (props) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const [dropdownOpen, setOpen] = useState(false);
  const [isOpen, setModalOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const toggleLogOutModal = () => setModalOpen(!isOpen);
  const [open, setNavOpen] = React.useState(false);

  const onSignOut = (isSignOut) => {
    toggleLogOutModal();
    if (isSignOut) {
      logout();
    }
  };

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname === RouteConstants.PATIENT_SESSION_CONTAINER) {
        showHideZoomWindow('zmmtg-root', 'block');
      }
      else {
        showHideZoomWindow('zmmtg-root', 'none');
      }
    })
 },[history])

  return (
    <header>
      <div className='container'>
        <div className='headerSection'>
          <div className='header-logo'>
            <span
              className='material-icons'
              onClick={() => {
                setNavOpen(!open);
              }}
            >
              {open ? 'close' : 'menu'}
            </span>
            <Link to={RouteConstants.DASHBOARD_PATIENT}>
              <img src={HEADERLOGO} all='logo' />
            </Link>
          </div>
          <nav className={open ? 'nav open' : 'nav none'}>
            <ul>
              <li
                onClick={() => {setNavOpen(!open);}}
                className={pathname === RouteConstants.DASHBOARD_PATIENT ? 'active' : ''}>
                <Link to={RouteConstants.DASHBOARD_PATIENT}>HOME</Link>
              </li>
              <li
                onClick={() => {setNavOpen(!open);}}
                className={pathname === RouteConstants.FIND_DOCTOR ? 'active' : ''}>
                <Link to={RouteConstants.FIND_DOCTOR}>Find A Provider</Link>
              </li>
              <li
                onClick={() => {setNavOpen(!open);}}
                className={
                  pathname === RouteConstants.PATIENT_UPCOMING_APPOINTMENT ||
                  pathname === RouteConstants.PATIENT_COMPLETED_APPOINTMENT ||
                  pathname === RouteConstants.PATIENT_CANCELED_APPOINTMENT
                    ? 'active'
                    : ''
                }
              >
                <Link to={RouteConstants.PATIENT_UPCOMING_APPOINTMENT}>APPOINTMENTS</Link>
              </li>
            </ul>
          </nav>
          <div className='small-right-nav'>
            <ul>
              <li>
                <span>
                  <ButtonDropdown
                    className='profile-dropdown patient-profile-dropdown'
                    direction='left'
                    isOpen={dropdownOpen}
                    toggle={toggle}
                  >
                    <DropdownToggle>
                      <div className='profile-img'>
                        <Image src={getProfileImage()} alt='Profile Image' />
                        <span className='material-icons md-36 d-none'>account_circle</span>
                      </div>
                      <span className='material-icons md-18'>expand_more</span>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem header>Account Details</DropdownItem>
                      <DropdownItem tag={Link} to={RouteConstants.PATIENT_ACCOUNT_INFORMATION}>
                        <span className='material-icons'>place</span> Account Information
                      </DropdownItem>
                      <DropdownItem tag={Link} to={RouteConstants.PATIENT_USER_PREFERENCES}>
                        <span className='material-icons-outlined'>manage_accounts</span>
                        User Preferences
                      </DropdownItem>
                      <DropdownItem tag={Link} to={RouteConstants.PATIENT_USER_PROFILE}>
                        <span className='material-icons'>account_circle</span>
                        User Profile
                      </DropdownItem>
                      <DropdownItem tag={Link} to={RouteConstants.FAVOURITE_DOCTOR_LIST}>
                        <span className='material-icons'>favorite</span>Favorite Providers
                      </DropdownItem>
                      <DropdownItem tag={Link} to={RouteConstants.PATIENT_MEMBERSHIP}>
                        <span className='material-icons'>monetization_on</span>Membership Information
                      </DropdownItem>
                      <DropdownItem tag={Link} to={RouteConstants.PATIENT_PAYMENT_HISTORY}>
                        <span className='material-icons-sharp'>request_quote</span>Payment History
                      </DropdownItem>
                      <DropdownItem className="patient-change-password-btn" tag={Link} to={RouteConstants.CHANGE_PASSWORD}>
                        <span className='material-icons'>https</span> Change Password
                      </DropdownItem>
                      <DropdownItem onClick={toggleLogOutModal}>
                        <span className='material-icons-outlined'>logout</span>
                        Logout
                      </DropdownItem>
                    </DropdownMenu>
                    <CustomModal
                      modalTitle={ModalConstants.SIGN_OUT_TITLE}
                      modalMessage={ModalConstants.SIGN_OUT_MESSAGE}
                      secondaryButonText={ModalConstants.CANCEL}
                      primaryButtonText={ModalConstants.SIGN_OUT}
                      setModelOpen={toggleLogOutModal}
                      isOpen={isOpen}
                      onButtonClicked={onSignOut}
                      modalClassName='modal-logout'
                    />
                  </ButtonDropdown>
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};
