import React, { useContext } from 'react';
import { Button } from 'reactstrap';
import UserRegistrationContext from '../../../contexts/user-registration/UserRegistrationContext';
import { getActiveStateAndValidity } from '../../../utils/PatientProfileUtil';
import { profileModeTypes } from '../../../constants/application.constant';


const PatientFooter  = ({maxStep, handleSave, handleNext, handlePrev, val, handleActionMode, handleCancel}) => {
    const [ oUserRegistration ] = useContext(UserRegistrationContext);

    const getDisabled = () => {
        return !getActiveStateAndValidity(oUserRegistration, oUserRegistration.patient_profile.current_step).isValid
    }

    const heightValidation = () => {
        if(!oUserRegistration.patient_profile.height_feet && oUserRegistration.patient_profile.height_inches){
            return oUserRegistration.patient_profile.height_feet.isValid
        } else {
            return true;
        }
    }

    const previousBtn = () => {
        if(val<=1) {
            return ''
        } else {
            return <Button color="secondary" onClick={handlePrev}>Previous</Button>
        }
    }

    return (
            <>
            {handleActionMode === profileModeTypes.EDIT ? (
                <footer className="static-footer">
                    <div className="container">
                        <div className="footer-btn">
                            <div className="next">
                                <Button color="secondary" onClick={handleCancel}>Cancel</Button>
                                <Button color="primary" disabled={!heightValidation()} onClick={handleNext}>Save</Button>
                            </div>
                        </div>
                    </div>
                </footer>
                ):(
                <footer>
                    <div className="container">
                        <div className="footer-btn">
                            <div className="prev">
                                {previousBtn()}
                            </div>
                            <div className="next">
                                { maxStep === val && <Button color="primary" disabled={getDisabled()} onClick={handleSave}>Save </Button>}
                                { maxStep !== val && <Button disabled={getDisabled()} color="primary" onClick={handleNext}>Next</Button>}
                            </div>
                        </div>
                    </div>
                </footer>
                )}
            </>
    )
}

export default PatientFooter;
