import { Spinner } from 'reactstrap';
import IconTooltip from '../../../../../../common/tooltip/IconTooltip';

const DocumentListFooter = (props) => {
  const { downloadDocument, documentList, downloadLoader } = props;

  const deleteButtonValidation = () => {
      const checkedItems = documentList.filter(e => e.checked);
      return checkedItems?.length ? true : false;
  }

  return (
        <div className={`table-footer-row ${downloadLoader ? 'spinner-box' : ''}`}>
            <button
                className={`btn-white-outline ${deleteButtonValidation() ? 'enabled-icon' : 'disabled-icon'}`}
                onClick={downloadDocument}
                id="downloadAll"
                disabled={downloadLoader}
            >
            {downloadLoader ? <Spinner /> :
                <span className="material-icons-sharp md-dark md-18">
                    file_download
                </span>
            }
                {IconTooltip('bottom', 'download', 'Download', 'All')}
            </button>
        </div>
    )
}

export default DocumentListFooter;
