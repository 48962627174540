import React from 'react';

function SummaryBioInfo({ oProfile }) {
  return (
    <ul className="value-list">
      <li>Weight (Pounds) : {oProfile.weight ? <span>{oProfile?.weight}</span> : '-'}</li>
      <li className="d-none">Age : {oProfile?.user?.age ? <span>{oProfile?.user?.age}</span> : '-'}</li>
      <li>
        Height (Feet & Inches) : {oProfile.height_feet ? <span>{`${oProfile?.height_feet}'`}</span> : '-'}
        {oProfile.height_feet && oProfile.height_inches ? <span>{`${oProfile?.height_inches}"`}</span> : ''}
        {oProfile.height_feet && !oProfile.height_inches ? <span>{`${'0'}"`}</span> : ''}
      </li>
    </ul>
  );
}

export default SummaryBioInfo;
