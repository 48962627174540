import axiosInstance from '../../../../../../axios/axiosInstance';
import ApiConstant from '../../../../../../constants/ApiConstant';
import { InitialPageLimit, ApplicationConstant } from '../../../../../../constants/application.constant';

export const getPatientDocumentList = (userId, page, sortKey) =>{
    return (
        axiosInstance.get(`${ApiConstant.PATIENT_APPOINTMENT_DOCUMENTS}?doctor_id=${
        userId}${sortKey?.sortBy && `&sortBy=${sortKey?.sortBy}&order=${
        sortKey?.order}`}&page=${page}&limit=${InitialPageLimit.PATIENT_DOCUMENT}`)
    )
}

export const deletePatientDocumentList = (payload) => {
    return axiosInstance.post(ApiConstant.DELETE_APPOINTMENT_DOCUMENT, payload)
}

export const resetScrollPosition = (scrollRef) => {
    return scrollRef.current.scrollTop = ApplicationConstant.ZERO;
}

export const downloadPatientMultipleDocuments = (payload) => {
    return axiosInstance.post(`${ApiConstant.DOWNLOAD_APPOINTMENT_DOCUMENT}`, payload, {responseType : 'blob'})
}

export const downloadPatientDocument = (documentId) => {
    return axiosInstance.get(`${ApiConstant.DOWNLOAD_DOCUMENT}`, {
    params: { document_id: documentId },
    responseType : 'blob'
    })
}

export const getPatientSessionDocumentList = (appointmentId, page, sortKey) =>{
    return (
        axiosInstance.get(`${ApiConstant.PATIENT_APPOINTMENT_DOCUMENTS}?appointment_id=${
            appointmentId}${sortKey?.sortBy && `&sortBy=${sortKey?.sortBy}&order=${
        sortKey?.order}`}&page=${page}&limit=${InitialPageLimit.PATIENT_DOCUMENT}`)
    )
}