import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import axiosInstance from '../../../../axios/axiosInstance';
import ApiConstant from '../../../../constants/ApiConstant';
import SummaryPreference from '../../../summary/patient/SummaryPreference';
import SpinnerComponent from '../../../common/spinner/SpinnerComponent';
import useDocumentTitle from '../../../../utils/DocumentTitle';
import { ToastContainer, toast, Slide } from 'react-toastify';
import { ApplicationConstant, PageTitles, ToastMessage } from '../../../../constants/application.constant';
import PatientEditProfile from '../../../common/modal/PatientEditProfile';

const UserPreferences = () => {
  const history = useHistory();
  const [isLoading, setLoading] = useState(true);
  const [oPreference, setPreference] = useState(null);
  const [isOpen, modalOpen] = useState(false);
  useDocumentTitle(PageTitles.USER_PREFERENCES);

  const toggleEditModal = () => {
    modalOpen(!isOpen);
  };

  const openEditForm = () => {
    toggleEditModal();
    history.push({
      state: { step: ApplicationConstant.NINE }
    });
  };

  const updateData = (data) => {
    setPreference(data?.data?.data?.patient_preferences);
    toggleEditModal();
    toast.success(ToastMessage.PROFILE_UPDATE);
  };

  useEffect(() => {
    setLoading(true);
    axiosInstance.get(`${ApiConstant.PATIENT_PROFILE}`).then(
      (res) => {
        setLoading(false);
        if (res && res.data) {
          const oProfile = res.data.data || null;
          setPreference(oProfile?.patient_preferences);
        }
      },
      (_error) => {
        setLoading(false);
      }
    );
  }, []);
  return (
    <>
      {isLoading ? (
        <SpinnerComponent />
      ) : (
        <div className="middle-content edit-profile user-right-container pt-40">
          <div className="pages">
            <div className="d-flex justify-content-end">
              <div
                className="btn-white-outline pr-10 pl-10 d-flex align-items-center cursor-pointer"
                onClick={openEditForm}
              >
                <span className="material-icons md-dark md-18 mt-0">edit</span>
              </div>
            </div>
            <div className="profile-information">
              <div className="form-step summary max-w-100">
                <div className="detail p-0 border-0">
                  <div className="content p-0">
                    <SummaryPreference oPatientPreference={oPreference} />
                  </div>
                </div>
              </div>
            </div>
            <PatientEditProfile
              isOpen={isOpen}
              modalOpen={toggleEditModal}
              className={'edit-profile-modal'}
              modalClassName={'right'}
              getSavedData={updateData}
            />
            <ToastContainer autoClose={4000} hideProgressBar transition={Slide} />
          </div>
        </div>
      )}
    </>
  );
};

export default UserPreferences;
