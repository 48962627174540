import React from 'react';
import { EMPTY_RECENT_ACTIVITY } from '../../../constants/image';

function HospitalRecentActivity() {
  return (
    <div className="col-sm-12 col-lg-4">
      <div className="box recent-activity-box">
        <div className="no-data-section">
        <img src={EMPTY_RECENT_ACTIVITY} alt="No data" />
        <h2>No recent activity yet!</h2>
      </div>
      </div>
    </div>
  );
}

export default HospitalRecentActivity;
