import { useState } from 'react';
import moment from 'moment';
import { ApplicationConstant, ModalConstants } from '../../../../../../../constants/application.constant';
import IconTooltip from '../../../../../../common/tooltip/IconTooltip';
import CustomModal from '../../../../../../common/modal/CustomModal';

const DocumentListItem = (props) => {
    const { item, onSelectListRow, changeDocumentVisibility, deleteDocument } = props;
    const [ documentVisibility, setDocumentVisibility ] = useState(item?.visible_to_patient);
    const [delateModal, setDeleteModal] = useState(false);

    const toggleDeleteModal = () => {
        setDeleteModal(!delateModal);
    }

    const deleteDocumentFile = (isFileDeleted) => {
        toggleDeleteModal();
        if (isFileDeleted) {
            deleteDocument(item?.id)
        }
    };

    const toggleDocumentVisibility = () => {
        changeDocumentVisibility(item?.id, documentVisibility ? ApplicationConstant.ZERO : ApplicationConstant.ONE );
        setDocumentVisibility(!documentVisibility);
    };

    return (
        <>
        <div className="divRow">
            <div className="table-col table-column-9" data-label="First Name">
                <label className="label--checkbox flat">
                    <input
                        type="checkbox"
                        className="checkbox"
                        id={`selectList${item?.id}`}
                        onChange={()=>{onSelectListRow(item?.id)}}
                        checked={item?.checked}
                    />
                    <label className="font-16 font-500" htmlFor={`selectList${item?.id}`}></label>
                </label>
            </div>
            <div className="table-col table-column-18" title={item?.first_name} data-label="By">
            <span>{item?.first_name}</span>
            </div>
            <div className="table-col table-column-27" title={item?.document_name} data-label="File Name">
            <span><a className="link" href={item?.document_url} target="_blank">{item?.document_name}</a></span>
            </div>
            <div className="table-col table-column-22" title={moment(item?.created_at).format('ll')} data-label="Date">
            <span>{moment(item?.created_at).format('ll')}</span>
            </div>
            <div className="table-col table-column-24 d-flex" data-label="Actions">
                {item?.doctor_id === item?.uploaded_by ?
                    <>
                        <span
                            className="btn-white-outline m-0"
                            onClick={toggleDocumentVisibility}
                            id={`visibility${item?.id}`}
                        >
                            <span class="material-icons-outlined md-dark md-18">
                            {documentVisibility ? 'visibility' : 'visibility_off'}
                            </span>
                            {IconTooltip('bottom', 'visibility', `${documentVisibility ? 'Visible to patient' : 'Not visible to patient'}`, item?.id)}
                        </span>
                        <span
                            className="btn-white-outline ml-5"
                            onClick={toggleDeleteModal}
                            id={`delete${item?.id}`}
                        >
                            <span className="material-icons md-dark md-18">delete</span>
                            {IconTooltip('bottom', 'delete', 'Delete', item?.id)}
                        </span>
                    </>
                : <p className="ml-10">-</p>}
            </div>
        </div>
        {delateModal &&
            <CustomModal
                modalTitle={ModalConstants.DELETE_DOCUMENT_TITLE}
                modalMessage={ModalConstants.DELETE_DOCUMENT_MESSAGE}
                secondaryButonText={ModalConstants.No}
                primaryButtonText={ModalConstants.YES}
                setModelOpen={toggleDeleteModal}
                isOpen={delateModal}
                onButtonClicked={deleteDocumentFile}
                modalClassName="modal-logout"
            />
        }
        </>
    )
}

export default DocumentListItem;
