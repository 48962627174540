import React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import FormCheckBox from '../../common/formElements/FormCheckBox';
import { PROFILEIMG } from '../../../constants/image';

const AppointmentConfirmedModal = (props) => {
  const {
    className,
    modalClassName,
    isOpen,
    setModelOpen,
    onButtonClicked,
    primaryButtonText,
    secondaryButonText
  } = props;

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          setModelOpen();
        }}
        className={className}
        modalClassName={modalClassName}
      >
        <ModalBody className="emal-sent">
            <div className="check-box">
                <span className="material-icons">done</span>
            </div>
            <h1>Appointment was successfully confirmed! </h1>
            <div className="profile-info">
                  <div className="box">
                    <div className="user-info">
                      <div className="profile-img active">
                        <img src={PROFILEIMG}></img>
                      </div>
                      <div className="head">
                        <div className="title-text">
                          <h2>With: <span>Patrick James</span></h2>
                          <h2>Date: <span>June 17, 2021</span></h2>
                          <h2>Time: <span>12:00 PM</span></h2>
                          <h2>Appointment: <span>In Person</span></h2>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        <FormCheckBox
            key={' '}
            currentItem={' '}
            item={' '}
            label={'Add to your eMD Calendar'}
            handleSelect={' '}
        />
        <FormCheckBox
            key={' '}
            currentItem={' '}
            item={' '}
            label={'Add to your Google Calendar'}
            handleSelect={' '}
        />
        <FormCheckBox
            key={' '}
            currentItem={' '}
            item={' '}
            label={'Add to your Outlook Calendar'}
            handleSelect={' '}
        />
                  
        </ModalBody>
        <ModalFooter>
            <Button
                color="secondary"
                onClick={() => {
                onButtonClicked(false);
                }}
            >
            {secondaryButonText}
          </Button>
          <Button
            color="primary"
            onClick={() => {
              onButtonClicked(true);
            }}
          >
            {primaryButtonText}
          </Button>{' '}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AppointmentConfirmedModal;
