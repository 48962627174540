import React from 'react';
import { Link } from 'react-router-dom';
import { PageTitles } from '../../../constants/application.constant';
import useDocumentTitle from '../../../utils/DocumentTitle';
import { dashboardRedirection } from '../../../utils/helper';

function PaymentErrorPage() {
  useDocumentTitle(PageTitles.PAYMENT_ERROR_PAGE);

  return (
    <div className="page-not-found">
      <h2>402</h2>
      <h4>There was some issue with your payment. Please try again after sometime!</h4>
      <Link to={dashboardRedirection()}>Go Back</Link>
    </div>
  );
}

export default PaymentErrorPage;
