import { npiNumberValidation } from '../../../../validators/CommonValidation';
import InputElement from '../../../common/formElements/InputElement';
import SelectElement from '../../../common/formElements/SelectElement';
import { ApplicationConstant } from '../../../../constants/application.constant';
import { FieldKey } from './AccountInformationUtils';


const BackgroundInformation = (props) => {
    const {profileData, setValueByFieldName, npiOption, specializationOptions} = props;

    const handleNpiNumberChange = (e) => {
        e.preventDefault();
        if (e.target.value.length <= ApplicationConstant.TEN) {
          const npiNumberObj = npiNumberValidation(e.target.value);
          setValueByFieldName(e.target.name, npiNumberObj);
        }
    };

    const handleNpiTypeChange = (e) => {
        setValueByFieldName(FieldKey.NPI_TYPE, e);
    }

    const handleSpecializationChange = (e) => {
        if(e.length >= ApplicationConstant.ONE){
            setValueByFieldName(FieldKey.SPECIALIZATIONS, e);
        } else{
            setValueByFieldName(FieldKey.SPECIALIZATIONS, '');
        }
    }

    return (
        <div className='row'>
            <InputElement
            className='col-sm-12'
            type='number'
            name='npi_number'
            label={'NPI Number*'}
            placeholder={'Please Enter'}
            onChange={handleNpiNumberChange}
            onBlur={handleNpiNumberChange}
            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
            isValid={profileData?.npi_number?.valid}
            value={profileData?.npi_number?.value}
            msg={profileData?.npi_number?.msg}
            />
            <SelectElement
            classNm={'col-sm-6 d-none'}
            name={'npi_type'}
            className='reactSelect'
            classNamePrefix='react-select'
            options={npiOption}
            placeholder={'NPI Type'}
            label={'NPI Type*'}
            onChange={handleNpiTypeChange}
            value={profileData?.npi_type}
            />
            <SelectElement
            classNm={'col-sm-12'}
            name={'specializations'}
            isMulti={ApplicationConstant.TRUE}
            className='reactSelect'
            classNamePrefix='react-select'
            options={specializationOptions}
            isSearchable={ApplicationConstant.TRUE}
            stayMenuOnSelect={ApplicationConstant.TRUE}
            placeholder={'Specialization'}
            label={'Specialty'}
            onChange={handleSpecializationChange}
            value={profileData?.specializations}
            />
        </div>
    )
}
export default BackgroundInformation;
