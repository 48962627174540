import axiosInstance from '../../axios/axiosInstance';
import ApiConstant from '../../constants/ApiConstant';


export const getSubscriptionPlan = () => {
    return axiosInstance.get(ApiConstant.GET_SUBSCRIPTION)
}

export const createSubscriptionPlan = (payloadData) => {
    const payload = {price_id:payloadData}
    return axiosInstance.post(ApiConstant.CREATE_SUBSCRIPTION, payload)
}

export const updateSubscriptionStatus = (payloadData) => {
    const payload = {session_id:payloadData}
    return axiosInstance.post(ApiConstant.UPDATE_SUBSCRIPTION_STATUS, payload)
}

export const cancelSubscriptionPlan = (payloadData) => {
    const payload = {subscription_id:payloadData}
    return axiosInstance.post(ApiConstant.CANCEL_SUBSCRIPTION, payload)
}
