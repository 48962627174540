import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { ToastContainer, toast, Slide } from 'react-toastify';
import axiosInstance from '../../../axios/axiosInstance';
import ApiConstant from '../../../constants/ApiConstant';
import { UserRole, PageTitles, RouteConstants } from '../../../constants/application.constant';
import useDocumentTitle from '../../../utils/DocumentTitle';
import PatientProgressBar from './PatientProfileStepper';
import PatientProfile from './PatientProfile';
import SpinnerComponent from '../../common/spinner/SpinnerComponent';
import PatientRecentActivity from './PatientRecentActivity';
import PatientMatchedDoctors from './PatientMatchedDoctors';
import PatientCalendar from './PatientCalendar';
import PatientUpcomingAppointments from '../../appointment/patient/history/upcoming/PatientUpcomingAppointments';
import { setProfileImage, getSearchParama } from '../../../utils/helper';
import Subscription from '../../subscription/Subscription';
import { updateSubscriptionStatus } from '../../subscription/SubscriptionPaymentUtils';
import PurchaseSubscriptionConfirmation from '../../subscription/SubscriptionConfirmation';

const PatientDashboard = () => {
  useDocumentTitle(PageTitles.PATIENT_DASHBOARD);
  const [profileInfo, setProfileInfo] = useState({});
  const [loader, setLoader] = useState(false);
  const [subscriptionModal, setSubscriptionModal] = useState(false);
  const [subscriptionConfirmationModal, setSubscriptionConfirmationModal] = useState(false);
  const history = useHistory();

  const fetchPatientProfile = async () => {
    setLoader(true);
    await axiosInstance
      .get(`${ApiConstant.PATIENT_DASHBOARD}`)
      .then((response) => {
        if (response && response.data) {
          const patientProfileData = response.data.data || {};
          setProfileInfo(patientProfileData);
          setProfileImage(patientProfileData?.profile_image);
        }
      })
      .catch((error) => {
        toast.error(error || '');
      });
    setLoader(false);
  };

  const toggleSubscriptionModal = () => {
    setSubscriptionModal(!subscriptionModal);
  }

  const toggleSubscriptionConfirmationModal = () => {
    setSubscriptionConfirmationModal(!subscriptionConfirmationModal);
  }

  const updateDoctorSubscriptionStatus = async () => {
    const subscriptionSessionId = getSearchParama(history, 'session_id');
    if(subscriptionSessionId) {
      await updateSubscriptionStatus(subscriptionSessionId)
      .then((_res) => {
        history.replace({pathname: RouteConstants.DASHBOARD_PATIENT, search: ''})
        toggleSubscriptionConfirmationModal();
      }).catch((error) => {
        toast.error(error || '')
      });
    }
  }

  useEffect(() => {
    fetchPatientProfile();
    updateDoctorSubscriptionStatus();
  }, []);

  return (
    <>
      {loader && <SpinnerComponent />}
      <div className='dashboard-page'>
        <div className='container'>
          <section className='detail-info'>
            <PatientProgressBar
              profileData={profileInfo}
              toggleSubscriptionModal={toggleSubscriptionModal}
            />
            <div className='profile-info'>
              <div className='row'>
                <PatientProfile profileData={profileInfo} />
                <PatientRecentActivity />
              </div>
            </div>
          </section>
          <PatientMatchedDoctors profileData={profileInfo} />
          <div className='row upa'>
            <div className='col-sm-12 col-lg-6 patient-dashbaord-appointments'>
              <PatientUpcomingAppointments isDashboard={true} />
            </div>
            <div className='col-sm-12 col-lg-6 dashboard-calendar-card'>
              <section className='my-matched-section'>
                <PatientCalendar />
              </section>
            </div>
          </div>
        </div>
      </div>
      {subscriptionModal &&
        <Subscription
          isOpen={subscriptionModal}
          setModelOpen={toggleSubscriptionModal}
          modalClassName="subscription-plan"
          discountAmount="50"
          title="Memberships!"
          subTitle="Become an equalityMD member to see the provider of your choice."
          subscriptionPlanValidity="Monthly"
          primaryButtonText="Sign up!"
          secondaryButtonText="Not Today"
          isAccessRestrictions={false}
          subscriptionRole={UserRole.PATIENT}
        />
      }
      {subscriptionConfirmationModal &&
        <PurchaseSubscriptionConfirmation
          isOpen={subscriptionConfirmationModal}
          setModelOpen={toggleSubscriptionConfirmationModal}
          roleType={UserRole.PATIENT}
          modalClassName="subscription-confirmation"
        />
      }
      <ToastContainer autoClose={4000} hideProgressBar transition={Slide} />
    </>
  );
};

export default PatientDashboard;
