import React, { useContext } from 'react';
import update from 'immutability-helper';
import { ApplicationConstant } from '../../../constants/application.constant';
import DoctorSignUpContext from '../../../contexts/doctor-signup/DoctorSignUpContext';
import SelectElement from '../../common/formElements/SelectElement';
import InputElement from '../../common/formElements/InputElement';
import { experienceValidation } from '../../../validators/CommonValidation';
import MultiSelectElement  from '../../common/formElements/MultiSelectElement';

function DoctorBackgroundInformation(props) {
  const{ oLabelInfo, isHospitalFlow = ApplicationConstant.FALSE } = props
  const [doctorSignUp, setDoctorSignUp] = useContext(DoctorSignUpContext);

  const setValueByFieldName = (fieldname, value) => {
    setDoctorSignUp(update(doctorSignUp, { doctor_profile: { [fieldname]: { $set: value } } }));
  };

  const onMultiSelectInput = (name, e) => {
    if (e.length >= ApplicationConstant.ONE) {
      setValueByFieldName(name, e);
    } else {
      setValueByFieldName(name, "");
    }
  };
  const onSelectInput = (name, e) => {
    setValueByFieldName(name, e);
  };

  const experienceInput = (e) => {
    e.preventDefault();
    const obj = experienceValidation(e.target.value, doctorSignUp.doctor_profile.year_of_experience);
    setValueByFieldName('year_of_experience', obj);
  };

  const otherTextInput = (e) => {
    e.preventDefault();
    setValueByFieldName(e.target.name, e.target.value);
  };

  return (
    <div className="mb-100">
      <h3 className="mt-24">
        {!isHospitalFlow ? "Background Information" : "Education Information"}
      </h3>
      <div className="row">
        <SelectElement
          classNm={"col-sm-12"}
          name={"medical_school_id"}
          onChange={(e) => {
            onSelectInput("medical_school", e);
          }}
          className="reactSelect"
          classNamePrefix="react-select"
          options={oLabelInfo.medical_schools}
          value={doctorSignUp.doctor_profile.medical_school}
          isSearchable={ApplicationConstant.TRUE}
          placeholder={""}
          label={"School"}
        />
        {doctorSignUp.doctor_profile.medical_school?.name?.toLowerCase() ===
          ApplicationConstant.OTHER_LOWER_CASE && (
          <InputElement
            className="col-sm-12"
            type="text"
            name="other_medical_school"
            value={doctorSignUp.doctor_profile.other_medical_school}
            onChange={otherTextInput}
            label={"Other School*"}
            placeholder={"Please enter school details"}
            required={ApplicationConstant.FALSE}
            autoFocus
          />
        )}
        <SelectElement
          classNm={"col-sm-12"}
          name={"certification"}
          isMulti={ApplicationConstant.TRUE}
          onChange={(e) => {
            onMultiSelectInput("doctor_certifications", e);
          }}
          className="reactSelect"
          classNamePrefix="react-select"
          options={oLabelInfo.certifications}
          value={doctorSignUp.doctor_profile.doctor_certifications}
          isSearchable={ApplicationConstant.TRUE}
          stayMenuOnSelect={ApplicationConstant.TRUE}
          placeholder={"Please select certificate"}
          label={"Degrees/Credentials*"}
        />
        {Array.isArray(doctorSignUp.doctor_profile.doctor_certifications) &&
          doctorSignUp.doctor_profile.doctor_certifications?.findIndex(
            (item) =>
              item?.name?.toLowerCase() === ApplicationConstant.OTHER_LOWER_CASE
          ) > -1 && (
            <InputElement
              className="col-sm-12"
              type="text"
              name="other_certification"
              value={doctorSignUp.doctor_profile.other_certification}
              onChange={otherTextInput}
              label={"Other Doctor certification*"}
              placeholder={"Please enter doctor certification"}
              required={ApplicationConstant.FALSE}
              autoFocus
            />
          )}
        <SelectElement
          classNm={"col-sm-12"}
          name={"specializations"}
          isMulti={ApplicationConstant.TRUE}
          onChange={(e) => {
            onMultiSelectInput("doctor_specializations", e);
          }}
          className="reactSelect"
          classNamePrefix="react-select"
          options={oLabelInfo.specializations}
          value={doctorSignUp.doctor_profile.doctor_specializations}
          isSearchable={ApplicationConstant.TRUE}
          stayMenuOnSelect={ApplicationConstant.TRUE}
          placeholder={"Please select specialization"}
          label={"Specialty"}
        />
        <InputElement
          className="col-sm-12"
          type="text"
          name="Year of Experience"
          value={doctorSignUp.doctor_profile.year_of_experience.value}
          onChange={experienceInput}
          label={"Years of Experience"}
          placeholder={"Please enter years of experience"}
          required={ApplicationConstant.FALSE}
          isValid={doctorSignUp.doctor_profile.year_of_experience.valid}
          msg={doctorSignUp.doctor_profile.year_of_experience.msg}
        />
        <SelectElement
          classNm={"col-sm-12 d-none"}
          name={"Practice"}
          isMulti={ApplicationConstant.TRUE}
          onChange={(e) => {
            onMultiSelectInput("practice", e);
          }}
          className="reactSelect"
          classNamePrefix="react-select"
          options={oLabelInfo.practice}
          value={doctorSignUp.doctor_profile.practice}
          isSearchable={ApplicationConstant.TRUE}
          stayMenuOnSelect={ApplicationConstant.TRUE}
          placeholder={"Please select practice"}
          label={"Practice"}
        />
        <MultiSelectElement
          selectOptions={oLabelInfo.procedure}
          selectValue={doctorSignUp.doctor_profile.procedure}
          name={"Procedure"}
          placeholder="Please select procedure"
          label="Procedure(s) Performed"
          spacing={"d-none"}
          onChange={(e) => {
            onMultiSelectInput("procedure", e);
          }}
        />
        <MultiSelectElement
          selectOptions={oLabelInfo.condition_treated}
          selectValue={doctorSignUp.doctor_profile.condition_treated}
          name={"Condition Treated"}
          placeholder="Please select condition treated"
          label="Condition(s) Treated"
          spacing={"d-none"}
          onChange={(e) => {
            onMultiSelectInput("condition_treated", e);
          }}
        />
      </div>
    </div>
  );
}

export default DoctorBackgroundInformation;
