import React, { useState, useContext, useEffect } from 'react';
import update from 'immutability-helper';
import { getCitiesById, getStates } from '../../../utils/CommonRequests';
import { ApplicationConstant } from '../../../constants/application.constant';
import PatientSignUpContext from '../../../contexts/patient-signup/PatientSignUpContext';
import InputElement from '../../common/formElements/InputElement';
import SelectElement from '../../common/formElements/SelectElement';
import { zipCodeValidation } from '../../../validators/CommonValidation';

function PatientLocation() {
  const [patientSignUp, setPatientSignUp] = useContext(PatientSignUpContext);
  const [states, setStates] = useState([]);
  const [stateId, setStateId] = useState(null);
  const [cities, setCities] = useState([]);

  const setLocationValues = (prop, val) => {
    setPatientSignUp(update(patientSignUp, { patient_details: { location: { [prop]: { $set: val } } } }));
  };

  const onStateInput = (e) => {
    setStateId(e.id);
    setLocationValues('state_id', e.id);
    setLocationValues('state', e);
  };

  const onCityInput = (e) => {
    setLocationValues('city_id', e.id);
    setLocationValues('city', e);
  };

  const setAddressValue = (e) => {
    e.preventDefault();
    setLocationValues(e.target.name, e.target.value);
  };

  const setZipCode = (e) => {
    zipCodeValidation('zip_code', e.target.value, setLocationValues);
  };

  useEffect(() => {
    getStates().then((res) => {
      if (res.length > 0) {
        setStates(res.map((item) => ({ ...item, label: item.name, value: item.name.toLowerCase() })));
      }
    });
    if (!!stateId) {
      setLocationValues('city', '');
    }
  }, []);

  useEffect(() => {
    getCitiesById(stateId).then((res) => {
      if (res.length > 0) {
        setCities(res.map((item) => ({ ...item, label: item.name, value: item.name.toLowerCase() })));
      }
    });
    if (!!stateId) {
      setLocationValues('city', '');
    }
  }, [stateId]);

  return (
    <div className="form-step">
      <form className="w-100">
        <div className="head-title mb-50">
          <div className="heading">Patient Registration</div>
          <div className="sub-heading">Location</div>
        </div>
        <div className="row">
          <InputElement
            className="col-sm-6"
            type="text"
            name="address1"
            value={patientSignUp.patient_details.location.address1}
            onChange={setAddressValue}
            autoComplete={ApplicationConstant.OFF}
            placeholder={'Please enter street address*'}
            label={'Street Address*'}
          />
          <InputElement
            className="col-sm-6"
            type="text"
            name="address2"
            value={patientSignUp.patient_details.location.address2}
            onChange={setAddressValue}
            autoComplete={ApplicationConstant.OFF}
            placeholder={'Please enter appartment'}
            label={'Apartment'}
          />
          <SelectElement
            classNm={'col-sm-6'}
            name={'state'}
            onChange={onStateInput}
            className="reactSelect"
            classNamePrefix="react-select"
            options={states}
            value={patientSignUp.patient_details.location.state}
            isSearchable={ApplicationConstant.TRUE}
            placeholder={''}
            label={'State*'}
          />
          <SelectElement
            classNm={'col-sm-6'}
            name={'city'}
            onChange={onCityInput}
            className="reactSelect"
            classNamePrefix="react-select"
            options={cities}
            value={patientSignUp.patient_details.location.city}
            isSearchable={ApplicationConstant.TRUE}
            placeholder={''}
            label={'City*'}
          />
          <InputElement
            className="col-sm-12"
            type="number"
            name="zip_code"
            value={patientSignUp.patient_details.location.zip_code.value}
            onChange={setZipCode}
            autoComplete={ApplicationConstant.OFF}
            placeholder={'Please enter zipcode'}
            label={'Zip Code*'}
            isValid={patientSignUp.patient_details.location.zip_code.valid}
            msg={patientSignUp.patient_details.location.zip_code.msg}
          />
        </div>
      </form>
    </div>
  );
}

export default PatientLocation;
