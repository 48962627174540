export const DoctorRequestInitialState = {
  doctor: {
    pronoun_id: null,
    other_pronoun: null,
    race_id: null,
    other_race: null,
    ethnicity_id: null,
    other_ethnicity: null,
    user_races: '',
    user_pronouns: '',
    user_languages: '',
    user_identifications: '',
    user_sexual_orientations: '',
    other_language: null,
    other_identification: null,
    other_sexual_orientation: null,
  },
  data: {
    userPronouns: '',
    userRaces: '',
    ethnicity: null,
    userLanguages: '',
    userIdentifications: '',
    userSexOrientation: ''
  }
};

export const DoctorFormState = {
  isPronounOther: false,
  isRaceOther: false,
  isEthnicityOther: false,
  isLanguageOther: false,
  isIdentificationOther: false,
  isSexualOrientationOther: false,
};
