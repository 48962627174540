import React from 'react';
import HospitalSignUp from '../components/user-signup/hospital/HospitalSignUp';
import HostpitalSignUpProvider from '../contexts/hospital-signup/HostpitalSignUpProvider';
import useDocumentTitle from '../utils/DocumentTitle';
import { PageTitles } from '../constants/application.constant';

function HospitalSignupPage() {
  useDocumentTitle(PageTitles.HOSPITAL_SIGN_UP);

  return (
    <div className="step-page">
      <div>
        <HostpitalSignUpProvider>
          <HospitalSignUp />
        </HostpitalSignUpProvider>
      </div>
    </div>
  );
}

export default HospitalSignupPage;
