import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axiosInstance from '../../../../axios/axiosInstance';
import { PageTitles, ApplicationConstant, RouteConstants } from '../../../../constants/application.constant';
import SpinnerComponent from '../../../common/spinner/SpinnerComponent';
import useDocumentTitle from '../../../../utils/DocumentTitle';
import ApiConstant from '../../../../constants/ApiConstant';
import { ToastContainer, toast, Slide } from 'react-toastify';
import { Button } from 'reactstrap';
import InputElement from '../../../common/formElements/InputElement';
import RadioToggleElement from '../../../common/formElements/RadioToggleElement';
import 'react-toastify/dist/ReactToastify.css';
import { validateQuestions } from './BackgroundUtils';

function DoctorBackgroundInfo() {
  const history = useHistory();
  useDocumentTitle(PageTitles.BACKGROUND_INFO);
  const [isLoading, setLoading] = useState(false);
  const [aQuestions, setQuestions] = useState([]);

  useEffect(() => {
    setLoading(true);
    axiosInstance.get(ApiConstant.DOCTOR_BACKGROUND_INFO_QUESTION).then(
      (res) => {
        setLoading(false);
        setQuestions(res?.data?.data || []);
      },
      (error) => {
        setLoading(false);
        toast.error(error || '');
      }
    );
  }, []);

  const handleToggle = (e) => {
    const actualQuestions = JSON.parse(JSON.stringify(aQuestions));
    const index = actualQuestions.findIndex((o) => o.id === parseInt(e.target.name));
    if (index > -1) {
      const oQuestion = actualQuestions[index];
      oQuestion.option = e.target.value;
      if (e.target.value === 'N') {
        oQuestion.description = '';
      }
      actualQuestions[index] = oQuestion;
      setQuestions(actualQuestions);
    }
  };

  const handleInput = (e) => {
    const actualQuestions = JSON.parse(JSON.stringify(aQuestions));
    const index = actualQuestions.findIndex((o) => o.id === parseInt(e.target.name));
    if (index > -1) {
      const oQuestion = actualQuestions[index];
      oQuestion.description = e.target.value;
      actualQuestions[index] = oQuestion;
      setQuestions(actualQuestions);
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    history.replace(RouteConstants.DASHBOARD_DOCTOR);
  };

  const updateBackgroundInfo = (e) => {
    e.preventDefault();
    setLoading(true);
    axiosInstance.post(ApiConstant.UPDATE_BACKGROUND_INFO, aQuestions).then(
      (res) => {
        setLoading(false);
        toast.success(res?.data?.message);
      },
      (error) => {
        setLoading(false);
        toast.error(error || '');
      }
    );
  };

  return (
    <>
      {isLoading && <SpinnerComponent />}
      <div className="middle-content edit-profile user-right-container">
        <div className="pages">
          <div className="profile-information">
            <div className="form-step max-w-100 background-info-wrapper">
              {aQuestions.map((item, index) => (
                <RadioToggleElement
                  key={index}
                  classNm={'col-sm-12'}
                  label={`${item.question}*`}
                  name={item.id}
                  selectedValue={aQuestions[index].option}
                  onChange={handleToggle}
                >
                  <InputElement
                    className="col-sm-12"
                    type="textarea"
                    height="100"
                    autoComplete={ApplicationConstant.OFF}
                    placeholder={'Please enter value'}
                    label={'Description*'}
                    name={item.id}
                    value={aQuestions[index].description || ''}
                    onChange={handleInput}
                  />
                </RadioToggleElement>
              ))}
              <div
                className="btn-group justify-content-end w-100 pt-10 pr-16 form-btn-group"
                hidden={aQuestions.length === ApplicationConstant.ZERO}
              >
                <Button className="btn mr-15 cancel-btn btn-secondary" onClick={handleCancel}>
                  CANCEL
                </Button>
                <Button
                  disabled={!validateQuestions(aQuestions)}
                  type="submit"
                  color="primary"
                  className="btn btn-primary"
                  onClick={updateBackgroundInfo}
                >
                  SAVE
                </Button>
              </div>
              <ToastContainer autoClose={4000} hideProgressBar transition={Slide} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DoctorBackgroundInfo;
