import React from 'react';
import { TabContent, TabPane } from 'reactstrap';
import DoctorDocument from '../documents/DoctorDocument';
import DoctorHistory from '../history/DoctorHistory';
import DoctorNotes from '../notes/DoctorNotes';
import DoctorSuperbill from "../superbill/DoctorSuperbill";
import { SessionDocumentTabTitle } from '../../../../../../constants/application.constant';

function TabBody(props) {
  const { activeTab, oMeeting, appointmentId, userId } = props;
  return (
    <TabContent activeTab={activeTab}>
      <TabPane tabId={SessionDocumentTabTitle.NOTES}>
        <DoctorNotes oMeeting={oMeeting} />
      </TabPane>
      <TabPane tabId={SessionDocumentTabTitle.DOCUMENTS}>
        <DoctorDocument
          appointmentId={appointmentId}
          userId={userId}
        />
      </TabPane>
        <TabPane tabId={SessionDocumentTabTitle.SUPERBILL}>
            <DoctorSuperbill
                userId={userId}
                appointmentId={appointmentId}
                oMeeting={oMeeting}
            />
        </TabPane>
      <TabPane tabId={SessionDocumentTabTitle.HISTORY}>
        <DoctorHistory userId={userId}/>
      </TabPane>
    </TabContent>
  );
}

export default TabBody;
