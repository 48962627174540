import { Link } from 'react-router-dom';
import { dashboardRedirection } from './../../../utils/helper';
import useDocumentTitle from '../../../utils/DocumentTitle';
import { PageTitles } from '../../../constants/application.constant';

const PageNotFound = () => {
  useDocumentTitle(PageTitles.PAGE_NOT_FOUND);
  return (
    <div className="page-not-found">
      <h2>404</h2>
      <h4>Oops! Page not found</h4>
      <p>We can't find the page you're looking for.</p>
      <Link to={dashboardRedirection()}>Go Back</Link>
    </div>
  );
};

export default PageNotFound;
