export const SortItems = [
  {
    id: 'date',
    label: 'Sort by: Date',
    value: 'Date',
  },
  {
    id: 'specialization',
    label: 'Sort by: Specialization',
    value: 'Specialization'
  },
  {
    id: 'name',
    label: 'Sort by: Provider Name',
    value: 'Provider Name'
  },
];
