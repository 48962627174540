import React, { useEffect } from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { ApplicationConstant } from '../../../constants/application.constant';

function FormCheckBox({
  keyId,
  item,
  label,
  isChecked,
  handleSelect,
  labelDescription,
  default_key,
  aLength,
}) {
  const handleChange = () => {
    console.log('Farazzz')
    handleSelect(keyId);
  };

  useEffect(() => {
    if (default_key === keyId && aLength < 1) {
      handleChange();
    }
  }, []);

  return (
    <div className='col-sm-6'>
      <FormGroup check>
        <Label check className={`label--checkbox ${isChecked ? 'active' : ''}`}>
          <Input
            onChange={handleChange}
            checked={item.isChecked}
            className='checkbox'
            type='checkbox'
            name='check1'
            id={`check${keyId}`}
          />
          <label htmlFor='check0'>
            {label}
            {!!labelDescription ? (
              <span>({labelDescription})</span>
            ) : (
              ApplicationConstant.NO_VALUE_STRING
            )}
          </label>
        </Label>
      </FormGroup>
    </div>
  );
}

export default FormCheckBox;
