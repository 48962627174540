import React, { useState } from 'react';
import useDocumentTitle from '../../../../utils/DocumentTitle';
import { ToastContainer, Slide } from 'react-toastify';
import { PageTitles, } from '../../../../constants/application.constant';
import PatientMembershipInformation from './PatientMembershipInformation';
import SpinnerComponent from '../../../common/spinner/SpinnerComponent';

function PatientMembership() {
  useDocumentTitle(PageTitles.MEMBERSHIP_INFORMATION);
  const [subscriptionLoader, setSubscriptionLoader] = useState(false);

    return (
      <>
        {subscriptionLoader && <SpinnerComponent />}
        <div className="middle-content edit-profile user-right-container">
          <div className="pages">
            <div className="payment-section">
              <PatientMembershipInformation
                setSubscriptionLoader={setSubscriptionLoader}
              />
            </div>
          </div>
        </div>
        <ToastContainer autoClose={4000} hideProgressBar transition={Slide} />
      </>
    );
}

export default PatientMembership;



