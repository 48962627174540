import React, { useContext } from 'react'
import HospitalSignUpContext from '../../../contexts/hospital-signup/HospitalSignUpContext';
import InputElement from '../../common/formElements/InputElement';
import RadioToggleElement from '../../common/formElements/RadioToggleElement';
import SelectElement from '../../common/formElements/SelectElement';
import update from 'immutability-helper';
import { ApplicationConstant } from '../../../constants/application.constant';
import { npiNumberValidation, preferredNameValidation} from '../../../validators/CommonValidation';


function HospitalBackground({ oLabelInfo }) {
  const [hospitalSignUp, setHospitalSignUp] = useContext(HospitalSignUpContext);
  const setValueByFieldName = (fieldname, value) => {
    setHospitalSignUp(
      update(hospitalSignUp, {
        hospital_details: { [fieldname]: { $set: value } },
      })
    );
  };

    const onSelectDoctorSpecializations = (name, e) => {
      if(e.length>=ApplicationConstant.ONE){
        setValueByFieldName(name, e);
      }else{
        setValueByFieldName(name, "");
      }
    };

      const onNpiNumberInput = (e) => {
        e.preventDefault();
        if (e.target.value.length <= ApplicationConstant.TEN) {
          const obj = npiNumberValidation(e.target.value);
          if (obj.value !== false) {
            setValueByFieldName('npi_number', obj);
          }
        }
      };

      const onNpiTypeInput = (e) => {
        setValueByFieldName('npi_type', e);
      };

      const onAuthorizedOfficalName = (e) => {
        e.preventDefault();
        const obj = preferredNameValidation(
          e.target.value,
          hospitalSignUp.hospital_details.authorized_official_name
        );
        setValueByFieldName('authorized_official_name', obj);
      };

      const handleToggle = (e) => {
        setValueByFieldName(e.target.name, e.target.value);
      };
    return (
      <div className='row'>
        <InputElement
          className='col-sm-12'
          type='text'
          name='npi_number'
          value={hospitalSignUp.hospital_details.npi_number.value}
          onChange={onNpiNumberInput}
          onBlur={onNpiNumberInput}
          onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
          label={'NPI Number'}
          placeholder={'Please enter npi number'}
          isValid={hospitalSignUp.hospital_details.npi_number.valid}
          msg={hospitalSignUp.hospital_details.npi_number.msg}
          required={ApplicationConstant.FALSE}
        />

        <SelectElement
          classNm={'col-sm-6 d-none'}
          name={'npi_type'}
          onChange={onNpiTypeInput}
          className='reactSelect'
          classNamePrefix='react-select'
          options={oLabelInfo.npi_type}
          value={hospitalSignUp.hospital_details.npi_type}
          isSearchable={ApplicationConstant.TRUE}
          placeholder={''}
          label={'NPI Type*'}
        />

        <RadioToggleElement
          classNm={'col-sm-12'}
          label={'Malpractice Insurance*'}
          name={'malpractice_insurance'}
          onChange={handleToggle}
          selectedValue={hospitalSignUp.hospital_details.malpractice_insurance}
        />

        <RadioToggleElement
          classNm={'col-sm-12'}
          label={'HIPAA Compliance*'}
          name={'hipaa_compliance'}
          onChange={handleToggle}
          selectedValue={hospitalSignUp.hospital_details.hipaa_compliance}
        />

        <InputElement
          className='col-sm-12'
          type='text'
          name='authorized_official_name'
          value={hospitalSignUp.hospital_details.authorized_official_name.value}
          onChange={onAuthorizedOfficalName}
          placeholder='Please enter official name'
          label={'Authorized Official Name*'}
          isValid={hospitalSignUp.hospital_details.authorized_official_name.valid}
          msg={hospitalSignUp.hospital_details.authorized_official_name.msg}
        />

        <SelectElement
          classNm={'col-sm-12'}
          name={'specializations'}
          isMulti={ApplicationConstant.TRUE}
          onChange={(e) => {
            onSelectDoctorSpecializations('doctor_specializations', e);
          }}
          className='reactSelect'
          classNamePrefix='react-select'
          options={oLabelInfo.specializations}
          value={hospitalSignUp.hospital_details.doctor_specializations}
          isSearchable={ApplicationConstant.TRUE}
          stayMenuOnSelect={ApplicationConstant.TRUE}
          placeholder={''}
          label={'Specialty'}
        />
      </div>
    )
}

export default HospitalBackground;
