import React, { useContext } from 'react';
import { FormGroup, Input, InputGroup } from "reactstrap";
import UserRegistrationContext from '../../../contexts/user-registration/UserRegistrationContext';
import update from 'immutability-helper';
import SkipToNextProfile from './SkipToNextProfile';
import { ApplicationConstant } from '../../../constants/application.constant';
import FormCheckBox from '../../common/formElements/FormCheckBox';

function PatientProfilePronoun({aPronounOption}) {
    const [oUserRegistration, setUserRegistration] = useContext(UserRegistrationContext);

    const handleSelect = (id) => {
        const i = aPronounOption.findIndex((o) => o.id === id);
        if (i > -ApplicationConstant.ONE) {
            aPronounOption[i].isChecked = !aPronounOption[i].isChecked;
          const selectedPronoun = aPronounOption.filter((o) => o.isChecked);
          setUserRegistration(
            update(oUserRegistration, {
              patient_profile: { user_pronouns: { $set: selectedPronoun } },
            })
          );
        }
      };

    const onOtherInput = (e) => {
        e.preventDefault();
        setUserRegistration(update(oUserRegistration, {patient_profile: {other_pronoun : {$set: e.target.value}}}));
    }

    return (
        <div className="form-step">
            <h3>{"Pronouns (Select all that apply)"}</h3>
            <form className="w-100">
                <FormGroup tag="fieldset">
                    <div className="row">
                        {
                            aPronounOption.map((item)=> {
                                if (
                                    oUserRegistration.patient_profile.user_pronouns.find(
                                      (o) => o.title.toLowerCase() === ApplicationConstant.OTHER_LOWER_CASE
                                    ) &&
                                    item.title.toLowerCase() === ApplicationConstant.OTHER_LOWER_CASE
                                  ) {
                                    return (
                                        <div key={item.id} className="col-sm-6">
                                            <InputGroup>
                                                <div className="label-float">
                                                    <Input value={oUserRegistration.patient_profile.other_pronoun}
                                                    onChange={onOtherInput} type="text" maxLength="100" placeholder="Please enter other"
                                                    autoFocus/>
                                                    <label>{`${item.title}`}</label>
                                                </div>
                                            </InputGroup>
                                        </div>
                                    );
                                } else {
                                    return (
                                        <FormCheckBox
                                            key={item.id}
                                            keyId={item.id}
                                            isChecked={item.isChecked}
                                            item={item}
                                            labelDescription={item.description}
                                            label={`${item.title}`}
                                            handleSelect={handleSelect}
                                        />
                                    );
                                }
                            })
                        }
                    </div>
                </FormGroup>
            </form>
            <SkipToNextProfile />
        </div>
    )
}

export default PatientProfilePronoun;
