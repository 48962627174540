import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Button } from 'reactstrap';
import { PageTitles, RouteConstants } from '../../../constants/application.constant';
import SpinnerComponent from '../../common/spinner/SpinnerComponent';
import useDocumentTitle from '../../../utils/DocumentTitle';
import PageLogo from '../../common/logo/PageLogo';
import ApiConstant from '../../../constants/ApiConstant';
import axiosInstance from '../../../axios/axiosInstance';
import SummaryMultiValues from "../SummaryMultiValues";
import SummarySingleValue from '../SummarySingleValue';
import ProfileCompleteMessage from '../ProfileCompleteMessage';
import SummaryHook from './DoctorSummaryHook';

function DoctorSummary(props) {
  const { isUserProfile, doctorSummaryData, isProfileDateSaved } = props;
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [isProfileCompleted, setProfileCompleted] = useState(false);
  const [oDoctorProfile, setDoctorProfile] = useState({});
  const { updateProfile } = SummaryHook(onCallback);
  useDocumentTitle(PageTitles.DOCTOR_SUMMARY);

  useEffect(() => {
    setLoading(true);
    axiosInstance.get(`${ApiConstant.DOCTOR_PROFILE}`).then(
      (res) => {
        if (res && res.data) {
          const oProfile = res.data.data || {};
          updateProfile(oProfile);
        }
      },
      (_error) => {
        setLoading(false);
      }
    );
  }, []);

  useEffect(() => {
    isProfileDateSaved && updateProfile(doctorSummaryData);
  }, [isProfileDateSaved]);

  function onCallback(oProfile, isProfileCompleted) {
    if (oProfile) {
      setDoctorProfile(oProfile);
      setProfileCompleted(isProfileCompleted);
    }
    setLoading(false);
  }

  const handleEditClick = (e) => {
    e.preventDefault();
    history.replace(RouteConstants.REGISTRATION_DOCTOR);
  };

  const handleClick = (e) => {
    e.preventDefault();
    history.replace(RouteConstants.DASHBOARD_DOCTOR);
  };

  return (
    <>
      {isLoading ? (
        <SpinnerComponent />
      ) : (
        <div className="step-page">
          <div className="middle">
            <div className="container">
              {!isUserProfile ? <PageLogo /> : ""}
              <div className="form-step summary">
                <form className="w-100"></form>
                {!isUserProfile ? (
                  <div className="emal-sent mt-50">
                    <div className="check-box mb-30">
                      <span className="material-icons mt-5">done</span>
                    </div>
                    <div className="mb-30">
                      <h1>Thank you, your profile has been created. </h1>
                      <ProfileCompleteMessage
                        isCompleted={isProfileCompleted}
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div>
                  <div className="detail">
                    <div className="number-heading">1. Pronouns</div>
                    <div className="content">
                      <SummaryMultiValues
                        aData={oDoctorProfile.user_pronouns}
                        keyValue={"title"}
                      />
                    </div>
                  </div>
                  <div className="detail">
                    <div className="number-heading">2. Race</div>
                    <div className="content">
                      <SummaryMultiValues
                        aData={oDoctorProfile.user_races}
                        keyValue={"name"}
                      />
                    </div>
                  </div>
                  <div className="detail">
                    <div className="number-heading">3. Ethnicity</div>
                    <div className="content">
                      <SummarySingleValue
                        oData={oDoctorProfile?.ethnicity}
                        keyValue={"name"}
                      />
                    </div>
                  </div>
                  <div className="detail">
                    <div className="number-heading">4. Languages Spoken</div>
                    <div className="content">
                      <SummaryMultiValues
                        aData={oDoctorProfile.user_languages}
                        keyValue={"name"}
                      />
                    </div>
                  </div>
                  <div className="detail">
                    <div className="number-heading">5. Gender Identity</div>
                    <div className="content">
                      <SummaryMultiValues
                        aData={oDoctorProfile.user_identifications}
                        keyValue={"title"}
                      />
                    </div>
                  </div>
                  <div className="detail">
                    <div className="number-heading">6. Sexual Orientation</div>
                    <div className="content">
                      <SummaryMultiValues
                        aData={oDoctorProfile.user_sexual_orientations}
                        keyValue={"name"}
                      />
                    </div>
                  </div>
                  {!isUserProfile ? (
                    <div className="text-center w-100 mt-40">
                      <div className="row ">
                        <div className="col-sm-4">
                          <Button
                            className="w-100 mb-20"
                            color="secondary"
                            onClick={handleEditClick}>
                            Edit
                          </Button>
                        </div>
                        <div className="col-sm-8">
                          <Button
                            className="w-100 mb-20"
                            color="primary"
                            onClick={handleClick}>
                            Go to Dashboard
                          </Button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default DoctorSummary;
