import { ApplicationConstant } from '../constants/application.constant';

export const allowDecimalNumber = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!ApplicationConstant.DECIMAL_REGEX.test(keyValue)) {
        event.preventDefault();
    }
}


