import React, { useState } from 'react';
import { ToastContainer, Slide } from 'react-toastify';
import useDocumentTitle from '../../../../utils/DocumentTitle';
import { PageTitles, } from '../../../../constants/application.constant';
import SpinnerComponent from '../../../common/spinner/SpinnerComponent';
import DoctorMembershipInformation from './DoctorMembershipInformation';
import DoctorStripeAccount from './DoctorStripeAccount';


function DoctorMembership() {
  useDocumentTitle(PageTitles.MEMBERSHIP_INFORMATION);
  const [loader, setLoader] = useState(false);
  const [subscriptionLoader, setSubscriptionLoader] = useState(false);

    return (
      <>
        {(loader || subscriptionLoader) && <SpinnerComponent />}
        <div className="middle-content edit-profile user-right-container">
          <div className="pages">
            <div className="payment-section">
              <DoctorStripeAccount
                setLoader={setLoader}
              />
              <DoctorMembershipInformation
                setSubscriptionLoader={setSubscriptionLoader}
              />
            </div>
          </div>
        </div>
        <ToastContainer autoClose={4000} hideProgressBar transition={Slide} />
      </>
    );
}

export default DoctorMembership;


