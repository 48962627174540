import { iconType, RouteConstants } from '../../../../constants/application.constant';

export const DoctorSideMenuItems = [
  {
    name: 'Account Information',
    icon: 'place',
    iconClass: iconType.FILLED,
    to: RouteConstants.DOCTOR_ACCOUNT_INFORMATION,
  },
  {
    name: 'User Profile',
    icon: 'account_circle',
    iconClass: iconType.FILLED,
    to: RouteConstants.DOCTOR_USER_PROFILE,
  },
  {
    name: 'Background Information',
    icon: 'info',
    iconClass: iconType.FILLED,
    to: RouteConstants.DOCTOR_BACKGROUND_INFO,
  },
  {
    name: 'Membership Information',
    icon: 'monetization_on',
    iconClass: iconType.FILLED,
    to: RouteConstants.DOCTOR_PAYMENT,
  },
  {
    name: 'Payment History',
    icon: 'request_quote',
    iconClass: iconType.SHARP,
    to: RouteConstants.DOCTOR_PAYMENT_HISTORY,
  },
  {
    name: 'Change Password',
    icon: 'https',
    iconClass: iconType.SHARP,
    to: RouteConstants.CHANGE_PASSWORD,
  },
  {
    name: 'Logout',
    icon: 'logout',
    iconClass: iconType.OUTLINED,
  },
];
