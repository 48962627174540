import React, { useState } from 'react';
import moment from 'moment';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import axiosInstance from '../../../axios/axiosInstance';
import ApiConstant from '../../../constants/ApiConstant';
import { toast } from 'react-toastify';
import { Image } from './../image/Image';
import { patientPreferredName  } from '../../../utils/helper';
import { convertUtcToLocalTime, timeZoneFormats } from '../../../utils/TimezoneUtils';
import InputElement from '../formElements/InputElement';
import SuggestAvailability from './../../appointment/doctor/suggestAvailability/SuggestAvailability';
import { ApplicationConstant } from '../../../constants/application.constant';

const PendingAppointment = (props) => {
  const { modalClassName, isOpen, setModelOpen, userData, fetchUpdatedList, acceptModalOpen} = props;
  const currentTime = moment().format('YYYY-MM-DD HH:mm:ss');
  const [isSuggestAvailability, setSuggestAvailability] = useState(false);
  const [personalMessage, setPersonalMessage] = useState('');
  const [appointmentButton, setAppointmentButton] = useState(false);

  const appointmentId = {
    appointment_id: userData?.appointment_id
  }

  const canceledAppointment = async () => {
    await axiosInstance.post(ApiConstant.DOCTOE_CANCELED_PATIENT_APPOINTMENT, appointmentId).then(
      (response) => {
        if(response && response.data) {
          toast.success(response?.data?.message || '');
          fetchUpdatedList();
          setModelOpen();
        }
      })
      .catch((error) => {
        toast.error(error || '');
      })
  }

  const appointmentActionButtonStatus = () => {
    if(convertUtcToLocalTime(userData?.end_date_time, timeZoneFormats.FULL_TIME) < currentTime) {
      setAppointmentButton(true);
    } else {
      setAppointmentButton(false);
    }
  }

  const acceptAppointment = async () => {
    await axiosInstance.post(ApiConstant.DOCTOE_ACCEPTED_PATIENT_APPOINTMENT, appointmentId).then(
      (response) => {
        if(response && response.data) {
          acceptModalOpen();
          setModelOpen();
        }
      })
      .catch((error) => {toast.error(error || '');})
  }

  const validateActionButtons = () => {
    if(appointmentButton) {
      return true;
    }
    return false;
  }

  useState(()=>{
    appointmentActionButtonStatus();
  },[])

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={() => {
          setModelOpen();
        }}
        modalClassName={modalClassName}>
        <ModalHeader
          toggle={() => {
            setModelOpen();
          }}></ModalHeader>
        <ModalBody className="emal-sent">
          <h1>Pending Appointment </h1>
          <div className="profile-info">
            <div className="box">
              <div className="user-info">
                <div className="profile-img">
                  <Image
                    src={userData?.user?.profile_image || ""}
                    alt="Profile image"
                  />
                </div>
                <div className="head">
                  <div className="title-text">
                    <h2>
                      With:{" "}
                      <span>
                        {patientPreferredName(
                          userData?.user?.first_name,
                          userData?.user?.last_name,
                          userData?.is_disply_preferred_name,
                          userData?.user?.preferred_name
                        )}{" "}
                      </span>
                    </h2>
                    <h2>
                      Date:{" "}
                      <span>
                        {convertUtcToLocalTime(userData?.start_date_time, "ll")}
                      </span>
                    </h2>
                    <h2>
                      Time:{" "}
                      <span>
                        {convertUtcToLocalTime(userData?.start_date_time, "LT")}
                      </span>
                    </h2>
                    <h2>
                      Appointment:{" "}
                      <span className="ml-3">
                        {" "}
                        {ApplicationConstant.TELEHEALTH}
                      </span>
                    </h2>
                  </div>
                  <div className="button-group">
                    <Button
                      color="secondary"
                      disabled={validateActionButtons()}
                      onClick={canceledAppointment}>
                      Reject
                    </Button>
                    <Button
                      color="primary"
                      onClick={acceptAppointment}
                      disabled={validateActionButtons()}>
                      Accept
                    </Button>
                    <Button
                      className="d-none"
                      color="secondary"
                      onClick={() => setSuggestAvailability(true)}>
                      Suggest a Different Time
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <InputElement
            className="personal-message-textarea d-none"
            value={personalMessage}
            type="textarea"
            name="personal_message"
            onChange={(e) => {
              setPersonalMessage(e.target.value);
            }}
            placeholder="Leave a personal message"
            label={"Leave a personal message"}
          />
          {isSuggestAvailability && (
            <SuggestAvailability
              apointmentId={userData?.appointment_id}
              userId={userData?.user_id}
              modalClose={() => {
                setModelOpen();
              }}
              fetchUpdatedList={fetchUpdatedList}
            />
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default PendingAppointment;
