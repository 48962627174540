import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { DOLLAR_ICON } from "../../../../constants/image";
import IconTooltip from '../../../common/tooltip/IconTooltip';
import { ApplicationConstant, RouteConstants } from '../../../../constants/application.constant';
import { getSubscriptionPlan, createSubscriptionPlan } from './../../../subscription/SubscriptionPaymentUtils';
import SubscriptionCancellation from './../../../subscription/SubscriptionCancellation';

const PatientMembershipInformation = (props) => {
    const { setSubscriptionLoader } = props;
    const [subscriptionDetail, setSubscriptionDetail] = useState(null);
    const [isUserSubscribed, setUserSubscribed] = useState(null);
    const [subscriptionCancellationModal, setSubscriptionCancellationModal] = useState(false);
    const subscriptionPlanType = ApplicationConstant.ZERO;

    const toggleSubscriptionCancellation = () => {
        setSubscriptionCancellationModal(!subscriptionCancellationModal);
    }

    const getSubscriptionDetail = async () => {
        setSubscriptionLoader(true);
        await getSubscriptionPlan().then((response)=>{
          if(response && response.data) {
            const userSubscriptionDetail = response.data?.data[subscriptionPlanType] || '';
            const userSubscription = response.data?.data[subscriptionPlanType]?.subscriptions?.subscription_id || null;
            setSubscriptionDetail(userSubscriptionDetail);
            setUserSubscribed(userSubscription);
          }
        }).catch((error) => {toast.error(error || '')});
        setSubscriptionLoader(false);
    }

    const createSubscription = async () => {
        setSubscriptionLoader(true);
        await createSubscriptionPlan(subscriptionDetail?.price_id).then((response) => {
          if(response && response.data) {
            setSubscriptionLoader(false);
            const userSubscriptionRedirectionUrl = response.data?.data?.url || '';
            if(userSubscriptionRedirectionUrl) {
              window.location.href = userSubscriptionRedirectionUrl
            }
          }
        }).catch((error) => {toast.error(error || '')});
    }

    useEffect(()=>{
        getSubscriptionDetail();
    },[])

    return (
        <>
            <div className="card-sec">
                <h3 className="mt-20">Membership Information</h3>
                <div class="box">
                    {!isUserSubscribed &&
                        <div className="not-a-member align-items-center">
                            <img src={DOLLAR_ICON} alt="" />
                            <div className="info">
                                <h4>You are currently not a member</h4>
                                <p>The first 90 calendar days are free. After 90 days, you will be invoiced $15/month. You can cancel your subscription whenever you want.</p>
                            </div>
                        </div>
                    }
                    <div className="monthly-price">
                        <div className="price">
                            <strong></strong><p>$30</p>${subscriptionDetail?.unit_amount}<span>Monthly</span>
                        </div>
                        <ul>
                            <li>
                                <span className="material-icons">account_circle</span>
                                <p>Find the best provider that fits your needs</p>
                            </li>
                            <li>
                                <span className="material-icons">event</span>
                                <p>Book a video consultation with your provider</p>
                            </li>
                        </ul>
                        {isUserSubscribed &&
                            <div className="info">
                                <p>
                                The first 90 calendar days are free. After 90 days, you will be invoiced $15/month. You can cancel your subscription whenever you want.
                                </p>
                                <span>
                                    The credit card used will be billed full price every month from time of purchase.
                                </span>
                            </div>
                        }
                    </div>
                    <div className={`membership-info-footer btn-sec ${isUserSubscribed ? 'update-membership-footer' : ''}`}>
                        <p>Note: Membership fees do not include session cost.</p>
                        <div className="membership-info-tooltip">
                            <span id="membershipInfo" class="material-icons">info</span>
                            {IconTooltip(
                                'bottom',
                                'membership',
                                `A recurring fee of $${subscriptionDetail?.unit_amount} will be charged monthly.`,
                                'Info'
                            )}
                        </div>
                        {isUserSubscribed ?
                            <div className="btn-wrapper">
                                <button className="btn btn-secondary" onClick={toggleSubscriptionCancellation}>Cancel</button>
                                <button className="btn btn-primary" onClick={createSubscription}>Update</button>
                            </div>
                            :<div className="btn-wrapper">
                            <button className="btn btn-primary" onClick={createSubscription}>Sign up!</button>
                            <Link to={RouteConstants.PRIVACY_POLICY}>See Terms of Agreement</Link>
                        </div>
                        }
                    </div>
                </div>
            </div>
            <SubscriptionCancellation
                isOpen={subscriptionCancellationModal}
                setModelOpen={toggleSubscriptionCancellation}
                updateMembersip={getSubscriptionDetail}
                subscriptionId={isUserSubscribed}
                modalClassName="subscription-plan"
                title="Sorry to see you go!"
                subTitle="We are sincerely sorry to see you go. Please feel free to contact us directly if we can support you in any way or would like to leave us feedback."
                primaryButtonText="Yes cancel"
                secondaryButtonText="I will stay"
                linkText="See Terms of Agreement"
            />
        </>
    )
}

export default PatientMembershipInformation;
