import React, { useContext, useState, useEffect, Fragment } from 'react';
import { ToastContainer, toast, Slide } from 'react-toastify';
import PageLogo from '../../common/logo/PageLogo';
import UserRegistrationContext from '../../../contexts/user-registration/UserRegistrationContext';
import { ApplicationConstant, PageTitles, HttpStatusCode, profileModeTypes } from '../../../constants/application.constant';
import PatientHealthProfileFooter from './PatientFooter';
import axiosInstance from '../../../axios/axiosInstance';
import ApiConstant from '../../../constants/ApiConstant';
import PatientProfileInformation from './PatientProfileInformation';
import PatientProfileStepper from './PatientProfileStepper';
import update from 'immutability-helper';
import { getActiveStateAndValidity, goToNextStep, goToPreviousStep, goToStepNumber } from '../../../utils/PatientProfileUtil';
import { useHistory, useLocation } from 'react-router';
import useDocumentTitle from './../../../utils/DocumentTitle';
import Spinner from '../../common/spinner/SpinnerComponent';

const MAX_STEP = ApplicationConstant.NINE;
let oFormFields = {};
let aLanguageOptions = [];

function PatientProfileContainer(props) {
    const history = useHistory();
    const location = useLocation();
    const [oUserRegistration, setUserRegistration] = useContext(UserRegistrationContext);
    const [isLoading, setLoading] = useState(true);
    const [editModeLoading, setEditModeLoading] = useState(false);
    useDocumentTitle(PageTitles.PATIENT_REGISTRATION);

    useEffect(async () => {
        setEditModeLoading(true);
        const state = location.state;
        let value = 1;
        if (state && state.step) {
            value = Number(state.step)
        }
        await getPatientProfileFormFields();
        await getLanguageSpokenOptions(oUserRegistration);
        oFormFields.languages = aLanguageOptions;
        await getPatientProfilePreference();
        updateAge(state.profile);
        await getSavedPatientProfile(value);
        setEditModeLoading(false)
        return () => {
            setLoading(null);
        };
    }, [])

    async function updateProfile(oSavedProfile, value) {
        const oData = {
            pronoun_id: oSavedProfile?.pronoun_id || '',
            other_pronoun: oSavedProfile?.other_pronoun || '',
            pronoun_name: oSavedProfile?.pronoun?.title || '',
            current_step: value,
            age: oSavedProfile?.user?.age || '',
            date_of_birth: oSavedProfile?.user?.date_of_birth || '',
            ethnicity_id: oSavedProfile?.ethnicity_id || '',
            ethnicity_name: oSavedProfile?.ethnicity?.name || '',
            height_feet: oSavedProfile?.height_feet || '',
            height_inches: oSavedProfile?.height_inches || '',
            identification_id: '',
            identification_name: '',
            notes: oSavedProfile?.notes || '',
            other_ethnicity: oSavedProfile?.other_ethnicity || '',
            other_identification: '',
            other_language: '',
            other_race: oSavedProfile?.other_race || '',
            other_sexual_orientation: '',
            race_id: oSavedProfile?.race_id || '',
            race_name: oSavedProfile?.race?.name || '',
            sexual_orientation_id: '',
            sexual_orientation_name: '',
            user_identifications: oSavedProfile.user_identifications.map(o => {
                return { "id": o.identification_id, title: o.title, isChecked: true, "other_identification": o.other_identification };
            }),
            user_languages: oSavedProfile.user_languages.map(o => {
                return { "id": o.language_id, name: o.name, isChecked: true, "other_language": o.other_language };
            }),
            user_sexual_orientations: oSavedProfile.user_sexual_orientations.map(o => {
                return { "id": o.sexual_orientation_id, name: o.name, isChecked: true, "other_sexual_orientation": o.other_sexual_orientation };
            }),
            user_pronouns: oSavedProfile.user_pronouns.map(o => {
            return { "id": o.pronoun_id, title: o.title, isChecked: true, "other_pronoun": o.other_pronoun };
            }),
            user_races: oSavedProfile.user_races.map(o => {
                return { "id": o.race_id, name: o.name, isChecked: true, "other_race": o.other_race };
            }),
            weight: oSavedProfile.weight || ''
        }

        updateIdentifications(oData);
        updateSexualOrientations(oData);
        updateLanguages(oData);
        updatePronoun(oData);
        updateRace(oData)
        updatePreferences(oData, oSavedProfile);
        setUserRegistration(update(oUserRegistration, { patient_profile: { $set: oData } }));

    }

    function updatePreferences(oData, oSavedProfile){

        oData.gender_prefrence_first = oFormFields?.preferences?.gender.find((item)=>item.id === oSavedProfile?.patient_preferences?.first_gender_id);
        oData.gender_prefrence_second= oFormFields?.preferences?.gender.find((item)=>item.id === oSavedProfile?.patient_preferences?.second_gender_id);
        oData.age_prefrence_first= oFormFields?.preferences?.age_range.find((item)=>item.value === oSavedProfile?.patient_preferences?.first_age_range)||'';
        oData.age_prefrence_second= oFormFields?.preferences?.age_range.find((item)=>item.value === oSavedProfile?.patient_preferences?.second_age_range)||'';
        oData.race_prefrence_first=oFormFields?.preferences?.races.find((item)=>item.id === oSavedProfile?.patient_preferences?.first_race_id)||'';
        oData.race_prefrence_second=oFormFields?.preferences?.races.find((item)=>item.id === oSavedProfile?.patient_preferences?.second_race_id)||'';
        oData.orentation_prefrence_first=oFormFields?.preferences?.sexual_orientations.find((item)=>item.id === oSavedProfile?.patient_preferences?.first_sexual_orientation_id)||'';
        oData.orentation_prefrence_second=oFormFields?.preferences?.sexual_orientations.find((item)=>item.id === oSavedProfile?.patient_preferences?.second_sexual_orientation_id) || '';
        oData.ethnicity_prefrence_first= oFormFields?.preferences?.ethnicity.find((item)=>item.id === oSavedProfile?.patient_preferences?.first_ethnicity_id) || '';
        oData.ethnicity_prefrence_second= oFormFields?.preferences?.ethnicity.find((item)=>item.id === oSavedProfile?.patient_preferences?.second_ethnicity_id) || '';
        oData.language_prefrence_first= oFormFields?.preferences?.language.find((item)=>item.id === oSavedProfile?.patient_preferences?.first_language_id) || '';
        oData.language_prefrence_second= oFormFields?.preferences?.language.find((item)=>item.id === oSavedProfile?.patient_preferences?.second_language_id) || '';

    }

    function updateIdentifications(oData) {
        const allIdentifications = oFormFields.identifications || [];
        const identifications = oData.user_identifications || [];
        for (const oDen of identifications) {
            const selectedIdentification = allIdentifications.find(o => o.id === oDen.id);
            if (selectedIdentification) {
                selectedIdentification.isChecked = true;
            }
        }
        const otherIdentity = identifications.find(o => o.title === ApplicationConstant.OTHER)
        if (otherIdentity) {
            oData.other_identification = otherIdentity.other_identification || '';
        }
    }

    function updatePronoun(oData) {
        const allPronouns = oFormFields.pronouns || [];
        const pronouns = oData.user_pronouns || [];
        for (const oDen of pronouns) {
            const selectedPronoun = allPronouns.find(o => o.id === oDen.id);
            if (selectedPronoun) {
                selectedPronoun.isChecked = true;
            }
        }
        const otherPronoun = pronouns.find(o => o.title === ApplicationConstant.OTHER)
        if (otherPronoun) {
            oData.other_pronoun = otherPronoun.other_pronoun || '';
        }
    }

    function updateRace(oData) {
        const allRaces = oFormFields.races || [];
        const races = oData.user_races || [];
        for (const oDen of races) {
            const selectedRace = allRaces.find(o => o.id === oDen.id);
            if (selectedRace) {
                selectedRace.isChecked = true;
            }
        }
        const otherRace = races.find(o => o.name === ApplicationConstant.OTHER)
        if (otherRace) {
            oData.other_race = otherRace.other_race || '';
        }
    }

    function updateSexualOrientations(oData) {
        const allSexOrientations = oFormFields.sexual_orientations || [];
        const sexOrientations = oData.user_sexual_orientations || [];
        for (const oDen of sexOrientations) {
            const selectedOrientation = allSexOrientations.find(o => o.id === oDen.id);
            if (selectedOrientation) {
                selectedOrientation.isChecked = true;
            }
        }
        const otherSexOrientation = sexOrientations.find(o => o.name === ApplicationConstant.OTHER)
        if (otherSexOrientation) {
            oData.other_sexual_orientation = otherSexOrientation.other_sexual_orientation || '';
        }
    }

    function updateLanguages(oData) {
        const allLanguages = oFormFields.languages || [];
        const aLanguages = oData.user_languages || [];
        for (const oDen of aLanguages) {
            const selectedLanguage = allLanguages.find(o => o.id === oDen.id);
            if (selectedLanguage) {
                selectedLanguage.isChecked = true;
            }
        }
        const otherLanguage = aLanguages.find(o => o.name === ApplicationConstant.OTHER)
        if (otherLanguage) {
            oData.other_language = otherLanguage.other_language || '';
        }
    }

    async function getPatientProfilePreference(){
        await axiosInstance.get(`${ApiConstant.PATIENT_PREFERENCE}`)
            .then((res) => {
                if (res && res.data) {
                    oFormFields.preferences = processPatientPreferenceData(res.data.data);
                }
                setLoading(false);
            }, (_err) => {
                setLoading(false);
            });
    }

    function processPatientPreferenceData(data){
        Object.keys(data).forEach((key)=>{
            if(key === 'age_range' || key === 'location_range'){
                data[`${key}`] = Object.entries(data[`${key}`]).map(item=> ({value: item[0], label: item[1]}));
            } else {
                data[`${key}`] = data[`${key}`].map(item=> ({...item, label: item.title?item.title:item.name, value: item.title?item.title:item.name}));
            }
        })
        return data;
    }

    async function getSavedPatientProfile(value) {
        await axiosInstance.get(`${ApiConstant.PATIENT_PROFILE}`)
            .then((res) => {
                if (res && res.data) {
                    const oProfile = res.data.data || {};
                    if (!oProfile || (Object.keys(oProfile).length === 0 && oProfile.constructor === Object)
                        || !oProfile.hasOwnProperty('user_identifications')) {
                        setLoading(false);
                        return;
                    }
                    updateProfile(oProfile, value);
                }
                setLoading(false);
            }, (_) => {
                setLoading(false);
            });
    }

    function updateAge(profile){
        if(profile && profile.age) {
            setUserRegistration(update(oUserRegistration, { patient_profile: {age: { $set: profile.age } }}))
        }
    }

    const nextStep = (e) => {
        e.preventDefault();
        savePatientProfile(oUserRegistration, setLoading)
        .then(response => {
            if(response && response.status === HttpStatusCode.SUCCESS_REQUEST) {
                if(props?.profileMode === profileModeTypes.EDIT) {
                    props?.getSavedData(response);
                } else {
                    goToNextStep(oUserRegistration, setUserRegistration);
                }
            }
        }).catch((error) => {
            toast.error(error || '');
        });

    }

    const prevStep = (e) => {
        e.preventDefault();
        goToPreviousStep(oUserRegistration, setUserRegistration);
    }

    const handleStepClick = (e) => {
        e.preventDefault();
        goToStepNumber(parseInt(e.target.dataset.step), oUserRegistration, setUserRegistration);
    }

    async function savePatientInformation(event) {
        if (event) {
            event.preventDefault();
        }
        await savePatientProfile(oUserRegistration, setLoading);
        history.replace('/patient/summary');
    }

    return (
        <Fragment>
            {props?.profileMode === profileModeTypes.EDIT ? (
                <>{editModeLoading && <Spinner />}</>
            ):(
                <>{isLoading && <Spinner />}</>
            )}
            { <div className="step-page">
                <div hidden={props?.profileMode === profileModeTypes.EDIT}>
                </div>
                <div className="middle">
                    <div className="container">
                        <div hidden={props?.profileMode === profileModeTypes.EDIT}>
                            <PageLogo />
                            <div className="head-title">
                                <div className="heading">
                                    Patient Registration
                                </div>
                                <div className="sub-heading">
                                    {(oUserRegistration.patient_profile.current_step === ApplicationConstant.NINE)?"Preferences":"Create your Health Profile!"}
                                </div>
                            </div>
                            <PatientProfileStepper handleStepClick={handleStepClick} />
                        </div>
                        <PatientProfileInformation oInputFields={oFormFields} handleSave={savePatientInformation} />
                        <PatientHealthProfileFooter
                            maxStep={MAX_STEP}
                            val={oUserRegistration.patient_profile.current_step}
                            handleNext={nextStep} handlePrev={prevStep}
                            handleSave={savePatientInformation}
                            isDisabled={!getActiveStateAndValidity(oUserRegistration, oUserRegistration.patient_profile.current_step).isValid}
                            handleActionMode={props?.profileMode}
                            handleCancel={props?.closeModal}
                        />
                    </div>
                </div>
            </div>}
            <ToastContainer autoClose={4000} hideProgressBar transition={Slide} />
            </Fragment>
    );
}

async function getPatientProfileFormFields() {
    try {
        const oResponse = await axiosInstance.get(ApiConstant.PATIENT_FORM_FIELDS);
        oFormFields = oResponse && oResponse.data && oResponse.data.data ? oResponse.data.data : {};
    } catch (e) {
        oFormFields = {};
    }
}

// function to get languages
async function getLanguageSpokenOptions(oUser) {
    try {
        const oResponse = await axiosInstance.get(ApiConstant.LANGUAGES);
        const aData = oResponse && oResponse.data && oResponse.data.data ? oResponse.data.data : [];
        aData.map(o => {
            o.isChecked = !!(oUser.patient_profile.user_languages.find(l => l === o.id));
        })
        aLanguageOptions = JSON.parse(JSON.stringify(aData));
    } catch (e) {
        aLanguageOptions = [];
    }
}


function checkValidations(oPayload) {
    const userLanguages = oPayload.user_languages || [];
    const sexOrientations = oPayload.user_sexual_orientations || [];
    const userIdentifications = oPayload.user_identifications || [];
    if (userLanguages.length > ApplicationConstant.ZERO) {
        const otherValue = oPayload.other_language || '';
        const otherLanguage = userLanguages.find(o => o.name === ApplicationConstant.OTHER);
        if (otherLanguage && !otherValue) {
            const otherIndex = userLanguages.findIndex(o => o.name === ApplicationConstant.OTHER);
            oPayload.user_languages.splice(otherIndex, 1);
        }
    }
    if (sexOrientations.length > ApplicationConstant.ZERO) {
        const otherValue = oPayload.other_sexual_orientation || '';
        const otherOrientation = sexOrientations.find(o => o.name === ApplicationConstant.OTHER);
        if (otherOrientation && !otherValue) {
            const otherIndex = sexOrientations.findIndex(o => o.name === ApplicationConstant.OTHER);
            oPayload.user_sexual_orientations.splice(otherIndex, 1);
        }
    }
    if (userIdentifications.length > ApplicationConstant.ZERO) {
        const otherValue = oPayload.other_identification || '';
        const otherIdentification = userIdentifications.find(o => o.title === ApplicationConstant.OTHER);
        if (otherIdentification && !otherValue) {
            const otherIndex = userIdentifications.findIndex(o => o.title === ApplicationConstant.OTHER);
            oPayload.user_identifications.splice(otherIndex, 1);
        }
    }
}


async function savePatientProfile(oRegistrationData, loadSetter) {
    try {
        loadSetter(ApplicationConstant.TRUE);
        const oPreferences = {
            "first_gender_id":oRegistrationData.patient_profile.gender_prefrence_first?.id || -1,
            "second_gender_id":oRegistrationData.patient_profile.gender_prefrence_second?.id || -1,
            "first_age_range":oRegistrationData.patient_profile.age_prefrence_first?.value|| '',
            "second_age_range":oRegistrationData.patient_profile.age_prefrence_second?.value || '',
            "first_race_id":oRegistrationData.patient_profile.race_prefrence_first?.id || -1,
            "second_race_id":oRegistrationData.patient_profile.race_prefrence_second?.id || -1,
            "first_sexual_orientation_id":oRegistrationData.patient_profile.orentation_prefrence_first?.id || -1,
            "second_sexual_orientation_id":oRegistrationData.patient_profile.orentation_prefrence_second?.id || -1,
            "first_ethnicity_id":oRegistrationData.patient_profile.ethnicity_prefrence_first?.id || -1,
            "second_ethnicity_id":oRegistrationData.patient_profile.ethnicity_prefrence_second?.id || -1,
            "first_language_id":oRegistrationData.patient_profile.language_prefrence_first?.id || -1,   
            "second_language_id":oRegistrationData.patient_profile.language_prefrence_second?.id || -1
        }
        const oPayload = {
            "pronoun_id": oRegistrationData.patient_profile.pronoun_id,
            "other_pronoun": (getActiveStateAndValidity(oRegistrationData, ApplicationConstant.ONE).isOther ?
                oRegistrationData.patient_profile.other_pronoun : ApplicationConstant.NO_VALUE_STRING),
            "weight": oRegistrationData.patient_profile.weight ? Number(oRegistrationData.patient_profile.weight) : ApplicationConstant.NO_VALUE_STRING,
            "height_feet": oRegistrationData.patient_profile.height_feet,
            "height_inches": oRegistrationData.patient_profile.height_inches,
            "date_of_birth": oRegistrationData.patient_profile.date_of_birth,
            "age": oRegistrationData.patient_profile.age ? Number(oRegistrationData.patient_profile.age) : ApplicationConstant.ZERO,
            "race_id": oRegistrationData.patient_profile.race_id,
            "other_race": (getActiveStateAndValidity(oRegistrationData, ApplicationConstant.THREE).isOther ?
                oRegistrationData.patient_profile.other_race : ApplicationConstant.NO_VALUE_STRING),
            "ethnicity_id": oRegistrationData.patient_profile.ethnicity_id,
            "other_ethnicity": (getActiveStateAndValidity(oRegistrationData, ApplicationConstant.FOUR).isOther ?
                oRegistrationData.patient_profile.other_ethnicity : ApplicationConstant.NO_VALUE_STRING),
            "identification_id": oRegistrationData.patient_profile.identification_id,
            "other_identification": (getActiveStateAndValidity(oRegistrationData, ApplicationConstant.SIX).isOther ?
                oRegistrationData.patient_profile.other_identification : ApplicationConstant.NO_VALUE_STRING),
            "sexual_orientation_id": oRegistrationData.patient_profile.sexual_orientation_id,
            "other_sexual_orientation": (getActiveStateAndValidity(oRegistrationData, ApplicationConstant.SEVEN).isOther ?
                oRegistrationData.patient_profile.other_sexual_orientation : ApplicationConstant.NO_VALUE_STRING),
            "user_languages": oRegistrationData.patient_profile.user_languages.map(o => {
                if (o.name === ApplicationConstant.OTHER) {
                    return { "id": o?.id, name: o.name, "other_language": oRegistrationData.patient_profile.other_language };
                } else {
                    return { "id": o?.id, name: o.name, "other_language": '' };
                }
            }),
            "user_identifications": oRegistrationData.patient_profile.user_identifications.map(o => {
                if (o.title === ApplicationConstant.OTHER) {
                    return { "id": o?.id, title: o.title, "other_identification": oRegistrationData.patient_profile.other_identification };
                } else {
                    return { "id": o?.id, title: o.title, "other_identification": '' };
                }
            }),
            "user_sexual_orientations": oRegistrationData.patient_profile.user_sexual_orientations.map(o => {
                if (o.name === ApplicationConstant.OTHER) {
                    return { "id": o?.id, name: o.name, "other_sexual_orientation": oRegistrationData.patient_profile.other_sexual_orientation };
                } else {
                    return { "id": o?.id, name: o.name, "other_sexual_orientation": '' };
                }
            }),
            "user_pronouns": oRegistrationData.patient_profile.user_pronouns.map(o => {
                if (o.title === ApplicationConstant.OTHER) {
                    return { "id": o?.id, title: o.title, "other_pronoun": oRegistrationData.patient_profile.other_pronoun };
                } else {
                    return { "id": o?.id, title: o.title, "other_pronoun": '' };
                }
            }),
            "user_races": oRegistrationData.patient_profile.user_races.map(o => {
                if (o.name === ApplicationConstant.OTHER) {
                    return { "id": o?.id, name: o.name, "other_race": oRegistrationData.patient_profile.other_race };
                } else {
                    return { "id": o?.id, name: o.name, "other_race": '' };
                }
            }),
            "other_language": (getActiveStateAndValidity(oRegistrationData, ApplicationConstant.FIVE).isOther ?
                oRegistrationData.patient_profile.other_language : ApplicationConstant.NO_VALUE_STRING),
            "notes": oRegistrationData.patient_profile.notes,
            "patient_preferences": oPreferences
        };
        checkValidations(oPayload);
        const response = await axiosInstance.post(ApiConstant.PATIENT_ACCOUNT_CREATION, oPayload);
        loadSetter(ApplicationConstant.FALSE);
        return response;
    } catch (e) {
        loadSetter(ApplicationConstant.FALSE);
    }
}

export default PatientProfileContainer;
