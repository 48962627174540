import React, { useState } from 'react';
import { ToastContainer, Slide, toast } from 'react-toastify';
import DoctorSummary from '../../../summary/doctor/DoctorSummary';
import useDocumentTitle from '../../../../utils/DocumentTitle';
import { PageTitles, StatusType } from '../../../../constants/application.constant';
import DoctorEditProfile from '../../../common/modal/DoctorEditProfile';

function DoctorUserProfile() {
  useDocumentTitle(PageTitles.USER_PROFILE);
  const [isOpen, modalOpen] = useState(false);
  const [doctorUpdatedProfileData, setDoctorUpdatedProfileData] = useState(null);
  const [fetchDataStatus, setFetchDataStatus] = useState(false);

  const toggleEditModal = () => {
    modalOpen(!isOpen);
    setFetchDataStatus(false);
  };

  const fetchProfileUpdatedData = (status, data) => {
    setDoctorUpdatedProfileData(data?.data?.data || {});
    setFetchDataStatus(true);
    toggleEditModal();
    if (status === StatusType.SUCCESS) {
      toast.success(data?.data?.message);
    } else {
      toast.error(data?.message);
    }
  };

  return (
    <div className="middle-content edit-profile user-right-container">
      <div className="pages">
        <div className="profile-information">
          <div className="form-step max-w-100">
            <div className="content p-0 position-relative doctor-user-profile">
              <div className="edit-profile-btn">
                <div className="btn-white-outline">
                  <span className="material-icons md-dark" onClick={toggleEditModal}>
                    edit
                  </span>
                </div>
              </div>
              <DoctorSummary
                isUserProfile={true}
                doctorSummaryData={doctorUpdatedProfileData}
                isProfileDateSaved={fetchDataStatus}
              />
              <DoctorEditProfile
                isOpen={isOpen}
                modalOpen={toggleEditModal}
                className={'edit-profile-modal'}
                modalClassName={'right'}
                fetchProfileData={fetchProfileUpdatedData}
              />
              <ToastContainer autoClose={4000} hideProgressBar transition={Slide} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DoctorUserProfile;
