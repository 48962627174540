import React from 'react';

function PatientThanksPage() {

  return (
    <div>
      <div className='emal-sent thanks mt-50'>
        <form className='w-100'>
          <div className='w-350' style={{ maxWidth: '365px' }}>
            <div className='check-box mb-50'>
              <span class='material-icons'>done</span>
            </div>
            <h1>Thank you for registering with equalityMD.</h1>
            <h2>
              Congratulations! Your account has been successfully created at equalityMD. Click the
              link sent on your registered email to verify your account. It is mandatory to verify
              your email id in order to sign into the application.
            </h2>
            <div className='sub-heading theme-color'>Thank you!</div>
            <div className='sub-heading font-600 theme-color mt-5'>equalityMD</div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PatientThanksPage;
