import React from 'react';
import InputElement from '../../../common/formElements/InputElement';
import SelectElement from '../../../common/formElements/SelectElement';
import DateElement from '../../../common/formElements/DateElement';
import ReactFileUploader from '../../../common/fileUpload/ReactFileUploader';
import RadioToggleElement from '../../../common/formElements/RadioToggleElement';
import { futureDateValidation, idNumberValidation, npiNumberValidation } from '../../../../validators/CommonValidation';
import { ApplicationConstant, BasePathConstant, DateConstant } from '../../../../constants/application.constant';

function DoctorProfessionalInformation({profileData, setValueByFieldName, options, fileName, setFilename}) {

    const onNpiNumberInput = (e) => {
        e.preventDefault();
        if (e.target.value.length <= ApplicationConstant.TEN) {
          const obj = npiNumberValidation(e.target.value);
          if (obj.value !== false) {
              setValueByFieldName('npi_number', obj);
          }
        }
      };

    const onLisenceNumberInput = (e) => {
        e.preventDefault();

        setValueByFieldName('license_number', {
            value: e.target.value,
            msg: '',
            valid: ApplicationConstant.TRUE,
            touched: ApplicationConstant.TRUE,
        });
    };

    const onExpirationDateInput = (date) => {
        const obj = futureDateValidation(date);
        setValueByFieldName('expiration_date', obj);
      };

    const onFileUpload = (url, name) => {
      setFilename(name);
      setValueByFieldName("document", { value: url });
    };

    const onFileRemove = () => {
      setFilename('');
        setValueByFieldName('document', {value: ''});
      };

    const onMultiSelectInput = (name, e) => {
        if (e.length >= 1) {
          setValueByFieldName(name, e);
        } else {
          setValueByFieldName(name, '');
        }
      };

    const handleToggle = (e) => {
        setValueByFieldName(e.target.name, e.target.value);
      };

    const otherTextInput = (e) => {
        e.preventDefault();
        setValueByFieldName(e.target.name, e.target.value);
      };

    return (
      <>
        <h3 className="mt-24">Professional Information</h3>
        <div className="row">
          <InputElement
            className="col-sm-6"
            type="text"
            name="npi_number"
            value={profileData?.npi_number?.value}
            onChange={onNpiNumberInput}
            onBlur={onNpiNumberInput}
            onKeyDown={(evt) =>
              ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
            }
            label={"NPI Number"}
            placeholder={"Please Enter"}
            isValid={profileData?.npi_number?.valid}
            msg={profileData?.npi_number?.msg}
            required={ApplicationConstant.FALSE}
          />
          <InputElement
            className="col-sm-6"
            type="text"
            name="license_number"
            onChange={onLisenceNumberInput}
            onBlur={onLisenceNumberInput}
            onKeyDown={(evt) =>
                ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
            }
            value={profileData?.license_number?.value}
            label={"License Number"}
            placeholder={"Please Enter Your License Number"}
            required={ApplicationConstant.FALSE}
          />
          <DateElement
            className="col-sm-12"
            type="date"
            name="expiration_date"
            value={profileData?.expiration_date?.value}
            onChange={onExpirationDateInput}
            dateFormat="MMM d, yyyy"
            minDate={new Date()}
            maxDate={new Date(DateConstant.END_DATE)}
            onKeyPress={(e) => {
              e.preventDefault();
            }}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            onKeyUp={(e) => {
              e.preventDefault();
            }}
            isValid={profileData?.expiration_date?.valid}
            msg={profileData?.expiration_date?.msg}
            label={"License Expiration Date"}
            required={ApplicationConstant.FALSE}
          />
          {/*<ReactFileUploader
            onSuccess={onFileUpload}
            onRemove={onFileRemove}
            id={"license_doc"}
            allowedExtensions={["jpg", "jpeg", "png", "pdf", "doc", "docx"]}
            allowedSize={10000000}
            basePath={BasePathConstant.DOCTOR_LICENSE}
            allowedTypeMsg={".jpg, .png, pdf and doc/docx allowed."}
            value={
              fileName !==
              ApplicationConstant.NO_VALUE_STRING
                ? fileName
                : null
            }
          />
          <div class="col-sm-12 pb-20">
            <p class="font-12 gray666">Maximum file size: 10 MB </p>
            <p class="font-12 gray666">
              Accepted files types: png, jpg, pdf, jpeg, doc/docx
            </p>
          </div>*/}
          <SelectElement
            classNm={"col-sm-12"}
            name={"state"}
            options={options?.states}
            isMulti={ApplicationConstant.TRUE}
            onChange={(e) => {
              onMultiSelectInput("state", e);
            }}
            className="reactSelect"
            classNamePrefix="react-select"
            value={profileData?.state}
            isSearchable={ApplicationConstant.TRUE}
            stayMenuOnSelect={ApplicationConstant.TRUE}
            placeholder={""}
            label={"State(s) of license*"}
          />
          <RadioToggleElement
            classNm={"col-sm-12"}
            label={"Are you a prescriber?*"}
            name={"prescription_availability"}
            onChange={handleToggle}
            selectedValue={profileData?.prescription_availability}
          />
          <RadioToggleElement
            classNm={"col-sm-12 d-none"}
            label={"Do you currently see patients in private practice?*"}
            name={"see_patients"}
            selectedValue={profileData?.see_patients}
            onChange={handleToggle}
          />
          <RadioToggleElement
            classNm={"col-sm-12"}
            label={
              "Do you currently have your own independent malpractice insurance?*"
            }
            name={"own_insurance"}
            selectedValue={profileData?.own_insurance}
            onChange={handleToggle}
          />
          <RadioToggleElement
            classNm={"col-sm-12"}
            label={"Do you currently accept insurance?*"}
            name={"accept_insurance"}
            selectedValue={profileData?.accept_insurance}
            onChange={handleToggle}
          />
          {profileData?.accept_insurance === "Y" && (
            <SelectElement
              classNm={"col-sm-12"}
              name={"insurance_provider"}
              isMulti={ApplicationConstant.TRUE}
              onChange={(e) => {
                onMultiSelectInput("insurance_provider", e);
              }}
              className="reactSelect"
              classNamePrefix="react-select"
              options={options?.insurance_provider}
              value={profileData?.insurance_provider}
              isSearchable={ApplicationConstant.TRUE}
              stayMenuOnSelect={ApplicationConstant.TRUE}
              placeholder={""}
              label={"Insurance Providers*"}
            />
          )}
          {profileData?.accept_insurance === "Y" &&
            Array.isArray(profileData?.insurance_provider) &&
            profileData?.insurance_provider?.findIndex(
              (item) =>
                item?.name?.toLowerCase() ===
                ApplicationConstant.OTHER_LOWER_CASE
            ) > -1 && (
              <InputElement
                className="col-sm-12"
                type="text"
                name="other_insurance_provider"
                value={profileData?.other_insurance_provider}
                onChange={otherTextInput}
                label={"Other Insurance Provider*"}
                placeholder={"Please enter other detail"}
                required={ApplicationConstant.FALSE}
                autoFocus={profileData?.other_insurance_provider ? false :true}
              />
            )}
          <RadioToggleElement
            classNm={"col-sm-12"}
            label={
              "Do you currently use your own independent electronic medical record?*"
            }
            name={"electronic_medical_record"}
            selectedValue={profileData?.electronic_medical_record}
            onChange={handleToggle}
          />
        </div>
      </>
    );
}

export default DoctorProfessionalInformation
