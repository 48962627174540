import React, { useEffect, useState } from 'react';
import Cropper from 'react-cropper';
import { Modal, ModalBody, ModalHeader, Button } from 'reactstrap';
import { validateType, validateSize } from '../../common/fileUpload/ReactFileUploaderUtils';
import 'cropperjs/dist/cropper.css';

const ImageCropper = (props) => {
    const { className, modalClassName, isOpen, setModelOpen, mediaPath, cropperImageUrl, allowedExtensions, allowedSize, allowedTypeMsg, imageUrl } = props;
    const [cropper, setCropper] = useState();
    const [validation, setValidation] = useState({ value: [], valid: true });

    const onFileChange = (file) => {
      if (!!file) {
        const type = validateType(file.type, allowedExtensions, allowedTypeMsg);
        const size = validateSize(file.size, allowedSize);
        if (type.valid && size.valid) {
          setValidation({ valid: true, value: ['success'] });
        } else {
          setValidation({ valid: false, value: [type.value, size.value] });
        }
      }
    };

    function urltoFile(url, filename){
      let arrBuffer = url.split(',');
      let mediaType = arrBuffer[0].match(/:(.*?);/)[1];
      return (fetch(url)
        .then(function(res){return res.arrayBuffer();})
        .then(function(aRes){return new File([aRes], filename, {type:mediaType});})
      );
    }

    const getCropData = () => {
      const croppedImage = cropper.getCroppedCanvas().toDataURL();
      urltoFile(croppedImage, 'profile-image')
        .then(function(fileObj){
          cropperImageUrl(fileObj)
        })
      setModelOpen();
    };

    useEffect(()=> {
      onFileChange(mediaPath)
    }, [])

  return (
    <Modal
        isOpen={isOpen}
        toggle={() => {
          setModelOpen();
        }}
        className={className}
        modalClassName={modalClassName}
      >
        <ModalHeader
          toggle={() => {
            setModelOpen();
          }}
        />
        <ModalBody>
        <Cropper
          style={{ height: 400, width: "100%", background:"#f5f5f5" }}
          zoomTo={0.5}
          initialAspectRatio={1}
          src={imageUrl}
          viewMode={1}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false}
          onInitialized={(instance) => {
            setCropper(instance);
          }}
          guides={true}
        />
        {!validation.valid && (
            <span className="ml-15 red-text-color2 image-error-text">{validation.value.map((e, i)=> <p key={i}>{e}</p>)}</span>
        )}
        <div className="action-btn">
            <Button color="btn mr-15 btn-secondary" onClick={setModelOpen}>Cancel</Button>
            <Button color="primary" disabled={!validation.valid} onClick={getCropData}>Upload</Button>
        </div>
        </ModalBody>
      </Modal>

  );
};

export default ImageCropper;
