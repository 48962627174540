import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router';
import { useLocation, Link } from 'react-router-dom';
import { PageTitles, RouteConstants } from '../../../../../constants/application.constant';
import useDocumentTitle from '../../../../../utils/DocumentTitle';
import axiosInstance from '../../../../../axios/axiosInstance';
import ApiConstant from '../../../../../constants/ApiConstant';
import { ToastContainer, toast, Slide } from 'react-toastify';
import SpinnerComponent from '../../../../common/spinner/SpinnerComponent';

function CancelConfirmation() {
  useDocumentTitle(PageTitles.CANCEL_CONFIRMATION);
  const [isLoading, setLoading] = useState(false);
  const [isAccepted, setAccepted] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const cancelAppointment = () => {
    setLoading(true);
    const appointmentId = location.state?.appointmentId || '';
    const targetPath = location.state?.targetPath || '';
    const oPayload = { appointment_id: appointmentId };
    axiosInstance.post(ApiConstant.CANCEL_APPOINTMENT_BY_PATIENT, oPayload).then(
      (_res) => {
        setLoading(false);
        history.replace(targetPath);
      },
      (error) => {
        setLoading(false);
        toast.error(error || '');
      }
    );
  };

  const handleChange = () => {
    setAccepted(!isAccepted);
  };

  return (
    <>
      {isLoading ? <SpinnerComponent /> : ''}
      <div className='full-bg-color'>
        <div className='container'>
          <div className='d-flex justify-content-end cursor-pointer pt-20' onClick={history.goBack}>
            <span className='material-icons'>close</span>
          </div>
          <div className='landing-ui'>
            <div className='emal-sent'>
              <form className='w-100'>
                <div className='patient-appointment-cancel-confirmation'>
                  <div className='check-box'>
                    <span className='material-icons'>done</span>
                  </div>
                  <h1>Sorry to see you had to cancel.</h1>
                  <h2 className='mt-15'>
                    <span>Cancellations should be submitted 24 hours before your session.</span>
                  </h2>
                  <h2>
                    <span>
                      Please see{' '}
                      <Link to={RouteConstants.PRIVACY_POLICY} className='theme-color cursor-pointer text-decoration'>policy</Link>{' '}
                      details.
                    </span>
                  </h2>
                  <div className='plane-checkbox'>
                    <div className='label--checkbox flat' onClick={handleChange}>
                      <input readOnly type='checkbox' className='checkbox' checked={isAccepted}  />
                      <label>Yes, I have read and agree equalityMD cancellation policy</label>
                    </div>
                  </div>
                  <div className='sub-heading theme-color mt-16 font-14'>Thank you!</div>
                  <div className='sub-heading font-600 theme-color mt-5 font-14'>equalityMD</div>
                  <div className='row mt-30'>
                    <div className='col-sm-12'>
                      <Button
                        disabled={!isAccepted}
                        className='mb-20'
                        color='primary'
                        onClick={cancelAppointment}
                      >
                        Yes, Cancel
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <ToastContainer autoClose={4000} hideProgressBar transition={Slide} />
      </div>
    </>
  );
}

export default CancelConfirmation;