import { TableClassNameObj } from '../../../../../../../constants/application.constant';

const DocumentListHeader = (props) => {
  const { sortData, sortFilter, selectAll, isSelectAllChecked } = props;
  const patientTableHeadContent = [
    { title: "Select All", class: `${TableClassNameObj.col9}`, sort: false },
    {
      title: "By",
      sortKey: "name",
      class: `${TableClassNameObj.col18} select-all-checkbox`,
      sort: true,
    },
    {
      title: "File Name",
      sortKey: "file_name",
      class: `${TableClassNameObj.col30}`,
      sort: true,
    },
    {
      title: "Date",
      sortKey: "date",
      class: `${TableClassNameObj.col25}`,
      sort: true,
    },
    { title: "Actions", class: `${TableClassNameObj.col18}`, sort: false },
  ];

  const patientActiveSortIcon = (sortKey, sortValue) => {
    const { sortBy, order } = sortFilter;
    if (sortKey === sortBy && sortValue === order) {
      return "active";
    } else {
      return "";
    }
  };

  return (
    <div className="headRow">
      {patientTableHeadContent.map((item, index) => {
        return (
          <div key={index} className={`table-col ${item.class}`}>
            {item.title === "Select All" ? (
              <label className="label--checkbox flat table-checkbox-position">
                <input
                  type="checkbox"
                  className="checkbox"
                  id="selectAll"
                  onChange={selectAll}
                  checked={isSelectAllChecked}
                />
                <label className="font-16 font-500" htmlFor="selectAll" />
              </label>
            ) : (
              <>
                <strong>{item.title}</strong>
                {item.sort && (
                  <div className="sorting-icon">
                    <span
                      className={`material-icons-outlined ${patientActiveSortIcon(
                        item.sortKey,
                        "asc"
                      )}`}
                      onClick={() => {
                        sortData(item.sortKey, "asc");
                      }}>
                      expand_less
                    </span>
                    <span
                      className={`material-icons-outlined ${patientActiveSortIcon(
                        item.sortKey,
                        "desc"
                      )}`}
                      onClick={() => {
                        sortData(item.sortKey, "desc");
                      }}>
                      expand_more
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default DocumentListHeader;
