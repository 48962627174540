import React, { useContext } from 'react';
import UserRegistrationContext from '../../../contexts/user-registration/UserRegistrationContext';
import PatientProfilePronoun from './PatientProfilePronoun';
import BiometricInformationComponent from './SelectBiometricInformation';
import RaceFormComponent from './SelectRace';
import EthnicityFormComponent from './SelectEthnicity';
import LanguageSpokenFormComponent from './SelectLanguage';
import IdentificationsFormComponent from './SelectIdentification';
import SexualOrientationFormComponent from './SelectSexualOrientation';
import HowCanWeSupportFormComponent from './SupportComponent';
import PatientPrefrence from './PatientPrefrence';


function PatientProfileInformation({oInputFields, handleSave}) {
    const [ oUserRegistration ] = useContext(UserRegistrationContext);
    const getComponent = (step) =>{
        switch (step) {
            case 1: return <PatientProfilePronoun aPronounOption={(oInputFields.pronouns || [])}/>;

            case 2: return <BiometricInformationComponent />;

            case 3: return <RaceFormComponent aRaceOption={(oInputFields.races || [])}/>;

            case 4: return <EthnicityFormComponent aEthnicityOption={(oInputFields.ethnicities || [])}/>;

            case 5: return <LanguageSpokenFormComponent aLanguageOptions={(oInputFields.languages || [])}/>;

            case 6: return <IdentificationsFormComponent aIdentificationOption={(oInputFields.identifications || [])}/>;

            case 7: return <SexualOrientationFormComponent aSexualOrientationOption={(oInputFields.sexual_orientations || [])}/>;

            case 8: return <HowCanWeSupportFormComponent />;

            case 9: return <PatientPrefrence aPreferences={(oInputFields.preferences || {})} handleSave={handleSave}/>;


            default: return <div>{"There was a problem !!"}</div>;
        }
    }

    return (
        <div>
            {
                getComponent(oUserRegistration.patient_profile.current_step)
            }
        </div>
    )
}

export default PatientProfileInformation;
