import React from 'react';
import { Spinner } from 'reactstrap';

function SpinnerComponent() {
    return (
        <div className="spinner-component full-page-spinner">
            <Spinner style={{ width: '4.0rem', height: '4.0rem' }} />
        </div>
    );
}

export default SpinnerComponent;
