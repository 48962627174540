import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { ApplicationConstant } from '../../../constants/application.constant';

const DropdownComponent = (props) => {
  const { items, placeholder, showFirstItem, onValueSelected, keyToReturn = 'id', value=''} = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value);
  const toggle = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  const onSelected = (e) => {
    const value = e.currentTarget.textContent;
    if (items) {
      const oItem = items.find((oItem) => oItem.value === value);
      setSelectedValue(oItem.label);
      onValueSelected(oItem[keyToReturn]);
    }
  };

  const getSelectedValue = () => {
    if (showFirstItem) {
      return selectedValue ? selectedValue : items[ApplicationConstant.ZERO]?.label;
    } else {
      return selectedValue ? selectedValue : placeholder;
    }
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle className='dropdown-btn'>
        {getSelectedValue()}
        <span className='material-icons md-18'>expand_more</span>
      </DropdownToggle>
      <DropdownMenu>
        <>
          {items.map((item, i) => {
            return (
              <DropdownItem key={i} onClick={onSelected}>
                {item.value}
              </DropdownItem>
            );
          })}
        </>
      </DropdownMenu>
    </Dropdown>
  );
};

export default DropdownComponent;
