import React, { useContext } from 'react';
import UserRegistrationContext from '../../../contexts/user-registration/UserRegistrationContext';
import update from 'immutability-helper';
import { FormGroup, Input, InputGroup } from 'reactstrap';
import SkipToNextProfile from './SkipToNextProfile';

function SupportComponent() {
  const [oUserRegistration, setUserRegistration] = useContext(UserRegistrationContext);

  const setNotes = (e) => {
    e.preventDefault();
    setUserRegistration(update(oUserRegistration, { patient_profile: { notes: { $set: e.target.value } } }));
  };

  return (
    <div className="form-step">
      <h3>{'How can equalityMD support you living your healthiest and most authentic life?'}</h3>
      <form className="w-100">
        <FormGroup tag="fieldset">
          <div className="row">
            <div className="col-sm-12">
              <InputGroup>
                <div className="label-float">
                  <Input
                    style={{ height: '150px' }}
                    value={oUserRegistration.patient_profile.notes}
                    onChange={setNotes}
                    maxLength="250"
                    type="textarea"
                    placeholder="Please Enter"
                  />
                  <label>{'How can we support?'}</label>
                </div>
              </InputGroup>
            </div>
          </div>
        </FormGroup>
      </form>
      <SkipToNextProfile />
    </div>
  );
}

export default SupportComponent;
