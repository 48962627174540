import React, { useState } from 'react';
import Select, { createFilter, components } from 'react-select';
import { FormGroup } from 'reactstrap';
import { FixedSizeList as List } from 'react-window';
import { ApplicationConstant } from '../../../constants/application.constant';

const MenuList = (props) => {
    const { options, children, maxHeight, getValue } = props;
    const [value] = getValue();
    const initialOffset = options.indexOf(value) * ApplicationConstant.FORTY;

    return (
      <List
        height={maxHeight}
        itemCount={children.length}
        itemSize={ApplicationConstant.FORTY}
        initialScrollOffset={initialOffset}
      >
        {({ index, style }) => <div style={style}>{children[index]}</div>}
      </List>
    );
}

const Option = props => {
    return (
      <div>
        <components.Option {...props}>
        <input
            type="checkbox"
            className="checkboxselect"
            checked={props.isSelected}
            onChange={() => null}
          />
          <label className="w-100">{props.label}</label>
        </components.Option>
      </div>
    );
  };

const MultiSelectElement = (props) => {
    const {selectOptions, selectValue, label, placeholder, onChange, spacing, name} = props;
    const [focus, setFocus] = useState(false);
    return (
        <FormGroup className={`col-12 ${spacing}`}>
            <div className="label-float">
                <Select
                    filterOption={createFilter({ ignoreAccents: false })}
                    components={{ MenuList, Option }}
                    options={selectOptions}
                    className="reactSelect"
                    classNamePrefix="react-select"
                    name={name}
                    isMulti
                    hideSelectedOptions={false}
                    closeMenuOnSelect={false}
                    onFocus={(e)=>{setFocus(true)}}
                    onBlur={(e)=>{if(!e.value){setFocus(false)}}}
                    placeholder={placeholder}
                    label={label}
                    onChange={onChange}
                    value={selectValue}
                />
                <label className={`selectLabel ${selectValue || focus ? 'selected' : ''}`}>{label}</label>
        </div>
    </FormGroup>
    )
};

export default MultiSelectElement;

