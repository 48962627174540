import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { Spinner } from 'reactstrap';
import { ToastContainer, toast, Slide } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import axiosInstance from '../../../../axios/axiosInstance';
import ApiConstant from '../../../../constants/ApiConstant';
import {
  ApplicationConstant,
  RouteConstants,
  PageTitles,
} from '../../../../constants/application.constant';
import { Image } from '../../../common/image/Image';
import SpinnerComponent from '../../../common/spinner/SpinnerComponent';
import FormButton from '../../../ui-designs/pages/FormButton';
import AppointmentCalendar from '../AppointmentCalendar';
import TimeSlot from './timeSlots/TimeSlot';
import { EMPTY_SEARCH } from '../../../../constants/image';
import EmptyStateComponent from '../../../common/emptyStateComponent/EmptyStateComponent';
import useDocumentTitle from '../../../../utils/DocumentTitle';
import UserProfileContext from '../../../../contexts/user-profile/UserProfileContext';
import {
  getFutureDate,
  getPastDate,
  convertUtcToLocalTime,
  timeZoneFormats,
} from '../../../../utils/TimezoneUtils';
import PaymentModal from '../../../common/modal/PaymentModal';

import update from 'immutability-helper';
import { isToday } from 'date-fns';
import DropdownComponent from '../../../common/dropdown/DropdownComponent';

function AvailableDoctor() {
  useDocumentTitle(PageTitles.SCHEDULE_APPOINTMENT);
  const history = useHistory();
  const location = useLocation();
  const { doctorProfile = {}, msg } = location.state;
  const [isCalenderOpen, setIsCalenderOpen] = useState(ApplicationConstant.FALSE);
  const [isCurrentCalenderOpen, setIsCurrentCalenderOpen] = useState(ApplicationConstant.FALSE);
  const [date, setDate] = useState(new Date());
  const [slot, setSlot] = useState(null);
  const [currentSlot, setCurrentSlot] = useState(null);
  const [isSlotAvaliable, setIsSlotAvaliable] = useState(false);
  const [slots, setSlots] = useState([]);
  const [loader, setLoader] = useState(false);
  const [doctorDetails, setDoctorDetails] = useState({});
  const [patientDetail, setPatientDetails] = useState({});
  const [doctorConsultationFee, setdoctorConsultationFee] = useState('');
  const [stripeAccountId, setStripeAccountId] = useState('');
  const [appointmentType, setAppointmentType] = useState(null);
  const [specializationId, setSpecializationId] = useState(null);
  const [slotLoader, setSlotLoader] = useState(false);
  const [oUserGenericData, setUserGenericData] = useContext(UserProfileContext);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);

  const intentPaymentPayload = {
    doctor_id: doctorDetails?.user?.id,
    schedule_id: slot?.schedule_id,
    start_date_time: slot?.utc_slot_start_time,
    end_date_time: slot?.utc_slot_end_time,
    disease_description: msg,
    specialization_id: specializationId,
    appointment_type: appointmentType,
    amount: doctorConsultationFee,
    account_id: stripeAccountId
  };

  const togglePaymentModal = () => {
    setPaymentModalOpen(!paymentModalOpen);
  };

  const cancelAppointment = () => {
    setIsCalenderOpen(!isCalenderOpen);
  };

  const onSlotSelect = (slot) => {
    setSlot(slot);
  };

  const onDateselect = (start) => {
    if (
      moment(start).format(timeZoneFormats.FULL_DATE) === moment().format(timeZoneFormats.FULL_DATE)
    ) {
      setDate(new Date());
    } else {
      setDate(start);
    }
  };

  useEffect(() => {
    fetchDoctorDetails();
  }, []);

  useEffect(() => {
    setSlot(null);
    fetchSlotsForAppointment(doctorProfile?.user?.id || doctorProfile?.user_id, date);
  }, [date]);

  const getSelectedDateSlots = (multiSlots) => {
    const filteredSlots = multiSlots?.map((e) => e.slots);
    const combinedAllSlots = [].concat.apply([], filteredSlots);
    return combinedAllSlots.filter(
      (e) =>
        convertUtcToLocalTime(e.utc_slot_start_time, timeZoneFormats.FULL_DATE) ===
        moment(date).format(timeZoneFormats.FULL_DATE)
    );
  };

  const fetchSlotsForAppointment = async (id, date) => {
    setSlotLoader(true);
    const oPayload = {
      doctor_id: id,
      start_date: getPastDate(date, 1, timeZoneFormats.FULL_DATE),
      end_date: getFutureDate(date, 1, timeZoneFormats.FULL_DATE),
    };
    await axiosInstance
      .post(`${ApiConstant.PATIENT_VIEW_AVAILABLE_SLOTS}`, oPayload)
      .then((res) => {
        if (res && res.data) {
          const calendarSlots = res.data?.data?.calender || [];
          setSlots(getSelectedDateSlots(calendarSlots));
        }
      })
      .catch((error) => {
        toast.error(error || '');
      });
    setSlotLoader(false);
  };

  const onConfirmAppointment = async (paymentDetail) => {
    setLoader(true);
    const oPayload = {
      doctor_id: doctorDetails?.user?.id,
      schedule_id: slot?.schedule_id,
      start_date_time: slot?.utc_slot_start_time,
      end_date_time: slot?.utc_slot_end_time,
      disease_description: msg,
      appointment_type: appointmentType,
      specialization_id: specializationId,
      charge_id: paymentDetail?.charge_id,
      client_secret: paymentDetail?.client_secret,
      amount: paymentDetail?.amount / ApplicationConstant.HUNDRED,
    };
    await axiosInstance
      .post(`${ApiConstant.PATIENT_BOOK_APPOINTMENT}`, oPayload)
      .then((res) => {
        setLoader(false);
        if (res && res.data && res.data.data) {
          history.push({
            pathname: `${RouteConstants.BOOK_APPOINTMENT}`,
            state: res.data.data,
          });
        } else {
          toast.error(res?.data?.message || '');
        }
      })
      .catch((error) => {
        setLoader(false);
        toast.error(error || '');
      });
  };

  const fetchCurrentSlotsForAppointment = async (id) => {
    const oPayload = {
      doctor_id: id,
    };
    await axiosInstance
      .post(`${ApiConstant.CHECK_DOCTOR_CURRENT_AVAILABLE_SLOTS}`, oPayload)
      .then((res) => {
        if (res && res.data && res.data.data) {
          if (res && res?.data && res?.data?.data?.id) {
            setIsSlotAvaliable(true);
            setCurrentSlot(res.data?.data);
          }
        }
      })
      .catch((error) => {
        toast.error(error || '');
      });
  };

  const bookCurrentSlot = () => {
    const slot = slots.find((item) => item?.id === currentSlot?.id);
    onSlotSelect(slot);
    setIsCalenderOpen(!isCalenderOpen);
  };

  const onSelectionChange = (e) => {
    setSpecializationId(e);
  }

  useEffect(() => {
    fetchCurrentSlotsForAppointment(doctorProfile?.user?.id || doctorProfile?.user_id);
  }, []);

  const fetchDoctorDetails = async () => {
    setLoader(true);
    await axiosInstance
      .get(
        `${ApiConstant.PATIENT_VIEW_DOCTOR_CONSULTANT_FEES}/${
          doctorProfile?.user?.id || doctorProfile?.user_id
        }`
      )
      .then((res) => {
        if (res && res.data) {
          const profileDetails = res.data.data.provider || {};
          const patientDetails = res.data.data.patient || {};
          const consultationFee = res.data.data.consultation_fee || ApplicationConstant.ZERO;
          const userStripAccountID = res.data?.data?.stripe_account?.account_id || '';
          profileDetails.doctor_specializations =
            profileDetails?.doctor_specializations?.length > ApplicationConstant.ZERO
              ? profileDetails.doctor_specializations.map((item) => ({
                  ...item,
                  value: item.name,
                  label: item.name,
                }))
              : [];
          setDoctorDetails(profileDetails);
          setPatientDetails(patientDetails);
          setdoctorConsultationFee(consultationFee);
          setStripeAccountId(userStripAccountID);
          setUserGenericData(
            update(oUserGenericData, {
              profile_image: { $set: patientDetails?.user?.profile_image },
            })
          );
        }
      });
    setLoader(false);
  };

  return (
    <>
      {loader && <SpinnerComponent />}
      <div className="middle-content edit-profile">
        <div className="appointment-calendar">
          <div className="container">
            <div class="back-link mt-30">
            </div>
            <div className="purchase-card">
              <div className=" profile-info">
                <div className="box">
                  <div className="user-info">
                    <div className={`profile-img`}>
                      <Image src={doctorDetails?.user?.profile_image || ''} alt="Profile image" />
                    </div>
                    <div className="head">
                      <div className="title-text">
                        <h1 className="font-500 font-16">
                          {doctorDetails?.user?.first_name} {doctorDetails?.user?.last_name}
                          {doctorDetails?.user?.first_name === '' &&
                          doctorDetails?.user?.last_name === ''
                            ? '-'
                            : ''}
                        </h1>
                        <h2>
                          {doctorDetails?.doctor_specializations?.length
                            ? doctorDetails?.doctor_specializations[ApplicationConstant.ZERO].name
                            : ""}
                        </h2>
                      </div>
                      <div className="price-sec ">
                        <div className="d-flex justify-content-between w-100">
                          <div>
                          <label>Appointment Type</label>
                          <label>{'Telehealth'}</label>
                          </div>
                          <div>
                            <label>Accept Insurance</label>
                            {doctorDetails?.accept_insurance === 'Y' ? (
                              <span class="material-icons check-icon">check_circle</span>
                            ) : (
                              <span class="material-icons theme-color">cancel</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between flex-wrap">
                    <div className="dropdown-btn d-flex d-none">
                      <div className="mr-15 mb-15 d-none">
                        <label>{'Telehealth'}</label>
                      </div>
                      {doctorDetails?.doctor_specializations?.length>0 && <DropdownComponent
                        placeholder={"Specialty"}
                        showFirstItem={false}
                        items={doctorDetails?.doctor_specializations || []}
                        onValueSelected={onSelectionChange}
                        keyToReturn={"specialization_id"}
                      />
                      }
                    </div>
                    <div className="action-status patient-scheduled-appointment-actions d-flex">
                      <FormButton
                        buttonLabel="SEE PROVIDER NOW"
                        buttonClassName="btn mr-15 btn-secondary small d-none"
                        disabled={!(isSlotAvaliable)}
                        handleClick={bookCurrentSlot}
                      />
                      <FormButton
                        handleClick={() => {
                          setIsCalenderOpen(!isCalenderOpen);
                        }}
                        buttonLabel="SCHEDULE APPOINTMENT"
                        buttonClassName="btn btn-primary small"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {(isCurrentCalenderOpen || isCalenderOpen) && (
              <div>
                <div class="back-link mt-30">
                  <h1>Schedule an Appointment</h1>
                </div>
                <div className="calander-row">
                  <div className="date-sec">
                    <AppointmentCalendar onSelectDate={onDateselect} />
                  </div>
                  <div className="time-sec">
                    <div className="head">
                      {slots?.length > 0 && <h3>Select 1-hour time slots </h3>}
                    </div>
                    <div className="sub-head">
                      {slots?.length > 0 && <h4>Appointment: {moment(date).format('ll')}</h4>}
                    </div>
                    {slotLoader ? (
                      <div className="spinner-component full-page-spinner position-absolute">
                        <Spinner />
                      </div>
                    ) : (
                      <>
                        {slots?.length ? (
                          <TimeSlot
                            slot={slot}
                            slots={slots}
                            currentSlot={currentSlot}
                            isToday={isToday(date)}
                            onSlotSelect={onSlotSelect}
                            selectedDate={date}
                          />
                        ) : (
                          <EmptyStateComponent
                            imageSrc={EMPTY_SEARCH}
                            title="No Slots Available."
                          />
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="action-status d-flex justify-content-end mt-30">
                  <FormButton
                    handleClick={cancelAppointment}
                    buttonLabel="CANCEL"
                    buttonClassName="btn mr-15 mb-15 btn-secondary"
                  />
                  <FormButton
                    handleClick={togglePaymentModal}
                    buttonLabel="go to payment"
                    buttonClassName="btn mb-15 btn-primary"
                    disabled={!slot}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <ToastContainer autoClose={4000} hideProgressBar transition={Slide} />
      {paymentModalOpen && (
        <PaymentModal
          setModelOpen={togglePaymentModal}
          isOpen={paymentModalOpen}
          modalClassName="paymentModal"
          className={'paymentModal-dialog'}
          title="Pay with card"
          bookAppointemnt={onConfirmAppointment}
          userDetail={patientDetail}
          paymentPayload={intentPaymentPayload}
        />
      )}
    </>
  );
}

export default AvailableDoctor;
