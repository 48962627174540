import React, { useState } from "react";
import '~/../../src/assets/scss/_dashboard.scss';
import { Route, Switch, Link, BrowserRouter as Router } from 'react-router-dom';
import { PRODUCTIMG, PROFILEIMG } from '../../../constants/image';
import StarRating from '../../common/rating/StarRating' 

const Dashboard = () => {
  
  const [rating, setRating] = useState(3);
  const handleChange = (value) => {
    setRating(value);
  }
  return (
    <div className="dashboard-page">
      <div className="container">        
        <section className="detail-info">
          <div className="welcome">
            <h1>Welcome Rodney! <span> Health Profile</span></h1>
          </div>
          <div className="profile-links">
            <ul>
              <li>
                <a className="active">
                  <div className="profile-status">
                    <span class="material-icons">done</span>
                  </div>
                  <span>Health Profile</span>
                </a>
              </li>
              <li>
                <a className="active">
                  <div className="profile-status">
                    <span class="material-icons">done</span>
                  </div>
                  <span>Add an Image</span>
                </a>
              </li>
              <li>
                <a>
                  <div className="profile-status">
                  <span class="material-icons">done</span>   
                  </div>
                  <span>Add your health histroy to get matched (<i>Any skipped information</i>)</span>
                </a>
              </li>
              <li>
                <a>
                  <div className="profile-status">
                    <span class="material-icons">done</span>
                  </div>
                  <span>Have your first <br/>Doc Session</span>
                </a>
              </li>
              <li>
                <a>
                  <div className="profile-status">
                    <span class="material-icons">done</span>                    
                  </div>
                  <span>Review your first <br/>Doc Session</span>
                </a>
              </li>
              
            </ul>
          </div>
          <div className="profile-info">
            <div className="row">
              <div className="col-sm-12 col-md-8">
                <div className="box">
                  
                  <div className="user-info">
                    <div className="profile-img active">
                      <img src={PROFILEIMG}></img>
                    </div>
                    <div className="head">
                      <div className="title-text">
                        <h1>Rodney Fuller</h1>
                        <h2>Pro Member <span>Available</span></h2>
                        <h3 className="d-flex">Engagement Score 
                          <span className="star-rating ml-10">
                            <StarRating
                              count={5}
                              size={17}
                              value={rating}
                              activeColor={'#FB7304'}
                              inactiveColor={'#777777'}
                              onChange={handleChange} /> 
                          </span>
                        </h3>
                      </div>
                      <div className="action-status">
                        <Link to="/patient/profile-edit" class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8"><span class="material-icons md-dark md-18">mode_edit</span></Link>
                        <Link to="/patient/profile-view" class="btn-white-outline">View Profile</Link>
                      </div>
                    </div>
                  </div>
                  <div className="profile-info">
                    <div className="row">
                      <div className="col-md-4 col-sm-6">
                        <label>Pronoun</label>
                        <div className="value">He/Him/His</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Weight* (lbs)</label>
                        <div className="value">176lb</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Height (Feet & Inch)</label>
                        <div className="value">6’11”</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Age</label>
                        <div className="value">48 years</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Race</label>
                        <div className="value">Alaska Native</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Ethnicity</label>
                        <div className="value">Non-Hispanic or Latino</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Language Spoken</label>
                        <div className="value">American English, British English <span className="chip">2 More</span></div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Gender Identity</label>
                        <div className="value">Cigender man</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Sexual Orientation</label>
                        <div className="value">Gay, Queer</div>
                      </div>
                    </div>
                    <h4>Member Since: <span>Jan 21, 2020</span></h4>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-4">
                <div className="box">
                  <div className="head">
                      <div className="title"> 
                        Recent Activity
                      </div>
                  </div>
                  <div className="activity">
                    <ul>
                      <li><span class="material-icons">today</span><span className="name">Upcoming Appointment </span></li>
                      <li><span class="material-icons">account_circle </span><span className="name">New Match </span></li>
                      <li><span class="material-icons">today</span><span className="name">Upcoming Appointment  </span></li>
                      <li><span class="material-icons">account_circle</span><span className="name">New Match </span></li>
                    </ul>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          
        </section>
        <section className="my-matched-section">
          <div className="heading-link">
            <h5>My Matched Doctors</h5>
            <a href="">View All</a>
          </div>
          <div className="card-box">
            <div className="card-row">
              <div className="row">
                <div className="col-lg-4 col-md-12 profile-info">
                  <div className="user-info">
                    <div className="profile-img active">
                      <img src={PROFILEIMG}></img>
                    </div>
                    <div className="head">
                      <div className="title-text">
                        <h1>Rodney Fuller</h1>
                        <h2>Pro Member <span>Available</span></h2>
                        <h3 className="d-flex">Engagement Score 
                          <span className="star-rating ml-10">
                            <StarRating
                              count={5}
                              size={17}
                              value={rating}
                              activeColor={'#FB7304'}
                              inactiveColor={'#777777'}
                              onChange={handleChange} /> 
                          </span>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                <div className="profile-info">
                    <div className="row">
                      <div className="col-md-4 col-sm-6">
                        <label>Pronoun</label>
                        <div className="value">He/Him/His</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Weight* (lbs)</label>
                        <div className="value">176lb</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Height (Feet & Inch)</label>
                        <div className="value">6’11”</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Age</label>
                        <div className="value">48 years</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Race</label>
                        <div className="value">Alaska Native</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Ethnicity</label>
                        <div className="value">Non-Hispanic or Latino</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-2 d-flex align-items-center">
                  <div className="action-status d-flex">
                    <a class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8"><span class="material-icons md-dark md-18">favorite</span></a>
                    <a class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8"><span class="material-icons md-dark md-18">email</span></a>
                    <a class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8"><span class="material-icons md-dark md-18">today</span></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-row">
              <div className="row">
                <div className="col-lg-4 col-md-12 profile-info">
                  <div className="user-info">
                    <div className="profile-img active">
                      <img src={PROFILEIMG}></img>
                    </div>
                    <div className="head">
                      <div className="title-text">
                        <h1>Rodney Fuller</h1>
                        <h2>Pro Member <span>Available</span></h2>
                        <h3 className="d-flex">Engagement Score 
                          <span className="star-rating ml-10">
                            <StarRating
                              count={5}
                              size={17}
                              value={rating}
                              activeColor={'#FB7304'}
                              inactiveColor={'#777777'}
                              onChange={handleChange} /> 
                          </span>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                <div className="profile-info">
                    <div className="row">
                      <div className="col-md-4 col-sm-6">
                        <label>Pronoun</label>
                        <div className="value">He/Him/His</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Weight* (lbs)</label>
                        <div className="value">176lb</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Height (Feet & Inch)</label>
                        <div className="value">6’11”</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Age</label>
                        <div className="value">48 years</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Race</label>
                        <div className="value">Alaska Native</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Ethnicity</label>
                        <div className="value">Non-Hispanic or Latino</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-2 d-flex align-items-center">
                  <div className="action-status d-flex">
                    <a class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8"><span class="material-icons md-dark md-18">favorite</span></a>
                    <a class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8"><span class="material-icons md-dark md-18">email</span></a>
                    <a class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8"><span class="material-icons md-dark md-18">today</span></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-row">
              <div className="row">
                <div className="col-lg-4 col-md-12 profile-info">
                  <div className="user-info">
                    <div className="profile-img active">
                      <img src={PROFILEIMG}></img>
                    </div>
                    <div className="head">
                      <div className="title-text">
                        <h1>Rodney Fuller</h1>
                        <h2>Pro Member <span>Available</span></h2>
                        <h3 className="d-flex">Engagement Score 
                          <span className="star-rating ml-10">
                            <StarRating
                              count={5}
                              size={17}
                              value={rating}
                              activeColor={'#FB7304'}
                              inactiveColor={'#777777'}
                              onChange={handleChange} /> 
                          </span>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                <div className="profile-info">
                    <div className="row">
                      <div className="col-md-4 col-sm-6">
                        <label>Pronoun</label>
                        <div className="value">He/Him/His</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Weight* (lbs)</label>
                        <div className="value">176lb</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Height (Feet & Inch)</label>
                        <div className="value">6’11”</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Age</label>
                        <div className="value">48 years</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Race</label>
                        <div className="value">Alaska Native</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Ethnicity</label>
                        <div className="value">Non-Hispanic or Latino</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-2 d-flex align-items-center">
                  <div className="action-status d-flex">
                    <a class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8"><span class="material-icons md-dark md-18">favorite</span></a>
                    <a class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8"><span class="material-icons md-dark md-18">email</span></a>
                    <a class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8"><span class="material-icons md-dark md-18">today</span></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

    )
}

export default Dashboard; 