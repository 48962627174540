import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import axiosInstance from '../../../../axios/axiosInstance';
import ApiConstant from '../../../../constants/ApiConstant';
import {
  ApplicationConstant,
  RouteConstants,
  PageTitles
} from '../../../../constants/application.constant';
import { Image } from '../../../common/image/Image';
import SpinnerComponent from '../../../common/spinner/SpinnerComponent';
import FormButton from '../../../ui-designs/pages/FormButton';
import useDocumentTitle from '../../../../utils/DocumentTitle';
import UserProfileContext from '../../../../contexts/user-profile/UserProfileContext';
import update from 'immutability-helper';

function SubscriptionPlan() {
  const [loader, setLoader] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [doctorFee, setDoctorFee] = useState({});
  const [doctorConsultationFee, setdoctorConsultationFee] = useState('');
  const { doctorProfile = {} } = location.state;
  const [oUserGenericData, setUserGenericData] = useContext(UserProfileContext);
  useDocumentTitle(PageTitles.SCHEDULE_APPOINTMENT);

  const purchase = () => {
    history.push({ pathname: RouteConstants.AVAILABLE_DOCTOR, state: location.state });
  };

  const fetchDoctorFee = async () => {
    setLoader(true);
    await axiosInstance
      .get(`${ApiConstant.PATIENT_VIEW_DOCTOR_CONSULTANT_FEES}/${doctorProfile?.user?.id || doctorProfile?.user_id}`)
      .then((res) => {
        if (res && res.data) {
          const feeDetails = res.data.data.provider || {};
          const patientDetails = res.data.data.patient || {};
          const consultationFee = res.data.data.consultation_fee || '';
          setDoctorFee(feeDetails);
          setdoctorConsultationFee(consultationFee);
          setUserGenericData(
            update(oUserGenericData, { profile_image: { $set: patientDetails?.user?.profile_image } })
          );
        }
      });
    setLoader(false);
  };

  useEffect(() => {
    fetchDoctorFee();
  }, []);

  return (
    <>
      {loader && <SpinnerComponent />}
      <div className="middle-content edit-profile">
        <div className="container">
          <div className="row">
            <div className="col-sm-10 m-auto">
              <div className="purchase-card">
                <div className=" profile-info">
                  <div className="box">
                    <div className="user-info">
                      <div className="profile-img">
                        <Image
                          src={doctorFee?.user?.profile_image || ""}
                          alt="Profile image"
                        />
                      </div>
                      <div className="head">
                        <div className="title-text">
                          <h1 className="font-500 font-16">
                            {doctorFee?.user?.first_name}{" "}
                            {doctorFee?.user?.last_name}
                            {doctorFee?.user?.first_name === "" &&
                            doctorFee?.user?.last_name === ""
                              ? "-"
                              : ""}
                          </h1>
                          <h2>
                            {doctorFee?.doctor_specializations?.length
                              ? doctorFee?.doctor_specializations[
                                  ApplicationConstant.ZERO
                                ].name
                              : ""}
                          </h2>
                        </div>
                        <div className="price-sec ">
                          <div>
                            <div>
                              <label>Accept Insurance</label>
                              {doctorFee?.accept_insurance === "Y" ? (
                                <span class="material-icons check-icon">
                                  check_circle
                                </span>
                              ) : (
                                <span class="material-icons theme-color">
                                  cancel
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="price  justify-content">
                            <h5>
                              <span>$</span>{doctorConsultationFee || '0'}
                            </h5>
                            <p>Consultation Fee</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="action-status">
                      <FormButton
                        buttonLabel="Purchase"
                        buttonClassName="btn mb-15 btn-primary small"
                        handleClick={purchase}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SubscriptionPlan;
