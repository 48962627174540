const PaymentHistoryCards = (props) => {
  const { cardItem } = props;
  return (
    <div className="card-sec">
    <div className="box">
      <div className="icon-bg"><span className="material-icons">monetization_on</span></div>
      <div className="info"><h4>${cardItem.total_earnings || 0}</h4><strong>Earnings</strong></div>
    </div>
    <div className="box">
      <div className="icon-bg"><span class="material-icons-sharp">file_copy</span></div>
      <div className="info"><h4>{cardItem.total_appointments || 0}</h4><strong>Appointments</strong></div>
    </div>
    <div className="box">
      <div className="icon-bg"><span className="patient-served-icon" /></div>
      <div className="info"><h4>{cardItem.patient_serve || 0}</h4><strong>Patients Cared For</strong></div>
    </div>
    </div>
  )
}

export default PaymentHistoryCards;
