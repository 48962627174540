import ReactPaginate from 'react-paginate';
import DoctorListHeader from './DoctorListHeader';
import DoctorListItem from './DoctorListItem';
import axiosInstance from './../../axios/axiosInstance';
import ApiConstant from '../../constants/ApiConstant';
import { toast } from 'react-toastify';
import { TableClassNameObj, ApplicationConstant } from './../../constants/application.constant';

const DoctorList = (props) => {
  const { doctorList, sortTableData, changePagination, sortObj, pageCount, initialPage } = props;
  const tableContent = [
    { title: 'First Name', sortKey: 'first_name', class: `${TableClassNameObj.col15}`, sort: true },
    { title: 'Last Name', sortKey: 'last_name', class: `${TableClassNameObj.col15}`, sort: true },
    { title: 'Pronouns', sortKey: 'pronoun', class: `${TableClassNameObj.col15}`, sort: true },
    { title: 'Matching Score', sortKey: 'match_value', class: `${TableClassNameObj.col15}`, sort: true },
    { title: 'Consultation fees', sortKey: 'consultation_fee', class: `${TableClassNameObj.col15}`, sort: true },
    { title: 'State', sortKey: 'state', class: `${TableClassNameObj.col10}`, sort: true },
    { title: 'Action', class: `${TableClassNameObj.col18}`, sort: false },
  ];

  const changeFavorite = async (isActive, payload) => {
    await axiosInstance
      .post(`${isActive ? ApiConstant.DELETE_FAV_DOCTOR : ApiConstant.ADD_FAV_DOCTOR}`, payload)
      .then((response) => {
        if (response && response.data) {
          toast.success(response?.data?.message);
        }
      })
      .catch((error) => {
        toast.error(error || '');
      });
  };

  return (
    <>
      <div class="divTable d-flex-table">
        <DoctorListHeader tableHeadContent={tableContent} sortData={sortTableData} filter={sortObj} />
        <div className="table-body">
          {doctorList.map((item, i) => {
            return <DoctorListItem key={item.user_id} item={item} changeFavorite={changeFavorite} />;
          })}
        </div>
        <div className="pagination-wrapper" hidden={pageCount === ApplicationConstant.ONE}>
          <ReactPaginate
            previousLabel={'previous'}
            nextLabel={'next'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={4}
            onPageChange={changePagination}
            pageRangeDisplayed={1}
            forcePage={initialPage}
            containerClassName={'pagination'}
            activeClassName={'active'}
            nextLinkClassName={'next-btn'}
            previousLinkClassName={'prev-btn'}
          />
        </div>
      </div>
    </>
  );
};

export default DoctorList;
