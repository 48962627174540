import React, { useState } from 'react';
import { Slide, ToastContainer } from 'react-toastify';
import { ApplicationConstant, PageTitles } from '../../../constants/application.constant';
import GoBack from '../../common/back/GoBack';
import SpinnerComponent from '../../common/spinner/SpinnerComponent';
import useDocumentTitle from '../../../utils/DocumentTitle';
import HospitalProviders from './HospitalProviders';

function HospitalProviderList() {
    useDocumentTitle(PageTitles.OUR_PROVIDER);
    const [isLoading, setLoading] = useState(false);
    return (
      <>
        {isLoading && <SpinnerComponent />}
        <div className="dashboard-page">
          <div className="container">
            <GoBack heading={PageTitles.OUR_PROVIDER}/>
            <HospitalProviders
            isViewAllClicked={ApplicationConstant.TRUE}
            page={ApplicationConstant.ONE}
            limit={ApplicationConstant.TEN}
            setSpinnerLoader={setLoading}/>
          </div>
        </div>
        <ToastContainer autoClose={4000} hideProgressBar transition={Slide} />
      </>
    );
}

export default HospitalProviderList;


