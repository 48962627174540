import React, { useState } from 'react';
import UserProfileContext from './UserProfileContext';
import UserProfileInitialState from './UserProfileInitialState';

const UserProfileProvider = (props) => {
  const [userProfile, setUserProfile] = useState(UserProfileInitialState);
  return (
    <UserProfileContext.Provider value={[userProfile, setUserProfile]}>
      {props.children}
    </UserProfileContext.Provider>
  );
};

export default UserProfileProvider;
