import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import axiosInstance from '../../../axios/axiosInstance';
import ApiConstant from '../../../constants/ApiConstant';
import { ToastContainer, toast, Slide } from 'react-toastify';
import { CustomDateCell } from '../../common/calendarElements/CalendarElements';
import { RouteConstants } from '../../../constants/application.constant';
import { convertUtcToLocalTime } from '../../../utils/TimezoneUtils';
import 'react-big-calendar/lib/sass/styles.scss';
const localizer = momentLocalizer(moment);

const DoctorCalendar = () => {
  const [currentDate, setCurrentDate] = useState(moment(new Date()).format('YYYY-MM'));
  const [calanderData, setCalanderData] = useState([]);

  useEffect(() => {
    axiosInstance.get(`${ApiConstant.DOCTOR_APPOINTMENTS}?date=${currentDate}`).then(
      (res) => {
        setCalanderData(res.data.data);
      },
      (error) => {
        toast.error(error || '');
      }
    );
  }, ['', currentDate]);

  const getTitle = (appointment_status_id) => {
    switch (appointment_status_id) {
      case 1:
        return 'Pending';
      case 2:
        return 'Approved';
      case 3:
        return 'Completed';
      case 4:
        return 'Time Changed';
      case 5:
        return 'Cancelled By Doctor';
      case 6:
        return 'Cancelled By user';
      default:
        return 'failed';
    }
  };

  const getEvents = () => {
    if (calanderData && calanderData.length) {
      return calanderData.map((event) => {
        return {
          title: getTitle(event.appointment_status_id),
          start: convertUtcToLocalTime(event.start_date_time, 'YYYY,M,DD'),
          end: convertUtcToLocalTime(event.end_date_time, 'YYYY,M,DD'),
        };
      });
    } else return [];
  };

  return (
    <>
      <div className="dashboard-calendar-wrapper">
        <Calendar
          selectable
          localizer={localizer}
          longPressThreshold={10}
          events={getEvents()}
          views={['month', 'week']}
          defaultDate={moment().toDate()}
          timeslots={4}
          components={{
            dateCellWrapper: CustomDateCell,
          }}
          onNavigate={(date) => {
            setCurrentDate(moment(date).format('YYYY-MM'));
          }}
        />
        <div className="calendar-setting">
            <Link className="ml-auto" to={RouteConstants.DOCTOR_SCHEDULE_AVAILABILITY}>Go to Calendar Settings</Link>
        </div>
      </div>
      <ToastContainer autoClose={4000} hideProgressBar transition={Slide} />
    </>
  );
};

export default DoctorCalendar;
