import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { ToastContainer, toast, Slide } from 'react-toastify';
import useDocumentTitle from '../../../../utils/DocumentTitle';
import SpinnerComponent from '../../../common/spinner/SpinnerComponent';
import { PageTitles, InitialPageLimit, ApplicationConstant } from '../../../../constants/application.constant';
import PaymentHistoryCardItem from './paymentHistoryCards/PaymentHistoryCards';
import PaymentHistoryList from './paymentHistoryList/PaymentHistoryList';
import PaymentHistoryListHeader from './paymentHistoryList/PaymentHistoryListHeader';
import { getUserInfo } from '../../../../utils/helper';
import { timeZoneFormats } from '../../../../utils/TimezoneUtils';
import { getDoctorPaymentHistory, getDoctorPaymentHistoryList, EmptyPaymentHistory, handleSelectAll } from './DoctorPaymentHistoryUtils';

const DoctorPaymentHistory = () => {
  useDocumentTitle(PageTitles.DOCTOR_PAYMENT_HISTORY);
  const [cardLoader, setCardLoader] = useState(false);
  const [listLoader, setListLoader] = useState(false);
  const [paymentHistoryDetail, setPaymentHistoryDetail] = useState(null);
  const [paymentHistoryList, setPaymentHistoryList] = useState(null);
  const [pageCount, setPageCount] = useState(ApplicationConstant.ONE);
  const [openListDetailTab, setOpenListDetailTab] = useState(true);
  const [selectAllMonths, setSelectAllMonths] = useState(false);
  const [currentPage, setCurrentPage] = useState(ApplicationConstant.ONE);
  const [listInitialPage, setListInitialPage] = useState(ApplicationConstant.ZERO);
  const userCreatedDate = moment(getUserInfo().created_at).format(timeZoneFormats.FULL_DATE);
  const currentDate = moment().format(timeZoneFormats.FULL_DATE);
  const [paymentHistoryDate, setPaymentHistoryDate] = useState({start:userCreatedDate, end:currentDate});
  const [isPaymentFilterApplied, setPaymentFilterApplied] = useState(false);

  const doctorPaymentHistory = async () => {
    setCardLoader(true);
    await getDoctorPaymentHistory(paymentHistoryDate).then((response)=>{
      if(response && response.data) {
        const paymentHistoryData = response.data?.data || null;
        setPaymentHistoryDetail(paymentHistoryData);
      }
    }).catch((error)=>{
      toast.error(error || '');
    })
    setCardLoader(false);
  }

  const doctorPaymentHistoryList = async () => {
    setListLoader(true);
    await getDoctorPaymentHistoryList(paymentHistoryDate, currentPage, InitialPageLimit.PAYMENT_HISTORY )
    .then((response)=>{
      if(response && response.data) {
        const paymentHistoryDataList = response.data?.data?.data || [];
        const listPageCount = response.data.data.last_page || '';
        setPaymentHistoryList(paymentHistoryDataList);
        setPageCount(listPageCount)
      }
    }).catch((error)=>{
      toast.error(error || '');
    })
    setListLoader(false);
  }

  const resetCurrentPageOnFilterChange= () => {
    setCurrentPage(ApplicationConstant.ONE);
    setListInitialPage(ApplicationConstant.ZERO);
    setOpenListDetailTab(false);
    setSelectAllMonths(false);
  }

  const changePagination = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage + ApplicationConstant.ONE);
    setListInitialPage(selectedPage);
    setOpenListDetailTab(false);
    setSelectAllMonths(false);
  };

  const applyPaymentHistoryFilter = (dateObj) => {
    setPaymentFilterApplied(true);
    setPaymentHistoryDate((prev)=>(
      {...prev,
        start:moment(dateObj.start).format(timeZoneFormats.FULL_DATE),
        end:moment(dateObj.end).format(timeZoneFormats.FULL_DATE)
      })
    );
    resetCurrentPageOnFilterChange();
  };

  const clearPaymentHistoryFilter = () => {
    setPaymentFilterApplied(false);
    setPaymentHistoryDate((prev)=>({...prev, start:userCreatedDate, end:currentDate}));
    resetCurrentPageOnFilterChange();
  }

  const selectListItems = (itemMonth) => {
    let checkedItems = paymentHistoryList;
    const checkedItemIndex = checkedItems.findIndex(e => e.month_year === itemMonth);
    if(checkedItemIndex > -1) {
      checkedItems[checkedItemIndex].checked = !checkedItems[checkedItemIndex].checked;
    }
    setPaymentHistoryList([...checkedItems]);
    handleSelectAll(paymentHistoryList, setSelectAllMonths);
  }

  const checkAllMonths = (e) => {
    const isChecked = e.target?.checked;
    const selectedItems = paymentHistoryList.map(item => ({...item, checked: isChecked}))
    setSelectAllMonths(isChecked);
    setPaymentHistoryList(selectedItems);
  }

  useEffect(()=>{
    doctorPaymentHistory();
  },[paymentHistoryDate])

  useEffect(()=>{
    doctorPaymentHistoryList();
  },[currentPage, paymentHistoryDate])

  return (
    <div className="middle-content edit-profile user-right-container">
      {(cardLoader || listLoader) &&  <SpinnerComponent />}
      <div className="pages">
        <h1 className="member-created-date">Member Since: <span>{moment(userCreatedDate).format('ll')}</span></h1>
        <div className="payment-transaction">
          {paymentHistoryDetail &&
            <PaymentHistoryCardItem
              cardItem={paymentHistoryDetail}
            />
          }
          <PaymentHistoryListHeader
            applyListFilter={applyPaymentHistoryFilter}
            clearListFilter={clearPaymentHistoryFilter}
            minFilterDate={userCreatedDate}
            maxFilterDate={currentDate}
            isAllmonthsChecked={selectAllMonths}
            checkAllMonths={checkAllMonths}
            paymentHistoryList={paymentHistoryList}
            appliedFilterDate={paymentHistoryDate}
          />
          {paymentHistoryList?.length ? (
            <PaymentHistoryList
              paymentHistoryList={paymentHistoryList}
              changePagination={changePagination}
              pageCount={pageCount}
              listInitialPage={listInitialPage}
              openListDetailTab={openListDetailTab}
              setOpenListDetailTab={setOpenListDetailTab}
              selectListItems={selectListItems}
            />
          ):(
            !listLoader && <EmptyPaymentHistory isFilterApplied={isPaymentFilterApplied} />
          )}
        </div>
      </div>
      <ToastContainer autoClose={4000} hideProgressBar transition={Slide} />
    </div>
  );
}

export default DoctorPaymentHistory

