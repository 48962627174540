import React, { Fragment, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button } from 'reactstrap';
import PageLogo from '../../common/logo/PageLogo';
import EmailSent from './EmailSent';
import { PageTitles, RegExConstant } from '../../../constants/application.constant';
import useDocumentTitle from '../../../utils/DocumentTitle';
import axiosInstance from '../../../axios/axiosInstance';
import ApiConstant from '../../../constants/ApiConstant';
import { ToastContainer, toast, Slide } from 'react-toastify';
import SpinnerComponent from '../../common/spinner/SpinnerComponent';
import InputElement from '../../common/formElements/InputElement';
import { isValidEmail } from './ResetPasswordHelper';
import 'react-toastify/dist/ReactToastify.css';

function ForgotPassword() {
  const [isDisable, setDisable] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isEmailSent, sentEmail] = useState(false);
  const [email, setEmail] = useState('');
  useDocumentTitle(PageTitles.FORGOT_PASSWORD);

  const handleChange = (e) => {
    e.preventDefault();
    const email = e.target.value || '';
    setEmail(email);
    if (email && RegExConstant.EMAIL_REG.test(email)) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    setLoading(true);
    const oPayload = { email: email };
    axiosInstance.post(ApiConstant.FORGOT_PASSWORD, oPayload).then(
      (_res) => {
        setLoading(false);
        sentEmail(true);
      },
      (error) => {
        setLoading(false);
        toast.error(error || '');
      }
    );
  };

  return (
    <Fragment>
      <ToastContainer autoClose={4000} hideProgressBar transition={Slide} />
      {isLoading && <SpinnerComponent />}
      {isEmailSent && <EmailSent emailValue={email} handleClick={handleClick} />}
      {!isEmailSent && (
        <div className="full-bg-color">
          <div className="container">
            <div className="landing-ui">
              <div className="forgot-password">
                <form className="w-100">
                  <div className="w-350">
                    <div className="login-title">
                      <PageLogo />
                      <h1>Forgot Password</h1>
                      <h2>Enter your email and we'll send you a link to get back into your account.</h2>
                    </div>
                    <div className="row">
                      <InputElement
                        className="col-sm-12"
                        type="text"
                        name="email"
                        value={email}
                        msg={'Invalid email address'}
                        isValid={isValidEmail(email)}
                        onChange={handleChange}
                        placeholder="Please enter value"
                        label={'Email Address*'}
                      />
                      <div className="col-sm-12 mt-20">
                        <Button disabled={!isDisable} className="w-100 mb-20" color="primary" onClick={handleClick}>
                          SEND LOGIN LINK
                        </Button>
                      </div>
                      <div className="col-sm-12">
                        <div className="signup-text">
                          <NavLink to="/">Back to Login</NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default ForgotPassword;
