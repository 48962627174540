import React, { useContext, useState } from 'react';
import { ApplicationConstant } from '../../../constants/application.constant';
import PageLogo from '../../common/logo/PageLogo';
import PatientAccountCreation from './PatientAccountCreation';
import PatientLocation from './PatientLocation';
import PatientSignUpFooter from './PatientSignUpFooter';
import update from 'immutability-helper';
import { savePatientAccountCreationData } from '../../../utils/patientDetailApi';
import PatientThanksPage from './PatientThanksPage';
import LoginFooter from '../../common/footer/LoginFooter';
import PatientSignUpContext from '../../../contexts/patient-signup/PatientSignUpContext';
import SpinnerComponent from '../../common/spinner/SpinnerComponent';
import GoBack from '../../common/back/GoBack';

const THANK_PAGE = ApplicationConstant.THREE;
const MAX_STEP = ApplicationConstant.TWO;
const FIRST_PAGE = ApplicationConstant.ONE;

function PatientRegistrationPage() {
  const [patientSignUp, setPatientSignUp] = useContext(PatientSignUpContext);
  const [isLoading, setLoading] = useState(ApplicationConstant.FALSE);

  const getNext = (step) => {
    return step + ApplicationConstant.ONE;
  };
  const getPrev = (step) => {
    return step - ApplicationConstant.ONE;
  };

  const handleNext = () => {
    setPatientSignUp(update(patientSignUp, { patient_details: { current_step: { $apply: getNext } } }));
  };

  const handlePrev = () => {
    setPatientSignUp(update(patientSignUp, { patient_details: { current_step: { $apply: getPrev } } }));
  };

  const handleSave = () => {
    setLoading(true);
    savePatientAccountCreationData(patientSignUp.patient_details)
      .then((val) => {
        setLoading(false);
        handleNext();
      })
      .catch((err) => {
        setLoading(false);
        setTimeout(() => {
          setPatientSignUp(
            update(patientSignUp, { patient_details: { current_step: { $set: ApplicationConstant.ONE } } })
          );
          setLoading(false);
        }, 500);
      });
  };

  const getValidity = () => {
    const oValid = { isValid: ApplicationConstant.FALSE };
    const oUser = patientSignUp.patient_details;
    if (oUser) {
        oValid.isValid = !!(
        oUser.first_name.value && oUser.first_name.valid &&
        oUser.password &&
        oUser.password.valid  &&
        oUser.confirm_password &&
        oUser.last_name.value && oUser.last_name.valid &&
        oUser.date_of_birth.value &&
        oUser.date_of_birth.valid &&
        oUser.phone.value &&
        oUser.phone.valid &&
        oUser.email.value &&
        oUser.email.valid &&
        oUser.password.value === oUser.confirm_password.value &&
        ((oUser.is_disply_preferred_name === ApplicationConstant.ONE && oUser.preferred_name.value) ||
          oUser.is_disply_preferred_name === ApplicationConstant.ZERO
        ) &&
        oUser.terms_and_conditions == ApplicationConstant.ONE
      );
    }
    return oValid;
  };

  const getStepTwoValidity = () => {
    const oValid = { isValid: ApplicationConstant.FALSE };
    const oUser = patientSignUp.patient_details.location;
    if (oUser) {
        oValid.isValid = !!(oUser.address1 && oUser.state &&  oUser.state.id &&
          oUser.city && oUser.city.id && oUser.zip_code && oUser.zip_code.valid);
    }
    return oValid;
  };

  const getComponent = (step) => {
    switch (step) {
      case 1:
        return <PatientAccountCreation />;

      case 2:
        return <PatientLocation handleSave={handleSave} handlePrev={handlePrev}/>;

      case 3:
        return <PatientThanksPage />;

      default:
        return <div>{'There was a problem !!'}</div>;
    }
  };

  return (
    <div className="step-page">
      <div className="middle">
        <div className="container">
          <div className="back-login">
            {(patientSignUp.patient_details.current_step === FIRST_PAGE && <GoBack/>)}
          </div>
          <PageLogo />
          {isLoading ? (
            <>
              <div className="head-title mb-50">
                <div className="heading">
                  <SpinnerComponent />
                </div>
              </div>
            </>
          ) : (
            getComponent(patientSignUp.patient_details.current_step)
          )}
        </div>
      </div>
      {patientSignUp.patient_details.current_step === THANK_PAGE || isLoading ? (
        <LoginFooter />
      ) : (
        <PatientSignUpFooter
          handlePrev={handlePrev}
          handleNext={handleNext}
          handleSave={handleSave}
          maxStep={MAX_STEP}
          val={patientSignUp.patient_details.current_step}
          getValidity={getValidity}
          isPrev={patientSignUp.patient_details.current_step!==ApplicationConstant.ONE}
          getStepTwoValidity={getStepTwoValidity}
        />
      )}
    </div>
  );
}

export default PatientRegistrationPage;
