import { useState } from 'react';
import ReactDocumentUploader from '../../../../../../common/fileUpload/ReactDocumentUploader';
import ApiConstant from '../../../../../../../constants/ApiConstant';

const DocumentForm = (props) => {
  const { uploadDocumentFile, appointmentId } = props;
  const [uploadValidation, setUploadValidation] = useState({ value: [], valid: true });

    return (
        <div className="upload-doc doctor-upload-doc">
          <div className="w-100">
            <div className="inner">
              <ReactDocumentUploader
                onSuccess={uploadDocumentFile}
                checkValidation={setUploadValidation}
                allowedExtensions={['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx', 'xlsx']}
                allowedSize={2000000}
                allowedTypeMsg={'Invalid file format.'}
                title={'Upload File'}
                url={`${ApiConstant.UPLOAD_APPOINTMENT_DOCUMENT}?appointment_id=${appointmentId}`}
                formFieldName='document'
              />
              <span className="allowed-size-text">
                Only .doc, .docx, .pdf, .xlsx, .png, and .jpg files with max size of 2mb.
              </span>
              <div className="error-text-row">
                {!uploadValidation.valid && uploadValidation.value.length && uploadValidation.value.map(e => <p>{e}</p>)}
              </div>
            </div>
          </div>
        </div>
    )
}

export default DocumentForm;
