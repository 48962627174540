import { useState } from 'react';
import moment from 'moment';
import { ModalConstants } from '../../../../../../../constants/application.constant';
import CustomModal from '../../../../../../common/modal/CustomModal';

const DocumentListItem = (props) => {
  const { item, onSelectListRow, deleteDocument, onDownloadDocumentByItem } =
    props;
  const [delateModal, setDeleteModal] = useState(false);

  const toggleDeleteModal = () => {
    setDeleteModal(!delateModal);
  };

  const deleteDocumentFile = (isFileDeleted) => {
    toggleDeleteModal();
    if (isFileDeleted) {
      deleteDocument(item?.id);
    }
  };

  return (
    <>
      <div className="divRow">
        <div className="table-col table-column-9" data-label="Select All">
          <label className="label--checkbox flat table-checkbox-position">
            <input
              type="checkbox"
              className="checkbox"
              id={`selectList${item?.id}`}
              onChange={() => {
                onSelectListRow(item?.id);
              }}
              checked={item?.checked}
            />
            <label
              className="font-16 font-500"
              htmlFor={`selectList${item?.id}`}></label>
          </label>
        </div>
        <div
          className="table-col table-column-18"
          title={item?.first_name}
          data-label="By">
          <span>{item?.first_name}</span>
        </div>
        <div
          className="table-col table-column-30"
          title={item?.document_name}
          data-label="File Name">
          <span>
            <a
              className="a-link theme-color"
              href={item?.document_url}
              target="_blank">
              {item?.document_name}
            </a>
          </span>
        </div>
        <div
          className="table-col table-column-25"
          title={moment(item?.created_at).format("ll")}
          data-label="Date">
          <span>{moment(item?.created_at).format("ll")}</span>
        </div>
        <div className="table-col table-column-18 d-flex" data-label="Actions">
          <button
            className={`btn-white-outline`}
            onClick={() => {
              onDownloadDocumentByItem(item?.id);
            }}
            id="downloadAll">
            <span className="material-icons-sharp md-dark md-18">
              file_download
            </span>
          </button>
        </div>
      </div>
      {delateModal && (
        <CustomModal
          modalTitle={ModalConstants.DELETE_DOCUMENT_TITLE}
          modalMessage={ModalConstants.DELETE_DOCUMENT_MESSAGE}
          secondaryButonText={ModalConstants.No}
          primaryButtonText={ModalConstants.YES}
          setModelOpen={toggleDeleteModal}
          isOpen={delateModal}
          onButtonClicked={deleteDocumentFile}
          modalClassName="modal-logout"
        />
      )}
    </>
  );
};

export default DocumentListItem;
