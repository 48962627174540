import { RegExConstant } from '../../../constants/application.constant';

export default function SignInValidator(values) {
  let errors = {};
  if (!values.email) {
    errors.email = 'Email address is required';
  } else if (!RegExConstant.EMAIL_REG.test(values.email)) {
    errors.email = 'Email address is invalid';
  }
  if (!values.password) {
    errors.password = 'Password is required';
  }
  return errors;
}

export const isValidEmail = (email) => {
  if (email && !RegExConstant.EMAIL_REG.test(email)) {
    return false;
  }
  return true;
};
