import React, { useState } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { exportDoctorPaymentHistory, exportPatientPaymentHistory } from '../../DoctorPaymentHistoryUtils';
import { downloadFileFromBlob } from '../../../../../../utils/helper';
import IconTooltip from '../../../../../common/tooltip/IconTooltip';
import { ApplicationConstant } from '../../../../../../constants/application.constant';

const PaymentHistoryDetailListItem = (props) => {
  const { listItem } = props;
  const [isDetailViewOpen, setDetailViewOpen] = useState(false);

  const toggleDetailView = () => {
    setDetailViewOpen(!isDetailViewOpen);
  }

  const downloadDoctorPaymentHistoryDocument = async(chargeId) => {
    await exportDoctorPaymentHistory(chargeId).then((response)=> response.data)
    .then((blob)=>{downloadFileFromBlob(blob, 'invoice.pdf');
    }).catch((error) => toast.error(error || ''));
  }

  const downloadPatientPaymentHistoryDocument = async(chargeId) => {
    await exportPatientPaymentHistory(chargeId).then((response)=> response.data)
    .then((blob)=>{downloadFileFromBlob(blob, 'invoice.pdf');
    }).catch((error) => toast.error(error || ''));
  }

  return (
    <div className="sub-accoridon">
      <div className="divRow">
        <div className="table-col table-column-12" data-label="Patient">
          <span id={`name${listItem?.id}`}>{listItem?.first_name}</span>
          {IconTooltip("bottom", "name", listItem?.first_name, listItem?.id)}
        </div>
        <div className="table-col table-column-18" data-label="Session fee">
          <span>${listItem?.amount || 0}</span>
        </div>
        <div className="table-col table-column-18" data-label="Platform fee">
          <span>${listItem?.application_fee || 0}</span>
        </div>
        <div className="table-col table-column-18" data-label="Net to provider">
          <span>${listItem?.net_to_provider || 0}</span>
        </div>
        <div className="table-col table-column-14" data-label="Date">
          <span>{moment(listItem?.payment_date).format("ll")}</span>
        </div>
        <div className="table-col table-column-20 pr-0" data-label="Action">
          <div className="action-status d-flex row-action-status justify-content-end">
            <button
              type="button"
              id={`doctor-document${listItem?.id}`}
              onClick={() => {
                downloadDoctorPaymentHistoryDocument(listItem?.id);
              }}
              className="btn-white-outline">
              <span className="material-icons md-dark md-18">
                picture_as_pdf
              </span>
            </button>
            {IconTooltip("bottom", "doctor-document", "Provider-Receipt", listItem?.id)}
            {listItem?.payment_type === ApplicationConstant.ONE ?
            <>
              <button
                type="button"
                id={`patient-document${listItem?.id}`}
                onClick={() => {
                  downloadPatientPaymentHistoryDocument(listItem?.id);
                }}
                className="btn-white-outline ml-4">
                <span className="material-icons md-dark md-18">
                  picture_as_pdf
                </span>
              </button>
              {IconTooltip("bottom", "patient-document", "Patient-Receipt", listItem?.id)}
            </>
            : ''}
            <button type="button" className="btn-white-outline ml-4" onClick={toggleDetailView}>
              <span className="material-icons md-dark md-18">
                {isDetailViewOpen ? "expand_less" : "expand_more"}
              </span>
            </button>
          </div>
        </div>
      </div>
      <div
        className={`profile-info ml-15 ${
          isDetailViewOpen ? "d-block" : "d-none"
        }`}>
        <div className="row">
          <div className="d-inline-block mr-20">
            <label>Transaction ID</label>
            <div className="value">{listItem?.charge_id || "-"}</div>
          </div>
          <div className="d-inline-block">
            <label>Canceled appointment</label>
            <div className="value">${listItem?.refund_amount || 0}</div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default PaymentHistoryDetailListItem;
