import React, { Fragment, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import PageLogo from '../../common/logo/PageLogo';
import useForm from './SignInUseForm';
import axiosInstance from '../../../axios/axiosInstance';
import StorageConstant from '../../../constants/StorageConstant';
import ApiConstant from '../../../constants/ApiConstant';
import {
  ApplicationConstant,
  PageTitles,
  RouteConstants,
  ModalConstants,
  HttpStatusCode,
} from '../../../constants/application.constant';
import { ToastContainer, toast, Slide } from 'react-toastify';
import useDocumentTitle from '../../../utils/DocumentTitle';
import SpinnerComponent from '../../common/spinner/SpinnerComponent';
import InputElement from '../../common/formElements/InputElement';
import 'react-toastify/dist/ReactToastify.css';
import { isValidEmail } from './SignInValidator';
import CustomModal from '../../common/modal/CustomModal';
import { setProfileImage } from '../../../utils/helper';

const SignIn = () => {
  const { values, handleChange } = useForm(login);
  const history = useHistory();
  const [isDisable, setDisable] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(true);
  const [isOpen, setModalOpen] = useState(false);
  const toggleResendEmailModal = () => setModalOpen(!isOpen);
  useDocumentTitle(PageTitles.LOGIN);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  function login(isSuccess) {
    setDisable(isSuccess);
  }

  const onResendEmail = (isSignOut) => {
    toggleResendEmailModal();
    if (isSignOut) {
      resendEmail();
    }
  };

  const resendEmail = () => {
    setLoading(true);
    axiosInstance.post(ApiConstant.RESEND_EMAIL, { email: values.email || '' }).then(
      (res) => {
        setLoading(false);
        toast.success(res?.data?.message || '');
      },
      (error) => {
        setLoading(false);
        toast.error(error || '');
      }
    );
  };

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    const oPayload = {
      email: values.email || '',
      password: values.password || '',
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };
    axiosInstance.post(ApiConstant.LOGIN, oPayload).then(
      (res) => {
        setLoading(false);
        if (res.status === HttpStatusCode.SUCCESS_CODE_202) {
          toggleResendEmailModal();
        } else {
          saveUserInfo(res);
        }
      },
      (error) => {
        setLoading(false);
        toast.error(error || '');
      }
    );
  }

  function saveUserInfo(res) {
    if (res && res.data) {
      const oUserProfile = res.data.data || null;
      const userObj = {
        first_name:oUserProfile?.first_name,
        last_name:oUserProfile?.last_name,
        email:oUserProfile?.email,
        created_at: oUserProfile?.created_at
      }
        localStorage.setItem(StorageConstant.TOKEN, oUserProfile?.access_token || '');
        localStorage.setItem(StorageConstant.ROLE_ID, oUserProfile?.role_id || '');
        localStorage.setItem(StorageConstant.USER,  JSON.stringify(userObj) || '');
        setProfileImage(oUserProfile?.profile_image);
        goToNextPage(oUserProfile);
    }
  }

  function goToNextPage(oUserProfile) {
    const isUserRegistered = oUserProfile.is_profile_complete === ApplicationConstant.ONE ? true : false;
    const userRoleId = oUserProfile.role_id;
    switch (userRoleId) {
      case ApplicationConstant.ONE:
        if (!isUserRegistered) {
          history.replace(RouteConstants.REGISTRATION_PATIENT, { profile: oUserProfile });
        } else {
          history.replace(RouteConstants.DASHBOARD_PATIENT);
        }
        break;

      case ApplicationConstant.TWO:
        if (!isUserRegistered) {
          history.replace(RouteConstants.REGISTRATION_DOCTOR);
        } else {
          history.replace(RouteConstants.DASHBOARD_DOCTOR);
        }
        break;

      case ApplicationConstant.THREE:
        history.replace(RouteConstants.DASHBOARD_HOSPITAL);
        break;
    }
  }
  return (
    <Fragment>
      {isLoading && <SpinnerComponent />}
      {
        <div className="full-bg-color">
          <div className="container">
            <div className="landing-ui">
              <div className="login">
                <form autoComplete="off" className="w-100" onSubmit={handleSubmit}>
                  <div className="w-350">
                    <div className="login-title">
                      <PageLogo />
                      <h1>Login</h1>
                      <h2>Please login to your account</h2>
                    </div>
                    <div className="row">
                      <InputElement
                        className="col-sm-12"
                        type="text"
                        name="email"
                        value={values?.email}
                        msg={'Invalid email address'}
                        isValid={isValidEmail(values?.email)}
                        onChange={handleChange}
                        placeholder={ApplicationConstant.PLEASE_ENTER}
                        label={'Email Address*'}
                      />
                      <div className="passwordshowhide mt-10">
                        <InputElement
                          className="col-sm-12"
                          type={!passwordShown ? 'text' : 'password'}
                          name="password"
                          value={values?.password}
                          onChange={handleChange}
                          placeholder={ApplicationConstant.PLEASE_ENTER}
                          label={'Password*'}
                        />
                        <span className="material-icons md-20 md-dark " onClick={togglePasswordVisiblity}>
                          {passwordShown ? 'visibility_off' : 'visibility'}
                        </span>{' '}
                      </div>
                      <div className="col-sm-12 text-right forgot mb-20">
                        <NavLink to={RouteConstants.FORGET_PASSWORD}>Forgot Password?</NavLink>
                      </div>
                      <div className="col-sm-12">
                        <Button disabled={!isDisable} className="w-100 mb-20" color="primary">
                          LOGIN
                        </Button>
                      </div>
                      <div className="col-sm-12">
                        <div className="signup-text">
                          Don’t have an account? <NavLink to={RouteConstants.SIGNUP}>Sign Up</NavLink>
                        </div>
                      </div>
                      <ToastContainer autoClose={4000} hideProgressBar transition={Slide} />
                      <CustomModal
                        modalTitle={ModalConstants.EMAIL_VERIFIED_TITLE}
                        modalMessage={ModalConstants.EMAIL_VERIFIED_MESSAGE}
                        secondaryButonText={ModalConstants.CANCEL}
                        primaryButtonText={ModalConstants.RESEND_EMAIL}
                        setModelOpen={toggleResendEmailModal}
                        isOpen={isOpen}
                        onButtonClicked={onResendEmail}
                        modalClassName="modal-logout"
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      }
    </Fragment>
  );
};

export default SignIn;
