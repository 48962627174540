import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { SessionDocumentTabTitle } from '../../../../../../constants/application.constant';

function DoctorTabHead(props) {
  const { activeTab, tabToggle } = props;

    return (
      <Nav tabs>
        <NavItem className="doctorDocumentTabHead">
          <NavLink
            className={classnames({
              active: activeTab === SessionDocumentTabTitle.NOTES,
            })}
            onClick={() => {
              tabToggle(SessionDocumentTabTitle.NOTES);
            }}>
            <span>{SessionDocumentTabTitle.TAKE_NOTES}</span>
          </NavLink>
        </NavItem>
        <NavItem className="doctorDocumentTabHead">
          <NavLink
            className={classnames({
              active: activeTab === SessionDocumentTabTitle.DOCUMENTS,
            })}
            onClick={() => {
              tabToggle(SessionDocumentTabTitle.DOCUMENTS);
            }}>
            <span>{SessionDocumentTabTitle.DOCUMENTS}</span>
          </NavLink>
        </NavItem>
          <NavItem className="doctorDocumentTabHead">
              <NavLink
                  className={classnames({
                      active: activeTab === SessionDocumentTabTitle.SUPERBILL,
                  })}
                  onClick={() => {
                      tabToggle(SessionDocumentTabTitle.SUPERBILL);
                  }}>
                  <span>{SessionDocumentTabTitle.SUPERBILL}</span>
              </NavLink>
          </NavItem>
      </Nav>
    );
}

export default DoctorTabHead;

