import { useState } from 'react';
import { Image } from '../../../../common/image/Image';
import PendingAppointment from '../../../../common/modal/PendingAppointment';
import { patientPreferredName } from '../../../../../utils/helper';
import { convertUtcToLocalTime } from '../../../../../utils/TimezoneUtils';
import AcceptAppointment from '../../../../common/modal/AcceptAppointment';
import { ApplicationConstant } from '../../../../../constants/application.constant';

const PendingAppointmentItem = (props) => {
  const { item, fetchUpdatedList } = props;
  const [pendingAppointmentModal, setPendingAppointmentModal] = useState(false);
  const [acceptAppointmentModal, setAcceptAppointmentModal] = useState(false);

  const toggleAcceptAppointmentModal = () => {
    setAcceptAppointmentModal(!acceptAppointmentModal);
    acceptAppointmentModal && fetchUpdatedList();
  }

  const togglePendingAppointmentModal = () => {
    setPendingAppointmentModal(!pendingAppointmentModal);
  };

  const sendEmail = (emailId) => {
    if (emailId) {
      window.location.assign(`mailto:${emailId}`);
    }
  };

  return (
    <>
      <div className='user-info'>
        <div className='profile-img'>
          <Image src={item?.user?.profile_image || ''} alt='Profile image' />
        </div>
        <div className='head'>
          <div className='title-text'>
            <h1 className='font-500 font-16'>
              {patientPreferredName(
                item?.user?.first_name,
                item?.user?.last_name,
                item?.is_disply_preferred_name,
                item?.user?.preferred_name
              )}{' '}</h1>
            <h3>Appointment: <span className="ml-3">{' '}{ApplicationConstant.TELEHEALTH}</span></h3>
            <div className='datetime'>
              <span className='material-icons'>event</span>
              <p>
                {convertUtcToLocalTime(item?.start_date_time, 'll')}{' | '}
                {convertUtcToLocalTime(item?.start_date_time, 'hh:mm A')}{' - '}
                {convertUtcToLocalTime(item?.end_date_time, 'hh:mm A')}
              </p>
            </div>
          </div>
          <div className='action-status d-flex row-action-status'>
            <a
              className='btn-white-outline mr-8'
              onClick={() => {
                sendEmail(item?.user?.email);
              }}>
              <span className='material-icons md-dark md-18'>email</span>
            </a>
            <button
              className='btn btn-primary btn-primary-pending small'
              onClick={togglePendingAppointmentModal}
            >
              Pending
            </button>
          </div>
        </div>
      </div>
      {pendingAppointmentModal && (
        <PendingAppointment
          setModelOpen={togglePendingAppointmentModal}
          isOpen={pendingAppointmentModal}
          modalClassName='pending-appointment'
          userData={item}
          fetchUpdatedList={fetchUpdatedList}
          acceptModalOpen={toggleAcceptAppointmentModal}
        />
      )}
      <AcceptAppointment
        isOpen={acceptAppointmentModal}
        setModelOpen={toggleAcceptAppointmentModal}
        modalClassName="appointment-confirmed"
        userData={item}
      />
    </>
  );
};

export default PendingAppointmentItem;
