import React from 'react';
import PageLogo from '../common/logo/PageLogo.js';
import { ApplicationConstant, PageTitles, RouteConstants } from '../../constants/application.constant.js';
import { NavLink } from 'react-router-dom';
import GoBack from '../common/back/GoBack.js';
import useDocumentTitle from './../../utils/DocumentTitle';

function ProfileSelection() {
  useDocumentTitle(PageTitles.SELECT_TYPE);

  return (
    <div className="step-page">
      <div className="middle">
        <div className="container">
          <div className="back-login">
            <GoBack />
          </div>
          <div className="reg-screen">
            <PageLogo />
            <div className="login-title">
              <h1>Who are you?</h1>
              <h2>Please select a user</h2>
            </div>
            <div className="box-content">
              <NavLink className="box" to={RouteConstants.SIGNUP_PATIENT} name="patient" data-role={ApplicationConstant.ONE}>
                <span className="icons patient" data-role={ApplicationConstant.ONE}></span>
                <h4 data-role={ApplicationConstant.ONE}>PATIENT</h4>
              </NavLink>
              <NavLink className="box" to={RouteConstants.SIGNUP_DOCTOR} name="doctor" data-role={ApplicationConstant.TWO}>
                <span className="icons doctor" data-role={ApplicationConstant.TWO}></span>
                <h4 data-role={ApplicationConstant.TWO}>PROVIDER</h4>
              </NavLink>
              <NavLink className="box" hidden={true} to={RouteConstants.SIGNUP_HOSPITAL} name="hospital" data-role={ApplicationConstant.THREE}>
                <span className="icons hospital" data-role={ApplicationConstant.THREE}></span>
                <h4 data-role={ApplicationConstant.THREE}>HOSPITAL</h4>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileSelection;
