import { useEffect, useState, useContext } from 'react';
import { Button, Spinner } from 'reactstrap';
import moment from 'moment';
import { toast } from 'react-toastify';
import axiosInstance from '../../../../../axios/axiosInstance';
import ApiConstant from '../../../../../constants/ApiConstant';
import ScheduleCalendar from './../calendar/ScheduleCalendar';
import DoctorSchedulerContext from '../../../../../contexts/doctor-scheduler/DoctorSchedulerContext';
import {
    returnFilteredSlots,
    setActiveDays,
    setActiveSlot,
    KeyProperty,
    getCurrentTimeZone
} from '../ScheduleAvailabilityUtils';
import {ApplicationConstant} from "../../../../../constants/application.constant";

const TimeSlot = (props) => {
    moment.locale('en',{
        week:{
            dow : 1
        }
    });

    const { setSlots, workingdDaysList, scheduleType } = props;
    const [slotList, setSlotList] = useState([]);
    const [selectedSlotList, setSelectedSlotList] = useState([]);
    const [userFilter, setUserFilter] = useState({});
    const currentDate = moment().startOf('isoWeek').format('YYYY-MM-DD');
    const [isSelectedDate, setSelecteddate] = useState(false);
    const [selectedCalendarDate, setSelectedCalendardate] = useState(currentDate);
    const [loader, setLoader] = useState(false);
    const [selectedTimeSlots, setSelectedTimeSlots] = useContext(DoctorSchedulerContext);
    const isFilterSetting = Object.keys(userFilter).length;

    const setHoursFormat = (key) => {
        const hourDuration = moment(key, ["H"]).format("h:mm a");
        return <p>{hourDuration}</p>
    }

    const fetchcheckedSlot = (slots) => {
        const selectedIDs = slots.filter(e => e.checked);
        const selectedSlots = selectedIDs.map((item)=>({
            slot_start_time: item.slot_start_time,
            slot_end_time: item.slot_end_time,
            day_of_week_id: item.day_of_week_id,
        }));
        setSelectedTimeSlots(prev => ({ ...prev, slots: selectedSlots}))
    }

    const fetchTimeSlot = async (selectedDate) => {
        await axiosInstance.get(`${ApiConstant.DOCTOR_TIME_SLOT}`).then((response) => {
            if(response && response.data) {
                const daySlot = response?.data?.data || [];
                setSlotList(daySlot)
                fetchTimeSlotByDate(daySlot, selectedDate);
            }
        })
    };

    const fetchTimeSlotByDate = async (daySlot, calendarSelectedDate) => {
        setLoader(true);
        const payload = {calendar_date: calendarSelectedDate}
        await axiosInstance.post(ApiConstant.DOCTOR_SCHEDULE_AVAILABILITY_SLOTS, payload).then(
            (response) => {
                if(response && response.data && response.data.data) {
                    const selectedSlots = response?.data?.data?.slots || [];
                    const userFilterSettings = response?.data?.data?.settings || {};
                    let filterdSelectedSlots = [];
                    if (selectedSlots.length) {
                        filterdSelectedSlots = selectedSlots.map((item) => ({...item, checked: true}));
                    }
                    const joinedSelectedSlots = returnFilteredSlots(filterdSelectedSlots, daySlot);
                    setSelectedSlotList(joinedSelectedSlots);
                    fetchcheckedSlot(joinedSelectedSlots);
                    setUserFilter(userFilterSettings);
                }
            })
            .catch((error) => {
                toast.error(error || '');
            });
            setLoader(false);
    };

    const removeScheduledTimeSlots = (slots) => {
        const selectedIDs = slots.filter(e => !e.checked && (e.hasOwnProperty(KeyProperty.BOOKED_APPOINTMENT) && !e.is_booked_appointment));
        const selectedSlots = selectedIDs.map((item)=>({slot_start_time: item.slot_start_time, slot_end_time: item.slot_end_time,}));
        setSelectedTimeSlots(prev => ({ ...prev, remove_slots: selectedSlots}))
        setSelectedTimeSlots(prev => ({ ...prev, calendar_date: selectedCalendarDate}));
    }

    const addAdditionalTimeSlots = (slots) => {
        const selectedIDs = slots.filter(e => e.checked && !e.hasOwnProperty(KeyProperty.BOOKED_APPOINTMENT));
        const selectedSlots = selectedIDs.map((item)=>({slot_start_time: item.slot_start_time, slot_end_time: item.slot_end_time,}));
        setSelectedTimeSlots(prev => ({ ...prev, add_slots: selectedSlots}))
        setSelectedTimeSlots(prev => ({ ...prev, calendar_date: selectedCalendarDate}));
    }
    const onSlotChange = (slot_start_time, day_of_week_id) => {
        const targetIdIndex = selectedSlotList.findIndex(e => e.slot_start_time === slot_start_time && e.day_of_week_id === day_of_week_id);
        if(targetIdIndex > -1) {
            selectedSlotList[targetIdIndex].checked = !selectedSlotList[targetIdIndex].checked;
        }
        fetchcheckedSlot(selectedSlotList)
        isFilterSetting && addAdditionalTimeSlots(selectedSlotList)
        isFilterSetting && removeScheduledTimeSlots(selectedSlotList)
    }

    const clearAddRemoveSlotContext = () => {
        setSelectedTimeSlots(prev => ({ ...prev, add_slots: []}));
        setSelectedTimeSlots(prev => ({ ...prev, remove_slots: []}));
    }

    const onDateChange = (calendarDate) => {
        setSelecteddate(true);
        setSelectedCalendardate(calendarDate);
        fetchTimeSlot(calendarDate);
        clearAddRemoveSlotContext();
        setSelectedTimeSlots(prev => ({ ...prev, calendar_date: calendarDate}));
    }

    const onUpdateSlots = () => {
        setSlots(fetchTimeSlot(selectedCalendarDate));
        clearAddRemoveSlotContext();
        setSelectedTimeSlots(prev => ({ ...prev, recurring_type: ApplicationConstant.THREE }));
    }

    const updateSlotsByScheduleType = () => {
        const selectedStandardedSlots = selectedSlotList.map((item) => ({
            ...item,
            checked: item?.slot_start_time >= scheduleType.start && item.slot_end_time <= scheduleType.end && scheduleType.day_of_week_id.indexOf(item.day_of_week_id) > -1 ? true : false
        }));
        if(isFilterSetting) {
            if(selectedTimeSlots.schedule_type === 1) {
                addAdditionalTimeSlots(selectedStandardedSlots);
                removeScheduledTimeSlots(selectedStandardedSlots);
            } else {
                removeScheduledTimeSlots(selectedStandardedSlots);
                setSelectedTimeSlots(prev => ({ ...prev, add_slots: []}))
            }
        }
        setSelectedSlotList(selectedStandardedSlots);
        fetchcheckedSlot(selectedStandardedSlots);
    }
    useEffect(() => {
        scheduleType && updateSlotsByScheduleType();
    }, [scheduleType])

    useEffect(() => {
        fetchTimeSlot(currentDate);
    }, [])

    return (
        <div className="timeslot-wrapper">
            <div className="timeslot-container position-relative">
                {isSelectedDate && loader &&
                    <div className="spinner-component full-page-spinner position-absolute">
                        <Spinner/>
                    </div>
                }
                <div className="timeslot-header">
                    <h3>Select Appointment Slot</h3>
                    <div className="timeslot-header-items">
                        <ScheduleCalendar onDateChange={onDateChange} />
                    </div>
                </div>
                <div className={`week-row ${setActiveDays(selectedTimeSlots.repeat_day)}`}>
                    {workingdDaysList.map((item, i) => {
                        return (
                            <div
                                key={i}
                                className={`week-slot ${item.checked ? 'active' : ''}`}
                            >
                                {item.name}
                            </div>
                    )})}
                </div>
                <div className="time-row">
                    {selectedSlotList.map((item, i) => {
                        return (
                            <div
                                className={`time-slot
                                    ${item.checked ? 'checked' : ''}
                                    ${setActiveSlot(item?.appointment_status)}`
                                }
                                key={`slot-${i}`}>
                                <input
                                    type="checkbox"
                                    id={`slot-${i}-id${item.id}`}
                                    value={item.time}
                                    onChange={() => onSlotChange(item.slot_start_time, item.day_of_week_id)}
                                    checked={item.checked}
                                    disabled={item?.is_booked_appointment}
                                />
                                <label htmlFor={`slot-${i}-id${item.id}`}>{setHoursFormat(item.slot_start_time)}</label>
                            </div>
                        )
                    })}
                </div>
                <div className="timeslot-button-wrapper">
                    <Button
                        type="button"
                        color="primary"
                        onClick={onUpdateSlots}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default TimeSlot;
