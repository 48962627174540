import React, { useContext, useState } from 'react';
import update from 'immutability-helper';
import {ApplicationConstant, DateConstant, RouteConstants} from '../../../constants/application.constant';
import PatientSignUpContext from '../../../contexts/patient-signup/PatientSignUpContext';
import InputElement from '../../common/formElements/InputElement';
import DateElement from '../../common/formElements/DateElement';
import {
  nameValidation,preferredNameValidation,dateofBirthValidation,emailValidation,
  emailValidationApi,phoneValidation,phoneValidationApi,passwordValidation,confirmPasswordValidation,
} from '../../../validators/CommonValidation';
import { subYears } from 'date-fns';
import {Link} from "react-router-dom";

function PatientAccountCreation() {
  const [passwordShown, setPasswordShown] = useState(ApplicationConstant.TRUE);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(ApplicationConstant.TRUE);
  const [patientSignUp, setPatientSignUp] = useContext(PatientSignUpContext);

  const setValueByFieldName = (fieldname, value) => {
    setPatientSignUp(update(patientSignUp, { patient_details: { [fieldname]: { $set: value } } }));
  };

  const handlePreferredCheck = (e) => {
    const check = e.target.checked;
    const value = check ? ApplicationConstant.ONE : ApplicationConstant.ZERO;
    setValueByFieldName('is_disply_preferred_name', value);
  }

  const onFirstNameInput = (e) => {
    e.preventDefault();
    const obj = nameValidation(e.target.value, patientSignUp.patient_details.first_name);
    setValueByFieldName('first_name', obj);
  };

  const onLastNameInput = (e) => {
    e.preventDefault();
    const obj = nameValidation(e.target.value, patientSignUp.patient_details.last_name);
    setValueByFieldName('last_name', obj);
  };

  const onPreferredInput = (e) => {
    e.preventDefault();
    const obj = preferredNameValidation(e.target.value, patientSignUp.patient_details.preferred_name);
    setValueByFieldName('preferred_name', obj);
  };

  const onDobInput = (date) => {
    const obj = dateofBirthValidation(date, ApplicationConstant.EIGHTEEN);
    setValueByFieldName('date_of_birth', obj);
  };

  const onEmailInput = (e) => {
    e.preventDefault();
    emailValidation('email', e.target.value, setValueByFieldName);
  };

  const onEmailInputApi = (e) => {
    e.preventDefault();
    emailValidationApi('email', e.target.value, setValueByFieldName, patientSignUp.patient_details.email);
  };

  const onPhoneInput = (e) => {
    e.preventDefault();
    if (e.target.value.length <= ApplicationConstant.TEN) {
      phoneValidation('phone', e.target.value, setValueByFieldName);
    }
  };

  const onPhoneInputApi = (e) => {
    e.preventDefault();
    if (e.target.value.length <= ApplicationConstant.TEN) {
      phoneValidationApi('phone', e.target.value, setValueByFieldName, patientSignUp.patient_details.phone);
    }
  };

  const onPasswordInput = (e) => {
    e.preventDefault();
    const obj = passwordValidation(e.target.value, patientSignUp.patient_details.password);
    setValueByFieldName('password', obj);
  };

  const onConfirmPasswordInput = (e) => {
    e.preventDefault();
    if (patientSignUp.patient_details.password.valid) {
      const obj = confirmPasswordValidation(e.target.value, patientSignUp.patient_details.password.value);
      setValueByFieldName('confirm_password', obj);
    }
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(confirmPasswordShown ? false : true);
  };

  const handleTermsAndConditionsCheck = (e) => {
    const check = e.target.checked;
    const value = check ? ApplicationConstant.ONE : ApplicationConstant.ZERO;
    setValueByFieldName('terms_and_conditions', value);
  }

  return (
    <div className="form-step">
      <form className="w-100" autocomplete="off">
        <div className="head-title mb-50">
          <div className="heading">Patient Registration</div>
          <div className="sub-heading">Account Creation</div>
        </div>
        <h3>Personal Information</h3>
        <div className="row">
          <InputElement
            className="col-sm-6"
            type="text"
            name="first_name"
            value={patientSignUp.patient_details.first_name.value}
            onChange={onFirstNameInput}
            onBlur={onFirstNameInput}
            isValid={patientSignUp.patient_details.first_name.valid}
            msg={patientSignUp.patient_details.first_name}
            placeholder="Please enter first name"
            label={"First Name*"}
          />
          <InputElement
            className="col-sm-6"
            type="text"
            name="last_name"
            value={patientSignUp.patient_details.last_name.value}
            onChange={onLastNameInput}
            onBlur={onLastNameInput}
            isValid={patientSignUp.patient_details.last_name.valid}
            msg={patientSignUp.patient_details.last_name}
            placeholder="Please enter last name"
            label={"Last Name*"}
          />
          <InputElement
            className="col-sm-6"
            type="text"
            name="preferred_name"
            value={patientSignUp.patient_details.preferred_name.value}
            onChange={onPreferredInput}
            placeholder="Please enter preferred name"
            label={`Preferred Name${
              patientSignUp.patient_details.is_disply_preferred_name ===
              ApplicationConstant.ONE
                ? "*"
                : ""
            }`}
          />
          <DateElement
            className="col-sm-6"
            type="date"
            name="date_of_birth"
            value={patientSignUp.patient_details.date_of_birth.value}
            onChange={onDobInput}
            dateFormat="MMM d, yyyy"
            maxDate={subYears(new Date(), ApplicationConstant.EIGHTEEN)}
            minDate={new Date(DateConstant.START_DATE)}
            onKeyPress={(e) => {
              e.preventDefault();
            }}
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            onKeyUp={(e) => {
              e.preventDefault();
            }}
            isValid={patientSignUp.patient_details.date_of_birth.valid}
            msg={patientSignUp.patient_details.date_of_birth.msg}
            label={"Birthdate*"}
          />
        </div>
        <div className="check-btn ml-0">
          <label className="label--checkbox flat d-flex">
            <input
              type="checkbox"
              className="checkbox"
              onClick={handlePreferredCheck}
              checked={
                patientSignUp.patient_details.is_disply_preferred_name ===
                ApplicationConstant.ONE
              }
            />
            <label className="font-12 font-500 ">
              {`Please select here to display only your Preferred name to the other users. ` +
                `By making this selection, your full name will not be displayed on your dashboard, or visible to other users ` +
                `including providers. We only use your full name for medical records, insurance and patient credentials.`}
            </label>
          </label>
        </div>
        <h3 className="mt-24">Contact Information</h3>
        <div className="row">
          <InputElement
            className="col-sm-6"
            type="email"
            name="email"
            value={patientSignUp.patient_details.email.value}
            onChange={onEmailInput}
            onBlur={onEmailInputApi}
            label={"Email Address*"}
            placeholder={"Please enter email address"}
            isValid={patientSignUp.patient_details.email.valid}
            msg={patientSignUp.patient_details.email.msg}
          />
          <InputElement
            className="col-sm-6"
            type="number"
            name="phone"
            value={patientSignUp.patient_details.phone.value}
            onChange={onPhoneInput}
            onBlur={onPhoneInputApi}
            label={"Phone Number*"}
            onKeyDown={(evt) =>
              ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
            }
            placeholder={"Phone Number must be 10 digits."}
            isValid={patientSignUp.patient_details.phone.valid}
            msg={patientSignUp.patient_details.phone.msg}
          />
        </div>
        <h3 className="mt-24">Password</h3>
        <div className="row">
          <div className="col-sm-6 p-0">
            <div className="passwordshowhide ">
              <InputElement
                className="col-sm-12"
                type={!passwordShown ? "text" : "password"}
                name="password"
                autocomplete="new-password"
                value={patientSignUp.patient_details.password.value}
                onChange={onPasswordInput}
                onBlur={onPasswordInput}
                label={"Password*"}
                placeholder={"Please enter password"}
                isValid={patientSignUp.patient_details.password.valid}
                msg={patientSignUp.patient_details.password.msg}
              />
              <span
                className="material-icons md-20 md-dark "
                onClick={togglePasswordVisiblity}>
                {passwordShown ? "visibility_off" : "visibility"}
              </span>{" "}
            </div>
          </div>
          <div className="col-sm-6 p-0">
            <div className="passwordshowhide ">
              <InputElement
                className="col-sm-12"
                type={!confirmPasswordShown ? "text" : "password"}
                name="confirm_password"
                value={patientSignUp.patient_details.confirm_password.value}
                onChange={onConfirmPasswordInput}
                onBlur={onConfirmPasswordInput}
                label={"Confirm Password*"}
                placeholder={"Please confirm password"}
                isDisabled={!patientSignUp.patient_details.password.valid}
                isValid={patientSignUp.patient_details.confirm_password.valid}
                msg={patientSignUp.patient_details.confirm_password.msg}
              />
              <span
                className="material-icons md-20 md-dark "
                onClick={toggleConfirmPasswordVisiblity}>
                {confirmPasswordShown ? "visibility_off" : "visibility"}
              </span>{" "}
            </div>
          </div>
          <div className="col-sm-12">
            <p className="font-12 grey666">
              8 Characters Minimum, 1 Upper Case Letter, 1 Number, 1 Special
              Character
            </p>
          </div>
        </div>
        <div className="check-btn ml-0">
          <label className="label--checkbox flat d-flex">
            <input
                type="checkbox"
                className="checkbox"
                onClick={handleTermsAndConditionsCheck}
                checked={
                    patientSignUp.patient_details?.terms_and_conditions ===
                    ApplicationConstant.ONE
                }
                isValid={patientSignUp.patient_details?.terms_and_conditions?.valid}
            />
            <label className="font-12 font-500 ">I agree to the&nbsp;</label>
            <Link target="_blank" className="font-12 font-500 " to={RouteConstants.TERMS_SERVICE}>Terms & Conditions</Link>
            <label className="font-12 font-500 ml-0">&nbsp;of equalityMD*</label>
          </label>
        </div>
      </form>
    </div>
  );
}

export default PatientAccountCreation;
