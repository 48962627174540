import React from 'react';
import { boolenDataFilter } from '../../../utils/helper';

function DoctorProfileQuestionInf(props) {
  const { profileInfo } = props;
  return (
    <>
      <div className="question-box-group">
        {profileInfo?.background_info?.map((item, index) => (
          <div className="question-box-row" key={index}>
            <p> {item?.question}</p>
            <span>{boolenDataFilter(item?.option)}</span>
          </div>
        ))}
      </div>
    </>
  );
}

export default DoctorProfileQuestionInf;

