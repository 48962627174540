import React, { useState } from 'react';
import TabBody from './tabs/TabBody';
import TabHead from './tabs/TabHead';
import { SessionDocumentTabTitle } from '../../../../../constants/application.constant';

function DocumentContainer(props) {
    const { appointmentId, userId } = props;
    const [activeTab, setActiveTab] = useState(SessionDocumentTabTitle.DOCUMENTS);

    const toggle = tab => {
      if(activeTab !== tab) setActiveTab(tab);
    }
    return (
        <div className="tab-section">
        <TabHead activeTab={activeTab} toggle={toggle} />
        <TabBody
          activeTab={activeTab}
          appointmentId={appointmentId}
          userId={userId}
        />
      </div>
    )
}

export default DocumentContainer;
