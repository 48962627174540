import React, { useState, useEffect, useContext } from 'react'
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import update from 'immutability-helper';
import { subYears } from 'date-fns';
import { ToastContainer, toast, Slide } from 'react-toastify';
import { Image } from '../../../common/image/Image';
import { getCitiesById, getStates } from '../../../../utils/CommonRequests';
import { ApplicationConstant, PageTitles, DateConstant, RouteConstants, BasePathConstant } from '../../../../constants/application.constant';
import InputElement from '../../../common/formElements/InputElement';
import SelectElement from '../../../common/formElements/SelectElement';
import DateElement from '../../../common/formElements/DateElement';
import useDocumentTitle from '../../../../utils/DocumentTitle';
import axiosInstance from '../../../../axios/axiosInstance';
import ApiConstant from '../../../../constants/ApiConstant';
import SpinnerComponent from '../../../common/spinner/SpinnerComponent';
import { intialState, updateAccountInfoPayload, checkValidation } from './AccountInformationUtils';
import ReactFileUploader from '../../../common/fileUpload/ReactFileUploader';
import {
  nameValidation, preferredNameValidation, phoneValidation, zipCodeValidation, dateofBirthValidation,
  phoneValidationApi
} from '../../../../validators/CommonValidation';
import UserProfileContext from '../../../../contexts/user-profile/UserProfileContext';
import { setProfileImage } from '../../../../utils/helper';


function AccountInformation() {
  useDocumentTitle(PageTitles.ACCOUNT_INFORMATION);
  const [fileUploaded, isFileUploaded] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const [profileInitialData, setProfileInitialData] = useState(null);
  const [profileEmail, setProfileEmail] = useState(null);
  const [loader, setLoader] = useState(false);
  const [states, setStates] = useState([]);
  const [stateId, setStateId] = useState(null);
  const [cities, setCities] = useState([]);
  const [userGenericData, setUserGenericData] = useContext(UserProfileContext);

  const fetchPatientProfile = async () => {
    setLoader(true);
    await axiosInstance
      .get(`${ApiConstant.PATIENT_PROFILE}`)
      .then((response) => {
        if (response && response.data) {
          const patientProfileData = response.data.data || {};
          const patientEmail = patientProfileData?.user?.email || '';
          const patientStateId = patientProfileData?.location?.state_id || '';
          setProfileData(intialState(patientProfileData));
          setProfileInitialData(intialState(patientProfileData));
          setProfileEmail(patientEmail);
          setStateId(patientStateId);
          setProfileImage(patientProfileData?.user?.profile_image);
          setUserGenericData(update(userGenericData, { 'profile_image': { $set: patientProfileData?.user?.profile_image || '' } }));
        }
      })
      .catch((error) => {
        toast.error(error || '');
      });
    setLoader(false);
  };

  const setLocationValues = (prop, val) => {
    setProfileData(update(profileData, { location: { [prop]: { $set: val } } }));
  };

  const setValueByFieldName = (fieldname, value) => {
    setProfileData(update(profileData, { [fieldname]: { $set: value } }));
  };

  const handleNameChange = (e) => {
    const obj = nameValidation(e.target.value, profileData[e.target.name]);
    setValueByFieldName(e.target.name, obj);
  };

  const onInputPreferredName = (e, keyValue) => {
    const obj = preferredNameValidation(e.target.value, profileData[keyValue]);
    setValueByFieldName(keyValue, obj);
  };

  const onPhoneInput = (e) => {
    e.preventDefault();
    if (e.target.value.length <= ApplicationConstant.TEN) {
      phoneValidation('phone', e.target.value, setValueByFieldName);
    }
  };

  const onPhoneInputApi = (e) => {
    e.preventDefault();
    if (e.target.value.length <= ApplicationConstant.TEN && e.target.value !== profileInitialData?.phone?.value) {
      phoneValidationApi('phone', e.target.value, setValueByFieldName, profileData.phone.value);
    }
  };

  const onDobInput = (date) => {
    const obj = dateofBirthValidation(date, ApplicationConstant.EIGHTEEN);
    setValueByFieldName('date_of_birth', obj);
  };

  const setAddressValue = (e) => {
    e.preventDefault();
    const aaddressObj = {
      value: e.target.value
    }
    setLocationValues(e.target.name, aaddressObj);
  };

  const setZipCode = (e) => {
    zipCodeValidation('zip_code', e.target.value, setLocationValues);
  };

  const onFileUploadSucess = (url) => {
    const updatedImagePayload = {...profileInitialData, profile_image: {value: url}};
    setValueByFieldName('profile_image', {value: url});
    isFileUploaded(true);
    setProfileImage(url);
    setUserGenericData(update(userGenericData, { 'profile_image': { $set: url } }));
    handleSubmit(updatedImagePayload);
  };

  const onFileRemove = () => {
    const updatedImagePayload = {...profileInitialData, profile_image: {value: ''}};
    setValueByFieldName('profile_image', {value: ''});
    isFileUploaded(false);
    setProfileImage(ApplicationConstant.NO_VALUE_STRING);
    setUserGenericData(update(userGenericData, { 'profile_image': { $set: ApplicationConstant.NO_VALUE_STRING } }));
    handleSubmit(updatedImagePayload);
  };

  const removeFilledCityValues = () => {
    profileData.location.city.id = '';
    profileData.location.city.label = '';
  }

  const onStateInput = (e) => {
    setStateId(e.id);
    setLocationValues('state', e);
    removeFilledCityValues();
  };

  const onCityInput = (e) => {
    setLocationValues('city', e);
  };

  const getStateData = () => {
    getStates().then((res) => {
      if (res.length > 0) {
        setStates(res.map((item) => ({ ...item, label: item.name, value: item.name.toLowerCase() })));
      }
    });
  }
  const getCityData = () => {
    getCitiesById(stateId).then((res) => {
      if (res.length > 0) {
        setCities(res.map((item) => ({ ...item, label: item.name, value: item.name.toLowerCase() })));
      }
    });
  }

  const handlePreferredCheck = (e) => {
    const check = e.target.checked;
    const value = check ? ApplicationConstant.ONE : ApplicationConstant.ZERO;
    setValueByFieldName('is_disply_preferred_name', value);
  }

  useEffect(() => {
    getStateData();
  }, []);

  useEffect(() => {
    getCityData();
  }, [stateId]);

  useEffect(() => {
    fetchPatientProfile();
  }, []);

  const handleSubmit = (payload) => {
    setLoader(true);
    axiosInstance.post(ApiConstant.UPDATE_PATIENT_PROFILE, updateAccountInfoPayload(payload)).then(
      (_res) => {
        setLoader(false);
        if (_res && _res.data) {
          const patientProfileData = _res.data.data || {};
          const patientEmail = patientProfileData?.user?.email || '';
          const patientStateId = patientProfileData?.location?.state_id || '';
          setProfileData(intialState(patientProfileData));
          setProfileInitialData(intialState(patientProfileData));
          setProfileEmail(patientEmail);
          setStateId(patientStateId);
        }
        toast.success(_res?.data?.message);
      },
      (_error) => {
        setLoader(false);
        toast.error(_error);
      }
    );
  }

  return (
    <>
    {loader && <SpinnerComponent />}
    <div className="middle-content edit-profile user-right-container">
      <div className="pages">
        <div className="profile-information">
          <div className="form-step max-w-100">
            <form>
              <div className="upload-profile-image">
                <div className="profile-image">
                  <Image src={profileData?.profile_image?.value || ''} alt="Profile image" isLoading={true} />
                </div>
                {profileData?.profile_image.value  && !fileUploaded ? <span class="material-icons btn-close" onClick={onFileRemove}>close</span> : ''}
                <div className="upload-action">
                  <h3 className="mb-10">Your Profile Picture</h3>
                  <ReactFileUploader
                    onSuccess={onFileUploadSucess}
                    onRemove={onFileRemove}
                    allowedExtensions={['jpg', 'jpeg', 'png']}
                    allowedSize={10000000}
                    allowedTypeMsg={'.jpg, .jpeg, and .png are allowed.'}
                    basePath={BasePathConstant.PATIENT_PROFILE_IMAGE}
                    dataLabel={profileData?.profile_image.value ? 'update' : 'upload'}
                    profileMode={true}
                  />
                </div>
              </div>
              <h3>Personal Information</h3>
              <div className="row">
                <InputElement
                  className="col-sm-6"
                  type="text"
                  name="first_name"
                  placeholder={'First Name'}
                  label={'First Name*'}
                  onChange={handleNameChange}
                  isValid={profileData?.first_name?.valid}
                  value={profileData?.first_name?.value}
                  msg={profileData?.first_name?.msg}
                />
                <InputElement
                  className="col-sm-6"
                  type="text"
                  name="last_name"
                  placeholder={'Last Name'}
                  label={'Last Name*'}
                  onChange={handleNameChange}
                  isValid={profileData?.last_name.valid}
                  value={profileData?.last_name?.value}
                  msg={profileData?.last_name?.msg}
                />
                <InputElement
                  className="col-sm-6"
                  type="text"
                  name="preferred_name"
                  placeholder={'Please enter preferred name'}
                  label={`Preferred Name${profileData?.is_disply_preferred_name === ApplicationConstant.ONE?'*':''}`}
                  onChange={(e) => onInputPreferredName(e, 'preferred_name')}
                  value={profileData?.preferred_name?.value}
                />
                <DateElement
                  className="col-sm-6"
                  type="date"
                  name="date_of_birth"
                  onChange={onDobInput}
                  dateFormat="MMM d, yyyy"
                  maxDate={subYears(new Date(), ApplicationConstant.EIGHTEEN)}
                  minDate={new Date(DateConstant.START_DATE)}
                  onKeyPress = {(e)=>{e.preventDefault()}}
                  onKeyDown = {(e)=>{e.preventDefault()}}
                  onKeyUp = {(e)=>{e.preventDefault()}}
                  isValid={profileData?.date_of_birth?.valid}
                  msg={profileData?.date_of_birth?.msg}
                  value={profileData?.date_of_birth?.value}
                  label={'Birthdate*'}
                />
              </div>
              <div className="check-btn ml-0">
                  <label className="label--checkbox flat d-flex">
                  <input type="checkbox" className="checkbox" onClick={handlePreferredCheck}
                  checked={profileData?.is_disply_preferred_name === ApplicationConstant.ONE}/>
                  <label className="font-12 font-500">
                  {
                    `Please select here to display only your Preferred name to the other users. `+
                    `By making this selection, your full name will not be displayed on your dashboard, or visible to other users `+
                    `including providers. We only use your full name for medical records, insurance and patient credentials.`
                  }
                  </label>
                  </label>
              </div>
              <h3 className="mt-24">Contact Information</h3>
              <div className="row">
                <InputElement
                  className="col-sm-6 read-only-field"
                  type="text"
                  name="email"
                  placeholder={'Please enter email'}
                  label={'Email Address*'}
                  value={profileEmail}
                  readOnly
                />
                <InputElement
                  className="col-sm-6"
                  type="number"
                  name="phone"
                  placeholder={'Please enter phone number'}
                  label={'Phone Number*'}
                  onChange={onPhoneInput}
                  onBlur={onPhoneInputApi}
                  isValid={profileData?.phone?.valid}
                  value={profileData?.phone?.value}
                  msg={profileData?.phone?.msg}
                />
              </div>
              <h3 className="mt-24">Location</h3>
              <div className="row">
                <InputElement
                  className="col-sm-6"
                  type="text"
                  name="address1"
                  value={profileData?.location?.address1.value}
                  onChange={setAddressValue}
                  autoComplete={ApplicationConstant.OFF}
                  placeholder={'Please enter street address*'}
                  label={'Street Address*'}
                />
                <InputElement
                  className="col-sm-6"
                  type="text"
                  name="address2"
                  value={profileData?.location?.address2.value}
                  onChange={setAddressValue}
                  autoComplete={ApplicationConstant.OFF}
                  placeholder={'Please enter appartment'}
                  label={'Apartment'}
                />
                <SelectElement
                  classNm={'col-sm-6'}
                  name={'state'}
                  onChange={onStateInput}
                  className="reactSelect"
                  classNamePrefix="react-select"
                  options={states || []}
                  value={profileData?.location?.state}
                  isSearchable={ApplicationConstant.TRUE}
                  placeholder={''}
                  label={'State*'}
                />
                <SelectElement
                  classNm={'col-sm-6'}
                  name={'city'}
                  onChange={onCityInput}
                  className="reactSelect"
                  classNamePrefix="react-select"
                  options={cities || []}
                  value={profileData?.location?.city}
                  isSearchable={ApplicationConstant.TRUE}
                  placeholder={''}
                  label={'City*'}
                />
                <InputElement
                  className="col-sm-12"
                  type="number"
                  name="zip_code"
                  autoComplete={ApplicationConstant.OFF}
                  placeholder={'Please enter zipcode'}
                  label={'Zip Code*'}
                  value={profileData?.location?.zip_code.value}
                  onChange={setZipCode}
                  isValid={profileData?.location?.zip_code.valid}
                  msg={profileData?.location?.zip_code.msg}
                />
              </div>
              <div className="btn-group justify-content-end w-100 pt-10 form-btn-group">
                <Link to={RouteConstants.DASHBOARD_PATIENT} className="btn mr-15 cancel-btn btn-secondary">CANCEL</Link>
                <Button
                  disabled={!checkValidation(profileData, profileInitialData)}
                  type="button"
                  color="primary"
                  className="btn btn-primary"
                  onClick={()=>{handleSubmit(profileData)}}
                >
                  SAVE
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <ToastContainer autoClose={4000} hideProgressBar transition={Slide} />
    </>
  )
}

export default AccountInformation;
