import React from 'react';
import GoBack from './../../common/back/GoBack';
import DoctorProfileImageInfo from './DoctorProfileImageInfo';
import DoctorProfileBasicInfo from './DoctorProfileBasicInfo';
import DoctorProfileProfInfo from './DoctorProfileProfInfo';
import DoctorProfileQuestionInf from './DoctorProfileQuestionInf';
import DoctorProfileBackgroundInfo from './DoctorProfileBackgroundInfo';
import DoctorProfileLocationInfo from './DoctorProfileLocationInfo';

const DoctorProfile = (props) => {
  const { profileInfo } = props;
  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="back-link">
            <GoBack heading={"Professional Profile"} />
          </div>
          <section className="detail-info">
            <div className="profile-info">
              <div className="row">
                <div className="col-sm-12 col-md-12">
                  <div className="box">
                    <DoctorProfileImageInfo profileInfo={profileInfo} />
                    <div className="profile-info">
                      <div className="row">
                        <DoctorProfileBasicInfo profileInfo={profileInfo} />
                        <DoctorProfileProfInfo profileInfo={profileInfo} />
                        <DoctorProfileBackgroundInfo
                          profileInfo={profileInfo}
                        />
                        <DoctorProfileLocationInfo profileInfo={profileInfo} />
                        <DoctorProfileQuestionInf profileInfo={profileInfo} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};
export default DoctorProfile;


