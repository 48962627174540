import React from 'react';
import { Link } from 'react-router-dom';
import { ApplicationConstant, RouteConstants } from '../../../constants/application.constant';
import { Image } from './../../common/image/Image';


function DoctorProfileImageInfo(props) {
  const { profileInfo } = props;

  return (
    <>
      <div className="user-info">
        <div className="profile-img">
          <Image
            src={profileInfo?.doctor_profile?.profile_image || ""}
            alt="Profile image"
          />
        </div>
        <div className="head">
          <div className="title-text mt-5">
            <h1>
              {profileInfo?.doctor_profile?.first_name}{" "}
              {profileInfo?.doctor_profile?.last_name}
            </h1>
            <h2>
              {profileInfo?.doctor_profile?.doctor_specializations?.length
                ? profileInfo?.doctor_profile?.doctor_specializations[
                    ApplicationConstant.ZERO
                  ].name
                : "-"}
            </h2>
          </div>
          <div className="action-status">
            <h4>
              Member Since:{" "}
              <span>{profileInfo?.doctor_profile?.member_since}</span>
            </h4>
            <Link
              to={RouteConstants.DOCTOR_ACCOUNT_INFORMATION}
              class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8">
              <span class="material-icons md-dark md-18">mode_edit</span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default DoctorProfileImageInfo;

