import React, { forwardRef, useContext, useEffect, useState } from 'react';
import UserRegistrationContext from '../../../contexts/user-registration/UserRegistrationContext';
import update from 'immutability-helper';
import { FormGroup, Input, InputGroup } from 'reactstrap';
import SkipToNextProfile from './SkipToNextProfile';
import { allowDecimalNumber } from '../../../utils/inputValidator';
import { ApplicationConstant, DateConstant } from '../../../constants/application.constant';
import { subYears } from 'date-fns';
import DateElement from '../../common/formElements/DateElement';
import { dateofBirthValidation } from '../../../validators/CommonValidation';
import moment from 'moment';
import { timeZoneFormats } from '../../../utils/TimezoneUtils';

function SelectBiometricInformation() {
  const [oUserRegistration, setUserRegistration] = useContext(UserRegistrationContext);
  const [dob, setDob] = useState(
    moment(oUserRegistration.patient_profile?.date_of_birth, timeZoneFormats.FULL_DATE).toDate()
  );

  useEffect(() => {
    setValueByFieldName('date_of_birth', moment(dob).format(timeZoneFormats.FULL_DATE));
  }, [dob]);

  const setValueByFieldName = (fieldname, value) => {
    setUserRegistration(
      update(oUserRegistration, { patient_profile: { [fieldname]: { $set: value } } })
    );
  };

  const setWeight = (e) => {
    e.preventDefault();
    if (
      e.target.value <= ApplicationConstant.NINE_HUNDRED_NINETY_NINE &&
      e.target.value >= ApplicationConstant.ZERO &&
      e.target.value.charAt(ApplicationConstant.ZERO) !== '0' &&
      e.target.value.match(ApplicationConstant.DECIMAL_REGEX)
    ) {
      setValueByFieldName('weight', e.target.value);
    }
  };
  const setHeightInches = (e) => {
    e.preventDefault();
    const val = parseInt(e.target.value);
    if (val >= ApplicationConstant.ZERO && val <= ApplicationConstant.TWELVE) {
      setValueByFieldName('height_inches', `${val}`);
    } else if (e.target.value === ApplicationConstant.NO_VALUE_STRING) {
      setValueByFieldName('height_inches', e.target.value);
    }
  };

  const setHeightFeet = (e) => {
    e.preventDefault();
    const value = e.target.value;
    if (
      value <= ApplicationConstant.TEN &&
      value >= ApplicationConstant.ZERO &&
      value.charAt(ApplicationConstant.ZERO) !== '0'
    ) {
      setValueByFieldName('height_feet', e.target.value);
    }
  };

  const _getAge = (date) => {
    return moment().diff(moment(date).format(timeZoneFormats.FULL_DATE), 'years', false);
  };

  const onDobInput = (date) => {
    const obj = dateofBirthValidation(date, ApplicationConstant.EIGHTEEN);
    setDob(date);
  };

  const AgeInputBox = forwardRef(({ value, onClick }, ref) => (
    <Input
      value={_getAge(dob) || ''}
      ref={ref}
      onClick={onClick}
      type='Number'
      className='mb-0'
      placeholder='Please enter age'
      readOnly
    />
  ));

  return (
    <div className='form-step'>
      <h3>{'Biometric Information'}</h3>
      <form className='w-100'>
        <FormGroup tag='fieldset'>
          <div className='row'>
            <div className='col-sm-12'>
              <InputGroup>
                <div className='label-float'>
                  <Input
                    onKeyPress={allowDecimalNumber}
                    value={oUserRegistration.patient_profile.weight || ''}
                    onChange={setWeight}
                    type='number'
                    placeholder='Please Enter'
                  />
                  <label>{'Weight (Pounds)'}</label>
                </div>
              </InputGroup>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-6'>
              <InputGroup>
                <div className='label-float'>
                  <Input
                    value={oUserRegistration.patient_profile.height_feet || ''}
                    onChange={setHeightFeet}
                    onKeyDown={(evt) =>
                      ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
                    }
                    type='Number'
                    placeholder='Please Enter'
                  />
                  <label>{'Height (Feet)'}</label>
                </div>
              </InputGroup>
            </div>
            <div className='col-sm-6'>
              <InputGroup>
                <div className='label-float'>
                  <Input
                    value={oUserRegistration.patient_profile.height_inches || ''}
                    onChange={setHeightInches}
                    onKeyDown={(evt) =>
                      ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
                    }
                    type='Number'
                    placeholder='Please Enter'
                  />
                  <label>{'Height (Inches)'}</label>
                </div>
              </InputGroup>
            </div>
          </div>
          <div className='row'>
            <DateElement
              className='col-sm-12 d-none'
              type='date'
              name='date_of_birth'
              value={dob || ''}
              onChange={onDobInput}
              customInput={<AgeInputBox />}
              dateFormat='MMM d, yyyy'
              maxDate={subYears(new Date(), ApplicationConstant.EIGHTEEN)}
              minDate={new Date(DateConstant.START_DATE)}
              onKeyPress={(e) => {
                e.preventDefault();
              }}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              onKeyUp={(e) => {
                e.preventDefault();
              }}
              label={'Age*'}
            />
          </div>
        </FormGroup>
      </form>
      <SkipToNextProfile />
    </div>
  );
}
export default SelectBiometricInformation;
