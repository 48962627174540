import React, { useEffect, useState } from 'react';
import { zipCodeValidation } from '../../../../validators/CommonValidation';
import InputElement from '../../../common/formElements/InputElement';
import SelectElement from '../../../common/formElements/SelectElement';
import { getCitiesById } from '../../../../utils/CommonRequests';
import { FieldKey } from './AccountInformationUtils';

const LocationInformation = (props) => {
    const {profileData, setValueByFieldName, stateId, setStateId, stateOptions} = props;
    const [cityOptions, setCityOptions] = useState([]);

    const getCityData = () => {
        getCitiesById(stateId).then((res) => {
            if (res.length > 0) {
            setCityOptions(res.map((item) => ({ ...item, label: item.name, value: item.name.toLowerCase() })));
            }
        });
    }

    const handleAddressChange = (e) => {
        e.preventDefault();
        const addressObj = {value: e.target.value}
        setValueByFieldName(e.target.name, addressObj);
    }

    const removeFilledCityValues = () => {
        profileData.city.id = '';
        profileData.city.label = '';
    }

    const handleStateChange = (e) => {
        setStateId(e.id);
        setValueByFieldName(FieldKey.STATE, e);
        removeFilledCityValues();
    }

    const handleCityChange = (e) => {
        setValueByFieldName(FieldKey.CITY, e);
    }

    const handleZipCodeChange = (e) => {
        e.preventDefault();
        zipCodeValidation(e.target.name, e.target.value, setValueByFieldName);
    };

    useEffect(() => {
        getCityData();
    }, [stateId]);

    return (
        <div className='row'>
            <InputElement
            className='col-sm-6'
            type='text'
            name='address1'
            placeholder={'Please enter street address'}
            label={'Street Address*'}
            onChange={handleAddressChange}
            value={profileData?.address1?.value}
            />
            <InputElement
            className='col-sm-6'
            type='text'
            name='address2'
            placeholder={'Please enter address 2'}
            label={'Address 2'}
            onChange={handleAddressChange}
            value={profileData?.address2?.value}
            />
            <SelectElement
            classNm={'col-sm-6'}
            name={'state_id'}
            className='reactSelect'
            classNamePrefix='react-select'
            options={stateOptions}
            placeholder={'State'}
            label={'States*'}
            onChange={handleStateChange}
            value={profileData?.state}
            />
            <SelectElement
            classNm={'col-sm-6'}
            name={'city'}
            className='reactSelect'
            classNamePrefix='react-select'
            options={cityOptions}
            placeholder={'State'}
            label={'City*'}
            onChange={handleCityChange}
            value={profileData?.city}
            />
            <InputElement
            className='col-sm-12'
            type='number'
            name='zip_code'
            placeholder={'Please enter zipcode'}
            label={'Zip Code*'}
            onChange={handleZipCodeChange}
            isValid={profileData?.zip_code.valid}
            value={profileData?.zip_code?.value}
            msg={profileData?.zip_code.msg}
            />

        </div>
    )
}
export default LocationInformation;
