import React,{useState,useEffect} from 'react';
import { toast } from 'react-toastify';
import { Button, Spinner } from 'reactstrap';
import axiosInstance from '../../../../../../axios/axiosInstance';
import ApiConstant from '../../../../../../constants/ApiConstant';
import { ApplicationConstant } from '../../../../../../constants/application.constant';
import { downloadFileFromBlob } from '../../../../../../utils/helper';

function CompletedAppointmentNotes(props) {
  const { item , viewHistoryOpen} = props;
  const [loading, setLoading] = useState(false);
  const [noteLoader, setNoteLoader] = useState(false);
  const [noteEditView, setNoteEditView] = useState(false);
  const [notes, setNotes] = useState({patient: '', doctor: ''});
  const [patientNotes, setPatientNotes] = useState('');
  const [doctorNotes, setDoctorNotes] = useState('');

  const fetchDoctorNotes = async() => {
    setNoteLoader(true);
    await axiosInstance.get(`${ApiConstant.APPOINTMENT_NOTES}`, {params: { appointment_id: item.appointment_id }})
    .then((response) => {
      if (response && response.data && response.data.data) {
        const updatedPatientNotes = response.data.data?.notes || '';
        const updatedDoctorNotes = response.data.data?.private_notes || '';
        setPatientNotes(updatedPatientNotes);
        setDoctorNotes(updatedDoctorNotes);
        setNotes({patient: updatedPatientNotes, doctor: updatedDoctorNotes})
      }
    })
    .catch((error) => {toast.error(error || '')});
    setNoteLoader(false);
  }

  const updateNotes = async() => {
    setLoading(true);
    const oPayload = {appointment_id: item.appointment_id,notes: patientNotes,private_notes: doctorNotes};
    await axiosInstance.post(ApiConstant.SAVE_APPOINTMENT_NOTE, oPayload).then(
    (response) => {
      if(response && response.data) {
        const updatedPatientNotes = response.data.data?.notes || '';
        const updatedDoctorNotes = response.data.data?.private_notes || '';
        setPatientNotes(updatedPatientNotes);
        setDoctorNotes(updatedDoctorNotes);
        setNotes({patient: updatedPatientNotes, doctor: updatedDoctorNotes})
        setNoteEditView(!noteEditView);
        toast.success(response.data?.message);
      }
    }).catch((error) => {toast.error(error || '');});
    setLoading(false);
  }

  const downloadNotes = async(noteType) => {
    await axiosInstance.get(`${ApiConstant.DOCTOR_DOWNLOAD_NOTES}`, {
      responseType : 'blob',
      params: { appointment_id: item?.appointment_id, is_private: noteType
    }})
    .then((response) => response.data).then((blob)=>{
      downloadFileFromBlob(blob, noteType ? 'PrivateNote.pdf' : 'Note.pdf');
    })
    .catch((error) => {toast.error(error || '')});
  };

  const cancelUpdatedNotes = () => {
    setPatientNotes(notes.patient);
    setDoctorNotes(notes.doctor);
    setNoteEditView(!noteEditView);
  }

  useEffect(() => {
    fetchDoctorNotes();
  }, [viewHistoryOpen]);

  return (
    <>
      <div>
          <div className={`body profile-info position-relative d-block`}>       
            {noteLoader &&
              <div className="spinner-component full-page-spinner position-absolute">
                <Spinner/>
              </div>
            }          
            <div className="row mb-20">
              <div className={`col-sm-12 ${noteEditView ? 'bg-box' : 'border-box'}`}>
                <div className="w-100">
                  <div className="notes-heading-btn">
                    Visible only to Provider
                    {!noteEditView && doctorNotes ?
                      <Button
                        type="button"
                        onClick={()=>downloadNotes(1)}
                      >
                        <span class="material-icons-sharp">download</span>
                      </Button>
                    :''}
                  </div>
                  {noteEditView ?
                    <textarea
                      name="private_notes"
                      placeholder="Private Notes"
                      onChange={(e)=>{setDoctorNotes(e.target.value)}}
                      >
                    {doctorNotes}
                    </textarea>
                  :
                    <p className="font-14 font-400 gray666 mb-0">{notes.doctor || '-'}</p>
                  }
                </div>
              </div>
              <div className={`col-sm-12 mt-10 ${noteEditView ? 'bg-box' : 'border-box'}`}>
                <div className="w-100">
                  <div className="notes-heading-btn">
                    Visible to both Provider and Patient
                    {!noteEditView && patientNotes ?
                      <Button
                        type="button"
                        onClick={()=>downloadNotes(0)}
                      >
                        <span class="material-icons-sharp">download</span>
                      </Button>
                    :''}
                  </div>
                  {noteEditView ?
                    <textarea
                      name="notes"
                      placeholder="Notes"
                      onChange={(e)=>{setPatientNotes(e.target.value)}}>
                      {patientNotes}
                    </textarea>
                  :
                    <p className="font-14 font-400 gray666 mb-0">{notes.patient || '-'}</p>
                  }
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="payment-button">
                  <div className="action-status">
                    {noteEditView ? (
                      <>
                        <Button
                          color="primary"
                          disabled={!(patientNotes.trim().length > ApplicationConstant.ZERO || doctorNotes.trim().length > ApplicationConstant.ZERO)}
                          onClick={updateNotes}
                        >
                          {loading ? <Spinner /> : 'Update Notes'}
                        </Button>
                        <Button
                          className="ml-10"
                          onClick={cancelUpdatedNotes}
                        >
                          Cancel
                        </Button>
                      </> ):(
                      <Button
                        onClick={()=>setNoteEditView(!noteEditView)}
                      >
                        Edit Notes
                      </Button>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </>
  );
}

export default CompletedAppointmentNotes;


