import React, {useState} from 'react'
import { InputGroup } from 'reactstrap';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getYear } from 'date-fns';

function DateElement({className, value="", isDisabled=false, label="", isValid=true, msg, ...props}) {
  const scrollableItems = (getYear(props.maxDate)||2100) - (getYear(props.minDate)||1900);
  const [focus, setFocus] = useState(false);
    return (
        <div className={className}>
            <InputGroup>
          <div className="label-float">
              <ReactDatePicker
                selected={value}
                showMonthDropdown
                showYearDropdown
                onFocus={(e) => { setFocus(true) }}
                popperPlacement="top-end"
                autoComplete={'off'}
                yearDropdownItemNumber={scrollableItems}
                scrollableYearDropdown={true}
                scrollIntoView={true}
                {...props}
              />
                <label className={`selectLabel ${value || focus ? 'selected' : ''}`}>{label}</label>
              </div>
              {isValid?<></>:<label className="font-12 red-text-color2">{msg}</label>}
            </InputGroup>
        </div>
    )
}

export default DateElement
