import React, { useState, useEffect } from 'react';
import SpinnerComponent from '../../common/spinner/SpinnerComponent';
import useDocumentTitle from './../../../utils/DocumentTitle';
import PageLogo from '../../common/logo/PageLogo';
import ApiConstant from '../../../constants/ApiConstant';
import axiosInstance from './../../../axios/axiosInstance';
import SelectElement from '../../common/formElements/SelectElement';
import { ApplicationConstant, PageTitles } from '../../../constants/application.constant';
import DoctorRegistrationFooter from './DoctorRegistrationFooter';
import useDoctorFields from './DoctorProfileFieldsHook';
import InputElement from '../../common/formElements/InputElement';
import update from 'immutability-helper';
import { createDoctorProfileData } from './DoctorRegistrationUtils';
import { DoctorRequestInitialState, DoctorFormState } from './DoctorRegistrationInitialState';

function DoctorRegistration(props) {
  const { editMode, closeModal, fetchProfile} = props;
  const { updateData } = useDoctorFields(updatedData);
  const [isLoading, setLoading] = useState(false);
  const [oDoctorRequest, setDoctorRequest] = useState(DoctorRequestInitialState);
  const [oDoctorFormState, setDoctorFormState] = useState(DoctorFormState);
  const [oProfile, setProfile] = useState({});
  const [oDoctorProfileFields, setDoctorProfileFields] = useState({});
  useDocumentTitle(PageTitles.DOCTOR_REGISTRATION);

  useEffect(() => {
    const fetchData = async () => {
      const oProfileFields = await axiosInstance.get(ApiConstant.DOCTOR_PROFILE_INFO);
      const oDoctorProfile = await axiosInstance.get(ApiConstant.DOCTOR_PROFILE);
      const aLanguages = await axiosInstance.get(ApiConstant.LANGUAGES);
      setLoading(false);
      setDoctorData(oProfileFields?.data, oDoctorProfile?.data, aLanguages?.data);
    };
    setLoading(true);
    fetchData();
  }, []);

  function updatedData(oData) {
    const oDoctorProfile = createDoctorProfileData(oProfile, oData).oDoctorProfile;
    const oState = createDoctorProfileData(oProfile, oData).oState;

    setDoctorRequest(update(oDoctorRequest, { $set: oDoctorProfile }));
    setDoctorFormState(update(oDoctorFormState, { $set: oState }));
    setDoctorProfileFields(oData);
  }

  function setDoctorData(oDoctorFields, oDoctorProfile, aLanguagesData) {
    setProfile(oDoctorProfile?.data || {});
    const oData = oDoctorFields?.data || {};
    oData.languages = aLanguagesData?.data || [];
    updateData(oData);
  }

  const onChangeSingle = (oData, key, valueKey, otherInputKey, otherKey) => {
    setDoctorRequest(
      update(oDoctorRequest, {
        doctor: { [key]: { $set: oData.id }, [otherInputKey]: { $set: '' } },
        data: { [valueKey]: { $set: oData } },
      })
    );
    if (oData.isOther) {
      setDoctorFormState(update(oDoctorFormState, { [otherKey]: { $set: true } }));
    } else {
      setDoctorFormState(update(oDoctorFormState, { [otherKey]: { $set: false } }));
    }
  };

  const onChangeMultiSelect = (aData, key, valueKey, otherKey, otherFormKey) => {
    const otherValue = aData.find((oItem) => oItem.isOther === true);
    if (otherValue) {
      setDoctorFormState(update(oDoctorFormState, { [otherFormKey]: { $set: true }, [otherKey]: { $set: '' } }));
      setDoctorRequest(
        update(oDoctorRequest, { doctor: { [key]: { $set: aData } }, data: { [valueKey]: { $set: aData } } })
      );
    } else {
      if (aData.length <= ApplicationConstant.ZERO) {
        aData = ApplicationConstant.NO_VALUE_STRING;
      }
      setDoctorFormState(update(oDoctorFormState, { [otherFormKey]: { $set: false } }));
      setDoctorRequest(
        update(oDoctorRequest, {
          doctor: { [key]: { $set: aData }, [otherKey]: { $set: '' } },
          data: { [valueKey]: { $set: aData } },
        })
      );
    }
  };

  const handleOnChange = (e, otherKey) => {
    setDoctorRequest(update(oDoctorRequest, { doctor: { [otherKey]: { $set: e.target.value } } }));
  };

  return (
    <>
      {isLoading ? (
        <SpinnerComponent />
      ) : (
        <div className="step-page">
          <div hidden={editMode}></div>
          <div
            className="middle doctor-registration-middle-container"
            style={{ minHeight: "100vh" }}>
            <div className="container">
              <div hidden={editMode}>
                <PageLogo />
                <div className="head-title mb-50">
                  <div className="heading">Provider Registration</div>
                  <div className="sub-heading">Create your Profile!</div>
                </div>
              </div>
              <div className="form-step">
                <div className="w-100">
                  <div className="row">
                    <SelectElement
                      classNm={"col-sm-12"}
                      name={"pronoun"}
                      className="reactSelect"
                      classNamePrefix="react-select"
                      value={oDoctorRequest?.data?.userPronouns || ''}
                      isMulti={true}
                      stayMenuOnSelect={ApplicationConstant.TRUE}
                      onChange={(e) =>
                        onChangeMultiSelect(
                          e,
                          "user_pronouns",
                          "userPronouns",
                          "other_pronoun",
                          "isPronounOther"
                        )
                      }
                      options={oDoctorProfileFields?.pronouns || []}
                      isSearchable={false}
                      placeholder={"Please select pronoun"}
                      label={"Pronouns* (Select all that apply)"}
                    />
                    {oDoctorFormState.isPronounOther ? (
                      <InputElement
                        className="col-sm-12"
                        type="text"
                        name="other_pronoun"
                        value={oDoctorRequest.doctor.other_pronoun}
                        onChange={(e) => handleOnChange(e, "other_pronoun")}
                        placeholder="Please enter pronoun value"
                        label={"Other*"}
                        autoFocus = {oDoctorRequest?.doctor?.other_pronoun ? false : true}
                      />
                    ) : (
                      <></>
                    )}
                    <SelectElement
                      classNm={"col-sm-12"}
                      name={"race"}
                      className="reactSelect"
                      classNamePrefix="react-select"
                      isMulti={true}
                      stayMenuOnSelect={ApplicationConstant.TRUE}
                      value={oDoctorRequest?.data?.userRaces || ""}
                      onChange={(e) =>
                        onChangeMultiSelect(
                          e,
                          "user_races",
                          "userRaces",
                          "other_race",
                          "isRaceOther"
                        )
                      }
                      options={oDoctorProfileFields?.races || []}
                      isSearchable={false}
                      placeholder={"Please select race"}
                      label={"Race* (Select all that apply)"}
                    />
                    {oDoctorFormState.isRaceOther ? (
                      <InputElement
                        className="col-sm-12"
                        type="text"
                        name="other_race"
                        value={oDoctorRequest.doctor.other_race}
                        onChange={(e) => handleOnChange(e, "other_race")}
                        placeholder="Please enter race value"
                        label={"Other*"}
                        autoFocus = {oDoctorRequest?.doctor?.other_race ? false : true}
                      />
                    ) : (
                      <></>
                    )}
                    <SelectElement
                      classNm={"col-sm-12"}
                      name={"ethnicity"}
                      className="reactSelect"
                      classNamePrefix="react-select"
                      value={oDoctorRequest?.data?.ethnicity}
                      onChange={(e) =>
                        onChangeSingle(
                          e,
                          "ethnicity_id",
                          "ethnicity",
                          "other_ethnicity",
                          "isEthnicityOther"
                        )
                      }
                      options={oDoctorProfileFields?.ethnicities || []}
                      isSearchable={false}
                      placeholder={"Please select ethnicity"}
                      label={"Ethnicity*"}
                    />
                    {oDoctorFormState.isEthnicityOther ? (
                      <InputElement
                        className="col-sm-12"
                        type="text"
                        name="other_ethnicity"
                        value={oDoctorRequest.doctor.other_ethnicity}
                        onChange={(e) => handleOnChange(e, "other_ethnicity")}
                        placeholder="Please enter ethnicity value"
                        label={"Other*"}
                        autoFocus = {oDoctorRequest?.doctor?.other_ethnicity ? false : true}
                      />
                    ) : (
                      <></>
                    )}
                    <SelectElement
                      classNm={"col-sm-12"}
                      name={"language"}
                      className="reactSelect"
                      classNamePrefix="react-select"
                      isMulti={true}
                      value={oDoctorRequest?.data?.userLanguages || ""}
                      options={oDoctorProfileFields?.languages || []}
                      onChange={(e) =>
                        onChangeMultiSelect(
                          e,
                          "user_languages",
                          "userLanguages",
                          "other_language",
                          "isLanguageOther"
                        )
                      }
                      placeholder={"Please select language"}
                      stayMenuOnSelect={ApplicationConstant.TRUE}
                      label={"Languages Spoken* (Select all that apply)"}
                    />
                    {oDoctorFormState.isLanguageOther ? (
                      <InputElement
                        className="col-sm-12"
                        type="text"
                        name="other_language"
                        value={oDoctorRequest.doctor.other_language || ""}
                        onChange={(e) => handleOnChange(e, "other_language")}
                        placeholder="Please enter language value"
                        label={"Other*"}
                        autoFocus = {oDoctorRequest?.doctor?.other_language ? false : true}
                      />
                    ) : (
                      <></>
                    )}
                    <SelectElement
                      classNm={"col-sm-12"}
                      name={"identification"}
                      className="reactSelect"
                      classNamePrefix="react-select"
                      isMulti={true}
                      value={oDoctorRequest?.data?.userIdentifications || ""}
                      options={oDoctorProfileFields?.identifications || []}
                      onChange={(e) =>
                        onChangeMultiSelect(
                          e,
                          "user_identifications",
                          "userIdentifications",
                          "other_identification",
                          "isIdentificationOther"
                        )
                      }
                      placeholder={"Please select identification"}
                      stayMenuOnSelect={ApplicationConstant.TRUE}
                      label={"Gender Identity* (Select all that apply)"}
                    />
                    {oDoctorFormState.isIdentificationOther ? (
                      <InputElement
                        className="col-sm-12"
                        type="text"
                        name="other_identification"
                        value={oDoctorRequest.doctor.other_identification || ""}
                        onChange={(e) =>
                          handleOnChange(e, "other_identification")
                        }
                        placeholder="Please enter identification value"
                        label={"Other*"}
                        autoFocus = {oDoctorRequest?.doctor?.other_identification ? false : true}
                      />
                    ) : (
                      <></>
                    )}
                    <SelectElement
                      classNm={"col-sm-12"}
                      name={"sexOrientation"}
                      className="reactSelect"
                      classNamePrefix="react-select"
                      isMulti={true}
                      value={oDoctorRequest?.data?.userSexOrientation}
                      options={oDoctorProfileFields?.sexual_orientations || ""}
                      onChange={(e) =>
                        onChangeMultiSelect(
                          e,
                          "user_sexual_orientations",
                          "userSexOrientation",
                          "other_sexual_orientation",
                          "isSexualOrientationOther"
                        )
                      }
                      placeholder={"Please select orientation"}
                      stayMenuOnSelect={ApplicationConstant.TRUE}
                      label={"Sexual Orientation* (Select all that apply)"}
                    />
                    {oDoctorFormState.isSexualOrientationOther ? (
                      <InputElement
                        className="col-sm-12"
                        type="text"
                        name="other_sexual_orientation"
                        value={
                          oDoctorRequest.doctor.other_sexual_orientation || ""
                        }
                        onChange={(e) =>
                          handleOnChange(e, "other_sexual_orientation")
                        }
                        placeholder="Please enter orientation value"
                        label={"Other*"}
                        autoFocus = {oDoctorRequest?.doctor?.other_sexual_orientation ? false : true}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DoctorRegistrationFooter
            setLoadingState={setLoading}
            oDoctorRequest={oDoctorRequest}
            oDoctorFormState={oDoctorFormState}
            profileMode={editMode}
            handleCancel={closeModal}
            fetchProfile={fetchProfile}
          />
        </div>
      )}
    </>
  );
}

export default DoctorRegistration;
