import React, {useEffect, useState} from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment-timezone';
import {CustomDateCell, CustomDateCell2} from '../../../../common/calendarElements/CalendarElements';
import 'react-big-calendar/lib/sass/styles.scss';
const localizer = momentLocalizer(moment);

const ScheduleCalendar = (props) => {
    const { onDateChange } = props;
    const start = moment().startOf('isoWeek');
    const end = moment(start).add(1, 'days');
    const end2 = moment(start).add(6, 'days');
    const [event, setEvent] = useState([{ start, end }]);
    const [calendarSelectedDate, setCalendarSelectedDate] = useState(start.format('ddd LL'))
    const [calendarSelectedDateEnd, setCalendarSelectedDateEnd] = useState(end2.format('ddd LL'))
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleCalendar = () => setDropdownOpen(prevState => !prevState);

    const handleSelect = ({ start, end }) => {
        if (moment(start).day() !== 1) {
            return;
        }

        const selectedDate = moment(start).format("YYYY-MM-DD").toString();

        setEvent([{ start, end }]);
        onDateChange(selectedDate);
        toggleCalendar();

        setCalendarSelectedDate(moment(start).format('ddd LL'))
        setCalendarSelectedDateEnd(moment(start).add(6, 'days').format('ddd LL'))
    }

    return (
        <>
            <div className="schedule-an-appointment">
                <h4>{calendarSelectedDate} - {calendarSelectedDateEnd}</h4>
                <Dropdown isOpen={dropdownOpen} toggle={toggleCalendar}>
                    <DropdownToggle tag="a" className="nav-link" caret>
                        <span class="material-icons-outlined">insert_invitation</span>
                    </DropdownToggle>
                    <DropdownMenu>
                        <Calendar
                            selectable
                            localizer={localizer}
                            events={event}
                            views={['month', 'week']}
                            //defaultDate={moment().startOf('isoWeek').toDate()}
                            onSelectEvent={e => console.log(e.start)}
                            onSelectSlot={handleSelect}
                            timeslots={4}
                            longPressThreshold={10}
                            components={{
                                dateCellWrapper: (props)=><CustomDateCell2 onSelect={handleSelect} {...props}/>,
                            }}
                        />
                    </DropdownMenu>
                </Dropdown>
            </div>
        </>
    )
}

export default ScheduleCalendar;
