import React,{useState} from 'react';
import { ApplicationConstant, NPITypes, profileKey, targetedId } from '../../../constants/application.constant';
import { getNPIType } from '../../../utils/helper';
import DocumentView from '../../common/modal/DocumentView';
import ProfileTooltip from '../../common/profileElements/ProfileTooltip';

function DoctorProfileProfInfo(props) {
  const { profileInfo } = props;
  const [isOpen, setModalOpen] = useState(false);
  const toggleViewModal = () => setModalOpen(!isOpen);
  return (
    <>
      <h2 className="profile-section-head">Professional Information</h2>
      <div className="col-md-4 col-sm-6">
        <label>NPI number</label>
        <div className="d-flex align-items-center">
          <div className="value mr-8">
            {profileInfo?.doctor_profile?.npi_number}
          </div>
          <div className="value">
            {profileInfo?.doctor_profile?.is_npi_verified ===
            ApplicationConstant.ONE ? (
              <span style={{ color: "#3dae51" }}> (Verified) </span>
            ) : (
              <span style={{ color: "#cf342d" }}>(Non-Verified)</span>
            )}
          </div>
        </div>
      </div>
      <div className="col-md-4 col-sm-6 ">
        <label>NPI Type</label>
        <div className="value">
          {getNPIType(profileInfo?.doctor_profile?.npi_type, NPITypes)}
        </div>
      </div>
      <div className="col-md-4 col-sm-6">
        <label>License Number</label>
        <div className="value">
          {profileInfo?.doctor_profile?.license_number}
        </div>
      </div>
      <div className="col-md-4 col-sm-6">
        <label>License Expiration Date</label>
        <div className="value">
          {profileInfo?.doctor_profile?.expiration_date}
        </div>
      </div>
      <div className="col-md-4 col-sm-6">
        <label>Uploaded Professional License</label>
        <div className="value">
          {
            <span className="view-doc" onClick={toggleViewModal}>
              VIEW DOCUMENT
            </span>
          }
        </div>
      </div>
      <div className="col-md-4 col-sm-6">
        <label>State(s) of license</label>
        <div className="value more-items">
          {profileInfo?.doctor_profile?.state_licenses?.length
            ? ProfileTooltip(
                profileInfo?.doctor_profile?.state_licenses,
                targetedId.DOCTOR,
                profileKey.NAME,
                profileKey.STATE
              )
            : "-"}
        </div>
      </div>
      <div className="col-md-4 col-sm-6">
        <label>Insurance Providers</label>
        <div className="value more-items">
          { profileInfo?.doctor_profile?.accept_insurance === 'N' ?
          (
            <span class="material-icons theme-color">cancel</span>
          ):(
           profileInfo?.doctor_profile?.doctor_insurance_providers?.length
            ? ProfileTooltip(
                profileInfo?.doctor_profile?.doctor_insurance_providers,
                targetedId.DOCTOR,
                profileKey.NAME,
                profileKey.OTHER_INSURANCE_PROVIDER
              )
            : "-"
          )}
        </div>
      </div>
      <div className="col-md-4 col-sm-6">
        <label>Prescription Availability</label>
        <div>
          {profileInfo?.doctor_profile?.prescription_availability === "Y" ? (
            <span class="material-icons check-icon">check_circle</span>
          ) : (
            <span class="material-icons theme-color">cancel</span>
          )}
        </div>
      </div>
      <div className="col-md-4 col-sm-6 d-none">
        <label>Private Practice</label>
        <div>
          {profileInfo?.doctor_profile?.see_patients === "Y" ? (
            <span class="material-icons check-icon">check_circle</span>
          ) : (
            <span class="material-icons theme-color">cancel</span>
          )}
        </div>
      </div>
      <div className="col-md-4 col-sm-6">
        <label>Malpractice Insurance</label>
        <div>
          {profileInfo?.doctor_profile?.own_insurances === "Y" ? (
            <span class="material-icons check-icon">check_circle</span>
          ) : (
            <span class="material-icons theme-color">cancel</span>
          )}
        </div>
      </div>
      <div className="col-md-4 col-sm-6">
        <label>Accept Insurance</label>
        <div>
          {profileInfo?.doctor_profile?.accept_insurance === "Y" ? (
            <span class="material-icons check-icon">check_circle</span>
          ) : (
            <span class="material-icons theme-color">cancel</span>
          )}
        </div>
      </div>
      <div className="col-md-4 col-sm-6">
        <label>Electronic Records</label>
        <div>
          {profileInfo?.doctor_profile?.electronic_medical_record === "Y" ? (
            <span class="material-icons check-icon">check_circle</span>
          ) : (
            <span class="material-icons theme-color">cancel</span>
          )}
        </div>
      </div>
      <DocumentView
        setModelOpen={toggleViewModal}
        isOpen={isOpen}
        modalClassName="viewDocModal"
        className={"viewDocModal-dialog"}
        mediaPath={profileInfo?.doctor_profile?.document}
      />
    </>
  );
}

export default DoctorProfileProfInfo;

