import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/sass/styles.scss';
import { CustomDateCell } from '../../common/calendarElements/CalendarElements';
import { useState } from 'react';

const localizer = momentLocalizer(moment);

const AppointmentCalendar = (props) => {
  const { onSelectDate } = props;
  const [event, setEvent] = useState([]);

  const handleSelect = ({ start, end }) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    if (currentDate <= start) {
      onSelectDate(start);
      setEvent([{ start, end }]);
    }
  };

  return (
    <>
      <p>Doctor Appointment</p>
      <Calendar
        selectable={true}
        localizer={localizer}
        events={event}
        startAccessor='start'
        endAccessor='end'
        style={{ height: 500 }}
        onSelectSlot={handleSelect}
        longPressThreshold={10}
        components={{
          dateCellWrapper: (props)=><CustomDateCell onSelect={handleSelect} {...props}/>,
        }}
      />
    </>
  );
};

export default AppointmentCalendar;
