import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import UserRegistrationProvider from '../../../contexts/user-registration/UserRegistrationProvider';
import PatientHealthProfileContainerComponent from '../../../components/user-registration/patient/PatientProfileContainer';

const PatientEditProfile = (props) => {
  const {
    isOpen,
    modalOpen,
    className,
    modalClassName,
    getSavedData
  } = props;

  return (
    <div>
      <Modal isOpen={isOpen} toggle={modalOpen} className={className} modalClassName={modalClassName}>
        <ModalHeader>
          <div>Edit Profile</div>
          <a onClick={modalOpen} class="btn-white-outline modal-close-btn d-flex align-items-center ">
            <span class="material-icons md-dark md-18">close</span>
          </a>
        </ModalHeader>
        <ModalBody>
          <div className="form-step">
            <div className="w-100">
              <UserRegistrationProvider>
                <PatientHealthProfileContainerComponent
                  profileMode={'edit'}
                  closeModal={modalOpen}
                  getSavedData={getSavedData}
                />
              </UserRegistrationProvider>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default PatientEditProfile;

