import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { ToastContainer, toast, Slide } from 'react-toastify';
import PatientSummary from '../../../summary/patient/PatientSummary';
import useDocumentTitle from '../../../../utils/DocumentTitle';
import { PageTitles, ToastMessage } from '../../../../constants/application.constant';
import PatientEditProfile from '../../../common/modal/PatientEditProfile';

function PatientUserProfile() {
  useDocumentTitle(PageTitles.USER_PROFILE);
  const history = useHistory();
  const [isOpen, modalOpen] = useState(false);
  const [profileSavedData, setProfileSavedData] = useState(null);
  const [fetchDataStatus, setFetchDataStatus] = useState(false);

  const toggleEditModal = () => {
    modalOpen(!isOpen);
    setFetchDataStatus(false);
  }

  const openEditForm = (infoSteps) => {
    toggleEditModal();
    history.push({
      state: { step: infoSteps },
    });
  };

  const getSavedData = (data) => {
    setProfileSavedData(data?.data?.data || {});
    toast.success(ToastMessage.PROFILE_UPDATE);
    setFetchDataStatus(true);
    toggleEditModal();
  }
  return (
    <div className="middle-content edit-profile user-right-container">
      <div className="pages">
        <div className="profile-information">
          <div className="form-step max-w-100">
            <div className="content p-0">
              <PatientSummary
                isUserProfile={true}
                handleEditSummary={openEditForm}
                savedProfileData={profileSavedData}
                isProfileDateSaved={fetchDataStatus}
                />
              <PatientEditProfile
                isOpen={isOpen}
                modalOpen={toggleEditModal}
                className={'edit-profile-modal'}
                modalClassName={'right'}
                getSavedData={getSavedData}
              />
              <ToastContainer autoClose={4000} hideProgressBar transition={Slide} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PatientUserProfile;
