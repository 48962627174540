import { Children, cloneElement } from 'react';
import { timeZoneFormats } from "../../../utils/TimezoneUtils";
import moment from 'moment';
import {isMobile ,isTablet } from 'react-device-detect';

const CalendarDays = (props) => {
    const { selectedDate, currentDate, calendarChildren } = props;
    return (
        <div className={`rbc-day-bg ${selectedDate < currentDate ? "date-in-past" : "" }`}>
            {calendarChildren}
        </div>
    )
}


export const CustomDateCell = ({value, onSelect, children}) => {
    const currentDate = new Date();
    const endDate = new Date(moment(value).add(1,'days').format(timeZoneFormats.FULL_DATE)).setHours(0,0,0,0);
    currentDate.setHours(0,0,0,0);
    return (
        <>
            {isMobile || isTablet ? cloneElement(Children.only(children), {
                    onTouchEnd: () => onSelect({action:'click', slots:[value], start:value, end: endDate}),
                    style: {
                    className: `${children}`
                    }
                })
            :
                <CalendarDays
                    selectedDate={value}
                    currentDate={currentDate}
                    calendarChildren={children}
                />
            }
        </>
    )
}

const CalendarDays2 = (props) => {
    const { selectedDate, currentDate, calendarChildren } = props;

    return (
        // only Mondays
        <div className={`rbc-day-bg ${selectedDate.getDay() !== 1 ? "date-in-past" : "" }`}>
            {calendarChildren}
        </div>
    )
}

export const CustomDateCell2 = ({value, onSelect, children}) => {
    const currentDate = new Date();
    const endDate = new Date(moment(value).add(1,'days').format(timeZoneFormats.FULL_DATE)).setHours(0,0,0,0);
    currentDate.setHours(0,0,0,0);

    return (
        <>
            {isMobile || isTablet ? cloneElement(Children.only(children), {
                    onTouchEnd: () => onSelect({action:'click', slots:[value], start:value, end: endDate}),
                    style: {
                        className: `${children}`
                    }
                })
                :
                <CalendarDays2
                    selectedDate={value}
                    currentDate={currentDate}
                    calendarChildren={children}
                />
            }
        </>
    )
}