
import { profileKey, ApplicationConstant, targetedId } from '../../../../../constants/application.constant';
import ProfileTooltip from '../../../../common/profileElements/ProfileTooltip';
import { Image } from '../../../../common/image/Image';
import SessionFee from '../sessionFee/SessionFee';

const DoctorInfo = (props) => {
    const { userProfile } = props;

    return (
      <>
        <div className="back-link doctor-schedule-back-link">
          <h1>Schedule Availability and Settings</h1>
        </div>
        <section className="detail-info doctor-schedule-profile">
          <div className="profile-info">
            <div className="row">
              <div className="col-sm-12 col-md-12">
                <div className="box">
                  <div className="user-info">
                    <div className="profile-img">
                      <Image
                        src={userProfile?.user?.profile_image || ""}
                        alt="Profile image"
                      />
                    </div>
                    <div className="head">
                      <div className="title-text mt-5">
                        <h1>
                          {userProfile?.user?.first_name}{" "}
                          {userProfile?.user?.last_name}
                        </h1>
                        <h2>
                          {userProfile?.doctor_specializations?.length
                            ? userProfile?.doctor_specializations[
                                ApplicationConstant.ZERO
                              ].name
                            : "-"}
                        </h2>
                      </div>
                      <div className="profile-info">
                        <div className="row">
                          <div className="col-md-4 col-sm-12">
                            <label>City</label>
                            <div className="value">
                              {userProfile?.location?.city_name}
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <label>State</label>
                            <div className="value">
                              {userProfile?.location?.state_name}
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-12">
                            <label>Associated Hospitals</label>
                            <div className="value more-items">
                              {userProfile?.user_facilities?.length
                                ? ProfileTooltip(
                                    userProfile?.user_facilities,
                                    targetedId.DOCTOR,
                                    profileKey.NAME
                                  )
                                : "-"}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <SessionFee />
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
}

export default DoctorInfo;
