import React from 'react';
import DoctorPersonalInformation from './DoctorPersonalInformation';
import DoctorProfessionalInformation from './DoctorProfessionalInformation';
import DoctorBackgroundInformation from './DoctorBackgroundInformation';

function DoctorAccountCreation(props) {
  const { oLabelInfo, isHospitalFlow , filename, setFilename} = props;
  return (
    <div>
      <div className="head-title mb-50">
        <div className="heading">{'Provider Registration'}</div>
        <div className="sub-heading">Account Creation</div>
      </div>
      <div className="form-step">
        <form className="w-100" autocomplete="off">
          <DoctorPersonalInformation isHospitalFlow={isHospitalFlow}/>
          <DoctorProfessionalInformation oLabelInfo={oLabelInfo} isHospitalFlow={isHospitalFlow} filename={filename} setFilename={setFilename}/>
          <DoctorBackgroundInformation oLabelInfo={oLabelInfo} isHospitalFlow={isHospitalFlow}/>
        </form>
      </div>
    </div>
  );
}

export default DoctorAccountCreation;
