import React, { useContext, useEffect } from 'react';
import UserRegistrationContext from '../../../contexts/user-registration/UserRegistrationContext';
import update from 'immutability-helper';
import { FormGroup, Input, InputGroup } from 'reactstrap';
import SkipToNextProfile from './SkipToNextProfile';
import { ApplicationConstant } from '../../../constants/application.constant';
import FormCheckBox from '../../common/formElements/FormCheckBox';

let aSexualOrientationOptions = [];

function SelectSexualOrientation({ aSexualOrientationOption }) {
    const [oUserRegistration, setUserRegistration] = useContext(UserRegistrationContext);
    const handleSelect = (id) => {
        const i = aSexualOrientationOption.findIndex(o => o.id === id);
        if (i > -ApplicationConstant.ONE) {
            aSexualOrientationOptions[i].isChecked = !aSexualOrientationOptions[i].isChecked;
            const selectedOrientation = aSexualOrientationOptions.filter(o => o.isChecked);
            setUserRegistration(update(oUserRegistration, { patient_profile: { user_sexual_orientations: { $set: selectedOrientation } } }));
        }
    }
    const onOtherInput = (e) => {
        e.preventDefault();
        const value = e.target.value;
        setUserRegistration(update(oUserRegistration, { patient_profile: { other_sexual_orientation: { $set: value } } }));
    }

    useEffect(() => {
        aSexualOrientationOptions = aSexualOrientationOption;
    }, [])
    return (
        <div className="form-step">
            <h3>{"Sexual Orientation (Select all that apply)"}</h3>
            <form className="w-100">
                <FormGroup tag="fieldset">
                    <div className="row">
                        {
                            aSexualOrientationOption.map((item) => {
                                if ((oUserRegistration.patient_profile.user_sexual_orientations.find(o => o.name.toLowerCase() === ApplicationConstant.OTHER_LOWER_CASE)) &&
                                    (item.name.toLowerCase() === ApplicationConstant.OTHER_LOWER_CASE)) {
                                    return (
                                        <div key={item.id} className="col-sm-6">
                                            <InputGroup>
                                                <div className="label-float">
                                                    <Input value={oUserRegistration.patient_profile.other_sexual_orientation}
                                                        onChange={onOtherInput}
                                                        maxLength="100"
                                                        type="text"
                                                        placeholder="Please Enter"
                                                        autoFocus/>
                                                    <label>{`${item.name}`}</label>
                                                </div>
                                            </InputGroup>
                                        </div>
                                    );
                                } else {
                                    return (
                                        <FormCheckBox
                                            key={item.id}
                                            keyId={item.id}
                                            isChecked={item.isChecked}
                                            item={item}
                                            label={`${item.name}`}
                                            handleSelect={handleSelect}
                                        />
                                    );
                                }
                            })
                        }
                    </div>
                </FormGroup>
            </form>
            <SkipToNextProfile />
        </div>
    )
}

export default SelectSexualOrientation;
