import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { loadStripe } from '@stripe/stripe-js';
import Payment from '../../payment/Payment';

const PaymentModal = (props) => {
  const { className, modalClassName, isOpen, setModelOpen, title, bookAppointemnt, userDetail, paymentPayload } = props;

  const stripePromise = () => {
    if(paymentPayload?.account_id) {
      return loadStripe(process.env.REACT_APP_STRIPE_KEY,{
        stripeAccount: paymentPayload?.account_id}
      );
    } else {
      return loadStripe(process.env.REACT_APP_STRIPE_KEY);
    }
  }

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={() => {setModelOpen();}}
        className={className}
        modalClassName={modalClassName}
      >
        <ModalHeader
          toggle={() => {setModelOpen();}}
        >{title}
        </ModalHeader>
        <ModalBody>
          <Payment
            bookAppointemnt={bookAppointemnt}
            userDetail={userDetail}
            paymentPayload={paymentPayload}
            stripePromise={stripePromise()}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default PaymentModal;
