import { useState } from 'react';
import { Image } from '../../../../common/image/Image';
import { patientPreferredName } from '../../../../../utils/helper';
import { convertUtcToLocalTime } from '../../../../../utils/TimezoneUtils';
import { ApplicationConstant } from '../../../../../constants/application.constant';
import DoctorAppointmentTabContainer from './documents/DoctorAppointmentTabContainer';

const CompletedAppointmentItem = (props) => {
  const { item } = props;
  const [viewHistoryOpen, isViewHistoryOpen] = useState(false);
  const [activeCollapse, setActiveCollapse] = useState(false);

  const sendEmail = (emailId) => {
    emailId && window.location.assign(`mailto:${emailId}`);
  };

  const toggleCollapsePanel = () => {
    setActiveCollapse(!activeCollapse);
    isViewHistoryOpen(true);
  }  

  return (
    <>
      <div className="completed-appointment-box doctor-completed-appointment-box">
        <div className="user-info">
          <div className="profile-img">
            <Image src={item?.user?.profile_image || ""} alt="Profile image" />
          </div>
          <div className="head">
            <div className="title-text">
              <h1 className="font-500 font-16">
                {patientPreferredName(
                  item?.user?.first_name,
                  item?.user?.last_name,
                  item?.is_disply_preferred_name,
                  item?.user?.preferred_name
                )}{" "}
              </h1>
              <h3>
                Appointment:{" "}
                <span className="ml-3">
                  {" "}
                  {ApplicationConstant.TELEHEALTH}
                </span>
              </h3>
              <div className="datetime">
                <span className="material-icons">event</span>
                <p>
                  {convertUtcToLocalTime(item?.start_date_time, 'll')}{' | '}
                  {convertUtcToLocalTime(item?.start_date_time, 'hh:mm A')}{' - '}
                  {convertUtcToLocalTime(item?.end_date_time, 'hh:mm A')}
                </p>
              </div>
            </div>
            <div class="action-status d-flex row-action-status">
              <a
                class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8"
                onClick={() => {
                  sendEmail(item?.user?.email);
                }}>
                <span class="material-icons md-dark md-18">email</span>
              </a>
            </div>
          </div>
        </div>
          <div class="button-row">
            <span
              class="view-history"
              onClick={toggleCollapsePanel}>
              View History{" "}
              <span class="material-icons-outlined">
                {activeCollapse ? "expand_less" : "expand_more"}
              </span>
            </span>
          </div>
          {activeCollapse && <DoctorAppointmentTabContainer 
            activeCollapse={activeCollapse}
            item={item}
            toggleCollapsePanel={toggleCollapsePanel}
            viewHistoryOpen={viewHistoryOpen}/>
          }
      </div>
    </>
  );
};

export default CompletedAppointmentItem;