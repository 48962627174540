import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Image } from './../image/Image';
import { patientPreferredName } from '../../../utils/helper';
import { convertUtcToLocalTime } from '../../../utils/TimezoneUtils';
import InputElement from '../formElements/InputElement';
import { ApplicationConstant, RouteConstants } from '../../../constants/application.constant';
import SuggestAvailability from './../../appointment/doctor/suggestAvailability/SuggestAvailability';

const CancelScheduleAppointment = (props) => {
  const { modalClassName, isOpen, setModelOpen, userData, fetchUpdatedList, targetPath} = props;
  const history = useHistory();
  const [isSuggestAvailability, setSuggestAvailability] = useState(false);
  const [personalMessage, setPersonalMessage] = useState('');

  const canceledAppointment = () => {
    history.push({
      pathname: RouteConstants.DOCTOR_CANCEL_CONFIRMATION,
      state: {
        appointmentId: userData?.appointment_id,
        targetPath: targetPath
      },
    });
  }


  return (
    <div>
      <Modal isOpen={isOpen} toggle={() => {setModelOpen()}} modalClassName={modalClassName}>
        <ModalHeader toggle={() => {setModelOpen();}}></ModalHeader>
        <ModalBody className="emal-sent">
            <h1>Cancel Appointment</h1>
            <div className="profile-info">
                  <div className="box">
                    <div className="user-info">
                      <div className="profile-img">
                        <Image src={userData?.user?.profile_image || ''} alt='Profile image' />
                      </div>
                      <div className="head">
                        <div className="title-text">
                          <h2>With: <span>
                            {patientPreferredName(
                              userData?.user?.first_name,
                              userData?.user?.last_name,
                              userData?.is_disply_preferred_name,
                              userData?.user?.preferred_name
                            )}{' '}
                          </span></h2>
                          <h2>Date: <span>{convertUtcToLocalTime(userData?.start_date_time, 'll')}</span></h2>
                          <h2>Time: <span>{convertUtcToLocalTime(userData?.start_date_time, 'LT')}</span></h2>
                          <h2>Appointment: <span className="ml-3">{' '}{ApplicationConstant.TELEHEALTH}</span></h2>
                        </div>
                        <div className="button-group">
                            <Button color="secondary" onClick={canceledAppointment}>Cancel</Button>
                            <Button color="primary" disabled>Accept</Button>
                            <Button className="d-none" color="secondary" onClick={()=>setSuggestAvailability(true)}>Suggest a Different Time</Button>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <InputElement
                className="personal-message-textarea"
                value={personalMessage}
                type="textarea"
                name="personal_message"
                onChange={(e)=>{setPersonalMessage(e.target.value)}}
                placeholder="Leave a personal message"
                label={'Leave a personal message'}
            />
            {isSuggestAvailability &&
              <SuggestAvailability
                apointmentId={userData?.appointment_id}
                userId={userData?.user_id}
                modalClose={() => {setModelOpen()}}
                fetchUpdatedList={fetchUpdatedList}
              />
            }
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CancelScheduleAppointment;
