import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { SessionDocumentTabTitle } from '../../../../../../constants/application.constant';


function PatientTabHead(props) {
    const { activeTab, toggle } = props;
    return (
        <Nav tabs>
        <NavItem className="patientDocumentTabHead">
          <NavLink
            className={classnames({
              active: activeTab === SessionDocumentTabTitle.NOTES,
            })}
            onClick={() => {
              toggle(SessionDocumentTabTitle.NOTES);
            }}>
            {SessionDocumentTabTitle.TAKE_NOTES}
          </NavLink>
        </NavItem>
        <NavItem className="patientDocumentTabHead">
          <NavLink
            className={classnames({
              active: activeTab === SessionDocumentTabTitle.DOCUMENTS,
            })}
            onClick={() => {
              toggle(SessionDocumentTabTitle.DOCUMENTS);
            }}>
            {SessionDocumentTabTitle.DOCUMENTS}
          </NavLink>
        </NavItem>
      </Nav>
    )
}

export default PatientTabHead;


