import React, { useState, useEffect } from 'react';
import SelectElement from '../../../common/formElements/SelectElement';
import InputElement from '../../../common/formElements/InputElement';
import { ApplicationConstant } from '../../../../constants/application.constant';
import { zipCodeValidation } from '../../../../validators/CommonValidation';
import { getCitiesById } from '../../../../utils/CommonRequests';

function DoctorLocationInformation({ profileData, setValueByFieldName, options, setLocationValues }) {
  const [stateId, setStateId] = useState(null);
  const [cities, setCities] = useState([]);

  const onMultiSelectInput = (name, e) => {
    if (e.length >= ApplicationConstant.ONE) {
      setValueByFieldName(name, e);
    } else {
      setValueByFieldName(name, '');
    }
  };

  const setAddressValue = (e) => {
    e.preventDefault();
    const aaddressObj = {
      value: e.target.value,
    };
    setLocationValues(e.target.name, aaddressObj);
  };

  const removeFilledCityValues = () => {
    profileData.location.city.id = '';
    profileData.location.city.label = '';
  };

  const onStateInput = (e) => {
    setStateId(e.id);
    setLocationValues('state', e);
    removeFilledCityValues();
  };

  const getCityData = () => {
    getCitiesById(stateId).then((res) => {
      if (res.length > ApplicationConstant.ZERO) {
        setCities(res.map((item) => ({ ...item, label: item.name, value: item.name.toLowerCase() })));
      }
    });
  };

  const onCityInput = (e) => {
    setLocationValues('city', e);
  };

  const setZipCode = (e) => {
    zipCodeValidation('zip_code', e.target.value, setLocationValues);
  };

  useEffect(() => {
    getCityData();
  }, [stateId]);

  return (
    <>
      <h3 className="mt-24">Location</h3>
      <h4 className="mt-24 font-400">Primary Address</h4>
      <div className="row mt-12">
        <InputElement
          className="col-sm-6"
          type="text"
          name="address1"
          value={profileData?.location?.address1.value}
          onChange={setAddressValue}
          autoComplete={ApplicationConstant.OFF}
          placeholder={"Please enter street address*"}
          label={"Street Address*"}
        />
        <InputElement
          className="col-sm-6"
          type="text"
          name="address2"
          value={profileData?.location.address2.value}
          onChange={setAddressValue}
          autoComplete={ApplicationConstant.OFF}
          placeholder={"Please enter appartment"}
          label={"Apartment"}
        />
        <SelectElement
          classNm={"col-sm-6"}
          name={"state"}
          onChange={onStateInput}
          className="reactSelect"
          classNamePrefix="react-select"
          options={options?.states}
          value={profileData?.location.state}
          isSearchable={ApplicationConstant.TRUE}
          placeholder={""}
          label={"State*"}
        />
        <SelectElement
          classNm={"col-sm-6"}
          name={"city"}
          onChange={onCityInput}
          className="reactSelect"
          classNamePrefix="react-select"
          options={cities || []}
          value={profileData?.location.city}
          isSearchable={ApplicationConstant.TRUE}
          placeholder={""}
          label={"City*"}
        />
        <InputElement
          className="col-sm-12"
          type="number"
          name="zip_code"
          value={profileData?.location.zip_code.value}
          onChange={setZipCode}
          autoComplete={ApplicationConstant.OFF}
          placeholder={"Please enter zipcode"}
          label={"Zip Code*"}
          isValid={profileData?.location.zip_code.valid}
          msg={profileData?.location.zip_code.msg}
        />
      </div>
    </>
  );
}

export default DoctorLocationInformation;
