import { ApplicationConstant } from '../../../constants/application.constant';

const setObj = (val, valid = false, msg = '') => {
  return {
    value: val,
    valid: valid,
    msg: msg
  };
};


export const futureDateValidation = (val) => {
  let o = {};
  if (new Date().toDateString() === new Date(val).toDateString()) {
    o = setObj(val, true, '');
  } else if (new Date() <= new Date(val)) {
    o = setObj(val, true, '');
  } else {
    o = setObj(val, false, 'Selected date must be a future date');
  }
  return o;
};

export const licenceNumberValidation = (val) => {
  let o = {};
  if (val && val.length === ApplicationConstant.TEN) {
    o = setObj(val, ApplicationConstant.TRUE, 'success');
  } else {
    o = setObj(val, ApplicationConstant.FALSE, 'Please Enter 10 digits License number.');
  }
  return o;
};
