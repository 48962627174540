const ApiConstant = {
  LOGIN: 'login',
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'reset-password',
  VALIDATE_TOKEN: 'verify-reset-password',
  VERIFY_EMAIL: 'verify-email',
  RESEND_EMAIL: 'resend-email-verification',
  PATIENT_FORM_FIELDS: 'patient-profile-info',
  PATIENT_ACCOUNT_CREATION: 'create-patient-profile',
  PATIENT_SIGNUP: 'signup',
  LANGUAGES: 'language',
  STATE: 'state',
  CITY: 'city',
  PATIENT_PROFILE: 'patient-profile',
  DOCTOR_PROFILE_INFO: 'doctor-profile-info',
  DOCTOR_PROFILE: 'doctor-profile',
  HOSPITAL_PROFILE: 'hospital-profile',
  CHECK_EMAIL: 'check-email',
  CHECK_PHONE: 'check-phone',
  DOCTOR_BACKGROUND_INFO: 'doctor-background-info',
  DOCTOR_SIGNUP: 'doctor-signup',
  CREATE_DOCTOR_PROFILE: 'create-doctor-profile',
  UPLOAD_DOCUMENT: 'upload-document',
  DOCTOR_FACILITY: 'facility',
  DOCTOR_BACKGROUND_QUESTIONS: 'doctor-background-questions',
  LOGOUT: 'logout',
  HOSPITAL_SIGNUP: 'hospital-signup',
  PATIENT_DASHBOARD:'patient-dashboard',
  PATIENT_REVIEWS: 'patient-reviews',
  PATIENT_MATCHED_DOCTORS: 'patient-matched-doctors',
  PATIENT_FAVORITE_DOCTORS: 'patient-favorite-doctors',
  DOCTOR_DASHBOARD: 'doctor-dashboard',
  DOCTOR_REVIEWS: 'doctor-reviews',
  SCHEDULED_PATIENTS: 'scheduled-patients',
  SPECIALIZATIONS:'specializations',
  HOSPITAL_DASHBOARD: 'hospital-dashboard',
  HOSPITAL_PROVIDERS: 'hospital-providers',
  CONTACT_US_QUESTIONS_TYPE: 'contact-question-type',
  SUBMIT_QUESTION: 'submit-question',
  DOCTOR_INSURANCE_PROVIDER:'insurance-provider',
  DOCTOR_PRACTICE:'practice',
  PROCEDURE:'procedure',
  CONDITION_TREATED:'condition-treated',
  TERMS_SERVICE:'contents/terms_of_service',
  PRIVACY_POLICY:'contents/privacy_policy',
  FAQS: 'faqs',
  SUBMIT_FAQ_FEEDBACK: 'submit-faq-feedback',
  PATIENT_PREFERENCE: 'patient-preference-info',
  UPDATE_PATIENT_PROFILE: 'update-patient-profile',
  UPDATE_DOCTOR_PROFILE: 'update-doctor-profile',
  UPDATE_HOSPITAL_PROFILE: 'hospital-update-profile',
  UPDATE_DOCTOR_USER_PROFILE: 'update-doctor-user-profile',
  UPLOAD_PROVIDERS: 'hospital-import-providers',
  CSV_SAMPLE_URL:'hospital-sample-providers',
  CHANGE_PASSWORD: 'change-password',
  HOSPITAL_ADD_INDIVIDUAL_PROVIDER:'hospital-add-individual-provider',
  GET_FAV_DOCTOR: 'patient-favorite-doctor',
  ADD_FAV_DOCTOR: 'create-favorite-doctor',
  DELETE_FAV_DOCTOR: 'delete-favorite-doctor',
  FIND_DOCTOR:'find-doctors',
  PATIENT_VIEW_DOCTOR_PROFILE:'patient-view-doctor-profile',
  DOCTOR_VIEW_PATIENT_PROFILE:'doctor-view-patient-profile',
  DOCTOR_BACKGROUND_INFO_QUESTION: 'doctor-background-information',
  UPDATE_BACKGROUND_INFO: 'update-doctor-background-information',
  DOCTOR_UPCOMING_APPOINTMENT: 'doctor-upcoming-appointment',
  DOCTOR_CANCELED_APPOINTMENT: 'doctor-cancel-appointment',
  DOCTOR_PENDING_APPOINTMENT: 'doctor-pending-appointment',
  DOCTOR_COMPLETED_APPOINTMENT: 'doctor-completed-appointment',
  PATIENT_COMPLETED_APPOINTMENT: 'patient-completed-appointment',
  PATIENT_CANCELED_APPOINTMENT: 'patient-cancel-appointment',
  PATIENT_UPCOMING_APPOINTMENT: 'patient-upcoming-appointment',
  PATIENT_VIEW_AVAILABLE_SLOTS: 'patient-view-doctor-availability-slots',
  CHECK_DOCTOR_CURRENT_AVAILABLE_SLOTS: 'check-doctor-current-available-slot',
  TIME_ZONE: 'time-zone',
  WORKING_DAYS: 'working-days',
  DOCTOR_TIME_SLOT: 'doctor-time-slot',
  DOCTOR_SCHEDULE_AVAILABILITY: 'doctor-schedule-availability',
  CREATE_SCHEDULE_AVAILABILITY: 'create-schedule-availability',
  PATIENT_VIEW_DOCTOR_CONSULTANT_FEES:'patient-view-doctor-consultant-fees',
  PATIENT_BOOK_APPOINTMENT:'patient-book-appointment',
  DOCTOE_ACCEPTED_PATIENT_APPOINTMENT: 'doctor-accepted-patient-appointment',
  DOCTOE_CANCELED_PATIENT_APPOINTMENT: 'doctor-cancelled-patient-appointment',
  CANCEL_APPOINTMENT_BY_PATIENT: 'appointment-cancel-by-patient',
  DOCTOR_APPOINTMENTS: 'doctor-emd-calendar',
  PATIENT_APPOINTMENTS: 'patient-emd-calendar',
  DOCTOR_SCHEDULE_AVAILABILITY_SLOTS: 'doctor-schedule-availability-slot-status',
  DOCTOR_COMPLETE_SESSION: 'doctor-completed-patient-telehealth-session',
  DOCTOR_AVAILABILITY_SLOTS: 'doctor-availability-slots',
  DOCTOR_SUGGESTED_AVAILABILITY: 'doctor-suggested-different-time-appointment',
  DOCTOR_TELEHEALTH_SESSION_STARTED: 'doctor-telehealth-session-started',
  STRIPE_PAYMENT_INTENT: 'stripe-payment-intent',
  ZOOM_SIGNATURE: 'generate-zoom-signature',
  UPLOAD_APPOINTMENT_DOCUMENT: 'upload-appointment-document',
  PATIENT_APPOINTMENT_DOCUMENTS: 'patient-appointment-documents',
  DOCTOR_APPOINTMENT_DOCUMENTS: 'doctor-appointment-documents',
  SAVE_APPOINTMENT_NOTE:'save-appointment-note',
  DOCTOR_VIEW_PATIENT_APPOINTMENT_HISTORY:'doctor-view-patient-appointment-history',
  DOCTOR_APPOINTMENTS_NOTES:'doctor-appointment-notes',
  DELETE_APPOINTMENT_DOCUMENT: 'delete-appointment-document',
  SET_DOCUMENT_VISIBILITY: 'set-document-visibility',
  DOCTOR_SESSION_FEE: 'get-consultation_fees',
  SAVE_DOCTOR_SESSION_FEE: 'save-consultation_fees',
  DOCTOR_DOWNLOAD_NOTES:'doctor-download-notes',
  PATIENT_VIEW_DOCTOR_APPOINTMENT_HISTORY: 'patient-view-doctor-appointment-history',
  DOWNLOAD_APPOINTMENT_DOCUMENT: 'download-appointment-document',
  DOWNLOAD_DOCUMENT: 'download-document',
  APPOINTMENT_NOTES: 'appointment-notes',
  PATIENT_DOWNLOAD_NOTES:'patient-download-notes',
  VIEW_APPOINTMENT: 'view-appointment',
  DOCTOR_STRIPE_ACCOUNT: 'doctor-stripe-account',
  CREATE_STRIPE_ACCOUNT: 'create-stripe-account',
  UPDATE_STRIPE_ACCOUNT: 'update-stripe-account-status',
  CREATE_SUBSCRIPTION: 'create-subscription',
  GET_SUBSCRIPTION: 'subscription-plan',
  UPDATE_SUBSCRIPTION_STATUS: 'update-subscription-status',
  CANCEL_SUBSCRIPTION: 'cancel-subscription',
  PATIENT_PAYMENT_HISTORY:'patient-payment-history',
  PATIENT_PAYMENT_HISTORY_EXPORT:'patient-payment-history-export',
  DOCTOR_PAYMENT_HISTORY: 'doctor-payment-history',
  DOCTOR_YEARLY_PAYMENT_HISTORY: 'doctor-yearly-payment-history',
  DOCTOR_MONTHLY_PAYMENT_HISTORY: 'doctor-monthly-payment-history',
  DOCTOR_PAYMENT_HISTORY_EXPORT: 'doctor-payment-history-export',
  DOCTOR_MULTISELECT_PAYMENT_HISTORY_EXPORT: 'doctor-multiselect-payment-history-export',
  UPDATE_ZOOM_SESSION_DURATION: 'update-zoom-session-duration',
  APPOINTMENT_SUPERBILL_CODES_SEARCH: 'superbill-codes-search',
  APPOINTMENT_SUPERBILL:'superbill',
  APPOINTMENT_SUPERBILL_SAVE: 'superbill-save',
};

export default ApiConstant;
