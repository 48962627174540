import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getSearchParama } from './../../utils/helper';

const DoctorSearch = (props) => {
  const { changeSearch } = props;
  const history = useHistory();
  const params = new URLSearchParams();
  const [searchvalue, setSearchvalue] = useState(getSearchParama(history, 'search'));

  const clearSearch = () => {
    setSearchvalue('');
    changeSearch('');
    history.replace({search: ''})
  };

  const updateSearchParams = (val) => {
    if (val) {
      params.set('search', val)
    }
    history.replace({search: params.toString()})
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    changeSearch(searchvalue);
    updateSearchParams(searchvalue);
  };

  return (
    <form className="w-100" onSubmit={handleSubmit}>
      <div className="search-form list-search">
        <input
          value={searchvalue}
          placeholder="Search a provider..."
          className="search-checkbox"
          type="text"
          onChange={(e) => setSearchvalue(e.target.value)}
        />
        <span className="material-icons">search</span>
        {searchvalue && (
          <span onClick={clearSearch} className="material-icons close">
            close
          </span>
        )}
      </div>
    </form>
  );
};

export default DoctorSearch;
