import { toast } from 'react-toastify';
import PaymentHistoryListFilter from './PaymentHistoryListFilter';
import { ApplicationConstant } from '../../../../../constants/application.constant';
import { exportDoctorMultipleMonthsPaymentHistory } from '../DoctorPaymentHistoryUtils';
import { downloadFileFromBlob } from '../../../../../utils/helper';

const PaymentHistoryListHeader = (props) => {
  const { applyListFilter,
    clearListFilter,
    minFilterDate,
    maxFilterDate,
    checkAllMonths,
    isAllmonthsChecked,
    paymentHistoryList,
    appliedFilterDate
  } = props;

  const getSelectedItemsLength = () => {
    return paymentHistoryList?.filter(e => e.checked);
  }

  const exportMonthCSV = async () => {
    const selectedMonths = getSelectedItemsLength().map(e => `${e.year}-${e.month}`);
    const payload = {month_year: selectedMonths}
    await exportDoctorMultipleMonthsPaymentHistory(payload)
    .then((response)=>response.data)
    .then((blob)=>{downloadFileFromBlob(blob, 'payment-history.csv');
    }).catch((error) => toast.error(error || ''));
  }

  return (
    <div className="filter-row">
      <div className="month" hidden={paymentHistoryList?.length < ApplicationConstant.TWO}>
        <div className="check-btn m-0 mt--15">
          <label className="label--checkbox flat d-flex">
            <input
              type="checkbox"
              className="checkbox"
              id="checkAllMonths"
              onChange={checkAllMonths}
              checked={isAllmonthsChecked}
            />
            <label className="font-16 font-500" htmlFor="checkAllMonths"></label>
          </label>
        </div>
        <span className="ml-10">Select All</span>
      </div>
      <div className="action-status d-flex row-action-status ml-auto flex-wrap">
        {paymentHistoryList?.length ?
          <button
            className="btn-white-outline mr-10"
            disabled={!getSelectedItemsLength().length}
            onClick={exportMonthCSV}
          >
          <span className="material-icons-sharp md-dark md-18">download</span>
          </button>: ''
        }
        <PaymentHistoryListFilter
          applyListFilter={applyListFilter}
          clearListFilter={clearListFilter}
          minFilterDate={minFilterDate}
          maxFilterDate={maxFilterDate}
          paymentHistoryList={paymentHistoryList}
          appliedFilterDate={appliedFilterDate}
        />
      </div>
    </div>
  )
}




export default PaymentHistoryListHeader;
