import React, { useState } from 'react';
import { RouteConstants } from '../../../../constants/application.constant';
import GoBack from '../../../common/back/GoBack';
import CommonSidebar from '../../CommonSidebar';
import { PatientSideMenuItems } from './PatientSideMenuItems';

const PatientProfileSidebar = () => {
  const [pageHeading, setPageHeading] = useState('');
  const setPatientProfileTitle = (title) => {
    setPageHeading(title);
  };

  return (
    <div className="profile-sidebar">
      <div className="ml-0">
        <GoBack heading={pageHeading} targetPath={RouteConstants.DASHBOARD_PATIENT} />
      </div>
      <CommonSidebar sideMenuItems={PatientSideMenuItems} setPageTitle={setPatientProfileTitle} />
    </div>
  );
};

export default PatientProfileSidebar;
