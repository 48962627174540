import React from 'react';
import './App.scss';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import ProtectedRoutes from './routes/protected/ProtectedRoutes';
import PublicRoutes from './routes/public/PublicRoutes';
import Layout from './layout/Layout';
import PageNotFound from './components/common/pageNotFound/PageNotFound';
import { Routes } from './routes/Route';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Layout>
          <Switch>
            {Routes.map((route, i) => {
              return route.isProtected ?
              <ProtectedRoutes key={i} {...route} component={route.component} path={route.path} exact />:
              <PublicRoutes key={i} {...route} component={route.component} path={route.path} exact />
            })}
            <Route component={PageNotFound} />
          </Switch>
        </Layout>
      </BrowserRouter>
    </div>
  );
}

export default App;
