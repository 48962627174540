import { useState, useEffect } from 'react';
import axiosInstance from '../../axios/axiosInstance';
import ApiConstant from '../../constants/ApiConstant';
import useDocumentTitle from '../../utils/DocumentTitle';
import PageLogo from '../common/logo/PageLogo';
import { ApplicationConstant, PageTitles } from '../../constants/application.constant';
import GoBack from '../common/back/GoBack';
import SpinnerComponent from '../common/spinner/SpinnerComponent';
import { getDateFormat } from './../../utils/helper';

const PrivacyPolicy = () => {
  useDocumentTitle(PageTitles.PRIVACY_POLICY);
  const [privacyData, setPrivacyData] = useState({});
  const [isLoading, setLoading] = useState(false);

  const fetchTermsAndService = async () => {
    setLoading(true);
    await axiosInstance.get(`${ApiConstant.PRIVACY_POLICY}`).then((response) => {
      if (response && response.data && response.data.data) {
        const privacyPolicyData = response.data.data[ApplicationConstant.ZERO] || {};
        setPrivacyData(privacyPolicyData);
      }
    });
    setLoading(false);
  };

  useEffect(() => {
    fetchTermsAndService();
  }, []);

  function createMarkup(data) {
    return { __html: data };
  }

  return (
    <>
      {isLoading && <SpinnerComponent />}
      <div className="middle-content static-page-container terms-policy mb-0 mt-0 pb-100 h-100vh">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 back-btn-icon">
              <GoBack />
            </div>
          </div>
        </div>
        <div className="login-title">
          <PageLogo />
          <div className="back-link">
            <h1>
              {privacyData?.title || '-'}{' '}
              <span className="title-text">
                {privacyData?.updated_at ? `Effective date of privacy policy: ${getDateFormat(privacyData?.updated_at)}` : '-'}
              </span>
            </h1>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="static-page-content">
                <div dangerouslySetInnerHTML={createMarkup(privacyData?.description)}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
