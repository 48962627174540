import React from 'react';
import SelectElement from '../../../common/formElements/SelectElement';
import InputElement from '../../../common/formElements/InputElement';
import MultiSelectElement from '../../../common/formElements/MultiSelectElement';
import { ApplicationConstant } from '../../../../constants/application.constant';
import { experienceValidation } from '../../../../validators/CommonValidation';

function DoctorBackgroundInformation({ profileData, setValueByFieldName, options }) {
  const onSelectInput = (name, e) => {
    setValueByFieldName(name, e);
  };

  const onMultiSelectInput = (name, e) => {
    if (e.length >= ApplicationConstant.ONE) {
      setValueByFieldName(name, e);
    } else {
      setValueByFieldName(name, '');
    }
  };

  const experienceInput = (e) => {
    e.preventDefault();
    const obj = experienceValidation(e.target.value, profileData?.year_of_experience);
    setValueByFieldName('year_of_experience', obj);
  };

  const otherTextInput = (e) => {
    e.preventDefault();
    setValueByFieldName(e.target.name, e.target.value);
  };

  return (
    <>
      <h3>Background Information </h3>
      <div className="row">
        <SelectElement
          classNm={'col-sm-12'}
          name={'medical_school_id'}
          onChange={(e) => {
            onSelectInput('medical_school', e);
          }}
          options={options?.medical_schools}
          className="reactSelect"
          classNamePrefix="react-select"
          value={profileData?.medical_school || ApplicationConstant.NO_VALUE_STRING}
          isSearchable={ApplicationConstant.TRUE}
          placeholder={''}
          label={'School'}
        />
         {profileData?.medical_school?.name?.toLowerCase() ===
          ApplicationConstant.OTHER_LOWER_CASE && (
          <InputElement
            className="col-sm-12"
            type="text"
            name="other_medical_school"
            value={profileData?.other_medical_school}
            onChange={otherTextInput}
            label={"Other School*"}
            placeholder={"Please enter school details"}
            required={ApplicationConstant.FALSE}
            autoFocus={profileData?.other_medical_school ? false : true}
          />
        )}
        <SelectElement
          classNm={'col-sm-12'}
          name={'certification'}
          isMulti={ApplicationConstant.TRUE}
          onChange={(e) => {
            onMultiSelectInput('doctor_certifications', e);
          }}
          options={options?.certifications}
          className="reactSelect"
          classNamePrefix="react-select"
          value={profileData?.doctor_certifications?.length > ApplicationConstant.ZERO ? profileData?.doctor_certifications : ApplicationConstant.NO_VALUE_STRING}
          isSearchable={ApplicationConstant.TRUE}
          stayMenuOnSelect={ApplicationConstant.TRUE}
          placeholder={'Please select certificate'}
          label={'Degrees/Credentials*'}
        />
         {Array.isArray(profileData?.doctor_certifications) &&
          profileData?.doctor_certifications?.findIndex(
            (item) =>
              item?.name?.toLowerCase() === ApplicationConstant.OTHER_LOWER_CASE
          ) > -1 && (
            <InputElement
              className="col-sm-12"
              type="text"
              name="other_certification"
              value={profileData?.other_certification}
              onChange={otherTextInput}
              label={"Other Doctor certification*"}
              placeholder={"Please enter certification details"}
              required={ApplicationConstant.FALSE}
              autoFocus={profileData?.other_certification ? false : true}
            />
          )}
        <SelectElement
          classNm={'col-sm-12'}
          name={'specializations'}
          isMulti={ApplicationConstant.TRUE}
          onChange={(e) => {
            onMultiSelectInput('doctor_specializations', e);
          }}
          options={options?.specializations}
          className="reactSelect"
          classNamePrefix="react-select"
          value={profileData?.doctor_specializations?.length > ApplicationConstant.ZERO ? profileData?.doctor_specializations : ApplicationConstant.NO_VALUE_STRING}
          isSearchable={ApplicationConstant.TRUE}
          stayMenuOnSelect={ApplicationConstant.TRUE}
          placeholder={'Please select specialization'}
          label={'Specialty'}
        />
        <InputElement
          className="col-sm-12"
          type="text"
          name="Year of Experience"
          value={profileData?.year_of_experience?.value}
          onChange={experienceInput}
          label={'Years of Experience'}
          placeholder={'Please enter years of experience'}
          required={ApplicationConstant.FALSE}
          isValid={profileData?.year_of_experience?.valid}
          msg={profileData?.year_of_experience?.msg}
        />
        <SelectElement
          classNm={'col-sm-12 d-none'}
          name={'Practice'}
          isMulti={ApplicationConstant.TRUE}
          onChange={(e) => {
            onMultiSelectInput('practice', e);
          }}
          options={options?.practice}
          className="reactSelect"
          classNamePrefix="react-select"
          value={profileData?.practice?.length > ApplicationConstant.ZERO ? profileData?.practice : ApplicationConstant.NO_VALUE_STRING}
          isSearchable={ApplicationConstant.TRUE}
          stayMenuOnSelect={ApplicationConstant.TRUE}
          placeholder={'Please select practice'}
          label={'Practice'}
        />
        <MultiSelectElement
          selectOptions={options?.procedure}
          selectValue={profileData?.procedure?.length > ApplicationConstant.ZERO ? profileData?.procedure : ApplicationConstant.NO_VALUE_STRING}
          name={'Procedure'}
          placeholder="Please select procedure"
          label="Procedure(s) Performed"
          spacing={'d-none'}
          onChange={(e) => {
            onMultiSelectInput('procedure', e);
          }}
        />
        <MultiSelectElement
          selectOptions={options?.condition_treated}
          selectValue={profileData?.condition_treated?.length > ApplicationConstant.ZERO ? profileData?.condition_treated : ApplicationConstant.NO_VALUE_STRING}
          name={'Condition Treated'}
          placeholder="Please select condition treated"
          label="Condition(s) Treated"
          spacing={'d-none'}
          onChange={(e) => {
            onMultiSelectInput('condition_treated', e);
          }}
        />
      </div>
    </>
  );
}

export default DoctorBackgroundInformation;
