import React from 'react';
import { Image } from '../../../../common/image/Image';
import { convertUtcToLocalTime } from '../../../../../utils/TimezoneUtils';
import { getSpecialzationName } from '../../../../../utils/helper';

function PatientCanceledAppointmentItem(props) {
  const { item } = props;
  return (
    <div className='user-info'>
      <div className='profile-img'>
        <Image src={item?.user?.profile_image || ''} alt='Profile image' />
      </div>
      <div className='head'>
        <div className='title-text'>
          <h1 className='font-500 font-16'>{item?.user?.first_name}&nbsp;{item?.user?.last_name}</h1>
          <h2>
          {getSpecialzationName(item?.doctor_specializations,item?.specialization_id)}
          </h2>
          <div className='datetime'>
            <span className='material-icons'>event</span>
            <p>
                {convertUtcToLocalTime(item?.start_date_time, 'll')}{' | '}
                {convertUtcToLocalTime(item?.start_date_time, 'hh:mm A')}{' - '}
                {convertUtcToLocalTime(item?.end_date_time, 'hh:mm A')}
              </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PatientCanceledAppointmentItem;
