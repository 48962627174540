import React from 'react';
import { Button } from 'reactstrap';

function DoctorFooter({handleSave, handleNext, handlePrev,
    saveBtnVisible, prevBtnVisible,
    enableSave, enableNext}) {
    return (
        <>
        <footer>
                <div className="container">
                    <div className="footer-btn">
                        <div className="prev">
                            {prevBtnVisible && <Button color="secondary" onClick={handlePrev}>{'Previous'}</Button>}
                        </div>
                        <div className="next">
                            {saveBtnVisible &&<Button color="primary" disabled={!enableSave} onClick={handleSave}>{'Save'}</Button>}
                            {!saveBtnVisible && <Button color="primary" disabled={!enableNext} onClick={handleNext}>{'Next'}</Button>}
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )

}

export default DoctorFooter;
