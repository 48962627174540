import React, { useState } from 'react';
import {  FormGroup, Label, Progress } from 'reactstrap';
import axiosInstance from '../../../axios/axiosInstance';
import ApiConstant from '../../../constants/ApiConstant';
import { BasePathConstant } from '../../../constants/application.constant';
import { getFilenameFromUrl } from '../../../utils/helper';
import { validateType, validateSize } from './ReactFileUploaderUtils';
import ImageCropper from '../../common/modal/ImageCropper';
import ReactImageUploader from './ReactImageUploader';


function ReactFileUploader({
  onSuccess,
  allowedExtensions,
  allowedSize,
  allowedTypeMsg,
  dataLabel,
  profileMode,
  url=ApiConstant.UPLOAD_DOCUMENT,
  formFieldName = 'document',
  progressBar = true,
  ...props
}) {
  const { id:fileId = 'exampleFile' } = props;
  const [file, setFile] = useState({});
  const [progressPercent, setProgressPercent] = useState(0);
  const [responseUrl, setResponseUrl] = useState(null);
  const [loader, setLoader] = useState(false);
  const [modal, setModal] = useState(false);
  const [validation, setValidation] = useState({ value: [], valid: true });
  const [imagePath, setImagePath] = useState(null)
  const [selectImageUrl, setselectImageUrl] = useState(null)
  const toggle = () => {
    setModal(!modal)
  };

  let fileInput = null;

  const onUploadProgress = (event) => {
    const percent = Math.round((event.loaded * 100) / event.total);
    setProgressPercent(percent);
  };

  const removeFile = (e) => {
    e?.preventDefault();
    setFile(null);
    if (!!fileInput) {
      fileInput.value = '';
    }
    props.onRemove && props.onRemove()
    setResponseUrl('');
  };

  const onUploadFile = (fileToUpload) => {
    if (fileToUpload) {
      const formData = new FormData();
      const config = { headers: { 'Content-Type': 'multipart/form-data' }, onUploadProgress: onUploadProgress };
      setLoader(true);
      formData.append(formFieldName, fileToUpload, fileToUpload.name);
      axiosInstance
        .post(`${url}?base_path=${props.basePath}`, formData, config)
        .then(function (response) {
          response.data.data.url && setResponseUrl(response.data.data.url);
          onSuccess && onSuccess(response.data.data.url, response?.data?.data?.name);
        })
        .catch(function (response) {
          setValidation({ valid: false, value: [response] });
          setResponseUrl('');
          removeFile();
        })
        .finally(() => {
          setLoader(false);
          setProgressPercent(0);
        });
    }
  };

  const selectFile = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (!!file) {
      const type = validateType(file.type, allowedExtensions, allowedTypeMsg);
      const size = validateSize(file.size, allowedSize);
      if (type.valid && size.valid) {
        setFile(file);
        setValidation({ valid: true, value: ['success'] });
        onUploadFile(file);
      } else {
        setValidation({ valid: false, value: [type.value, size.value] });
        if (!!fileInput) {
          fileInput.value = '';
        }
      }
    }
  };

  const openImageWindow = (e) => {
    e.preventDefault();
    const imageFile = e.target.files[0];
    if (imageFile) {
      setselectImageUrl(URL.createObjectURL(imageFile));
      setImagePath(imageFile);
      toggle();
    }
  }

  const setImageUrl = (fileObj) => {
    onUploadFile(fileObj);
  }

  return (
    <div className="col-sm-12">
      {!profileMode ? (
      <FormGroup>
        <div className="custom-file-link">
          <Label for={fileId} data-label={dataLabel}>
            {!!props.value && <>
                <span className="material-icons">attachment</span> <span>{getFilenameFromUrl(props.value)}</span>
                <span class="material-icons md-18 red-text-color2 ml-8"  onClick={removeFile}>close</span>
              </>}
            {!props.value && (responseUrl ? (
              <>
                <span className="material-icons">attachment</span> <span>{file.name}</span>
                <span class="material-icons md-18 red-text-color2 ml-8"  onClick={removeFile}>close</span>
              </>
            ):(
              <>
                <span className="material-icons">file_upload</span> <span>{'UPLOAD DOCUMENT (Professional License*)'}</span>{' '}
              </>
            ))}
            {!validation.valid && (
              <span className="ml-15 red-text-color2">{validation.value.map((e, i)=> <p key={i}>{e}</p>)}</span>
            )}
            {progressBar && loader && (
              <span className="col-sm-6">
                <Progress color="warning" value={progressPercent}>{`${progressPercent}%`}</Progress>
              </span>
            )}
          </Label>
            {props.basePath !== BasePathConstant.DOCTOR_LICENSE || !responseUrl ? (
              <input
                ref={(input) => {
                  fileInput = input;
                }}
                type="file"
                name="file"
                class={"exampleFile"}
                id={fileId}
                onChange={selectFile}
              />
            ):('')}
        </div>
      </FormGroup>
      ):(
      <div className="image-upload-container" hidden={!profileMode}>
        <Label className="open-image-cropper">
          <input
            type="file"
            onChange={openImageWindow}
          />
          {dataLabel}
        </Label>
        <ReactImageUploader
          value={props?.value}
          responseUrl={responseUrl}
          progressPercent={progressPercent}
          progressBar={progressBar}
          removeFile={removeFile}
          loader={loader}
        />
        {modal &&
          <ImageCropper
            setModelOpen={toggle}
            isOpen={modal}
            modalClassName="cropModal"
            className={'modal-cropp-dialog'}
            mediaPath={imagePath}
            imageUrl={selectImageUrl}
            cropperImageUrl={setImageUrl}
            allowedExtensions={allowedExtensions}
            allowedSize={allowedSize}
            allowedTypeMsg={allowedTypeMsg}
          />
        }
      </div>
      )}
    </div>
  );
}

export default ReactFileUploader;
