import { TableClassNameObj } from '../../../../../../../constants/application.constant';

const HistoryListHeader = (props) => {
  const { filter, sortList } = props;
  const tableHeadContent = [
    { title: 'Provider', sortKey: 'provider', class: `${TableClassNameObj.col40} provider-col`, sort: false},
    { title: 'Appointment Date', sortKey: 'appointment_date', class: `${TableClassNameObj.col40}`, sort: true},
    { title: 'Status', sortKey: 'status', class: `${TableClassNameObj.col20} pr-0`, sort: true},
  ];

  const doctorListactiveSortIcon = (sortKey, sortValue) => {
    const { sortBy, order } = filter;
    if (sortKey === sortBy && sortValue === order) {
      return 'active';
    } else {
      return '';
    }
  };

  return (
      <div className="headRow patient-history-head-row">
        {tableHeadContent.map((item, index) => {
            return (
                <div key={index} className={`table-col ${item.class}`}>
                    <strong>{item.title}</strong>
                    {item.sort &&
                      <div className="sorting-icon">
                          <span
                            className={`material-icons-outlined ${doctorListactiveSortIcon(item.sortKey, 'asc')}`}
                            onClick={()=>{sortList(item.sortKey, 'asc');}}>expand_less
                          </span>
                          <span
                            className={`material-icons-outlined ${doctorListactiveSortIcon(item.sortKey, 'desc')}`}
                            onClick={()=>{sortList(item.sortKey, 'desc');}}>expand_more
                          </span>
                      </div>
                    }
                </div>
            );
        })}
    </div>
  )
}

export default HistoryListHeader;
