import {ApplicationConstant} from './../../../../constants/application.constant';
import {getDateIgnoreTimezone, getFormattedDate} from './../../../../utils/helper';

export const intialState = (value) =>  {
    return {
        first_name: {value: value?.user?.first_name || '',},
        last_name: {value: value?.user?.last_name || '',},
        preferred_name: {value: value?.user?.preferred_name || ''},
        date_of_birth: {value: (value?.user?.date_of_birth ? getDateIgnoreTimezone(value.user.date_of_birth) : ''), valid: true},
        phone: {value: value?.user?.phone || ''},
        profile_image: {value: value?.user?.profile_image || ''},
        is_image_deleted: 0,
        is_disply_preferred_name: value?.user?.is_disply_preferred_name,
        location: {
          address1: {value: value?.location?.address1 || ''},
          address2: {value: value?.location?.address2 || ''},
          state: { id: value?.location?.state_id, label: value?.location?.state_name,},
          city: { id: value?.location?.city_id, label: value?.location?.city_name},
          zip_code: {value: value?.location?.zip_code || '', valid: true},
        },
    }
};

export const checkValidation = (data, initialData) => {
    const isValidFirstName = data?.first_name?.value;
    const isValidLastName = data?.last_name?.value;
    const isValidDateOfBirth = data?.date_of_birth?.valid;
    const isValidPhone = isValidPhoneNumber(data);
    const isAddressValid = data?.location?.address1.value;
    const isStateValid = data?.location?.state?.id;
    const isCityValid = data?.location?.city?.id;
    const isZipValid = data?.location?.zip_code.valid;
    const isPreferredNameReq = ((data?.is_disply_preferred_name === ApplicationConstant.ONE && data?.preferred_name?.value) ||
    data?.is_disply_preferred_name === ApplicationConstant.ZERO);
    if (isValidFirstName && isValidLastName && isValidDateOfBirth &&
        isValidPhone && isAddressValid && isStateValid && isCityValid &&
        isZipValid && isPreferredNameReq) {
      return true;
    }
    return false;
};

const accountInfoKeys = [
  {name:'first_name', location:false, val:'value'},
  {name:'last_name', location:false, val:'value'},
  {name:'date_of_birth', location:false, val:'value'},
  {name:'preferred_name', location:false, val:'value'},
  {name:'phone', location:false, val:'value'},
  {name:'profile_image', location:false, val:'value'},
  {name:'address1', location:true, val:'value'},
  {name:'address2', location:true, val:'value'},
  {name:'zip_code', location:true, val:'value'},
  {name:'state', location:true, val:'label'},
  {name:'city', location:true, val:'label'},
]

export const isValidPhoneNumber = (data) => {
  const phoneValue = data?.phone?.value || '';
  if (phoneValue && phoneValue.length === ApplicationConstant.TEN) {
    return true;
  }
  return false;
};

export const updateAccountInfoPayload = (payload) => {
    const dateOfBirthFormat = getFormattedDate(payload?.date_of_birth?.value)
    return {
      first_name: payload?.first_name?.value,
      last_name: payload?.last_name?.value,
      preferred_name: checkPreferredName(payload),
      date_of_birth: dateOfBirthFormat,
      phone: payload?.phone?.value,
      profile_image: payload?.profile_image?.value,
      is_image_deleted: payload?.profile_image?.value ? ApplicationConstant.ZERO : ApplicationConstant.ONE,
      is_disply_preferred_name: payload?.is_disply_preferred_name,
      location: {
      address1: payload?.location?.address1.value,
      address2: payload?.location?.address2.value,
      state_id: payload?.location?.state.id.toString(),
      city_id: payload?.location?.city.id.toString(),
      zip_code: payload?.location?.zip_code.value,
      }
    }
}
const checkPreferredName = (payload) => {
  if(payload?.preferred_name?.value) {
    return payload?.preferred_name?.value
  } else {return}
}
