import React, { useContext, useState, useEffect } from 'react';
import HospitalSignUpContext from '../../../contexts/hospital-signup/HospitalSignUpContext';
import HospitalThanksPage from './HospitalThanksPage';
import HospitalAccountCreation from './HospitalAccountCreation';
import PageLogo from '../../common/logo/PageLogo';
import SpinnerComponent from '../../common/spinner/SpinnerComponent';
import SignUpFooter from '../../common/footer/SignUpFooter';
import { ApplicationConstant, NPITypes } from '../../../constants/application.constant';
import LoginFooter from '../../common/footer/LoginFooter';
import update from 'immutability-helper';
import ApiConstant from '../../../constants/ApiConstant';
import { getAPIData } from '../../../utils/doctorDetailsApi';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axiosInstance from '../../../axios/axiosInstance';
import GoBack from '../../common/back/GoBack';
import { getFormattedDate } from '../../../utils/helper';


let LABEL_INFO = {
  npi_type: NPITypes
};

const THANK_PAGE = ApplicationConstant.TWO;


function HospitalSignUp() {
  const [hospitalSignUp, setHospitalSignUp] = useContext(HospitalSignUpContext);
  const [isLoading, setLoader] = useState(ApplicationConstant.FALSE);
  const [labelInfo, setLabelInfo] = useState(LABEL_INFO);

  const getNext = (step) => {
    return step + ApplicationConstant.ONE;
  };

  const handleNext = () => {
    setHospitalSignUp(
      update(hospitalSignUp, {
        hospital_details: { current_step: { $apply: getNext } },
      })
    );
  };

  const handleSave = () => {
    let specializations = [];
    let dob = getFormattedDate(hospitalSignUp.hospital_details.date_of_business.value)
    setLoader(true);
    if(hospitalSignUp.hospital_details.doctor_specializations.length>ApplicationConstant.ZERO){
      specializations = hospitalSignUp.hospital_details.doctor_specializations.map((o) => o.id);
    }
    const oPayload = {
      first_name: hospitalSignUp.hospital_details.first_name.value,
      user_name: hospitalSignUp.hospital_details.user_name.value,
      tax_id: hospitalSignUp.hospital_details.tax_id.value,
      date_of_birth:dob,
      email: hospitalSignUp.hospital_details.email.value,
      phone: hospitalSignUp.hospital_details.phone.value,
      password: hospitalSignUp.hospital_details.password.value,
      address1: hospitalSignUp.hospital_details.address1,
      address2: hospitalSignUp.hospital_details.address2,
      state_id: hospitalSignUp.hospital_details.state.id,
      city_id: hospitalSignUp.hospital_details.city.id,
      zip_code: hospitalSignUp.hospital_details.zip_code.value,
      npi_number: hospitalSignUp.hospital_details.npi_number.value,
      npi_type: hospitalSignUp.hospital_details.npi_type.id,
      malpractice_insurance:
        hospitalSignUp.hospital_details.malpractice_insurance,
      hipaa_compliance: hospitalSignUp.hospital_details.hipaa_compliance,
      authorized_official_name: hospitalSignUp.hospital_details.authorized_official_name.value,
      doctor_specializations: specializations
    };

    axiosInstance.post(ApiConstant.HOSPITAL_SIGNUP, oPayload).then(
      (res) => {
        setTimeout(() => {
          setLoader(false);
        }, 500);
        handleNext();
      },
      (err) => {
        setTimeout(() => {
          setHospitalSignUp(
            update(hospitalSignUp, {
              hospital_details: {
                current_step: { $set: ApplicationConstant.ONE },
              },
            })
          );
          setLoader(false);
          toast.error(err || '');
        }, 500);
      }
    );
  };
  const getValidity = () => {
    const oStateValidity = { isValid: ApplicationConstant.FALSE };
    const oUser = hospitalSignUp.hospital_details;
    if (oUser) {
      oStateValidity.isValid = !!(
        oUser.first_name.value && oUser.first_name.valid &&
        oUser.user_name.value && oUser.user_name.valid &&
        oUser.tax_id.value && oUser.tax_id.valid &&
        oUser.date_of_business.valid && oUser.phone.value &&
        oUser.phone.valid && oUser.email.value &&
        oUser.email.valid && oUser.password.value &&
        oUser.password.valid &&
        oUser.password.value === oUser.confirm_password.value &&
        oUser.confirm_password && oUser.npi_number.valid &&
        oUser.npi_number.value &&
        oUser.malpractice_insurance && oUser.hipaa_compliance &&
        oUser.authorized_official_name.value && oUser.authorized_official_name.valid &&
        oUser.address1 && oUser.state && oUser.city &&
        oUser.zip_code.value && oUser.zip_code.valid
      );
    }
    return oStateValidity;
  };

  const setBackgroundLabel = () => {
    getAPIData(ApiConstant.SPECIALIZATIONS)
      .then((res) => {
        if (Object.entries(res).length > 0) {
            LABEL_INFO["specializations"] = res.map((item) => ({
              ...item,
              label: item.name,
              value: item.name.toLowerCase(),
            }));
          setLabelInfo({ ...labelInfo, ...LABEL_INFO });
        }
      })
      .catch((err) => {
        LABEL_INFO = {};
      });
  };

  useEffect(() => {
    setBackgroundLabel();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getComponent = (step) => {
    switch (step) {
      case 1:
        return <HospitalAccountCreation oLabelInfo={LABEL_INFO} />;

      case 2:
        return <HospitalThanksPage />;

      default:
        return <div>{'There was a problem !!'}</div>;
    }
  };

  return (
    <div className='step-page'>
      <div className='middle'>
        <div className='container'>
          <div className="back-login">
            {(hospitalSignUp.hospital_details.current_step !== THANK_PAGE ) && <GoBack/>}
          </div>
          <PageLogo />
          {isLoading ? (
            <>
              <div className='head-title mb-50'>
                <div className='heading'>
                  <SpinnerComponent />
                </div>
              </div>
            </>
          ) : (
            getComponent(hospitalSignUp.hospital_details.current_step)
          )}
        </div>
      </div>
      {hospitalSignUp.hospital_details.current_step ===
      ApplicationConstant.ONE ? (
        <SignUpFooter
          handleSave={handleSave}
          isEnabled={getValidity().isValid}
        />
      ) : (
        <LoginFooter />
      )}
      <ToastContainer autoClose={8000} hideProgressBar transition={Slide} />
    </div>
  );
}

export default HospitalSignUp;
