import React, { useState } from 'react';
import { RouteConstants } from '../../../../constants/application.constant';
import GoBack from '../../../common/back/GoBack';
import CommonSidebar from '../../CommonSidebar';
import { DoctorSideMenuItems } from './DoctorSideMenuItems';

function DoctorProfileSidebar() {
  const [pageHeading, setPageHeading] = useState('');
  const setProfileTitle = (title) => {
    setPageHeading(title);
  };

  return (
    <div className="profile-sidebar">
      <div className="ml-0">
        <GoBack heading={pageHeading} targetPath={RouteConstants.DASHBOARD_DOCTOR} />
      </div>
      <CommonSidebar sideMenuItems={DoctorSideMenuItems} setPageTitle={setProfileTitle} />
    </div>
  );
}

export default DoctorProfileSidebar;
