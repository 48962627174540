import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../../../../axios/axiosInstance';
import ApiConstant from '../../../../../../constants/ApiConstant';
import { ApplicationConstant } from '../../../../../../constants/application.constant';
import FormButton from '../../../../../common/buttons/FormButton';
import InputElement from '../../../../../common/formElements/InputElement';
import SpinnerComponent from '../../../../../common/spinner/SpinnerComponent';
import { ToastContainer, toast, Slide } from 'react-toastify';
import { convertUtcToLocalTime } from '../../../../../../utils/TimezoneUtils';
import { downloadFileFromBlob } from '../../../../../../utils/helper';

function DoctorNotes(props) {
  const { oMeeting } = props;
  const [privateNotes, setPrivateNotes] = useState('');
  const [patientNotes, setPatientNotes] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [updatedAt, setUpdatedAt] = useState(null);
  const [notesObj, setNotesObj] = useState(null);
  const [isNotesAvaliable, setNotesAvaliable] = useState(false);

  useEffect(() => {
    fetchAppointmentNotes();
  }, []);

  const handlePrivateNotes = (e) => {
    setPrivateNotes(e.target.value);
  };

  const handlePatientNotes = (e) => {
    setPatientNotes(e.target.value);
  };

  function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    const oPayload = {
      appointment_id: oMeeting.appointment_id,
      notes: patientNotes,
      private_notes: privateNotes,
    };
    axiosInstance.post(ApiConstant.SAVE_APPOINTMENT_NOTE, oPayload).then(
      (res) => {
        setLoading(false);
        toast.success(res?.data?.message);
        fetchAppointmentNotes();
      },
      (error) => {
        setLoading(false);
        toast.error(error || '');
      }
    );
  }

  const fetchAppointmentNotes = async () => {
    setLoading(true);
    await axiosInstance
      .get(`${ApiConstant.DOCTOR_APPOINTMENTS_NOTES}`, { params: { patient_id: oMeeting.user.id } })
      .then((res) => {
        if (res && res.data && res.data.data) {
          const oNotes = res.data.data;
          setNotesFromObject(oNotes);
        }
        setLoading(false);
      })
      .catch((error) => {
        toast.error(error || '');
        setLoading(false);
      });
  };

  const fetchDownloadFiles = async (id) => {
    await axiosInstance
      .get(`${ApiConstant.DOCTOR_DOWNLOAD_NOTES}`, {responseType : 'blob',
        headers: {'Content-Type': 'application/pdf'},
        params: { appointment_id: oMeeting.appointment_id, is_private: id },
      })
      .then((response) => response.data)
      .then((blob)=>{
        const fileName = id === 1 ? "privateNotes.pdf" : "notes.pdf";
        downloadFileFromBlob(blob, fileName);
      })
      .catch((error) => {
        toast.error(error || '');
      });
  };

  function setNotesFromObject(oNotes) {
    if (oMeeting?.appointment_id === oNotes?.appointment_id) {
      setNotesObj(oNotes);
      setNotesAvaliable(true);
      if (oNotes?.updated_at) {
        setUpdatedAt(oNotes.updated_at);
      }
      if (oNotes?.private_notes) {
        setPrivateNotes(oNotes.private_notes);
      }
      if (oNotes?.notes) {
        setPatientNotes(oNotes.notes);
      }
    } else {
      setNotesAvaliable(false);
    }
  }

  return (
    <>
      {isLoading && <SpinnerComponent />}
      {
        <div className="body">
          <div className="col-sm-12 p-0 d-flex justify-content-between position-relative">
            <h4>Visible only to Provider</h4>
            {isNotesAvaliable && notesObj.private_notes && (
              <span
                className="material-icons theme-color cursor-pointer"
                onClick={() => {
                  fetchDownloadFiles(1);
                }}
              >
                file_download
              </span>
            )}
          </div>
          <InputElement
            className="col-sm-12 p-0"
            type="textarea"
            height="100"
            value={privateNotes}
            onChange={handlePrivateNotes}
            placeholder={'Please enter'}
            label={'Your Note'}
          />
          <div className="last-updated-box">
            {isNotesAvaliable && notesObj.private_notes && (
              <span className="last-update">
                {`Last updated: ${convertUtcToLocalTime(updatedAt, 'L | LT')}`}
              </span>
            )}
          </div>
          <div className="col-sm-12 p-0 d-flex justify-content-between position-relative mt-24">
            <h4>Visible to both Provider and Patient</h4>
            {isNotesAvaliable && notesObj.notes && (
              <span
                className="material-icons theme-color cursor-pointer"
                onClick={() => {
                  fetchDownloadFiles(0);
                }}
              >
                file_download
              </span>
            )}
          </div>
          <InputElement
            className="col-sm-12 p-0"
            type="textarea"
            height="100"
            value={patientNotes}
            onChange={handlePatientNotes}
            placeholder={'Please enter'}
            label={'Your Note'}
          />
          <div className="last-updated-box">
            {isNotesAvaliable && notesObj.notes && (
              <span className="last-update">
                {`Last updated: ${convertUtcToLocalTime(updatedAt, 'L | LT')}`}
              </span>
            )}
          </div>
          <div className="col-sm-12 p-0 action-button justify-content-end ">
            <div className="btn-sec ">
              <FormButton
                buttonLabel="SUBMIT"
                buttonClassName="btn btn-primary"
                handleClick={handleSubmit}
                disabled={!( patientNotes.trim().length > 0 || privateNotes.trim().length > 0 )}
              />
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default DoctorNotes;
