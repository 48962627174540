import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast, Slide } from 'react-toastify';
import {
  PageTitles,
  ApplicationConstant,
  RouteConstants,
} from '../../../../../constants/application.constant';
import axiosInstance from '../../../../../axios/axiosInstance';
import ApiConstant from '../../../../../constants/ApiConstant';
import EmptyStateComponent from '../../../../common/emptyStateComponent/EmptyStateComponent';
import { EMPTY_ICON } from '../../../../../constants/image';
import PatientCompletedAppointmentItem from './PatientCompletedAppointmentItem';
import SpinnerComponent from '../../../../common/spinner/SpinnerComponent';
import useDocumentTitle from '../../../../../utils/DocumentTitle';
import DropdownComponent from '../../../../common/dropdown/DropdownComponent';
import { SortItems } from './SorItems';

function PatientCompletedAppointments() {
  useDocumentTitle(PageTitles.COMPLETED_APPOINTMENT);
  const sorting = SortItems[ApplicationConstant.ZERO].id;
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [serachKey, setsearchKey] = useState(false);
  const [selectedSorting, setSelectedSorting] = useState(sorting);
  const [pageCount, setPageCount] = useState(1);
  const [completedAppointments, setCompletedAppointments] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [listInitialPage, setListInitialPage] = useState(0);


  const fetchCompletedAppointments = async () => {
    setLoading(true);
    await axiosInstance
      .get(
        `${ApiConstant.PATIENT_COMPLETED_APPOINTMENT}?page=${currentPage}&limit=${ApplicationConstant.TEN
        }&sortBy=${selectedSorting}&keyword=${searchKeyword}`
      )
      .then((res) => {
        if (res && res.data) {
          const completedAppointmentList = res.data?.data?.data || [];
          const totalPageCount = res.data?.data?.last_page || '';
          setCompletedAppointments(completedAppointmentList);
          setPageCount(totalPageCount);
        }
      })
      .catch((error) => {
        toast.error(error || '');
      });
    setLoading(false);
  };

  const addFavDoctor = async (id) => {
    const payload = { doctor_id: id };
    await axiosInstance
      .post(`${ApiConstant.ADD_FAV_DOCTOR}`, payload)
      .then((res) => {
        if (res && res.data) {
          toast.success(res?.data?.message);
        }
      })
      .catch((error) => {
        toast.error(error || '');
      });
  };

  const removeFavDoctor = async (id) => {
    const oPayload = { doctor_id: id };
    await axiosInstance
      .post(`${ApiConstant.DELETE_FAV_DOCTOR}`, oPayload)
      .then((res) => {
        if (res && res.data) {
          toast.success(res?.data?.message);
        }
      })
      .catch((error) => {
        toast.error(error || '');
      });
  };

  const onFavBtnClick = (isFav, id) => {
    if (isFav) {
      removeFavDoctor(id);
    } else {
      addFavDoctor(id);
    }
  };

  const onSelectionChange = (value) => {
    setSelectedSorting(value);
    setCurrentPage(ApplicationConstant.ONE);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setsearchKey(searchKeyword);
    setListInitialPage(ApplicationConstant.ZERO);
    setCurrentPage(ApplicationConstant.ONE);
  };

  const clearSearch = () => {
    setsearchKey('');
    setSearchKeyword('')
  };

  const onScheduleAppointment = (input, doctorData) => {
    history.push({
      pathname: RouteConstants.SUBSCRIPTION_PLAN,
      state: { msg: input, patientProfile: {}, doctorProfile: {user:{id:doctorData.user_id}} },
    });
  };

  const changePagination = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage + ApplicationConstant.ONE);
    setListInitialPage(selectedPage);
  };

  useEffect(() => {
    fetchCompletedAppointments();
  }, [currentPage, selectedSorting, serachKey]);

  return (
    <>
      <div className='middle-content edit-profile user-right-container'>
        <div className='pages'>
          <div className='profile-information'>
            <div className='form-step max-w-100'>
              <div className='history-and-records doctor-appointment-list'>
                <div className='profile-info'>
                  <form className='w-100 d-flex align-items-center completed-search' onSubmit={handleSearchSubmit }>
                    <div className='search-form list-search flex-1 mr-15'>
                      <input
                        placeholder='Search a provider....'
                        className='search-checkbox mb-0'
                        type='text'
                        value={searchKeyword}
                        onChange={(e) => setSearchKeyword(e.target.value)}
                      />
                      <span className='material-icons'>search</span>
                      {searchKeyword && (
                        <span onClick={clearSearch} className="material-icons close">
                          close
                        </span>
                      )}
                    </div>
                    <div className='dropdown-btn'>
                      <DropdownComponent
                        placeholder={''}
                        showFirstItem={true}
                        items={SortItems}
                        onValueSelected={onSelectionChange}
                      />
                    </div>
                  </form>
                  <div className='box'>
                    {isLoading ? (
                      <SpinnerComponent />
                    ) : (
                      <>
                        {completedAppointments?.length ? (
                          completedAppointments.map((item, i) => {
                            return (
                              <PatientCompletedAppointmentItem
                                item={item}
                                index={i}
                                key={i}
                                id={item?.user?.id}
                                doctorData={item}
                                onFavBtnClick={onFavBtnClick}
                                isViewAllClicked={ApplicationConstant.TRUE}
                                onScheduleAppointment={onScheduleAppointment}
                                oAppointment={item}
                              />
                            );
                          })
                        ) : (
                          <>
                            {!isLoading && (
                              <EmptyStateComponent
                                imageSrc={EMPTY_ICON}
                                title='No Completed Appointment Found.'
                              />
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                  <div
                    className='pagination-wrapper'
                    hidden={pageCount === ApplicationConstant.ONE}
                  >
                    <ReactPaginate
                      previousLabel={'previous'}
                      nextLabel={'next'}
                      breakLabel={'...'}
                      breakClassName={'break-me'}
                      pageCount={pageCount}
                      marginPagesDisplayed={4}
                      onPageChange={changePagination}
                      pageRangeDisplayed={1}
                      forcePage={listInitialPage}
                      containerClassName={'pagination'}
                      activeClassName={'active'}
                      nextLinkClassName={'next-btn'}
                      previousLinkClassName={'prev-btn'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={4000} hideProgressBar transition={Slide} />
    </>
  );
}

export default PatientCompletedAppointments;
