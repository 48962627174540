import React from 'react';

function StarRating(props) {
  const {
    count = 5,
    value,
    readOnly = true,
    inactiveColor = '#777',
    size = 17,
    activeColor = '#FB7304',
    showValue = false,
    onChange,
  } = props;
  const stars = Array.from({ length: count }, () => 'star');

  const handleChange = (value) => {
    if (readOnly) {
      return;
    }
    onChange(value + 1);
  };

  return (
    <div className="d-flex align-items-center">
      {stars.map((s, index) => {
        let style = inactiveColor;
        if (index < value) {
          style = activeColor;
        }
        return (
          <span
            className={'material-icons'}
            key={index}
            style={{ color: style, width: size, height: size, fontSize: size }}
            onClick={() => handleChange(index)}
          >
            {s}
          </span>
        );
      })}
      {showValue ? <span className="text-value">({value})</span> : <></>}
    </div>
  );
}

export default StarRating;
