export const ApplicationConstant = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  SEVEN: 7,
  EIGHT: 8,
  NINE:9,
  TEN: 10,
  ELEVEN: 11,
  TWELVE: 12,
  EIGHTEEN: 18,
  FORTY: 40,
  NINE_HUNDRED_NINETY_NINE:999,
  HUNDRED:100,
  TRUE: true,
  FALSE: false,
  NO_VALUE_STRING: '',
  DECIMAL_REGEX: /^\d*(\.\d{0,2})?$/,
  OTHER: 'Other',
  OTHER_LOWER_CASE: 'other',
  NONE: 'none',
  OFF: 'off',
  ON: 'on',
  SUCCESS_MSG: 'success',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  YES_SHORT: 'Y',
  NO_SHORT: 'N',
  FIRST_PREFERENCE: 'First Preference:',
  SECOND_PREFERENCE: 'Second Preference:',
  FIRST_PREF: 'First Preference',
  SECOND_PREF: 'Second Preference',
  PREFER_NOT_TO_DISCLOSE: 'no preference',
  DEFAULT_PASSWORD: 'Test@',
  PLEASE_ENTER: 'Please Enter',
  TELEHEALTH:'Telehealth',
  PDF_FILES:'pdf',
  XLSX_FILES:'xlsx'
};

export const RegExConstant = {
  FIRST_SPACE_REGEX: /^$|^[A-Za-z].*$/,
  DECIMAL_REGEX: /^\d*(\.\d{0,2})?$/,
  EMAIL_REGEX: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  NAME_REGEX: /^[a-zA-Z]+ [a-zA-Z]+$/,
  NAME_REGEX_V1: /^$|^[A-Za-z]+$/,
  PASSWORD_REGEX: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$/,
  PHONE_REGEX_V1: /^[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}$/,
  EMAIL_REG:
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  PHONE_REGEX: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/,
  TAX_NUMBER: /^[0-9\s]*$/,
  TAX_ID: /^[0-9-]+$/,
  WORDS_REGEX: /^[A-Za-z][A-Za-z ]*$/,
  TWO_DIGIT_FLOAT:/^\d{0,2}(\.\d{1})?$/,
  REMOVE_SPECIAL_CHAR:/^[!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]*$/,
  DETECT_SPECIAL_CHAR:/[!@#$%^&*()_+=\[\]{};':"\\|,.<>\/?]+/,
  DECIMAL_AT_END:/^[\d]?[\d]\.$/,
  DETECT_DIGIT: /\d+/
};

export const PageTitles = {
  LOGIN: 'Login',
  FORGOT_PASSWORD: 'Forgot Password',
  RESET_PASSWORD: 'Reset Password',
  PATIENT_REGISTRATION: 'Patient Registration',
  PATIENT_SUMMARY: 'Patient Summary',
  DOCTOR_REGISTRATION: 'Provider Registration',
  DOCTOR_SUMMARY: 'Provider Summary',
  SELECT_TYPE: 'Who are you?',
  PATIENT_DASHBOARD: 'Patient Dashboard',
  DOCTOR_DASHBOARD: 'Provider Dashboard',
  HOSPITAL_DASHBOARD: 'Hospital Dashboard',
  PAGE_NOT_FOUND: 'Page Not Found',
  PATIENT_SIGN_UP: 'Patient SignUp',
  DOCTOR_SIGN_UP: 'Provider SignUp',
  HOSPITAL_SIGN_UP: 'Hospital SignUp',
  CONTACT_US: 'Contact Us',
  PATIENT_PROFILE: 'Patient Profile',
  HOSPITAL_PROFILE: 'Hospital Profile',
  DOCTOR_PROFILE: 'doctor Profile',
  TERMS_SERVICE: 'Terms Of Service',
  PRIVACY_POLICY: 'Privacy Policy',
  ACCOUNT_INFORMATION: 'Account Information',
  USER_PROFILE: 'User Profile',
  USER_PREFERENCES: 'User Preferences',
  FAVOURITE_DOCTOR_LIST: 'Favorite Providers',
  FAQ: 'FAQs',
  VERIFY_EMAIL: 'Verify Email',
  PRESCRIPTION:'Prescription',
  CHANGE_PASSWORD: 'Change Password',
  CSV_UPLOAD: 'Upload CSV',
  ADD_DOCTOR: 'Add Provider',
  FIND_DOCTOR: 'Find A Provider',
  PATIENT_VIEW_DOCTOR_PROFILE:'Patient view doctor profile',
  BACKGROUND_INFO: 'Background Information',
  MY_MATCHED_DOCTORS: 'My Matched Providers',
  PENDING_APPOINTMENT: 'Pending Appointments',
  UPCOMING_APPOINTMENT: 'Upcoming Appointments',
  CANCELED_APPOINTMENT: 'Canceled Appointments',
  COMPLETED_APPOINTMENT: 'Completed Appointments',
  SCHEDULE_AVAILABILITY: 'Schedule Availability',
  CANCEL_CONFIRMATION: 'Cancel Confirmation',
  SCHEDULE_APPOINTMENT: 'Schedule Appointment',
  TELEHEALTH_VIDEO: 'Telehealth Video',
  MEMBERSHIP_INFORMATION:'Membership Information',
  DOCTOR_PAYMENT_HISTORY:'Payment History',
  PATIENT_PAYMENT_HISTORY:'Payment History',
  PAYMENT_ERROR_PAGE:'Payment Error Page',
  OUR_PROVIDER:'Our Provider'
};

export const ModalConstants = {
  SIGN_OUT_TITLE: 'Logout',
  SIGN_OUT_MESSAGE: 'Are you sure you want to logout?',
  SIGN_OUT: 'LOGOUT',
  CANCEL: 'CANCEL',
  EMAIL_VERIFIED_TITLE: 'Email Not Verified',
  EMAIL_VERIFIED_MESSAGE: 'Click on the "Resend Email" button to receive the verification email.',
  RESEND_EMAIL: 'RESEND EMAIL',
  SCHEDULER_TITLE:'Tell us what you want/need from your appointment today.',
  SAVE: 'SAVE',
  VISIT_REASON: 'Reason for your visit today?*',
  MEMBERSHIP_TITLE:'Upgrade now!',
  MEMBERSHIP_OFFER:`Here's what you can save`,
  GO_MEMBERSHIP: 'GO TO MEMBERSHIP',
  NO_THANKS:'NO THANKS',
  DELETE_DOCUMENT_TITLE: 'Delete',
  DELETE_DOCUMENT_MESSAGE: 'Are you sure you want to delete this document?',
  YES: 'Yes',
  No: 'No',
};

export const RouteConstants = {
  LOGIN: '/',
  FORGET_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  SIGNUP: '/sign-up',
  SIGNUP_PATIENT: '/sign-up/patient',
  SIGNUP_DOCTOR: '/sign-up/doctor',
  SIGNUP_HOSPITAL: '/sign-up/hospital',
  DASHBOARD_PATIENT: '/dashboard/patient',
  DASHBOARD_DOCTOR: '/dashboard/doctor',
  DASHBOARD_HOSPITAL: '/dashboard/hospital',
  REGISTRATION_PATIENT: '/registration/patient',
  REGISTRATION_DOCTOR: '/registration/doctor',
  SUMMARY_PATIENT: '/patient/summary',
  SUMMARY_DOCTOR: '/doctor/summary',
  CONTACT_US: '/contact-us',
  PATIENT_PROFILE: '/patient/profile',
  HOSPITAL_PROFILE: '/hospital/profile',
  DOCTOR_PROFILE: '/doctor/profile',
  TERMS_SERVICE: '/terms-of-service',
  PRIVACY_POLICY: '/privacy-policy',
  HTML: '/html-design',
  FAQ: '/faq',
  PATIENT_ACCOUNT_INFORMATION: '/patient/account-information',
  PATIENT_USER_PREFERENCES: '/patient/user-preferences',
  PATIENT_USER_PROFILE: '/patient/user-profile',
  USER_PROFILE: '/user-profile',
  USER_PREFERENCES: '/user-preferences',
  ACCOUNT_INFORMATION: '/account-information',
  FAVOURITE_DOCTOR_LIST:'/favourite-doctor-list',
  VERIFY_EMAIL: '/verify-email',
  DOCTOR_ACCOUNT_INFORMATION: '/doctor/account-information',
  DOCTOR_PRESCRIPTION: '/doctor/prescription',
  CHANGE_PASSWORD:'/change-password',
  HOSPITAL_ACCOUNT_INFORMATION: '/hospital/account-information',
  DOCTOR_USER_PROFILE: '/doctor/user-profile',
  DOCTOR_BACKGROUND_INFO: '/doctor/background-info',
  BACKGROUND_INFO: '/background-info',
  CSV_UPLOAD:'/csv-upload',
  ADD_DOCTOR:'/add-doctor',
  FIND_DOCTOR:'/patient/find-a-doctor',
  VIEW_ALL_DOCTOR:'/patient/view-all-doctor',
  PATIENT_VIEW_DOCTOR_PROFILE:'/patient-view-doctor-profile/',
  DOCTOR_ID_SLUG: 'doctor_id',
  DOCTOR_PENDING_APPOINTMENT: '/doctor/pending-appointments',
  DOCTOR_UPCOMING_APPOINTMENT: '/doctor/upcoming-appointments',
  DOCTOR_CANCELED_APPOINTMENT: '/doctor/canceled-appointments',
  DOCTOR_COMPLETED_APPOINTMENT: '/doctor/completed-appointments',
  DOCTOR_APPOINTMENT: '/doctor-appointment',
  PATIENT_APPOINTMENT:'/patient-appointment',
  SUBSCRIPTION_PLAN:'/patient-subscription-plan',
  AVAILABLE_DOCTOR:'/patient-available-doctor',
  BOOK_APPOINTMENT:'/patient-book-appointment',
  PATIENT_CANCELED_APPOINTMENT:'/patient-cancel-appointment',
  PATIENT_COMPLETED_APPOINTMENT:'/patient-completed-appointment',
  PATIENT_UPCOMING_APPOINTMENT:'/patient-upcoming-appointment',
  DOCTOR_SCHEDULE_AVAILABILITY: '/doctor/schedule-availability',
  PATIENT_CANCEL_CONFIRMATION: '/patient/cancel-confirmation',
  DOCTOR_CANCEL_CONFIRMATION: '/doctor/cancel-confirmation',
  PATIENT_SESSION: '/patient/session',
  PROVIDER_SESSION_CONTAINER:'/provider-session',
  DOCTOR_SESSION_CONTAINER:'/doctor-session',
  PATIENT_SESSION_CONTAINER:'/patient-session',
  DOCTOR_PAYMENT:'/payment',
  PATIENT_MEMBERSHIP:'/patient-membership',
  DOCTOR_PAYMENT_HISTORY:'/doctor-payment-history',
  PATIENT_PAYMENT_HISTORY:'/patient-payment-history',
  PAYMENT_ERROR_PAGE:'/payment-error-page',
  VIEW_ALL_OUR_DOCTOR:'/view-all-our-doctor'
};

export const authPaths = [
  RouteConstants.LOGIN,
  RouteConstants.SIGNUP_PATIENT,
  RouteConstants.SIGNUP_HOSPITAL,
  RouteConstants.SIGNUP_DOCTOR,
  RouteConstants.REGISTRATION_PATIENT,
  RouteConstants.REGISTRATION_DOCTOR,
]

export const hideHeaderPaths = [
  RouteConstants.SUMMARY_PATIENT,
  RouteConstants.SUMMARY_DOCTOR,
  RouteConstants.RESET_PASSWORD,
  RouteConstants.TERMS_SERVICE,
  RouteConstants.PRIVACY_POLICY,
  RouteConstants.FAQ,
  RouteConstants.CONTACT_US,
  RouteConstants.VERIFY_EMAIL,
]

export const SideBarRoutes = [
  RouteConstants.PATIENT_ACCOUNT_INFORMATION,
  RouteConstants.PATIENT_USER_PREFERENCES,
  RouteConstants.PATIENT_USER_PROFILE,
  RouteConstants.DOCTOR_ACCOUNT_INFORMATION,
  RouteConstants.FAVOURITE_DOCTOR_LIST,
  RouteConstants.DOCTOR_PRESCRIPTION,
  RouteConstants.CHANGE_PASSWORD,
  RouteConstants.HOSPITAL_ACCOUNT_INFORMATION,
  RouteConstants.DOCTOR_USER_PROFILE,
  RouteConstants.DOCTOR_BACKGROUND_INFO,
  RouteConstants.DOCTOR_PAYMENT,
  RouteConstants.PATIENT_MEMBERSHIP,
  RouteConstants.DOCTOR_PAYMENT_HISTORY,
  RouteConstants.PATIENT_PAYMENT_HISTORY
];

export const AppointmentSideBarRoutes = [
  RouteConstants.DOCTOR_PENDING_APPOINTMENT,
  RouteConstants.DOCTOR_UPCOMING_APPOINTMENT,
  RouteConstants.DOCTOR_CANCELED_APPOINTMENT,
  RouteConstants.DOCTOR_COMPLETED_APPOINTMENT,
  RouteConstants.PATIENT_UPCOMING_APPOINTMENT,
  RouteConstants.PATIENT_CANCELED_APPOINTMENT,
  RouteConstants.PATIENT_COMPLETED_APPOINTMENT,
];

export const iconType = {
  FILLED: 'material-icons',
  OUTLINED: 'material-icons-outlined',
  SHARP: 'material-icons-sharp',
};

export const targetedId = {
  PATIENT: 'patient',
  DOCTOR: 'doctor',
  HOSPITAL: 'hospital',
};

export const profileKey = {
  NAME: 'name',
  OTHER_LANGUAGE: 'other_language',
  OTHER_PRONOUN: 'other_pronoun',
  OTHER_PRONOUNS:'other_pronouns',
  OTHER_RACES:'other_races',
  OTHER_RACE:'other_race',
  TITLE: 'title',
  OTHER_SPECIALIZATIONS: 'other_specialization',
  OTHER_CERTIFICATION:'other_certification',
  OTHER_IDENTIFICATION: 'other_identification',
  OTHER_SEXUAL_ORIENTATION: 'other_sexual_orientation',
  STATE: 'state',
  CERTIFICATION: 'certifications',
  SPECIALIZATION: 'specialization',
  FACILITIES: 'facilities',
  CONDITION_TREATEDS: 'condition',
  OTHER_CONDITION_TREATEDS: 'other_condition',
  PROCEDURES: 'procedures',
  OTHER_PROCEDURES: 'other_procedures',
  PRACTICES: 'practices',
  OTHER_PRACTICES: 'other_practices',
  INSURANCE_PROVIDER: 'doctor_insurance_providers',
  OTHER_INSURANCE_PROVIDER: 'other_insurance_provider',
  OTHER_MEDICAL_SCHOOL: 'other_medical_school'
};

export const UserRole = {
  PATIENT: 'patient',
  DOCTOR: 'doctor',
  HOSPITAL: 'hospital',
};

export const HttpStatusCode = {
  UNAUTHORISED: 401,
  SUCCESS_REQUEST: 200,
  SUCCESS_CODE_202: 202,
};

export const profileModeTypes = {
  EDIT: 'edit',
  CREATE: 'create',
}
export const NPITypes = [
  { id: 1, name: 'Individual', label: 'Individual', value: 'Individual' },
  {
    id: 2,
    name: 'Organization(Clinics,Hospitals)',
    label: 'Organization(Clinics,Hospitals)',
    value: 'organization(Clinics,Hospitals)',
  },
];

export const Roles = [
  { id: 1, name: 'Patient', label: 'Patient', value: 'Patient' },
  { id: 2, name: 'Provider', label: 'Provider', value: 'Provider' },
  { id: 3, name: 'Hospital', label: 'Hospital', value: 'Hospital' },
  { id: 4, name: 'New Role', label: 'New Role', value: 'New Role' },
  { id: 5, name: 'Other', label: 'Other', value: 'Other' },
];

export const DateConstant = {
  START_DATE: '1900-01-01',
  END_DATE: '2100-12-31',
}

export const BasePathConstant = {
  DOCTOR_LICENSE: 'doctor/document',
  PATIENT_PROFILE_IMAGE: 'patient/profile_image',
  DOCTOR_PROFILE_IMAGE: 'doctor/profile_image',
  HOSPITAL_PROFILE_IMAGE: 'hospital/profile_image',
  CSV_UPLOAD:'csv_upload'
}

export const ToastMessage = {
  PROFILE_UPDATE: 'Your profile has been updated successfully.',
  FILE_UPLOADED: 'The file has been added successfully, please review the error message if any.',
}

export const StatusType = {
  SUCCESS: 'success',
  ERROR: 'error'
}

export const TableClassNameObj = {
  col4: 'table-column-4',
  col9: 'table-column-9',
  col10: 'table-column-10',
  col11: 'table-column-11',
  col12: 'table-column-12',
  col13: 'table-column-13',
  col14: 'table-column-14',
  col15: 'table-column-15',
  col16: 'table-column-16',
  col18: 'table-column-18',
  col20: 'table-column-20',
  col22: 'table-column-22',
  col24: 'table-column-24',
  col25: 'table-column-25',
  col27: 'table-column-27',
  col30: 'table-column-30',
  col40: 'table-column-40',
}

export const InitialPageLimit = {
  FIND_DOCTOR: ApplicationConstant.TEN,
  PATIENT_DOCUMENT: ApplicationConstant.SIX,
  DOCTOR_DOCUMENT: ApplicationConstant.SIX,
  PATIENT_PAYMENT_HISTORY:ApplicationConstant.TEN,
  PAYMENT_HISTORY: ApplicationConstant.TEN,
  PAYMENT_HISTORY_DETAIL: ApplicationConstant.FIVE,
}

export const SessionDocumentTabTitle = {
  DOCUMENTS: 'Documents',
  NOTES: 'Notes',
  HISTORY: 'History',
  TAKE_NOTES: 'Take Notes',
  SUPERBILL: 'Superbill'
}
