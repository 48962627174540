import React, { useState } from 'react';
import { Image } from '../../../../common/image/Image';
import {
  ApplicationConstant,
  ModalConstants,
  RouteConstants,
} from '../../../../../constants/application.constant';
import { UncontrolledTooltip, } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import AppointmentModal from '../../../../common/modal/AppointmentModal';
import { convertUtcToLocalTime } from '../../../../../utils/TimezoneUtils';
import PatientAppointmentTabContainer from "./documents/PatientAppointmentTabContainer";

function PatientCompletedAppointmentItem(props) {
  const history = useHistory();
  const {
    item: doctor_data,
    index: key,
    onFavBtnClick,
    onScheduleAppointment,
    doctorData,
  } = props;
  const [viewHistoryOpen, isViewHistoryOpen] = useState(false);
  const [activeCollapse, setActiveCollapse] = useState(false);
  const [isFav, setFav] = useState(doctor_data?.my_favorite === ApplicationConstant.ONE);
  const [isOpen, setModalOpen] = useState(false);

  const schedularModal = () => {
    doctorData?.available_for_booking && setModalOpen(!isOpen);
  }

  const saveModal = (bool, input) => {
    if (bool) {
      onScheduleAppointment(input, doctorData);
    }
    schedularModal();
  };

  const sendEmail = (emailId) => {
    if (emailId) {
      window.location.assign(`mailto:${emailId}`);
    }
  };

  const toggleCollapsePanel = () => {
    setActiveCollapse(!activeCollapse);
    isViewHistoryOpen(true);
  }

  const onFavClick = (e) => {
    e.preventDefault();
    const id = doctor_data?.user_id;
    onFavBtnClick(isFav, id);
    setFav(!isFav);
  };

  const openDoctorProfile = (e) => {
    e.preventDefault();
    history.push({
      pathname: `${RouteConstants.PATIENT_VIEW_DOCTOR_PROFILE}`,
      state: { doctor_id: doctor_data?.user_id },
    });
  };

  return (
    <div className="completed-appointment-box patient-completed-appointment-box">
      <div className='user-info'>
        <div className='profile-img'>
          <Image src={doctor_data?.profile_image || ''} alt='Profile image' />
        </div>
        <div className='head patient-completed-appointments'>
          <div className='title-text'>
            <div className="user-detail">
            <h1 className='font-500 font-16'>
              {doctor_data?.first_name}&nbsp;
              {doctor_data?.last_name}
            </h1>
            <h2>
              {doctor_data?.specialization}
            </h2>
            </div>
            <div className='price-sec'>
              <div className="user-appointment-info">
                <label>TeleHealth</label>
                <div className='value'>{doctor_data?.specialization || '-'}</div>
              </div>
              <div className="user-appointment-info">
                <label>Accept Insurance</label>
                  <span
                    class={`material-icons ${doctor_data?.accept_insurance === 'Y' ? 'check-icon' : 'theme-color'}`}
                  >
                    {doctor_data?.accept_insurance === 'Y' ? 'check_circle' : 'cancel'}
                  </span>
              </div>
            </div>
            <div className='datetime'>
              <span className='material-icons'>event</span>
              <p>
                {convertUtcToLocalTime(doctor_data?.start_datetime, 'll')}{' | '}
                {convertUtcToLocalTime(doctor_data?.start_datetime, 'hh:mm A')}{' - '}
                {convertUtcToLocalTime(doctor_data?.end_datetime, 'hh:mm A')}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className='button-row'>
        <span
              class="view-history"
              onClick={toggleCollapsePanel}>
          View History{" "}
          <span className='material-icons-outlined'>
            {activeCollapse ? "expand_less" : "expand_more"}
          </span>
        </span>
        <AppointmentModal
          modalTitle={ModalConstants.SCHEDULER_TITLE}
          modalMessage={ModalConstants.VISIT_REASON}
          secondaryButonText={ModalConstants.CANCEL}
          primaryButtonText={ModalConstants.SAVE}
          setModelOpen={schedularModal}
          isOpen={isOpen}
          onButtonClicked={saveModal}
          modalClassName='modal-logout'
        />
        <div className='action-status d-flex flex-wrap'>
          <a
            href
            className={`btn-white-fav ${
              isFav ? 'active' : ''
            } pr-10 pt-10 pb-10 pl-10 d-flex align-items-center mr-8 mt-4 mb-4`}
            onClick={onFavClick}
            id={`favorite-btn-${key}`}
          >
            <span class='material-icons md-dark md-18'>favorite</span>
          </a>
          <UncontrolledTooltip
            className='moreItemTooltiip'
            placement='bottom'
            target={`favorite-btn-${key}`}
          >
            <span>{isFav ? 'Unsave' : 'Save'}</span>
          </UncontrolledTooltip>

          {doctor_data?.can_schedule ?
          <>
            <a
              href
              className='btn-white-outline pr-10 pt-10 pb-10 pl-10 d-flex align-items-center mr-8 mt-4 mb-4'
              id={`email-btn-${key}`}
              onClick={() => {
                sendEmail(doctor_data?.email);
              }}
            >
              <span class='material-icons md-dark md-18'>email</span>
            </a>
            <UncontrolledTooltip
              className='moreItemTooltiip'
              placement='bottom'
              target={`email-btn-${key}`}
            >
              <span>Mail</span>
            </UncontrolledTooltip>
            <a
              href
              className={`btn-white-outline pr-10 pt-10 pb-10 pl-10 d-flex align-items-center mr-8 mt-4 mb-4 ${doctorData?.available_for_booking ? 'enabled-icon' : 'disabled-icon'}`}
              id={`schedule-btn-${key}`}
              onClick={() => {
                schedularModal();
              }}
            >
              <span class='material-icons md-dark md-18'>today</span>
            </a>
            <UncontrolledTooltip
            className='moreItemTooltiip'
            placement='bottom'
            target={`schedule-btn-${key}`}
          >
            <span>Schedule Appointment</span>
          </UncontrolledTooltip>
          </>
          :''}

          <a
            href
            className='btn-white-outline pr-10 pt-10 pb-10 pl-10 d-flex align-items-center mt-4 mb-4'
            id={`view-details-btn-${key}`}
            onClick={openDoctorProfile}
          >
            <span class='material-icons md-dark md-18'>description</span>
          </a>
          <UncontrolledTooltip
            className='moreItemTooltiip'
            placement='bottom'
            target={`view-details-btn-${key}`}
          >
            <span>View Profile</span>
          </UncontrolledTooltip>
        </div>
      </div>
       {activeCollapse && <PatientAppointmentTabContainer
        viewHistoryOpen={viewHistoryOpen}
        item={doctor_data}
        activeCollapse={activeCollapse}
       />
      }
    </div>
  );
}

export default PatientCompletedAppointmentItem;
