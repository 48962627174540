import { Spinner } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ApplicationConstant } from '../../../../../../../constants/application.constant';
import DocumentListHeader from './DocumentListHeader';
import DocumentListItem from './DocumentListItem';
import DocumentListFooter from './DocumentListFooter';

const DocumentList = (props) => {
    const { documentList,
        fetchMoreData,
        scrollLoader,
        sortFilter,
        sortData,
        onSelectListRow,
        deleteDocument,
        selectAll,
        isSelectAllChecked,
        downloadDocument,
        tableScrollRef,
        totalPageLimit,
        downloadLoader
    } = props;

    return (
        <div class={`divTable d-flex-table ${documentList?.length > ApplicationConstant.TWO ? 'scroll-padding' : 'initial-padding'}`}>
            <div className="table-head">
                <DocumentListHeader
                    sortFilter={sortFilter}
                    sortData={sortData}
                    selectAll={selectAll}
                    isSelectAllChecked={isSelectAllChecked}
                />
            </div>
            <div id="patientDocumentScrollableDiv" className="table-body" ref={tableScrollRef}>
                <InfiniteScroll
                    dataLength={documentList.length}
                    next={fetchMoreData}
                    hasMore={totalPageLimit > ApplicationConstant.ONE}
                    scrollThreshold="70px"
                    scrollableTarget="patientDocumentScrollableDiv"
                >
                    {documentList.map((item, index) => {
                        return (
                            <DocumentListItem
                                key={`doc-${index}-${item?.id}`}
                                item={item}
                                onSelectListRow={onSelectListRow}
                                deleteDocument={deleteDocument}
                            />
                        )
                    })}
                </InfiniteScroll>
            </div>
            {scrollLoader && <Spinner/>}
            <div className="table-footer">
                <DocumentListFooter
                    downloadDocument={downloadDocument}
                    documentList={documentList}
                    downloadLoader={downloadLoader}
                />
            </div>
        </div>
    )
}

export default DocumentList;
