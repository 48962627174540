import { useEffect, useState } from 'react';
import { Modal, ModalBody, Button, Spinner } from 'reactstrap';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { ApplicationConstant, RouteConstants, UserRole } from '../../constants/application.constant';
import { getSubscriptionPlan, createSubscriptionPlan } from './SubscriptionPaymentUtils';
import SpinnerComponent from '../common/spinner/SpinnerComponent';

const Subscription = (props) => {
  const { isOpen,
    setModelOpen,
    modalClassName,
    discountAmount,
    title,
    subTitle,
    subscriptionPlanValidity,
    primaryButtonText,
    secondaryButtonText,
    isAccessRestrictions,
    subscriptionRole,
  } = props;
  const [subscriptionPriceID, setSubscriptionPriceID] = useState('');
  const [subscriptionAmount, setSubscriptionAmount] = useState('');
  const [loader, setLoader] = useState(false);
  const [modalLoader, setModalLoader] = useState(false);
  const subscriptionPlanType = ApplicationConstant.ZERO;

  const getSubscriptionDetail = async () => {
    setModalLoader(true);
    await getSubscriptionPlan().then((response)=>{
      if(response && response.data) {
        const userSubscriptionPriceId = response.data?.data[subscriptionPlanType]?.price_id || '';
        const userSubscriptionAmount = response.data?.data[subscriptionPlanType]?.unit_amount || '';
        setSubscriptionPriceID(userSubscriptionPriceId);
        setSubscriptionAmount(userSubscriptionAmount);
      }
    }).catch((error) => {toast.error(error || '')});
    setModalLoader(false);
  }

  const createSubscription = async () => {
    setLoader(true);
    await createSubscriptionPlan(subscriptionPriceID).then((response) => {
      if(response && response.data) {
        setLoader(false);
        const userSubscriptionRedirectionUrl = response.data?.data?.url || '';
        if(userSubscriptionRedirectionUrl) {
          window.location.href = userSubscriptionRedirectionUrl
        }
      }
    }).catch((error) => {toast.error(error || '')});
  }

  useEffect(()=>{
    getSubscriptionDetail();
  },[])

  return (
    <>
      {loader && <SpinnerComponent />}
      <Modal isOpen={isOpen} modalClassName={modalClassName}>
        <ModalBody>
            {modalLoader &&
              <div className="spinner-component full-page-spinner position-absolute">
                <Spinner/>
              </div>
            }
            <>
            <h1>{title}</h1>
            <p>{subTitle}</p>
            <div className="subscription-amount">
              <p>{subscriptionRole === UserRole.DOCTOR ? '$150' : '$30'}</p>
              <div className="w-100">
                <small>$</small>
                <span>{subscriptionAmount}</span>
                <h4>{subscriptionPlanValidity}</h4>
              </div>
            </div>
            {subscriptionRole === UserRole.DOCTOR ?
            <div className="subscription-features">
              <p><span class="material-icons">event</span>Receive bookings from patients</p>
              <p><span class="material-icons">account_circle</span>Receive free OutCare training</p>
            </div>
            :
            <div className="subscription-features">
              <p><span class="material-icons">account_circle</span>Find the best provider that fits your needs</p>
              <p><span class="material-icons">event</span>Book a video consultation with your provider</p>
            </div>
            }
            <div className="subscription-actions">
              <p hidden={subscriptionRole=='doctor'}>After 90 days, your account will be billed monthly at a rate of ${subscriptionAmount}.</p>
              {isAccessRestrictions ?
                <Link className="btn btn-secondary link-btn" to={RouteConstants.DASHBOARD_DOCTOR} >Go to dashbaord</Link>
              :
                <Button
                  color="secondary"
                  onClick={setModelOpen}>{secondaryButtonText}
                </Button>
              }
                <Button
                  color="primary"
                  onClick={createSubscription}>{primaryButtonText}
                </Button>
            </div>
            </>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Subscription;
