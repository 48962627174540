import React from 'react';
import { NPITypes, profileKey, targetedId, RouteConstants } from '../../../../constants/application.constant';
import GoBack from '../../../common/back/GoBack';
import { Image } from '../../../common/image/Image';
import ProfileTooltip from '../../../common/profileElements/ProfileTooltip';
import { getNPIType } from '../../../../utils/helper';
import { Link } from 'react-router-dom';

function HospitalProfile(props) {
  const { oHospitalProfile } = props;

  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="back-link">
          <GoBack heading={'Hospital Profile'} />
        </div>
        <section className="detail-info">
          <div className="profile-info">
            <div className="row">
              <div className="col-sm-12 col-md-12">
                <div className="box">
                  <div className="user-info">
                    <div className="profile-img">
                      <Image src={oHospitalProfile?.profile_image || ''} alt="Profile image" />
                    </div>
                    <div className="head">
                      <div className="title-text mt-5">
                        <h1>{oHospitalProfile?.first_name}</h1>
                      </div>
                      <div className="action-status">
                        <h4>
                          Joined Since: <span>{oHospitalProfile?.joined_since}</span>
                        </h4>
                        <a class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8">
                          <span class="material-icons md-dark md-18">mail</span>
                        </a>
                        <Link
                          to={RouteConstants.HOSPITAL_ACCOUNT_INFORMATION}
                          class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8"
                        >
                          <span class="material-icons md-dark md-18">mode_edit</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="profile-info">
                    <div className="row">
                      <div className="col-md-4 col-sm-6">
                        <label>TaxID</label>
                        <div className="value">{oHospitalProfile?.tax_id || '-'}</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Date of Business Creation</label>
                        <div className="value">{oHospitalProfile?.date_of_business_creation || '-'}</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Point of Contact - Email Address</label>
                        <div className="value">{oHospitalProfile?.email || '-'}</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Point of Contact - Phone Number</label>
                        <div className="value">{oHospitalProfile?.phone || '-'}</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>NPI Number</label>
                        <div className="value">{oHospitalProfile?.npi_number || '-'}</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>NPI Type</label>
                        <div className="value">{getNPIType(oHospitalProfile?.npi_type, NPITypes) || '-'}</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Specialty</label>
                        <div className="value more-items">
                          {oHospitalProfile?.doctor_specializations?.length
                            ? ProfileTooltip(
                                oHospitalProfile?.doctor_specializations,
                                targetedId.HOSPITAL,
                                profileKey.NAME,
                                profileKey.OTHER_SPECIALIZATIONS
                              )
                            : '-'}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Street Address</label>
                        <div className="value">{oHospitalProfile?.address1}</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Address2</label>
                        <div className="value">{oHospitalProfile?.address2 || '-'}</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>City</label>
                        <div className="value">{oHospitalProfile?.city}</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>State</label>
                        <div className="value">{oHospitalProfile?.state}</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Zip Code</label>
                        <div className="value">{oHospitalProfile?.zip_code}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default HospitalProfile;
