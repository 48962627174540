import React, { useState } from 'react'
import { Slide, ToastContainer } from 'react-toastify'
import { ApplicationConstant, PageTitles } from '../../../../constants/application.constant';
import PatientMatchedDoctors from '../../../dashboard/patient/PatientMatchedDoctors'
import useDocumentTitle from '../../../../utils/DocumentTitle';
import SpinnerComponent from '../../../common/spinner/SpinnerComponent';
import ApiConstant from '../../../../constants/ApiConstant';


function PatientFavouriteDoctorList() {
  const [loader, setLoader] = useState(false);
  useDocumentTitle(PageTitles.FAVOURITE_DOCTOR_LIST);

    return (
    <>
    {loader && <SpinnerComponent />}
    <div className="middle-content edit-profile user-right-container">
      <div className="pages">
        <div className="profile-information">
          <div className="form-step max-w-100">
            <div className="content PatientMatchedDoctors p-0">
              <PatientMatchedDoctors
                isFavDocFlow={ApplicationConstant.TRUE}
                apiUrl={ApiConstant.GET_FAV_DOCTOR}
                viewAllClicked={ApplicationConstant.TRUE}
                page={ApplicationConstant.ONE}
                limit={ApplicationConstant.TEN}
                setSpinnerLoader={setLoader}
              />
              <ToastContainer autoClose={4000} hideProgressBar transition={Slide} />
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
    )
}

export default PatientFavouriteDoctorList
