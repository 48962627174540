import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

const AppointmentModal = (props) => {
  const {
    className,
    modalClassName,
    isOpen,
    setModelOpen,
    onButtonClicked,
    modalTitle,
    modalMessage,
    primaryButtonText,
    secondaryButonText,
  } = props;
  const [userInput, setUserInput] = useState(null);

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={(e) => {
          setModelOpen(e);
        }}
        className={className}
        modalClassName={modalClassName}
      >
        <ModalBody>
          <h1>{modalTitle}</h1>
          <div className={'modal-body-msg'}>
            <p>{modalMessage}</p>
            <textarea
              onChange={(e) => {
                setUserInput(e.target.value);
              }}
            >
              {userInput}
            </textarea>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color='secondary'
            onClick={() => {
              setUserInput(null);
              onButtonClicked(false);
            }}
          >
            {secondaryButonText}
          </Button>
          <Button
            color='primary'
            disabled={!userInput?.trim()}
            onClick={() => {
              onButtonClicked(true, userInput);
            }}
          >
            {primaryButtonText}
          </Button>{' '}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AppointmentModal;
