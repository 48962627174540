import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

function RadioToggleElement({ classNm, label, name, onChange, selectedValue, ...props }) {
  return (
    <>
      <div className={classNm}>
        <FormGroup check>
          <div className="multi-radio">
            <span>{label}</span>
            <Label className="label--radio" check>
              <Input
                onChange={onChange}
                className="radio"
                checked={selectedValue === 'Y'}
                type="radio"
                id={`${name}_Y`}
                name={name}
                value={'Y'}
              />
              <label htmlFor="radio1">Yes</label>
            </Label>
            <Label className="label--radio" check>
              <Input
                onChange={onChange}
                className="radio"
                checked={selectedValue === 'N'}
                type="radio"
                id={`${name}_N`}
                name={name}
                value={'N'}
              />
              <label htmlFor="radio1">No</label>
            </Label>
          </div>
        </FormGroup>
      </div>
      {selectedValue === 'Y' && props.children}
    </>
  );
}

export default RadioToggleElement;
