import { useLocation } from 'react-router-dom';
import { CommonFooter } from '../components/common/footer/CommonFooter';
import { PatientHeader } from '../components/dashboard/patient/PatientHeader';
import { DoctorHeader } from '../components/dashboard/doctor/DoctorHeader';
import { HospitalHeader } from '../components/dashboard/hospital/HospitalHeader';
import StorageConstant from '../constants/StorageConstant';
import { isAuthorized, getMatchedPath } from '../utils/helper';
import {
  ApplicationConstant,
  RouteConstants,
  authPaths,
  SideBarRoutes,
  hideHeaderPaths,
  AppointmentSideBarRoutes,
} from '../constants/application.constant';
import PatientProfileSidebar from './../components/profile/patient/profileSidebar/PatientProfileSidebar';
import DoctorProfileSidebar from './../components/profile/doctor/profileSidebar/DoctorProfileSidebar';
import HospitalProfileSidebar from '../components/profile/hospital/profileSidebar/HospitalProfileSidebar';
import UserProfileProvider from '../contexts/user-profile/UserProfileProvider';
import DoctorAppointmentSidebar from '../components/appointment/doctor/history/appointmentSidebar/DoctorAppointmentSidebar';
import PatientAppointmentSidebar from '../components/appointment/patient/history/appointmentSidebar/PatientAppointmentSidebar';

const Layout = (props) => {
  const { children } = props;
  const { pathname } = useLocation();
  const routeKeys = Object.values(RouteConstants);
  const roleId = parseInt(localStorage.getItem(StorageConstant.ROLE_ID));

  const activeSidebar = () => {
    switch (roleId) {
      case ApplicationConstant.ONE:
        return <PatientProfileSidebar />;
      case ApplicationConstant.TWO:
        return <DoctorProfileSidebar />;
      case ApplicationConstant.THREE:
        return <HospitalProfileSidebar />;
      default:
        return null;
    }
  };

  const activeAppointmentSidebar = () => {
    switch (roleId) {
      case ApplicationConstant.ONE:
        return <PatientAppointmentSidebar/>;
      case ApplicationConstant.TWO:
        return <DoctorAppointmentSidebar />;
      default:
        return null;
    }
  };

  const activeHeader = () => {
    switch (roleId) {
      case ApplicationConstant.ONE:
        return <PatientHeader />;
      case ApplicationConstant.TWO:
        return <DoctorHeader />;
      case ApplicationConstant.THREE:
        return <HospitalHeader />;
      default:
        return null;
    }
  };

  return <UserProfileProvider>{getMatchedPath(routeKeys, pathname) && authPaths.indexOf(pathname) === -1 ? (
    <>
      {isAuthorized() && hideHeaderPaths.indexOf(pathname) === -1 && activeHeader()}
      {isAuthorized() && getMatchedPath(SideBarRoutes, pathname) && activeSidebar()}
      {isAuthorized() && getMatchedPath(AppointmentSideBarRoutes, pathname) && activeAppointmentSidebar()}
      {children}
      <CommonFooter />
    </>
  ) : (
    <>{children}</>
  )}
  </UserProfileProvider>
};

export default Layout;
