import React, { useState, useEffect } from 'react';
import { ToastContainer, toast, Slide } from 'react-toastify';
import axiosInstance from '../../../../axios/axiosInstance';
import ApiConstant from '../../../../constants/ApiConstant';
import { PageTitles } from '../../../../constants/application.constant';
import useDocumentTitle from '../../../../utils/DocumentTitle';
import SpinnerComponent from '../../../common/spinner/SpinnerComponent';
import GoBack from '../../../common/back/GoBack';
import PatientViewDoctorProfileBasic from './PatientViewDoctorProfileBasic';
import PatientViewDoctorProfileContact from './PatientViewDoctorProfileContact';
import PatientViewDoctorProfileProfesional from './PatientViewDoctorProfileProfesional';
import PatientViewDoctorProfileEducation from './PatientViewDoctorProfileEducation';
import { useLocation } from 'react-router-dom';
import PatientViewDoctorProfileLocation from './PatientViewDoctorProfileLocation';

function PatientViewDoctorContainer() {
  useDocumentTitle(PageTitles.PATIENT_VIEW_DOCTOR_PROFILE);
  const [loader, setLoader] = useState(false);
  const [oProfile, setProfile] = useState(null);
  const location = useLocation();

  useEffect(() => {
    setLoader(true)
    axiosInstance
      .get(`${ApiConstant.PATIENT_VIEW_DOCTOR_PROFILE}/${location?.state?.doctor_id}`)
      .then((res) => {
        if (res && res.data) {
          const oProfile = res.data.data || [];
          setProfile(oProfile);
          setLoader(false);
        }
      })
      .catch((error) => {
        toast.error(error || '');
      });
  }, []);

  return (
    <>
      {loader ? <SpinnerComponent /> : (
      <div className="dashboard-page">
        <div className="container">
          <div className="back-link">
            <GoBack heading={'Professional Profile'} />
          </div>
          <section className="detail-info">
            <div className="profile-info">
              <div className="row">
                <div className="card-box">
                  <PatientViewDoctorProfileBasic doctorData={oProfile} />
                  <PatientViewDoctorProfileContact doctorData={oProfile} />
                  <PatientViewDoctorProfileProfesional doctorData={oProfile} />
                  <PatientViewDoctorProfileEducation doctorData={oProfile} />
                  <PatientViewDoctorProfileLocation doctorData={oProfile} />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      )}
      <ToastContainer autoClose={4000} hideProgressBar transition={Slide} />
    </>
  );
}

export default PatientViewDoctorContainer;
