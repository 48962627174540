import moment from 'moment-timezone';

export const convertUtcToLocalTime= (utcDate, timeFormat) => {
    //utc Date format "YYYY-MM-DD HH:mm:ss"
    const utcTime = moment.utc(utcDate);
    return utcTime.local().format(timeFormat);
}

export const timeZoneFormats = {
    FULL_TIME: 'YYYY-MM-DD HH:mm:ss',
    FULL_DATE: 'YYYY-MM-DD',
}

export const getFutureDate = (actionDate, marginDays, format) => {
    return moment(actionDate).add(marginDays, 'days').format(format)
}

export const getPastDate = (actionDate, marginDays, format) => {
    return moment(actionDate).subtract(marginDays, 'days').format(format)
}
