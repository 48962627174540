export const validateType = (type, allowedTypes, allowedTypeMsg) => {
    const result = { value: '', valid: false };
    if (allowedTypes.length >= 1 && typeof type === 'string') {
      result.valid = allowedTypes.some((value) => type.toLowerCase().includes(value.toLowerCase()));
    }
    result.value = result.valid ? '' : allowedTypeMsg;
    return result;
};

const allowedSizeCalculation = (fileAllowedSize) => {
    return fileAllowedSize/1000000;
}

export const validateSize = (size, allowSize) => {
    const result = { value: '', valid: false };
    if (size <= allowSize) {
      result.value = '';
      result.valid = true;
    } else {
      result.value = `Maximum file size should be ${allowedSizeCalculation(allowSize)} MB.`;
      result.valid = false;
    }
    return result;
};
