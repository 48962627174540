import React, {useState} from 'react';
import moment from 'moment';
import { Dropdown, DropdownToggle, DropdownMenu, Button, InputGroup } from 'reactstrap';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { filterValidation } from '../DoctorPaymentHistoryUtils';

const PaymentHistoryListFilter = (props) => {
  const { applyListFilter, clearListFilter, minFilterDate, maxFilterDate, paymentHistoryList, appliedFilterDate } = props;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filterDate, setfilterDate] = useState({start:'', end:''});
  const [isFilterApplied, setFilterApplied] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  }

  const applyFilter = () => {
    applyListFilter(filterDate);
    toggleDropdown();
    setFilterApplied(true);
  }

  const clearFilter = () => {
    setfilterDate({start:'', end:''});
    if(isFilterApplied) {
      clearListFilter();
      setDropdownOpen(false);
    }
    setFilterApplied(false);
  }

  return (
    <>
      {isFilterApplied &&
        <div className="applied-filter-info">
          <p>{moment(appliedFilterDate.start).format('ll')}{' - '}{moment(appliedFilterDate.end).format('ll')}</p>
          <button onClick={clearFilter}>
            <span class="material-icons">close</span>
          </button>
        </div>
      }
      <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} className="filter-dropdown" hidden={!isFilterApplied && !paymentHistoryList?.length}>
        <DropdownToggle tag="button" className="btn btn-secondary small min-w-auto filter-btn">
          <span class="material-icons-sharp">filter_alt</span>Filter
        </DropdownToggle>
        <DropdownMenu right>
          <InputGroup>
            <div className="label-float">
              <ReactDatePicker
                selected={filterDate.start}
                onChange={(date)=>{setfilterDate({...filterDate, start:date})}}
                popperPlacement="top-end"
                autoComplete={'off'}
                minDate={moment(minFilterDate).toDate()}
                maxDate={moment(maxFilterDate).toDate()}
                scrollableYearDropdown
                scrollIntoView
              />
              <label className={`selectLabel ${filterDate.start ? 'selected' : ''}`}>From</label>
            </div>
          </InputGroup>
          <InputGroup>
            <div className="label-float">
              <ReactDatePicker
                selected={filterDate.end}
                onChange={(date)=>{setfilterDate({...filterDate, end:date})}}
                popperPlacement="top-end"
                autoComplete={'off'}
                minDate={moment(minFilterDate).toDate()}
                maxDate={moment(maxFilterDate).toDate()}
                scrollableYearDropdown
                scrollIntoView
              />
              <label className={`selectLabel ${filterDate.end ? 'selected' : ''}`}>To</label>
            </div>
          </InputGroup>
          {filterDate.end && filterDate.end < filterDate.start &&
            <span className="filter-error">End date must be greater or equal to start date.</span>
          }
          <div className="filter-btn-group">
            <Button color="secondary" disabled={filterValidation(filterDate)} onClick={clearFilter}>Clear All</Button>
            <Button color="primary" disabled={filterValidation(filterDate)} onClick={applyFilter}>Apply</Button>
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default PaymentHistoryListFilter;
