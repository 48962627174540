import React from 'react';
import { ApplicationConstant } from '../../../constants/application.constant';

function DoctorProfileStepper(props) {
  const { oDoctorProfile , toggleSubscriptionModal} = props;

  return (
    <div>
      <div className="welcome">
        <h1>
          Welcome {oDoctorProfile?.doctor_profile?.first_name || ""}!{" "}
          <span>
            &nbsp;
            {oDoctorProfile?.doctor_profile?.doctor_specializations?.length
              ? oDoctorProfile?.doctor_profile?.doctor_specializations[
                  ApplicationConstant.ZERO
                ].name
              : "-"}
          </span>
        </h1>
      </div>
      <div className="profile-links">
        <ul>
          <li>
            <a
              className={
                oDoctorProfile?.doctor_profile?.professional_profile
                  ? "active"
                  : ""
              }>
              <div className="profile-status">
                <span className="material-icons">done</span>
              </div>
              <span>Professional Profile</span>
            </a>
          </li>
          <li>
            <a
              className={
                oDoctorProfile?.doctor_profile?.add_an_image ? "active" : ""
              }>
              <div className="profile-status">
                <span className="material-icons">done</span>
              </div>
              <span>Add an Image</span>
            </a>
          </li>
          <li className="subscription-link-tab hide">
            {oDoctorProfile?.doctor_profile?.become_a_member ?
              <a className="active">
                <div className="profile-status"><span className="material-icons">done</span></div>
                <span>Become a Member!</span>
              </a>
            :
              <div className="link-icon" onClick={toggleSubscriptionModal}>
                <div className="profile-status"><span className="material-icons">done</span></div>
                <span>Become a Member!</span>
              </div>
              }
          </li>
          <li>
            <a
              className={
                oDoctorProfile?.doctor_profile?.first_patient_session
                  ? "active"
                  : ""
              }>
              <div className="profile-status">
                <span className="material-icons">done</span>
              </div>
              <span>Have your first patient session</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default DoctorProfileStepper;
