import React, { useState } from 'react';
import HospitalSignUpContext from './HospitalSignUpContext';
import HospitalSignUpInitialState from './HospitalSignUpInitialState';

const HostpitalSignUpProvider = (props) => {
  const [hospitalSignUp, setHospitalSignUp] = useState(HospitalSignUpInitialState);
  return (
    <HospitalSignUpContext.Provider value={[hospitalSignUp, setHospitalSignUp]}>
      {props.children}
    </HospitalSignUpContext.Provider>
  );
};

export default HostpitalSignUpProvider;
