import React from 'react';

function PatientViewDoctorProfileLocation(props) {
  const { doctorData } = props;
  return (
    <>
      <div className="profile-info">
        <h3 className="mt-10 mb-18">Location</h3>
        <div className="row">
          <div className="col-md-4 col-sm-6">
            <label>Street Address</label>
            <div className="value">{doctorData?.address1 || '-'}</div>
          </div>
          <div className="col-md-4 col-sm-6">
            <label>Apartment</label>
            <div className="value">{doctorData?.address2 || '-'}</div>
          </div>
          <div className="col-md-4 col-sm-6">
            <label>City</label>
            <div className="value">{doctorData?.city || '-'}</div>
          </div>
          <div className="col-md-4 col-sm-6">
            <label>State</label>
            <div className="value">{doctorData?.state || '-'}</div>
          </div>
          <div className="col-md-4 col-sm-6">
            <label>Zip Code</label>
            <div className="value">{doctorData?.zip_code || '-'}</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PatientViewDoctorProfileLocation;
