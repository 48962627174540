import React from 'react';
import HospitalBackground from './HospitalBackground';
import HospitalBasicInformation from './HospitalBasicInformation';
import HospitalLocation from './HospitalLocation';

function HospitalAccountCreation({ oLabelInfo }) {
  return (
    <div>
      <div className='head-title mb-50'>
        <div className='heading'>Hospital Registration</div>
        <div className='sub-heading'>Account Creation</div>
      </div>
      <div className='form-step'>
        <form className='w-100' autocomplete="off">
          <HospitalBasicInformation oLabelInfo={oLabelInfo} />
          <HospitalLocation/>
          <HospitalBackground oLabelInfo={oLabelInfo}/>
        </form>
      </div>
    </div>
  );
}

export default HospitalAccountCreation;
