import { useState } from "react";
import { Progress } from 'reactstrap';
import axiosInstance from '../../../axios/axiosInstance';
import { validateType, validateSize } from './ReactFileUploaderUtils';
import { ApplicationConstant } from '../../../constants/application.constant';

const ReactDocumentUploader = (props) => {
    const { onSuccess, checkValidation, allowedExtensions, allowedSize, allowedTypeMsg, title, url, formFieldName } = props;
    const [progressloader, setProgressLoader] = useState(false);
    const [progressPercent, setProgressPercent] = useState(ApplicationConstant.ZERO);

    const onUploadProgress = (event) => {
        const percent = Math.round((event.loaded * ApplicationConstant.HUNDRED) / event.total);
        setProgressPercent(percent);
    };

    const onUploadFile = async (targetedFile) => {
        if (targetedFile) {
          const formData = new FormData();
          const config = {headers: { 'Content-Type': 'multipart/form-data' }, onUploadProgress: onUploadProgress};
          setProgressLoader(true);
          formData.append(formFieldName, targetedFile, targetedFile.name);
          await axiosInstance
            .post(`${url}`, formData, config).then((response) => {
                if(response && response.data) {
                    const responseFileUrl = response.data.data?.document_url || '';
                    responseFileUrl && onSuccess(responseFileUrl);
                    checkValidation({ value: ['success'], valid: true });
                }
            }).catch((response) => {
              checkValidation({ valid: false, value: [response] });
            })
            setProgressLoader(false);
            setProgressPercent(0);
        }
    };

    const onSelectFile = (e) => {
        e.preventDefault();
        const selectedFile = e.target.files[ApplicationConstant.ZERO];
        e.target.value = '';
        if (selectedFile) {
            const fileType = validateType(selectedFile.type, allowedExtensions, allowedTypeMsg);
            const fileSize = validateSize(selectedFile.size, allowedSize);
            if(fileType.valid && fileSize.valid) {
                checkValidation({ value: ['success'], valid: true });
                onUploadFile(selectedFile);
            }else {
                checkValidation({ valid: false, value: [fileType.value, fileSize.value] });
                e.target.value = null;
            }
        }
    }

    return (
        <>
        <label className="btn btn-secondary document-upload-btn">
            <input
                type="file"
                name="upload_file"
                id="upload_file"
                onChange={onSelectFile}
            />
            <span class="material-icons-sharp">upload</span>
            {title}
        </label>
        {progressloader && <Progress color="warning" value={progressPercent}>{`${progressPercent}%`}</Progress>}
        </>
    )
}

export default ReactDocumentUploader;
