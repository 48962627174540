import React, { useState, useEffect } from 'react';
import { useLocation, useHistory, Link } from 'react-router-dom';
import CustomModal from '../common/modal/CustomModal';
import { PageTitles, RouteConstants, ModalConstants } from '../../constants/application.constant';
import { logout } from '../../utils/CommonRequests';
import { linkActiveClass } from '../../utils/helper';
import { Button } from 'reactstrap';

function CommonSidebar(props) {
  const { sideMenuItems, setPageTitle } = props;
  const [navOpen, setNavOpen] = useState(false);
  const [isOpen, setModalOpen] = useState(false);
  const toggleLogOutModal = () => setModalOpen(!isOpen);
  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    getPath(pathname);
  }, []);

  function getPath(routePath) {
    if (routePath) {
      const heading = getHeading(routePath.substring(routePath.lastIndexOf('/')));
      if (heading) {
        setPageTitle(heading);
      }
    }
  }
  useEffect(() => {
    return history.listen((location) => {
      if(location){
      getPath(location.pathname);
      }
    });
  }, [history]);

  function getHeading(pageType) {
    switch (pageType) {
      case RouteConstants.USER_PROFILE:
        return PageTitles.USER_PROFILE;
      case RouteConstants.ACCOUNT_INFORMATION:
        return PageTitles.ACCOUNT_INFORMATION;
      case RouteConstants.CHANGE_PASSWORD:
        return PageTitles.CHANGE_PASSWORD;
      case RouteConstants.USER_PREFERENCES:
        return PageTitles.USER_PREFERENCES;
      case RouteConstants.FAVOURITE_DOCTOR_LIST:
        return PageTitles.FAVOURITE_DOCTOR_LIST;
      case RouteConstants.BACKGROUND_INFO:
        return PageTitles.BACKGROUND_INFO;
      case RouteConstants.DOCTOR_PAYMENT:
        return PageTitles.MEMBERSHIP_INFORMATION;
      case RouteConstants.PATIENT_MEMBERSHIP:
        return PageTitles.MEMBERSHIP_INFORMATION;
      case RouteConstants.DOCTOR_PAYMENT_HISTORY:
        return PageTitles.DOCTOR_PAYMENT_HISTORY;
      case RouteConstants.PATIENT_PAYMENT_HISTORY:
        return PageTitles.PATIENT_PAYMENT_HISTORY;
      default:
        return '';
    }
  }

  const onSignOut = (isSignOut) => {
    toggleLogOutModal();
    if (isSignOut) {
      logout();
    }
  };

  return (
    <>
      <div className={navOpen ? 'edit-nav open' : 'edit-nav'}>
        <ul>
          {sideMenuItems.map((item, index) => {
            return (
              <li
                onClick={() => {
                  setNavOpen(!navOpen);
                }}
                key={index}
                className={linkActiveClass(pathname, item.to)}
              >
                {item.to ? (
                  <Link to={item.to}>
                    <span className={item.iconClass}>{item.icon}</span>
                    <span className="name">{item.name}</span>
                  </Link>
                ) : (
                  <Button className="logout-btn" onClick={toggleLogOutModal}>
                    <span className={item.iconClass}>{item.icon}</span>
                    <span className="name">{item.name}</span>
                  </Button>
                )}
              </li>
            );
          })}
        </ul>
        <a
          className="nav-toggal"
          onClick={() => {
            setNavOpen(!navOpen);
          }}
        >
          <span className="material-icons rotate-icon">chevron_right</span>
        </a>
        <CustomModal
          modalTitle={ModalConstants.SIGN_OUT_TITLE}
          modalMessage={ModalConstants.SIGN_OUT_MESSAGE}
          secondaryButonText={ModalConstants.CANCEL}
          primaryButtonText={ModalConstants.SIGN_OUT}
          setModelOpen={toggleLogOutModal}
          isOpen={isOpen}
          onButtonClicked={onSignOut}
          modalClassName="modal-logout"
        />
      </div>
    </>
  );
}

export default CommonSidebar;
