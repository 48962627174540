import React, { useContext, useState } from 'react';
import update from 'immutability-helper';
import {
  nameValidation,
  dateofBirthValidation,
  emailValidation,
  phoneValidation,
  emailValidationApi,
  phoneValidationApi,
  passwordValidation,
  confirmPasswordValidation,
} from "../../../validators/CommonValidation.js";
import {ApplicationConstant, DateConstant, RouteConstants} from '../../../constants/application.constant';
import DoctorSignUpContext from '../../../contexts/doctor-signup/DoctorSignUpContext';
import InputElement from '../../common/formElements/InputElement';
import DateElement from '../../common/formElements/DateElement';
import { subYears } from 'date-fns';
import {Link} from "react-router-dom";

function DoctorPersonalInformation(props) {
  const { isHospitalFlow = ApplicationConstant.FALSE } = props;
  const [passwordShown, setPasswordShown] = useState(ApplicationConstant.TRUE);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(ApplicationConstant.TRUE);
  const [doctorSignUp, setDoctorSignUp] = useContext(DoctorSignUpContext);

  const setValueByFieldName = (fieldname, value) => {
    setDoctorSignUp(update(doctorSignUp, { doctor_profile: { [fieldname]: { $set: value } } }));
  };

  const onFirstNameInput = (e) => {
    e.preventDefault();
    const obj = nameValidation(e.target.value);
    setValueByFieldName('first_name', obj);
  };

  const onLastNameInput = (e) => {
    e.preventDefault();
    const obj = nameValidation(e.target.value);
    setValueByFieldName('last_name', obj);
  };

  const onDobInput = (date) => {
    const obj = dateofBirthValidation(date, ApplicationConstant.EIGHTEEN);
    setValueByFieldName('date_of_birth', obj);
  };

  const onEmailInput = (e) => {
    e.preventDefault();
    emailValidation('email', e.target.value, setValueByFieldName);
  };
  const onEmailInputApi = (e) => {
    e.preventDefault();
    emailValidationApi('email', e.target.value, setValueByFieldName, doctorSignUp.doctor_profile.email);
  };

  const onPhoneInput = (e) => {
    e.preventDefault();
    if (e.target.value.length <= ApplicationConstant.TEN) {
      phoneValidation('phone', e.target.value, setValueByFieldName);
    }
  };

  const onPhoneInputApi = (e) => {
    e.preventDefault();
    if (e.target.value.length <= ApplicationConstant.TEN) {
      phoneValidationApi('phone', e.target.value, setValueByFieldName, doctorSignUp.doctor_profile.phone);
    }
  };

  const onPasswordInput = (e) => {
    e.preventDefault();
    const obj = passwordValidation(e.target.value, doctorSignUp.doctor_profile.password);
    setValueByFieldName('password', obj);
  };

  const onConfirmPasswordInput = (e) => {
    e.preventDefault();
    if (doctorSignUp.doctor_profile.password.valid) {
      const obj = confirmPasswordValidation(e.target.value, doctorSignUp.doctor_profile.password.value);
      setValueByFieldName('confirm_password', obj);
    }
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(confirmPasswordShown ? false : true);
  };

  const handleTermsAndConditionsCheck = (e) => {
    const check = e.target.checked;
    const value = check ? ApplicationConstant.ONE : ApplicationConstant.ZERO;
    setValueByFieldName('terms_and_conditions', value);
  }

  return (
    <div>
      <h3>Personal Information</h3>
      <div className="row">
        <InputElement
          className="col-sm-6"
          type="text"
          name="first_name"
          value={doctorSignUp.doctor_profile.first_name.value}
          onChange={onFirstNameInput}
          onBlur={onFirstNameInput}
          isValid={doctorSignUp.doctor_profile.first_name.valid}
          msg={doctorSignUp.doctor_profile.first_name}
          placeholder="Please enter first name"
          label={"First Name*"}
        />
        <InputElement
          className="col-sm-6"
          type="text"
          name="last_name"
          value={doctorSignUp.doctor_profile.last_name.value}
          onChange={onLastNameInput}
          onBlur={onLastNameInput}
          isValid={doctorSignUp.doctor_profile.last_name.valid}
          msg={doctorSignUp.doctor_profile.last_name}
          placeholder="Please enter last name"
          label={"Last Name*"}
        />
        <DateElement
          className="col-sm-12"
          type="date"
          name="date_of_birth"
          value={doctorSignUp.doctor_profile.date_of_birth.value}
          onChange={onDobInput}
          dateFormat="MMM d, yyyy"
          maxDate={subYears(new Date(), ApplicationConstant.EIGHTEEN)}
          minDate={new Date(DateConstant.START_DATE)}
          onKeyPress={(e) => {
            e.preventDefault();
          }}
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          onKeyUp={(e) => {
            e.preventDefault();
          }}
          isValid={doctorSignUp.doctor_profile.date_of_birth.valid}
          msg={doctorSignUp.doctor_profile.date_of_birth.msg}
          label={"Birthdate*"}
        />
      </div>
      <h3 className="mt-24">Contact Information</h3>
      <div className="row">
        <InputElement
          className="col-sm-6"
          type="email"
          name="email"
          value={doctorSignUp.doctor_profile.email.value}
          onChange={onEmailInput}
          onBlur={onEmailInputApi}
          label={"Email Address*"}
          placeholder={"Please enter email address"}
          isValid={doctorSignUp.doctor_profile.email.valid}
          msg={doctorSignUp.doctor_profile.email.msg}
        />
        <InputElement
          className="col-sm-6"
          type="number"
          name="phone"
          value={doctorSignUp.doctor_profile.phone.value}
          onChange={onPhoneInput}
          onBlur={onPhoneInputApi}
          onKeyDown={(evt) =>
            ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()
          }
          label={"Phone Number*"}
          placeholder={"Please enter phone no"}
          isValid={doctorSignUp.doctor_profile.phone.valid}
          msg={doctorSignUp.doctor_profile.phone.msg}
        />
      </div>
      {!isHospitalFlow && (
        <>
          <h3 className="mt-24">Password</h3>
          <div className="row">
            <div className="col-sm-6 p-0">
              <div className="passwordshowhide ">
                <InputElement
                  className="col-sm-12"
                  type={!passwordShown ? "text" : "password"}
                  name="password"
                  autocomplete="new-password"
                  value={doctorSignUp.doctor_profile.password.value}
                  onChange={onPasswordInput}
                  onBlur={onPasswordInput}
                  label={"Password*"}
                  placeholder={"Please enter password"}
                  isValid={doctorSignUp.doctor_profile.password.valid}
                  msg={doctorSignUp.doctor_profile.password.msg}
                />
                <span
                  className="material-icons md-20 md-dark "
                  onClick={togglePasswordVisiblity}>
                  {passwordShown ? "visibility_off" : "visibility"}
                </span>{" "}
              </div>
            </div>
            <div className="col-sm-6 p-0">
              <div className="passwordshowhide ">
                <InputElement
                  className="col-sm-12"
                  type={!confirmPasswordShown ? "text" : "password"}
                  name="confirm_password"
                  value={doctorSignUp.doctor_profile.confirm_password.value}
                  onChange={onConfirmPasswordInput}
                  onBlur={onConfirmPasswordInput}
                  label={"Confirm Password*"}
                  placeholder={"Please enter 10 digit number"}
                  isDisabled={!doctorSignUp.doctor_profile.password.valid}
                  isValid={doctorSignUp.doctor_profile.confirm_password.valid}
                  msg={doctorSignUp.doctor_profile.confirm_password.msg}
                />
                <span
                  className="material-icons md-20 md-dark "
                  onClick={toggleConfirmPasswordVisiblity}>
                  {confirmPasswordShown ? "visibility_off" : "visibility"}
                </span>{" "}
              </div>
            </div>
            <div className="col-sm-12 ">
              <p className="font-12 gray666">
                8 Characters Minimum, 1 Upper Case Letter, 1 Number, 1 Special
                Character
              </p>
            </div>
          </div>
          <div className="check-btn ml-0">
            <label className="label--checkbox flat d-flex">
              <input
                  type="checkbox"
                  className="checkbox"
                  onClick={handleTermsAndConditionsCheck}
                  checked={
                      doctorSignUp.doctor_profile?.terms_and_conditions ===
                      ApplicationConstant.ONE
                  }
                  isValid={doctorSignUp.doctor_profile?.terms_and_conditions?.valid}
              />
              <label className="font-12 font-500 ">I agree to the&nbsp;</label>
              <Link target="_blank" className="font-12 font-500 " to={RouteConstants.TERMS_SERVICE}>Terms & Conditions</Link>
              <label className="font-12 font-500 ml-0">&nbsp;of equalityMD*</label>
            </label>
          </div>
        </>
      )}
    </div>
  );
}

export default DoctorPersonalInformation;
