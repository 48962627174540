import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import { toast } from 'react-toastify';
import SpinnerComponent from '../../../../../common/spinner/SpinnerComponent';
import { InitialPageLimit, ApplicationConstant } from '../../../../../../constants/application.constant';
import PaymentHistoryDetailListHeader from './PaymentHistoryDetailListHeader';
import PaymentHistoryDetailListItem from './PaymentHistoryDetailListItem';
import { getDoctorPaymentDetailList, EmptyPaymentHistory } from '../../DoctorPaymentHistoryUtils';

const PaymentHistoryDetailList = (props) => {
  const { listDate, totalEarnings, totalRefund } = props;
  const [historyDetailListLoader, setHistoryDetailListLoader] = useState(false);
  const [paymentDetailList, setPaymentDetailList] = useState(null);
  const [sortObj, setSortObj] = useState({ sortBy: '', order: '' });
  const [pageCount, setPageCount] = useState(ApplicationConstant.ONE);
  const [currentPage, setCurrentPage] = useState(ApplicationConstant.ONE);

  const doctorPaymentDetailList = async () => {
    setHistoryDetailListLoader(true);
    await getDoctorPaymentDetailList(listDate, sortObj, currentPage, InitialPageLimit.PAYMENT_HISTORY_DETAIL)
    .then((response)=>{
      if(response && response.data) {
        const paymentDetailDataList = response.data?.data?.data || null;
        const listPageCount = response.data?.data?.last_page || '';
        setPaymentDetailList(paymentDetailDataList);
        setPageCount(listPageCount)
      }
    }).catch((error)=>{
      toast.error(error || '');
    })
    setHistoryDetailListLoader(false);
  }

  const changePagination = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage + ApplicationConstant.ONE);
  };

  const sortTableData = (sortBy, order) => {
    setSortObj({ sortBy, order });
  };

  useEffect(()=>{
   doctorPaymentDetailList();
  },[sortObj, currentPage])

  return (
    <>
    {historyDetailListLoader && <SpinnerComponent />}
    <div className="table-section">
      <div className="total-refunds">
        <ul>
          <li>Total Monthly Refunds <strong>${totalRefund || 0}</strong></li>
          <li>Total Monthly Payments <strong>${totalEarnings || 0}</strong></li>
        </ul>
      </div>
      <div className="box mt-0">
        <div class="divTable d-flex-table doctor-payment-table">
          {paymentDetailList?.length ? (
          <>
            <div className="table-head">
              <PaymentHistoryDetailListHeader
                sortTableData={sortTableData}
                sortFilterObj={sortObj}
              />
            </div>
            <div className="table-body">
                {paymentDetailList.map((item, index) => {
                  return (
                    <PaymentHistoryDetailListItem
                      key={index}
                      listItem={item}
                    />
                  )
                })}
            </div>
            <div className="payment-history-detail-footer">
              <div className="pagination-wrapper" hidden={pageCount === ApplicationConstant.ONE}>
                <ReactPaginate
                  previousLabel={'previous'}
                  nextLabel={'next'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={pageCount}
                  marginPagesDisplayed={3}
                  onPageChange={changePagination}
                  pageRangeDisplayed={1}
                  containerClassName={'pagination'}
                  activeClassName={'active'}
                  nextLinkClassName={'next-btn'}
                  previousLinkClassName={'prev-btn'}
                />
              </div>
            </div>
          </>
          ):(
            !historyDetailListLoader && <EmptyPaymentHistory />
          )}
        </div>
      </div>
    </div>
    </>
  )
}

export default PaymentHistoryDetailList;
