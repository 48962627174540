import { ApplicationConstant } from '../../../constants/application.constant';
import { DoctorRequestInitialState, DoctorFormState } from './DoctorRegistrationInitialState';

export const checkValidation = (oDoctor, oDoctorForm) => {
  const isValidPronoun = checkMultiElement(oDoctor, 'user_pronouns', 'other_pronoun', oDoctorForm.isPronounOther);
  const isValidRace = checkMultiElement(oDoctor, 'user_races', 'other_race', oDoctorForm.isRaceOther);
  const isValidEthnicity = checkSingleElement(oDoctor, 'ethnicity_id', 'other_ethnicity', oDoctorForm.isEthnicityOther);
  const isValidLanguages = checkMultiElement(oDoctor, 'user_languages', 'other_language', oDoctorForm.isLanguageOther);
  const isValidIdentification = checkMultiElement(oDoctor, 'user_identifications', 'other_identification', oDoctorForm.isIdentificationOther);
  const isValidSexOrientation = checkMultiElement(oDoctor, 'user_sexual_orientations', 'other_sexual_orientation', oDoctorForm.isSexualOrientationOther);
  if (isValidPronoun && isValidRace && isValidEthnicity
        && isValidLanguages && isValidIdentification && isValidSexOrientation) {
    return false;
  }
  return true;
};

function checkSingleElement(oData, key, otherKey, isOtherAdded) {
  const elementId = oData[key] || '';
  if (elementId) {
    if (isOtherAdded) {
      const otherValue = oData[otherKey]?.trim() || '';
      return otherValue ? true : false;
    }
    return true;
  }
  return false;
}

function checkMultiElement(oData, key, otherKey, isOtherAdded) {
  const aData = oData[key] || [];
  if (aData.length === ApplicationConstant.ZERO) {
    return false;
  }
  if (isOtherAdded) {
    const otherValue = oData[otherKey]?.trim() || '';
    return otherValue ? true : false;
  }
  return true;
}

export const createDoctorProfileData = (oProfile, oProfileFields) => {
  const oData = {
    oDoctorProfile: null,
    oState: null,
  };
  const oDoctorProfile = JSON.parse(JSON.stringify(DoctorRequestInitialState));
  const oDoctorFormState = JSON.parse(JSON.stringify(DoctorFormState));
  if (oProfile) {
    const ethnicityId = oProfile.ethnicity_id || '';
    const userPronouns = oProfile.user_pronouns || [];
    const userRaces = oProfile.user_races || [];
    const userLanguages = oProfile.user_languages || [];
    const userIdentifications = oProfile.user_identifications || [];
    const userSexOrientation = oProfile.user_sexual_orientations || [];
    if (ethnicityId) {
      oDoctorProfile.doctor.ethnicity_id = ethnicityId;
      const otherEthnicity = oProfile.other_ethnicity || '';
      oDoctorProfile.doctor.other_ethnicity = otherEthnicity;
      oDoctorFormState.isEthnicityOther = otherEthnicity ? true : false;

      const oEthnicity = oProfileFields.ethnicities.find((oEthnicity) => oEthnicity.id === ethnicityId);
      oDoctorProfile.data.ethnicity = oEthnicity || {};
    }
    if (userPronouns.length > ApplicationConstant.ZERO) {
      const itemIds = [];
      const aPronounData = [];
      for (const oItem of userPronouns) {
        itemIds.push({ id: oItem.pronoun_id, other: oItem.other_pronoun || '' });
      }
      const aPronouns = oProfileFields.pronouns || [];
      for (const item of itemIds) {
        const oPronoun = aPronouns.find((oPronoun) => oPronoun.id === item.id);
        if (oPronoun) {
          if (oPronoun.isOther) {
            oDoctorProfile.doctor.other_pronoun = item.other || '';
            oDoctorFormState.isPronounOther = true;
          }
          aPronounData.push(oPronoun);
        }
        oDoctorProfile.data.userPronouns = aPronounData;
        oDoctorProfile.doctor.user_pronouns = aPronounData;
      }
    }
    if (userRaces.length > ApplicationConstant.ZERO) {
      const itemIds = [];
      const aRaceData = [];
      for (const oItem of userRaces) {
        itemIds.push({ id: oItem.race_id, other: oItem.other_race || '' });
      }
      const aRaces = oProfileFields.races || [];
      for (const item of itemIds) {
        const oRace = aRaces.find((oRace) => oRace.id === item.id);
        if (oRace) {
          if (oRace.isOther) {
            oDoctorProfile.doctor.other_race = item.other || '';
            oDoctorFormState.isRaceOther = true;
          }
          aRaceData.push(oRace);
        }
        oDoctorProfile.data.userRaces = aRaceData;
        oDoctorProfile.doctor.user_races = aRaceData;
      }
    }
    if (userLanguages.length > ApplicationConstant.ZERO) {
      const itemIds = [];
      const aLanguageData = [];
      for (const oItem of userLanguages) {
        itemIds.push({ id: oItem.language_id, other: oItem.other_language || '' });
      }
      const aLanguages = oProfileFields.languages || [];
      for (const item of itemIds) {
        const oLanguage = aLanguages.find((oLanguage) => oLanguage.id === item.id);
        if (oLanguage) {
          if (oLanguage.isOther) {
            oDoctorProfile.doctor.other_language = item.other || '';
            oDoctorFormState.isLanguageOther = true;
          }
          aLanguageData.push(oLanguage);
        }
        oDoctorProfile.data.userLanguages = aLanguageData;
        oDoctorProfile.doctor.user_languages = aLanguageData;
      }
    }
    if (userIdentifications.length > ApplicationConstant.ZERO) {
      const itemIds = [];
      const aIdentificatonData = [];
      for (const oItem of userIdentifications) {
        itemIds.push({ id: oItem.identification_id, other: oItem.other_identification || '' });
      }
      const aIdentifications = oProfileFields.identifications || [];
      for (const item of itemIds) {
        const oIdentification = aIdentifications.find((oIdentification) => oIdentification.id === item.id);
        if (oIdentification) {
          if (oIdentification.isOther) {
            oDoctorProfile.doctor.other_identification = item.other || '';
            oDoctorFormState.isIdentificationOther = true;
          }
          aIdentificatonData.push(oIdentification);
        }
        oDoctorProfile.data.userIdentifications = aIdentificatonData;
        oDoctorProfile.doctor.user_identifications = aIdentificatonData;
      }
    }
    if (userSexOrientation.length > ApplicationConstant.ZERO) {
      const itemIds = [];
      const aSexOrientationData = [];
      for (const oItem of userSexOrientation) {
        itemIds.push({ id: oItem.sexual_orientation_id, other: oItem.other_sexual_orientation || '' });
      }
      const aSexOrientations = oProfileFields.sexual_orientations || [];
      for (const item of itemIds) {
        const oSexOrientation = aSexOrientations.find((oSexOrientation) => oSexOrientation.id === item.id);
        if (oSexOrientation) {
          if (oSexOrientation.isOther) {
            oDoctorProfile.doctor.other_sexual_orientation = item.other || '';
            oDoctorFormState.isSexualOrientationOther = true;
          }
          aSexOrientationData.push(oSexOrientation);
        }
        oDoctorProfile.data.userSexOrientation = aSexOrientationData;
        oDoctorProfile.doctor.user_sexual_orientations = aSexOrientationData;
      }
    }
  }
  oData.oDoctorProfile = oDoctorProfile;
  oData.oState = oDoctorFormState;
  return oData;
};
