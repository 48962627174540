import { UncontrolledTooltip } from 'reactstrap';

const IconTooltip = (placement, targetId, title, id) => {
  return (
    <UncontrolledTooltip placement={placement} target={`${targetId}${id}`}>
      {title}
    </UncontrolledTooltip>
  )
}

export default IconTooltip;
