import { useEffect, useState } from 'react';
import { Modal, ModalBody, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UserRole, RouteConstants, ApplicationConstant } from '../../constants/application.constant';
import { getSubscriptionPlan } from './SubscriptionPaymentUtils';

const PurchaseSubscriptionConfirmation = (props) => {
  const {
    isOpen,
    modalClassName,
    roleType,
    setModelOpen
} = props;
  const [subscriptionAmount, setSubscriptionAmount] = useState('');
  const [isUserSubscribed, setUserSubscribed] = useState(null);
  const [modalLoader, setModalLoader] = useState(false);
  const subscriptionPlanType = ApplicationConstant.ZERO;

  const getSubscriptionDetail = async () => {
    setModalLoader(true);
    await getSubscriptionPlan().then((response)=>{
      if(response && response.data) {
        const userSubscriptionAmount = response.data?.data[subscriptionPlanType]?.unit_amount || '';
        const userSubscription = response.data?.data[subscriptionPlanType]?.subscriptions?.subscription_id || null;
        setSubscriptionAmount(userSubscriptionAmount);
        setUserSubscribed(userSubscription);
      }
    }).catch((error) => {toast.error(error || '')});
    setModalLoader(false)
  }

  useEffect(()=>{
    getSubscriptionDetail();
  },[])

return (
  <Modal
    isOpen={isOpen}
    className={modalClassName}>
    <ModalBody className="m-0">
      <div className="w-100" hidden={modalLoader}>
        <h1>Congratulations!</h1>
        <div className="w-100 text-center card-update-success d-none">
          <p>Your card details updated successfully.</p>
          <button className="btn btn-primary w-100 mb-15" onClick={setModelOpen}>Ok</button>
          <Link to={RouteConstants.PRIVACY_POLICY} className="w-100 text-decoration theme-color" color="secondary">See Terms of Agreement</Link>
        </div>
        <div className="w-100">
          <p className="text-center text-dark mb-10">Welcome to the equalityMD platform and community.</p>
          <p className="text-center text-dark mb-10">You are now ready to make an appointment!</p>
          <p className="text-center text-dark mb-10" hidden={roleType=='doctor'}>After 90 days, your account will be billed monthly at a rate of ${subscriptionAmount}.</p>
          <div className="text-center mt-30" hidden={roleType === UserRole.PATIENT}>
            <Link
              to={RouteConstants.DOCTOR_SCHEDULE_AVAILABILITY}
              className="btn btn-primary w-100 mb-15">set fee and availability
            </Link>
            <Link
              to={'/'}
              className="btn btn-secondary w-100">ok
            </Link>
          </div>
          <div className="text-center mt-30 subscription-confirmation-modal-footer" hidden={roleType === UserRole.DOCTOR}>
            <Link
              to={'/'}
              className="btn btn-primary w-100 mb-15"
              color="secondary">Ok
            </Link>
            <Link to={RouteConstants.PRIVACY_POLICY}
              className="w-100 text-decoration theme-color"
              color="secondary">See Terms of Agreement
            </Link>
          </div>
        </div>
      </div>
    </ModalBody>
  </Modal>
);
};

export default PurchaseSubscriptionConfirmation;
