import axiosInstance from '../../../../../../axios/axiosInstance';
import ApiConstant from '../../../../../../constants/ApiConstant';
import { InitialPageLimit, ApplicationConstant } from '../../../../../../constants/application.constant';

export const getDoctorHistoryList = (userId, appointemntStatusId, page, sortKey) =>{
  return (
      axiosInstance.get(`${ApiConstant.DOCTOR_VIEW_PATIENT_APPOINTMENT_HISTORY}/${userId}/${appointemntStatusId}?page=${
      page}&limit=${InitialPageLimit.PATIENT_DOCUMENT}${
      sortKey?.sortBy && `&sortBy=${sortKey?.sortBy}&order=${sortKey?.order}`}`)
  )
}

export const resetDoctorHistoryScrollPosition = (scrollRef) => {
  return scrollRef.current.scrollTop = ApplicationConstant.ZERO;
}

export const AllAppointmentsTypes = [
  {id: 1, label: "All Appointments", value: "All Appointments"},
  {id: 2, label: "Pending Appointments", value: "Pending Appointments"},
  {id: 3, label: "Upcoming Appointments", value: "Upcoming Appointments"},
  {id: 4, label: "Canceled Appointments", value: "Canceled Appointments"},
  {id: 5, label: "Completed Appointments", value: "Completed Appointments",},
]
