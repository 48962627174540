import { RegExConstant } from '../../../constants/application.constant';

export const validatePassword = (oPasswordState) => {
  const oldPassword = oPasswordState.old_password || '';
  const newPassword = oPasswordState.new_password || '';
  const confirmPassword = oPasswordState.confirm_password || '';
  if (oldPassword && RegExConstant.PASSWORD_REGEX.test(oldPassword) &&
    newPassword && RegExConstant.PASSWORD_REGEX.test(newPassword) && newPassword === confirmPassword) {
    return true;
  }
  return false;
};

export const isValidConfirmPassword = (oPasswordState) => {
  const newPassword = oPasswordState.new_password || '';
  const confirmPassword = oPasswordState.confirm_password || '';
  if (confirmPassword && newPassword !== confirmPassword) {
    return false;
  }
  return true;
};
