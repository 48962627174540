import React, { useContext, useState } from 'react';
import { ApplicationConstant, BasePathConstant } from '../../../../constants/application.constant';
import { Image } from '../../../common/image/Image';
import ReactFileUploader from '../../../common/fileUpload/ReactFileUploader';
import update from 'immutability-helper';
import UserProfileContext from '../../../../contexts/user-profile/UserProfileContext';
import { setProfileImage } from '../../../../utils/helper';


function DoctorProfileImage(props) {
    const {profileData, setValueByFieldName, intialProfileData, updateProfileImage} = props;
    const [fileUploaded, isFileUploaded] = useState(false);
    const [userGenericData, setUserGenericData] = useContext(UserProfileContext);


    const onFileUploadSucess = (url) => {
        const updatedImagePayload = {...intialProfileData, profile_image: {value: url}};
        setValueByFieldName('profile_image', {value: url});
        isFileUploaded(true);
        setProfileImage(url);
        setUserGenericData(update(userGenericData, { 'profile_image': { $set: url } }));
        updateProfileImage(updatedImagePayload);
    };

    const onFileRemove = () => {
        const updatedImagePayload = {...intialProfileData, profile_image: {value: ''}};
        setValueByFieldName('profile_image', {value: ''});
        isFileUploaded(false);
        setProfileImage(ApplicationConstant.NO_VALUE_STRING);
        setUserGenericData(update(userGenericData, { 'profile_image': { $set: ApplicationConstant.NO_VALUE_STRING } }));
        updateProfileImage(updatedImagePayload);
    };

    return (
        <div>
            <div className="upload-profile-image">
                <div className="profile-image">
                    <Image src={profileData?.profile_image?.value || ''} alt="Profile image" isLoading={true} />
                </div>
                    {profileData?.profile_image.value  && !fileUploaded ? <span class="material-icons btn-close" onClick={onFileRemove}>close</span> : ''}
                <div className="upload-action">
                    <h3 className="mb-10">Your Profile Picture</h3>
                    <ReactFileUploader
                        id={'profile_image'}
                        onSuccess={onFileUploadSucess}
                        onRemove={onFileRemove}
                        allowedExtensions={['jpg', 'jpeg', 'png']}
                        allowedSize={10000000}
                        allowedTypeMsg={'.jpg, .jpeg, and .png are allowed.'}
                        basePath={BasePathConstant.DOCTOR_PROFILE_IMAGE}
                        dataLabel={profileData?.profile_image.value ? 'update' : 'upload'}
                        profileMode={true}
                    />
                </div>
              </div>
        </div>
    )
}

export default DoctorProfileImage;
