import React, { useState,useContext,useEffect } from 'react'
import { ApplicationConstant } from '../../../constants/application.constant';
import HospitalSignUpContext from '../../../contexts/hospital-signup/HospitalSignUpContext';
import InputElement from '../../common/formElements/InputElement';
import update from 'immutability-helper';
import SelectElement from '../../common/formElements/SelectElement';
import { getCitiesById, getStates } from '../../../utils/CommonRequests';
import { zipCodeValidation } from '../../../validators/CommonValidation';

function HospitalLocation() {
  const [hospitalSignUp, setHospitalSignUp] = useContext(HospitalSignUpContext);
  const [states, setStates] = useState([]);
  const [stateId, setStateId] = useState(null);
  const [cities, setCities] = useState([]);

  const setValueByFieldName = (fieldname, value) => {
    setHospitalSignUp(
      update(hospitalSignUp, {
        hospital_details: { [fieldname]: { $set: value } },
      })
    );
  };
  const setLocationValues = (prop, val) => {
    setHospitalSignUp(update(hospitalSignUp, { hospital_details: { [prop]: { $set: val } } }));
  };

  const setAddressValue = (e) => {
    e.preventDefault();
    setValueByFieldName(e.target.name, e.target.value);
  };

  const onStateInput = (e) => {
    setStateId(e.id);
    setLocationValues('state', e);
  };

  const onCityInput = (e) => {
    setLocationValues('city', e);
  };

  const setZipCode = (e) => {
    zipCodeValidation('zip_code', e.target.value, setLocationValues);
  };
  useEffect(() => {
    getStates().then((res) => {
      if (res.length > 0) {
        setStates(res.map((item) => ({ ...item, label: item.name, value: item.name.toLowerCase() })));
      }
    });
    if (!!stateId) {
      setLocationValues('city', '');
    }
  }, []);

  useEffect(() => {
    getCitiesById(stateId).then((res) => {
      if (res.length > 0) {
        setCities(res.map((item) => ({ ...item, label: item.name, value: item.name.toLowerCase() })));
      }
    });
    if (!!stateId) {
      setLocationValues('city', '');
    }
  }, [stateId]);
    return (
      <div className='row'>
        <InputElement
          className='col-sm-6'
          type='text'
          name='address1'
          value={hospitalSignUp.hospital_details.address1}
          onChange={setAddressValue}
          autoComplete={ApplicationConstant.OFF}
          placeholder={'Please enter street address'}
          label={'Street Address*'}
        />

        <InputElement
          className='col-sm-6'
          type='text'
          name='address2'
          value={hospitalSignUp.hospital_details.address2}
          onChange={setAddressValue}
          autoComplete={ApplicationConstant.OFF}
          placeholder={'Please enter address 2'}
          label={'Address 2'}
        />
        <SelectElement
          classNm={'col-sm-6'}
          name={'state_id'}
          onChange={onStateInput}
          className='reactSelect'
          classNamePrefix='react-select'
          options={states}
          value={hospitalSignUp.hospital_details.state}
          isSearchable={ApplicationConstant.TRUE}
          placeholder={''}
          label={'States*'}
        />
        <SelectElement
          classNm={'col-sm-6'}
          name={'city'}
          onChange={onCityInput}
          className='reactSelect'
          classNamePrefix='react-select'
          options={cities}
          value={hospitalSignUp.hospital_details.city}
          isSearchable={ApplicationConstant.TRUE}
          placeholder={''}
          label={'City*'}
        />

        <InputElement
          className='col-sm-12'
          type='number'
          name='zip_code'
          value={hospitalSignUp.hospital_details.zip_code.value}
          onChange={setZipCode}
          autoComplete={ApplicationConstant.OFF}
          placeholder={'Please enter zipcode'}
          label={'Zip Code*'}
          isValid={hospitalSignUp.hospital_details.zip_code.valid}
          msg={hospitalSignUp.hospital_details.zip_code.msg}
        />

      </div>
    )
}

export default HospitalLocation
