
import axiosInstance from '../../../../axios/axiosInstance';
import ApiConstant from '../../../../constants/ApiConstant';
import { ApplicationConstant } from '../../../../constants/application.constant';

export const getDoctorPaymentHistory = (paymentHistoryDate) => {
  return axiosInstance.get(`${ApiConstant.DOCTOR_PAYMENT_HISTORY}`,{
    params: { from_date: paymentHistoryDate.start, to_date:paymentHistoryDate.end},
  })
}

export const getDoctorPaymentHistoryList = (paymentHistoryDate, pageNumber, pageOffset) => {
  return axiosInstance.get(`${ApiConstant.DOCTOR_YEARLY_PAYMENT_HISTORY}`,{
    params: { from_date: paymentHistoryDate.start, to_date:paymentHistoryDate.end, page:pageNumber, limit:pageOffset },
  })
}

export const getDoctorPaymentDetailList = (paymentHistoryListDate, sortObj, pageNumber, pageOffset) => {
  return axiosInstance.get(`${ApiConstant.DOCTOR_MONTHLY_PAYMENT_HISTORY}`,{
    params: { date:paymentHistoryListDate, sortBy:sortObj?.sortBy, order:sortObj?.order, page:pageNumber, limit:pageOffset },
  })
}

export const exportDoctorPaymentHistory = (paymentID) => {
  return axiosInstance.get(`${ApiConstant.DOCTOR_PAYMENT_HISTORY_EXPORT}`,{
    params: { payment_id:paymentID, type:'pdf' },
    responseType : 'blob'
  })
}

export const exportDoctorMultipleMonthsPaymentHistory = (payload) => {
  return axiosInstance.post(`${ApiConstant.DOCTOR_MULTISELECT_PAYMENT_HISTORY_EXPORT}`,payload,
  {responseType : 'blob'})
}


export const EmptyPaymentHistory = (props) => {
  const { isFilterApplied } = props;
  return (
    <div className="data-not-found">
      <div className="content">
        <div className="icon-bg">
          <span className="material-icons">credit_card</span>
        </div>
        <h2>{isFilterApplied ? 'No result found.' : 'You have not made any payment yet'}</h2>
      </div>
    </div>
  )
}

export const filterValidation = (dateObj) => {
  if (!dateObj.start || !dateObj.end || dateObj.end < dateObj.start) {
    return true;
  }
}

export const handleSelectAll = (list, setSelectAll) => {
  const checkedList = list.filter(e => e.checked);
  if (checkedList.length <= ApplicationConstant.ZERO) {
    setSelectAll(false);
  } else if(checkedList.length === list.length) {
    setSelectAll(true);
  }
}

export const exportPatientPaymentHistory = (paymentID) => {
  return axiosInstance.get(`${ApiConstant.PATIENT_PAYMENT_HISTORY_EXPORT}`,{
    params: { payment_id:paymentID, type:'pdf' },
    responseType : 'blob'
  })
}