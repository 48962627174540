import React,{useState} from 'react'
import { SessionDocumentTabTitle } from '../../../../../../constants/application.constant';
import TabBody from './TabBody';
import DoctorTabHead from './DoctorTabHead';

function DoctorAppointmentTabContainer(props) {
  const {item} = props;
  const [activeTab, setActiveTab] = useState(SessionDocumentTabTitle.NOTES);
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <div className="tab-section">
      <DoctorTabHead activeTab={activeTab} tabToggle={toggle} />
      <TabBody
        activeTab={activeTab}
        oMeeting={item}
        appointmentId={item.appointment_id}
        userId={item.user_id}
      />
    </div>
  );
}

export default DoctorAppointmentTabContainer;

