import React from 'react';
import { Input, InputGroup } from 'reactstrap';

function InputElement({className,max, type, name, onChange=()=>{}, onBlur=()=>{},
                        value="", isDisabled=false, label="",
                        placeholder="", isValid=true, msg, min ,height, ...props}) {
    return (
        <div className={className} >
            <InputGroup>
              <div className="label-float">
                <Input
                    className={!isValid ? 'invalid' : ''}
                    type={type}
                    id={name}
                    name={name}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    disabled={isDisabled}
                    autoComplete={props.autocomplete || 'off'}
                    placeholder={placeholder}
                    max={max}
                    min={min}
                    style={{height: height + 'px'}}
                    onKeyDown={props.onKeyDown}
                    onKeyPress={props.onKeyPress}
                    onKeyUp={props.onKeyUp}
                    {...props}
                    />
                <label>{label}</label>
              </div>
              {isValid?<></>:<label className="font-12 red-text-color2">{msg}</label>}
            </InputGroup>
        </div>
    )
}

export default InputElement;
