import { useState, useEffect } from 'react';
import validate from './SignInValidator';

const SignInUseForm = (callback) => {

    const [values, setValues] = useState({});

    useEffect(() => {
        const errors = validate(values);
        if (Object.keys(errors).length === 0) {
            callback(true);
        } else {
            callback(false);
        }
    }, [values]);

    const handleChange = (event) => {
        event.persist();
        setValues(values => ({ ...values, [event.target.name]: event.target.value }));
    };
    return {
        handleChange,
        values
    }
};

export default SignInUseForm;
