import React, { Fragment, useEffect, useState } from 'react';
import axiosInstance from '../../../axios/axiosInstance';
import { PageTitles } from '../../../constants/application.constant';
import ApiConstant from '../../../constants/ApiConstant';
import useDocumentTitle from '../../../utils/DocumentTitle';
import SpinnerComponent from '../../common/spinner/SpinnerComponent';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import PageLogo from '../../common/logo/PageLogo';
import { Button } from 'reactstrap';
import update from 'immutability-helper';

function VerifyEmail() {
  const history = useHistory();
  const oState = { isSuccess: false, message: '' };
  const [isLoading, setLoading] = useState(false);
  const [oEmailState, setEmailState] = useState(oState);
  const query = new URLSearchParams(useLocation().search);
  useDocumentTitle(PageTitles.VERIFY_EMAIL);

  useEffect(() => {
    setLoading(true);
    const verifyToken = query.get('token') || '';
    const oPayload = {
      token: verifyToken,
    };
    axiosInstance.post(ApiConstant.VERIFY_EMAIL, oPayload).then(
      (res) => {
        setLoading(false);
        updateState(true, res?.data?.message);
      },
      (error) => {
        setLoading(false);
        updateState(false, error);
      }
    );
  }, []);

  const updateState = (isSucceeded, responseMessage) => {
    setEmailState(update(oEmailState, { isSuccess: { $set: isSucceeded }, message: { $set: responseMessage } }));
  };

  const handleClick = (e) => {
    e.preventDefault();
    history.push('/');
  };

  return (
    <Fragment>
      {isLoading && <SpinnerComponent />}
      {!isLoading && (
        <div className="full-bg-color">
          <div className="landing-ui">
            <div className="emal-sent">
              <form className="w-100">
                <div className="w-350">
                  <div className="login-title">
                    <PageLogo />
                  </div>
                  {oEmailState?.isSuccess ? (
                    <div>
                      <div className="check-box">
                        <span className="material-icons">done</span>
                      </div>
                      <h1 className="font-600 black4a">Account verified successfully!</h1>
                      <h2>{oEmailState?.message}</h2>
                    </div>
                  ) : (
                    <div>
                      <div className="check-box">
                        <span className="material-icons-round reverse">priority_high</span>
                      </div>
                      <h1 className="font-600 black4a">Account could not be verified.</h1>
                      <h2>{oEmailState?.message}</h2>
                    </div>
                  )}
                  <div className="col-sm-12 mt-30">
                    <Button className="w-100 mb-20" color="primary" onClick={handleClick}>
                      Back To Login
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}

export default VerifyEmail;
