import React from "react";
import '~/../../src/assets/scss/_dashboard.scss';
import { Button, FormGroup, Label, Input, InputGroup, Table  } from 'reactstrap';
import { PRODUCTIMG, PROFILEIMG } from '../../../constants/image';
const HospitalDashboard = () => {
  return (
    <div className="dashboard-page">
      <div className="container">        
        <section className="detail-info">
          <div className="welcome">
            <h1>Welcome to the dashboard!</h1>
          </div>
          
          <div className="profile-info">
            <div className="row">
              <div className="col-sm-12 col-md-8">
                <div className="box">
                  
                  <div className="user-info">
                    <div className="profile-img active">
                      <img src={PROFILEIMG}></img>
                    </div>
                    <div className="head">
                      <div className="title-text">
                        <h1 className="mt-20">AmerisourceBergen</h1>
                        <h2><span className="ml-0">Available</span></h2>
                      </div>
                      <div className="action-status">
                      <a class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8"><span class="material-icons md-dark md-18">mail</span></a>
                        <a class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8"><span class="material-icons md-dark md-18">mode_edit</span></a>
                        <a class="btn-white-outline">View Profile</a>
                      </div>
                    </div>
                  </div>
                  <div className="profile-info">
                    <div className="row">
                      <div className="col-md-4 col-sm-6">
                        <label>Tax ID</label>
                        <div className="value">813-422-2378</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Date of Business Creation</label>
                        <div className="value">Sep 13, 1995</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>City</label>
                        <div className="value">San Diego</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>State</label>
                        <div className="value">California</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>NPI Number</label>
                        <div className="value">103270160</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>NPI Type</label>
                        <div className="value">Individual</div>
                      </div>
                      <div className="col-sm-12">
                        <label>Specialization</label>
                        <div className="value">Andrologist, Cardiac Electrophysiologist, Epidemiologist, Hyperbaric Physician</div>
                      </div>
                     
                    </div>
                    <h4>Member Since: <span>Jan 21, 2020</span></h4>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-4">
                <div className="box">
                  <div className="head">
                      <div className="title"> 
                        Recent Activity
                      </div>
                  </div>
                  <div className="activity">
                    <ul>
                      <li><span class="material-icons">account_circle</span><span className="name">Updated Doctor Profile </span></li>
                      <li><span class="material-icons">account_circle </span><span className="name">New Doctor Added </span></li>
                      <li><span class="material-icons">error</span><span className="name">Error in intake  </span></li>
                    </ul>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          
        </section>
        

        <section className="providers-section">
          <div className="heading-link">
            <h5>Our Providers</h5>
            <Button className="small" color="primary">Add Provider</Button>
          </div>
          <div className="table-section">
            <div className="heading">
              <Table responsive hover>
                <thead>
                  <tr>
                    <th>
                      <div className="check-btn m-0">
                        <label className="label--checkbox flat">
                          <input type="checkbox" className="checkbox" id="1" />
                          <label className="font-16 font-500" for="1"></label>
                        </label>
                      </div>  
                    </th>
                    <th><div className="data-name"><strong>First Name </strong><span class="material-icons">unfold_more</span></div></th>
                    <th><div className="data-name"><strong>Last Name </strong><span class="material-icons">unfold_more</span></div></th>
                    <th><div className="data-name"><strong>License </strong><span class="material-icons">unfold_more</span></div></th>
                    <th><div className="data-name"><strong>NPI Number </strong><span class="material-icons">unfold_more</span></div></th>
                    <th><div className="data-name"><strong>State </strong><span class="material-icons">unfold_more</span></div></th>
                    <th><div className="data-name"><strong>Email Address </strong><span class="material-icons">unfold_more</span></div></th>
                    <th><div className="data-name"><strong>Phone Number </strong><span class="material-icons">unfold_more</span></div></th>
                    <th><div className="data-name"><strong>Medical School </strong><span class="material-icons">unfold_more</span></div></th>
                    <th><div className="data-name"><strong>Status </strong><span class="material-icons">unfold_more</span></div></th>
                  </tr>
                </thead>
                </Table>
              </div>
            <div className="box">
              <Table responsive hover>
                <tbody>
                  <tr>
                    <th scope="row">
                      <div className="check-btn m-0">
                        <label className="label--checkbox flat">
                          <input type="checkbox" className="checkbox" id="1" />
                          <label className="font-16 font-500" for="1"></label>
                        </label>
                      </div>       
                    </th>
                    <td>Rodney</td>
                    <td>Fuller</td>
                    <td>192307</td>
                    <td>103270160</td>
                    <td>California</td>
                    <td>rodney-fuller@gmail.com</td>
                    <td>(778) 704-1569</td>
                    <td>Harvard Medical School</td>
                    <td>Inactive</td>
                  </tr>
                  <tr className="active">
                    <th scope="row">
                      <div className="check-btn m-0">
                        <label className="label--checkbox flat">
                          <input type="checkbox" className="checkbox" id="1" />
                          <label className="font-16 font-500" for="1"></label>
                        </label>
                      </div>       
                    </th>
                    <td>Rodney</td>
                    <td>Fuller</td>
                    <td>192307</td>
                    <td>103270160</td>
                    <td>California</td>
                    <td>rodney-fuller@gmail.com</td>
                    <td>(778) 704-1569</td>
                    <td>Harvard Medical School</td>
                    <td>Inactive</td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <div className="check-btn m-0">
                        <label className="label--checkbox flat">
                          <input type="checkbox" className="checkbox" id="1" />
                          <label className="font-16 font-500" for="1"></label>
                        </label>
                      </div>       
                    </th>
                    <td>Rodney</td>
                    <td>Fuller</td>
                    <td>192307</td>
                    <td>103270160</td>
                    <td>California</td>
                    <td>rodney-fuller@gmail.com</td>
                    <td>(778) 704-1569</td>
                    <td>Harvard Medical School</td>
                    <td>Inactive</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </section>
      </div>
    </div>

    )
}

export default HospitalDashboard; 