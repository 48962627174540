import axiosInstance from '../axios/axiosInstance';
import ApiConstant from '../constants/ApiConstant';
import { ApplicationConstant } from '../constants/application.constant';
import { generatePassword, getFormattedDate } from './helper';

export const getAPIData = async (endPoint) => {
  let data = [];
  try {
    if (endPoint) {
      const oResponse = await axiosInstance.get(endPoint);
      data =
        oResponse && oResponse.data && oResponse.data.data
          ? oResponse.data.data
          : [];
    }
  } catch (e) {
    data = [];
  }
  return data;
};

export const saveDoctorAccountCreationData = async (
  oAccountDetailsData,
  labelInfo,
  isHospitalFlow = ApplicationConstant.FALSE,
  filename
) => {
  let response = null;
  let dob = getFormattedDate(oAccountDetailsData.date_of_birth.value);
  let expDate = oAccountDetailsData.expiration_date.value ? getFormattedDate(oAccountDetailsData.expiration_date.value) : null;
  let url = isHospitalFlow
    ? ApiConstant.HOSPITAL_ADD_INDIVIDUAL_PROVIDER
    : ApiConstant.DOCTOR_SIGNUP;
  const oPayload = {
    first_name: oAccountDetailsData.first_name.value,
    last_name: oAccountDetailsData.last_name.value,
    date_of_birth: dob,
    preferred_name: oAccountDetailsData.preferred_name.value,
    phone: oAccountDetailsData.phone.value,
    email: oAccountDetailsData.email.value,
    state_licenses: oAccountDetailsData.state.map((o) => o.id),
    doctor_pratice: oAccountDetailsData.practice,
    password: isHospitalFlow
      ? generatePassword()
      : oAccountDetailsData.password.value,
    npi_number: oAccountDetailsData.npi_number.value,
    npi_type: oAccountDetailsData.npi_type.id,
    license_number: oAccountDetailsData.license_number.value,
    expiration_date: expDate,
    prescription_availability: oAccountDetailsData.prescription_availability,
    see_patients: oAccountDetailsData.see_patients,
    own_insurances: oAccountDetailsData.own_insurance,
    accept_insurance: oAccountDetailsData.accept_insurance,
    electronic_medical_record: oAccountDetailsData.electronic_medical_record,
    location: {
      state_id: oAccountDetailsData.location.state.id,
      city_id: oAccountDetailsData.location.city.id,
      address1: oAccountDetailsData.location.address1,
      address2: oAccountDetailsData.location.address2,
      zip_code: oAccountDetailsData.location.zip_code.value,
    },
  };
  if (!!oAccountDetailsData.document) {
    oPayload.document = oAccountDetailsData.document;
    oPayload.document_name = filename;
  }

  if (!!oAccountDetailsData.doctor_background_questions && !isHospitalFlow) {
    oPayload.doctor_background_information = [];
    labelInfo.questions.forEach((i) => {
      oPayload.doctor_background_information.push({
        background_information_id: i.id,
        option: oAccountDetailsData.doctor_background_questions[i.id],
        description:
          oAccountDetailsData.doctor_background_questions[`comment_${i.id}`],
      });
    });
  }

  if (oAccountDetailsData?.medical_school?.id) {
    oPayload.medical_school_id = oAccountDetailsData.medical_school.id;
  }
  if (oAccountDetailsData?.other_medical_school) {
    oPayload.other_medical_school = oAccountDetailsData.other_medical_school;
  }

  if (oAccountDetailsData?.year_of_experience?.value) {
    oPayload.year_of_exp = oAccountDetailsData.year_of_experience.value;
  }

  if (
    oAccountDetailsData?.doctor_certifications?.length >
    ApplicationConstant.ZERO
  ) {
    oPayload.doctor_certifications =
      oAccountDetailsData.doctor_certifications.map((o) => {
        if (o.name.toLowerCase() === ApplicationConstant.OTHER_LOWER_CASE) {
          return {
            id: o.id,
            other_certification: oAccountDetailsData.other_certification,
          };
        } else {
          return { id: o.id, other_certification: "" };
        }
      });
  }

  if (
    oAccountDetailsData?.insurance_provider?.length > ApplicationConstant.ZERO
  ) {
    oPayload.doctor_insurance_provider =
      oAccountDetailsData.insurance_provider.map((o) => {
        if (o.name.toLowerCase() === ApplicationConstant.OTHER_LOWER_CASE) {
          return {
            id: o.id,
            name: o.name,
            other_insurance_provider:
              oAccountDetailsData.other_insurance_provider,
          };
        } else {
          return { id: o.id, name: o.name, other_insurance_provider: "" };
        }
      });
  }

  if (oAccountDetailsData?.procedure?.length > ApplicationConstant.ZERO) {
    oPayload.doctor_procedure = oAccountDetailsData.procedure.map((o) => o.id);
  }

  if (
    oAccountDetailsData?.condition_treated?.length > ApplicationConstant.ZERO
  ) {
    oPayload.doctor_condition_treated =
      oAccountDetailsData.condition_treated.map((o) => o.id);
  }

  if (
    oAccountDetailsData?.doctor_specializations?.length >
    ApplicationConstant.ZERO
  ) {
    oPayload.doctor_specializations =
      oAccountDetailsData.doctor_specializations.map((o) => o.id);
  }

  try {
    return axiosInstance.post(url, oPayload);
  } catch (e) {
    console.log(e);
  }
  return response;
};
