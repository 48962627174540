
const PatientProfileStepper = (props) => {
  const { profileData, toggleSubscriptionModal } = props;
  return (
    <>
      <div className="welcome">
        <h1>
          Welcome {profileData?.first_name|| '-'}! <span> Health Profile</span>

        </h1>
      </div>
      <div className="profile-links">
        <ul>
          <li>
            <a className={profileData?.health_profile ? 'active' : ''}>
              <div className="profile-status">
                <span className="material-icons">done</span>
              </div>
              <span>Health Profile</span>
            </a>
          </li>
          <li>
            <a className={profileData.add_an_image ? 'active' : ''}>
              <div className="profile-status">
                <span className="material-icons">done</span>
              </div>
              <span>Add an Image</span>
            </a>
          </li>
          <li className="subscription-link-tab">
            {profileData?.become_a_member ?
              <a className="active">
                <div className="profile-status"><span className="material-icons">done</span></div>
                <span>Become a Member!</span>
              </a>
            :
              <div className="link-icon" onClick={toggleSubscriptionModal}>
                <div className="profile-status"><span className="material-icons">done</span></div>
                <span>Become a Member!</span>
              </div>
            }
          </li>
          <li>
            <a className={profileData.first_doc_session ? 'active' : ''}>
              <div className="profile-status">
                <span className="material-icons">done</span>
              </div>
              <span>Have your first Provider Session</span>
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};
export default PatientProfileStepper;
