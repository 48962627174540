import React from 'react';
import { NPITypes, profileKey, targetedId } from '../../../../constants/application.constant';
import { getNPIType } from '../../../../utils/helper';
import ProfileTooltip from '../../../common/profileElements/ProfileTooltip';

function PatientViewDoctorProfileProfesional(props) {
  const { doctorData } = props;
  return (
    <>
      <div className="profile-info">
        <h3 className="mt-10 mb-18">Professional Information</h3>
        <div className="row">
          <div className="col-md-4 col-sm-6">
            <label>NPI Number</label>
            <div className="value">
              {doctorData?.npi_number ? <span class="material-icons check-icon">check_circle</span> : '-'}
            </div>
          </div>
          <div className="col-md-4 col-sm-6 d-none">
            <label>NPI Type</label>
            <div className="value">{getNPIType(doctorData?.npi_type, NPITypes)}</div>
          </div>
          <div className="col-md-4 col-sm-6">
            <label>License Number</label>
            <div className="value">
              {doctorData?.license_number ? <span class="material-icons check-icon">check_circle</span> : '-'}
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <label>{'State(s) of license'}</label>
            <div className="value more-items">
              {doctorData?.state_licenses?.length
                ? ProfileTooltip(doctorData.state_licenses, targetedId.DOCTOR, profileKey.NAME, profileKey.STATE)
                : '-'}
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <label>Prescription Availability</label>
            <div className="value">
              {doctorData?.prescription_availability ? (
                <span class="material-icons check-icon">check_circle</span>
              ) : (
                '-'
              )}
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <label>Private Practice</label>
            <div className="value">
              {doctorData?.see_patients ? <span class="material-icons check-icon">check_circle</span> : '-'}
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <label>Malpractice Insurance</label>
            <div className="value">
              {doctorData?.own_insurances ? <span class="material-icons check-icon">check_circle</span> : '-'}
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <label>Accept Insurance</label>
            <div className="value">
              {doctorData?.accept_insurance ? <span class="material-icons check-icon">check_circle</span> : '-'}
            </div>
          </div>
          <div className="col-md-4 col-sm-6">
            <label>Electronic Records</label>
            <div className="value">
              {doctorData?.electronic_medical_record ? (
                <span class="material-icons check-icon">check_circle</span>
              ) : (
                '-'
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PatientViewDoctorProfileProfesional;
