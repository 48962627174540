import React, {useState} from 'react';
import { Collapse, CardBody, Card, CardHeader} from 'reactstrap';

function Faq() {
  const [toggleQuestion, setToggequestion] = useState(1);//1 is the default id to be opened by default
  return (
    <div>
      <div className="middle-content faqs">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="back-link">
                <h1>FAQs</h1>
                
              </div>
            </div>
            <div className="col-sm-4">
              <div className="edit-nav">
                <ul>
                  <li><span className="name">Memberships and Subscriptions</span></li>
                  <li className="active"><span className="name">Aenean ac nunc eu</span></li>
                  <li><span className="name">Egestas neque vel</span></li>
                  <li><span className="name">Ullam faucibus ligula</span></li>
                  <li><span className="name">Mauris sed mauris</span></li>
                  <li><span className="name">Aliquam nec dolor</span></li>
                </ul>
              </div>
            </div>
            <div className="col-sm-8">
              <div className="pages">
                <div className="accordion">
                  <Card>
                    <CardHeader onClick={() => setToggequestion(1)}>
                      <strong>Nulla vitae odio sit amet ex convallis iaculis eu vitae mi?</strong>
                      <span class="material-icons md-20">
                        {toggleQuestion === 1 ? "expand_less" : "expand_more"}
                      </span>
                    </CardHeader>
                    <Collapse  isOpen={toggleQuestion === 1 ? true : false}>
                      <CardBody>
                        <p>
                          There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.
                        </p>
                        {/* <RadioToggleElement
                          classNm={'col-sm-12'}
                          label={'Was this question helpful?'}
                          name={'Was this question helpful?'}
                        /> */}
                      </CardBody>
                  </Collapse>
                  </Card>

                  <Card>
                    <CardHeader onClick={() => setToggequestion(2)}>
                        <strong>Pellentesque interdum ex quis tortor rutrum, eget congue est dapibus?</strong>
                        <span class="material-icons md-20">
                          {toggleQuestion === 1 ? "expand_less" : "expand_more"}
                        </span>
                    </CardHeader>
                    <Collapse  isOpen={toggleQuestion === 2 ? true : false}>
                        <CardBody>
                              <p>
                              There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.
                            </p>
                        </CardBody>
                    </Collapse>
                  </Card>
                  <Card>
                    <CardHeader onClick={() => setToggequestion(3)}>
                        <strong>Suspendisse posuere ex dapibus, laoreet metus at, hendrerit mi?</strong>
                        <span class="material-icons md-30">
                          {toggleQuestion === 1 ? "expand_less" : "expand_more"}
                        </span>
                    </CardHeader>
                    <Collapse  isOpen={toggleQuestion === 3 ? true : false}>
                        <CardBody>
                              <p>
                              There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.
                            </p>
                        </CardBody>
                    </Collapse>
                  </Card>
                  <Card>
                    <CardHeader onClick={() => setToggequestion(4)}>
                        <strong>Pellentesque interdum ex quis tortor rutrum, eget congue est dapibus?</strong>
                        <span class="material-icons md-20">
                          {toggleQuestion === 1 ? "expand_less" : "expand_more"}
                        </span>
                    </CardHeader>
                    <Collapse  isOpen={toggleQuestion === 4 ? true : false}>
                        <CardBody>
                              <p>
                              There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.
                            </p>
                        </CardBody>
                    </Collapse>
                  </Card>
                  <Card>
                    <CardHeader onClick={() => setToggequestion(5)}>
                        <strong>Quisque nec metus congue, placerat odio quis, iaculis odio?</strong>
                        <span class="material-icons md-20">
                          {toggleQuestion === 1 ? "expand_less" : "expand_more"}
                        </span>
                    </CardHeader>
                    <Collapse  isOpen={toggleQuestion === 5 ? true : false}>
                        <CardBody>
                              <p>
                              There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.
                            </p>
                        </CardBody>
                    </Collapse>
                  </Card>
                  <Card>
                    <CardHeader onClick={() => setToggequestion(6)}>
                        <strong>Cras sed mauris at dui vestibulum congue nec at tortor?</strong>
                        <span class="material-icons md-20">
                          {toggleQuestion === 1 ? "expand_less" : "expand_more"}
                        </span>
                    </CardHeader>
                    <Collapse  isOpen={toggleQuestion === 6 ? true : false}>
                        <CardBody>
                              <p>
                              There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.
                            </p>
                        </CardBody>
                    </Collapse>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Faq
