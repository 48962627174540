import React, { useState, useEffect } from 'react';
import { ToastContainer, toast, Slide } from 'react-toastify';
import axiosInstance from '../../../../axios/axiosInstance';
import ApiConstant from '../../../../constants/ApiConstant';
import useDocumentTitle from '../../../../utils/DocumentTitle';
import { PageTitles } from '../../../../constants/application.constant';
import PatientProfile from './PatientProfile';
import SpinnerComponent from '../../../common/spinner/SpinnerComponent';

const PatientProfileView = () => {
  useDocumentTitle(PageTitles.PATIENT_PROFILE);

  const [profileData, setProfileData] = useState({});
  const [isLoading, setLoader] = useState(false);

  const fetchPatientProfile = async () => {
    setLoader(true);
    await axiosInstance
      .get(`${ApiConstant.PATIENT_DASHBOARD}`)
      .then((response) => {
        if (response && response.data) {
          const patientProfileData = response.data.data || {};
          setProfileData(patientProfileData);
        }
      })
      .catch((error) => {
        toast.error(error || '');
      });
    setLoader(false);
  };

  useEffect(() => {
    fetchPatientProfile();
  }, []);

  return (
    <>
    {isLoading && <SpinnerComponent />}
      <div className="middle-content view-profile">
        <div className="container">
          <PatientProfile profileInfo={profileData} />
        </div>
      </div>
      <ToastContainer autoClose={4000} hideProgressBar transition={Slide} />
    </>
  );
};
export default PatientProfileView;
