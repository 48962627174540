import React, { useState } from 'react';
import InputElement from '../../common/formElements/InputElement';
import SelectElement from '../../common/formElements/SelectElement';
import FormRadioButton from '../../common/formElements/FormRadioButton';
import FormCheckBox from '../../common/formElements/FormCheckBox';

import FormButton from './FormButton';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ProfileEditModal = (props) => {
  const {
    buttonLabel,
    className,
    modalClassName
  } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div>
      <span class="material-icons md-20 cursor-pointer" onClick={toggle}>{buttonLabel}</span>
      <Modal isOpen={modal} toggle={toggle} className={className} modalClassName={modalClassName}>
        <ModalHeader><div>Edit Profile</div> <a onClick={toggle} class="btn-white-outline pr-10 pl-10 d-flex align-items-center "><span class="material-icons md-dark md-18">close</span></a></ModalHeader>
        <ModalBody>
          <div className="form-step">
            <form className="w-100">
            <h3>heading text</h3>
              <div className="row">
                <SelectElement
                  classNm={"col-sm-6"}
                  name={"medical_school_id"}                
                  className="reactSelect"
                  classNamePrefix="react-select"
                  placeholder={""}
                  label={'What is your role?'}
                />
                <FormCheckBox
                  key={' '}
                  currentItem={' '}
                  item={' '}
                  label={'checkbox label'}
                  handleSelect={' '}/>
                <FormRadioButton
                    key={' '}
                    keyId={' '}
                    label={'radio label'}
                    currentItem={' '}
                    item={' '}
                    handleSelect={' '}
                />
                <InputElement
                  className="col-sm-6"
                  type="text"
                  name="address1"
                  placeholder={'Please enter'}
                  label={'Preferred Name'}
                  value={"dasf"}
                />
                <InputElement
                  className="col-sm-6"
                  type="text"
                  name="address1"
                  placeholder={'Please enter'}
                  label={'First Name*'}
                />
                <InputElement
                  className="col-sm-6"
                  type="text"
                  name="address1"
                  placeholder={'Please enter'}
                  label={'Last Name*'}
                />
              
                <InputElement
                  className="col-sm-6"
                  type="text"
                  name="email"
                  placeholder={'Please enter'}
                  label={'Email Address*'}
                />
              <InputElement
                  className="col-sm-6"
                  type="number"
                  name="address1"
                  placeholder={'Please enter'}
                  label={'Phone Number*'}
                />
                <SelectElement
                  classNm={"col-sm-12"}
                  name={"medical_school_id"}                
                  className="reactSelect"
                  classNamePrefix="react-select"
                  placeholder={""}
                  label={'Please select the type of question you have to help us direct it to the right place.'}
                />
                <InputElement
                  className="col-sm-12 pt-10"
                  type="textarea"
                  height="100"
                  placeholder={'Please enter'}
                  label={'Please add your question here'}
                />
                <div className="col-sm-12">
                  <div className="btn-group justify-content-end w-100 pt-10">
                    <FormButton buttonLabel = "CANCEL" buttonClassName="btn mr-15 btn-secondary w-120" />
                    <FormButton buttonLabel = "SUBMIT" buttonClassName="btn btn-primary w-120" />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </ModalBody>       
      </Modal>
    </div>
  );
}

export default ProfileEditModal;