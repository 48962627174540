import React, { useState, useEffect, useContext } from 'react';
import { Collapse } from 'reactstrap';
import { ApplicationConstant } from '../../../../../constants/application.constant';
import { setArrayRange } from '../../../../../utils/helper';
import axiosInstance from '../../../../../axios/axiosInstance';
import ApiConstant from '../../../../../constants/ApiConstant';
import SelectElement from '../../../../common/formElements/SelectElement';
import DoctorSchedulerContext from '../../../../../contexts/doctor-scheduler/DoctorSchedulerContext';
import { returnFilteredArray, FilterTypes, getCurrentTimeZone, scheduledTypeTime } from './../ScheduleAvailabilityUtils';

const Filter = (props) => {
    const { filterSettings, displayWorkingDays, changeScheduleType } = props;
    const [isScheduleOpen, setScheduleOpen] = useState(true);
    const [isDaysOpen, setDaysOpen] = useState(true);
    const [isRepeatOpen, setRepeatOpen] = useState(true);
    const [isNotificationsOpen, setNotificationsOpen] = useState(true);
    const [dayList, setDayList] = useState([]);
    const [timeZoneList, setTimeZoneList] = useState([]);
    const [currentTimeZone, setCurrentTimeZone] = useState([]);
    const [scheduleType, setScheduleType] = useState(
        (filterSettings?.schedule_type === ApplicationConstant.ONE ? ApplicationConstant.ONE : ApplicationConstant.ZERO) || ApplicationConstant.ZERO);
    const [notificationType, setNotificationType] = useState({
        emd_notification: filterSettings.emd_notification || ApplicationConstant.ZERO,
        email_notification: filterSettings.email_notification || ApplicationConstant.ZERO,
        sms_notification: filterSettings.sms_notification || ApplicationConstant.ZERO,
    });
    const [repeatDay, setRepeatDay] = useState(filterSettings.repeat_day || ApplicationConstant.ONE);
    const [recurringType, setRecurringType] = useState(filterSettings.recurring_type || ApplicationConstant.THREE);
    const [selectedFilter, setSelectedFilter] = useContext(DoctorSchedulerContext);
    const isFilterSetting = Object.keys(filterSettings).length;

    const fetchTimeZoneList = async () => {
        await axiosInstance.get(`${ApiConstant.TIME_ZONE}`).then((response) => {
            if (response && response.data && response.data.data) {
                const timeZone = response.data.data.map((item)=>({...item,label:item.time_zone_name,value: item.offset,}));
                setTimeZoneList(timeZone);
                setDefaultTimeZone(timeZone);
                fetchInitialFieldValue(timeZone);
            }
        });
    };

    const fetchWorkingDayList = async () => {
        await axiosInstance.get(`${ApiConstant.WORKING_DAYS}`).then((response) => {
            if(response && response.data) {
                const workingDayList = response.data.data || {};
                fetchCustomDays(workingDayList)
            }
        })
    };

    const updateSelectedFilter = (fieldName, id) => {
        setSelectedFilter(prev => ({ ...prev, [fieldName]: id}))
    }

    const fetchCheckedDay = (days) => {
        const checkedIds = days.filter(e => e.checked);
        updateSelectedFilter(FilterTypes.WORKING_DAYS, checkedIds.map(e => e.id));
    }

    const fetchCustomDays = (days) => {
        if(isFilterSetting) {
            const selectedWorkingDays = filterSettings.working_days.map((item) => ({id:item.day_of_week_id, name: item.name, checked: true}));
            setDayList(returnFilteredArray(selectedWorkingDays, days));
            displayWorkingDays(returnFilteredArray(selectedWorkingDays, days));
        } else {
            setDayList(days);
            displayWorkingDays(days);
        }
    }

    const setDefaultTimeZone = (timeZoneItems) => {
        const usersCurrentTimeZone = timeZoneItems.filter(e => e.abbreviation === getCurrentTimeZone());
        if (isFilterSetting) {
            const userSelectedTimezone = timeZoneItems.filter(e => e.id === filterSettings?.time_zone_id);
            setCurrentTimeZone(userSelectedTimezone);
        } else {
            setCurrentTimeZone(usersCurrentTimeZone);
        }
    }

    const onDayChange = (id)=>{
        const targetIdIndex = dayList.findIndex(e => e.id === parseInt(id));
        if(targetIdIndex > -1) {
            dayList[targetIdIndex].checked = !dayList[targetIdIndex].checked;
        }
        fetchCheckedDay(dayList);
        displayWorkingDays(dayList);
    }

    const onDayTypeChange = (id)=>{
        setRepeatDay(id);
        if(id === ApplicationConstant.THREE) {
            setDayList(dayList.map((item) => ({...item, checked: true})));
            setSelectedFilter(prev => ({ ...prev, working_days: setArrayRange(ApplicationConstant.ONE,ApplicationConstant.SEVEN)}))
            displayWorkingDays(dayList.map((item) => ({...item, checked: true})));
        }
        updateSelectedFilter(FilterTypes.REPEAT_DAY, id);
    }

    const onRecurringTypeChange = (id)=>{
        setRecurringType(id);
        updateSelectedFilter(FilterTypes.RECURRING_TYPE, id);
    }

    const onNotificationTypeChange = (e)=>{
        const name = e.target.name;
        const isChecked = e.target.checked;
        setNotificationType({...notificationType, [name]: isChecked});
        updateSelectedFilter(name, isChecked ? 1 : 0);
    }

    const onTimeZoneChange = (e) => {
        const timeZoneId = e.id;
        setCurrentTimeZone(e);
        updateSelectedFilter(FilterTypes.TIME_ZONE_ID, timeZoneId);
    }

    const onScheduleTypeChange = (e)=>{
        const name = e.target.name;
        const isChecked = e.target.checked;
        setScheduleType(isChecked ? 1 : 0);
        updateSelectedFilter(name, isChecked ? 1 : 2);
        changeScheduleType(isChecked ? scheduledTypeTime.STANDARD : scheduledTypeTime.DEFAULT);
    }

    const fetchInitialFieldValue = (timeZone) => {
        setSelectedFilter(prev => ({ ...prev, emd_notification: isFilterSetting ? filterSettings?.emd_notification : ApplicationConstant.ZERO}))
        setSelectedFilter(prev => ({ ...prev, email_notification: isFilterSetting ? filterSettings?.email_notification : ApplicationConstant.ZERO}))
        setSelectedFilter(prev => ({ ...prev, sms_notification: isFilterSetting ? filterSettings?.sms_notification : ApplicationConstant.ZERO}))
        setSelectedFilter(prev => ({ ...prev, recurring_type: isFilterSetting ? filterSettings?.recurring_type : ApplicationConstant.THREE}))
        setSelectedFilter(prev => ({ ...prev, repeat_day: isFilterSetting ? filterSettings?.repeat_day : ApplicationConstant.ONE}))
        setSelectedFilter(prev => ({ ...prev, schedule_type: isFilterSetting ? filterSettings?.schedule_type : ApplicationConstant.TWO}))
        setSelectedFilter(prev => ({ ...prev, time_zone_id: isFilterSetting ? filterSettings?.time_zone_id : timeZone.filter(e => e.abbreviation === getCurrentTimeZone())[0]?.id}))
        setSelectedFilter(prev => ({ ...prev, working_days: isFilterSetting ? filterSettings?.working_days?.map(item => {return item.day_of_week_id}) : []}))
    }

    useEffect(()=>{
        fetchTimeZoneList();
        fetchWorkingDayList();
    },[])

    return (
        <>
            <div className="filter-wrapper">
                <h3>Settings</h3>
                <div className="setting-items">
                    <h4 className="collapse-disabled">Set Time Zone</h4>
                    <SelectElement
                        classNm="select-timezone"
                        name="medical_school_id"
                        onChange={onTimeZoneChange}
                        options={timeZoneList}
                        className="reactSelect"
                        classNamePrefix="react-select"
                        value={currentTimeZone}
                        placeholder="Set Time Zone"
                        label="Set Time Zone"
                    />
                </div>
                <div className={`setting-items hide`}>
                    <h4 onClick={() => { setScheduleOpen(!isScheduleOpen) }}>Schedule 1</h4>
                    <Collapse isOpen={isScheduleOpen}>
                        <label className="label--checkbox">
                            <input
                                type="checkbox"
                                className="checkbox"
                                id="schedule_type"
                                name="schedule_type"
                                onChange={onScheduleTypeChange}
                                checked={scheduleType}
                            />
                            <label htmlFor="schedule_type">Standard 9 - 5pm</label>
                        </label>
                    </Collapse>
                </div>
                <div className="{`setting-items ${isDaysOpen ? 'open' : ''}`} hide">
                    <h4 onClick={() => { setDaysOpen(!isDaysOpen) }}>Days</h4>
                    <Collapse isOpen={isDaysOpen}>
                        <label className="label--radio">
                            <input
                                type="radio"
                                id="everyDay"
                                name="customDays"
                                className="radio"
                                onChange={()=>{onDayTypeChange(ApplicationConstant.ONE)}}
                                checked={repeatDay === ApplicationConstant.ONE}
                            />
                            <label htmlFor="everyDay">Every Day</label>
                        </label>
                        <label className="label--radio">
                            <input
                                type="radio"
                                id="weekDay"
                                name="customDays"
                                className="radio"
                                checked={repeatDay === ApplicationConstant.TWO}
                                onChange={()=>{onDayTypeChange(ApplicationConstant.TWO)}}
                            />
                            <label htmlFor="weekDay">Week days only</label>
                        </label>
                        <label className="label--radio">
                            <input
                                type="radio"
                                id="customDay"
                                name="customDays"
                                className="radio"
                                checked={repeatDay === ApplicationConstant.THREE}
                                onChange={()=>{onDayTypeChange(ApplicationConstant.THREE)}}
                            />
                            <label htmlFor="customDay">Custom</label>
                        </label>
                        {repeatDay === ApplicationConstant.THREE ? (
                            <div className="custom-days-checkbox">
                                {dayList.map((item, i) =>{
                                    return (
                                    <label className="label--checkbox" key={`day-${i}`}>
                                        <input
                                            type="checkbox"
                                            className="checkbox"
                                            id={`day-${item.id}`}
                                            onChange={()=>{onDayChange(item.id)}}
                                            checked={item.checked}
                                        />
                                        <label htmlFor={`day-${item.id}`}>{item?.name}</label>
                                    </label>
                                    )
                                })}
                        </div>
                        ):''}
                    </Collapse>
                </div>
                <div className={`setting-items border-0 ${isRepeatOpen ? 'open' : ''}`}>
                    <h4 onClick={() => { setRepeatOpen(!isRepeatOpen)}}>Repeat</h4>
                    <Collapse isOpen={isRepeatOpen}>
                        <label className="label--radio">
                            <input
                                type="radio"
                                id="weekly1"
                                name="repeatDays"
                                className="radio"
                                checked={selectedFilter.recurring_type === ApplicationConstant.THREE}
                                onChange={()=>{onRecurringTypeChange(ApplicationConstant.THREE)}}
                            />
                            <label htmlFor="weekly1">Only selected week</label>
                        </label>
                        <label className="label--radio">
                            <input
                                type="radio"
                                id="weekly"
                                name="repeatDays"
                                className="radio"
                                checked={selectedFilter.recurring_type === ApplicationConstant.ONE}
                                onChange={()=>{onRecurringTypeChange(ApplicationConstant.ONE)}}
                            />
                            <label htmlFor="weekly">Weekly <span>(Next 3 weeks)</span></label>
                        </label>
                        <label className="label--radio">
                            <input
                                type="radio"
                                id="monthly"
                                name="repeatDays"
                                className="radio"
                                checked={selectedFilter.recurring_type === ApplicationConstant.TWO}
                                onChange={()=>{onRecurringTypeChange(ApplicationConstant.TWO)}}
                            />
                            <label htmlFor="monthly">Monthly <span>(Next 6 months)</span></label>
                        </label>
                    </Collapse>
                </div>
                <div className={`setting-items d-none ${isNotificationsOpen ? 'open' : ''}`}>
                    <h4 onClick={() => { setNotificationsOpen(!isNotificationsOpen) }}>Notifications</h4>
                    <Collapse isOpen={isNotificationsOpen}>
                        <label className="label--checkbox">
                            <input
                                type="checkbox"
                                className="checkbox"
                                id="emdNotification"
                                name="emd_notification"
                                onChange={onNotificationTypeChange}
                                checked={notificationType.emd_notification}
                            />
                            <label htmlFor="emdNotification">eMD notification</label>
                        </label>
                        <label className="label--checkbox">
                            <input
                                type="checkbox"
                                className="checkbox"
                                id="emailNotification"
                                name="email_notification"
                                onChange={onNotificationTypeChange}
                                checked={notificationType.email_notification}
                            />
                            <label htmlFor="emailNotification">Email notification</label>
                        </label>
                        <label className="label--checkbox">
                            <input
                                type="checkbox"
                                className="checkbox"
                                id="smsNotification"
                                name="sms_notification"
                                onChange={onNotificationTypeChange}
                                checked={notificationType.sms_notification}
                            />
                            <label htmlFor="smsNotification">sms notification</label>
                        </label>
                    </Collapse>
                </div>
            </div>
        </>
    )
}

export default Filter;
