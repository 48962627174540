const DoctorSchedulerInitialState = {
  time_zone_id: '',
  duration:  60,
  schedule_type: '',
  repeat_day: '',
  recurring_type: '',
  emd_notification: '',
  email_notification: '',
  sms_notification: '',
  working_days: [],
  slots: [],
  calendar_date:'',
  add_slots:[],
  remove_slots:[],
};

export default DoctorSchedulerInitialState;
