import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import FormButton from './FormButton';

function SubscriptionPlan() {
  const [activeTab, setActiveTab] = useState('1');

  const toggle = tab => {
    if(activeTab !== tab) setActiveTab(tab);
  }
  return (
    <div className="middle-content subscriptionPlan">
      <div className="container">
        <div className="tab-section">
          <div className="back-link">
            <a class="btn-white-outline pr-7 pl-7 d-flex align-items-center mr-15"><span class="material-icons md-dark md-24">arrow_back</span></a>
            <h1>Choose your plan</h1>
          </div>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => { toggle('1'); }}
              >
                Monthly
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => { toggle('2'); }}
              >
                Yearly
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <div className="row">
                <div className="col-md-4">
                  <div className="box">
                    <div className="planDetail">
                      <div className="planName">
                        Basic Plan
                      </div>
                      <div className="planHeading">
                        Discover
                      </div>
                      <div className="planAmount">
                        <span>$</span>
                        <span>29</span>
                      </div>
                      <div className="planDate">
                      per month
                      </div>
                    </div>
                    <div className="activity">
                      <ul>
                        <li><span class="material-icons">today</span><span className="name">1 Included telehealth consultation </span></li>
                        <li><span class="material-icons">account_circle</span><span className="name">Selectable Profile   </span></li>
                        <li><span class="material-icons">local_shipping</span><span className="name">Free Shipping </span></li>
                      </ul>
                      <FormButton buttonLabel = "Get Started" buttonClassName="btn btn-secondary w-100" />
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="box recommended">
                    <div className="planDetail">
                      <div className="planMostRecommended">
                      Most Recommended
                      </div>
                      <div className="planName">
                      PREMIUM
                      </div>
                      <div className="planHeading">
                      WELLNESS
                      </div>
                      <div className="planAmount">
                        <span>$</span>
                        <span>79</span>
                      </div>
                      <div className="planDate">
                        per month
                      </div>
                    </div>
                    <div className="activity">
                      <ul>
                        <li><span class="material-icons">today</span><span className="name">1 Included telehealth consultation </span></li>
                        <li><span class="material-icons">account_circle</span><span className="name">Selectable Profile   </span></li>
                        <li><span class="material-icons">local_shipping</span><span className="name">Free Shipping </span></li>
                      </ul>
                      <FormButton buttonLabel = "get started" buttonClassName="btn btn-primary w-100" />
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="box">
                    <div className="planDetail">
                      <div className="planName">
                      Advance
                      </div>
                      <div className="planHeading">
                      COMPLETE
                      </div>
                      <div className="planAmount">
                        <span>$</span>
                        <span>199</span>
                      </div>
                      <div className="planDate">
                      per month
                      </div>
                    </div>
                    <div className="activity">
                      <ul>
                        <li><span class="material-icons">today</span><span className="name">1 Included telehealth consultation </span></li>
                        <li><span class="material-icons">account_circle</span><span className="name">Selectable Profile   </span></li>
                        <li><span class="material-icons">local_shipping</span><span className="name">Free Shipping </span></li>
                      </ul>
                      <FormButton buttonLabel = "Get Started" buttonClassName="btn btn-secondary w-100" />
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tabId="2">
            <div className="row">
                <div className="col-md-4">
                  <div className="box">
                    <div className="planDetail">
                      <div className="planName">
                        Basic Plan
                      </div>
                      <div className="planHeading">
                        Discover
                      </div>
                      <div className="planAmount">
                        <span>$</span>
                        <span>325</span>
                      </div>
                      <div className="planDate">
                        Yearly
                      </div>
                    </div>
                    <div className="activity">
                      <ul>
                        <li><span class="material-icons">today</span><span className="name">1 Included telehealth consultation </span></li>
                        <li><span class="material-icons">account_circle</span><span className="name">Selectable Profile   </span></li>
                        <li><span class="material-icons">local_shipping</span><span className="name">Free Shipping </span></li>
                      </ul>
                      <FormButton buttonLabel = "Get Started" buttonClassName="btn btn-secondary w-100" />
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="box recommended">
                    <div className="planDetail">
                      <div className="planMostRecommended">
                        Most Recommended
                      </div>
                      <div className="planName">
                      PREMIUM
                      </div>
                      <div className="planHeading">
                      WELLNESS
                      </div>
                      <div className="planAmount">
                        <span>$</span>
                        <span>900</span>
                      </div>
                      <div className="planDate">
                        Yearly
                      </div>
                    </div>
                    <div className="activity">
                      <ul>
                        <li><span class="material-icons">today</span><span className="name">1 Included telehealth consultation </span></li>
                        <li><span class="material-icons">account_circle</span><span className="name">Selectable Profile   </span></li>
                        <li><span class="material-icons">local_shipping</span><span className="name">Free Shipping </span></li>
                      </ul>
                      <FormButton buttonLabel = "get started" buttonClassName="btn btn-primary w-100" />
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="box">
                    <div className="planDetail">
                      <div className="planName">
                      Advance
                      </div>
                      <div className="planHeading">
                      COMPLETE
                      </div>
                      <div className="planAmount">
                        <span>$</span>
                        <span>2000</span>
                      </div>
                      <div className="planDate">
                        Yearly
                      </div>
                    </div>
                    <div className="activity">
                      <ul>
                        <li><span class="material-icons">today</span><span className="name">1 Included telehealth consultation </span></li>
                        <li><span class="material-icons">account_circle</span><span className="name">Selectable Profile   </span></li>
                        <li><span class="material-icons">local_shipping</span><span className="name">Free Shipping </span></li>
                      </ul>
                      <FormButton buttonLabel = "Get Started" buttonClassName="btn btn-secondary w-100" />
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>
          </TabContent>
        </div>
      </div>
    </div>
  )
}

export default SubscriptionPlan
