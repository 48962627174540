import React from 'react';
import { Link } from 'react-router-dom';
import GoBack from '../../../common/back/GoBack';
import {
  profileKey,
  ApplicationConstant,
  targetedId,
  RouteConstants,
} from '../../../../constants/application.constant';
import ProfileTooltip from '../../../common/profileElements/ProfileTooltip';
import { Image } from '../../../common/image/Image';

const PatientProfile = (props) => {
  const { profileInfo } = props;

  return (
    <div className="row">
      <div className="col-sm-12">
        <GoBack heading="Health Profile" />
        <section className="detail-info">
          <div className="profile-info">
            <div className="row">
              <div className="col-sm-12 col-md-12">
                <div className="box">
                  <div className="user-info">
                    <div className="profile-img">
                      <Image src={profileInfo?.profile_image || ''} alt="Profile image" />
                    </div>
                    <div className="head">
                      <div className="title-text mt-5">
                        <h1>
                        <span>{profileInfo?.first_name}</span> <span>{profileInfo?.last_name}</span>    
                        </h1>
                      </div>
                      <div className="action-status">
                        <h4>
                          Member Since: <span>{profileInfo?.member_since}</span>
                        </h4>
                        <Link
                          to={RouteConstants.PATIENT_ACCOUNT_INFORMATION}
                          class="btn-white-outline pr-10 pl-10 d-flex align-items-center mr-8"
                        >
                          <span class="material-icons md-dark md-18">mode_edit</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="profile-info">
                    <div className="row">
                      <div className="col-md-4 col-sm-6">
                        <label>Pronouns</label>
                        <div className="value more-items">
                          {profileInfo?.user_pronouns?.length
                            ? ProfileTooltip(
                                profileInfo.user_pronouns,
                                targetedId.PATIENT,
                                profileKey.TITLE,
                                profileKey.OTHER_PRONOUN
                              )
                            : '-'}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Weight (Pounds)</label>
                        <div className="value">{profileInfo?.weight || '-'}</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Height (Feet & Inches)</label>
                        <div className="value">
                          {profileInfo?.height_feet || '-'}
                          {profileInfo?.height_inches ? `'${profileInfo?.height_inches}"` : ''}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Age</label>
                        <div className="value">{profileInfo?.age ? `${profileInfo?.age} Years` : '-'}</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Race</label>
                        <div className="value more-items">
                          {profileInfo?.user_races?.length
                            ? ProfileTooltip(
                                profileInfo.user_races,
                                targetedId.PATIENT,
                                profileKey.NAME,
                                profileKey.OTHER_RACE
                              )
                            : '-'}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Ethnicity</label>
                        <div className="value">{profileInfo?.ethnicity || '-'}</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Language Spoke</label>
                        <div className="value more-items">
                          {profileInfo?.user_languages?.length
                            ? ProfileTooltip(
                                profileInfo.user_languages,
                                targetedId.PATIENT,
                                profileKey.NAME,
                                profileKey.OTHER_LANGUAGE
                              )
                            : '-'}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Gender Identity</label>
                        <div className="value more-items">
                          {profileInfo?.user_identifications?.length
                            ? ProfileTooltip(
                                profileInfo.user_identifications,
                                targetedId.PATIENT,
                                profileKey.TITLE,
                                profileKey.OTHER_IDENTIFICATION
                              )
                            : '-'}
                        </div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Sexual Orientation</label>
                        <div className="value more-items">
                          {profileInfo?.user_sexual_orientations?.length
                            ? ProfileTooltip(
                                profileInfo.user_sexual_orientations,
                                targetedId.PATIENT,
                                profileKey.NAME,
                                profileKey.OTHER_SEXUAL_ORIENTATION
                              )
                            : '-'}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <label> How can equalityMD support you living your healthiest
                      and most authentic life?</label>
                        <div className="value">{profileInfo?.notes || '-'}</div>
                      </div>
                      <h2 className="profile-section-head">Contact Information</h2>
                      <div className="col-md-4 col-sm-6">
                        <label>Birthday</label>
                        <div className="value">{profileInfo?.date_of_birth}</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Prefered Name</label>
                        <div className="value">{profileInfo?.preferred_name || '-'}</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Email Address</label>
                        <div className="value">{profileInfo?.email}</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Phone Number</label>
                        <div className="value">{profileInfo?.phone}</div>
                      </div>
                      <h2 className="profile-section-head">Location</h2>
                      <div className="col-md-4 col-sm-6">
                        <label>Street Address</label>
                        <div className="value">{profileInfo?.address1}</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Apartmant</label>
                        <div className="value">{profileInfo?.address2 || '-'}</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>City</label>
                        <div className="value">{profileInfo?.city}</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>State</label>
                        <div className="value">{profileInfo?.state}</div>
                      </div>
                      <div className="col-md-4 col-sm-6">
                        <label>Zip Code</label>
                        <div className="value">{profileInfo?.zip_code}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
export default PatientProfile;
