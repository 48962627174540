import React from 'react'
import PropTypes from 'prop-types';

const FormButton = (props) => {
  const {buttonLabel, buttonClassName, handleClick, disabled} = props;
  return (
    <div>
      <button
        className={buttonClassName}
        onClick={handleClick}
        disabled={disabled}
      >
        {buttonLabel}
      </button>
    </div>
  )
}
FormButton.propTypes = {
  label: PropTypes.string.isRequired,
  buttonClassName: PropTypes.string.isRequired,
  handleClick: PropTypes.func
}
export default FormButton
