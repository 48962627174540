import ReactPaginate from 'react-paginate';
import PaymentHistoryListItem from './PaymentHistoryListItem';
import { ApplicationConstant } from './../../../../../constants/application.constant';

const PaymentHistoryList = (props) => {
  const { paymentHistoryList, pageCount, changePagination, listInitialPage, openListDetailTab, setOpenListDetailTab, selectListItems } = props;
  return (
    <section className="payment-list-section">
      {paymentHistoryList.map((item, index)=> {
        return (
          <PaymentHistoryListItem
            item={item}
            key={`${index}-${item.id}`}
            openListDetailTab={openListDetailTab}
            setOpenListDetailTab={setOpenListDetailTab}
            selectListItems={selectListItems}
          />
        )
      })}
      <div className="pagination-wrapper" hidden={pageCount === ApplicationConstant.ONE}>
        <ReactPaginate
          previousLabel={'previous'}
          nextLabel={'next'}
          breakLabel={'...'}
          breakClassName={'break-me'}
          pageCount={pageCount}
          marginPagesDisplayed={4}
          onPageChange={changePagination}
          pageRangeDisplayed={1}
          forcePage={listInitialPage}
          containerClassName={'pagination'}
          activeClassName={'active'}
          nextLinkClassName={'next-btn'}
          previousLinkClassName={'prev-btn'}
        />
      </div>
    </section>
  )
}

export default PaymentHistoryList;
