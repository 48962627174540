import React from 'react'
import { ApplicationConstant, profileKey, targetedId } from '../../../constants/application.constant'
import ProfileTooltip from '../../common/profileElements/ProfileTooltip'

function DoctorProfileBackgroundInfo(props) {
  const { profileInfo } = props;
  return (
    <>
      <h2 className="profile-section-head">Education Information</h2>
      <div className="col-md-4 col-sm-6">
        <label>School</label>
        <div className="value">
          {profileInfo?.doctor_profile?.medical_school?.toLowerCase() ===
          ApplicationConstant.OTHER_LOWER_CASE
            ? profileInfo?.doctor_profile?.other_medical_school
            : profileInfo?.doctor_profile?.medical_school || "-"}
        </div>
      </div>
      <div className="col-md-4 col-sm-6">
        <label>Degrees/Credentials</label>
        <div className="value more-items">
          {profileInfo?.doctor_profile?.doctor_certifications?.length
            ? ProfileTooltip(
                profileInfo?.doctor_profile?.doctor_certifications,
                targetedId.DOCTOR,
                profileKey.NAME,
                profileKey.OTHER_CERTIFICATION
              )
            : "-"}
        </div>
      </div>
      <div className="col-md-4 col-sm-6">
        <label>Specialty</label>
        <div className="value more-items">
          {profileInfo?.doctor_profile?.doctor_specializations?.length
            ? ProfileTooltip(
                profileInfo?.doctor_profile?.doctor_specializations,
                targetedId.DOCTOR,
                profileKey.NAME,
                profileKey.SPECIALIZATION
              )
            : "-"}
        </div>
      </div>
      <div className="col-md-4 col-sm-6">
        <label>Years of Experience</label>
        <div className="value">
          {profileInfo?.doctor_profile?.year_of_exp}{" "}
          {profileInfo?.doctor_profile?.year_of_exp > ApplicationConstant.ONE
            ? "Years"
            : "Year"}
        </div>
      </div>
      <div className="col-md-4 col-sm-6 d-none">
        <label>Practice</label>
        <div className="value more-items">
          {profileInfo?.doctor_profile?.doctor_practices?.length
            ? ProfileTooltip(
                profileInfo?.doctor_profile?.doctor_practices,
                targetedId.DOCTOR,
                profileKey.NAME,
                profileKey.PRACTICES
              )
            : "-"}
        </div>
      </div>
      <div className="col-md-4 col-sm-6 d-none">
        <label>Procedure(s) Performed</label>
        <div className="value more-items">
          {profileInfo?.doctor_procedures?.length
            ? ProfileTooltip(
                profileInfo.doctor_procedures,
                targetedId.DOCTOR,
                profileKey.NAME,
                profileKey.PROCEDURES
              )
            : "-"}
        </div>
      </div>
      <div className="col-md-4 col-sm-6 d-none">
        <label>Condition(s) Treated</label>
        <div className="value more-items">
          {profileInfo?.doctor_condition_treateds?.length
            ? ProfileTooltip(
                profileInfo.doctor_condition_treateds,
                targetedId.DOCTOR,
                profileKey.NAME,
                profileKey.CONDITION_TREATEDS
              )
            : "-"}
        </div>
      </div>
    </>
  );
}

export default DoctorProfileBackgroundInfo;

