import InfiniteScroll from 'react-infinite-scroll-component';
import { Spinner } from 'reactstrap';
import HistoryListHeader from './HistoryListHeader';
import HistoryListItem from './HistoryListItem';
import { ApplicationConstant } from '../../../../../../../constants/application.constant';

const HistoryList = (props) => {
  const { historyList,
    sortFilter,
    sortList,
    fetchMoreData,
    tableScrollRef,
    scrollLoader,
    onSelectListRow,
    totalPageLimit,
    onDownloadDocument
  } = props;

  const isRowCollapse = () => historyList.filter(e => e.checked)

  return (
    <>
      <div
        className={`table-head ${historyList?.length >= ApplicationConstant.FOUR  || isRowCollapse().length ? 'scroll-padding' : ''}`}>
        <HistoryListHeader filter={sortFilter} sortList={sortList}/>
      </div>
      <div id="patientHistoryScrollableDiv" className="table-body" ref={tableScrollRef}>
        <InfiniteScroll
            dataLength={historyList.length}
            next={fetchMoreData}
            hasMore={totalPageLimit > ApplicationConstant.ONE}
            scrollThreshold="70px"
            scrollableTarget="patientHistoryScrollableDiv"
        >
            {historyList.map((item, index) => {
                return (
                  <HistoryListItem
                    key={`history-${index}-${item?.id}`}
                    item={item}
                    onSelectListRow={onSelectListRow}
                    onDownloadDocument={onDownloadDocument}
                  />
                )
            })}
        </InfiniteScroll>
      </div>
      {scrollLoader && <Spinner/>}
    </>
  )
}

export default HistoryList;
