import { Spinner } from 'reactstrap';
import { ApplicationConstant } from '../../../../../../../constants/application.constant';
import IconTooltip from '../../../../../../common/tooltip/IconTooltip';

const DocumentListFooter = (props) => {
  const { downloadDocument, downloadLoader, documentList } = props;

  const deleteButtonValidation = () => {
    const checkedItems = documentList.filter((e) => e.checked);
    return checkedItems?.length ? true : false;
  };

  return (
    <div className={`table-footer-row spinner-box mt-20 pb-20 d-flex`}>
      {documentList?.length > ApplicationConstant.ONE ? (
        <button
          className={`btn-white-outline ml-auto ${
            deleteButtonValidation() ? "enabled-icon" : "disabled-icon"
          }`}
          onClick={downloadDocument}
          id="downloadAll"
          disabled={downloadLoader}>
          {downloadLoader ? (
            <Spinner />
          ) : (
            <span className="material-icons md-dark md-18">file_download</span>
          )}
          {IconTooltip("bottom", "download", "Download", "All")}
        </button>
      ) : (
        ""
      )}
    </div>
  );
}

export default DocumentListFooter;
