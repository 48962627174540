import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import axiosInstance from '../../../axios/axiosInstance';
import ApiConstant from '../../../constants/ApiConstant';
import { ApplicationConstant, RouteConstants } from './../../../constants/application.constant';
import PatientMatchedDoctorItem from './PatientMatchedDoctorItem';
import { EMPTY_ICON } from './../../../constants/image';
import { toast } from 'react-toastify';
import EmptyStateComponent from './../../common/emptyStateComponent/EmptyStateComponent';
import ReactPaginate from 'react-paginate';
import { getPageParams } from '../../../utils/helper';

const PatientMatchedDoctors = (props) => {
  const {
    isFavDocFlow = ApplicationConstant.FALSE,
    apiUrl = ApiConstant.PATIENT_MATCHED_DOCTORS,
    isViewAllClicked = ApplicationConstant.FALSE,
    limit = ApplicationConstant.THREE,
    setSpinnerLoader = ApplicationConstant.FALSE,
    profileData: patientData = {},
  } = props;
  const history = useHistory();
  const [doctorList, setDoctorList] = useState([]);
  const [itemCount, setItemCount] = useState(ApplicationConstant.ZERO);
  const [loader, setLoader] = useState(ApplicationConstant.FALSE);
  const params = new URLSearchParams();
  const [listInitialPage, setListInitialPage] = useState(getPageParams(history, 'page') - 1);
  const [pageNum, setPageNum] = useState(listInitialPage+1);
  const [lastPage, setLastPage] = useState(ApplicationConstant.ZERO);


  const fetchPatientMatchedDoctorList = async (pageNum) => {
    setSpinnerLoader && setSpinnerLoader(ApplicationConstant.TRUE);
    setLoader(ApplicationConstant.TRUE);
    await axiosInstance.get(`${apiUrl}?page=${pageNum}&limit=${limit}`).then((response) => {
      if (response && response.data && response.data.data) {
        const patientMatchedDoctorList = response.data.data.data || {};
        const totalitems = response.data.data.total;
        setDoctorList(patientMatchedDoctorList);
        setItemCount(totalitems);
        setLastPage(Math.ceil(totalitems / limit));
      }
    });
    setLoader(ApplicationConstant.FALSE);
    setSpinnerLoader && setSpinnerLoader(ApplicationConstant.FALSE);
  };

  const findDoctorRedirection = () => {
    history.push(RouteConstants.FIND_DOCTOR);
  };

  const setPageParams = (pageRange) => {
    if (pageRange) {
      params.set('page', pageRange);
    }
    history.replace({search: params.toString()});
  }

  useEffect(() => {
    fetchPatientMatchedDoctorList(pageNum);
  }, [pageNum]);

  const addFavDoctor = async (id) => {
    const payload = { doctor_id: id };
    await axiosInstance
      .post(`${ApiConstant.ADD_FAV_DOCTOR}`, payload)
      .then((res) => {
        if (res && res.data) {
          toast.success(res?.data?.message);
        }
      })
      .catch((error) => {
        toast.error(error || '');
      });
  };

  const removeFavDoctor = async (id) => {
    const payload = { doctor_id: id };
    await axiosInstance
      .post(`${ApiConstant.DELETE_FAV_DOCTOR}`, payload)
      .then((response) => {
        if (response && response.data) {
          toast.success(response?.data?.message);
          if (isFavDocFlow) {
            fetchPatientMatchedDoctorList(pageNum);
          }
        }
      })
      .catch((error) => {
        toast.error(error || '');
      });
  };

  const onFavBtnClick = (isFav, id) => {
    if (isFav) {
      removeFavDoctor(id);
    } else {
      addFavDoctor(id);
    }
  };

  const changePagination = (data) => {
    const selectedPage = data.selected;
    setPageNum(selectedPage + ApplicationConstant.ONE);
    setListInitialPage(selectedPage);
    setPageParams(selectedPage + ApplicationConstant.ONE);
  };

  const onScheduleAppointment = (input, doctorData) => {
    history.push({
      pathname: RouteConstants.SUBSCRIPTION_PLAN,
      state: { msg: input, patientProfile: patientData, doctorProfile: doctorData },
    });
  };

  return (
    <section className='my-matched-section' hidden={loader}>
      {!isViewAllClicked && !isFavDocFlow && (
        <div className={`heading-link`}>
          <h5>{'My Matched Providers'}</h5>
          {itemCount > ApplicationConstant.THREE && !isViewAllClicked && (
            <Link to={RouteConstants.VIEW_ALL_DOCTOR}>
              <span>VIEW ALL</span>
            </Link>
          )}
        </div>
      )}
      {doctorList?.length ? (
        <>
          <div className='card-box'>
            {doctorList.map((item, index) => {
              return (
                <PatientMatchedDoctorItem
                  key={item?.user?.id}
                  id={item?.user?.id}
                  doctorData={item}
                  isFavDocFlow={isFavDocFlow}
                  onFavBtnClick={onFavBtnClick}
                  isViewAllClicked={ApplicationConstant.TRUE}
                  onScheduleAppointment={onScheduleAppointment}
                />
              );
            })}
          </div>
        </>
      ) : (
        <EmptyStateComponent
          imageSrc={EMPTY_ICON}
          title={isFavDocFlow ? 'No favorite provider found.' : 'No matched provider found.'}
          subtitle='Click on the button below to find a provider.'
          buttonText='find a Provider'
          handleClick={findDoctorRedirection}
        />
      )}

      {(isViewAllClicked || isFavDocFlow) && doctorList?.length > ApplicationConstant.ZERO && (
        <div className='pagination-wrapper' hidden={lastPage === ApplicationConstant.ONE}>
          <ReactPaginate
            previousLabel={'previous'}
            nextLabel={'next'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={lastPage}
            marginPagesDisplayed={ApplicationConstant.FOUR}
            onPageChange={changePagination}
            pageRangeDisplayed={1}
            forcePage={pageNum - ApplicationConstant.ONE}
            containerClassName={'pagination'}
            activeClassName={'active'}
            nextLinkClassName={'next-btn'}
            previousLinkClassName={'prev-btn'}
          />
        </div>
      )}
    </section>
  );
};

export default PatientMatchedDoctors;
