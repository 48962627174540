import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';

const CancelAppointmentModal = (props) => {
  const { isOpen, setModelOpen, cancelPatientAppointment } = props;
  return (
    <Modal isOpen={isOpen} toggle={setModelOpen} className="appointment-cancel">
      <ModalBody className="m-0">
        <h1>Cancel Appointment </h1>
        <h5 className="text-center text-dark mb-10">Are you sure you want to cancel?</h5>
        <h5 className="text-center text-dark mb-10">or Did you try to reschedule instead?</h5>
        <h5 className="text-center text-dark mb-10 font-12">
          please review our 24 hour cancellation policy
        </h5>
        <div className="text-center mt-50">
          <Button
            className="w-100"
            color="secondary"
            onClick={() => {
              cancelPatientAppointment();
            }}
          >
            YES, CANCEL
          </Button>{' '}
        </div>
      </ModalBody>
    </Modal>
  );
};
export default CancelAppointmentModal;
