import React from 'react'
import { ApplicationConstant, PageTitles } from '../../../../constants/application.constant';
import useDocumentTitle from '../../../../utils/DocumentTitle';
import DoctorSignUpPage from '../../../user-signup/doctor/DoctorSignUpPage';
import DoctorSignUpProvider from '../../../../contexts/doctor-signup/DoctorSignUpProvider';
import { useLocation } from 'react-router-dom';


function AddDoctor() {

    useDocumentTitle(PageTitles.ADD_DOCTOR);
    const location = useLocation();

    return (
      <div className="step-form csv-form add-doctor-form">
          <div className="container">
              <DoctorSignUpProvider><DoctorSignUpPage isHospitalFlow={ApplicationConstant.TRUE} userId={location.state.user_id}/></DoctorSignUpProvider>
        </div>
      </div>
    )
}
export default AddDoctor;
