import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ApplicationConstant, ModalConstants, profileKey, RouteConstants, targetedId } from './../../../constants/application.constant';
import ProfileTooltip from '../../common/profileElements/ProfileTooltip';
import { Image } from './../../common/image/Image';
import { UncontrolledTooltip } from 'reactstrap';
import AppointmentModal from '../../common/modal/AppointmentModal';

const PatientMatchedDoctorItem = (props) => {
  const history = useHistory();

  const { id: key, doctorData, onFavBtnClick, isFavDocFlow, onScheduleAppointment} = props;
  const [isFav, setFav] = useState(doctorData?.my_favorite === ApplicationConstant.ONE || isFavDocFlow);
  const [isOpen, setModalOpen] = useState(false);
  const schedularModal = () => {
    doctorData?.available_for_booking && setModalOpen(!isOpen);
  }

  const saveModal =(bool, input)=>{
    if(bool){
      onScheduleAppointment(input, doctorData);
    }
    schedularModal();
  }



  const sendEmail = (emailId) => {
    if (emailId) {
      window.location.assign(`mailto:${emailId}`);
    }
  }

  const onFavClick = (e) => {
    e.preventDefault();
    const id = doctorData?.user.id;
    onFavBtnClick(isFav, id);
    setFav(!isFav);
  }

  const openDoctorProfile = (e) => {
    e.preventDefault()
    history.push({ pathname: `${RouteConstants.PATIENT_VIEW_DOCTOR_PROFILE}`, state: { doctor_id: doctorData.user.id } })
  }

  return (
    <div className="card-row">
      <div className="row">
        <div className="col-lg-4 col-md-12 profile-info">
          <div className="user-info" >
            <div className="profile-img" style={{ cursor: 'pointer' }} onClick={openDoctorProfile}>
              <Image src={doctorData?.user?.profile_image || ''} alt="Profile image" />
            </div>
            <div className="head">
              <div className="title-text matched-doctor-title mt-5">
                <h1 className="font-16 font-500" hidden={!doctorData?.hospital}>{doctorData?.hospital ? doctorData?.hospital.preferred_name : "-" }</h1>
                <h1 className="font-16 font-500" style={{ cursor: 'pointer' }} onClick={openDoctorProfile} >
                  {doctorData.user?.first_name} {doctorData.user?.last_name}
                  {doctorData.user?.first_name === '' && doctorData.user?.last_name === '' ? '-' : ''}
                </h1>
                <h2>
                  {doctorData.doctor_specializations?.length ?
                    doctorData.doctor_specializations[ApplicationConstant.ZERO].name : ""}
                </h2>
                <h3 className="matching-percentage">Matching <span>({doctorData?.match_value ? `${doctorData?.match_value}%` : '-'})</span></h3>
                <h3 className="matching-percentage">Consultation fees <span>({doctorData?.consultation_fee && doctorData?.consultation_fee.includes("###") ? '' : '$'}{doctorData?.consultation_fee})</span></h3>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-12">
          <div className="profile-info">
            <div className="row">
              <div className="col-md-4 col-sm-6">
                <label>Pronouns</label>
                <div className="value more-items">
                  {doctorData?.user_pronouns?.length
                    ? ProfileTooltip(
                      doctorData?.user_pronouns,
                      targetedId.DOCTOR,
                      profileKey.TITLE,
                      profileKey.OTHER_PRONOUN
                    )
                    : '-'}
                </div>

              </div>
              <div className="col-md-4 col-sm-6">
                <label>Ethnicity</label>
                <div className="value">{doctorData.ethnicity?.name || '-'}</div>
              </div>
              <div className="col-md-4 col-sm-6">
                <label>Sexual Orientation</label>
                <div className="value more-items">
                  {doctorData.user_sexual_orientations?.length
                    ? ProfileTooltip(
                      doctorData.user_sexual_orientations,
                      targetedId.DOCTOR,
                      profileKey.NAME,
                      profileKey.OTHER_SEXUAL_ORIENTATION
                    )
                    : '-'}
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <label>Race</label>
                <div className="value more-items">
                  {doctorData.user_races?.length
                    ? ProfileTooltip(
                      doctorData.user_races,
                      targetedId.DOCTOR,
                      profileKey.NAME,
                      profileKey.OTHER_RACE
                    )
                    : '-'}
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <label>Gender identity</label>
                <div className="value more-items">
                  {doctorData.user_identifications?.length
                    ? ProfileTooltip(
                      doctorData.user_identifications,
                      targetedId.DOCTOR,
                      profileKey.TITLE,
                      profileKey.OTHER_IDENTIFICATION
                    )
                    : '-'}
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <label>Age</label>
                <div className="value">{doctorData?.age ? `${doctorData?.age} Years` : '-'}</div>
              </div>
            </div>
          </div>
        </div>
        <AppointmentModal
              modalTitle={ModalConstants.SCHEDULER_TITLE}
              modalMessage={ModalConstants.VISIT_REASON}
              secondaryButonText={ModalConstants.CANCEL}
              primaryButtonText={ModalConstants.SAVE}
              setModelOpen={schedularModal}
              isOpen={isOpen}
              onButtonClicked={saveModal}
              modalClassName="modal-logout"
          />
        <div className="col-lg-2 col-sm-12 p-0 d-flex align-items-center">
          <div className="action-status d-flex flex-wrap">
            <a href class={`btn-white-fav ${isFav ? 'active' : ''} pr-10 pt-10 pb-10 pl-10 d-flex align-items-center mr-8 mt-4 mb-4`}
              onClick={onFavClick} id={`favorite-btn-${key}`}>
              <span class="material-icons md-dark md-18">favorite</span>
            </a>
            <UncontrolledTooltip className="moreItemTooltiip" placement="bottom" target={`favorite-btn-${key}`}>
              <span>{isFav ? 'Unsave' : 'Save'}</span>
            </UncontrolledTooltip>
            {doctorData.can_schedule ?
            <>
              <a href class="btn-white-outline pr-10 pt-10 pb-10 pl-10 d-flex align-items-center mr-8 mt-4 mb-4" id={`email-btn-${key}`}
                onClick={() => { sendEmail(doctorData?.user?.email) }}>
                <span class="material-icons md-dark md-18">email</span>
              </a>
              <UncontrolledTooltip className="moreItemTooltiip" placement="bottom" target={`email-btn-${key}`}>
                <span>Mail</span>
              </UncontrolledTooltip>
              <a href
                className={`btn-white-outline pr-10 pt-10 pb-10 pl-10 d-flex align-items-center mt-4 mb-4 mr-8 ${doctorData?.available_for_booking ? 'enabled-icon' : 'disabled-icon'}`}
                id={`schedule-btn-${key}`}
              onClick={()=>{schedularModal()}}>
                <span class="material-icons md-dark md-18">today</span>
              </a>
              <UncontrolledTooltip className="moreItemTooltiip" placement="bottom" target={`schedule-btn-${key}`}>
                <span>Schedule Appointment</span>
              </UncontrolledTooltip>
            </>
            :''}
            <a href class="btn-white-outline pr-10 pt-10 pb-10 pl-10 d-flex align-items-center mt-4 mb-4" id={`view-details-btn-${key}`}
            onClick={openDoctorProfile}>
              <span class="material-icons md-dark md-18">description</span>
            </a>
            <UncontrolledTooltip className="moreItemTooltiip" placement="bottom" target={`view-details-btn-${key}`}>
              <span>View Profile</span>
            </UncontrolledTooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientMatchedDoctorItem;
