import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';
import { ApplicationConstant } from '../../../constants/application.constant';

function FormRadioButton({ keyId, label, labelDescription, item, currentItem, handleSelect }) {
  return (
    <div className="col-sm-6">
      <FormGroup check>
        <Label check className={`label--radio ${item === currentItem ? 'active' : ''}`}>
          <Input
            checked={item === currentItem}
            onChange={() => {
              handleSelect(item);
            }}
            className="radio"
            type="radio"
            name="radio1"
            id={`radio${keyId}`}
          />
          <label htmlFor="radio0">
            {label}
            {!!labelDescription ? <span>({labelDescription})</span> : ApplicationConstant.NO_VALUE_STRING}
          </label>
        </Label>
      </FormGroup>
    </div>
  );
}

export default FormRadioButton;
