import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import axiosInstance from '../../../../axios/axiosInstance';
import ApiConstant from '../../../../constants/ApiConstant';
import { getSearchParama } from '../../../../utils/helper'

const DoctorStripeAccount = (props) => {
    const { setLoader } = props;
    const history = useHistory();
    const [accountId, setAccountId] = useState(null);
    const account_id = getSearchParama(history, "account_id")

    const fetchDoctorStripAccount = async () => {
        setLoader(true);
        await axiosInstance.get(`${ApiConstant.DOCTOR_STRIPE_ACCOUNT}`)
            .then((response) => {
                if (response && response?.data && response?.data?.data) {
                    const doctorStripAccountObj = response?.data?.data;
                    setAccountId(doctorStripAccountObj);
                }

            })
            .catch((error) => {
                toast.error(error || '');
            });
        setLoader(false);
    }

    const createStripeAccount = async () => {
        setLoader(true);
        await axiosInstance.get(`${ApiConstant.CREATE_STRIPE_ACCOUNT}`)
            .then((response) => {
                if (response && response?.data && response?.data?.data) {
                    const stripeUrlAccount = response?.data?.data?.url
                    if (stripeUrlAccount) {
                        window.location.replace(stripeUrlAccount)
                    }
                }

            })
            .catch((error) => {
                toast.error(error || '');
            });
        setLoader(false);

    }
    const updateStripAccount = async (userAccountId) => {
        const payload = {account_id: userAccountId};
        await axiosInstance
            .post(`${ApiConstant.UPDATE_STRIPE_ACCOUNT}`, payload)
            .then((response) => {
                fetchDoctorStripAccount();

            })
            .catch((error) => {
                toast.error(error || "");
            });
        setLoader(false);
    }

    const handleAccount = () => {
        createStripeAccount();
    }

    useEffect(() => {
        if(!!account_id){
            updateStripAccount(account_id);
        }else{
            fetchDoctorStripAccount();
        }
    }, [])

    return (
      <div className="card-sec">
        <h3>Set-up Stripe Account</h3>
        <div className="go-to-stripe">
            {(accountId?.status_id == 1) ?
            <>
                <p>Please update your stripe account</p>
                <button className="btn btn-primary" onClick={handleAccount}>
                    {"Update"}
                </button>
            </>
            : (accountId?.status_id == 2 || accountId?.status_id == 3 || accountId?.status_id == 4) ?
            <>
                <p>Your Stripe account has not been approved!</p>
                <button className="btn btn-primary" onClick={handleAccount}>
                    {"Continue setup"}
                </button>
            </>
            :
            <>
                <p>Please set up a Stripe account in order to receive payment</p>
                <button className="btn btn-primary" onClick={handleAccount}>
                    {"Go to Stripe"}
                </button>
            </>
        }
        </div>
      </div>
    );
}

export default DoctorStripeAccount;
